import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const requiereSubliente = ['HptPbvVxYYUjUzp7Nt3TtmyAt0x1','31RxHqtBOmbgOWWeMCKnb1DuEHc2'];


export default class Download extends React.Component {
    
    constructor(props)
    {
        super();
        let {data}=props;
        let {uid}=props;
        this.state = {
            dataSet1: data,
            uid
        }
     //   console.log(this.state);

    }

    componentDidUpdate=(prevProps)=>{
        
    }
    
    render() {

        if(this.state.uid && requiereSubliente.find(rs=>rs===this.state.uid)){
            return (
                <ExcelFile  element={
                      <div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>
                    }>
                    <ExcelSheet data={this.state.dataSet1} name="Viajes"> 
                        <ExcelColumn label="Viaje" value="viaje"/>
                        <ExcelColumn label="Carta Porte" value="carta"/>
                        <ExcelColumn label="Tipo de viaje" value="tipoviaje"/>
                        <ExcelColumn label="Segmento de Negocio" value="segmentoNegocio"/>
                        <ExcelColumn label="Estatus" value="estatus"/>
                        <ExcelColumn label="Recolección" value="recoleccion"/>
                        <ExcelColumn label="Entrega" value="entrega"/>
                        <ExcelColumn label="Cliente" value="cliente"/>
                        <ExcelColumn label="Subcliente" value="subcliente"/>                
                        <ExcelColumn label="Operador" value="operador"/>
                        <ExcelColumn label="Comisión" value="comision"/>
                        <ExcelColumn label="Unidad" value="unidad"/>
                        <ExcelColumn label="Origen" value="origen"/>
                        <ExcelColumn label="Destino" value="destino"/>
                        <ExcelColumn label="Remolque1" value="remolque1"/>
                        <ExcelColumn label="Remolque2" value="remolque2"/>
                        <ExcelColumn label="Kms." value="km"/>
                        <ExcelColumn label="Referencia" value="referencia"/>
                        <ExcelColumn label="Total" value="tarifa"/>
                        <ExcelColumn label="SubTotal" value="subTarifa"/>
                        <ExcelColumn label="Moneda" value="moneda"/>
                        <ExcelColumn label="Ingreso x Km." value="ingresokm"/>
                        <ExcelColumn label="Comentarios" value="detalles"/>
                    </ExcelSheet>
                   
                </ExcelFile>
            );
        }else{
            return (
                <ExcelFile  element={
                      <div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>
                    }>
                    <ExcelSheet data={this.state.dataSet1} name="Viajes"> 
                        <ExcelColumn label="Viaje" value="viaje"/>
                        <ExcelColumn label="Carta Porte" value="carta"/>
                        <ExcelColumn label="Tipo de viaje" value="tipoviaje"/>
                        <ExcelColumn label="Segmento de Negocio" value="segmentoNegocio"/>
                        <ExcelColumn label="Estatus" value="estatus"/>
                        <ExcelColumn label="Recolección" value="recoleccion"/>
                        <ExcelColumn label="Entrega" value="entrega"/>
                        <ExcelColumn label="Cliente" value="cliente"/>                
                        <ExcelColumn label="Operador" value="operador"/>
                        <ExcelColumn label="Comisión" value="comision"/>
                        <ExcelColumn label="Unidad" value="unidad"/>
                        <ExcelColumn label="Origen" value="origen"/>
                        <ExcelColumn label="Destino" value="destino"/>
                        <ExcelColumn label="Remolque1" value="remolque1"/>
                        <ExcelColumn label="Remolque2" value="remolque2"/>
                        <ExcelColumn label="Kms." value="km"/>
                        <ExcelColumn label="Referencia" value="referencia"/>
                        <ExcelColumn label="Total" value="tarifa"/>
                        <ExcelColumn label="Moneda" value="moneda"/>
                        <ExcelColumn label="Ingreso x Km." value="ingresokm"/>
                        <ExcelColumn label="Comentarios" value="detalles"/>
                    </ExcelSheet>
                   
                </ExcelFile>
            );
        }
       
    }
}
