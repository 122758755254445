import React, { useState, Fragment } from 'react';

import DashboardContent from './content';

import HeaderNew from '../utils/HeaderNew';
import SideBarNew from '../utils/NewSideBar';

const Dashboard = props => {
    const [isToggle, setIsToggle] = useState(false);

    return (
        <div>
            <HeaderNew isToggle={isToggle} setIsToggle={setIsToggle} />
            <main>
                <SideBarNew isToggle={isToggle} />

                <div className="wrapper">
                    <div style={{ minWidth: '80vw',width:'auto', minHeight: '95vh',height:"auto" }}>
                        <DashboardContent />
                    </div>

                </div>
            </main>
        </div>
    )

}

export default Dashboard;