import React, { useState, useEffect, Fragment } from 'react';
import { firebase } from '../../../configs/firebase';
import { deleteIngreso } from '../../../controllers/ingresos';
import { getUserByUid, getAllUsuarios } from '../../../controllers/usuarios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import { addDays } from "date-fns";
import { HotTable, HotColumn } from '@handsontable/react';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { registerLanguageDictionary } from 'handsontable/i18n';
import esMX from 'handsontable/i18n/languages/es-MX';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ModalCreateEdit from '../ModalCreateEdit';
import { getCombustibles, deleteCombustible, getCombustiblesPorViaje } from '../../../controllers/combustible';
import { EstatusRender, ActionRender } from './ActionsRender';
import { getUidMaster } from '../../../session/session_controller';
//import { ActionRender, DateRender, MoneyRender } from './ActionsRender';
import Download from './ExcelExport';


const ActivoContent = (props) => {

    registerLanguageDictionary(esMX);
    registerLocale('es', es)

    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [firstDay, setFirstDay] = useState(null);
    const [lastDay, setLastDay] = useState(null);
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);

    const [iddeviaje, setiddeviaje] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [querysearch, setQuerysearch] = useState('');
    const [ingresos, setIngresos] = useState([]);
    const [ingresosNoFilter, setIngresosNoFilter] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currenteditrecrod, setCurrentEditRecord] = useState(null);
    const [total, setTotal] = useState(0.0);
    const [totalMask, setTotalMask] = useState('');

    const [litrostotales, setLitrosTotales] = useState(0);
    const [constoPromedio, setCostoPromedio] = useState(0);
    const [costoxlitro, setCostoXLitro] = useState(0);
    const [totalMontosFloat, setTotalMontosFloat] = useState(0);
    const [totalMontos, setTotalMontos] = useState("");


    const validateAdmin = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (user.type && user.type === "administrador") {
                    isadmin = true;
                }
            });
            if (isadmin) {
                setAdminCurrentUser(uid);
                setIsAdmin(true);
            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                setIsAdmin(false);
                setUserlist([]);
                // this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }

    useEffect(() => {
        if (props.iddeviaje) {
            setiddeviaje(props.iddeviaje);
        }
    }, [props]);



    useEffect(() => {
        if (isAdmin) {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                setUserlist(a);
            }).catch(e => {
                console.log(e);
                //this.setState({isLoading:false,})
            });
        }
    }, [isAdmin]);

    useEffect(() => {
        setTotalMask(total.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
    }, [total]);

    useEffect(() => {


        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {
                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);
                setUser(userr);
                // setAdminCurrentUser(userr.uid);

                if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                    // this.validateAdmin(user.uid);
                    //console.log("validando admin");
                    validateAdmin(userr.uid);
                }
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });

    }, []);


    useEffect(() => {
        if (querysearch.trim() === '') {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                aux.push(element);
            });
            setIngresos(aux);
        } else {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                let query = querysearch.trim().toUpperCase();
                if (element.factura.toUpperCase().includes(query) ||
                    element.unidad.nombre.toUpperCase().includes(query)
                ) {
                    aux.push(element);
                }

            });
            setIngresos(aux);
        }
    }, [querysearch]);

    useEffect(() => {
        getData(user);
    }, [user, currentDate, adminCurrentUser]);

    useEffect(() => {
        if (!isOpen) {
            setCurrentEditRecord(null);
        }
    }, [isOpen])


    useEffect(() => {
        //calculando totales
        let t = 0.0;
        let lit = 0.0;
        let costosaux = [];
        let litrosaux = [];
        let totalcostos = 0.0;
        ingresos.forEach(element => {

            if (element.totalFloat) {
                // costosaux.push(element.totalFloat);
                let actual = parseFloat(element.totalFloat).toFixed(2);
                t = parseFloat(t) + parseFloat(actual);
            }
            if (element.costoFloat) {
                costosaux.push(element.costoFloat);
                let actual = parseFloat(element.costoFloat).toFixed(2);
                totalcostos = parseFloat(totalcostos) + parseFloat(actual);
            }
            if (element.cantidad) {
                litrosaux.push(parseFloat(element.cantidad).toFixed(2));
                let actual = parseFloat(element.cantidad).toFixed(2);
                lit = parseFloat(lit) + parseFloat(actual);
                //  console.log("litros de registro",lit);
            }
        });

        if (costosaux.length > 0) {
            let costoprom = parseFloat(totalcostos) / parseFloat(costosaux.length);
            costoprom = parseFloat(costoprom).toFixed(2);
            console.log("costo promedio", costoprom);
            setCostoPromedio(costoprom);
        }
        setLitrosTotales(parseFloat(lit).toFixed(2));

        setTotalMontosFloat(t);

    }, [ingresos])

    const refresh = () => {
        getData(user);
    }

    useEffect(() => {
        if (constoPromedio > 0 && litrostotales > 0) {
            let clitro = parseFloat(constoPromedio) * parseFloat(litrostotales);
            setCostoXLitro(clitro.toFixed(2));
        }
    }, [constoPromedio, litrostotales])


    const handleDateChanged = (date) => {
        let fDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);
        setCurrentDate(date);
    }

    const findRecord = (id) => {
        let obj = ingresos.find(element => element.id === id);
        return obj;
    }

    const getData = async (usertodisplay) => {
        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }
            // setIsLoading(true);
            let a = [];
            let index = 0;
            var moment = require('moment'); // require
            setIsLoading(true);
            if (iddeviaje) {
                console.log("getting combustibles de viaje", iddeviaje);
                getCombustiblesPorViaje(usertoconsult.uid, iddeviaje).then(snapshot => {
                    console.log("respuesta", snapshot.docs.length);
                    snapshot.forEach(doc => {
                        let o = doc.data();
                        o.index = doc.id;
                        o.n = index;
                        o.id = doc.id;
                        o.key = doc.id;
                        index++;
                        a.push(o);
                    });
                    setIngresos(a);
                    setIngresosNoFilter(a);
                    setIsLoading(false);
                }).catch(err => {
                    console.log(err);
                    Swal.fire("Error", "Error de al consultar combustibles, comprueba tu conexión ", 'error');
                    setIsLoading(false);
                });
                return;
            }
            getCombustibles(usertoconsult.uid).then(snapshot => {
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    o.id = doc.id;
                    o.key = doc.id;
                    index++;
                    a.push(o);
                });
                setIngresos(a);
                setIngresosNoFilter(a);
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar combustibles, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });
            /**
             * action getdata
             */
            /*await getAllIngresosByUserDateFilter(usertoconsult.uid, firstDay, lastDay).then(snapshot => {
                            snapshot.forEach(doc => {
                                let o = doc.data();
                                o.index = doc.id;
                                o.n = index;
                                o.id = doc.id;
                                o.key = doc.id;
                                o.fechaformat = moment(o.fecha.toDate()).format('DD/MM/YYYY');
                                index++;
                                a.push(o);
                            });
                            setIngresos(a);
                            setIngresosNoFilter(a);
                            setIsLoading(false);
                        }).catch(err => {
                            console.log(err);
                            Swal.fire("Error", "Error de al consultar los ingresos, comprueba tu conexión ", 'error');
                            setIsLoading(false);
                        });*/
        } else {
            //console.log("user null");
        }
    }

    useEffect(() => {
        if (currenteditrecrod != null) {
            if (currenteditrecrod.none) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
            }
            setIsOpen(true);
        }
    }, [currenteditrecrod])

    const handleCreate = () => {
        setIsEdit(false);
        setCurrentEditRecord({ none: true });
        //setIsOpen(true);
    }
    const handleEditFromId = id => {
        let obj = ingresos.find(element => element.id === id);
        if (obj) {
            console.log("editando", obj);
            setIsEdit(true);
            setCurrentEditRecord(obj);

        }
    }
    const handleDeleteFromId = id => {
        let obj = ingresos.find(element => element.id === id);
        if (obj) {
            Swal.fire({
                title: '¿Esta seguro de eliminar el gasto de combustible seleccionado?',
                text: "Una vez eliminada no podrá ser recuperado.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1c666f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    deleteCombustible(id).then(() => {
                        refresh();
                        Swal.fire('Éxito', 'Se ha eliminado con éxito.', 'success');
                    }).catch(err => {
                        Swal.fire("Error", "Error de al eliminar el gasto de combustible revisa tu conexión", 'error');

                    });
                }

            });
        }
    }


    return (
        <Fragment>
            {
                <div className="row">
                    <div className="col-12">
                        <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>&nbsp;Gasto de combustible.</h3>
                        <div className="card">
                            <div className="card-header card-header-flex">

                                <div className="d-flex px-3 row justify-content-between align-items-center">
                                <div style={{height:'46px',padding:'5px'}}>
                                    <div className="col-auto pl-0" style={{ display: 'flex' }}>
                                        <button type="button" style={{ marginRight: '10px' }} className="boton primBtn add ms-right" onClick={() => handleCreate()}><span>Agregar Gasto de Combustible</span></button>
                                        {
                                            !isLoading
                                        }
                                        {
                                            !isLoading && ingresos.length > 0 && <Download data={
                                                ingresos.map(element => {
                                                    /**
                                                     *  <HotColumn className="htCenter htMiddle" readOnly title="Unidad" data="unidad.nombre" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Fecha" data="fechaCarga" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Factura" data="factura" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Cantidad" data="cantidad" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Costo" data="costo" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Total" data="total" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Km anterior" data="ultimoKM" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Km actual" data="nuevoKM" />
                                                     */
                                                    return {
                                                        unidad: element.unidad.nombre||"",
                                                        fecha: element.fechaCarga||"",
                                                        cantidad: element.cantidad||"",
                                                        factura: element.factura||"",
                                                        costo: element.costo||"",
                                                        total: element.total||"",
                                                        kmanterior: element.ultimoKM||"",
                                                        kmactual: element.nuevoKM||"",
                                                       
                                                    }
                                                })
                                            } className="btn btn-primary btn-sm" />
                                        }
                                        {/*<TextField
                                            style={{ height: '20px' }}
                                            variant="outlined"
                                            size="small"
                                            id="input-with-icon-textfield"
                                            label="Buscar"
                                            value={querysearch}
                                            onChange={(e) => setQuerysearch(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />*/}

                                    </div>
                                    </div>
                                    <div>
                                        {/*<span style={{ color: 'black', marginTop: '10px' }}>Total litros:</span><span >{litrostotales}</span>
                                        <span style={{ color: 'black', marginTop: '10px' }}>&nbsp;&nbsp;&nbsp;Total:</span><span className="currencyrecordmin">${parseFloat(totalMontosFloat).toFixed(2)}</span>
                                        <span style={{ color: 'black', marginTop: '10px' }}>&nbsp;&nbsp;&nbsp;Costo promedio:</span><span className="currencyrecordmin">${constoPromedio}</span>
                                        <span style={{ color: 'black', marginTop: '10px' }}>&nbsp;&nbsp;&nbsp;Costo promedio x litro:</span><span className="currencyrecordmin">${costoxlitro}</span>
                                    */}
                                    </div>
                                    <div>
                                        {
                                            /*isAdmin ? <div>

                                                <select className="form-control" name='adminCurrentUser' onChange={(e) => setAdminCurrentUser(e.target.value)} value={adminCurrentUser} >
                                                    {
                                                        userlist.map((item, index) => {
                                                            return <option key={index} value={item.uid}>{item.email}</option>

                                                        })
                                                    }
                                                </select>
                                            </div> : <div></div>*/
                                        }
                                        <DatePicker
                                            className="form-control customdatepicker"
                                            locale="es"
                                            selected={currentDate}
                                            onChange={date => handleDateChanged(date)}
                                            maxDate={addDays(new Date(), 1)}
                                            dateFormat="MMMM yyyy"
                                            showMonthYearPicker
                                        />
                                    </div>

                                </div>
                            </div>
                            <LoadingOverlay
                                active={isLoading}
                                spinner
                                text='Actualizando datos, espere ...'
                            >
                                <div className="card-body">
                                    <HotTable settings={{
                                        colHeaders: true,
                                        rowHeaders: true,
                                        autoRowSize: false,
                                        columnSorting: true,
                                        dropdownMenu: ['filter_by_condition',
                                            'filter_by_value',
                                            'filter_action_bar'],
                                        filters: true,
                                        manualColumnResize: true,
                                        colWidths: [180, 144, 175, 100, 100, 100, 100, 100, 80],
                                        //para ancho total                                    
                                        //  minSpareRows: 1,          // almenos una fila
                                        // autoColumnSize: true,     // 
                                        //  stretchH: 'all',          // 
                                        //disableVisualSelection: true,
                                        licenseKey: "non-commercial-and-evaluation",
                                        language: "es-MX"
                                    }} data={ingresos} colHeaders={true} rowHeaders={false} width="100%" height="400" >


                                        <HotColumn className="htCenter htMiddle" readOnly title="Unidad" data="unidad.nombre" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Fecha" data="fechaCarga" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Factura" data="factura" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Cantidad" data="cantidad" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Costo" data="costo" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Total" data="total" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Km anterior" data="ultimoKM" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Km actual" data="nuevoKM" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Acciones" data="id"  >
                                            <ActionRender hot-renderer handleEditFromId={handleEditFromId} handleDeleteFromId={handleDeleteFromId} />
                                        </HotColumn>

                                    </HotTable>


                                </div>


                            </LoadingOverlay>
                        </div>
                    </div>
                    <ModalCreateEdit
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrod}
                        currentDate={currentDate}
                        isEdit={isEdit}
                        isOpen={isOpen}
                        uid={uid ? uid : null}
                        iddeviaje={iddeviaje || null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={refresh}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setIsOpen(false)} />
                </div>
            }
        </Fragment>
    );
}
export default ActivoContent;