import React, { useState, useEffect } from 'react';
import { firebase } from '../../../configs/firebase';
import { getUserByUid, getAllUsuarios } from '../../../controllers/usuarios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import { HotTable, HotColumn } from '@handsontable/react';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { registerLanguageDictionary } from 'handsontable/i18n';
import esMX from 'handsontable/i18n/languages/es-MX';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ModalCreateEdit from '../ModalCreateEdit';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

//import { ActionRender, DateRender, MoneyRender } from './ActionsRender';
//import Download from './ExcelExport';
import Ubicaciones from '../../ubicaciones/content';
import Operadores from '../../operadores/content';
import Combustibles from '../../combustible/content';
import Tabulador from '../../tabuladoroperadores/content';
import Tarifario from '../..//tarifario/content';
import Clientes from '../../clientes/content';
import Reportes from '../../reportes/content';
import { ActionRender, EstatusRender } from './ActionsRender';
import { getActivos, deleteActivo } from '../../../controllers/activos';
import Download from './ExcelExport';
import { getUidMaster } from '../../../session/session_controller';

const AlertaDenegado = () => {
    return <div>
        <ul class="list-group">
            <li class="list-group-item list-group-item-danger" style={{}}>Acceso denegado</li>
        </ul>
    </div>
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'} variant={'body2'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
    },
    paperrootfull: {
        width: '100%',
        margin: '5px',
        padding: '30px',
        display: 'flex'
    },

    paperroot: {
        width: '33%',
        display: 'flex'
    },
    paper: {
        minHeight: 80,
        height: 'auto',
        width: '100%',
    },
    paperscontainer: {
        // width: '100%'
        //overflow: 'hidden'
        flex: 1
    },
    title: {
        height: 30,
        backgroundColor: '#dcdcdc',
        paddingLeft: 10,
        paddingTop: 5,
    },
}));

const ActivoContent = (props) => {

    registerLanguageDictionary(esMX);
    registerLocale('es', es)
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [firstDay, setFirstDay] = useState(null);
    const [lastDay, setLastDay] = useState(null);
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [querysearch, setQuerysearch] = useState('');
    const [ingresos, setIngresos] = useState([]);
    const [ingresosNoFilter, setIngresosNoFilter] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currenteditrecrod, setCurrentEditRecord] = useState(null);
    const [total, setTotal] = useState(0.0);
    const [totalMask, setTotalMask] = useState('');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [denys, setDenys] = useState([]);

    const validateAdmin = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (user.type && user.type === "administrador") {
                    isadmin = true;
                }
            });
            if (isadmin) {
                setAdminCurrentUser(uid);
                setIsAdmin(true);
            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                setIsAdmin(false);
                setUserlist([]);
                // this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }

    useEffect(() => {
        if (isAdmin) {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                setUserlist(a);
            }).catch(e => {
                console.log(e);
                //this.setState({isLoading:false,})
            });
        }
    }, [isAdmin]);

    useEffect(() => {
        setTotalMask(total.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
    }, [total]);

    useEffect(() => {


        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);

        if (localStorage.getItem('deny')) {
            let auxdeny = localStorage.getItem('deny').split(":");
            setDenys(auxdeny);
        }

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {

                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);

                setUser(userr);

                // setAdminCurrentUser(userr.uid);

                if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                    // this.validateAdmin(user.uid);
                    //console.log("validando admin");
                    validateAdmin(userr.uid);
                }
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });

    }, []);


    useEffect(() => {
        if (querysearch.trim() === '') {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                aux.push(element);
            });
            setIngresos(aux);
        } else {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                let query = querysearch.trim().toUpperCase();
                if (element.numEconomico.toUpperCase().includes(query) ||
                    element.marca.toUpperCase().includes(query) ||
                    element.placas.toUpperCase().includes(query) ||
                    element.tipo.toUpperCase().includes(query) ||
                    element.year.includes(query) ||
                    element.descripcion.toUpperCase().includes(query)) {
                    aux.push(element);
                }

            });
            setIngresos(aux);
        }
    }, [querysearch]);

    useEffect(() => {
        getData(user);
    }, [user, currentDate, adminCurrentUser]);

    useEffect(() => {
        if (!isOpen) {
            setCurrentEditRecord(null);
        }
    }, [isOpen])


    useEffect(() => {
        //calculando totales
        let t = 0.0;
        ingresos.forEach(element => {
            if (element.montoFloat) {
                t += element.montoFloat;
            }
        });
        setTotal(t);
    }, [ingresos])

    const refresh = () => {
        getData(user);
    }



    const handleDateChanged = (date) => {
        let fDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);
        setCurrentDate(date);
    }

    const findRecord = (id) => {
        let obj = ingresos.find(element => element.id === id);
        return obj;
    }

    const getData = async (usertodisplay) => {
        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }
            // setIsLoading(true);
            let a = [];
            let index = 0;
            var moment = require('moment'); // require
            setIsLoading(true);
            getActivos(usertoconsult.uid).then(snapshot => {
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    o.id = doc.id;
                    o.key = doc.id;
                    index++;
                    a.push(o);
                });
                setIngresos(a);
                setIngresosNoFilter(a);
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar las ubicaciones, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });
            /**
             * action getdata
             */
            /*await getAllIngresosByUserDateFilter(usertoconsult.uid, firstDay, lastDay).then(snapshot => {
                            snapshot.forEach(doc => {
                                let o = doc.data();
                                o.index = doc.id;
                                o.n = index;
                                o.id = doc.id;
                                o.key = doc.id;
                                o.fechaformat = moment(o.fecha.toDate()).format('DD/MM/YYYY');
                                index++;
                                a.push(o);
                            });
                            setIngresos(a);
                            setIngresosNoFilter(a);
                            setIsLoading(false);
                        }).catch(err => {
                            console.log(err);
                            Swal.fire("Error", "Error de al consultar los ingresos, comprueba tu conexión ", 'error');
                            setIsLoading(false);
                        });*/
        } else {
            //console.log("user null");
        }
    }

    useEffect(() => {
        if (currenteditrecrod != null) {
            if (currenteditrecrod.none) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
            }
            setIsOpen(true);
        }
    }, [currenteditrecrod])

    const handleCreate = () => {
        setIsEdit(false);
        setCurrentEditRecord({ none: true });
        //setIsOpen(true);
    }
    const handleEditFromId = id => {
        let obj = ingresos.find(element => element.id === id);
        if (obj) {
            setIsEdit(true);
            setCurrentEditRecord(obj);

        }
    }
    const handleDeleteFromId = id => {
        let obj = ingresos.find(element => element.id === id);
        if (obj) {
            Swal.fire({
                title: '¿Esta seguro de eliminar el ingreso seleccionado?',
                text: "Una vez eliminada no podrá ser recuperado.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1c666f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    deleteActivo(id).then(() => {
                        refresh();
                        Swal.fire('Éxito', 'Se ha eliminado con éxito.', 'success');
                    }).catch(err => {
                        Swal.fire("Error", "Error de al eliminar el activo comprueba tu conexión", 'error');

                    });
                }

            });
        }
    }


    return (

        <div className="card-body">
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs
                        value={value}
                        indicatorColor="tabindicator"
                        textColor="tabtextcolor"
                        onChange={handleChange}
                        aria-label="simple tabs example">
                        <Tab label="Activos" {...a11yProps(0)} />
                        <Tab label="Ubicaciones" {...a11yProps(1)} />
                        <Tab label="Operadores" {...a11yProps(2)} />
                        <Tab label="Tabulador Operadores" {...a11yProps(3)} />
                        <Tab label="Tarifario" {...a11yProps(4)} />
                        <Tab label="Gasto de combustible" {...a11yProps(5)} />
                        <Tab label="Clientes" {...a11yProps(6)} />
                        <Tab label="Reportes" {...a11yProps(7)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                {!denys.find(e => e === "activoss") ?
                    <div style={{ paddingRight: 25, paddingLeft: 24 }}>
                        {
                            <div className="row">
                                <div className="col-12">
                                    <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>&nbsp;Activos</h3>
                                    <div className="card">
                                        <div className="card-header card-header-flex">

                                            <div className="d-flex px-3 row justify-content-between align-items-center">
                                                <div style={{ height: '46px', padding: '5px' }}>
                                                    <div className="col-auto pl-0" style={{ display: 'flex' }}>

                                                        <button type="button" style={{ marginRight: '10px' }} className="boton primBtn add ms-right" onClick={() => handleCreate()}><span>Agregar Activo</span></button>
                                                        {
                                                            !isLoading && ingresos.length > 0 && <Download data={
                                                                ingresos.map(element => {
                                                                    return {
                                                                        numEconomico: element.numEconomico,
                                                                        propiedad: element.propiedad,
                                                                        tipo: element.tipo,
                                                                        placas: element.placas,
                                                                        placasUsa: element.placasUsa,
                                                                        marca: element.marca,
                                                                        year: element.year,
                                                                        noSerie: element.noSerie,
                                                                        aseguradora: element.aseguradora,
                                                                        poliza: element.poliza,
                                                                        descripcion: element.descripcion,
                                                                        status: element.active ? "Activo" : "Inactivo"

                                                                    }
                                                                })
                                                            } className="btn btn-primary btn-sm" />
                                                        }
                                                        <TextField
                                                            style={{ marginTop: '3px', height: '20px' }}
                                                            variant="outlined"
                                                            size="small"
                                                            id="input-with-icon-textfield"
                                                            label="Buscar"
                                                            value={querysearch}
                                                            onChange={(e) => setQuerysearch(e.target.value)}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <SearchIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div>


                                                </div>
                                                <div>

                                                </div>

                                            </div>
                                        </div>
                                        <LoadingOverlay
                                            active={isLoading}
                                            spinner
                                            text='Actualizando datos, espere ...'
                                        >
                                            <div className="card-body">
                                                <HotTable settings={{
                                                    colHeaders: true,
                                                    rowHeaders: true,
                                                    autoRowSize: false,
                                                    columnSorting: true,
                                                    dropdownMenu: ['filter_by_condition',
                                                        'filter_by_value',
                                                        'filter_action_bar'],
                                                    filters: true,
                                                    manualColumnResize: true,
                                                    colWidths: [180, 110, 110, 110, 110, 110, 180, 110, 80],
                                                    //para ancho total                                    
                                                    //  minSpareRows: 1,          // almenos una fila
                                                    // autoColumnSize: true,     // 
                                                    //  stretchH: 'all',          // 
                                                    //disableVisualSelection: true,
                                                    licenseKey: "non-commercial-and-evaluation",
                                                    language: "es-MX"
                                                }} data={ingresos} colHeaders={true} rowHeaders={false} width="100%" height="400" >

                                                    <HotColumn className="htCenter htMiddle" readOnly title="No. Económico" data="numEconomico" />
                                                    <HotColumn className="htCenter htMiddle" readOnly title="Propiedad" data="propiedad" />
                                                    <HotColumn className="htCenter htMiddle" readOnly title="Tipo" data="tipo" />
                                                    <HotColumn className="htCenter htMiddle" readOnly title="Placas" data="placas" />
                                                    <HotColumn className="htCenter htMiddle" readOnly title="Marca" data="marca" />
                                                    <HotColumn className="htCenter htMiddle" readOnly title="Año" data="year" />
                                                    <HotColumn className="htCenter htMiddle" readOnly title="Descripción" data="descripcion" />
                                                    <HotColumn className="htCenter htMiddle" render readOnly title="Estatus" data="active" >
                                                        <EstatusRender hot-renderer></EstatusRender>
                                                    </HotColumn>
                                                    <HotColumn className="htCenter htMiddle" readOnly title="Acciones" data="id"  >
                                                        <ActionRender hot-renderer handleEditFromId={handleEditFromId} handleDeleteFromId={handleDeleteFromId} />
                                                    </HotColumn>

                                                </HotTable>

                                            </div>


                                        </LoadingOverlay>
                                    </div>
                                </div>
                                <ModalCreateEdit
                                    findRecord={findRecord}
                                    currenteditrecrod={currenteditrecrod}
                                    currentDate={currentDate}
                                    isEdit={isEdit}
                                    isOpen={isOpen}
                                    uid={uid ? uid : null}
                                    uidAltaEdit={uidAltaEdit}
                                    adminCurrentUser={adminCurrentUser || null}
                                    isAdmin
                                    notify={refresh}
                                    setLoading={value => setIsLoading(value)}
                                    toggle={() => setIsOpen(false)} />
                            </div>
                        }
                    </div>: <AlertaDenegado /> }
                </TabPanel>
                <TabPanel value={value} index={1}>
                {!denys.find(e => e === "ubicaciones") ? <Ubicaciones /> : <AlertaDenegado /> }
                </TabPanel>
                <TabPanel value={value} index={2}>
                   
                    {!denys.find(e => e === "operadores") ?  <Operadores /> : <AlertaDenegado /> }
                </TabPanel>
                <TabPanel value={value} index={3}>
                    {!denys.find(e => e === "tabulador") ? <Tabulador></Tabulador> : <AlertaDenegado /> }
                    
                </TabPanel>
                <TabPanel value={value} index={4}>
                    {!denys.find(e => e === "tarifario") ? <Tarifario /> : <AlertaDenegado /> }
                </TabPanel>
                <TabPanel value={value} index={5}>
                {!denys.find(e => e === "combustible") ? <Combustibles /> : <AlertaDenegado /> }
                    
                </TabPanel>
                <TabPanel value={value} index={6}>
                {!denys.find(e => e === "clientes") ? <Clientes /> : <AlertaDenegado /> }
                    
                </TabPanel>
                <TabPanel value={value} index={7}>
                {!denys.find(e => e === "reportes") ? <Reportes /> : <AlertaDenegado /> }
                    
                </TabPanel>

            </div>
        </div>

    );
    /**
     *   {!denys.find(e => e === "ubicaciones") &&<Tab label="Ubicaciones" {...a11yProps(1)} />}
                        {!denys.find(e => e === "operadores") &&<Tab label="Operadores" {...a11yProps(2)} />}
                        {!denys.find(e => e === "tabulador") &&<Tab label="Tabulador Operadores" {...a11yProps(3)} />}
                        {!denys.find(e => e === "tarifario") && <Tab label="Tarifario" {...a11yProps(4)} />}
                        {!denys.find(e => e === "combustible") &&<Tab label="Gasto de combustible" {...a11yProps(5)} />}
                        {!denys.find(e => e === "clientes") &&<Tab label="Clientes" {...a11yProps(6)} />}
                        {!denys.find(e => e === "reportes") &&<Tab label="Reportes" {...a11yProps(7)} />}
     */
}
export default ActivoContent;