import { useDrag } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import Swal from 'sweetalert2';

const style = {
    border: '1px dashed gray',
    backgroundColor: 'white',
    padding: '5px',
    margingLeft:"5px",
    marginRight: '2px',
    marginBottom: '2px',
    cursor: 'move',
    float: 'left',
};
export const BoxPreplan = function BoxPreplan({ handleDespachar, name, id, descrip }) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.BOXPREPLAN,
        item: { name, id, descrip },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                // console.log("destino",dropResult);
                //console.log("testing",dropResult.id.descrip)
                //  alert(`Tu asignaste la unidad ${item.id} en el viaje ${dropResult.id.id}!`);
                Swal.fire({
                    title: 'Pre-planear viaje a unidad',
                    text: `Deseas asignar el viaje ${item.descrip} como pre-plan a la unidad ${dropResult.id.descrip}.`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#1c666f',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.value) {
                        handleDespachar(dropResult.id.id, item.id);
                    }

                });
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }));
    const opacity = isDragging ? 0.4 : 1;
    return (<div ref={drag} role="Box" style={{ ...style,marginLeft:'5px', opacity }} data-testid={`box-${name}`}>
        {name}
    </div>);
};
