import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getUserByUid } from '../../../controllers/usuarios';
import { getClienteByID, updateConfiguracionCliente } from '../../../controllers/clientes';
import { getFacturaArchivos } from '../../../controllers/facturama';
import { getViajeById, getViajeRepo } from '../../../controllers/viajes';
import { getIngresoById } from '../../../controllers/ingresos';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import xml2js from 'xml2js';

import axios from 'axios';
import { getTabuladorOperadores } from '../../../controllers/tabuladoroperadores';


const ModalCreateEdit = (props) => {
    //constantes
    const conceptos = [
        { clave: "78101800", descripcion: "Transporte de carga por carretera" },
        { clave: "78101801", descripcion: "Servicios de transporte de carga por carretera (en camión) en área local" },
        { clave: "78101802", descripcion: "Servicios transporte de carga por carretera (en camión) a nivel regional y nacional" },
        { clave: "78101806", descripcion: "Servicios transporte de carga por carretera a nivel internacional" },
        { clave: "78101807", descripcion: "Servicios transporte de carga de petróleos o químicos" },
        { clave: "78141500", descripcion: "Movimiento de fletes" },
        { clave: "78141501", descripcion: "Coordinación de fletes" },

    ];
    const CAT_METODOSPAGO = [
        {
            "Name": "Pago en parcialidades ó diferido",
            "Value": "PPD"
        },
        {
            "Name": "Pago en una sola exhibición",
            "Value": "PUE"
        }
    ];
    const CAT_USOCFDI = [
        {
            "Natural": true,
            "Moral": false,
            "Name": "Honorarios médicos, dentales y gastos hospitalarios.",
            "Value": "D01"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos médicos por incapacidad o discapacidad",
            "Value": "D02"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos funerales.",
            "Value": "D03"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Donativos.",
            "Value": "D04"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
            "Value": "D05"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Aportaciones voluntarias al SAR.",
            "Value": "D06"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Primas por seguros de gastos médicos.",
            "Value": "D07"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos de transportación escolar obligatoria.",
            "Value": "D08"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
            "Value": "D09"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Pagos por servicios educativos (colegiaturas)",
            "Value": "D10"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Adquisición de mercancias",
            "Value": "G01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Devoluciones, descuentos o bonificaciones",
            "Value": "G02"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Gastos en general",
            "Value": "G03"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Construcciones",
            "Value": "I01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Mobilario y equipo de oficina por inversiones",
            "Value": "I02"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Equipo de transporte",
            "Value": "I03"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Equipo de computo y accesorios",
            "Value": "I04"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Dados, troqueles, moldes, matrices y herramental",
            "Value": "I05"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Comunicaciones telefónicas",
            "Value": "I06"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Comunicaciones satelitales",
            "Value": "I07"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Otra maquinaria y equipo",
            "Value": "I08"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Por definir",
            "Value": "P01"
        }
    ]
    const CAT_FORMASPAGO = [
        {
            "Name": "Efectivo",
            "Value": "01"
        },
        {
            "Name": "Cheque nominativo",
            "Value": "02"
        },
        {
            "Name": "Transferencia electrónica de fondos",
            "Value": "03"
        },
        {
            "Name": "Tarjeta de crédito",
            "Value": "04"
        },
        {
            "Name": "Monedero electrónico",
            "Value": "05"
        },
        {
            "Name": "Dinero electrónico",
            "Value": "06"
        },
        {
            "Name": "Vales de despensa",
            "Value": "08"
        },
        {
            "Name": "Dación en pago",
            "Value": "12"
        },
        {
            "Name": "Pago por subrogación",
            "Value": "13"
        },
        {
            "Name": "Pago por consignación",
            "Value": "14"
        },
        {
            "Name": "Condonación",
            "Value": "15"
        },
        {
            "Name": "Compensación",
            "Value": "17"
        },
        {
            "Name": "Novación",
            "Value": "23"
        },
        {
            "Name": "Confusión",
            "Value": "24"
        },
        {
            "Name": "Remisión de deuda",
            "Value": "25"
        },
        {
            "Name": "Prescripción o caducidad",
            "Value": "26"
        },
        {
            "Name": "A satisfacción del acreedor",
            "Value": "27"
        },
        {
            "Name": "Tarjeta de débito",
            "Value": "28"
        },
        {
            "Name": "Tarjeta de servicios",
            "Value": "29"
        },
        {
            "Name": "Aplicación de anticipos",
            "Value": "30"
        },
        {
            "Name": "Intermediarios",
            "Value": "31"
        },
        {
            "Name": "Por definir",
            "Value": "99"
        }
    ]
    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);
    const [idViaje, setIdViaje] = useState(null);

    const [chekingCSD, setCheckinCSD] = useState(true);

    const [nviaje, setNviaje] = useState("");
    const [ncarta, setNcarta] = useState("");
    const [cliente, setCliente] = useState("");
    const [clienteId, setClienteId] = useState(null);
    const [clienteData, setClietneData] = useState(null);
    const [isIVA16, setIsIVA16] = useState(true);
    const [facturaArchivos, setFacturaArchivos] = useState(null);
    const [isFacuturaCancelada, setIsFacturaCancelada] = useState(false);
    const [ivatasa, setIvaTasa] = useState("16");
    const [retencion, setRetencion] = useState("0");
    const [version, setVersion] = useState("4.0");
    const [razonesSociales, setRazonesSociales] = useState([]);
    const [rfc, setRfc] = useState("");

    const [usoCfdi, setUsoCfdi] = useState("P01 - Por definir");
    const [claveConcepto, setClaveConcepto] = useState("78101800");

    const [metodoPago, setMetodoPago] = useState("PUE - Pago en una sola exhibición");
    const [formaPago, setFormaPago] = useState("99 - Por definir");
    const [dolarprice, setDolarPrice] = useState(20.0);
    const [isDLL, setIsDLL] = useState(false);
    const [moneda, setMoneda] = useState("MXN");
    const [extras, setExtras] = useState([]);
    const [ntibmres, setNtimbres] = useState(0);
    const [traslado, setTraslado] = useState(false);

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);

    useEffect(() => {
        if (user && user.razones) {
            let razonesasignar = [];
            user.razones.forEach(r => {
                if (!r.inactivo) {
                    razonesasignar.push(r);
                }
            })
            setRazonesSociales(razonesasignar);

            if (razonesasignar.length > 0 && razonesasignar[0].Rfc) {
                setRfc(razonesasignar[0].Rfc);
            }
        }
    }, [user]);

    useEffect(() => {
        if (rfc && clienteData && clienteData.rfc && rfc === clienteData.rfc && recordedit && recordedit.isFormatoNuevo) {
            setTraslado(true);
        } else if (traslado) {
            setTraslado(false);
        }
    }, [rfc, clienteData])
    const checkCSD = async () => {
        console.log("cheking csd", uid);
        if (uid) {
            await getViajeRepo(uid).then(resp => {
                let nt = 0;
                resp.forEach(document => {
                    let o = document.data();
                    if (o && o.ntimbres && nt <= o.ntimbres) {
                        nt = o.ntimbres;
                    }
                });
                setNtimbres(nt);
            }).catch(err => { });
            getUserByUid(uid).then(resp => {
                let obuser = null;
                resp.forEach(document => {
                    obuser = { ...document.data(), id: document.id }

                });
                setUser(obuser);
                setCheckinCSD(false);
                //console.log("datos usuario",resp.docs[0].data());
                if (resp.docs.length > 0 && resp.docs[0].data().csd) {
                    initValues();
                } else {
                    Swal.fire("No CSD", "No se han cargado los CSD para esta cuenta, da de alta primero tus credenciales de facturacion en la seccion de dashboard -> configuraciones ", 'warning');
                    setIsLoading(false);
                    setIsOpen(false);
                }
            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar CSD, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });
        } else {
            Swal.fire("Error", "Error sesion terminada", 'error');
            setIsLoading(false);
            setIsOpen(false);
        }

    }

    useEffect(() => {
        if (clienteId) {
            getClienteByID(clienteId).then(resp => {
                if (resp.data()) {
                    setClietneData(resp.data());


                }
            }).catch(err => { });
        }
    }, [clienteId]);

    const initValues = async () => {
        console.log("recordedit", props);
        console.log("init values", idViaje);
        if (idViaje != null) {

            await getIngresoById(idViaje).then(document => {
                console.log("llegaron datos");


                let o = document.data();
                o.id = document.id;
                o.key = document.id;
                setRecordEdit(o);
                setIsLoading(false);
            }).catch(err => { console.log(err); setIsLoading(false); });
        }
    }

    useEffect(() => {

        // console.log("recordedit heree",recordedit);
        if (recordedit != null) {
            //facuturado
            if (recordedit.complementos && recordedit.complementos.length>0) {
                setFacturaArchivos(recordedit.complementos[0]);
            } else {
                setFacturaArchivos(null);
            }
            if (recordedit.facturaCancelada === true) {
                // console.log("is cancelada factura",recordedit.facturaCancelada);
                setIsFacturaCancelada(true);
            } else {
                setIsFacturaCancelada(false);
            }

        } else {

        }
    }, [recordedit]);

    const changeRetencionExtra = (event, index) => {
        //
        let newArr = [...extras];

        if (index >= 0) {
            newArr[index].retencion = event.target.value;
            setExtras(newArr);
        }

    }

    useEffect(() => {

        setCheckinCSD(true);
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);

        //  initValues();

        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        // setRecordEdit(props.currenteditrecrod);
        if (props.currenteditrecrod) {
            if (props.currenteditrecrod.notacredito) {

            } else {
                setIdViaje(props.currenteditrecrod.key);
            }

        }
        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (idViaje) {

            initValues();
        }

    }, [idViaje]);




    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            checkCSD();
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    const validaCCP = async () => {
        const errores = [];
        if (recordedit) {
            if (!recordedit.carta_porte.distBase_km || recordedit.carta_porte.distBase_km === "" || recordedit.carta_porte.distBase_km == 0) {
                errores.push("Falta registrar los Kilómetros del viaje");
            }


            if (errores.length > 0) {
                let errstrings = errores.join(",");
                Swal.fire("Viaje no valido", `Faltan datos del viaje: ${errstrings}`, 'warning');
                return false;
            }

        } else {
            Swal.fire("Viaje no valido", `No se encontraron datos del viaje`, 'warning');
            return false;
        }
        return true;
    }
    const actualizaConfiguracion = async () => {
        if (clienteId) {
            let nuevaConfiguracion = {
                usoCfdi,
                formaPago,
                metodoPago,
                claveConcepto
            }
            await updateConfiguracionCliente(clienteId, nuevaConfiguracion).then(resp => { console.log("configuracion actualizada") }).catch(err => { console.log(err) });
        }

    }
    const handleSumbit = async (e) => {
        e.preventDefault();
        if (ntibmres <= 0) {
            Swal.fire("No se puede timbrar", "No hay timbres disponibles", 'warning');
            return;
        }
        if (!facturaArchivos && recordedit && recordedit.key) {

            setIsLoading(true);
            let ivarate = 0.16;
            let retencionrate = 0.0;
            /*if (isIVA16) {
                ivarate = 0.16;
            } else {
                ivarate = 0.08;
            }*/
            if (ivatasa == "16") {
                ivarate = 0.16;
            } else if (ivatasa == "8") {
                ivarate = 0.08;
            } else {
                ivarate = 0.0;
            }

            if (retencion === "4") {
                retencionrate = 0.04;
            } else {
                retencionrate = 0.0;
            }

            let rutatipofactura = "fpt";
            if (!recordedit.isFormatoNuevo) {
                rutatipofactura = "fptcfdiingreso";
            } else {
                let valido = await validaCCP(recordedit.key);
                if (!valido) {
                    return;
                }
            }
            //https://us-central1-transport-plus-98f66.cloudfunctions.net/app
            //http://localhost:5000/transport-plus-98f66/us-central1/app

            //https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/
            //http://localhost:5000/unit-testing-d4fe7/us-central1/app/api/
            let rutaPost = "https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/";
            if (rfc === "EKU9003173C9") {
                rutaPost = "https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/";
            }

            // rutaPost="http://localhost:5000/unit-testing-d4fe7/us-central1/app/api/";

            console.log("data a post", {
                id: recordedit.key,
                rfc,
                ivar: ivarate,
                retencionrate,
                metodoPago,
                claveConcepto,
                extras,
                moneda,
                formaPago,
                usoCfdi,
                traslado,
                version,
                dolarprice
            });

            if (!traslado && clienteData && !clienteData.configuracionCFDI) {
                await actualizaConfiguracion();
            } else if (!traslado && clienteData && clienteData.configuracionCFDI) {
                //checamos si hay que actualizar
                if (clienteData.configuracionCFDI.usoCfdi !== usoCfdi ||
                    clienteData.configuracionCFDI.formaPago !== formaPago ||
                    clienteData.configuracionCFDI.metodoPago !== metodoPago ||
                    clienteData.configuracionCFDI.claveConcepto !== claveConcepto){
                        await actualizaConfiguracion();
                    }
            }
            // return;
            await axios.post(`${rutaPost}${rutatipofactura}`,
                {
                    id: recordedit.key,
                    rfc,
                    ivar: ivarate,
                    retencionrate,
                    metodoPago,
                    claveConcepto,
                    extras,
                    moneda,
                    formaPago,
                    usoCfdi,
                    version,
                    traslado,
                    dolarprice
                }).then(resp => {
                    setIsLoading(false);
                    props.notify(resp.data);
                    props.toggle();

                    if (resp.data == "Ok") {
                        Swal.fire("Factura generada", `Se ha generado la factura `, 'success');
                    } else {
                        console.log("resp.data", resp.data);

                        if (resp.data && resp.data.estatus && resp.data.estatus.informacionTecnica) {
                            Swal.fire("No se pudo generar", `${resp.data.estatus.informacionTecnica}`, 'warning');
                        } else {
                            if (typeof resp.data === "object") {
                                const myJSON = JSON.stringify(resp.data);
                                Swal.fire("No se pudo generar", `${myJSON}`, 'warning');
                            } else {
                                Swal.fire("No se pudo generar", `${resp.data}`, 'warning');
                            }

                        }

                    }

                    //console.log("respuesta", resp);


                }).catch(err => {
                    setIsLoading(false);
                    console.log("error", err.toString());
                    if (err.toString().includes("Network Error")) {
                        Swal.fire("Error", "Error de conexión, no fue posible conectar con el servidor, revisa tu conexión", 'error');
                    } else {
                        Swal.fire("Error", "No se pudo generar la factura", 'error');
                    }
                });
        }
    }
    const downloadPDF = (pdf) => {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        let nombrefactura = "";
        if (ncarta) {
            nombrefactura = ncarta || "";
        }
        const fileName = `factura${nombrefactura}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    const downloadXML = (pdf) => {
        const linkSource = `data:application/xml;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        let nombrefactura = "";
        if (ncarta) {
            nombrefactura = ncarta || "";
        }
        const fileName = `factura${nombrefactura}.xml`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    const verComplementoCartaPorte = async () => {
        if (recordedit && recordedit.isFormatoNuevo) {
            let datatimbrado = await asyncparser(facturaArchivos);
            console.log("datatimbrado", datatimbrado);
            let rfctimbrado = null;
            let rfcReceptor = null;
            if (datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc']) {
                rfctimbrado = datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc'];
                rfcReceptor = datatimbrado['cfdi:Comprobante']['cfdi:Receptor'][0]['$']['Rfc'];
            }
            if (rfctimbrado) {
                let dates = new Date();
                if (recordedit.dataTimbrada) {
                    if (rfctimbrado !== rfcReceptor) {
                        const win = window.open('/ccpv2/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
                        win.focus();
                    } else {
                        const win = window.open('/ccptraslado/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
                        win.focus();
                    }

                } else {
                    const win = window.open('/ccp/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
                    win.focus();
                }

            }
        } else if (recordedit) {
            let datatimbrado = await asyncparser(facturaArchivos);
            console.log("datatimbrado", datatimbrado);
            let rfctimbrado = null;
            if (datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc']) {
                rfctimbrado = datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc'];
            }
            if (rfctimbrado) {

                if(recordedit.cfdiversion && recordedit.cfdiversion==="4.0"){
                    let dates = new Date();
                    const win = window.open('/complementopago20/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
                    win.focus();
                }else{
                    let dates = new Date();
                    const win = window.open('/complementopago/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
                    win.focus();
                }
               
            }

            //descargarFacturaPDF();
        }

        // return;
        /* if (!facturaArchivos && recordedit && recordedit.key) {
             let dates = new Date();
             const win = window.open('/ccp/' + recordedit.key + "?rfc=" + rfc + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
             win.focus();
         } else if (facturaArchivos) {
             let dates = new Date();
             const win = window.open('/ccp/' + recordedit.key + "?rfc=" + facturaArchivos.rfc + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
             win.focus();
         }else{
             //alert("here");
         }*/
    }
    const asyncparser = async (obj) => {
        if (obj) {
            let xmlstring = obj.cfdixml;
            //let fixedXml=xmlstring.replace('cfdi:', '');
            let fixedXml = xmlstring.split('cfdi:').join('');
            fixedXml = xmlstring.split('$').join('obj');

            let response = null;
            await xml2js.parseString(fixedXml, async (err, result) => {
                if (err) {
                    console.log(err);
                }
                else {
                    // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                    // let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                    console.log("primer xml", result);
                    response = result;
                    // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                }

            });

            // console.log("response xml", response);
            return response;
        } else {
            return null;
        }

    }

    const descargarFacturaPDF = () => {
        if (facturaArchivos) {
            downloadPDF(facturaArchivos.pdf);
            /*getFacturaArchivos(facturaArchivos).then(docu => {
                downloadPDF(docu.data().pdf);
            }).catch(err => { });*/
        }
    }
    const descargarFacturaXML = () => {
        if (facturaArchivos) {
            var xmltext = facturaArchivos.cfdixml;

            let nombrefactura = "";
            if (ncarta) {
                nombrefactura = ncarta || "";
            }
            var filename = `factura${nombrefactura}.xml`;
            var pom = document.createElement('a');
            var bb = new Blob([xmltext], { type: 'text/plain' });

            pom.setAttribute('href', window.URL.createObjectURL(bb));
            pom.setAttribute('download', filename);

            pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
            pom.draggable = true;
            pom.classList.add('dragout');

            pom.click();
            /*
            getFacturaArchivos(facturaArchivos).then(docu => {
                downloadXML(docu.data().xml);
            }).catch(err => { });*/
        }
    }
    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {facturaArchivos ? "Descargar Factura" : `Facturar viaje `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <LoadingOverlay
                        active={chekingCSD}
                        spinner
                        text='Comprobando CSD, espere ...'
                    >
                        {
                            isFacuturaCancelada && <div>
                                Factura cancelada, generar nuevo CFDI..
                            </div>
                        }
                        {
                            (facturaArchivos) ? <div>
                                <div className="row">
                                    <div style={{ paddingRight: 20, paddingLeft: 20 }} className="col-6">
                                        <div onClick={() => verComplementoCartaPorte()} className="btn btn-primary col-12"> Descargar PDF</div>
                                    </div>
                                    <div style={{ paddingRight: 20, paddingLeft: 20 }} className="col-6">
                                        <div onClick={() => descargarFacturaXML()} className="btn btn-primary col-12"> Descargar XML</div>
                                    </div>
                                </div>
                            </div> : <div>
                                <div className='row'>
                                    <div className="form-group col-3">
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Versión CFDI</FormLabel>
                                            <RadioGroup row aria-label="gender" name="gender1" value={version} onChange={e => setVersion(e.target.value)}>
                                                {/*<FormControlLabel value="3.3" control={<Radio />} label="3.3" />*/}
                                                <FormControlLabel value="4.0" control={<Radio />} label="4.0" />

                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-3">
                                        <label>No. Viaje</label><label className="requiredlabel">*</label>
                                        <input readOnly type="text" name='nviaje' className="form-control" value={nviaje} onChange={e => setNviaje(e.target.value)} />
                                    </div>
                                    <div className="form-group col-3">
                                        <label>No. Carta Porte</label><label className="requiredlabel">*</label>
                                        <input readOnly type="text" name='ncarta' className="form-control" value={ncarta} onChange={e => setNcarta(e.target.value)} />
                                    </div>
                                    <div className="form-group col-3">
                                        <label>Cliente</label><label className="requiredlabel">*</label>
                                        <input readOnly type="text" name='cliente' className="form-control" value={cliente} onChange={e => setCliente(e.target.value)} />
                                    </div>
                                    <div className="form-group col-3">
                                        <label>RFC Cliente</label><label className="requiredlabel">*</label>
                                        <input readOnly type="text" name='cliente' className="form-control" value={clienteData ? clienteData.rfc : ""} onChange={e => console.log("")} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-4">
                                        <label>Uso CFDI</label><label className="requiredlabel">*</label>
                                        <select className={"form-control "} name='rfc' onChange={(e) => setUsoCfdi(e.target.value)} value={usoCfdi} required >
                                            {
                                                CAT_USOCFDI.map((item, index) => {
                                                    return <option key={index} value={`${item.Value} - ${item.Name}`} >{`${item.Value} - ${item.Name}`}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    {!traslado && <div className="form-group col-4">
                                        <label>Método de Pago</label><label className="requiredlabel">*</label>
                                        <select className={"form-control "} name='rfc' onChange={(e) => setMetodoPago(e.target.value)} value={metodoPago} required >
                                            {
                                                CAT_METODOSPAGO.map((item, index) => {
                                                    return <option key={index} value={`${item.Value} - ${item.Name}`} >{`${item.Value} - ${item.Name}`}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    }
                                    {!traslado && <div className="form-group col-4">
                                        <label>Forma de Pago</label><label className="requiredlabel">*</label>
                                        <select className={"form-control "} name='rfc' onChange={(e) => setFormaPago(e.target.value)} value={formaPago} required >
                                            {
                                                CAT_FORMASPAGO.map((item, index) => {
                                                    return <option key={index} value={`${item.Value} - ${item.Name}`} >{`${item.Value} - ${item.Name}`}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    }
                                </div>

                                {recordedit && <div className="row">
                                    <div className="form-group col-12">
                                        <label>Concepto</label><label className="requiredlabel">*</label>
                                        <select className={"form-control "} name='rfc' onChange={(e) => setClaveConcepto(e.target.value)} value={claveConcepto} required >
                                            {
                                                conceptos.map((item, index) => {
                                                    return <option key={index} value={`${item.clave}`} >{`${item.clave} - ${item.descripcion}`}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>}
                                {isDLL && !traslado && <div className="row">
                                    <div className="form-group col-3">
                                        <label>DLL. tipo de cambio</label><label className="requiredlabel">*</label>
                                        <input type="number" name='cliente' className="form-control" value={dolarprice} onChange={e => setDolarPrice(e.target.value)} />
                                    </div>
                                    <div className="form-group col-3">
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Moneda</FormLabel>
                                            <RadioGroup row aria-label="gender" name="gender1" value={moneda} onChange={e => setMoneda(e.target.value)}>
                                                <FormControlLabel value="MXN" control={<Radio />} label="MXN" />
                                                <FormControlLabel value="DLL" control={<Radio />} label="DLL" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>}
                                <div className="row" style={{ marginLeft: '10px' }}>

                                    {traslado && <div className="form-group col-3">
                                        <label>CFDI</label>
                                        <input type="text" disabled name='cliente' className="form-control" value={"TRASLADO"} onChange={e => console.log(e.target.value)} />
                                    </div>}

                                    {!traslado && <FormControl component="fieldset">
                                        <FormLabel component="legend">IVA</FormLabel>
                                        <RadioGroup row aria-label="gender" name="gender1" value={ivatasa} onChange={e => setIvaTasa(e.target.value)}>
                                            <FormControlLabel value="16" control={<Radio />} label="16%" />
                                            <FormControlLabel value="8" control={<Radio />} label="8%" />
                                            <FormControlLabel value="0" control={<Radio />} label="Tasa 0" />

                                        </RadioGroup>
                                    </FormControl>}


                                    {
                                        razonesSociales.length > 1 && <div className="form-group col-3">
                                            <label>Razón Social</label><label className="requiredlabel">*</label>
                                            <select className={"form-control "} name='rfc' onChange={(e) => setRfc(e.target.value)} value={rfc} required >
                                                {
                                                    razonesSociales.map((item, index) => {
                                                        return <option key={index} value={item.Rfc}>{item.Razon}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    }
                                    {!traslado && <FormControl component="fieldset">
                                        <FormLabel component="legend">Retención</FormLabel>
                                        <RadioGroup row aria-label="gender" name="gender1" value={retencion} onChange={e => setRetencion(e.target.value)}>
                                            <FormControlLabel value="4" control={<Radio />} label="4%" />
                                            <FormControlLabel value="0" control={<Radio />} label="0" />

                                        </RadioGroup>
                                    </FormControl>}

                                    {/* <label> <Typography component="div">
                                            <Grid component="label" container alignItems="center" spacing={1} style={{ margin: '1px' }}>
                                                <Grid item style={{ fontSize: '12px' }}>IVA 8%</Grid>
                                                <Grid item>
                                                    <AntSwitch checked={isIVA16} onChange={() => setIsIVA16(!isIVA16)} name="checkedCisfull" />
                                                </Grid>
                                                <Grid item style={{ fontSize: '12px' }}>16%</Grid>
                                            </Grid>
                                        </Typography></label>
                                        <label for="inputfecha">&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        */}

                                </div>
                                <div style={{ marginLeft: '10px' }}>
                                    {
                                        extras.map((extra, index) => {
                                            return <div className='row'>
                                                <div className="form-group col-4">
                                                    <label>Concepto</label>
                                                    <input type='text' disabled className='form-control' value={`${extra.clave} ${extra.descricpion}`} />
                                                </div>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Retención</FormLabel>
                                                    <RadioGroup row aria-label="gender" name="gender1" value={extra.retencion} onChange={e => changeRetencionExtra(e, index)}>
                                                        <FormControlLabel value="0.04" control={<Radio />} label="4%" />
                                                        <FormControlLabel value="0" control={<Radio />} label="0" />

                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </LoadingOverlay>
                </ModalBody>
                <ModalFooter>
                    {!facturaArchivos && !traslado && <div style={{ marginRight: 'auto' }}> <button type="button" className="boton secBtn" onClick={() => {
                        const win = window.open('/ccpreview/' + recordedit.key + "?rfc=" + rfc + "&mp=" + metodoPago + "&ucfdi=" + usoCfdi + "&m=" + moneda + "&c=" + dolarprice + "&fp=" + formaPago + "&i=" + ivatasa + "&r=" + retencion + "&l=" + claveConcepto, "_blank");
                        win.focus();
                    }} >Vista Preliminar</button></div>}
                    <div style={{ marginRight: 'auto' }}>Timbres:{` ${ntibmres}`}</div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >
}
export default ModalCreateEdit;