import React, { useEffect, useState } from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        marginLeft: 15,
        paddingRight: 15,
        // backgroundColor: '#E4E4E4'
    },
    sectionHeader: {
        width: '98%',
        flexDirection: 'row',
    },
    sectionLogo: {
        margin: 0,
        padding: 0,
        width: '20%',
        //border: '1px solid black',
        flexGrow: 1
    },
    sectionUserInfo: {
        width: '50%',
        margin: 10,
        padding: 10,
        flexGrow: 2
    },
    sectionCPinfo: {
        margin: 10,
        width: '20%',
        padding: 2,
        border: '1px solid black',
        flexGrow: 1
    },
    sectionFolioTop: {
        // margin: 10,
        width: '29vw',
        height: "35px",
        borderLeft: '1px solid black',
        borderTop: '1px solid black',

    },
    sectionFolioTopMedium: {
        // margin: 10,
        width: '18.5vw',
        height: "35px",
        borderRight: '1px solid black',
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
    },
    sectionFolioTopMediumNoBorder: {
        // margin: 10,
        width: '18.5vw',
        height: "35px",
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
    },
    sectionSellosBottomFirst: {
        // margin: 10,
        marginTop: '3px',
        width: '95vw',
        height: "40px",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionSellosBottom: {
        // margin: 10,
        width: '95vw',
        height: "40px",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionSellosBottomLast: {
        // margin: 10,
        width: '95vw',
        height: "45px",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionClientePaga: {
        // margin: 10,
        width: '95vw',
        height: "70px",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionClientePagaHeader: {
        width: '100%',
        height: "auto",
        borderBottom: '1px solid black',
        //   flexGrow: 1
    },

    sectionCol: {
        margin: 0,
        width: '10vw',
        height: "auto",
        border: '1px solid black',

        // flexGrow: 1
    },
    sectionColMiddle: {
        margin: 0,
        width: '13vw',
        height: "auto",
        borderRight: '1px solid black',
        textOverflow: 'ellipsis',
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        // flexGrow: 1
    },
    sectionColMiddleX: {
        margin: 0,
        width: '33vw',
        height: "auto",
        borderRight: '1px solid black',
        textOverflow: 'ellipsis',
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        // flexGrow: 1
    },
    sectionOrigenDestino: {
        marginRight: 5,
        width: '47vw',
        height: "60px",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionClientePagaHeader: {
        width: '100%',
        height: "auto",
        borderBottom: '1px solid black',
        backgroundColor: "#7b7b7b",
        color: "#FFF"
        //   flexGrow: 1
    },
    sectionbajoconceptoizquierda: {
        margin: "0px",
        width: '69vw',
        height: "55px",
        //  border: '1px solid black',
    },
    sectionbajoconceptoderecha: {
        margin: 0,
        width: '26vw',
        height: "50px",
        borderRight: '1px solid black',
        borderLeft: '1px solid black',
        borderBottom: '1px solid black',
    },
    docutitulo: {
        margin: 5,
        fontSize: 10,
        fontWeight: 900,
        width: '10vw',
        height: "auto",
    },
    docutexto: {
        margin: 5,
        fontSize: 9,
        width: '80vw',
        height: "auto",
    }
});
const Contrato1 = props => {
    return <Page size="A4" style={styles.page}>
        <Text style={{ marginTop: '20px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center', width: '90%' }}>Condiciones de prestación de servicios que ampara la CARTA DE PORTE O COMPROBANTE PARA EL TRANSPORTE DE
            MERCANCÍAS.</Text>

        <View style={{ flexDirection: 'row', marginTop: 1, width: '90vw' }}>
            <Text style={styles.docutitulo}>PRIMERA.-</Text>
            <Text style={styles.docutexto}>Para los efectos del presente contrato de transporte se denomina "Transportista" al que realiza el servicio de transportación y
                "Remitente" o "Expedidor" al usuario que contrate el servicio o remite la mercancía.
            </Text>
        </View>
        <View style={{ flexDirection: 'row', marginTop: 1, width: '90vw' }}>
            <Text style={styles.docutitulo}>SEGUNDA.-</Text>
            <Text style={styles.docutexto}>El "Remitente" o "Expedidor" es responsable de que la información proporcionada al "Transportista" sea veraz y que la
                documentación que entregue para efectos del transporte sea la correcta.
            </Text></View>
        <View style={{ flexDirection: 'row', marginTop: 1, width: '90vw' }}>
            <Text style={styles.docutitulo}>TERCERA.-</Text>
            <Text style={styles.docutexto}>El "Remitente" o "Expedidor" debe declarar al "Transportista" el tipo de mercancía o efectos de que se trate, peso, medidas y/o
                número de la carga que entrega para su transporte y, en su caso, el valor de la misma.
                La carga que se entregue a granel será pesada por el "Transportista" en el primer punto donde haya báscula apropiada o, en
                su defecto, aforada en metros cúbicos con la conformidad del "Remitente" o "Expedidor".
            </Text>
        </View>

        <View style={{ flexDirection: 'row', marginTop: 1, width: '90vw' }}>
            <Text style={styles.docutitulo}>CUARTA.-</Text>
            <Text style={styles.docutexto}>Para efectos del transporte, el "Remitente" o "Expedidor" deberá entregar al "Transportista" los documentos que las leyes y
                reglamentos exijan para llevar a cabo el servicio, en caso de no cumplirse con estos requisitos el "Transportista" está
                obligado a rehusar el transporte de las mercancías.
            </Text></View>
        <View style={{ flexDirection: 'row', marginTop: 1, width: '90vw' }}>
            <Text style={styles.docutitulo}>QUINTA.-</Text>
            <Text style={styles.docutexto}>Si por sospecha de falsedad en la declaración del contenido de un bulto el "Transportista" deseare proceder a su reconocimiento, podrá hacerlo ante testigos y con asistencia del "Remitente" o "Expedidor" o del consignatario.
                Si este último no concurriere, se solicitará la presencia de un inspector de la Secretaría de Comunicaciones y Transportes, y se
                levantará el acta correspondiente.
                El "Transportista" tendrá en todo caso, la obligación de dejar los bultos en el estado en que se encontraban antes del
                reconocimiento.
            </Text></View>
        <View style={{ flexDirection: 'row', marginTop: 1, width: '90vw' }}>
            <Text style={styles.docutitulo}>SEXTA.-</Text>
            <Text style={styles.docutexto}>El "Transportista" deberá recoger y entregar la carga precisamente en los domicilios que señale el "Remitente" o "Expedidor",
                ajustándose a los términos y condiciones convenidos. El "Transportista" sólo está obligado a llevar la carga al domicilio de
                consignatario para su entrega una sola vez. Si ésta no fuera recibida, se dejará aviso de que la mercancía queda a disposición
                del interesado en las bodegas que indique el "Transportista".
            </Text></View>
        <View style={{ flexDirection: 'row', marginTop: 1, width: '90vw' }}>
            <Text style={styles.docutitulo}>SÉPTIMA.-</Text>
            <Text style={styles.docutexto}>Si la carga no fuere retirada dentro de los 30 días hábiles siguientes a aquél en que hubiere sido puesta a disposición del
                consignatario, el "Transportista" podrá solicitar la venta en subasta pública con arreglo a lo que dispone el Código de Comercio.
            </Text></View>
        <View style={{ flexDirection: 'row', marginTop: 1, width: '90vw' }}>
            <Text style={styles.docutitulo}>OCTAVA.-</Text>
            <Text style={styles.docutexto}>El "Transportista" y el "Remitente" o "Expedidor" negociarán libremente el precio del servicio, tomando en cuenta su tipo,
                característica de los embarques, volumen, regularidad, clase de carga y sistema de pago.
            </Text></View>
        <View style={{ flexDirection: 'row', marginTop: 1, width: '90vw' }}>
            <Text style={styles.docutitulo}>NOVENA.-</Text>
            <Text style={styles.docutexto}>Si el "Remitente" o "Expedidor" desea que el "Transportista" asuma la responsabilidad por el valor de las mercancías o efectos
                que él declare y que cubra toda clase de riesgos, inclusive los derivados de caso fortuito o de fuerza mayor, las partes
                deberán convenir un cargo adicional, equivalente al valor de la prima del seguro que se contrate, el cual se deberá expresar en
                la Carta de Porte.
            </Text></View>
        <View style={{ flexDirection: 'row', marginTop: 1, width: '90vw' }}>
            <Text style={styles.docutitulo}>DÉCIMA.-</Text>
            <Text style={styles.docutexto}>Cuando el importe del flete no incluya el cargo adicional, la responsabilidad del "Transportista" queda expresamente limitada a
                la cantidad equivalente a 15 días del salario mínimo vigente en el Distrito Federal por tonelada o cuando se trate de
                embarques cuyo peso sea mayor de 200 kg., pero menor de 1000 kg; y a 4 días de salario mínimo por remesa cuando se trate
                de embarques con peso hasta de 200 kg.
            </Text></View>
        <View style={{ flexDirection: 'row', marginTop: 1, width: '90vw' }}>
            <Text style={styles.docutitulo}>DÉCIMA PRIMERA.-</Text>
            <Text style={styles.docutexto}>El precio del transporte deberá pagarse en origen, salvo convenio entre las partes de pago en destino. Cuando el transporte se
                hubiere concertado "Flete por Cobrar", la entrega de las mercancías o efectos se hará contra el pago del flete y el
                "Transportista" tendrá derecho a retenerlos mientras no se le cubra el precio convenido.
            </Text></View>
        <View style={{ flexDirection: 'row', marginTop: 1, width: '90vw' }}>
            <Text style={styles.docutitulo}>DÉCIMA SEGUNDA.-</Text>
            <Text style={styles.docutexto}>Si al momento de la entrega resultare algún faltante o avería, el consignatario deberá hacerla constar en ese acto en la Carta
                de Porte y formular su reclamación por escrito al "Transportista", dentro de las 24 horas siguientes.
            </Text></View>
        <View style={{ flexDirection: 'row', marginTop: 1, width: '90vw' }}>
            <Text style={styles.docutitulo}>DÉCIMA TERCERA.-</Text>
            <Text style={styles.docutexto}>El "Transportista" queda eximido de la obligación de recibir mercancías o efectos para su transporte, en los siguientes casos:
                a) Cuando se trate de carga que por su naturaleza, peso, volumen, embalaje defectuoso o cualquier otra circunstancia no
                pueda transportarse sin destruirse o sin causar daño a los demás artículos o al material rodante, salvo que la empresa
                de que se trate tenga el equipo adecuado.
                b) Las mercancías cuyo transporte haya sido prohibido por disposiciones legales o reglamentarias.
                Cuando tales disposiciones no prohíban precisamente el transporte de determinadas mercancías, pero sí ordenen la presentación de ciertos documentos para que puedan ser transportadas, el "Remitente" o "Expedidor" estará obligado a entregar al
                "Transportista" los documentos correspondientes.
            </Text></View>
        <View style={{ flexDirection: 'row', marginTop: 1, width: '90vw' }}>
            <Text style={styles.docutitulo}>DÉCIMA CUARTA.-</Text>
            <Text style={styles.docutexto}>Los casos no previstos en las presentes condiciones y las quejas derivadas de su aplicación se someterán por la vía administrativa a la Secretaría de Comunicaciones y Transportes.
            </Text></View>
        <View style={{ flexDirection: 'row', marginTop: 1, width: '90vw' }}>
            <Text style={styles.docutitulo}>DÉCIMA QUINTA.-</Text>
            <Text style={styles.docutexto}>Para el caso de que el "Remitente" o "Expedidor" contrate carro por entero, este aceptará la responsabilidad solidaria para con
                el "Transportista" mediante la figura de la corresponsabilidad que contempla el artículo 10 del Reglamento Sobre el Peso,
                Dimensiones y Capacidad de los Vehículos de Autotransporte que Transitan en los Caminos y Puentes de Jurisdicción Federal,
                por lo que el "Remitente" o "Expedidor" queda obligado a verificar que la carga y el vehículo que la transporta, cumplan con el
                peso y dimensiones máximas establecidos en la NOM-012-SCT-2-2014.
            </Text></View>

    </Page>
}

export default Contrato1;