import { firebase } from '../configs/firebase';

export const getAllCostos = () => {
    var db = firebase.firestore();
    return db.collection('costos').get();
}
export const getAllCostosByUser = (uid) => {
    var db = firebase.firestore();
    return db.collection('costos').where("uid", "==", uid).orderBy("createAt", 'asc').get();
}
export const getAllCostosRecurrentesByUser = (uid) => {
    var db = firebase.firestore();
    return db.collection('costos_recurrentes').where("uid", "==", uid).orderBy("createAt", 'asc').get();
}
export const getAllCostosRecurrentesByUserActives = (uid, date) => {
    var db = firebase.firestore();
    return db.collection('costos_recurrentes').where("uid", "==", uid).where("fecha_fin", '>=', date).get();
}


export const getAllCostosNoRecurrentesByUserDateFilter = (uid, init, end) => {
    //console.log("datefilter "+init+" "+end+" user:"+uid);
    init.setHours(0, 0, 0);
    end.setHours(23, 59, 0);

    var db = firebase.firestore();
    return db.collection('costos').where("uid", "==", uid).where("fecha_fin", '>=', end).get();
}
export const getAllCostosRecurrentesByUserDateFilter = (uid, init, end) => {
    //console.log("datefilter "+init+" "+end+" user:"+uid);
    // init.setHours(0,0,0);
    //end.setHours(23,59,0);

    var db = firebase.firestore();
    return db.collection('costos_recurrentes').where("uid", "==", uid).where("fecha_fin", '>=', end).get();
}
export const getAllCostosByUserDateFilter = (uid, init, end) => {
    //console.log("datefilter "+init+" "+end+" user:"+uid);
    init.setHours(0, 0, 0);
    end.setHours(23, 59, 0);
    console.log("buscando no recurrentes", init);
    console.log("a", end);
    var db = firebase.firestore();
    return db.collection('costos').where("uid", "==", uid).where("fecha", ">=", init).where("fecha", "<=", end).orderBy("fecha", 'asc').get();
}
export const getAllCostosCombustibleByUserDateFilter = (uid, init, end) => {
    //console.log("datefilter "+init+" "+end+" user:"+uid);
    init.setHours(0, 0, 0);
    end.setHours(23, 59, 0);
    console.log("buscando no recurrentes", init);
    console.log("a", end);
    var db = firebase.firestore();
    return db.collection('combustible_user').where("uid", "==", uid).where("fechaCargaDate", ">=", init).where("fechaCargaDate", "<=", end).orderBy("fechaCargaDate", 'asc').get();
}
export const getTiposCosto = () => {
    var db = firebase.firestore();
    return db.collection('tipo_costo').orderBy("order", "asc").get();
}
export const addCosto = (data) => {
    let dataaux = { ...data, createAt: new Date(), active: true };
    var db = firebase.firestore();
    return db.collection('costos').add(dataaux);
}
export const addCostoRecurrente = (data) => {
    let dataaux = { ...data, createAt: new Date(), active: true };
    var db = firebase.firestore();
    return db.collection('costos_recurrentes').add(dataaux);
}
export const updateCosto = (id, data) => {
    let dataaux;
    if (data.deleted) {
        dataaux = { ...data, udpateAt: new Date(), active: false };
    } else {
        dataaux = { ...data, udpateAt: new Date(), active: true };
    }

    var db = firebase.firestore();
    return db.collection('costos').doc(id).update(dataaux);
}
export const updateCostoRecurrente = (id, data) => {
    let dataaux;
    if (data.deleted) {
        dataaux = { ...data, udpateAt: new Date(), active: false };
    } else {
        dataaux = { ...data, udpateAt: new Date(), active: true };
    }

    var db = firebase.firestore();
    return db.collection('costos_recurrentes').doc(id).update(dataaux);
}
export const deleteCosto = (id) => {
    var db = firebase.firestore();
    return db.collection('costos').doc(id).delete();
}
export const deleteCostoRecurrente = (id) => {
    var db = firebase.firestore();
    return db.collection('costos_recurrentes').doc(id).delete();
}

export const cancelarCosto = (id) => {
    console.log("cancelando costo",id);
    var db = firebase.firestore();
    return db.collection('costos').doc(id).update({ udpateAt: new Date(), monto: "$0.00", montofloat: 0.00,tipo:'Cancelado' });
}


export const getCostoById = (id) => {
    var db = firebase.firestore();
    return db.collection('costos').doc(id).get();
}
export const getCostoRecurrenteById = (id) => {
    var db = firebase.firestore();
    return db.collection('costos_recurrentes').doc(id).get();
}