import {firebase} from './../configs/firebase';
import { getUserByUid } from '../controllers/usuarios';
import { useIndexedDB } from 'react-indexed-db';

export const doLogin = (props,onIncorrectLogin) => {
    
    localStorage.removeItem('lastviajes');
    localStorage.removeItem('lastviajesexpiration');
    localStorage.removeItem('marketpointmanager-muid');
    console.log("do login");
    return firebase.auth().signInWithEmailAndPassword(props.email,props.password);
}

const LimpiarViajes=async()=>{
    const { clear } = useIndexedDB('viajes');
    await clear().then(event => {
       // console.log("agregado nuevo");
    });

}
const LimpiarActivos=async()=>{
    const { clear } = useIndexedDB('activos');
    await clear().then(event => {
       // console.log("agregado nuevo");
    });

}
export const doLogout = async() => {
    localStorage.removeItem('lastviajes');
    localStorage.removeItem('lastviajesexpiration');
    localStorage.removeItem('marketpointmanager-muid');
    localStorage.removeItem("fechaviajes");
    localStorage.removeItem("mesCargado");

    localStorage.removeItem("allow");

    await LimpiarViajes();
    await LimpiarActivos();

    firebase.auth().signOut().then( () => {
        localStorage.removeItem('marketpointmanagerid');
        localStorage.removeItem('marketpointmanager-user');
        window.location.href = '/';
    }).catch(e => {
        console.log("error firebase",e);
    })
}

export const validateSession = () => {
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            // User is signed in.
        } else {
            // No user is signed in.
            localStorage.removeItem('marketpointmanagerid');
            localStorage.removeItem('marketpointmanager-user');
            localStorage.removeItem('lastviajes');
            localStorage.removeItem('lastviajesexpiration');
            window.location.href = '/';
        }
    });
}

export const createNewUser = (email,password) => {
    return firebase.auth().createUserWithEmailAndPassword(email,password);
}

export const sendEmail = (email) => {
    return firebase.auth().sendPasswordResetEmail(email);
}

export const checkMaster = async (uid) =>{
    await getUserByUid(uid).then(snapshot => {
        snapshot.forEach(doc => {
            let user = doc.data();
            if (user.master && user.master !== "") {
                console.log("tiene master");
                localStorage.setItem('marketpointmanager-muid', user.master);
            }
        });
    }).catch();
}
export const getUidMaster = () =>{
    if(localStorage.getItem('marketpointmanager-muid')){
        return localStorage.getItem('marketpointmanager-muid');
    }else{
        return null;
    }
}

export const getSessionLvl = () => {
    let a;
    switch (localStorage.getItem('marketpointmanager-user')) {
        case 'user':
            a = 1;
            break;
        case 'administrador':
            a = 4;
            break;
        default:
            a = 0;
            break;
    }
    return a;
}