import React from "react";
import moment from 'moment';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TimeIcon from '@material-ui/icons/Timer';
// The avaiable renderer-related props are:
// - row (row index)
// - col (column index)
// - prop (column property name)
// - TD (the HTML cell element)
// - cellProperties (the cellProperties object for the edited cell)

export const MoneyRender = (props) => {

    const { value } = props;

    if (value) {
        let num = "";
        try {
            num = '$' + value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        } catch (e) {

        }

        return (
            <span align="center" className="currencyrecord">{num}</span>

        );
    }
    return (
        <>
            <span></span>
        </>
    );
};

export const DiasCreditoRender = (props) => {
    const { value } = props;
    const [diasCredito, setDiasCredito] = React.useState(0);

    React.useEffect(() => {
        if (value && value.fecha_facturado && value.diasCredito) {
            let date1 = value.fecha_facturado.toDate();
            let date2 = new Date();
            date1.setHours(0, 0, 0);
            date2.setHours(0, 0, 0);
            let a = moment(date1);
            let b = moment(date2);
            let diastranscurridos = b.diff(a, 'days');

            if (value.status === "Pagado") {
                setDiasCredito(0);
            } else {
                setDiasCredito(value.diasCredito - diastranscurridos);
            }

        } else {
            setDiasCredito("");
        }
    }, [value])

    return (
        <>
            {diasCredito >= 0 ? <span align="center" className="normalrecord">{diasCredito}</span> : <span align="center" className="normalrecord" style={{ color: 'red' }}>{diasCredito}</span>}

        </>
    );
}

export const FacturaRender = (props) => {
    const { value } = props;
    const prefijo = value.prefijoFactura || "";
    const folio = value.folioFactura || "";
    return (
        <span align="center" className="normalrecord">{`${prefijo}-${folio}`}</span>

    );
}
export const DateRender = (props) => {

    const { value } = props;
    if (value) {
        if (value.toDate) {
            return (
                <span align="center" className="normalrecord">{moment(value.toDate()).format("DD/MM/YYYY")}</span>

            );
        } else {
            <>
                <span>-</span>
            </>
        }


    }
    return (
        <>
            <span>-</span>
        </>
    );
};
export const ActionRender = (props) => {

    const { value } = props;
    const { handleFacturar } = props;
    const { handleFacturarCfdiNormal } = props;
    const { handleCambiarComentario } = props;
    const { handleCancelarFactura } = props;
    const { handleNota } = props;
    const { handleInvoice } = props;
    const { handleCancelado } = props;
    const { handleCapturaTiempo } = props;

    if (value && value.id) {
        return (
            <>

                {value.notacredito && <LocalAtmIcon size="small" style={{
                    color: "#2fa2fa",

                }} onClick={() => handleNota(value.id)} />}

                {value.invoice && <LocalAtmIcon size="small" style={{
                    color: "#2fa2fa",

                }} onClick={() => handleInvoice(value.id)} />}

                {
                    value.cfdisolo && <LocalAtmIcon size="small" style={{
                        color: "#2fa2fa",

                    }} onClick={() => handleFacturarCfdiNormal(value.id)} />
                }

                {
                  /*  value.cfdisolo && value.comentarios && <LocalAtmIcon size="small" style={{
                        color: "#2fa2fa",

                    }} onClick={() => handleCambiarComentario(value.id, value.comentarios)} />
                */}

                {!value.notacredito && !value.invoice && !value.cfdisolo && <LocalAtmIcon size="small" style={{
                    color: "#2fa2fa",

                }} onClick={() => handleFacturar(value.id)} />}
                {!value.facturaCancelada && !value.notacredito && !value.invoice && <DeleteForeverIcon size="small" style={{
                    color: "#2fa2fa",

                }} onClick={() => handleCancelarFactura(value.id)} />}


                {value.facturaCancelada && <span style={{ color: "#00F", textDecoration: 'underline', role: 'button' }} onClick={() => handleCancelado(value.id)}>Cancelado</span>}

               {handleCapturaTiempo!=null && <TimeIcon size="small" style={{
                    color: "#2fa2fa",

                }} onClick={() => handleCapturaTiempo(value.id)} /> }
            </>
        );
    }
    return (
        <>
            <span>-</span>
        </>
    );
};