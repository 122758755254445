import React, { useState, useEffect, Fragment } from 'react';
import { firebase } from '../../../configs/firebase';
import { getUserByUid, getAllUsuarios } from '../../../controllers/usuarios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import { addDays } from "date-fns";
import { HotTable, HotColumn } from '@handsontable/react';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { registerLanguageDictionary } from 'handsontable/i18n';
import esMX from 'handsontable/i18n/languages/es-MX';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { ActionRender, DateRender, MoneyRender, DiasCreditoRender, FacturaRender } from './ActionsRender';
import Download from './ExcelExport';
import { getViajesFacturados, getAllViajesByUserDateFilter, getNotasDeCreditoDateFilter, getCanceladoById } from '../../../controllers/viajes';
import { getInvoicesDateFilter, getCfdiSolos } from '../../../controllers/innvoices';
import ModalFacturar from '../ModalFacturar';
import ModalCancelarCFID from '../../cancelarCFDI/ModalCancelar';
import ModalNotaCreditoView from '../ModalNotaCredito';
import ModalFacturaExterna from '../ModalFacturaExterna';
import ModalEditarComentario from '../ModalEditarComentario';
import ModalIngreso from '../../ingresos/ModalCreateEdit';
import ModalNotaCredito from '../../notascredito/ModalCreateEdit';
import ModalInnvoice from '../../usainvoice/ModalCreateEdit';
import ModalCFDINormal from '../../cfdinormal/ModalCreateCFDINormal';
import moment from 'moment';
import axios from 'axios';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { getUidMaster } from '../../../session/session_controller';
import xml2js from 'xml2js';


const FacturasContent = (props) => {
    registerLanguageDictionary(esMX);
    registerLocale('es', es)

    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [firstDay, setFirstDay] = useState(null);
    const [lastDay, setLastDay] = useState(null);
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);

    const [facturas, setFacturas] = useState([]);

    const [facturasNoFilter, setFacturasNoFilter] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [querysearch, setQuerysearch] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isOpennc, setIsOpennc] = useState(false);
    const [isOpenCancelar, setIsOpenCancelar] = useState(false);
    const [recordCancelar, setRecordCancelar] = useState(null);
    const [isOpenInnvoice, setIsOpenInnvoice] = useState(false);
    const [isOpenexterna, setIsOpenExterna] = useState(false);
    const [isOpenIngreso, setIsOpenIngreso] = useState(false);
    const [isOpenNotaCredito, setIsOpenNotaCredito] = useState(false);
    const [isOpenCFDINormal, setIsOpenCFDINormal] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [currenteditrecrod, setCurrentEditRecord] = useState(null);
    const [currenteditrecrodcfdinormal, setCurrentEditRecordCfdiNormal] = useState(null);
    const [currentEditCometario, setCurrentEditComentario] = useState(null);
    const [isOpenEditComentario, setIsOpenEditComentario] = useState(false);
    const [currenteditrecrodnc, setCurrentEditRecordnc] = useState(null);
    const [total, setTotal] = useState(0.0);
    const [totalMask, setTotalMask] = useState('0.0');
    const [totalFacturadoFloat, setTotalFacturadoFloat] = useState(0.0);
    const [totalFacturadoMask, setTotalFacturadoMask] = useState('0.0');
    const [totalvencidoFloat, setTotalVencidoFloat] = useState(0.0);
    const [totalvencidoMask, setTotalVencidoMask] = useState('0.0');

    const [cargarDatosPeridodo, setCargarDatosPeddridodo] = useState("0");
    const [fechaRangoStart, setFechaRangoStart] = useState(new Date());
    const [fechaRangoEnd, setFechaRangoEnd] = useState(new Date());
    
    const requiereTiemopoEntrega=['RZHc17ViPqQTIJ7VShGfbBRwXBw1'];
     
    const onChangeDaterangeTwo = (fechas) => {
        if (!fechas || !fechas[0] || !fechas[1]) {
            return;
        }

        let start = new Date(fechas[0].getTime());
        let end = new Date(fechas[1].getTime());

        if (start)
            start.setHours(0, 0, 0);
        if (end)
            end.setHours(23, 59, 59);
        setFechaRangoStart(start);
        setFechaRangoEnd(end);

        if (start && end) {
            if (start.getTime() <= end.getTime()) {

                setTimeout(() => {
                    console.log("buscar por rango");

                    //BuscarPorRango(start, end);
                }, 500);
            }
        }

    };

    useEffect(() => { getData() }, [fechaRangoEnd])

    useEffect(() => {
        if (cargarDatosPeridodo === "0") {
            setFechaRangoStart(new Date());
            setFechaRangoEnd(new Date());
        }
    }, [cargarDatosPeridodo])

    const handleCreate = () => {

    }
    const handleDateChanged = (date) => {
        let fDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);
        setCurrentDate(date);
    }
    const findRecord = (id) => {
        let obj = facturas.find(element => element.id === id);
        return obj;
    }

    useEffect(() => {
        setTotalFacturadoMask(totalFacturadoFloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
    }, [totalFacturadoFloat]);
    useEffect(() => {
        setTotalVencidoMask(totalvencidoFloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
    }, [totalvencidoFloat]);


    useEffect(() => {
        if (facturas) {
            let totalFac = 0.0;
            let totalvencido = 0.0;
            facturas.forEach(element => {
                if (element.factura_monto) {
                    totalFac += element.factura_monto;

                }
                if (element.fecha_facturado && element.diasCredito) {
                    let date1 = element.fecha_facturado.toDate();
                    let date2 = new Date();
                    date1.setHours(0, 0, 0);
                    date2.setHours(0, 0, 0);
                    let a = moment(date1);
                    let b = moment(date2);
                    let diastranscurridos = b.diff(a, 'days');
                    if (diastranscurridos >= element.diasCredito && element.factura_monto) {
                        totalvencido += element.factura_monto;
                    }
                }

            });
            setTotalFacturadoFloat(totalFac);
            setTotalVencidoFloat(totalvencido);
        }
        console.log("cambiaron facturas");
        if (isLoading) {
            setIsLoading(false);
        }
    }, [facturas]);

    const handleEditFromId = () => {

    }
    const handleDeleteFromId = () => {

    }
    useEffect(() => {
        if (isAdmin) {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                setUserlist(a);
            }).catch(e => {
                console.log(e);
                //this.setState({isLoading:false,})
            });
        }
    }, [isAdmin]);
    const handleFacturar = id => {
        //   history.push('/cporte/' + id);
        let o = findRecord(id);
        if (o) {
            setCurrentEditRecord(o);
        } else {
            console.log("no se encontro registro");
        }
    }

    const handleFacturarCfdiNormal = id => {
        //   history.push('/cporte/' + id);
        let o = findRecord(id);
        if (o) {
            setCurrentEditRecordCfdiNormal(o);
        } else {
            console.log("no se encontro registro");
        }
    }


    const handleCambiarComentario = (id, currentComentario) => {
        let o = findRecord(id);
        if (o) {
            setCurrentEditComentario(o);
        } else {
            console.log("no se encontro registro");
        }
    }
    const handleCancelado = id => {
        //   history.push('/cporte/' + id);
        let o = findRecord(id);
        if (o) {
            console.log("data o", o)
            // setCurrentEditRecordCfdiNormal(o);
            getCanceladoById(id).then(snapshot => {
                if (snapshot.docs.length > 0) {
                    let doc = snapshot.docs[0];
                    let docObj = doc.data();
                    console.log("docOBJ", docObj);
                    if (docObj && docObj.r && docObj.r.acuse) {
                        var xmltext = docObj.r.acuse;

                        let nombrefactura = "";
                        var filename = `acuseCancelacion${nombrefactura}.xml`;
                        var pom = document.createElement('a');
                        var bb = new Blob([xmltext], { type: 'text/plain' });

                        pom.setAttribute('href', window.URL.createObjectURL(bb));
                        pom.setAttribute('download', filename);

                        pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
                        pom.draggable = true;
                        pom.classList.add('dragout');

                        pom.click();


                    }


                }
            }).catch(err => {

            });

        } else {
            console.log("no se encontro registro");
        }
    }


    const handleInvoice = id => {
        window.open('/invoice/' + id, '_blank');
    }
    const handleNota = id => {
        //mostrar nota credito
        console.log("noda de credito", id);
        let o = findRecord(id);
        if (o) {
            console.log("registro", o);
            setCurrentEditRecordnc(o);
        } else {
            console.log("no se encontro registro");
        }
    }

    const cancelarCFID = async (idViaje, userID) => {
        console.log("cancelando");
        //console.log("datacacelarcfci",{ id: idViaje, uid: userID });
        // return;
        await axios.post(`https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/cancelarcfdi`, { id: idViaje, uid: userID }).then(resp => {
            console.log("cancelado ok");
            if (resp.data.codigo == "000") {
                Swal.fire("Exito", `Se cancelo la factura`, 'success');
            } else {
                Swal.fire("No se pudo cancelar", `${resp.data}`, 'warning');
            }

            //console.log("respuesta", resp);


        }).catch(err => {
            console.log("error", err.toString());
            if (err.toString().includes("Network Error")) {
                Swal.fire("Error", "Error de conexión, no fue posible conectar con el servidor, revisa tu conexión", 'error');
            } else {
                Swal.fire("Error", "No se pudo cancelar la factura", 'error');
            }
        });
    }
    useEffect(() => {
        if (recordCancelar) {
            setIsOpenCancelar(true);
        } else if (isOpenCancelar) {
            setIsOpenCancelar(false);
        }
    }, [recordCancelar]);

    const handleCancelarFactura = id => {

        let o = findRecord(id);
        if (o) {
            console.log("cancelando", o);
            setRecordCancelar(o);
            /*Swal.fire({
                title: '¿Esta seguro de cancelar el CFDI seleccionado?',
                text: "Una vez cancelado pueden pasar hasta no podrá revertirse. Recuerde que la actualización del estatus en el sat puede durar hasta 72 horas una vez enviada la petición de cancelación",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1c666f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {

                    cancelarCFID(o.id, user.uid);
                }

            });*/
        } else {
            console.log("no se encontro registro");
        }
    }

    const handleCapturaTiempo = id => {

        let o = findRecord(id);
        if (o) {
            //console.log("cancelando", o);
            setCurrentEditComentario(o);
            
        } else {
            console.log("no se encontro registro");
        }
    }

    useEffect(() => {
        if (!isOpen && currenteditrecrod) {
            setCurrentEditRecord(null);
        }
    }, [isOpen])

    useEffect(() => {
        if (!isOpenCFDINormal && currenteditrecrodcfdinormal) {
            setCurrentEditRecordCfdiNormal(null);
        }
    }, [isOpenCFDINormal])

    useEffect(() => {
        if (currenteditrecrod != null) {
            if (currenteditrecrod.none) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
            }
            setIsOpen(true);
        }
    }, [currenteditrecrod])


    useEffect(() => {
        if (currenteditrecrodcfdinormal != null) {
            if (currenteditrecrodcfdinormal.none) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
            }
            setIsOpenCFDINormal(true);
        }
    }, [currenteditrecrodcfdinormal]);

    useEffect(() => {
        if (currentEditCometario != null) {
            setIsOpenEditComentario(true);
        }
    }, [currentEditCometario]);

    useEffect(() => {
        if (currenteditrecrodnc != null) {
            if (currenteditrecrodnc.none) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
            }
            setIsOpennc(true);
        }
    }, [currenteditrecrodnc])

    const getClienteInfoFromXML = async (xmldata) => {
        // console.log("data xml",xmldata);

        let nombreCliente = "";
        await xml2js.parseString(xmldata, async (err, result) => {
            if (err) {
                console.log(err);
            }
            else {
                // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                //  let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                //console.log("primer xml", result['cfdi:Comprobante']['cfdi:Receptor'][0]['$']['Nombre']);
                nombreCliente = result['cfdi:Comprobante']['cfdi:Receptor'][0]['$']['Nombre'];
                // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
            }

        });
        return nombreCliente;
    }
    const getClienteInfoTotalFromXML = async (xmldata) => {
        // console.log("data xml",xmldata);

        let total = "";
        await xml2js.parseString(xmldata, async (err, result) => {
            if (err) {
                console.log(err);
            }
            else {
                // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                //  let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                // console.log("primer xml", result['cfdi:Comprobante']["$"]["Total"]);
                total = result['cfdi:Comprobante']["$"]["Total"];
                //nombreCliente=result['cfdi:Comprobante']['cfdi:Receptor'][0]['$']['Nombre'];
                // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
            }

        });
        return total;
    }
    const getData = async (usertodisplay) => {

        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }
            setIsLoading(true);
            let a = [];
            let index = 0;
            var moment = require('moment'); // require
           // console.log("getting data", usertoconsult.uid);

            let fechai = fechaRangoStart;
            let fechal = fechaRangoEnd;
            fechai.setHours(0, 0, 1);
            fechal.setHours(23, 59, 0);
            if (cargarDatosPeridodo == "1") {
                fechai = fechaRangoStart;
                fechal = fechaRangoEnd;
            }

            console.log("gettind data tiempo", fechai);
            console.log("gettind data tiempo2", fechal);
            await getAllViajesByUserDateFilter(usertoconsult.uid, fechai, fechal).then(snapshot => {
                snapshot.forEach(doc => {
                    let pendientes = 0.0;
                    let o = doc.data();
                    if (!o.facuturado) {
                        return;
                    }

                    //MEVR650512VE6
                    /* let cador=o.facuturado.cadenaOriginalCFD;
                     if(cador){
                         let result = cador.includes("MEVR650512VE6");
                         if(result){
                           //  console.log("RFC MEVR650512VE6");
                         }else{
                             console.log("NO MERV",doc.id);
                         }
 
                     }*/
                    // console.log("facturado",o.facuturado.cadenaOriginalCFD)

                    o.id = doc.id;
                    o.key = doc.id;
                    o.factura = o.general.nviaje;
                    o.carta_porte = o.general.ncarta;
                    o.cliente = o.general.cliente;
                    o.referencia = o.general.referencia;
                    o.total = o.detalles.tarifaFloat;
                    o.moneda = o.detalles.currencyMX ? "MXN" : "USD";
                    o.fecha = o.fecha_facturado || null;
                    o.fecharevisionformat=o.fechaRevision ? moment(o.fechaRevision.toDate()).format("DD-MM-YYYY") : "";
                    o.fechaformat = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD-MM-YYYY") : "";
                    o.fechaformat2 = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD/MM/YYYY") : "";
                    o.nfactura = o.folioFactura ? `F-${o.folioFactura}` : "";

                    if (o.pagos) {
                        //calculamos pendientes
                        let pagado = 0.0;
                        o.pagos.forEach(element => {
                            pagado += element.montoFloat;
                        });

                        if (o.factura_monto >= pagado) {
                            pendientes = o.factura_monto - pagado;
                        } else {
                            pendientes = 0.0;
                        }

                    } else {
                        pendientes = o.factura_monto;
                    }
                    o.pendientes = pendientes;
                    o.obj = { ...o };

                    o.facturaname = `${o.prefijoFactura}-${o.folioFactura}`;

                    a.push(o);
                });

            }).catch(err => {
                console.log("error", err);
            });

            //notas de credito
            await getNotasDeCreditoDateFilter(usertoconsult.uid, fechai, fechal).then(snapshot => {
                snapshot.forEach(doc => {
                    let pendientes = 0.0;


                    let o = doc.data();

                    o.id = doc.id;
                    o.key = doc.id;
                    o.factura = "";
                    o.carta_porte = "";
                    o.cliente = o.cliente;
                    o.referencia = "";
                    o.factura_monto = parseFloat(o.total);
                    o.moneda = o.moneda;
                    o.fecha = null;
                    o.fechaformat = o.fecha_facturado ? moment(o.fecha.toDate()).format("DD-MM-YYYY") : "";
                    o.fechaformat2 = o.fecha_facturado ? moment(o.fecha.toDate()).format("DD/MM/YYYY") : "";
                    o.prefijoFactura = "NC";
                    o.folioFactura = o.folio;
                    o.pendientes = pendientes;
                    o.notacredito = true;
                    o.obj = { ...o };
                    o.status = "N.Crédito";
                    o.facturaname = `NC-${o.folio}`;
                    o.nfactura = `NC-${o.folio}`;

                    a.push(o);
                });

            }).catch(err => {
                console.log("error", err);
            });

            await getInvoicesDateFilter(usertoconsult.uid, fechai, fechal).then(snapshot => {
                snapshot.forEach(doc => {
                    let pendientes = 0.0;
                    let o = doc.data();
                    o.id = doc.id;
                    o.key = doc.id;
                    o.factura = "";
                    o.carta_porte = "";
                    o.cliente = o.cliente;
                    o.referencia = o.referencia || "";
                    o.factura_monto = parseFloat(o.total);
                    o.moneda = "USD";
                    o.fecha = null;
                    o.fechaformat = o.createAt ? moment(o.createAt.toDate()).format("DD-MM-YYYY") : "";
                    o.fechaformat2 = o.fecha_facturado ? moment(o.createAt.toDate()).format("DD/MM/YYYY") : "";
                    o.prefijoFactura = "INV";
                    o.folioFactura = o.invoiceNumber;
                    o.pendientes = pendientes;
                    o.invoice = true;
                    o.obj = { ...o };
                    o.status = "US Invoice";
                    o.facturaname = `INV-${o.invoiceNumber}`;
                    o.nfactura = `INV-${o.invoiceNumber}`;
                    a.push(o);
                });
            }).catch(err => console.log(err));

            await getCfdiSolos(usertoconsult.uid, fechai, fechal).then(async (snapshot) => {
                snapshot.forEach(async (doc) => {
                    let pendientes = 0.0;

                    let o = doc.data();
                    o.id = doc.id;
                    o.key = doc.id;
                    o.factura = "";
                    o.carta_porte = "";
                    o.cliente = o.cliente;
                    if (!o.cliente || o.cliente === "") {
                        o.cliente = await getClienteInfoFromXML(o.facuturado.cfdixml);
                    }
                    o.fecharevisionformat=o.fechaRevision ? moment(o.fechaRevision.toDate()).format("DD-MM-YYYY hh:mm") : "";
                    o.referencia = o.referencia || "";
                    o.factura_monto = parseFloat(o.total);
                    if (!o.total || o.total === "") {
                        console.log("test");
                        //  o.total= await getClienteInfoTotalFromXML(o.facuturado.cfdixml)
                        let totalf = await getClienteInfoTotalFromXML(o.facuturado.cfdixml);
                        totalf = parseFloat(totalf);
                        o.factura_monto = totalf;
                    }
                    if (o.pagos) {
                        //calculamos pendientes
                        let pagado = 0.0;
                        o.pagos.forEach(element => {
                            pagado += element.montoFloat;
                        });

                        if (o.factura_monto >= pagado) {
                            pendientes = o.factura_monto - pagado;
                        } else {
                            pendientes = 0.0;
                        }

                    } else {
                        pendientes = o.factura_monto;
                    }

                    o.moneda = o.moneda || "MXN";
                    o.fecha = null;
                    o.fechaformat = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD-MM-YYYY") : "";
                    o.fechaformat2 = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD/MM/YYYY") : "";
                    o.prefijoFactura = "F";
                    o.folioFactura = o.folioFactura;
                    o.pendientes = pendientes;
                    o.cfdisolo = true;
                    o.obj = { ...o };
                    o.status = o.status || "Facturado";
                    o.facturaname = `F-${o.folioFactura}`;
                    o.nfactura = `F-${o.folioFactura}`;

                    a.push(o);
                });
            }).catch(err => console.log(err));

            const auxfacturas = [];
            await a.forEach(async (element) => {
                let foliouuid = "";
                let subtotal = "";
                let totalcfdi = "";
                let monedacfdi = "";
                let trasladado = "";
                let retenido = "";
                if (element.facuturado) {
                    foliouuid = element.facuturado.uuid;

                    await xml2js.parseString(element.facuturado.cfdixml, async (err, result) => {
                        if (err) {
                            console.log(err);
                        }
                        else {
                            // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                            //  let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                            // console.log("primer xml", result['cfdi:Comprobante']["$"]["Total"]);
                            subtotal = result['cfdi:Comprobante']["$"]["SubTotal"];
                            totalcfdi = result['cfdi:Comprobante']["$"]["Total"];
                            monedacfdi = result['cfdi:Comprobante']["$"]["Moneda"];
                            console.log("impuestos retenidos", result['cfdi:Comprobante']['cfdi:Impuestos'])
                            retenido = result['cfdi:Comprobante']['cfdi:Impuestos'] ? result['cfdi:Comprobante']['cfdi:Impuestos'][0]['$']['TotalImpuestosRetenidos'] || "" : "";
                            trasladado = result['cfdi:Comprobante']['cfdi:Impuestos'] ? result['cfdi:Comprobante']['cfdi:Impuestos'][0]['$']['TotalImpuestosTrasladados'] || "" : "";
                            // trasladado = result['cfdi:Comprobante']['cfdi:Impuestos']['$']['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$']['Importe'] || "";

                        }

                    });
                }




                let n = { ...element, foliouuid, subtotal, totalcfdi, monedacfdi, retenido, trasladado };
                if (element.cancelada || element.facturaCancelada) {
                    n.status = "Cancelado";
                    n.cancelada = true;
                }
                auxfacturas.push(n);
            });

            setFacturas(auxfacturas);
            //setIsLoading(false);

            // console.log("data", a);
        } else {
            //console.log("user null");
        }
    }
    const validateAdmin = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (user.type && user.type === "administrador") {
                    isadmin = true;
                }
            });
            if (isadmin) {
                setAdminCurrentUser(uid);
                setIsAdmin(true);
            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                setIsAdmin(false);
                setUserlist([]);
                // this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }
    useEffect(() => {

        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getDay() + 1, 0);
        //let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {
                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);
                setUser(userr);
                // setAdminCurrentUser(userr.uid);

                if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                    // this.validateAdmin(user.uid);
                    //console.log("validando admin");
                    validateAdmin(userr.uid);
                }
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });

    }, []);
    const openViajesNewTab = () => {
        window.open('/viajes', '_blank');
    }
    useEffect(() => {
        if (user) {
            setFechaRangoStart(new Date());
            setFechaRangoEnd(new Date());
        }
    }, [user, currentDate, adminCurrentUser]);

    return <Fragment>
        {
            <div className="row">
                <div className="col-12">
                    <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>&nbsp;Módulo de Facturas</h3>
                    <div className="card">
                        <div className="card-header card-header-flex">

                            <div className="d-flex px-3 row  align-items-center">
                                <div className="col-auto pl-0">

                                    <div style={{ display: 'flex' }}>
                                        <button className="boton primBtn add ms-right" onClick={() => {
                                            setCurrentEditRecordCfdiNormal(null);
                                            setIsOpenCFDINormal(true)
                                        }}><span>Agregar Factura</span></button>
                                        <button className="boton primBtn add ms-right" onClick={() => setIsOpenIngreso(true)}><span>Agregar Ingreso</span></button>
                                        <button className="boton primBtn add ms-right" onClick={() => setIsOpenNotaCredito(true)}><span>Nota de crédito</span></button>
                                        <button className="boton primBtn add ms-right" onClick={() => setIsOpenInnvoice(true)}><span>US Invoice</span></button>
                                        {
                                            !isLoading && facturas && facturas.length > 0 && <Download data={facturas} className="btn btn-primary btn-sm" />
                                        }
                                        {/*<div style={{ marginTop: '0' }}>
                                            <span style={{ color: 'black', marginTop: '10px' }}>Entregado:</span><span className="currencyrecordmin">${totalMask}</span>
                                            <span style={{ color: 'black', marginTop: '10px' }}>&nbsp;&nbsp;&nbsp;Facturado:</span><span className="currencyrecordmin">{`$${totalFacturadoMask}`}</span>
                                            <span style={{ color: 'black', marginTop: '10px' }}>&nbsp;&nbsp;&nbsp;Vencido:</span><span style={{ color: '#F10' }} className="currencyrecordmin">{`$${totalvencidoMask}`}</span>
                                        </div>*/}
                                    </div>

                                </div>

                                <div>

                                    <div style={{ width: '500px', display: 'flex' }}>
                                        <FormControl component="fieldset">
                                            <RadioGroup row aria-label="periodo" name="periodo" value={cargarDatosPeridodo} onChange={e => setCargarDatosPeddridodo(e.target.value)}>
                                                <FormControlLabel value="0" control={<Radio />} label="Dia actual" />
                                                <FormControlLabel value="1" control={<Radio />} label="Rango" />
                                            </RadioGroup>
                                        </FormControl>
                                        {cargarDatosPeridodo === "1" && <div style={{ marginTop: '5px' }}>
                                            <DateRangePicker

                                                clearIcon={null}
                                                value={[fechaRangoStart, fechaRangoEnd]}
                                                onChange={onChangeDaterangeTwo}
                                            />

                                        </div>}

                                    </div>
                                </div>


                            </div>
                        </div>
                        <LoadingOverlay
                            active={isLoading}
                            spinner
                            text='Actualizando datos, espere ...'
                        >
                            <div className="card-body">
                                <HotTable settings={{
                                    colHeaders: true,
                                    rowHeaders: true,
                                    autoRowSize: false,
                                    columnSorting: true,
                                    dropdownMenu: ['filter_by_condition',
                                        'filter_by_value',
                                        'filter_action_bar'],
                                    filters: true,
                                    manualColumnResize: true,
                                    colWidths: [110, 110, 180, 280, 110, 80, 170, 110, 135, 85, 85],
                                    //para ancho total                                    
                                    minSpareRows: 1,          // almenos una fila
                                    // autoColumnSize: true,     // 
                                    //  stretchH: 'all',          // 
                                    //disableVisualSelection: true,
                                    licenseKey: "non-commercial-and-evaluation",
                                    language: "es-MX"
                                }} data={facturas} colHeaders={true} rowHeaders={false} width="100%" height="400" >

                                    <HotColumn className="htCenter htMiddle" readOnly title="Factura" data="nfactura" />
                                    <HotColumn className="htCenter htMiddle" readOnly title="Carta Porte" data="carta_porte" />
                                    <HotColumn className="htCenter htMiddle" readOnly title="Referencia" data="referencia" />
                                    <HotColumn className="htCenter htMiddle" readOnly title="Cliente" data="cliente" />
                                    <HotColumn className="htCenter htMiddle mycustomcolumn" render readOnly title="Total" data="factura_monto" >
                                        <MoneyRender className="htCenter htMiddle" hot-renderer></MoneyRender>
                                    </HotColumn>
                                    <HotColumn className="htCenter htMiddle" readOnly title="Moneda" data="moneda" />
                                    <HotColumn className="htCenter htMiddle mycustomcolumn" render readOnly title="Balance Pendiente" data="pendientes" >
                                        <MoneyRender className="htCenter htMiddle" hot-renderer></MoneyRender>
                                    </HotColumn>
                                    <HotColumn className="htCenter htMiddle" readOnly title="Fecha" data="fechaformat2" />
                                    <HotColumn className="htCenter htMiddle" render readOnly title="Días de Crédito" data="obj" >
                                        <DiasCreditoRender hot-renderer></DiasCreditoRender>
                                    </HotColumn>
                                    <HotColumn render readOnly title="Estatus" data="status" />
                                    <HotColumn className="htCenter htMiddle" readOnly title="Acciones" data="obj"  >
                                        <ActionRender hot-renderer handleCambiarComentario={handleCambiarComentario} handleCancelado={handleCancelado} handleFacturarCfdiNormal={handleFacturarCfdiNormal} handleInvoice={handleInvoice} handleNota={handleNota} handleFacturar={handleFacturar} handleCancelarFactura={handleCancelarFactura} handleCapturaTiempo={(uid && requiereTiemopoEntrega.find(rf=>rf===uid)) ? handleCapturaTiempo:null } />
                                    </HotColumn>
                                </HotTable>
                            </div>


                        </LoadingOverlay>
                    </div>
                    <ModalFacturar
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrod}
                        currentDate={currentDate}
                        isEdit={isEdit}
                        isOpen={isOpen}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={(data) => console.log("refresh")}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => {
                            setIsOpen(false)
                            setCurrentEditRecord(null);
                        }} />

                    <ModalCancelarCFID
                        findRecord={findRecord}
                        currenteditrecrod={recordCancelar}
                        currentDate={currentDate}
                        isEdit={isEdit}
                        isOpen={isOpenCancelar}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={() => console.log("refresh")}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => {
                            setIsOpenCancelar(false);
                            setRecordCancelar(null)
                        }} />
                    <ModalNotaCreditoView
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrodnc}
                        currentDate={currentDate}
                        isEdit={isEdit}
                        isOpen={isOpennc}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={() => console.log("refresh")}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => {
                            setIsOpennc(false);
                            setCurrentEditRecordnc(null)
                        }} />

                    <ModalInnvoice
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrodnc}
                        currentDate={currentDate}
                        isEdit={isEdit}
                        isOpen={isOpenInnvoice}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={() => console.log("refresh")}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => {
                            setIsOpenInnvoice(false);
                            setCurrentEditRecordnc(null)
                        }} />

                    <ModalFacturaExterna
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrod}
                        currentDate={currentDate}
                        isEdit={false}
                        isOpen={isOpenexterna}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={() => console.log("refresh")}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setIsOpenExterna(false)}
                    />

                    <ModalIngreso
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrod}
                        currentDate={new Date()}
                        isEdit={false}
                        isOpen={isOpenIngreso}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={() => console.log("refresh")}
                        facturasref={facturas.filter(element => element.status !== "Pagado")}
                        setLoading={value => console.log("loading", value)}
                        toggle={() => setIsOpenIngreso(false)} />

                    <ModalCFDINormal
                        findRecord={findRecord}
                        currenteditrecrodcfdinormal={currenteditrecrodcfdinormal}
                        currentDate={new Date()}
                        isEdit={false}
                        isOpen={isOpenCFDINormal}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={(e) => {
                            //console.log("refresh", e)
                            if (e.status === "ok") {
                                getData();
                            }
                        }}
                        facturasref={facturas.filter(element => element.status !== "Pagado")}
                        setLoading={value => console.log("loading", value)}
                        toggle={() => {
                            setIsOpenCFDINormal(false);
                            setCurrentEditRecordCfdiNormal(null);
                        }} />

                    <ModalNotaCredito
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrod}
                        currentDate={new Date()}
                        isEdit={false}
                        isOpen={isOpenNotaCredito}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={() => console.log("refresh")}
                        facturasref={facturas.filter(element => element.status !== "Pagado")}
                        setLoading={value => console.log("loading", value)}
                        toggle={() => setIsOpenNotaCredito(false)} />

                    <ModalEditarComentario
                        findRecord={findRecord}
                        currenteditrecrod={currentEditCometario}
                        currentDate={new Date()}
                        isEdit={false}
                        isOpen={isOpenEditComentario}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={() => console.log("refresh")}
                        facturasref={facturas.filter(element => element.status !== "Pagado")}
                        setLoading={value => console.log("loading", value)}
                        toggle={() => {
                            setCurrentEditComentario(null);
                            setIsOpenEditComentario(false);
                        }} />

                </div>
            </div>
        }
    </Fragment>

};


export default FacturasContent;