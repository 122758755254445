import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import BlockIcon from "@material-ui/icons/Block";
import { withStyles } from "@material-ui/core/styles";
import PencilIcon from '@material-ui/icons/Create';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import DescriptionIcon from '@material-ui/icons/Description';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

class CustomToolbarSelect extends React.Component {

  constructor(props) {
    super(props);
    let objdata = props.getObjByIndex(props.selectedRows.data.map(row => row.dataIndex));
    //console.log("data select",objdata);
    this.state = { indexdata: props.selectedRows.data.map(row => row.dataIndex), objdata };
  }
  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    let objdata = nextProps.getObjByIndex(nextProps.selectedRows.data.map(row => row.dataIndex));
    this.setState({ objdata });
  }

  handleClickInverseSelection = () => {
    const nextSelectedRows = this.props.displayData.reduce((nextSelectedRows, _, index) => {
      if (!this.props.selectedRows.data.find(selectedRow => selectedRow.index === index)) {
        nextSelectedRows.push(index);
      }

      return nextSelectedRows;
    }, []);

    this.props.setSelectedRows(nextSelectedRows);
  };


  handleClickEditar = () => {
    // let indextoedit = this.props.selectedRows.data.map(row => row.dataIndex);
    this.props.handleViewFromId(this.state.objdata.key);
  };
  handleClickCopiar = () => {
    //let indextoedit = this.props.selectedRows.data.map(row => row.dataIndex);
    this.props.handleCopy(this.state.objdata.key);
  };
  handleCP = () => {
    this.props.handleCP(this.state.objdata.key);
  }
  handleFacturar = () => {
    this.props.handleFacturar(this.state.objdata.key);
  }

  handleClickBlockSelected = () => {
    console.log(`block users with dataIndexes: ${this.props.selectedRows.data.map(row => row.dataIndex)}`);
  };
  handleWPId = () => {
    this.props.handleWPId(this.state.objdata.key);
  }

  render() {
    const { classes } = this.props;
    const { handleViewFromIndex } = this.props;
    const { handleWPId } = this.props;
    const { handleCP } = this.props;
    const { handleFacturar } = this.props;
    const { handleCopy } = this.props;

    return (
      <div className={classes.iconContainer}>
        <Tooltip title={"Editar"}>
          <IconButton className={classes.iconButton} onClick={this.handleClickEditar}>
            <PencilIcon style={{
              color: "#95d44c",

            }} size="small" aria-label="editar" />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Copiar"}>
          <IconButton className={classes.iconButton} onClick={this.handleClickCopiar}>
            <FileCopyIcon style={{
              color: "#95d44c",

            }} size="small" aria-label="copiar" />
          </IconButton>
        </Tooltip>
        <Tooltip title={"WhatsApp"}>
          <IconButton className={classes.iconButton} onClick={this.handleWPId}>
            <WhatsAppIcon style={{
              color: "#00F",

            }} size="small" aria-label="copiar" />
          </IconButton>
        </Tooltip>
        {
          (!this.state.objdata.isFormatoNuevo && this.state.objdata.info && this.state.objdata.info.ncarta !== "") && <Tooltip title={"Carta porte"}>
            <IconButton className={classes.iconButton} onClick={this.handleCP}>
              <DescriptionIcon style={{
                color: "#ff9800",

              }} size="small" aria-label="cp" />
            </IconButton>
          </Tooltip>
        }
        {
          (this.state.objdata.isViajeCargado && this.state.objdata.info && this.state.objdata.info.ncarta !== "") && <Tooltip title={"Facturar"}>
            <IconButton className={classes.iconButton} onClick={this.handleFacturar}>
              <LocalAtmIcon style={{
                color: "#FF0000",

              }} size="small" aria-label="facturar" />
            </IconButton>
          </Tooltip>
        }
      </div>
    );
  }
}

export default withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect);
