import React, { useEffect, useState } from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import { useParams, useLocation } from 'react-router-dom';
import { getViajeById } from '../../controllers/viajes';
import { getClienteByID } from '../../controllers/clientes';
import { getUbicacionesById } from '../../controllers/ubicaciones';
import { getUnidadByID, getRemolquesByNombreAndUid } from '../../controllers/unidades';
import { getUserByUid } from '../../controllers/usuarios';
import { getOperadorById } from '../../controllers/operadores';
import moment from 'moment';
import qrcode from 'qrcode.react';
import queryString from 'query-string';
import xml2js from 'xml2js';
import { NumeroALetras } from './numaletras';
import { parseBooleans } from 'xml2js/lib/processors';
import zIndex from '@material-ui/core/styles/zIndex';
//var QRCode = require('qrcode.react');
// Create styles
const CAT_UNIDADES = [
    {
        "codigo": "ACT",
        "descripcion": "Actividad"
    },
    {
        "codigo": "KT",
        "descripcion": "Bolsa"
    },
    {
        "codigo": "HEA",
        "descripcion": "CABEZA"
    },
    {
        "codigo": "BB",
        "descripcion": "CAJA"
    },
    {
        "codigo": "DAY",
        "descripcion": "DIA"
    },
    {
        "codigo": "DPC",
        "descripcion": "DOCENA"
    },
    {
        "codigo": "GLL",
        "descripcion": "GALON"
    },
    {
        "codigo": "GRM",
        "descripcion": "GRS"
    },
    {
        "codigo": "HUR",
        "descripcion": "HORA"
    },
    {
        "codigo": "KT",
        "descripcion": "JUEGO"
    },
    {
        "codigo": "KGM",
        "descripcion": "KG"
    },
    {
        "codigo": "LTR",
        "descripcion": "LITRO"
    },
    {
        "codigo": "LO",
        "descripcion": "LOTE"
    },
    {
        "codigo": "MTK",
        "descripcion": "M2"
    },
    {
        "codigo": "MTQ",
        "descripcion": "M3"
    },
    {
        "codigo": "MTR",
        "descripcion": "METRO"
    },
    {
        "codigo": "LM",
        "descripcion": "METRO LINEAL"
    },
    {
        "codigo": "A9",
        "descripcion": "No Aplica"
    },
    {
        "codigo": "PR",
        "descripcion": "PAR"
    },
    {
        "codigo": "H87",
        "descripcion": "PIEZA"
    },
    {
        "codigo": "C9",
        "descripcion": "ROLLO"
    },
    {
        "codigo": "E48",
        "descripcion": "SERVICIO"
    },
    {
        "codigo": "TNE",
        "descripcion": "TONS"
    },
    {
        "codigo": "LN",
        "descripcion": "TRAMO"
    },
    {
        "codigo": "C62",
        "descripcion": "Unidad"
    }
]

const cat_conceptos = [
    { clave: "78101800", descripcion: "Transporte de carga por carretera" },
    { clave: "78101801", descripcion: "Servicios de transporte de carga por carretera (en camión) en área local" },
    { clave: "78101802", descripcion: "Servicios transporte de carga por carretera (en camión) a nivel regional y nacional" },
    { clave: "78101806", descripcion: "Servicios transporte de carga por carretera a nivel internacional" },
    { clave: "78101807", descripcion: "Servicios transporte de carga de petróleos o químicos" },
    { clave: "78141500", descripcion: "Coordinación de logística en transporte" },
    { clave: "78141501", descripcion: "Coordinación de fletes" },
];

const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        marginTop: 15,
        paddingBottom: '30px',
        marginLeft: 15,
        paddingRight: 15,
        // backgroundColor: '#E4E4E4'
    },
    sectionHeader: {
        width: '98%',
        flexDirection: 'row',
    },
    sectionLogo: {
        margin: 0,
        padding: '0',
        width: '20%',
        // border: '1px solid black',
        flexGrow: 1
    },
    sectionUserInfo: {
        width: '40%',
        margin: 2,
        padding: 10,
        flexGrow: 2
    },
    sectionCPinfo: {
        margin: 10,
        width: '30%',
        padding: 2,
        //border: '1px solid black',
        flexGrow: 1
    },
    sectionFolioTopSmall: {
        // margin: 10,
        width: '9.11%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTopCompenzaSmall: {
        // margin: 10,
        width: '13.22%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTopBigDetail: {
        // margin: 10,
        width: '36.22%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTop: {
        // margin: 10,
        width: '15.11%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTopMedium: {
        // margin: 10,
        width: '18.5vw',
        height: "35px",
        borderRight: '1px solid black',
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
    },
    sectionFolioTopMediumNoBorder: {
        // margin: 10,
        width: '18.5vw',
        height: "35px",
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
    },
    sectionSellosBottomFirst: {
        // margin: 10,
        marginTop: '0px',
        width: 'auto',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionSellosBottom: {
        // margin: 10,
        width: 'auto',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionComentariosBottom: {
        // margin: 10,
        width: '95vw',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionSellosBottomLast: {
        // margin: 10,
        width: 'auto',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionClientePaga: {
        // margin: 10,
        width: '95vw',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionClientePagaHeader: {
        width: '100%',
        height: "auto",
        borderBottom: '1px solid black',
        //   flexGrow: 1
    },

    sectionCol: {
        margin: 0,
        width: '10vw',
        height: "140px",
        border: '1px solid black',

        // flexGrow: 1
    },
    sectionColMiddle: {
        margin: 0,
        width: '13vw',
        height: "140px",
        borderRight: '1px solid black',
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        // flexGrow: 1
    },
    sectionColMiddleX: {
        margin: 0,
        width: '33vw',
        height: "140px",
        borderRight: '1px solid black',
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        // flexGrow: 1
    },
    sectionOrigenDestino: {
        marginRight: 5,
        width: '47vw',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionLabelInfo: {
        width: '14%',
        height: "auto",
        textAlign: 'right',
        backgroundColor: "#d9d9d9",
        color: "black"
    },
    sectionLabelInfosmall: {
        width: '29%',
        height: "auto",
        textAlign: 'right',
        backgroundColor: "#d9d9d9",
        color: "black"
    },
    sectionInfoSmall: {
        width: '71%',
        marginLeft: '5px',
        height: "auto",
        textAlign: 'left',
        color: "black"
    },
    sectionInfoNoventa: {
        width: '85%',
        marginLeft: '5px',
        height: "auto",
        textAlign: 'left',
        color: "black"
    },
    sectionInfoCuarenta: {
        width: '35%',
        marginLeft: '5px',
        height: "auto",
        textAlign: 'left',
        color: "black"
    },
    sectionClientePagaHeader: {
        width: '100%',
        height: "auto",
        borderBottom: '1px solid black',
        backgroundColor: "#203764",
        color: "#FFF"
        //   flexGrow: 1
    },
    sectionClientePagaHeader2: {
        width: '100%',
        height: "auto",
        borderBottom: '1px solid black',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: "#203764",
        color: "#FFF"
        //   flexGrow: 1
    },
    sectionbajoconceptoizquierda: {
        margin: "0px",
        width: '69vw',
        height: "55px",
        //  border: '1px solid black',
    },
    sectionbajoconceptoderecha: {
        margin: 0,
        width: '26vw',
        height: "50px",
        borderRight: '1px solid black',
        borderLeft: '1px solid black',
        borderBottom: '1px solid black',
    },
    docutitulo: {
        margin: 5,
        fontSize: 10,
        fontWeight: 900,
        width: '10vw',
        height: "auto",
    },
    docutexto: {
        margin: 5,
        fontSize: 9,
        width: '80vw',
        height: "auto",
    }
});
const CartaPorte = props => {
    const CAT_METODOSPAGO = [
        {
            "Name": "Pago en parcialidades ó diferido",
            "Value": "PPD"
        },
        {
            "Name": "Pago en una sola exhibición",
            "Value": "PUE"
        }
    ];
    const CAT_USOCFDI = [
        {
            "Natural": true,
            "Moral": false,
            "Name": "Honorarios médicos, dentales y gastos hospitalarios.",
            "Value": "D01"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos médicos por incapacidad o discapacidad",
            "Value": "D02"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos funerales.",
            "Value": "D03"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Donativos.",
            "Value": "D04"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
            "Value": "D05"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Aportaciones voluntarias al SAR.",
            "Value": "D06"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Primas por seguros de gastos médicos.",
            "Value": "D07"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos de transportación escolar obligatoria.",
            "Value": "D08"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
            "Value": "D09"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Pagos por servicios educativos (colegiaturas)",
            "Value": "D10"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Adquisición de mercancias",
            "Value": "G01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Devoluciones, descuentos o bonificaciones",
            "Value": "G02"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Gastos en general",
            "Value": "G03"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Construcciones",
            "Value": "I01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Mobilario y equipo de oficina por inversiones",
            "Value": "I02"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Equipo de transporte",
            "Value": "I03"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Equipo de computo y accesorios",
            "Value": "I04"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Dados, troqueles, moldes, matrices y herramental",
            "Value": "I05"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Comunicaciones telefónicas",
            "Value": "I06"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Comunicaciones satelitales",
            "Value": "I07"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Otra maquinaria y equipo",
            "Value": "I08"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Por definir",
            "Value": "P01"
        }
    ]
    const CAT_FORMASPAGO = [
        {
            "Name": "Efectivo",
            "Value": "01"
        },
        {
            "Name": "Cheque nominativo",
            "Value": "02"
        },
        {
            "Name": "Transferencia electrónica de fondos",
            "Value": "03"
        },
        {
            "Name": "Tarjeta de crédito",
            "Value": "04"
        },
        {
            "Name": "Monedero electrónico",
            "Value": "05"
        },
        {
            "Name": "Dinero electrónico",
            "Value": "06"
        },
        {
            "Name": "Vales de despensa",
            "Value": "08"
        },
        {
            "Name": "Dación en pago",
            "Value": "12"
        },
        {
            "Name": "Pago por subrogación",
            "Value": "13"
        },
        {
            "Name": "Pago por consignación",
            "Value": "14"
        },
        {
            "Name": "Condonación",
            "Value": "15"
        },
        {
            "Name": "Compensación",
            "Value": "17"
        },
        {
            "Name": "Novación",
            "Value": "23"
        },
        {
            "Name": "Confusión",
            "Value": "24"
        },
        {
            "Name": "Remisión de deuda",
            "Value": "25"
        },
        {
            "Name": "Prescripción o caducidad",
            "Value": "26"
        },
        {
            "Name": "A satisfacción del acreedor",
            "Value": "27"
        },
        {
            "Name": "Tarjeta de débito",
            "Value": "28"
        },
        {
            "Name": "Tarjeta de servicios",
            "Value": "29"
        },
        {
            "Name": "Aplicación de anticipos",
            "Value": "30"
        },
        {
            "Name": "Intermediarios",
            "Value": "31"
        },
        {
            "Name": "Por definir",
            "Value": "99"
        }
    ]

    const data = props.objdata;

    let dateIn = data.datos_viaje.fechaDestino.toDate();
    let dateOut = data.datos_viaje.fechaOrigen.toDate();


    if (data && data.datatimbrado && data.datatimbrado.FechaTimbrado) {
        let datex = new Date(data.datatimbrado.FechaTimbrado);
        dateIn.setHours(datex.getHours(), datex.getMinutes());
        dateOut.setHours(datex.getHours(), datex.getMinutes());
    }

    let fechaEntrega = moment(dateIn).format('DD/MM/YYYY HH:mm');
    let fechaOrigen = moment(dateOut).format('DD/MM/YYYY HH:mm');

    let datacliente = data.clienteinfo;
    let dataviaje = data.datos_viaje;
    let datacp = data.carta_porte;
    let origeninfo = data.origeninfo;
    let destinoinfo = data.destinoinfo;
    let usuarioInfo = data.usuarioInfo;
    let unidadinfo = data.unidadinfo;
    let operadorinfo = data.operadorinfo;
    let paradasinfo = data.paradas;
    let datosTimbrado = data.datosTimbrado;

    let remolqueString = "";
    let comentarios = data.detalles.comentarios;
    if (data.carta_porte) {
        if (data.carta_porte.remolque1 !== "") {
            remolqueString += `${data.carta_porte.messageRemolque1}:${data.carta_porte.remolque1} `;
        }
        if (data.carta_porte.remolque2 !== "") {
            remolqueString += `${data.carta_porte.messageRemolque2}:${data.carta_porte.remolque2} `;
        }
    }


    if (!datacliente) {
        datacliente = {};
    }
    if (!origeninfo) {
        origeninfo = {};
    }
    if (!destinoinfo) {
        destinoinfo = {};
    }
    if (!usuarioInfo) {
        //usuarioInfo = { razon: "NORAZON" };
    }

    let datatimbre = {
        TaxStamp: {}
    };
    if (data.facturamaCP) {
        datatimbre = data.facturamaCP;
    }
    let cpFactOBJ = null;
    if (data.cpfacuturado) {
        cpFactOBJ = data.cpfacuturado;
        let xmlData = data.cpfacuturado.timbreXML;


    }
    const [credenciales, setCredenciales] = useState(usuarioInfo.credenciales || null);
    const [logotipo, setLogotipo] = useState(usuarioInfo.logotipo || null);
    const [referencia, setReferencia] = useState(data.general.referencia || "");
    const [ncarta, setNcarta] = useState(data.general.ncarta || "-");
    const [nviaje, setNviaje] = useState(data.general.nviaje || "-");
    const [unidad, setUnidad] = useState(data.detalles.unidad || "-");


    const [unidadplacas, setUnidadplacas] = useState(unidadinfo ? unidadinfo.placas : "");
    const [unidadyear, setUnidadYear] = useState(unidadinfo ? unidadinfo.year : "");
    const [aseguradora, setaseguradora] = useState(unidadinfo ? unidadinfo.aseguradora : "");
    const [aseguradoraMedioAmbiente, setaseguradoraMedioAmbiente] = useState(unidadinfo ? unidadinfo.aseguradoraMedioAmbiente : "");
    const [poliza, setpoliza] = useState(unidadinfo ? unidadinfo.poliza : "");
    const [polizaMedioAmbiente, setpolizaMedioAmbiente] = useState(unidadinfo ? unidadinfo.polizaMedioAmbiente : "");
    const [tipoSct, settipoSct] = useState(unidadinfo ? unidadinfo.tipoSct : "");
    const [tipoUnidad, settipoUnidad] = useState(unidadinfo ? unidadinfo.tipoUnidad : "");
    const [sct, setsct] = useState(unidadinfo ? unidadinfo.sct : "");

    const [operadorlicencia, setOperadorLicencia] = useState(operadorinfo ? `${operadorinfo.tipoLicencia}-${operadorinfo.noLicencia}` : "");
    const [operadornombre, setOperadorNombre] = useState(operadorinfo ? `${operadorinfo.nombre} ${operadorinfo.apellidos}` : "");
    const [operadorrfc, setOperadorRFC] = useState(operadorinfo ? `${operadorinfo.rfc}` : "");
    const [operadorDireccion, setOperadorDireccion] = useState(operadorinfo ? `${operadorinfo.calle || ""} ${operadorinfo.numero || ""},${operadorinfo.colonia || ""} ${operadorinfo.localidad || ""} ${operadorinfo.cp || ""} ${operadorinfo.internacional ? "USA" : "México"}` : "");

    const [cantidad, setCantidad] = useState(data.detalles.cantidad || "");
    const [descripcion, setDescripcion] = useState(data.detalles.descripcion || "");

    const [folioFiscal, setFolioFiscal] = useState(cpFactOBJ ? cpFactOBJ.uuid : "-");
    const [serieSat, setSerieSat] = useState(data.datatimbrado ? data.datatimbrado.NoCertificadoSAT : "-");
    const [fechaTimbrado, setFechaTimbrado] = useState(data.datatimbrado ? data.datatimbrado.FechaTimbrado : "-");
    const [fechaCer, setFechaCer] = useState(cpFactOBJ ? cpFactOBJ.fecha : "-");

    //const [metodoPago, setMetodoPago] = useState(data.datatimbrado ? `${data.datatimbrado.MetodoPago} - ${CAT_METODOSPAGO.find(e => e.Value === data.datatimbrado.MetodoPago).Name}` : "-");
    //const [formaPago, setFormaPago] = useState(data.datatimbrado ? `${data.datatimbrado.FormaPago} - ${CAT_FORMASPAGO.find(e => e.Value === data.datatimbrado.FormaPago).Name}` : "-");
    //const [usoCfdi, setUsoCfdi] = useState(data.datatimbrado ? `${data.datatimbrado.UsoCFDI} - ${CAT_USOCFDI.find(e => e.Value === data.datatimbrado.UsoCFDI).Name}` : "-");
    let auxmoneda=data.datosTimbrado ? data.datosTimbrado.moneda : "-";
    if(auxmoneda=="" && data.ttarifarioSelected){
        if(data.ttarifarioSelected.currencyMx===true){
            auxmoneda='MXN';
        }else{
            auxmoneda="USD";
        }
        
        console.log(data.ttarifarioSelected);
    }
    const [moneda, setMoneda] = useState(auxmoneda);
    //const [tipoCambio, setTipoCambio] = useState(data.datatimbrado ? data.datatimbrado.TipoCambio : "-");

    let totalmoneda = data.detalles.tarifaFloat;
    if(data.detalles.tarifaFloat===""){
        totalmoneda=0;
    }
    if (!data.currencyMX && data.datosTimbrado.moneda == "MXN") {
        //cambio de divisa
        //dolarprice
        totalmoneda = totalmoneda * parseFloat(data.datosTimbrado.dolarprice);
    }
    const [conceptos, setConceptos] = useState([{
        clave: `${data.datosTimbrado.concepto} - ${data.datosTimbrado.conceptoObj ? data.datosTimbrado.conceptoObj.descripcion : ""}`,
        monto: `${totalmoneda.toFixed(2)}`,
        iva: datosTimbrado.retencion == "0" ? `IVA ${datosTimbrado.iva}%` : `IVA ${datosTimbrado.iva}%, Retención ${datosTimbrado.retencion}%`,
        total: `$${totalmoneda}`
    }]);

    const [subtotal, setsubtotal] = useState(data.detalles ? totalmoneda.toFixed(2) : ".00");

    let caltotal = totalmoneda;
    let caliva = 0.0;
    if (datosTimbrado.iva == "16") {
        caliva = caltotal * 0.16;
        caltotal = caltotal * 1.16;

    } else if (datosTimbrado.iva == "8") {
        caliva = caltotal * 0.08;
        caltotal = caltotal * 1.08;
    }

    let calretencion = 0.0;
    if (datosTimbrado.retencion == "4") {
        caltotal = caltotal - totalmoneda * 0.04;
        calretencion = totalmoneda * 0.04;
    }
   
    const [tarifa, setTarifa] = useState(data.detalles.tarifaFloat);

    const [total, settotal] = useState(data.showTarifa ? caltotal.toFixed(2) : 0.00);

    const [serieEmisor, setSerieEmisor] = useState(data.datatimbrado ? data.datatimbrado.NoCertificado : "-");

    const [sellocfdi, setSelloCfdi] = useState(cpFactOBJ ? cpFactOBJ.selloCFD : "-");
    const [sellosat, setSelloSat] = useState(cpFactOBJ ? cpFactOBJ.selloSAT : "-");
    const [cadenaOriginal, setCadenaOriginal] = useState(cpFactOBJ ? cpFactOBJ.cadenaOriginal : "-");


    const [operador, setOperador1] = useState(data.detalles.operador1 || "-");
    const [isInternational, setIsInternacional] = useState(data.isInternational);

    const [kms, setKms] = useState(data.carta_porte.distBase_km);

    const [fecha, setFecha] = useState(moment(data.createAt.toDate()).format('DD/MM/YYYY') || "-");
    const [clientenombre, setClienteNombre] = useState(datacliente.nombre || "-");
    const [clientenumero, setClienteNumero] = useState(datacliente.numero || "");
    const [clientecalle, setClientecalle] = useState(datacliente.calle || "");
    let auxcolonia = datacliente.colonia;
    if (auxcolonia === "" && datacliente.coloniaSat && datacliente.colonias) {
        let colfinded = datacliente.colonias.find(col => col.claveColonia === datacliente.coloniaSat);
        if (colfinded) {
            auxcolonia = colfinded.colonia || "";
        }
    }
    const [clientecolonia, setClientecolonia] = useState(auxcolonia || "");
    const [clientelocalidad, setClienteLocalidad] = useState(datacliente.localidad || "");
    const [clientecp, setClientecp] = useState(datacliente.cp || "");
    const [clienterfc, setClienterfc] = useState(datacliente.rfc || "XEXX010101000");
    const [clientestado, setClienteestado] = useState(datacliente.estado || "");

    const [origenInternacional, setOrigenInternaciona] = useState(origeninfo.internacional);
    const [origenlocation, setOrigenlocation] = useState(origeninfo.nombre || "");
    const [origenciudad, setOrigenCiudad] = useState(origeninfo.localidad || "");
    const [origenestado, setOrigenEstado] = useState(origeninfo.estado || "");
    const [origenumero, setOrigennumero] = useState(origeninfo.numero || "");
    const [origenumeroInteior, setOrigennumeroInterior] = useState(origeninfo.numeroInterior || "");
    const [origencalle, setOrigencalle] = useState(origeninfo.calle || "");
    const [origencolonia, setOrigencolonia] = useState(origeninfo.colonia || "");
    const [origencp, setOrigencp] = useState(origeninfo.cp || "");
    const [origenrfc, setOrigenrfc] = useState(origeninfo.rfc || "");
    const [origennombre, setOrigennombre] = useState(origeninfo.nombre || "");

    const [destinoInternacional, setDestinoInternaciona] = useState(destinoinfo.internacional);
    const [destinolocation, setDestinolocation] = useState(destinoinfo.nombre || "");
    const [destinociudad, setDestinoCiudad] = useState(destinoinfo.localidad || "");
    const [destinoestado, setDestinoEstado] = useState(destinoinfo.estado || "");
    const [destinonumero, setDestinonumero] = useState(destinoinfo.numero || "");
    const [destinoumeroInteior, setDestinonumeroInterior] = useState(destinoinfo.numeroInterior || "");
    const [destinocalle, setDestinocalle] = useState(destinoinfo.calle || "");
    const [destinocolonia, setDestinocolonia] = useState(destinoinfo.colonia || "");
    const [destinocp, setDestinocp] = useState(destinoinfo.cp || "");
    const [destinorfc, setDestinoRfc] = useState(destinoinfo.rfc || "");
    const [destinnombre, setDestinonombre] = useState(destinoinfo.nombre || "");

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    let sellosfixed = ``;
    if (data.datos_viaje.noSello && data.datos_viaje.noSello !== "") {
        sellosfixed = data.datos_viaje.noSello;
    }
    if (data.datos_viaje.noSello2 && data.datos_viaje.noSello2 !== "") {
        sellosfixed += " " + data.datos_viaje.noSello2;
    }

    const [sellos, setSellos] = useState(sellosfixed || "");

    const [datosTimbre, setDatosTimbre] = useState(data.cpfacuturado || null);

    let pedis = ""
    data.detalles.pedimentos.forEach(element => {
        let e = element.split(" ").join("");
        pedis += e + " ";
    })
    pedis = pedis.trim();
    const [pedimentos, setPedimentos] = useState(pedis || "");
    const [tiposervicio, setTiposervicio] = useState(data.datos_viaje.tipo === "Cruce" ? "Local (-100 kms)" : data.datos_viaje.tipo || "");
    const [mercancias, setMercancias] = useState(data.mercancias || []);
    const [paradas, setParadas] = useState(data.paradas || []);

    const [impuestos, setImpuestos] = useState([{
        iva: caliva.toFixed(2),
        retencion: calretencion.toFixed(2)
    }]);

    const [razon, setRazon] = useState(usuarioInfo.credenciales.Razon || "");
    const [rfcuser, setRfcUser] = useState(usuarioInfo.credenciales.Rfc || "");
    let direcccionfixed = `${usuarioInfo.credenciales.direccion.calle} ${usuarioInfo.credenciales.direccion.numero} Colonia ${usuarioInfo.credenciales.direccion.colonia} CP ${usuarioInfo.credenciales.direccion.cp} ${usuarioInfo.credenciales.direccion.localidad} ${usuarioInfo.credenciales.direccion.estado}`;
    const [dirCalle, setDirCalle] = useState(usuarioInfo.credenciales.direccion.calle || " ");
    const [dirNumero, setDirNumero] = useState(usuarioInfo.credenciales.direccion.numero || " ");
    const [dirColonia, setDirColonia] = useState(usuarioInfo.credenciales.direccion.colonia || " ");
    const [dirCp, setDirCp] = useState(usuarioInfo.credenciales.direccion.cp || " ");
    const [dirLocalidad, setDirLocalidad] = useState(usuarioInfo.credenciales.direccion.localidad || " ");
    const [dirEstado, setDirEstado] = useState(usuarioInfo.credenciales.direccion.estado || " ");

    const [nombreRemolque1, setNombreremolque1] = useState(data.remolque1 ? data.remolque1.nombre : "");
    const [nombreRemolque2, setNombreremolque2] = useState(data.remolque2 ? data.remolque2.nombre : "");
    const [placasRemolque1, setPlacasremolque1] = useState(data.remolque1 ? data.remolque1.placas : "");
    const [placasRemolque2, setPlacasremolque2] = useState(data.remolque2 ? data.remolque2.placas : "");

    const [direccioncp, setdireccioncp] = useState(direcccionfixed || "--");
    const [showTarifa, setShowTarifa] = useState(data.showTarifa);

    //let cadenaqr="https://verificacfdi.facturaelectronica.sat.gob.mx/default.aspx?id=abc\&re="+rfcuser+"\&rr="+clienterfc+"\&tt=0.000000\&fe=SeQDTA==";
    const [dataqr, setDataQR] = useState(`${data.id}` || "abc");

    useEffect(() => {
        // console.log("props", props);
    }, []);

    return <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.sectionHeader}>
                <View style={styles.sectionLogo}>
                    <Image style={{ width: '100%', height: "auto", marginTop: '10px' }} src={`data:image/png;base64, ${logotipo || ""}`}></Image>
                </View>
                <View style={styles.sectionUserInfo}>
                    <Text style={{ fontSize: '14px', fontWeight: 'bold' }}>{razon}</Text>
                    <Text style={{ fontSize: '9px', marginTop: '2px' }}>{rfcuser}</Text>
                    <Text style={{ fontSize: '9px', marginTop: "2px" }}>{`${dirCalle} ${dirNumero}`}</Text>
                    <Text style={{ fontSize: '9px', marginTop: "2px" }}>{`${dirColonia}`}</Text>
                    <Text style={{ fontSize: '9px', marginTop: "2px" }}>{`${dirLocalidad}, ${dirEstado}`}</Text>
                    <Text style={{ fontSize: '9px', marginTop: "2px" }}>{`${dirCp}`}</Text>
                    <Text style={{ fontSize: '9px', marginTop: "8px" }}>{referencia !== "" ? "Referencia:" + referencia : ""}</Text>

                </View>
                <View style={styles.sectionCPinfo}>


                    <Text style={{ fontSize: '9px', textAlign: 'center' }}>{`Carta Porte-Factura:`} <Text style={{ color: "#F00" }}>{ncarta}</Text></Text>
                    <Text style={{ fontSize: '11px', fontWeight: 'extrabold', textAlign: 'center' }}>Folio Fiscal</Text>
                    <Text style={{ fontSize: '8px', textAlign: 'center' }}>{folioFiscal}</Text>
                    <Text style={{ fontSize: '11px', fontWeight: 'extrabold', textAlign: 'center' }}>No. Certificado Digital</Text>
                    <Text style={{ fontSize: '8px', textAlign: 'center' }}>{serieEmisor}</Text>
                    <Text style={{ fontSize: '11px', fontWeight: 'extrabold', textAlign: 'center' }}>No. Certificado SAT</Text>
                    <Text style={{ fontSize: '8px', textAlign: 'center' }}>{serieSat}</Text>
                    <Text style={{ fontSize: '11px', fontWeight: 'extrabold', textAlign: 'center' }}>Fecha Timbrado</Text>
                    <Text style={{ fontSize: '8px', textAlign: 'center' }}>{fechaTimbrado}</Text>
                </View>
            </View>
            <Text style={{ fontSize: '10px', fontWeight: 'bold', marginLeft: 1 }}>Carta Porte</Text>
            <View style={styles.sectionClientePaga}>

                <View style={styles.sectionClientePagaHeader}>
                    <Text style={{ fontSize: '11px', fontWeight: 'bold', marginLeft: 1 }}>Datos del Cliente / Bill To Customer</Text>
                </View>
                <View style={{ flexDirection: 'row', marginTop: 0 }}>

                    <View style={styles.sectionLabelInfo}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>RFC:</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Nombre:</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Calle:</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Colonia:</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Localidad:</Text>
                    </View>
                    <View style={styles.sectionInfoCuarenta}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{clienterfc}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{clientenombre}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${clientecalle} ${clientenumero}`}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{clientecolonia || "No Aplica"}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${clientecp},${clientelocalidad}`}</Text>
                    </View>


                </View>
                {/*<Text style={{ fontSize: '10px', fontWeight: 'bold', marginLeft: 5 }}>{clientenombre}</Text>
                <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 5 }}>{`${clientenumero} ${clientecalle} ${clientecolonia}  .  ${clientelocalidad} ${clientecp}`}</Text>
                <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 5 }}>{clienterfc}</Text>
                <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 5 }}>{`${clientelocalidad}, ${clientestado}`}</Text>*/}
            </View>

            <View style={{ flexDirection: 'row', marginTop: 5 }}>
                <View style={styles.sectionOrigenDestino}>
                    <View style={styles.sectionClientePagaHeader2}>
                        <Text style={{ width: '100px', fontSize: '11px', fontWeight: 'bold', marginLeft: 1 }}>Origen / Shipper</Text>
                        <Text style={{ width: '75px', fontSize: '11px', fontWeight: 'bold' }}>ID: OR000001</Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>RFC/TAX:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{origenrfc}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Nombre:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{origenlocation}</Text>
                        </View>
                    </View>

                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Dirección:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${origenumero} ${(origenumeroInteior && origenumeroInteior.trim() !='')  ? `Int.${origenumeroInteior}`:"" } ${origencalle} ${origencolonia}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>CP:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${origencp}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Localidad:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${origenciudad}, ${origenestado}, ${origenInternacional ? "USA" : "México"}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Fecha:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${fechaOrigen}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Kms:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>0.0</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.sectionOrigenDestino}>
                    <View style={styles.sectionClientePagaHeader2}>
                        <Text style={{ fontSize: '11px', fontWeight: 'bold', marginLeft: 1 }}>Destino / Consignee</Text>
                        <Text style={{ width: '75px', fontSize: '11px', fontWeight: 'bold' }}>{`ID: DE${zeroPad(2 + paradas.length, 6)}`}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>RFC/TAX:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{destinorfc}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Nombre:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{destinolocation}</Text>
                        </View>
                    </View>

                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Dirección:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${destinonumero} ${(destinoumeroInteior && destinoumeroInteior.trim() !='')  ? `Int.${destinoumeroInteior}`:"" } ${destinocalle} ${destinocolonia}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>CP:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${destinocp}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Localidad:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${destinociudad}, ${destinoestado}, ${destinoInternacional ? "USA" : "México"}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Fecha:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{fechaEntrega}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Kms:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{parseFloat(kms).toFixed(2)}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 10, width: "95vw", backgroundColor: "#d9d9d9" }}>
                <Text style={{ fontSize: '11px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>R E P A R T O S  (Stop Off)</Text>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>

                <View style={{ width: '10%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>KM</Text>
                    </View>
                </View>
                <View style={{ width: '20%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Nombre</Text>
                    </View>
                </View>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>RFC</Text>
                    </View>
                </View>
                <View style={{ width: '47%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Dirección</Text>
                    </View>
                </View>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>ID</Text>
                    </View>
                </View>

            </View>
            {paradas.length === 0 && <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                    <View style={{ width: '10%', height: "auto" }}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>-</Text>
                    </View>
                    <View style={{ width: '20%', height: "auto" }}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>-</Text>
                    </View>
                    <View style={{ width: '12%', height: "auto" }}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>-</Text>
                    </View>
                    <View style={{ width: '47%', height: "auto" }}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>-</Text>
                    </View>
                    <View style={{ width: '12%', height: "auto" }}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>-</Text>
                    </View>
                </View>
            </View>
            }
            {
                paradas.map((e, index) => {
                    return <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                        <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                            <View style={{ width: '10%', height: "auto" }}>
                                <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{e.kms || ""}</Text>
                            </View>
                            <View style={{ width: '20%', height: "auto" }}>
                                <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{e.nombre || ""}</Text>
                            </View>
                            <View style={{ width: '12%', height: "auto" }}>
                                <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{e.rfc || ""}</Text>
                            </View>
                            <View style={{ width: '47%', height: "auto" }}>
                                <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{e.internacional ?
                                    `${e.numero} ${e.calle}. ${e.localidad} ${e.cp}` :
                                    `${e.calle} #${e.numero}, ${e.colonia}. ${e.localidad} ${e.cp}`}</Text>
                            </View>
                            <View style={{ width: '12%', height: "auto" }}>
                                <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{`DE${zeroPad(2 + index, 6)}`}</Text>
                            </View>
                        </View>
                    </View>
                })
            }
            {data.detalles.comentarios !== "" && <View style={{ flexDirection: 'row', marginTop: 1, width: "95vw" }} wrap={false} >
                <View style={styles.sectionComentariosBottom}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', margin: 'auto' }}>Comentarios</Text>
                    </View>
                    <Text style={{ fontSize: '9px', margin: 2 }}>{data.detalles.comentarios}</Text>
                </View>
            </View>
            }
            <View style={{ flexDirection: 'row', marginTop: 5, width: "95vw", backgroundColor: "#d9d9d9" }}>
                <Text style={{ fontSize: '11px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>U N I D A D  /  R E M O L Q U E S (Assets details) </Text>
            </View>

            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Unidad</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{unidad}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Placas</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{unidadplacas}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Año</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{unidadyear}</Text>
                    </View>
                </View>

                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Remolque 1</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{nombreRemolque1}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Placas</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{placasRemolque1}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Remolque 2</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{nombreRemolque2}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Placas</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{placasRemolque2}</Text>
                    </View>
                </View>

            </View>
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Aseguradora</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{aseguradora}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Poliza</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{poliza}</Text>
                    </View>
                </View>
                <View style={{ width: '27%', height: "auto" }} >
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>SCT</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{sct}</Text>
                    </View>
                </View>

                
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>&nbsp;</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{""}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>&nbsp;</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{""}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>&nbsp;</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{""}</Text>
                    </View>
                </View>

            </View>
            <View style={{ flexDirection: 'row', marginTop: 10, width: "95vw", backgroundColor: "#d9d9d9" }}>
                <Text style={{ fontSize: '11px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>M E R C A N C I A S  (Cargo)</Text>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Unidad</Text>
                    </View>

                </View>
                <View style={{ width: '5%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Cant.</Text>
                    </View>

                </View>
                <View style={{ width: '38%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Descripción</Text>
                    </View>

                </View>
                <View style={{ width: '4%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Valor</Text>
                    </View>

                </View>
                <View style={styles.sectionFolioTopSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Fracc. Ar.</Text>
                    </View>

                </View>
                <View style={{ width: '17%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Pedimento</Text>
                    </View>

                </View>
                <View style={styles.sectionFolioTopSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>M. Peligroso</Text>
                    </View>

                </View>
                {/*<View style={styles.sectionFolioTopSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Embalaje</Text>
                    </View>

                </View>*/}
                <View style={{ width: '7%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Peso kg.</Text>
                    </View>

                </View>
            </View>
            {
                mercancias.map(m => {
                    return <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }} wrap={false}>
                        <View style={{ width: '12%', height: "auto" }}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{
                                    CAT_UNIDADES.find(e => e.descripcion === m.unidad) ? `${CAT_UNIDADES.find(e => e.descripcion === m.unidad).codigo} - ${m.unidad}` : `${m.unidad}`

                                }</Text>
                            </View>
                        </View>
                        <View style={{ width: '5%', height: "auto" }}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{m.cantidad}</Text>
                            </View>
                        </View>
                        <View style={{ width: '38%', height: "auto" }}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative" }}>{`${m.csat} - ${m.descripcion}`}</Text>
                            </View>
                        </View>
                        <View style={{ width: '4%', height: "auto" }}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{`$0.0`}</Text>
                            </View>
                        </View>
                        <View style={styles.sectionFolioTopSmall}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{`${m.fraccion}`}</Text>
                            </View>
                        </View>
                        <View style={{ width: '17%', height: "auto" }}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{(m.pedimento !== undefined && m.pedimento!="") ? m.pedimento : pedimentos }</Text>
                            </View>
                        </View>
                        <View style={styles.sectionFolioTopSmall}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{`${m.c_MaterialPeligroso || ""}`}</Text>
                            </View>
                        </View>
                        {/*<View style={styles.sectionFolioTopSmall}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{`${m.c_TipoEmbalaje || ""}`}</Text>
                            </View>
                </View>*/}
                        <View style={{ width: '7%', height: "auto" }}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{`${parseFloat(m.pesoKG).toFixed(2)}`}</Text>
                            </View>
                        </View>
                    </View>
                })
            }
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Total</Text>
                    </View>

                </View>
                <View style={{ width: '5%', height: "auto" }}>
                    <View >
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{
                            mercancias.reduce((sum, current) => { return sum + parseFloat(current.cantidad) }, 0)
                        }</Text>
                    </View>

                </View>
                <View style={{ width: '68%', height: "auto" }}>
                    <View >
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>&nbsp;</Text>
                    </View>

                </View>

                <View style={styles.sectionFolioTopSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Peso Neto&nbsp;</Text>
                    </View>

                </View>
                {/*<View style={styles.sectionFolioTopSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Embalaje</Text>
                    </View>

                </View>*/}
                <View style={{ width: '7%', height: "auto" }}>
                    <View >
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{
                            mercancias.reduce((sum, current) => { return sum + parseFloat(current.pesoKG) }, 0).toFixed(2)
                        }</Text>
                    </View>

                </View>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                <View style={{ width: '85%', height: "auto" }}>
                    <View >
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}></Text>
                    </View>

                </View>
                <View style={styles.sectionFolioTopSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Peso Bruto</Text>
                    </View>

                </View>
                {/*<View style={styles.sectionFolioTopSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Embalaje</Text>
                    </View>

                </View>*/}
                <View style={{ width: '7%', height: "auto" }}>
                    <View >
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{
                            mercancias.reduce((sum, current) => { return sum + parseFloat(current.pesoKG) }, 0).toFixed(2)
                        }</Text>
                    </View>

                </View>
            </View>


            <View style={{ flexDirection: 'row', marginTop: 10, width: "95vw", backgroundColor: "#d9d9d9" }}>
                <Text style={{ fontSize: '11px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>O P E R A D O R (Drivers) </Text>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }} wrap={false}>
                <View style={{ width: '9%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>&nbsp;</Text>
                    </View>

                </View>
                <View style={{ width: '9%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Unidad</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTopCompenzaSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>RFC</Text>
                    </View>
                </View>
                <View style={{ width: '20%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Nombre</Text>
                    </View>
                </View>
                <View style={{ width: '13%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Licencia</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTopBigDetail}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Dirección</Text>
                    </View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                <View style={{ width: '9%', height: "auto" }}>
                    <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Operador</Text>

                </View>
                <View style={{ width: '9%', height: "auto" }}>
                    <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{unidad}</Text>
                </View>
                <View style={styles.sectionFolioTopCompenzaSmall}>
                    <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{operadorrfc}</Text>
                </View>
                <View style={{ width: '20%', height: "auto" }}>
                    <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{operadornombre}</Text>
                </View>
                <View style={{ width: '13%', height: "auto" }}>
                    <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{operadorlicencia}</Text>
                </View>
                <View style={styles.sectionFolioTopBigDetail}>
                    <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{operadorDireccion}</Text>
                </View>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 10, width: "95vw", backgroundColor: "#d9d9d9" }}>
                <Text style={{ fontSize: '11px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>P R O D U C T O S    /    S E R V I C I O S    /    P A R T I D A S   (Line Items)</Text>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }} wrap={false}>
                <View style={styles.sectionFolioTopCompenzaSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Cantidad</Text>
                    </View>
                </View>
                <View style={{ width: '29%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Unidad</Text>
                    </View>
                </View>
                <View style={{ width: '45%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Descripción</Text>
                    </View>
                </View>

                <View style={styles.sectionFolioTopCompenzaSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Importe</Text>
                    </View>
                </View>
            </View>
            {
                conceptos && conceptos.length > 0 && conceptos.map((c, index) => {
                    return <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }} wrap={false}>
                        <View style={styles.sectionFolioTopCompenzaSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{"1.00"}</Text>
                        </View>
                        <View style={{ width: '29%', height: "auto" }}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{"E48 - Unidad de servicio"}</Text>
                        </View>
                        <View style={{ width: '45%', height: "auto" }}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{data.concepto? cat_conceptos.find(cc=>cc.clave===data.concepto) ? `${data.concepto} - ${cat_conceptos.find(cc=>cc.clave===data.concepto).descripcion}`:"78101800 - FLETE" : "78101800 - FLETE"}</Text>
                        </View>
                        <View style={styles.sectionFolioTopCompenzaSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{`$${total}`}</Text>
                        </View>

                    </View>
                })
            }
            <View style={{ flexDirection: 'row', marginTop: 1, width: "95vw" }} wrap={false} >
                <View style={{ flexDirection: 'row', marginTop: 10, width: "75vw" }}>
                    <Text style={{ fontSize: '9px', fontWeight: 'bold', margin: 'auto' }}>Total con letra:</Text>

                    <Text style={{ fontSize: '9px', fontWeight: 'bold', margin: 'auto' }}>({NumeroALetras(total, moneda === "MXN")})</Text>
                </View>
                <View style={{ flexDirection: 'row', marginTop: 10, width: "20vw" }}>
                    <View style={{ flexDirection: 'column', marginTop: 10, width: "10vw", backgroundColor: "#d9d9d9" }}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginRight: '0', textAlign: 'right' }}>{`SubTotal:`}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginRight: '0', textAlign: 'right' }}>{`Total:`}</Text>
                    </View>
                    <View style={{ flexDirection: 'column', marginTop: 10, width: "10vw" }}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginRight: '0', marginLeft: '5', textAlign: 'left' }}>{` $${total}`}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginRight: '0', marginLeft: '5', textAlign: 'left' }}>{` $${total}`}</Text>
                    </View>
                </View>
            </View>

            <View style={{ flexDirection: 'col', marginTop: 10, width: "95vw" }} wrap={false} >
                <View style={{ flexDirection: 'row', marginTop: 10, width: "95vw" }} wrap={false} >
                    <View style={{ flexDirection: 'row', marginTop: 10, width: "25vw" }}>
                        {datosTimbre && <Image style={{ marginLeft: "0px", width: '100%', height: "auto" }} src={`https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/qrgenerator?content=${dataqr}`}></Image>
                        }
                    </View>
                    <View style={{ flexDirection: 'column', marginTop: 10, width: "70vw" }}>
                        <View style={styles.sectionSellosBottomFirst}>
                            <View style={styles.sectionClientePagaHeader}>
                                <Text style={{ fontSize: '9px', fontWeight: 'bold', margin: 'auto' }}>Sello Digital del CFDI</Text>
                            </View>
                            <Text style={{ fontSize: '7px', marginLeft: 1 }}>{sellocfdi}</Text>

                        </View>
                        <View style={styles.sectionSellosBottom}>
                            <View style={styles.sectionClientePagaHeader}>
                                <Text style={{ fontSize: '9px', fontWeight: 'bold', margin: 'auto' }}>Sello Digital del SAT</Text>
                            </View>
                            <Text style={{ fontSize: '7px', marginLeft: 1 }}>{sellosat}</Text>

                        </View>
                        <View style={styles.sectionSellosBottomLast}>
                            <View style={styles.sectionClientePagaHeader}>
                                <Text style={{ fontSize: '8px', fontWeight: 'bold', margin: 'auto' }}>CADENA ORIGINAL DEL COMPLEMENTO DE CERTIFICACION DIGITAL DEL SAT</Text>
                            </View>
                            <Text style={{ fontSize: '7px', marginLeft: 1 }}>{cadenaOriginal}</Text>

                        </View>
                    </View>


                </View>
                <View style={{ width: '95vw', marginTop: '10px' }}>
                    <Text style={{ fontSize: '8px', fontWeight: 'nomal', margin: 'auto' }}>Este documento es una representación impresa de un CFDI</Text>
                    <Text style={{ fontSize: '9px', fontWeight: 'bold', margin: 'auto' }}> Todos los derechos reservados, Prohibida su copia, reproducción total o parcial sin autorización.</Text>
                    <Text style={{ fontSize: '8px', fontWeight: 'normal', margin: 'auto' }}> https://home.transportplustms.com </Text>
                </View>
            </View>



        </Page>
    </Document>;
};

const PDFView = props => {
    const params = useParams();
    const locations = useLocation();
    const [id, setId] = useState(params.id || null);
    //console.log(locations);
    const [objData, setObjData] = useState(null);
    let queryparams = queryString.parse(locations.search);
    const [showTarifa, setShowTarifa] = useState(queryparams.t);
    const [concepto, setConcepto] = useState(queryparams.c);

    const getData = async () => {
        let r = null;
        if (id) {
            console.log("getting viaje");
            await getViajeById(id).then(resp => {
                r = { id, ...resp.data() };

            }).catch(err => {

            });

            if (r && r.general && r.general.clienteId) {
                await getClienteByID(r.general.clienteId).then(resp => {
                    let clienteinfo = resp.data();
                    r = { ...r, clienteinfo };
                }).catch(err => { });
            }

            if(concepto && concepto.length>0){
                r.concepto=concepto;
            }

            if (r.datos_viaje && r.datos_viaje.origenId) {
                await getUbicacionesById(r.datos_viaje.origenId).then(resp => {
                    let origeninfo = resp.data();

                    r = { ...r, origeninfo };
                }).catch(err => { });
            }

            if (r.datos_viaje && r.datos_viaje.destinoId) {
                await getUbicacionesById(r.datos_viaje.destinoId).then(resp => {
                    let destinoinfo = resp.data();

                    r = { ...r, destinoinfo };
                }).catch(err => { });
            }

            /*if (r.detalles.unidadId && r.detalles.unidadId !== "") {
                await getUnidadByID(r.detalles.unidadId).then(resp => {
                    let unidadinfo = resp.data();
                    r = { ...r, unidadinfo };
                }).catch(err => { });
            }*/

            let operadorid = null;
            if (r.detalles && r.detalles.operador1Id && r.detalles.operador1Id.length > 5) {
                operadorid = r.detalles.operador1Id;
            } else if (r.detalles && r.detalles.operador2Id && r.detalles.operador2Id.length > 5) {
                operadorid = r.detalles.operador2Id;
            }
            if (operadorid) {
                await getOperadorById(operadorid).then(resp => {
                    let operadorinfo = resp.data();
                    r = { ...r, operadorinfo };
                }).catch(err => { });
            }

            if (r.paradas) {
                console.log("paradas", r.paradas);
            }

            let dataremolque1 = null;
            let dataremolque2 = null;
            if (r.carta_porte && r.carta_porte.remolque1 !== "") {
                await getRemolquesByNombreAndUid(r.uid, r.carta_porte.remolque1).then(snapshot => {
                    snapshot.forEach(document => {
                        dataremolque1 = document.data();
                        dataremolque1.id = document.id;
                        if(r.datos_viaje.claseViaje==="Marítimo-puertos" && r.carta_porte.contenedor1!==""){
                            dataremolque1.nombre=r.carta_porte.contenedor1;
                        }
                    })
                }).catch(err => { });
            }
            if (r.datos_viaje.claseViaje === "Marítimo-puertos" && r.carta_porte.contenedor1 !== "") {
                dataremolque1 = { nombre: r.carta_porte.contenedor1, placas: r.carta_porte.placas1 }
                //dataremolque1.nombre = r.carta_porte.contenedor1;
            }

            if (r.carta_porte && r.carta_porte.remolque2 !== "") {
                await getRemolquesByNombreAndUid(r.uid, r.carta_porte.remolque2).then(snapshot => {
                    snapshot.forEach(document => {
                        dataremolque2 = document.data();
                        dataremolque2.id = document.id;
                        if(r.datos_viaje.claseViaje==="Marítimo-puertos" && r.carta_porte.contenedor2!==""){
                            dataremolque2.nombre=r.carta_porte.contenedor2;
                        }
                    })
                }).catch(err => { });
            }


            if (dataremolque1 && dataremolque2) {
                r.remolque1 = dataremolque2;
                r.remolque2 = dataremolque1;
            } else if (dataremolque1) {
                r.remolque1 = dataremolque1;
            } else if (dataremolque2) {
                r.remolque1 = dataremolque2;
            }
            r.showTarifa = parseBooleans(showTarifa);

            await getUserByUid(r.uid).then(snapshot => {
                let usuarioInfo = {};
                snapshot.forEach(document => {
                    usuarioInfo = document.data();
                    usuarioInfo.id = document.id;
                })

                r = { ...r, usuarioInfo };
            }).catch(err => { });
            if (r.usuarioInfo && queryparams.rfc) {
                if (r.usuarioInfo.razones && r.usuarioInfo.razones.length > 0) {
                    let credenciales = null;
                    r.usuarioInfo.razones.forEach(element => {
                        if (element.Rfc === queryparams.rfc) {
                            credenciales = element;
                        }
                    });

                    if (credenciales) {
                        r.usuarioInfo.credenciales = credenciales;
                    }
                }
            }
            if (r.detalles.unidadId && r.detalles.unidadId !== "") {
                await getUnidadByID(r.detalles.unidadId).then(resp => {
                    let unidadinfo = resp.data();
                    r = { ...r, unidadinfo };
                }).catch(err => { });
            }

            let datosTimbrado = {
                usoCFDI: queryparams.ucfdi || "",
                metodoPago: queryparams.mp || "",
                dolarprice: queryparams.c || "",
                moneda: queryparams.m || "",
                fp: queryparams.fp || "",
                iva: queryparams.i || "0",
                retencion: queryparams.r || "0",
                concepto: queryparams.l || "",
                conceptoObj: cat_conceptos.find(e => e.clave == queryparams.l)
            };
            r = { ...r, datosTimbrado };


            let datatimbrado = await asyncparser(r);
            if (datatimbrado) {
                r = { ...r, datatimbrado };
                //  r.datatimbrado = datatimbrado;
            }
            /* if (certificadoFolio) {
           //      r.certificadoFolio = certificadoFolio;
             } else {
                 r.certificadoFolio = "";
             }*/
            r.fe = "";

            if (id && !r.id) {
                r.id = id;
            }
            setObjData(r);
        }

    }

    useEffect(() => {
        // console.log("num a letra",NumeroALetras(12022.60,false));
        console.log("getting data");
        getData();
    }, []);

    const asyncparser = async (obj) => {
        if (obj.cpfacuturado) {
            let xmlstring = obj.cpfacuturado.timbreXML;
            //let fixedXml=xmlstring.replace('cfdi:', '');
            let fixedXml = xmlstring.split('cfdi:').join('');
            fixedXml = xmlstring.split('$').join('obj');

            let response = null;
            await xml2js.parseString(fixedXml, async (err, result) => {
                if (err) {
                    console.log(err);
                }
                else {
                    // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                    let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                    console.log("primer xml", result);
                    response = objdataxml;
                    // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                }

            });

            let xmlstring2 = obj.cpfacuturado.cfdixml;
            //let fixedXml=xmlstring.replace('cfdi:', '');
            let fixedXml2 = xmlstring2.split('cfdi:').join('');
            fixedXml2 = xmlstring2.split('$').join('obj');
            await xml2js.parseString(fixedXml2, async (err, result) => {
                if (err) {
                    console.log(err);
                }
                else {
                    // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                    let objdataxml = result["cfdi:Comprobante"]["$"];
                    let UsoCFDI = result["cfdi:Comprobante"]["cfdi:Receptor"][0]["$"]["UsoCFDI"];
                    let Conceptos = result["cfdi:Comprobante"]["cfdi:Conceptos"];
                    let Impuestos = result["cfdi:Comprobante"]["cfdi:Impuestos"];

                    let DatCCP = result["cfdi:Comprobante"]["cfdi:Complemento"][0]['cartaporte20:CartaPorte'];
                    console.log("DataCCP", DatCCP);

                    let dataAutotransporte = null;

                    if (DatCCP && DatCCP[0] && DatCCP[0]['cartaporte20:Mercancias'] && DatCCP[0]['cartaporte20:Mercancias'][0]['cartaporte20:Autotransporte']) {
                        dataAutotransporte = DatCCP[0]['cartaporte20:Mercancias'][0]['cartaporte20:Autotransporte'][0];
                    }

                    console.log("data conceptos", Conceptos)
                    console.log("segundo xml", result);
                    if (response) {
                        response = { ...response, ...objdataxml, UsoCFDI, Conceptos, Impuestos, dataAutotransporte };
                    }
                    //response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                }

            });
            console.log("response xml", response);
            return response;
        } else {
            return null;
        }

    }

    useEffect(() => {
        if (objData) {
            console.log("data to pdf", objData);
        }
    }, [objData])

    /**
     *  <PDFDownloadLink document={<MyDoc />} fileName="somename.pdf">
          {({ blob, url, loading, error }) =>
            loading ? 'Loading document...' : 'Download now!'
          }
        </PDFDownloadLink>
     <PDFViewer fileName="myPdf.pdf" style={{ width: '100vw', height: '99vh' }}>
                        <CartaPorte objdata={objData} algo="hola mundo" />
                    </PDFViewer>
    
     */

    return (
        <div>
            {

                objData &&
                <PDFViewer style={{ width: '100vw', height: '99vh' }}>
                    <CartaPorte objdata={objData} algo="hola mundo" />
                </PDFViewer>
            }
        </div>);

}

export default PDFView;