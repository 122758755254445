import React, { useEffect, useState } from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import { useParams, useLocation } from 'react-router-dom';
import { getInvoiceByID } from '../../controllers/innvoices';
import { getClienteByID } from '../../controllers/clientes';
import { getUbicacionesById } from '../../controllers/ubicaciones';
import { getUnidadByID, getRemolquesByNombreAndUid } from '../../controllers/unidades';
import { getUserByUid } from '../../controllers/usuarios';
import { getOperadorById } from '../../controllers/operadores';
import moment from 'moment';
import qrcode from 'qrcode.react';
import queryString from 'query-string';
import xml2js from 'xml2js';

import { parseBooleans } from 'xml2js/lib/processors';
import zIndex from '@material-ui/core/styles/zIndex';
//var QRCode = require('qrcode.react');
// Create styles
const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        marginTop: 15,
        paddingBottom: '30px',
        marginLeft: 15,
        paddingRight: 15,
        // backgroundColor: '#E4E4E4'
    },
    sectionHeader: {
        width: '98%',
        flexDirection: 'row',
    },
    sectionLogo: {
        margin: 0,
        padding: '0',
        width: '20%',
       // border: '1px solid black',
        flexGrow: 1
    },
    sectionUserInfo: {
        width: '40%',
        margin: 2,
        padding: 10,
        flexGrow: 2
    },
    sectionCPinfo: {
        margin: 10,
        width: '30%',
        padding: 2,
        //border: '1px solid black',
        flexGrow: 1
    },
    sectionFolioTopSmall: {
        // margin: 10,
        width: '9.11%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTopCompenzaSmall: {
        // margin: 10,
        width: '13.22%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTopBigDetail: {
        // margin: 10,
        width: '36.22%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTop: {
        // margin: 10,
        width: '11.11%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTopMedium: {
        // margin: 10,
        width: '18.5vw',
        height: "35px",
        borderRight: '1px solid black',
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
    },
    sectionFolioTopMediumNoBorder: {
        // margin: 10,
        width: '18.5vw',
        height: "35px",
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
    },
    sectionSellosBottomFirst: {
        // margin: 10,
        marginTop: '0px',
        width: 'auto',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionSellosBottom: {
        // margin: 10,
        width: 'auto',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionSellosBottomLast: {
        // margin: 10,
        width: 'auto',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionClientePaga: {
        // margin: 10,
        width: '95vw',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionClientePagaHeader: {
        width: '100%',
        height: "auto",
        borderBottom: '1px solid black',
        //   flexGrow: 1
    },

    sectionCol: {
        margin: 0,
        width: '10vw',
        height: "140px",
        border: '1px solid black',

        // flexGrow: 1
    },
    sectionColMiddle: {
        margin: 0,
        width: '13vw',
        height: "140px",
        borderRight: '1px solid black',
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        // flexGrow: 1
    },
    sectionColMiddleX: {
        margin: 0,
        width: '33vw',
        height: "140px",
        borderRight: '1px solid black',
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        // flexGrow: 1
    },
    sectionOrigenDestino: {
        marginRight: 5,
        width: '47vw',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionLabelInfo: {
        width: '14%',
        height: "auto",
        textAlign: 'right',
        backgroundColor: "#d9d9d9",
        color: "black"
    },
    sectionLabelInfosmall: {
        width: '29%',
        height: "auto",
        textAlign: 'right',
        backgroundColor: "#d9d9d9",
        color: "black"
    },
    sectionInfoSmall: {
        width: '71%',
        marginLeft: '5px',
        height: "auto",
        textAlign: 'left',
        color: "black"
    },
    sectionInfoNoventa: {
        width: '85%',
        marginLeft: '5px',
        height: "auto",
        textAlign: 'left',
        color: "black"
    },
    sectionInfoCuarenta: {
        width: '35%',
        marginLeft: '5px',
        height: "auto",
        textAlign: 'left',
        color: "black"
    },
    sectionClientePagaHeader: {
        width: '100%',
        height: "auto",
        borderBottom: '1px solid black',
        backgroundColor: "#203764",
        color: "#FFF"
        //   flexGrow: 1
    },
    sectionbajoconceptoizquierda: {
        margin: "0px",
        width: '69vw',
        height: "55px",
        //  border: '1px solid black',
    },
    sectionbajoconceptoderecha: {
        margin: 0,
        width: '26vw',
        height: "50px",
        borderRight: '1px solid black',
        borderLeft: '1px solid black',
        borderBottom: '1px solid black',
    },
    docutitulo: {
        margin: 5,
        fontSize: 10,
        fontWeight: 900,
        width: '10vw',
        height: "auto",
    },
    docutexto: {
        margin: 5,
        fontSize: 9,
        width: '80vw',
        height: "auto",
    }
});
const CartaPorte = props => {
    const CAT_METODOSPAGO = [
        {
            "Name": "Pago en parcialidades ó diferido",
            "Value": "PPD"
        },
        {
            "Name": "Pago en una sola exhibición",
            "Value": "PUE"
        }
    ];
    const CAT_USOCFDI = [
        {
            "Natural": true,
            "Moral": false,
            "Name": "Honorarios médicos, dentales y gastos hospitalarios.",
            "Value": "D01"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos médicos por incapacidad o discapacidad",
            "Value": "D02"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos funerales.",
            "Value": "D03"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Donativos.",
            "Value": "D04"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
            "Value": "D05"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Aportaciones voluntarias al SAR.",
            "Value": "D06"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Primas por seguros de gastos médicos.",
            "Value": "D07"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos de transportación escolar obligatoria.",
            "Value": "D08"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
            "Value": "D09"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Pagos por servicios educativos (colegiaturas)",
            "Value": "D10"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Adquisición de mercancias",
            "Value": "G01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Devoluciones, descuentos o bonificaciones",
            "Value": "G02"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Gastos en general",
            "Value": "G03"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Construcciones",
            "Value": "I01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Mobilario y equipo de oficina por inversiones",
            "Value": "I02"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Equipo de transporte",
            "Value": "I03"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Equipo de computo y accesorios",
            "Value": "I04"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Dados, troqueles, moldes, matrices y herramental",
            "Value": "I05"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Comunicaciones telefónicas",
            "Value": "I06"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Comunicaciones satelitales",
            "Value": "I07"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Otra maquinaria y equipo",
            "Value": "I08"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Por definir",
            "Value": "P01"
        }
    ]
    const CAT_FORMASPAGO = [
        {
            "Name": "Efectivo",
            "Value": "01"
        },
        {
            "Name": "Cheque nominativo",
            "Value": "02"
        },
        {
            "Name": "Transferencia electrónica de fondos",
            "Value": "03"
        },
        {
            "Name": "Tarjeta de crédito",
            "Value": "04"
        },
        {
            "Name": "Monedero electrónico",
            "Value": "05"
        },
        {
            "Name": "Dinero electrónico",
            "Value": "06"
        },
        {
            "Name": "Vales de despensa",
            "Value": "08"
        },
        {
            "Name": "Dación en pago",
            "Value": "12"
        },
        {
            "Name": "Pago por subrogación",
            "Value": "13"
        },
        {
            "Name": "Pago por consignación",
            "Value": "14"
        },
        {
            "Name": "Condonación",
            "Value": "15"
        },
        {
            "Name": "Compensación",
            "Value": "17"
        },
        {
            "Name": "Novación",
            "Value": "23"
        },
        {
            "Name": "Confusión",
            "Value": "24"
        },
        {
            "Name": "Remisión de deuda",
            "Value": "25"
        },
        {
            "Name": "Prescripción o caducidad",
            "Value": "26"
        },
        {
            "Name": "A satisfacción del acreedor",
            "Value": "27"
        },
        {
            "Name": "Tarjeta de débito",
            "Value": "28"
        },
        {
            "Name": "Tarjeta de servicios",
            "Value": "29"
        },
        {
            "Name": "Aplicación de anticipos",
            "Value": "30"
        },
        {
            "Name": "Intermediarios",
            "Value": "31"
        },
        {
            "Name": "Por definir",
            "Value": "99"
        }
    ]
    const data = props.objdata;

    

    let datacliente = data.clienteinfo;
    let dataviaje = data.datos_viaje;
    let datacp = data.carta_porte;
    let origeninfo = data.origeninfo;
    let destinoinfo = data.destinoinfo;
    let usuarioInfo = data.usuarioInfo;
    let unidadinfo = data.unidadinfo;
    let operadorinfo = data.operadorinfo;
    let paradasinfo = data.paradas;

    let remolqueString = "";
    
    if (data.carta_porte) {
        if (data.carta_porte.remolque1 !== "") {
            remolqueString += `${data.carta_porte.messageRemolque1}:${data.carta_porte.remolque1} `;
        }
        if (data.carta_porte.remolque2 !== "") {
            remolqueString += `${data.carta_porte.messageRemolque2}:${data.carta_porte.remolque2} `;
        }
    }


    if (!datacliente) {
        datacliente = {};
    }
    if (!origeninfo) {
        origeninfo = {};
    }
    if (!destinoinfo) {
        destinoinfo = {};
    }
    if (!usuarioInfo) {
        //usuarioInfo = { razon: "NORAZON" };
    }

    let datatimbre = {
        TaxStamp: {}
    };
    if (data.facturamaCP) {
        datatimbre = data.facturamaCP;
    }
    let cpFactOBJ = null;
    if (data.facuturado) {
        cpFactOBJ = data.facuturado;

        let xmlData = data.facuturado.timbreXML;



    }
    const [credenciales, setCredenciales] = useState(usuarioInfo.credenciales || null);
    const [logotipo, setLogotipo] = useState(usuarioInfo.logotipo || null);
    const [referencia, setReferencia] = useState(data.referencia || "");
    const [ncarta, setNcarta] = useState(data.invoiceNumber || "-");


    const [clientenombre, setClienteNombre] = useState(datacliente.nombre || "-");
    const [clientenumero, setClienteNumero] = useState(datacliente.numero || "");
    const [clientecalle, setClientecalle] = useState(datacliente.calle || "");
    const [clientecolonia, setClientecolonia] = useState(datacliente.colonia || "");
    const [clientelocalidad, setClienteLocalidad] = useState(datacliente.localidad || "");
    const [clientecp, setClientecp] = useState(datacliente.cp || "");
    const [clienterfc, setClienterfc] = useState(datacliente.rfc || "XEXX010101000");
    const [clientestado, setClienteestado] = useState(datacliente.estado || "");

    const [origenInternacional, setOrigenInternaciona] = useState(origeninfo.internacional);
    const [origenlocation, setOrigenlocation] = useState(origeninfo.nombre || "");
    const [origenciudad, setOrigenCiudad] = useState(origeninfo.localidad || "");
    const [origenestado, setOrigenEstado] = useState(origeninfo.estado || "");
    const [origenumero, setOrigennumero] = useState(origeninfo.numero || "");
    const [origencalle, setOrigencalle] = useState(origeninfo.calle || "");
    const [origencolonia, setOrigencolonia] = useState(origeninfo.colonia || "");
    const [origencp, setOrigencp] = useState(origeninfo.cp || "");
    const [origenrfc, setOrigenrfc] = useState(origeninfo.rfc || "");
    const [origennombre, setOrigennombre] = useState(origeninfo.nombre || "");

    const [destinoInternacional, setDestinoInternaciona] = useState(destinoinfo.internacional);
    const [destinolocation, setDestinolocation] = useState(destinoinfo.nombre || "");
    const [destinociudad, setDestinoCiudad] = useState(destinoinfo.localidad || "");
    const [destinoestado, setDestinoEstado] = useState(destinoinfo.estado || "");
    const [destinonumero, setDestinonumero] = useState(destinoinfo.numero || "");
    const [destinocalle, setDestinocalle] = useState(destinoinfo.calle || "");
    const [destinocolonia, setDestinocolonia] = useState(destinoinfo.colonia || "");
    const [destinocp, setDestinocp] = useState(destinoinfo.cp || "");
    const [destinorfc, setDestinoRfc] = useState(destinoinfo.rfc || "");
    const [destinnombre, setDestinonombre] = useState(destinoinfo.nombre || "");

   
   
    const [conceptos, setConceptos] = useState( []);

    const [razon, setRazon] = useState( "");
    const [rfcuser, setRfcUser] = useState("");
    let direcccionfixed = `${usuarioInfo.credenciales.direccion.calle} ${usuarioInfo.credenciales.direccion.numero} Colonia ${usuarioInfo.credenciales.direccion.colonia} CP ${usuarioInfo.credenciales.direccion.cp} ${usuarioInfo.credenciales.direccion.localidad} ${usuarioInfo.credenciales.direccion.estado}`;
    const [dirCalle, setDirCalle] = useState(usuarioInfo.credenciales.direccion.calle || " ");
    const [dirNumero, setDirNumero] = useState(usuarioInfo.credenciales.direccion.numero || " ");
    const [dirColonia, setDirColonia] = useState(usuarioInfo.credenciales.direccion.colonia || " ");
    const [dirCp, setDirCp] = useState(usuarioInfo.credenciales.direccion.cp || " ");
    const [dirLocalidad, setDirLocalidad] = useState(usuarioInfo.credenciales.direccion.localidad || " ");
    const [dirEstado, setDirEstado] = useState(usuarioInfo.credenciales.direccion.estado || " ");

    const [direccioncp, setdireccioncp] = useState(direcccionfixed || "--");
    const [showTarifa, setShowTarifa] = useState(data.showTarifa);


    useEffect(() => {
        // console.log("props", props);
    }, []);

    return <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.sectionHeader}>
                <View style={styles.sectionLogo}>
                    <Image style={{ width: '100%', height: "auto",marginTop:'10px' }} src={`data:image/png;base64, ${logotipo || ""}`}></Image>
                </View>
                <View style={styles.sectionUserInfo}>
                    <Text style={{ fontSize: '14px', fontWeight: 'bold' }}>{razon}</Text>
                    <Text style={{ fontSize: '9px', marginTop: '2px' }}>{rfcuser}</Text>
                    <Text style={{ fontSize: '9px', marginTop: "2px" }}>{`${dirCalle} ${dirNumero}`}</Text>
                    <Text style={{ fontSize: '9px', marginTop: "2px" }}>{`${dirColonia}`}</Text>
                    <Text style={{ fontSize: '9px', marginTop: "2px" }}>{`${dirLocalidad}, ${dirEstado}`}</Text>
                    <Text style={{ fontSize: '9px', marginTop: "2px" }}>{`${dirCp}`}</Text>
                    <Text style={{ fontSize: '9px', marginTop: "8px" }}>{referencia !== "" ? "Reference:" + referencia : ""}</Text>

                </View>
                <View style={styles.sectionCPinfo}>


                    <Text style={{ fontSize: '9px', textAlign: 'center' }}>{`Invoice No:`} <Text style={{ color: "#F00" }}>{ncarta}</Text></Text>
                    <Text style={{ fontSize: '9px', textAlign: 'center' }}>{`Date:${data.date||""}`} </Text>
                   
                </View>
            </View>
            <View style={{marginTop:'5px'}}></View>
            <View style={styles.sectionClientePaga}>

                <View style={styles.sectionClientePagaHeader}>
                    <Text style={{ fontSize: '11px', fontWeight: 'bold', marginLeft: 1 }}> Bill To Customer</Text>
                </View>
                <View style={{ flexDirection: 'row', marginTop: 0 }}>

                    <View style={styles.sectionLabelInfo}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Name:</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Adress:</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>City:</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>State:</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Zip Code:</Text>
                    </View>
                    <View style={styles.sectionInfoCuarenta}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{clientenombre}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${clientenumero} ${clientecalle} `}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{clientelocalidad || ""}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{clientestado || ""}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${clientecp}`}</Text>
                    </View>
                   
                   

                </View>
                {/*<Text style={{ fontSize: '10px', fontWeight: 'bold', marginLeft: 5 }}>{clientenombre}</Text>
                <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 5 }}>{`${clientenumero} ${clientecalle} ${clientecolonia}  .  ${clientelocalidad} ${clientecp}`}</Text>
                <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 5 }}>{clienterfc}</Text>
                <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 5 }}>{`${clientelocalidad}, ${clientestado}`}</Text>*/}
            </View>

           {data.isViajeCargado && <View style={{ flexDirection: 'row', marginTop: 5 }}>
                <View style={styles.sectionOrigenDestino}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '11px', fontWeight: 'bold', marginLeft: 1 }}> Shipper</Text>
                    </View>
                   
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Name:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{origenlocation}</Text>
                        </View>
                    </View>

                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Adress:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${origenumero} ${origencalle} ${origencolonia}`}</Text>
                        </View>
                    </View>                   
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>City:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${origenciudad}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>State:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${origenestado}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>ZIP:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${origencp}`}</Text>
                        </View>
                    </View>
                    
                </View>
                <View style={styles.sectionOrigenDestino}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '11px', fontWeight: 'bold', marginLeft: 1 }}>Consignee</Text>
                    </View>
                    
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Name:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{destinolocation}</Text>
                        </View>
                    </View>

                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Adress:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${destinonumero} ${destinocalle} ${destinocolonia}`}</Text>
                        </View>
                    </View>
                    
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>City:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${destinociudad}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>State:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${destinoestado}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>ZIP:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${destinocp}`}</Text>
                        </View>
                    </View>
                    
                    
                </View>
            </View>
            }

            <View style={{ flexDirection: 'row', marginTop: 10, width: "95vw", backgroundColor: "#d9d9d9" }}>
                <Text style={{ fontSize: '11px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>Line Items</Text>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }} wrap={false}>
               
                <View style={{ width: '40%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Description</Text>
                    </View>
                </View>
                <View style={{ width: '20%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Quantity</Text>
                    </View>
                </View>
                <View style={{ width: '20%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Unit Price / Rate</Text>
                    </View>
                </View>
                <View style={{ width: '20%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Amount</Text>
                    </View>
                </View>

                
            </View>
            {
                data.items.map(c => {
                    return <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }} wrap={false}>
                       
                        <View style={{ width: '40%', height: "auto" }}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{ c.descripcion ? c.descripcion: "-"}</Text>
                        </View>
                        <View style={{ width: '20%', height: "auto" }}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{ c.quanitty ? c.quanitty :"-"}</Text>
                        </View>
                        <View style={{ width: '20%', height: "auto" }}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{ c.unitprice ? c.unitprice :"-"}</Text>
                        </View>
                        <View style={{ width: '20%', height: "auto" }}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{ c.currency ? c.currency :"-"}</Text>
                        </View>
                    </View>
                })
            }
            <View style={{ flexDirection: 'row', marginTop: 10, width: "95vw" }} wrap={false} >
              
                <View style={{ flexDirection: 'row', marginTop: 10, width: "20vw",marginLeft:'73vw' }}>
                    <View style={{ flexDirection: 'column', marginTop: 10, width: "10vw", backgroundColor: "#d9d9d9" }}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginRight: '0', textAlign: 'right' }}>{`SubTotal:`}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginRight: '0', textAlign: 'right' }}>{`TAX %:`}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginRight: '0', textAlign: 'right' }}>{`Balance Due:`}</Text>
                    </View>
                    <View style={{ flexDirection: 'column', marginTop: 10, width: "10vw" }}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginRight: '0', marginLeft: '5', textAlign: 'left' }}>{data.subtotal || "$0.00"}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginRight: '0', marginLeft: '5', textAlign: 'left' }}>{`${data.taxRate || "0"}%`}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginRight: '0', marginLeft: '5', textAlign: 'left' }}>{data.total || "$0.00"}</Text>
                    </View>
                </View>

            </View>
           


        </Page>
    </Document>;
};

const PDFView = props => {
    const params = useParams();
    const locations = useLocation();
    const [id, setId] = useState(params.id || null);
    //console.log(locations);
    const [objData, setObjData] = useState(null);
    let queryparams = queryString.parse(locations.search);
    const [showTarifa, setShowTarifa] = useState(queryparams.t);

    const getData = async () => {
        let r = null;
        if (id) {
            console.log("getting innvoice");
            await getInvoiceByID(id).then(resp => {
                r = { id, ...resp.data() };

            }).catch(err => {

            });

            console.log("getting user uid",r.uid);
            await getUserByUid(r.uid).then(snapshot => {
                let usuarioInfo = {};
                snapshot.forEach(document => {
                    usuarioInfo = document.data();
                    usuarioInfo.id = document.id;
                })

                r = { ...r, usuarioInfo };
            }).catch(err => { });
            if (r.usuarioInfo ) {
                if (r.usuarioInfo.razones && r.usuarioInfo.razones.length > 0) {
                    let credenciales = null;
                    r.usuarioInfo.razones.forEach((element,index) => {
                        if (index===0) {
                            credenciales = element;
                        }
                    });

                    if (credenciales) {
                        r.usuarioInfo.credenciales = credenciales;
                    }
                }
            }

            if(r.clienteId){
                await getClienteByID(r.clienteId).then(resp => {
                    let clienteinfo = resp.data();
                    r = { ...r, clienteinfo };
                }).catch(err => { });
            }


            if (r.origenId) {
                await getUbicacionesById(r.origenId).then(resp => {
                    let origeninfo = resp.data();
                    r = { ...r, origeninfo };
                }).catch(err => { });
            }

            if (r.destinoId) {
                await getUbicacionesById(r.destinoId).then(resp => {
                    let destinoinfo = resp.data();
                    r = { ...r, destinoinfo };
                }).catch(err => { });
            }
            
        
            setObjData(r);
        }

    }

    useEffect(() => {
        // console.log("num a letra",NumeroALetras(12022.60,false));
        console.log("getting data");
        getData();
    }, []);

 

    useEffect(() => {
        if (objData) {
            // console.log("data to pdf",objData);
        }
    }, [objData])



    return (
        <div>

            {

                objData &&
                <PDFViewer style={{ width: '100vw', height: '99vh' }}>
                    <CartaPorte objdata={objData} algo="hola mundo" />
                </PDFViewer>
            }
        </div>);

}

export default PDFView;