import { firebase } from '../configs/firebase';

export const addIngreso = (data) => {
    let dataaux = { ...data, createAt: new Date(), active: true };
    var db = firebase.firestore();
    return db.collection('ingresos').add(dataaux);
}

export const updateIngreso = (id, data) => {
    let dataaux;
    if (data.deleted) {
        dataaux = { ...data, udpateAt: new Date(), active: false };
    } else {
        dataaux = { ...data, udpateAt: new Date(), active: true };
    }
    var db = firebase.firestore();
    return db.collection('ingresos').doc(id).update(dataaux);
}

export const getAllIngresosByUserDateFilter = (uid, init, end) => {
    //  console.log("ingresos datefilter "+init+" "+end+" user:"+uid);
    init.setHours(0, 0, 0);
    end.setHours(23, 59, 59);
    var db = firebase.firestore();
    return db.collection('ingresos').where("uid", "==", uid).where("fecha", ">=", init).where("fecha", "<=", end).orderBy("fecha", 'asc').get();
}

export const getIngresoById = (id) => {
    var db = firebase.firestore();
    return db.collection("ingresos").doc(id).get();
}


export const deleteIngreso = (id) => {
    var db = firebase.firestore();
    return db.collection('ingresos').doc(id).update({uid:"deleted"});
}

export const addDestino = (data) => {
    let dataaux = { ...data, createAt: new Date(), active: true };
    var db = firebase.firestore();
    return db.collection('destino_ingreso').add(dataaux);
}

export const getAllDestinosByUser = (uid) => {
    var db = firebase.firestore();
    return db.collection('destino_ingreso').where("uid", "==", uid).get();
}


export const reactivarDeuda = async (listaviajes,listCFDIS)=>{
    var db = firebase.firestore();
    var batch=db.batch();
    listaviajes.forEach(element => {
        var docRef = db.collection("viajes").doc(element); 
        batch.update(docRef,{status:'Facturado',pagos:[]});
      
    });
    listCFDIS.forEach(element => {
        var docRef = db.collection("cfdinormal").doc(element); 
        batch.update(docRef,{status:'Facturado',pagos:[]});
      
    });

    await batch.commit();  //Done
    return true;
}   

export const pagarViajes = async (listaviajes)=>{
    var db = firebase.firestore();
    var batch=db.batch();
    listaviajes.forEach(element => {
        if(element.isCFDI){
            var docRef = db.collection("cfdinormal").doc(element.id); //automatically generate unique id
            batch.update(docRef,{status:'Pagado'});
        }else{
            var docRef = db.collection("viajes").doc(element.id); //automatically generate unique id
            batch.update(docRef,{status:'Pagado'});
        }
      
    });
    await batch.commit();  //Done
    return true;
}   