import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import CurrencyInput from 'react-currency-input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getPendientes, addViaje } from '../../../controllers/viajes';
import { getUserByUid } from '../../../controllers/usuarios';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles';
import { getUidMaster } from '../../../session/session_controller';
import { firebase } from '../../../configs/firebase';


const ModalCombustible = (props) => {
    //constantes
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            width: '100%',
            paddingLeft: 0,
            paddingRight: 0,
        },
        paperrootfull: {
            width: '100%',
            margin: '5px',
            padding: '30px',
            display: 'flex'
        },

        paperroot: {
            width: '33%',
            display: 'flex'
        },
        paper: {
            minHeight: 80,
            height: 'auto',
            width: '100%',
        },
        paperscontainer: {
            // width: '100%'
            //overflow: 'hidden'
            flex: 1
        },
        title: {
            height: 30,
            backgroundColor: '#dcdcdc',
            paddingLeft: 10,
            paddingTop: 5,
        },
        label: {
            fontSize: '12px',
            paddingLeft: 3,
            paddingTop: 7,
            width: '38%',
        },

        input: {
            display: 'inline-block',
            float: 'right',
            width: '60%',
            height: 30,
            marginTop: 4,
            marginRight: 2
        },
        inputbig: {
            width: '100%',
            height: 30,
            marginTop: 4,
            marginRight: 2
        },
        inputsmall: {
            display: 'inline-block',
            float: 'right',
            width: '50%',
            height: 30,
            marginTop: 4,
            marginRight: 2
        },
        inputwithextra: {
            display: 'inline-block',
            float: 'right',
            width: '30%',
            height: 30,
            marginRight: 2
        },
        inputdate: {
            width: '99%',
            marginTop: 4,
            marginLeft: 0,
            marginRight: 2,
            height: '30px',
        }
    }));
    const classes = useStyles();
    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);

    const [tipo, setTipo] = useState("autoabasto");
    const [litros, setLitros] = useState(0);
    const [precioLitro, setPrecioLitro] = useState("");
    const [precioLitroFloat, setPrecioLitroFloat] = useState(0);
    //estados propios del formulario
    const [cargas, setCargas] = useState([]);


    const handleChangeCurrency = (event, maskedvalue, floatvalue) => {
        setPrecioLitro(maskedvalue);
        setPrecioLitroFloat(floatvalue);
    }
    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);




    useEffect(() => {
        setIsOpen(props.isOpen);
        setCargas(props.cargas || []);

    }, [props]);

    const getData = async () => {
        getPendientes(uid).then(snapshot => {
            console.log("Cantidad de pendientes", snapshot.docs.length);
        }).catch(err => console.log(err));
    }
    useEffect(() => {
        setTipo("autoabasto");
        setPrecioLitro("");
        setPrecioLitroFloat(0);
        
        if (!isOpen) {
            props.toggle();
        } else {
            if (uid)
                getData();
        }
    }, [isOpen]);

    const aceptarCarga = async (id) => {
        window.location.href = '/viaje?carga=' + id;
    }

    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Cargas de Combustible" : `Cargas de Combustible`}
            </ModalHeader>

            <ModalBody>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div class="dataTable twoCol">
                        <div class="dataTitle"><p className="fixedP">Cargas de Combustible</p>
                        </div>

                        <div class="dataBox">

                            <div class="dataRow">
                                <p className="fixedP">Tipo</p>
                                <div class="fieldInput">
                                    <select name='segmento' onChange={(e) => setTipo(e.target.value)} value={tipo} required >
                                        <option key={'sicomplemento'} value={"autoabasto"} >Autoabastecimiento</option>
                                        <option key={'nocomplemento'} value={"carretera"}>Carga en carretera</option>
                                    </select>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="dataTable twoCol">
                        <div class="dataTitle"><p className="fixedP">Cargas de Combustible</p>
                        </div>

                        <div class="dataBox">

                            <div class="dataRow">
                                <p className="fixedP">Litros</p>
                                <div class="fieldInput">
                                    <input type="number" style={{width:'90%'}} name='referlitros' value={litros} onChange={e => setLitros(e.target.value)} />
                                </div>
                            </div>
                            {tipo === "carretera" && <div class="dataRow">
                                <p className="fixedP">Precio Litro</p>
                                <div class="fieldInput">
                                    <CurrencyInput value={precioLitro} onChangeEvent={handleChangeCurrency} prefix="$" required />
                                </div>
                            </div>}

                        </div>
                    </div>
                </div>

            </ModalBody>
            <ModalFooter>
                <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>

            </ModalFooter>

        </LoadingOverlay>
    </Modal >

}
export default ModalCombustible;