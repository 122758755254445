import React, { useState, useEffect, Fragment } from 'react';
import {
    ComposedChart,
    Line,
    Area,
    BarChart,
    LineChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

const InfoSegmento = (props) => {
    let dataEnero = { ixkm: 0, cxkm: 0 }
    let dataFebrero = { ixkm: 0, cxkm: 0 }
    let dataMarzo = { ixkm: 0, cxkm: 0 }
    let dataAbril = { ixkm: 0, cxkm: 0 }
    let dataMayo = { ixkm: 0, cxkm: 0 }
    let dataJunio = { ixkm: 0, cxkm: 0 }
    let dataJulio = { ixkm: 0, cxkm: 0 }
    let dataAgosto = { ixkm: 0, cxkm: 0 }
    let dataSeptiembre = { ixkm: 0, cxkm: 0 }
    let dataOctubre = { ixkm: 0, cxkm: 0 }
    let dataNoviembre = { ixkm: 0, cxkm: 0 }
    let dataDiciembre = { ixkm: 0, cxkm: 0 }

    const [seleccionado, setSeleccionado] = useState(null);

    let historico = [...props.dataHistorica || []];
    let auxtemp = null;
    const busquedaDatames = (mes) => {
        let febrerobusqueda = historico.find(h => h.fecha.split("-")[0] == mes);
        if (febrerobusqueda) {
            febrerobusqueda = febrerobusqueda.resumenLineas.find(b => b.segmento === props.segmento);
           
        }
      
        let ikm = 0;
        let ckm = 0;
        if (febrerobusqueda) {
            auxtemp = febrerobusqueda;
            //setSeleccionado(febrerobusqueda);
            if(febrerobusqueda.kms>0){
                ikm = parseFloat(febrerobusqueda.ingresos / febrerobusqueda.kms).toFixed(2);
                ikm = parseFloat(ikm);
    
                ckm = parseFloat((febrerobusqueda.costosFijos + febrerobusqueda.costosVariables + febrerobusqueda.comision + febrerobusqueda.sueldos) / febrerobusqueda.kms).toFixed(2);
                ckm = parseFloat(ckm);
            }
           
        }
        return { ixkm: ikm, cxkm: ckm }
    }
    if (historico.length > 0) {
        console.log("hay historicooo");
        auxtemp = null;
        dataEnero = busquedaDatames('01');
        dataFebrero = busquedaDatames('02');
        dataMarzo = busquedaDatames('03');
        dataAbril = busquedaDatames('04');
        dataMayo = busquedaDatames('05');
        dataJunio = busquedaDatames('06');
        dataJulio = busquedaDatames('07');
        dataAgosto = busquedaDatames('08');
        dataSeptiembre = busquedaDatames('09');
        dataOctubre = busquedaDatames('10');
        dataNoviembre = busquedaDatames('11');
        dataDiciembre = busquedaDatames('12');
        if (auxtemp) {
            //  setSeleccionado(auxtemp);
        }


    }else{
        console.log("no hay historico");
    }

    useEffect(() => {
        if (historico.length > 0) {

        }
    }, []);

    const seleccionarMes = (mesnombre) => {
        let mes = '01';
        switch (mesnombre) {
            case 'Enero':
                mes = '01'
                break;
            case 'Febrero':
                mes = '02'
                break;
            case 'Marzo':
                mes = '03'
                break;
            case 'Abril':
                mes = '04'
                break;
            case 'Mayo':
                mes = '05'
                break;
            case 'Junio':
                mes = '06'
                break;
            case 'Julio':
                mes = '07'
                break;
            case 'Agosto':
                mes = '08'
                break;
            case 'Septiembre':
                mes = '09'
                break;
            case 'Octubre':
                mes = '10'
                break;
            case 'Noviembre':
                mes = '11'
                break;
            case 'Diciembre':
                mes = '12'
                break;
            default:
                mes = '01';
                break;
        }

        let febrerobusqueda = historico.find(h => h.fecha.split("-")[0] == mes);
        if (febrerobusqueda) {
            febrerobusqueda = febrerobusqueda.resumenLineas.find(b => b.segmento === props.segmento);

            if (febrerobusqueda) {
              
                let aux = { ...febrerobusqueda };
                let ikm = 0;
                let ckm = 0;
                let cfkm=0;
                let cvkm=0;
                let sokm=0;

                //setSeleccionado(febrerobusqueda);
                ikm = parseFloat(febrerobusqueda.ingresos / febrerobusqueda.kms).toFixed(2);
                ikm = parseFloat(ikm);

                ckm = parseFloat((febrerobusqueda.costosFijos + febrerobusqueda.costosVariables + febrerobusqueda.comision + febrerobusqueda.sueldos) / febrerobusqueda.kms).toFixed(2);
                ckm = parseFloat(ckm);

                aux.ixkm = ikm;
                aux.cxkm = ckm;

                cfkm = parseFloat((febrerobusqueda.costosFijos) / febrerobusqueda.kms).toFixed(2);
                cfkm = parseFloat(cfkm);

                cvkm = parseFloat((febrerobusqueda.costosVariables) / febrerobusqueda.kms).toFixed(2);
                cvkm = parseFloat(cvkm);

                sokm = parseFloat((febrerobusqueda.comision + febrerobusqueda.sueldos) / febrerobusqueda.kms).toFixed(2);
                sokm = parseFloat(sokm);


                aux.cfkm=cfkm;
                aux.cvkm=cvkm;
                aux.sokm=sokm;

                let utilidadkm=0;
                utilidadkm=ikm-ckm;
                let utilidadreal = parseFloat(utilidadkm*febrerobusqueda.kms).toFixed(2);
                utilidadreal = parseFloat(utilidadreal);

                aux.utilidadkm=utilidadkm;
                aux.utilidadreal=utilidadreal;

                setSeleccionado(aux);
            }
        }

    }
    const formatoMoneda = (valor) => {
        let fixtostring = "" + valor;
        let fixtofloat = parseFloat(fixtostring);
        let toMoney = '$' + fixtofloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        return toMoney;

    }
    const dataChart = [
        {
            name: 'Enero',
            ingresoXkm: dataEnero.ixkm,
            costoXkm: dataEnero.cxkm,
        },
        {
            name: 'Febrero',
            ingresoXkm: dataFebrero.ixkm,
            costoXkm: dataFebrero.cxkm,
        },
        {
            name: 'Marzo',
            ingresoXkm: dataMarzo.ixkm,
            costoXkm: dataMarzo.cxkm,
        },
        {
            name: 'Abril',
            ingresoXkm: dataAbril.ixkm,
            costoXkm: dataAbril.cxkm,
        },
        {
            name: 'Mayo',
            ingresoXkm: dataMayo.ixkm,
            costoXkm: dataMayo.cxkm,
        },
        {
            name: 'Junio',
            ingresoXkm: dataJunio.ixkm,
            costoXkm: dataJunio.cxkm,
        },
        {
            name: 'Julio',
            ingresoXkm: dataJulio.ixkm,
            costoXkm: dataJulio.cxkm,
        },
        {
            name: 'Agosto',
            ingresoXkm: dataAgosto.ixkm,
            costoXkm: dataAgosto.cxkm,
        },
        {
            name: 'Septiembre',
            ingresoXkm: dataSeptiembre.ixkm,
            costoXkm: dataSeptiembre.cxkm,
        },
        {
            name: 'Octubre',
            ingresoXkm: dataOctubre.ixkm,
            costoXkm: dataOctubre.cxkm,
        },
        {
            name: 'Noviembre',
            ingresoXkm: dataNoviembre.ixkm,
            costoXkm: dataNoviembre.cxkm,
        },
        {
            name: 'Diciembre',
            ingresoXkm: dataDiciembre.ixkm,
            costoXkm: dataDiciembre.cxkm,
        },

    ];
    console.log("datachart",dataChart);
    return <div style={{ width: "100%", height: "auto" }}>
        <div style={{ width: "100%", marginLeft: '15px', marginTop: '5px' }} className="col-12">
            <h3 style={{ fontWeight: "600" }}>{props.segmento}</h3>
        </div>
        <div style={{ border: "1px solid #d6d6d6", padding: '5px', marginBottom: "20px" }}>
            <div className="row" style={{ padding: '2px', marginLeft: '1px', marginTop: '5px', width: "100%", height: "50px" }}>

                <div style={{ marginTop: '5px' }} className="col-12 ExpenseTable2">

                    <table className="tablenostriped" style={{ color: "#36596b" }}>
                        <tbody><tr>

                            <td style={{ textAlign: 'center', fontWeight: "900" }}>
                                Km. Totales <span style={{ fontSize: "15pt" }}><label for="lblFuelAmount" >{seleccionado ? seleccionado.kms : 0}</label></span>
                            </td>

                            <td style={{ textAlign: 'center', fontWeight: "900" }}>
                                Viajes Cargados <span style={{ fontSize: "15pt" }}><label for="lblFuelAmount" >{seleccionado ? seleccionado.viajes : 0}</label></span>
                            </td>

                            <td style={{ textAlign: 'center', fontWeight: "900" }}>
                                Ingresos <span style={{ fontSize: "15pt" }}><label for="lblFuelAmount" >{seleccionado ? formatoMoneda(seleccionado.ingresos) : '$0.00'}</label></span>
                            </td>

                            <td style={{ textAlign: 'center', fontWeight: "900" }}>
                                Ingreso x Km. <span style={{ fontSize: "15pt" }}><label for="lblFuelAmount" >{seleccionado ? formatoMoneda(seleccionado.ixkm) : '$0.00'}</label></span>
                            </td>
                            <td style={{ textAlign: 'center', fontWeight: "900" }}>
                                CF x Km. <span style={{ fontSize: "15pt" }}><label for="lblFuelAmount" >{seleccionado ? formatoMoneda(seleccionado.cfkm) : '$0.00'}</label></span>
                            </td>
                            <td style={{ textAlign: 'center', fontWeight: "900" }}>
                                CV x Km. <span style={{ fontSize: "15pt" }}><label for="lblFuelAmount" >{seleccionado ? formatoMoneda(seleccionado.cvkm) : '$0.00'}</label></span>
                            </td>
                            <td style={{ textAlign: 'center', fontWeight: "900" }}>
                                SO x Km. <span style={{ fontSize: "15pt" }}><label for="lblFuelAmount" >{seleccionado ? formatoMoneda(seleccionado.sokm) : '$0.00'}</label></span>
                            </td>
                            <td style={{ textAlign: 'center', fontWeight: "900" }} >
                                Costo x Km. <span style={{ fontSize: "15pt" }}><label for="lblFuelAmount" >{seleccionado ? formatoMoneda(seleccionado.cxkm) : '$0.00'}</label></span>
                            </td>
                            <td style={{ textAlign: 'center', fontWeight: "900" }}>
                                Utilidad x Km. <span style={{ fontSize: "15pt" }}><label for="lblFuelAmount" >{seleccionado ? formatoMoneda(seleccionado.utilidadkm) : '$0.00'}</label></span>
                            </td>
                            <td style={{ textAlign: 'center', fontWeight: "900" }}>
                                Utilidad <span style={{ fontSize: "15pt" }}><label for="lblFuelAmount" >{seleccionado ? formatoMoneda(seleccionado.utilidadreal) : '$0.00'}</label></span>
                            </td>
                        </tr></tbody></table>
                </div>

            </div>
            <div style={{ marginTop: '35px', height: "300px" }}>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        width={400}
                        height={250}
                        data={dataChart}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" scale="band" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar name='Ingreso x Km.' dataKey="ingresoXkm" barSize={70} fill="#2FA2FA" onClick={(e) => { seleccionarMes(e.name) }} />
                        <Line name="Costo x Km." type="monotone" dataKey="costoXkm" stroke="#ed7c88" activeDot={{ r: 8 }} strokeWidth={2} />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>

    </div>;
}

export default InfoSegmento;