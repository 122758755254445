import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router";
import {  doLogout } from './../../../session/session_controller';
import Footer from '../../utils/Footer';
import Header from '../../utils/Header';
import ProSideBar from '../../utils/ProSideBar';
//import TabComponent from './tabcomp';
import ViajeCreateContent from './content'; 

class index extends Component {

    constructor() {
        super();
        
        let localStorageProperty = JSON.parse(localStorage.getItem('navleft_marketpoint'));
        if (localStorageProperty === null) {
            localStorage.setItem('navleft_marketpoint', true);
            localStorageProperty = true;
        }

        this.state = {
            id:null,
            isNotFolded: localStorageProperty,
        }
    }
    componentDidMount() {
       // console.log("params",this.props.match.params);
        const id = this.props.match.params.id;
        if(id){
            this.setState({id});
        }
    }
    foldBar=()=>{
        let currentstatebar=this.state.isNotFolded;
        currentstatebar=!currentstatebar;
        this.setState({isNotFolded:currentstatebar},()=>{
            localStorage.setItem('navleft_marketpoint', currentstatebar);
        });
    }


    handleLogOut = () => {
        doLogout();
    }   


    
    render() {
        return (
            <Fragment>
                {/* //////////// LOADING
                <div className="loading"></div>
                {/* //////////// END LOADING */ }
                <div className="air__layout air__layout--hasSider">

                <ProSideBar isNotFolded={this.state.isNotFolded}></ProSideBar>
                    {/* //////////// CONTENT RIGHT */}
                    <div className="air__layout">
                        {/* /////// HEADER */}
                        <Header foldBar={this.foldBar}></Header>
                        {/* /////// END HEADER */}
                        {/* /////// MAIN CONTENT */}
                       <ViajeCreateContent id={this.state.id} />
                        {/* /////// END MAIN CONTENT */}
                        {/* /////// FOOTER */}
                        <Footer>
                           
                        </Footer>
                        {/* /////// END FOOTER */}
                    </div>
                    {/* //////////// END CONTENT RIGHT */}
                </div>
            </Fragment>
        )
    }
}
export default withRouter(index);