import { firebase } from '../configs/firebase';
export const getCombustibles = (uid) => {
    var db = firebase.firestore();
    return db.collection("combustible_user").where("uid", "==", uid).get();
}

export const getCombustiblesByRango = (uid) => {
    var db = firebase.firestore();
    return db.collection("combustible_user").where("uid", "==", uid).get();
}

export const getCombustiblesPorUnidad = (uid, unidadId) => {
    var db = firebase.firestore();
    return db.collection("combustible_user").where("uid", "==", uid).where("unidad_id", "==", unidadId).get();
}

export const getCombustiblesPorViaje = (uid, viajeId) => {
    var db = firebase.firestore();
    return db.collection("combustible_user").where("uid", "==", uid).where("viaje_id", "==", viajeId).get();
}

export const addCombustible = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('combustible_user').add(dataaux);
}

export const updateCombustible = (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date() };
    var db = firebase.firestore();
    return db.collection('combustible_user').doc(id).update(dataaux);
}

export const deleteCombustible = (id) => {
    var db = firebase.firestore();
    return db.collection('combustible_user').doc(id).delete();
}
