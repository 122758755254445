import React from "react";
import moment from 'moment';
import PencilIcon from '@material-ui/icons/Build';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

// The avaiable renderer-related props are:
// - row (row index)
// - col (column index)
// - prop (column property name)
// - TD (the HTML cell element)
// - cellProperties (the cellProperties object for the edited cell)

export const MoneyRender = (props) => {

    const { value } = props;

    if (value) {
        let num ="";
        try{
            num = '$' + value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        }catch(e){
            
        }
         
        return (
            <span align="center" className="currencyrecord">{num}</span>

        );
    }
    return (
        <>
            <span></span>
        </>
    );
};

export const DateRender = (props) => {

    const { value } = props;
    if (value) {
        if (value.toDate) {
            return (
                <span align="center" className="normalrecord">{moment(value.toDate()).format("DD/MM/YYYY")}</span>

            );
        } else {
            <>
                <span>-</span>
            </>
        }


    }
    return (
        <>
            <span>-</span>
        </>
    );
};
export const CreditoRender = (props) => {
    const { value } = props;
    if(value===null){
        return (
            <>
                <span align="center" className="normalrecord">Cargando..</span>
            </>
        );
    }else{
        return (
            <>
                <span align="center" className="normalrecord">{value}</span>
            </>
        );
    }
}
export const EstatusRender = (props) => {

    const { value } = props;
    if (value) {
        return (
            <>
                <span align="center" className="normalrecord">Activo</span>
            </>
        );
    }
    return (
        <>
            <span align="center" className="normalrecord">Inactivo</span>
        </>
    );
};
export const ActionRender = (props) => {

    const { value } = props;
    const { handleEditFromId } = props;
    const { handleDeleteFromId } = props;
    
    if (value) {
        return (
            <>
                <IconButton size="small" style={{
                    color: "#2fa2fa",

                }} onClick={() => handleEditFromId(value)} aria-label="editar">
                    <PencilIcon />
                </IconButton>
                <IconButton size="small"  style={{
                    color: "#2fa2fa",

                }} color="#2fa2fa" onClick={() => handleDeleteFromId(value)} aria-label="borrar">
                    <DeleteIcon />
                </IconButton>

            </>
        );
    }
    return (
        <>
            <span>-</span>
        </>
    );
};