import React from "react";
import moment from 'moment';
import PencilIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import DescriptionIcon from '@material-ui/icons/Description';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faDollar, faCopy, faGasPump, faRoad } from '@fortawesome/free-solid-svg-icons';

// The avaiable renderer-related props are:
// - row (row index)
// - col (column index)
// - prop (column property name)
// - TD (the HTML cell element)
// - cellProperties (the cellProperties object for the edited cell)

export const MoneyRender = (props) => {

    const { value } = props;

    if (value) {
        let num = "";
        try {
            num = '$' + value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        } catch (e) {

        }

        return (
            <span align="center" className="currencyrecord">{num}</span>

        );
    }
    return (
        <>
            <span></span>
        </>
    );
};

export const DateRender = (props) => {

    const { value } = props;
    if (value) {
        if (value) {
            return (
                <span align="center" className="normalrecord">{value}</span>

            );
        } else {
            <>
                <span>no val</span>
            </>
        }


    }
    return (
        <>
            <span></span>
        </>
    );
};
export const CombustibleRecordRender = (props) => {

    const { value } = props;
    const { handleCargaCombustible } = props;
    return (
        value && value.id ? <>
            <FontAwesomeIcon style={{ color: "2FA2FA", marginLeft: '2px' }} icon={faGasPump} onClick={() => handleCargaCombustible(value.id)} />
        </> : <></>);
};
export const KmsRealesRecordRender = (props) => {

    const { value } = props;
    // console.log("render kmsreales", value);
    const { handleKmsReales } = props;
    return (
        value && value.id ? <>
            {value.kmsreales}<FontAwesomeIcon style={{ color: "2FA2FA", marginLeft: '2px' }} icon={faRoad} onClick={() => handleKmsReales(value.id)} />
        </> : <></>);
};
export const InputKmsRender = (props) => {

    const { value } = props;
    const { handlechangeKMSReales } = props;

    console.log("rendering", value);

    if (value) {
        if (value) {
            return (
                <input type='text' align="center" value={value.kmsreales} onChange={e => {
                    handlechangeKMSReales(value.id, e.target.value);
                }} />

            );
        } else {
            <>
                <input type='text' align="center" className="normalrecord" value={0} />
            </>
        }


    }
    return (
        <>
            <input type='text' align="center" className="normalrecord" value={0} />
        </>
    );
};
export const ActionRender = (props) => {

    let { value } = props;

    const { handleViewFromId } = props;
    const { handleWPId } = props;
    const { handleCP } = props;
    const { handleFacturar } = props;
    const { handleCopy } = props;

    let iscp = false;

    if (value && value.id && !value.id.includes("-Cancelado")) {
        iscp = value.id.includes("-cporte");
        if (iscp) {
            value.id = value.id.split("-cporte")[0];
        }

        return (
            <>
                <FontAwesomeIcon style={{ color: "2FA2FA", marginLeft: '2px' }} icon={faPencil} onClick={() => handleViewFromId(value.id)} />

                {/*<PencilIcon size="small" style={{
                    color: "#95d44c",

                }} onClick={() => handleViewFromId(value.id)} aria-label="editar">
                </PencilIcon> 
                */}

                <FontAwesomeIcon style={{ color: "2FA2FA", marginLeft: '5px' }} icon={faCopy} onClick={() => handleCopy(value.id)} />

                {/*<FileCopyIcon size="small" style={{
                    color: "#95d44c",

                }} onClick={() => handleCopy(value.id)} aria-label="editar">

                </FileCopyIcon>*/}


                <WhatsAppIcon size="small" style={{
                    color: "#2FA2FA", marginLeft: '5px'

                }} onClick={() => handleWPId(value.id)} aria-label="editar">

                </WhatsAppIcon>
                {
                    (!value.formatoNuevo && value.isCP) && <DescriptionIcon size="small" style={{
                        color: "#2FA2FA", marginLeft: '5px'

                    }} onClick={() => handleCP(value.id)} aria-label="editar" />

                }
                {
                    value.isCargado && value.isCP && <FontAwesomeIcon style={{ color: "2FA2FA", marginLeft: '5px' }} icon={faDollar} onClick={() => handleFacturar(value.id)} />


                }


            </>
        );
    }
    else if (value && value.id && value.id.includes("-Cancelado")) {
        return (
            <>
                <span>Cancelado</span>
            </>
        );
    }
    return (
        <>
            <span>-</span>
        </>
    );
};