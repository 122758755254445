import React, { useState, useEffect, Fragment } from 'react';
import { firebase } from '../../../configs/firebase';
import { deleteIngreso } from '../../../controllers/ingresos';
import { getUserByUid, getAllUsuarios } from '../../../controllers/usuarios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import { addDays } from "date-fns";
import { HotTable, HotColumn } from '@handsontable/react';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { registerLanguageDictionary } from 'handsontable/i18n';
import esMX from 'handsontable/i18n/languages/es-MX';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ModalCreateEdit from '../ModalCreateEdit';
import { getAllViajesByUserDateFilter } from '../../../controllers/viajes';
import { ActionRender, DateRender, MoneyRender } from './ActionsRender';
import { getUidMaster } from '../../../session/session_controller';
//import Download from './ExcelExport';


const CartasPorteContent = (props) => {

    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [firstDay, setFirstDay] = useState(null);
    const [lastDay, setLastDay] = useState(null);
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [querysearch, setQuerysearch] = useState('');
    const [ingresos, setIngresos] = useState([]);
    const [ingresosNoFilter, setIngresosNoFilter] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currenteditrecrod, setCurrentEditRecord] = useState(null);
    const [total, setTotal] = useState(0.0);
    const [totalMask, setTotalMask] = useState('');

    const handleCreate = () => {
        setIsEdit(false);
        setCurrentEditRecord({ none: true });
        setIsOpen(true);
    }

    const validateAdmin = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (user.type && user.type === "administrador") {
                    isadmin = true;
                }
            });
            if (isadmin) {
                setAdminCurrentUser(uid);
                setIsAdmin(true);
            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                setIsAdmin(false);
                setUserlist([]);
                // this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }

    useEffect(() => {
        if (isAdmin) {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                setUserlist(a);
            }).catch(e => {
                console.log(e);
                //this.setState({isLoading:false,})
            });
        }
    }, [isAdmin]);

    useEffect(() => {
        setTotalMask(total.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
    }, [total]);

    useEffect(() => {


        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {
                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);

                setUser(userr);
                // setAdminCurrentUser(userr.uid);

                if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                    // this.validateAdmin(user.uid);
                    //console.log("validando admin");
                    validateAdmin(userr.uid);
                }
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });

    }, []);


    useEffect(() => {
        if (querysearch.trim() === '') {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                aux.push(element);
            });
            setIngresos(aux);
        } else {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                let query = querysearch.trim().toUpperCase();
                if (element.nombre.toUpperCase().includes(query) ||
                    element.apellidos.toUpperCase().includes(query) ||
                    element.noLicencia.toUpperCase().includes(query) ||
                    element.noIMSS.toUpperCase().includes(query)) {
                    aux.push(element);
                }

            });
            setIngresos(aux);
        }
    }, [querysearch]);

    useEffect(() => {
        getData(user);
    }, [user, currentDate, adminCurrentUser]);

    useEffect(() => {
        if (!isOpen) {
            setCurrentEditRecord(null);
        }
    }, [isOpen])


    useEffect(() => {
        console.log("total cp", ingresos);
    }, [ingresos])

    const refresh = () => {
        getData(user);
    }

    const getData = async (usertodisplay) => {
        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }
            setIsLoading(true);
            let a = [];
            let index = 0;
            var moment = require('moment'); // require
            await getAllViajesByUserDateFilter(usertoconsult.uid, firstDay, lastDay).then(snapshot => {
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    o.id = doc.id;
                    o.key = doc.id;
                    o.fechaformat = moment(o.datos_viaje.fechaOrigen.toDate()).format('DD/MM/YYYY');
                    let ciudad = o.carta_porte.origenCiudad || "";
                    let estado = o.carta_porte.origenEstadoShort || "";
                    let ciudaddestino = o.carta_porte.destinoCiudad || "";
                    let estadodestino = o.carta_porte.destinoEstadoShort || "";
                    o.remolque = o.carta_porte.remolque1 || "";
                    o.cliente = o.general.cliente;
                    o.origenformat = ciudad + "," + estado;
                    o.destinoformat = ciudaddestino + "," + estadodestino;
                    if (o.general.ncarta && o.general.ncarta) {
                        o.id = doc.id + "-cporte";
                    }

                    index++;
                    if (o.status === 'Cancelado') {
                        o.id = doc.id + "-Cancelado";
                    }
                    if (o.general.ncarta && o.general.ncarta !== '')
                        a.push(o);
                });
                setIngresos(a);
                setIngresosNoFilter(a);
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar los ingresos, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });
        } else {
            //console.log("user null");
        }
    }

    const handleDateChanged = (date) => {
        let fDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);
        setCurrentDate(date);
    }

    const findRecord = (id) => {
        let obj = ingresos.find(element => element.id === id);
        return obj;
    }

    return (
        <Fragment>
            {
                <div className="row">
                    <div className="col-12">
                        <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>&nbsp;Módulo de Cartas Porte</h3>
                        <div className="card">
                            <div className="card-header card-header-flex">

                                <div className="d-flex px-3 row justify-content-between align-items-center">
                                    <div className="col-auto pl-0">
                                       {/* <button type="button" style={{ marginRight: '10px' }} className="btn btn-outline-dark btn-sm" onClick={() => handleCreate()}><i className="fa fa-plus-circle iconfa"></i> &nbsp;Agregar Carta Porte</button>
*/}
                                       

                                    </div>
                                    <div>

                                    </div>
                                    <div>
                                       
                                        <DatePicker
                                            className="form-control customdatepicker"
                                            locale="es"
                                            selected={currentDate}
                                            onChange={date => handleDateChanged(date)}
                                            maxDate={addDays(new Date(), 1)}
                                            dateFormat="MMMM yyyy"
                                            showMonthYearPicker
                                        />
                                    </div>

                                </div>
                            </div>
                            <LoadingOverlay
                                active={isLoading}
                                spinner
                                text='Actualizando datos, espere ...'
                            >
                                <div className="card-body">
                                    <HotTable settings={{
                                        colHeaders: true,
                                        rowHeaders: true,
                                        autoRowSize: false,
                                        columnSorting: true,
                                        dropdownMenu: ['filter_by_condition',
                                            'filter_by_value',
                                            'filter_action_bar'],
                                        filters: true,
                                        manualColumnResize: true,
                                        // colWidths: [100,100,80,90,90,140,140,160,160,80,120,80,120,90],
                                        //para ancho total                                    
                                        //  minSpareRows: 1,          // almenos una fila
                                        autoColumnSize: true,     // 
                                        //  stretchH: 'all',          // 
                                        //disableVisualSelection: true,
                                        currentRowClassName: 'currentRowHightlight',
                                        licenseKey: "non-commercial-and-evaluation",
                                        language: "es-MX"
                                    }} data={ingresos} colHeaders={true} rowHeaders={false} width="100%" height="400" >


                                        <HotColumn className="htCenter htMiddle" readOnly title="Carta Porte" data="general.ncarta" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Cliente Paga" data="cliente" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Operador" data="detalles.operador1" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Unidad" data="detalles.unidad" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Remolque" data="remolque" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Origen" data="origenformat" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Destino" data="destinoformat" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Kms." data="carta_porte.distTotales_km" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Referencia" data="general.referencia" />

                                    </HotTable>

                                </div>


                            </LoadingOverlay>
                        </div>
                    </div>
                    <ModalCreateEdit
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrod}
                        currentDate={currentDate}
                        isEdit={isEdit}
                        isOpen={isOpen}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={refresh}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setIsOpen(false)} />
                </div>
            }
        </Fragment>
    );
}
export default CartasPorteContent;
