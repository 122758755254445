import React from "react";
import moment from 'moment';
import PencilIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Cancel from '@material-ui/icons/Cancel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil,faDollar,faCopy } from '@fortawesome/free-solid-svg-icons';


// The avaiable renderer-related props are:
// - row (row index)
// - col (column index)
// - prop (column property name)
// - TD (the HTML cell element)
// - cellProperties (the cellProperties object for the edited cell)

export const MoneyRender = (props) => {

    const { value } = props;

    if (value) {
        let num = '$' + value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        return (
            <span align="center" className="currencyrecord">{num}</span>

        );
    }
    return (
        <>
            <span></span>
        </>
    );
};
export const NameRender = (props) => {

    const { value } = props;
    if (value) {
        let nombbase = value.split(' (R)');
        let isR = value.includes(' (R)');
        return (
            <>
                <span className="normalrecordleft">{nombbase[0]}
                    {
                        isR ? <span style={{ fontWeight: 'bold', fontSize: '14px' }}> &nbsp;(R)</span> : <></>
                    }
                </span>

            </>
        );
    }
    return (
        <>
            <span>-</span>
        </>
    );
};
export const DateRender = (props) => {

    const { value } = props;
    if (value) {
        if (value.toDate) {
            return (
                <span align="center" className="normalrecord">{moment(value.toDate()).format("DD/MM/YYYY")}</span>

            );
        } else {
            <>
                <span>-</span>
            </>
        }


    }
    return (
        <>
            <span>-</span>
        </>
    );
};
export const ActionRender = (props) => {

    const { value } = props;
    const { handleEditFromId } = props;
    const { handleDeleteFromId } = props;
    const { handleCancelar } = props;
    if (value) {
        return (
            <>
             
             <IconButton size="small" style={{
                    color: "#2fa2fa",

                }} onClick={() => handleEditFromId(value)} aria-label="editar">
                    <PencilIcon />
                </IconButton>
                {
                   handleCancelar &&  <IconButton size="small" style={{
                    color: "#2fa2fa",

                }} onClick={() => handleCancelar(value)} aria-label="borrar">
                    <Cancel />
                </IconButton>
                }
                <IconButton size="small" style={{
                    color: "#2fa2fa",

                }} onClick={() => handleDeleteFromId(value)} aria-label="borrar">
                    <DeleteIcon />
                </IconButton>


            </>
        );
    }
    return (
        <>
            <span>-</span>
        </>
    );
};