import React from "react";
import moment from 'moment';
import PencilIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faDollar, faCopy } from '@fortawesome/free-solid-svg-icons';
// The avaiable renderer-related props are:
// - row (row index)
// - col (column index)
// - prop (column property name)
// - TD (the HTML cell element)
// - cellProperties (the cellProperties object for the edited cell)

export const MoneyRender = (props) => {

    const { value } = props;

    if (value) {
        let num = "";
        try {
            num = '$' + value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        } catch (e) {

        }

        return (
            <span align="center" className="currencyrecord">{num}</span>

        );
    }
    return (
        <>
            <span></span>
        </>
    );
};

export const DateRender = (props) => {

    const { value } = props;
    if (value) {
        if (value.toDate) {
            return (
                <span align="center" className="normalrecord">{moment(value.toDate()).format("DD/MM/YYYY")}</span>

            );
        } else {
            <>
                <span>-</span>
            </>
        }


    }
    return (
        <>
            <span>-</span>
        </>
    );
};
export const ActionRender = (props) => {

    const { value } = props;
    const { handleEditFromId } = props;
    const { handleDeleteFromId } = props;
    const { handleDownloadComplemento } = props;
    const { handleDownloadComplementoXML } = props;

    if (value) {
        return (
            <>

                <IconButton size="small" style={{
                    color: "#2fa2fa",

                }} onClick={() => handleEditFromId(value.id)} aria-label="editar">
                    <PencilIcon />
                </IconButton>
                {
                    value.complementos && value.complementos.length > 0 && <DescriptionIcon size="small" style={{
                        color: "#2fa2fa",

                    }} onClick={() => handleDownloadComplemento(value.id)} aria-label="editar" />
                }
                {/*
                    value.complementos && value.complementos.length > 0 && <DescriptionIcon size="small" style={{
                        color: "#9cb5c8",

                    }} onClick={() => handleDownloadComplementoXML(value.complementos[0])} aria-label="editar" />
                */}
                <IconButton size="small" style={{
                    color: "#2fa2fa",
                }} onClick={() => handleDeleteFromId(value.id)} aria-label="borrar">
                    <DeleteIcon />
                </IconButton>

                


            </>
        );
    }
    return (
        <>
            <span>-</span>
        </>
    );
};