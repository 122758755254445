import React from "react";
import moment from 'moment';
import { Box } from './Box';
import { BoxPreplan } from './BoxPrePlan';
import { Dustbin } from "./Dustbin";
import { DustbinPreplan } from "./DustbinPrePlan";
import DateTimePicker from 'react-datetime-picker';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';


// The avaiable renderer-related props are:
// - row (row index)
// - col (column index)
// - prop (column property name)
// - TD (the HTML cell element)
// - cellProperties (the cellProperties object for the edited cell)
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        fontSize:'5px',
        marginLeft: '5px',
        marginRight: theme.spacing(1),
        width: 205,
    },
}));
export const MoneyRender = (props) => {

    const { value } = props;

    if (value) {
        let num = "";
        try {
            num = '$' + value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        } catch (e) {

        }

        return (
            <span align="center" className="currencyrecord">{num}</span>

        );
    }
    return (
        <>
            <span></span>
        </>
    );
};

export const DateRender = (props) => {

    const { value } = props;

    if (value && value != "") {
        if (value.toDate) {
            return (
                <span align="center" className="normalrecord">{moment(value.toDate()).format("DD/MM/YYYY")}</span>

            );
        } else {
            <>
                <span>-</span>
            </>
        }


    }
    return (
        <>
            <span>-</span>
        </>
    );
};
export const LinkViajeRender = (props) => {
    const { value } = props;
    const { handleGoToViaje } = props;
    return (
        <>
            <button align="center" style={{ color: 'white', width: '100%', fontFamily:'Lato, sans-serif' }} onClick={() => handleGoToViaje(value)} className="btn btn-sm btn-primary">{value}</button>
        </>
    );

}
export const EstatusRender = (props) => {

    const { value } = props;
    if (value) {

        if (value === 'Planeado') {
            return (
                <>
                    <span align="center" style={{ color: "#9c27b0" }} className="normalrecord"><b>{value}</b></span>
                </>
            );
        }
        return (
            <>
                <span align="center" className="normalrecord">{value}</span>
            </>
        );
    }
    return (
        <>
            <span align="center" className="normalrecord"> </span>
        </>
    );
};
export const EstatusRenderUnidad = (props) => {

    const { value } = props;
    if (value) {

        if (value === 'Despachado') {
            return (
                <>
                    <span align="center" style={{ color: "#ff9800" }} className="normalrecord"><b>{value}</b></span>
                </>
            );
        }
        return (
            <>
                <span align="center" className="normalrecord">{value}</span>
            </>
        );
    }
    return (
        <>
            <span align="center" className="normalrecord"> </span>
        </>
    );
};
export const ActionRender = (props) => {

    const { value } = props;
    const { handleDespachar } = props;

    if (value) {

        if (value.viaje_asignado) {
            let nomb = "-"
            if (value.viaje_asignado.general && value.viaje_asignado.general.nviaje) {
                nomb = value.viaje_asignado.general.nviaje;
            }
            return (
                <>
                    {/*<span align="center" className="normalrecord">{nomb}</span>*/}
                    <DustbinPreplan style={{ marginTop: '5px' }} id={value.id} descrip={value.nombre} />
                </>
            );
        }
        return (
            <>
                <Box handleDespachar={handleDespachar} name={`Despachar`} id={value.id} descrip={value.nombre} />

            </>
        );
    }
    return (
        <>
            <span>-</span>
        </>
    );
};

export const TimeRender = props => {
    const { value } = props;
    const { handleChangeHour } = props;

    const { type } = props;
    let hortemp = null;
    if (type === "desp") {
        if (value.desp)
            hortemp = value.desp || null;
    }
    if (type === "up") {
        if (value.hora_up)
            hortemp = value.hora_up || null;
    }
    if (type === "carga") {
        if (value.hora_carga)
            hortemp = value.hora_carga || null;
    }
    if (type === "documentos") {
        if (value.hora_documentos)
            hortemp = value.hora_documentos || null;
    }
    if (type === "fila") {
        if (value.hora_fila)
            hortemp = value.hora_fila || null;
    }
    if (type === "inspeccion") {
        if (value.hora_inspeccion)
            hortemp = value.hora_inspeccion || null;
    }
    if (type === "modulomx") {
        if (value.hora_modulomx)
            hortemp = value.hora_modulomx || null;
    }
    if (type === "modulousa") {
        if (value.hora_modulousa)
            hortemp = value.hora_modulousa || null;
    }

    if (type === "recoleccion") {
        if (value.hora_recoleccion)
            hortemp = value.hora_recoleccion || null;
    }
    if (type === "terminorecoleccion") {
        if (value.hora_termino_recoleccion)
            hortemp = value.hora_termino_recoleccion || null;
    }
    if (type === "salidabase") {
        if (value.hora_salida_base)
            hortemp = value.hora_salida_base || null;
    }
    if (type === "regresovacio") {
        if (value.hora_regreso_vacio)
            hortemp = value.hora_regreso_vacio || null;
    }

    if (type === "start") {
        if (value.hora_start)
            hortemp = value.hora_start || null;
    }
    if (type === "end") {
        if (value.hora_end)
            hortemp = value.hora_end|| null;
    }

    const [hora, setHora] = React.useState(hortemp);

    const changeHora = e => {
        console.log("hora", e.target.value)
        setHora(e.target.value);
        handleChangeHour(value.id, e.target.value);
    }
    const classes = useStyles();
    const [selectedDate, handleDateChange] = React.useState(new Date());
    if (value) {
        return (
            <>
                {/*<DateTimePicker
                    disableClock={true}
                    format="dd/MM/yy h:m a"
                    closeClock={false}
                    onChange={e => changeHora(e)}
                    value={hora}
                />*/}
                <TextField
                    id="datetime-local"
                    type="datetime-local"
                    onChange={e => changeHora(e)}
                    value={hora}
                    style={{fontSize:'12px'}}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </>
        );
    }
}

export const DropRender = (props) => {

    const { value } = props;
    const { handleEditFromId } = props;
    const { handleDeleteFromId } = props;
    const { handlePrePlanear } = props;

    if (value) {
        //  console.log("testing", value);
        if (value.status === "Planeado") {
            return (
                <>
                    <Dustbin style={{ marginTop: '5px' }} id={value.id} descrip={value.general.nviaje} />
                    <BoxPreplan handleDespachar={handlePrePlanear} name={`Pre-plan`} id={value.id} descrip={value.general.nviaje} />
                </>
            );
        }
        return (
            <>
                <span align="center" className="normalrecord">-</span>
            </>
        );
    }
    return (
        <>
            <span>-</span>
        </>
    );
};