import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getUserByUid } from '../../../controllers/usuarios';
import { getClienteByID, updateConfiguracionCliente } from '../../../controllers/clientes';
import { getFacturaArchivos } from '../../../controllers/facturama';
import { getViajeById, getViajeRepo, actualizaExtrasDentroFlete } from '../../../controllers/viajes';
import xml2js from 'xml2js';
import CurrencyInput from 'react-currency-input';

import axios from 'axios';
import { getTabuladorOperadores } from '../../../controllers/tabuladoroperadores';


const ModalCreateEdit = (props) => {
    //particularidades LOZJRkQRLvbN2mWSjX4MFNr1qvj2
    const requiereIVacustom = ['31RxHqtBOmbgOWWeMCKnb1DuEHc2'];
    const requiere4decimalestipocambio = ['BoGosidpskNN0ggjtrAwHPcug1A2'];
    const requieredesglose = ['MPVVo1Hr5XgmmmqdHxfqNfQgQB03'];
    const requiereserie = ['KeMdhwEofxWSIrXh2mylJWra9Jr1'];
    const requiereSinPresio = ['oQ2vlHtaUfU3cOYeLTpBdFIH7QH2', 'RZHc17ViPqQTIJ7VShGfbBRwXBw1', 'yzUc7ZqAHfYJtaHVfboi8hl6yp72', 'u3AB65PQHNea7reDJ5uEzrCB9kL2', 'DpgnFvRr99WMzSfrVXWADeZy0R83'];

    //constantes
    //props.uid
    const conceptos = [
        { clave: "78101800", descripcion: "Transporte de carga por carretera" },
        { clave: "78101801", descripcion: "Servicios de transporte de carga por carretera (en camión) en área local" },
        { clave: "78101802", descripcion: "Servicios transporte de carga por carretera (en camión) a nivel regional y nacional" },
        { clave: "78101806", descripcion: "Servicios transporte de carga por carretera a nivel internacional" },
        { clave: "78101807", descripcion: "Servicios transporte de carga de petróleos o químicos" },
        { clave: "78141500", descripcion: "Movimiento de fletes" },
        { clave: "78141500x", descripcion: "Coordinación de logística en transporte" },
        { clave: "78141501", descripcion: "Coordinación de fletes" },

    ];
    const conceptosExtra = [
        { id: "1", clave: "78101800", descripcion: "Transporte de carga por carretera" },
        { id: "2", clave: "78101801", descripcion: "Servicios de transporte de carga por carretera (en camión) en área local" },
        { id: "3", clave: "78101802", descripcion: "Servicios transporte de carga por carretera (en camión) a nivel regional y nacional" },
        { id: "4", clave: "78101806", descripcion: "Servicios transporte de carga por carretera a nivel internacional" },
        { id: "5", clave: "78101807", descripcion: "Servicios transporte de carga de petróleos o químicos" },
        { id: "24", clave: "78141500", descripcion: "Movimiento de fletes" },
        { id: "26", clave: "78141500", descripcion: "Flete por rechazo de producto" },
        { id: "6", clave: "78141501", descripcion: "Coordinación de fletes" },
        { id: "7", clave: '95111602', descripcion: 'Carretera o autopista de peaje' },
        { id: "8", clave: '78121602', descripcion: 'Servicios de pesaje' },
        { id: "9", clave: '78121601', descripcion: 'Carga y descarga de mercancías' },
        { id: "10", clave: '78141902', descripcion: 'Estadia/Demoras' },
        { id: "11", clave: '78101802', descripcion: 'Reparto o recolección' },
        { id: "12", clave: '78111808', descripcion: 'Alquiler de vehículos' },
        { id: "13", clave: '78101805', descripcion: 'Servicio de alquiler de remolques o camiones cisterna' },
        { id: '14', clave: '78101801', descripcion: 'Paradas o recolecciones' },
        { id: '15', clave: '78121502', descripcion: 'Sello Y Flejado' },
        { id: '16', clave: '84131504', descripcion: 'Seguros de carga' },
        { id: '17', clave: '92121504', descripcion: 'Custodia' },
        { id: '18', clave: '78101802', descripcion: 'Servicio de GPS' },
        { id: '19', clave: '78101802', descripcion: 'Servicio express' },
        { id: '20', clave: '78101806', descripcion: 'Servicio especial' },
        { id: '21', clave: '78141902', descripcion: 'Alquiler de contenedores cerrados' },
        { id: '22', clave: '78121600', descripcion: 'Servicio de manejo de materiales' },
        { id: '23', clave: '78131803', descripcion: 'Almacenaje de materiales peligrosos' },
        { id: "25", clave: "76111801", descripcion: "Limpieza de vehículos" },
        { id: "27", clave: '78101805', descripcion: 'Renta de cajas' },
        { id: "28", clave: '78111809', descripcion: 'Leasing de vehículos sedán, cupé o camioneta' },
        { id: "29", clave: '78131700', descripcion: 'Estadia /Demoras' },
    ];

    const [conceptosDataExtra, setConceptosDataExtra] = useState(conceptosExtra);

    const CAT_METODOSPAGO = [
        {
            "Name": "Pago en parcialidades ó diferido",
            "Value": "PPD"
        },
        {
            "Name": "Pago en una sola exhibición",
            "Value": "PUE"
        }
    ];
    const CAT_USOCFDI = [
        {
            "Natural": true,
            "Moral": false,
            "Name": "Honorarios médicos, dentales y gastos hospitalarios.",
            "Value": "D01"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos médicos por incapacidad o discapacidad",
            "Value": "D02"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos funerales.",
            "Value": "D03"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Donativos.",
            "Value": "D04"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
            "Value": "D05"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Aportaciones voluntarias al SAR.",
            "Value": "D06"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Primas por seguros de gastos médicos.",
            "Value": "D07"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos de transportación escolar obligatoria.",
            "Value": "D08"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
            "Value": "D09"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Pagos por servicios educativos (colegiaturas)",
            "Value": "D10"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Adquisición de mercancias",
            "Value": "G01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Devoluciones, descuentos o bonificaciones",
            "Value": "G02"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Gastos en general",
            "Value": "G03"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Construcciones",
            "Value": "I01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Mobilario y equipo de oficina por inversiones",
            "Value": "I02"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Equipo de transporte",
            "Value": "I03"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Equipo de computo y accesorios",
            "Value": "I04"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Dados, troqueles, moldes, matrices y herramental",
            "Value": "I05"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Comunicaciones telefónicas",
            "Value": "I06"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Comunicaciones satelitales",
            "Value": "I07"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Otra maquinaria y equipo",
            "Value": "I08"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Sin efectos fiscales.",
            "Value": "S01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Por definir",
            "Value": "P01"
        }
    ]
    const CAT_FORMASPAGO = [
        {
            "Name": "Efectivo",
            "Value": "01"
        },
        {
            "Name": "Cheque nominativo",
            "Value": "02"
        },
        {
            "Name": "Transferencia electrónica de fondos",
            "Value": "03"
        },
        {
            "Name": "Tarjeta de crédito",
            "Value": "04"
        },
        {
            "Name": "Monedero electrónico",
            "Value": "05"
        },
        {
            "Name": "Dinero electrónico",
            "Value": "06"
        },
        {
            "Name": "Vales de despensa",
            "Value": "08"
        },
        {
            "Name": "Dación en pago",
            "Value": "12"
        },
        {
            "Name": "Pago por subrogación",
            "Value": "13"
        },
        {
            "Name": "Pago por consignación",
            "Value": "14"
        },
        {
            "Name": "Condonación",
            "Value": "15"
        },
        {
            "Name": "Compensación",
            "Value": "17"
        },
        {
            "Name": "Novación",
            "Value": "23"
        },
        {
            "Name": "Confusión",
            "Value": "24"
        },
        {
            "Name": "Remisión de deuda",
            "Value": "25"
        },
        {
            "Name": "Prescripción o caducidad",
            "Value": "26"
        },
        {
            "Name": "A satisfacción del acreedor",
            "Value": "27"
        },
        {
            "Name": "Tarjeta de débito",
            "Value": "28"
        },
        {
            "Name": "Tarjeta de servicios",
            "Value": "29"
        },
        {
            "Name": "Aplicación de anticipos",
            "Value": "30"
        },
        {
            "Name": "Intermediarios",
            "Value": "31"
        },
        {
            "Name": "Por definir",
            "Value": "99"
        }
    ];
    const CAT_TIPORELACION = [
        { value: '', descripcion: '' },
        { value: '01', descripcion: 'Notas de Crédito de Documentos Relacionados' },
        { value: '02', descripcion: 'Notas de Débito de los Documentos Relacionados' },
        { value: '03', descripcion: 'Devolución de Mercancías sobre Facturas o Traslados Previos' },
        { value: '04', descripcion: 'Sustitución de los CFDI Previos' },
        { value: '05', descripcion: 'Traslados de Mercancías Facturados Previamente' },
        { value: '06', descripcion: 'Factura Generada por los Traslados Previos' },
        { value: '07', descripcion: 'CFDI por Aplicación de Anticipo' },
        { value: '08', descripcion: 'Facturas Generadas por Pagos en Parcialidades' },
        { value: '09', descripcion: 'Factura Generada por Pagos Diferidos' }
    ];
    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);
    const [idViaje, setIdViaje] = useState(null);

    const [chekingCSD, setCheckinCSD] = useState(true);

    const [nviaje, setNviaje] = useState("");
    const [ncarta, setNcarta] = useState("");
    const [cliente, setCliente] = useState("");
    const [clienteId, setClienteId] = useState(null);
    const [clienteData, setClietneData] = useState(null);
    const [isIVA16, setIsIVA16] = useState(true);
    const [facturaArchivos, setFacturaArchivos] = useState(null);
    const [incluyeDesglose, setIncluyeDesglose] = useState(false);

    const [isFacuturaCancelada, setIsFacturaCancelada] = useState(false);
    const [ivatasa, setIvaTasa] = useState("16");
    const [retencion, setRetencion] = useState("0");
    const [retencionISR, setRetencionISR] = useState("0");
    const [version, setVersion] = useState("4.0");
    const [razonesSociales, setRazonesSociales] = useState([]);
    const [rfc, setRfc] = useState("");

    const [usoCfdi, setUsoCfdi] = useState("P01 - Por definir");
    const [claveConcepto, setClaveConcepto] = useState("78101800");

    const [metodoPago, setMetodoPago] = useState("PUE - Pago en una sola exhibición");
    const [formaPago, setFormaPago] = useState("99 - Por definir");
    const [condicionesPago, setCondicionesPago] = useState("");
    const [dolarprice, setDolarPrice] = useState(20.0);
    const [isDLL, setIsDLL] = useState(false);
    const [moneda, setMoneda] = useState("MXN");
    const [extras, setExtras] = useState([]);
    const [ntibmres, setNtimbres] = useState(0);
    const [traslado, setTraslado] = useState(false);

    const [existeRelacion, setExisteRelacion] = useState("No");
    const [tipoRelacion, setTipoRelacion] = useState("");
    const [UIDRelacion, setUidRelacion] = useState("");


    const [descripcionPersonalizada, setDescripcionPersonalizada] = useState("");
    const [isDescripcionPerzonalizada, setIsDescripcionPersonalizada] = useState(false);

    const [extrasDentroFlete, setExtrasDentroFlete] = useState([]);

    const [allowTimbrado, setAllowTimbrado] = useState(true);
    const [serie, setSerie] = useState("");


    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);

    useEffect(() => {
        if (user && user.razones) {
            let razonesasignar = [];
            user.razones.forEach(r => {
                if (!r.inactivo) {
                    razonesasignar.push(r);
                }
            })
            setRazonesSociales(razonesasignar);

            if (razonesasignar.length > 0 && razonesasignar[0].Rfc) {
                setRfc(razonesasignar[0].Rfc);
            }
        }
    }, [user]);

    useEffect(() => {
        if (rfc && clienteData && clienteData.rfc && rfc === clienteData.rfc && recordedit && recordedit.isFormatoNuevo) {
            setTraslado(true);
        } else if (traslado) {
            setTraslado(false);
        }
    }, [rfc, clienteData])
    const checkCSD = async () => {
        console.log("cheking csd", uid);
        if (uid) {
            await getViajeRepo(uid).then(resp => {
                let nt = 0;
                resp.forEach(document => {
                    let o = document.data();
                    if (o && o.ntimbres && nt <= o.ntimbres) {
                        nt = o.ntimbres;
                    }
                });
                setNtimbres(nt);
            }).catch(err => { });
            getUserByUid(uid).then(resp => {
                let obuser = null;
                resp.forEach(document => {
                    obuser = { ...document.data(), id: document.id }

                });
                setUser(obuser);
                if (obuser.dolarprice) {
                    setDolarPrice(obuser.dolarprice);
                }
                setCheckinCSD(false);
                //console.log("datos usuario",resp.docs[0].data());
                if (resp.docs.length > 0 && resp.docs[0].data().csd) {
                    initValues();
                } else {
                    Swal.fire("No CSD", "No se han cargado los CSD para esta cuenta, da de alta primero tus credenciales de facturacion en la seccion de dashboard -> configuraciones ", 'warning');
                    setIsLoading(false);
                    setIsOpen(false);
                }
            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar CSD, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });
        } else {
            Swal.fire("Error", "Error sesion terminada", 'error');
            setIsLoading(false);
            setIsOpen(false);
        }

    }

    useEffect(() => {
        if (clienteId) {
            getClienteByID(clienteId).then(resp => {
                if (resp.data()) {
                    setClietneData(resp.data());


                }
            }).catch(err => { });
        }
    }, [clienteId]);

    const initValues = async () => {
        console.log("recordedit", props);
        console.log("init values", idViaje);
        if (idViaje != null) {

            await getViajeById(idViaje).then(document => {
                console.log("llegaron datos");


                let o = document.data();
                o.id = document.id;
                o.key = document.id;
                setRecordEdit(o);
                setIsLoading(false);
            }).catch(err => { console.log(err); setIsLoading(false); });
        }
    }

    useEffect(() => {

        // console.log("recordedit heree",recordedit);
        if (recordedit != null) {
            if (!recordedit.detalles.currencyMX) {
                setIvaTasa("0");
            } else {
                setIvaTasa("16");
            }

            if (clienteData && clienteData.configuracionCFDI) {

                setUsoCfdi(clienteData.configuracionCFDI.usoCfdi || "P01 - Por definir");
                setMetodoPago(clienteData.configuracionCFDI.metodoPago || "PUE - Pago en una sola exhibición");
                setFormaPago(clienteData.configuracionCFDI.formaPago || "99 - Por definir");
                setCondicionesPago(clienteData.condicionesPago || "")
                setClaveConcepto(clienteData.configuracionCFDI.claveConcepto || "78101800");

            } else {
                setUsoCfdi("P01 - Por definir");
                setMetodoPago("PUE - Pago en una sola exhibición");
                setFormaPago("99 - Por definir");
                setCondicionesPago("");
                setClaveConcepto("78101800");
            }


            setSerie("");


            setDolarPrice(dolarprice || recordedit.dolarprice || 20.00)
            setNviaje(recordedit.general.nviaje || "");
            setNcarta(recordedit.general.ncarta || "");
            setCliente(recordedit.general.cliente || "");
            setClienteId(recordedit.general.clienteId || "");
            if (!recordedit.detalles.currencyMX) {
                setMoneda("DLL");
                setIsDLL(true);

            }
            if (recordedit.lineas_captura && recordedit.lineas_captura.length > 0) {
                let auxextras = [];
                recordedit.lineas_captura.forEach(ex => {
                    if (ex.isIngreso && ex.codigoSAT) {
                        let objextratopush = { clave: ex.codigoSAT, descricpion: ex.descripcioSAT || "", monto: ex.montoFloat, retencion: "0" };
                        if (uid && requiereIVacustom) {
                            objextratopush.customivar = "0.16";
                        }
                        auxextras.push(objextratopush)
                    }
                });
                setExtras(auxextras);

            }



            //facuturado
            if (recordedit.extrasDentroFlete && recordedit.extrasDentroFlete.length > 0) {
                console.log("si incluye desglose", recordedit.extrasDentroFlete)
                setIncluyeDesglose(true);
            } else {
                setIncluyeDesglose(false);
            }
            if (recordedit.facuturado) {
                setFacturaArchivos(recordedit.facuturado);
            } else {
                setFacturaArchivos(null);
            }
            if (recordedit.facturaCancelada === true) {
                // console.log("is cancelada factura",recordedit.facturaCancelada);
                setIsFacturaCancelada(true);
            } else {
                setIsFacturaCancelada(false);
            }

        } else {

        }
    }, [recordedit]);

    const changeRetencionExtra = (event, index) => {
        //
        let newArr = [...extras];

        if (index >= 0) {
            newArr[index].retencion = event.target.value;
            setExtras(newArr);
        }

    }

    const changeIvaCustomExtra = (event, index) => {
        //
        let newArr = [...extras];

        if (index >= 0) {
            newArr[index].customivar = event.target.value;
            setExtras(newArr);
        }

    }

    useEffect(() => {

        if (localStorage.getItem('deny')) {
            let auxdeny = localStorage.getItem('deny').split(":");
            if (auxdeny.find(e => e === "timbrado")) {
                setAllowTimbrado(false);
            }


        }
        setCheckinCSD(true);
        setUid(props.uid);
        //LOZJRkQRLvbN2mWSjX4MFNr1qvj2
        //perzonalizacion IMIX vG29FGwOkOaayuCoQub18bzwpMx1
        if (props.uid === "vdFfOQBu8QThjB74T4aoYLigZE03" || props.uid === "9nhgbXbSy4StUGrqTvjQaa0pXpa2" || props.uid === "Yi6xYs7PdUWNNLYXfl7XwV5oNVt1" || props.uid === "LOZJRkQRLvbN2mWSjX4MFNr1qvj2" || props.uid === "vG29FGwOkOaayuCoQub18bzwpMx1" || props.uid === "70NJdMGNIFQhYd8cpiAx26zHPXO2" || props.uid === "KeMdhwEofxWSIrXh2mylJWra9Jr1" || props.uid === "BoGosidpskNN0ggjtrAwHPcug1A2" || props.uid === "Is55uxcCDdMdI53LaFtWvyFXGH03") {
            // setIsDescripcionPersonalizada(true);
        }
        setIsDescripcionPersonalizada(true);

        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);

        //  initValues();

        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        // setRecordEdit(props.currenteditrecrod);
        if (props.currenteditrecrod) {
            if (props.currenteditrecrod.notacredito) {

            } else {
                setIdViaje(props.currenteditrecrod.key);
            }

        }
        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (idViaje) {

            initValues();
        }

    }, [idViaje]);




    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            checkCSD();

            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    useEffect(() => {
        //particualridad IMIX
        if (claveConcepto && claveConcepto !== "" && isDescripcionPerzonalizada) {
            //clave descrip
            let seleccionado = conceptos.find(c => c.clave === claveConcepto);
            if (seleccionado)
                setDescripcionPersonalizada(seleccionado.descripcion);
        }
    }, [claveConcepto]);

    const validaCCP = async () => {
        const errores = [];
        if (recordedit) {
            if (!recordedit.carta_porte.distBase_km || recordedit.carta_porte.distBase_km === "" || recordedit.carta_porte.distBase_km == 0) {
                errores.push("Falta registrar los Kilómetros del viaje");
            }


            if (errores.length > 0) {
                let errstrings = errores.join(",");
                Swal.fire("Viaje no valido", `Faltan datos del viaje: ${errstrings}`, 'warning');
                return false;
            }

        } else {
            Swal.fire("Viaje no valido", `No se encontraron datos del viaje`, 'warning');
            return false;
        }
        return true;
    }
    const actualizaConfiguracion = async () => {
        if (clienteId) {
            let nuevaConfiguracion = {
                usoCfdi,
                formaPago,
                metodoPago,
                claveConcepto
            }
            await updateConfiguracionCliente(clienteId, nuevaConfiguracion).then(resp => { console.log("configuracion actualizada") }).catch(err => { console.log(err) });
        }

    }
    const handleSumbit = async (e) => {
        e.preventDefault();
        if (ntibmres <= 0) {
            Swal.fire("No se puede timbrar", "No hay timbres disponibles", 'warning');
            return;
        }
        if (!facturaArchivos && recordedit && recordedit.key) {

            setIsLoading(true);
            let ivarate = 0.16;
            let retencionrate = 0.0;
            let retencionisrrate = 0.0;

            /*if (isIVA16) {
                ivarate = 0.16;
            } else {
                ivarate = 0.08;
            }*/
            if (ivatasa == "16") {
                ivarate = 0.16;
            } else if (ivatasa == "8") {
                ivarate = 0.08;
            } else if (ivatasa == "-1") {
                ivarate = -1;
            }
            else {
                ivarate = 0.0;
            }

            if (retencion === "4") {
                retencionrate = 0.04;
            } else if (retencion == "1.25")
                retencionrate = 0.0125;
            else if (retencion == "0.106667")
                retencionrate = 0.106667;
            else {
                retencionrate = 0.0;
            }


            if (retencionISR === "4") {
                retencionisrrate = 0.04;
            } else if (retencionISR == "1.25")
                retencionisrrate = 0.0125;
            else {
                retencionisrrate = 0.0;
            }

            let rutatipofactura = "fpt";
            if (!recordedit.isFormatoNuevo) {
                rutatipofactura = "fptcfdiingreso";
            } else {
                let valido = await validaCCP(recordedit.key);
                if (!valido) {
                    return;
                }

                if (recordedit.versionCCP == "3.0") {
                    rutatipofactura = "fpt30";
                }

                if (recordedit.versionCCP == "3.1") {
                    rutatipofactura = "fpt31";
                }
            }
            //https://us-central1-transport-plus-98f66.cloudfunctions.net/app
            //http://localhost:5000/transport-plus-98f66/us-central1/app

            //https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/
            //http://localhost:5000/unit-testing-d4fe7/us-central1/app/api/
            let rutaPost = "https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/";
            if (rfc === "EKU9003173C9") {
                rutaPost = "https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/";
            }

            //  rutaPost = "http://localhost:5000/unit-testing-d4fe7/us-central1/app/api/";

            console.log("data a post", {
                id: recordedit.key,
                rfc,
                ivar: ivarate,
                retencionrate,
                retencionisrrate,
                metodoPago,
                claveConcepto,
                extras,
                moneda,
                formaPago,
                condicionesPago,
                usoCfdi,
                traslado,
                version,
                dolarprice
            });

            if (!traslado && clienteData && !clienteData.configuracionCFDI) {
                await actualizaConfiguracion();
            } else if (!traslado && clienteData && clienteData.configuracionCFDI) {
                //checamos si hay que actualizar
                if (clienteData.configuracionCFDI.usoCfdi !== usoCfdi ||
                    clienteData.configuracionCFDI.formaPago !== formaPago ||
                    clienteData.configuracionCFDI.metodoPago !== metodoPago ||
                    clienteData.configuracionCFDI.claveConcepto !== claveConcepto) {
                    await actualizaConfiguracion();
                }
            }

            if (extrasDentroFlete && extrasDentroFlete.length > 0) {

                await actualizaExtrasDentroFlete(recordedit.key, extrasDentroFlete).then(resp => { }).catch(err => { });
            }
            // return;
            let fixclaveconcepto = claveConcepto;
            if (requiereserie.find(f => f === uid) && serie != "" && serie.trim() != "") {
                fixclaveconcepto = fixclaveconcepto + '-' + serie.trim();
            }
            let digitos = false;
            if (clienteData && clienteData.decimales && clienteData != false) {
                digitos = '4';
            }

            /*console.log("datapost", {
                id: recordedit.key,
                rfc,
                ivar: ivarate,
                retencionrate,
                retencionisrrate,
                metodoPago,
                claveConcepto: fixclaveconcepto,
                extras,
                moneda,
                formaPago,
                usoCfdi,
                version,
                traslado,
                digitos,
                dolarprice,
                existeRelacion,
                UIDRelacion,
                serie: serie.trim(),2-3
                tipoRelacion,
                perzonalizada: isDescripcionPerzonalizada ? descripcionPersonalizada : null
            });
            return;*/
            await axios.post(`${rutaPost}${rutatipofactura}`,
                {
                    id: recordedit.key,
                    rfc,
                    ivar: ivarate,
                    retencionrate,
                    retencionisrrate,
                    metodoPago,
                    claveConcepto: fixclaveconcepto,
                    extras,
                    moneda,
                    formaPago,
                    condicionesPago,
                    usoCfdi,
                    version,
                    traslado,
                    digitos,
                    dolarprice,
                    existeRelacion,
                    UIDRelacion,
                    serie: serie.trim(),
                    tipoRelacion,
                    perzonalizada: isDescripcionPerzonalizada ? descripcionPersonalizada : null
                }).then(resp => {
                    setIsLoading(false);
                    props.notify(resp.data);
                    props.toggle();

                    if (resp.data == "Ok") {
                        Swal.fire("Factura generada", `Se ha generado la factura `, 'success');
                    } else {
                        console.log("resp.data", resp.data);

                        if (resp.data && resp.data.estatus && resp.data.estatus.informacionTecnica) {
                            Swal.fire("No se pudo generar", `${resp.data.estatus.informacionTecnica}`, 'warning');
                        } else {
                            if (typeof resp.data === "object") {
                                const myJSON = JSON.stringify(resp.data);
                                Swal.fire("No se pudo generar", `${myJSON}`, 'warning');
                            } else {
                                Swal.fire("No se pudo generar", `${resp.data}`, 'warning');
                            }

                        }

                    }

                    //console.log("respuesta", resp);


                }).catch(err => {
                    setIsLoading(false);
                    console.log("error", err.toString());
                    if (err.toString().includes("Network Error")) {
                        Swal.fire("Error", "Error de conexión, no fue posible conectar con el servidor, revisa tu conexión", 'error');
                    } else {
                        Swal.fire("Error", "No se pudo generar la factura", 'error');
                    }
                });
        }
    }


    const handlePreview = async () => {

        console.log("submiting");
        if (clienteId === "" || cliente === "") {
            Swal.fire("CFDI no válido", `Selecciona el cliente`, 'warning');
            return false;
        }
        let ivarate = 0.16;
        let retencionrate = 0.0;
        let retencionisrrate = 0.0;

        /*if (isIVA16) {
            ivarate = 0.16;
        } else {
            ivarate = 0.08;
        }*/
        if (ivatasa == "16") {
            ivarate = 0.16;
        } else if (ivatasa == "8") {
            ivarate = 0.08;
        } else if (ivatasa == "-1") {
            ivarate = -1;
        }
        else {
            ivarate = 0.0;
        }

        if (retencion === "4") {
            retencionrate = 0.04;
        } else if (retencion == "1.25")
            retencionrate = 0.0125;
        else if (retencion == "0.106667")
            retencionrate = 0.106667;
        else {
            retencionrate = 0.0;
        }


        if (retencionISR === "4") {
            retencionisrrate = 0.04;
        } else if (retencionISR == "1.25")
            retencionisrrate = 0.0125;
        else {
            retencionisrrate = 0.0;
        }



        if (extrasDentroFlete && extrasDentroFlete.length > 0) {

            await actualizaExtrasDentroFlete(recordedit.key, extrasDentroFlete).then(resp => { }).catch(err => { });
        }
        // return;
        let fixclaveconcepto = claveConcepto;
        if (requiereserie.find(f => f === uid) && serie != "" && serie.trim() != "") {
            fixclaveconcepto = fixclaveconcepto + '-' + serie.trim();
        }
        let digitos = false;
        if (clienteData && clienteData.decimales && clienteData != false) {
            digitos = '4';
        }

        setIsLoading(true);

        let datatopost = {
            id: recordedit.key,
            rfc,
            ivar: ivarate,
            retencionrate,
            retencionisrrate,
            metodoPago,
            claveConcepto: fixclaveconcepto,
            extras,
            moneda,
            formaPago,
            condicionesPago,
            usoCfdi,
            version,
            traslado,
            digitos,
            dolarprice,
            existeRelacion,
            UIDRelacion,
            serie: serie.trim(),
            tipoRelacion,
            perzonalizada: isDescripcionPerzonalizada ? descripcionPersonalizada : null
        }

        let rutaPost = "https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/";


        //rutaPost = "http://localhost:5000/unit-testing-d4fe7/us-central1/app/api/";
        let rutatipofactura = "fpt30preview";

        await axios.post(`${rutaPost}${rutatipofactura}`, datatopost).then(resp => {
            setIsLoading(false);
            //  props.notify();
            // props.toggle();
            console.log(resp.data);
            if (resp.data.id && resp.data.id != 0) {
                let dtime = new Date().getTime();
                const win = window.open('/preview/' + resp.data.id + "?d=" + dtime, "_blank");
                win.focus();
                //   Swal.fire("Factura generada", `Se ha generado la perview ${ resp.data.id}`, 'success');
            } else {
                console.log("resp.data", resp.data);
                if (typeof resp.data === "object") {
                    const myJSON = JSON.stringify(resp.data);
                    Swal.fire("No se pudo generar", `${myJSON}`, 'warning');
                } else {
                    Swal.fire("No se pudo generar", `${resp.data}`, 'warning');
                }



            }

            //console.log("respuesta", resp);


        }).catch(err => {
            setIsLoading(false);
            console.log("error", err.toString());
            if (err.toString().includes("Network Error")) {
                Swal.fire("Error", "Error de conexión, no fue posible conectar con el servidor, revisa tu conexión", 'error');
            } else {
                Swal.fire("Error", "No se pudo generar la preview", 'error');
            }
        });
    }

    const downloadPDF = (pdf) => {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        let nombrefactura = "";
        if (ncarta) {
            nombrefactura = ncarta || "";
        }
        const fileName = `factura${nombrefactura}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    const downloadXML = (pdf) => {
        const linkSource = `data:application/xml;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        let nombrefactura = "";
        if (ncarta) {
            nombrefactura = ncarta || "";
        }
        const fileName = `factura${nombrefactura}.xml`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    //verComplementoCartaPorteSinpresio
    const verComplementoCartaPorte = async (requiereTarifa = 1) => {
        console.log("formato nuevo", recordedit.isFormatoNuevo);
        if (recordedit && recordedit.isFormatoNuevo && recordedit.isFormatoNuevo == true) {
            let datatimbrado = await asyncparser(facturaArchivos);
            console.log("datatimbrado1", datatimbrado);
            let rfctimbrado = null;
            let rfcReceptor = null;
            if (datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc']) {
                rfctimbrado = datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc'];
                rfcReceptor = datatimbrado['cfdi:Comprobante']['cfdi:Receptor'][0]['$']['Rfc'];
            }
            if (rfctimbrado) {
                let dates = new Date();
                if (recordedit.dataTimbrada) {
                    if (rfctimbrado !== rfcReceptor) {
                        const win = window.open('/ccpv2/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + requiereTarifa, "_blank");
                        win.focus();
                    } else {
                        const win = window.open('/ccptraslado/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + requiereTarifa, "_blank");
                        win.focus();
                    }

                } else {
                    const win = window.open('/ccp/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
                    win.focus();
                }

            }
        } else if (recordedit) {
            let datatimbrado = await asyncparser(facturaArchivos);
            console.log("datatimbrado2", datatimbrado);
            let rfctimbrado = null;
            if (datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc']) {
                rfctimbrado = datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc'];
            }
            if (rfctimbrado) {
                let dates = new Date();
                const win = window.open('/cfdiv/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
                win.focus();
            }

            //descargarFacturaPDF();
        }

        // return;
        /* if (!facturaArchivos && recordedit && recordedit.key) {
             let dates = new Date();
             const win = window.open('/ccp/' + recordedit.key + "?rfc=" + rfc + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
             win.focus();
         } else if (facturaArchivos) {
             let dates = new Date();
             const win = window.open('/ccp/' + recordedit.key + "?rfc=" + facturaArchivos.rfc + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
             win.focus();
         }else{
             //alert("here");
         }*/
    }

    const verDesgloseCartaPorte = async () => {
        if (recordedit && recordedit.isFormatoNuevo) {
            let datatimbrado = await asyncparser(facturaArchivos);
            console.log("datatimbrado", datatimbrado);
            let rfctimbrado = null;
            let rfcReceptor = null;
            if (datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc']) {
                rfctimbrado = datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc'];
                rfcReceptor = datatimbrado['cfdi:Comprobante']['cfdi:Receptor'][0]['$']['Rfc'];
            }
            if (rfctimbrado) {
                let dates = new Date();
                if (recordedit.dataTimbrada) {
                    if (rfctimbrado !== rfcReceptor) {
                        const win = window.open('/desglose/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
                        win.focus();
                    } else {
                        const win = window.open('/desglose/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
                        win.focus();
                    }

                } else {
                    const win = window.open('/desglose/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
                    win.focus();
                }

            }
        } else if (recordedit) {
            let datatimbrado = await asyncparser(facturaArchivos);
            console.log("datatimbrado", datatimbrado);
            let rfctimbrado = null;
            if (datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc']) {
                rfctimbrado = datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc'];
            }
            if (rfctimbrado) {
                let dates = new Date();
                const win = window.open('/cfdiv/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
                win.focus();
            }

            //descargarFacturaPDF();
        }

        // return;
        /* if (!facturaArchivos && recordedit && recordedit.key) {
             let dates = new Date();
             const win = window.open('/ccp/' + recordedit.key + "?rfc=" + rfc + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
             win.focus();
         } else if (facturaArchivos) {
             let dates = new Date();
             const win = window.open('/ccp/' + recordedit.key + "?rfc=" + facturaArchivos.rfc + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
             win.focus();
         }else{
             //alert("here");
         }*/
    }
    const asyncparser = async (obj) => {
        if (obj) {
            let xmlstring = obj.cfdixml;
            //let fixedXml=xmlstring.replace('cfdi:', '');
            let fixedXml = xmlstring.split('cfdi:').join('');
            fixedXml = xmlstring.split('$').join('obj');

            let response = null;
            await xml2js.parseString(fixedXml, async (err, result) => {
                if (err) {
                    console.log(err);
                }
                else {
                    // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                    // let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                    console.log("primer xml", result);
                    response = result;
                    // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                }

            });

            // console.log("response xml", response);
            return response;
        } else {
            return null;
        }

    }

    const descargarFacturaPDF = () => {
        if (facturaArchivos) {
            downloadPDF(facturaArchivos.pdf);
            /*getFacturaArchivos(facturaArchivos).then(docu => {
                downloadPDF(docu.data().pdf);
            }).catch(err => { });*/
        }
    }
    const descargarFacturaXML = () => {
        if (facturaArchivos) {
            var xmltext = facturaArchivos.cfdixml;

            let nombrefactura = "";
            if (ncarta) {
                nombrefactura = ncarta || "";
            }
            var filename = `factura${nombrefactura}.xml`;
            var pom = document.createElement('a');
            var bb = new Blob([xmltext], { type: 'text/plain' });

            pom.setAttribute('href', window.URL.createObjectURL(bb));
            pom.setAttribute('download', filename);

            pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
            pom.draggable = true;
            pom.classList.add('dragout');

            pom.click();
            /*
            getFacturaArchivos(facturaArchivos).then(docu => {
                downloadXML(docu.data().xml);
            }).catch(err => { });*/
        }
    }
    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {facturaArchivos ? "Descargar Factura" : `Facturar viaje. `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <LoadingOverlay
                        active={chekingCSD}
                        spinner
                        text='Comprobando CSD, espere ...'
                    >
                        {
                            isFacuturaCancelada && <div>
                                Factura cancelada, generar nuevo CFDI..
                            </div>
                        }
                        {
                            (facturaArchivos) ? <div>
                                <div className="row">
                                    <div style={{ paddingRight: 20, paddingLeft: 20 }} className="col-6">
                                        <div onClick={() => verComplementoCartaPorte()} className="btn btn-primary col-12"> Descargar PDF</div>
                                    </div>

                                    <div style={{ paddingRight: 20, paddingLeft: 20 }} className="col-6">
                                        <div onClick={() => descargarFacturaXML()} className="btn btn-primary col-12"> Descargar XML</div>
                                    </div>

                                </div>
                                {
                                    incluyeDesglose && <div style={{ marginTop: '10px' }} className="row">
                                        <div style={{ paddingRight: 20, paddingLeft: 20 }} className="col-6">
                                            <div onClick={() => verDesgloseCartaPorte()} className="btn btn-primary col-12"> Descargar Desglose</div>
                                        </div>



                                    </div>
                                }
                                {
                                    uid && requiereSinPresio.find(fd => fd === uid) && <div style={{ marginTop: '10px' }} className="row">
                                        <div style={{ paddingRight: 20, paddingLeft: 20 }} className="col-6">
                                            <div onClick={() => verComplementoCartaPorte(0)} className="btn btn-primary col-12"> Descargar PDF Operador</div>
                                        </div>



                                    </div>
                                }
                            </div> : <div>
                                {allowTimbrado ? <div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div class="dataTable twoCol">
                                            <div class="dataTitle"><p className="fixedP">CFDI</p></div>
                                            <div class="dataBox">
                                                <div class="dataRow">
                                                    <p className="fixedP">Versión CFDI</p>
                                                    <div class="fieldInput">
                                                        <select name='rfc' onChange={(e) => setVersion(e.target.value)} value={version} required >
                                                            {/*<option disabled key="ver33" value={`3.3`} >{`3.3`}</option>*/}
                                                            <option key="ver40" value={`4.0`} >{`4.0`}</option>
                                                        </select>

                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">Cliente</p>
                                                    <div class="fieldInput">
                                                        <input readOnly type="text" name='cliente' value={cliente} onChange={e => setCliente(e.target.value)} />

                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">RFC Cliente</p>
                                                    <div class="fieldInput">
                                                        <input readOnly type="text" name='cliente' value={clienteData ? clienteData.rfc : ""} onChange={e => console.log("")} />

                                                    </div>
                                                </div>

                                                <div class="dataRow">
                                                    <p className="fixedP">No. Carta Porte</p>
                                                    <div class="fieldInput">
                                                        <input readOnly type="text" name='ncarta' value={ncarta} onChange={e => setNcarta(e.target.value)} />

                                                    </div>
                                                </div>
                                                {!traslado && <div class="dataRow">
                                                    <p className="fixedP">IVA</p>
                                                    <div class="fieldInput">
                                                        <select name='rfc' value={ivatasa} onChange={e => setIvaTasa(e.target.value)} required >
                                                            <option key={"iva16"} value={`16`} >{`16%`}</option>
                                                            <option key={"iva08"} value={`8`} >{`8%`}</option>
                                                            <option key={"iva00"} value={`0`} >{`Tasa 0`}</option>
                                                            <option key={"ivanoobj"} value={`-1`} >{`No objeto`}</option>
                                                        </select>

                                                    </div>
                                                </div>}
                                                <div class="dataRow">
                                                    <p className="fixedP">Emisor</p>
                                                    <div class="fieldInput">
                                                        <select name='rfc' onChange={(e) => setRfc(e.target.value)} value={rfc} required >
                                                            {
                                                                razonesSociales.map((item, index) => {
                                                                    return <option key={index} value={item.Rfc}>{item.Razon}</option>
                                                                })
                                                            }
                                                        </select>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dataTable twoCol">
                                            <div class="dataTitle"><p className="fixedP">Terminos</p></div>
                                            <div class="dataBox">

                                                <div class="dataRow">
                                                    <p className="fixedP">Uso CFDI</p>
                                                    <div class="fieldInput">
                                                        <select name='rfc' onChange={(e) => setUsoCfdi(e.target.value)} value={usoCfdi} required >
                                                            {
                                                                CAT_USOCFDI.map((item, index) => {
                                                                    return <option key={index} value={`${item.Value} - ${item.Name}`} >{`${item.Value} - ${item.Name}`}</option>
                                                                })
                                                            }
                                                        </select>

                                                    </div>
                                                </div>

                                                {
                                                    traslado && <div class="dataRow">
                                                        <p className="fixedP">CFDI</p>
                                                        <div class="fieldInput">
                                                            <input readOnly type="text" name='traslado' value={"TRASLADO"} onChange={e => console.log("")} />

                                                        </div>
                                                    </div>
                                                }

                                                {!traslado && <div class="dataRow">
                                                    <p className="fixedP">Método de Pago</p>
                                                    <div class="fieldInput">
                                                        <select name='rfc' onChange={(e) => setMetodoPago(e.target.value)} value={metodoPago} required >
                                                            {
                                                                CAT_METODOSPAGO.map((item, index) => {
                                                                    return <option key={index} value={`${item.Value} - ${item.Name}`} >{`${item.Value} - ${item.Name}`}</option>
                                                                })
                                                            }
                                                        </select>

                                                    </div>
                                                </div>}

                                                {!traslado && <div class="dataRow">
                                                    <p className="fixedP">Forma de Pago</p>
                                                    <div class="fieldInput">
                                                        <select name='rfc' onChange={(e) => setFormaPago(e.target.value)} value={formaPago} required >
                                                            {
                                                                CAT_FORMASPAGO.map((item, index) => {
                                                                    return <option key={index} value={`${item.Value} - ${item.Name}`} >{`${item.Value} - ${item.Name}`}</option>
                                                                })
                                                            }
                                                        </select>

                                                    </div>
                                                </div>}
                                                {!traslado && <div class="dataRow">
                                                    <p className="fixedP">Condonaciones de Pago</p>
                                                    <div class="fieldInput">
                                                        <input placeholder='Opcional' type="text" name='condicionesPago' value={condicionesPago} onChange={e => setCondicionesPago(e.target.value)} />
                                                    </div>
                                                </div>}


                                                {!traslado && <div class="dataRow">
                                                    <p className="fixedP">Moneda</p>
                                                    <div class="fieldInput">
                                                        <select name='rfc' onChange={(e) => setMoneda(e.target.value)} value={moneda} required >
                                                            <option key={"m01"} value={`MXN`} >{`MXN`}</option>
                                                            <option key={"m02"} value={`DLL`} >{`USD`}</option>

                                                        </select>

                                                    </div>
                                                </div>}
                                                {!traslado && <div class="dataRow">
                                                    <p className="fixedP">Tipo de Cambio</p>
                                                    <div class="fieldInput">
                                                        <input type="number" step={(uid && requiere4decimalestipocambio.find(f => f == uid)) ? 0.0001 : 0.01} min={0.00} style={{ width: "90%" }} name='cliente' value={dolarprice} onChange={e => setDolarPrice(e.target.value)} />
                                                    </div>
                                                </div>}
                                                {
                                                    <div class="dataRow">
                                                        <p className="fixedP">Relación</p>
                                                        <div class="fieldInput">
                                                            <select name='rfc' onChange={(e) => setExisteRelacion(e.target.value)} value={existeRelacion} required >
                                                                <option key={"r01"} value={`Si`} >{`Si`}</option>
                                                                <option key={"r02"} value={`No`} >{`No`}</option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    existeRelacion === "Si" && <div class="dataRow">
                                                        <p className="fixedP">Tipo Relación</p>
                                                        <div class="fieldInput">
                                                            <select name='rfc' onChange={(e) => setTipoRelacion(e.target.value)} value={tipoRelacion} required >
                                                                {
                                                                    CAT_TIPORELACION.map((item, index) => {
                                                                        return <option key={index} value={`${item.value}`} >{`${item.value} - ${item.descripcion}`}</option>
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    existeRelacion === "Si" && <div class="dataRow">
                                                        <p className="fixedP">UUID</p>
                                                        <div class="fieldInput">
                                                            <input type="text" style={{ width: "90%" }} name='cliente' value={UIDRelacion} onChange={e => setUidRelacion(e.target.value)} required />
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div class="dataTable oneColmargin">
                                        <div class="dataTitle"><p className="fixedP">Conceptos</p>

                                        </div>

                                        <div class="dataBox">
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '63.5%' }}>
                                                    <div class="dataRow">
                                                        <p className="fixedP">Concepto</p>
                                                        <div class="fieldInput" style={{ width: '93%' }}>
                                                            <select name='rfc' onChange={(e) => setClaveConcepto(e.target.value)} value={claveConcepto} required >
                                                                {
                                                                    conceptos.map((item, index) => {
                                                                        return <option key={index} value={`${item.clave}`} >{`${item.clave.split("x").join("")} - ${item.descripcion}`}</option>
                                                                    })
                                                                }

                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ width: '16.5%', marginLeft: '2px' }}>
                                                    <div class="dataRow">
                                                        <p className="fixedP">Retención</p>
                                                        <div class="fieldInput">
                                                            {!traslado &&
                                                                <select name='retencion' value={retencion} onChange={e => setRetencion(e.target.value)} required >
                                                                    <option key={"retvalue4"} value={`4`} >{`4%`}</option>
                                                                    <option key={"retvalue0"} value={`0`} >{`0`}</option>
                                                                    {/*<option key={"retvalue23"} value={`0.106667`} >{`2/3`}</option>*/}

                                                                </select>

                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ width: '16.5%', marginLeft: '2px' }}>
                                                    <div class="dataRow">
                                                        <p className="fixedP">Ret.ISR</p>
                                                        <div class="fieldInput">
                                                            {!traslado &&
                                                                <select name='retencion' value={retencionISR} onChange={e => setRetencionISR(e.target.value)} required >
                                                                    <option key={"retvalue125"} value={`1.25`} >{`1.25%`}</option>
                                                                    <option key={"retvalue0"} value={`0`} >{`0`}</option>

                                                                </select>

                                                            }

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            {
                                                //particularidad de IMIX
                                                requiereserie.find(f => f === uid) &&
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ width: '73.5%' }}>
                                                        <div class="dataRow">
                                                            <p className="fixedP">NoIdentificacion</p>
                                                            <div class="fieldInput" style={{ width: '93%' }}>
                                                                <input type="text" value={serie} onChange={e => setSerie(e.target.value)} />

                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            }
                                            {
                                                //particularidad de IMIX
                                                isDescripcionPerzonalizada &&
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ width: '73.5%' }}>
                                                        <div class="dataRow">
                                                            <p className="fixedP">Descripción</p>
                                                            <div class="fieldInput" style={{ width: '93%' }}>
                                                                <input type="text" value={descripcionPersonalizada} onChange={e => setDescripcionPersonalizada(e.target.value)} />

                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            }
                                            {
                                                extras.map((extra, index) => {
                                                    return <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '73.5%' }}>
                                                            <div class="dataRow">
                                                                <p className="fixedP">Concepto</p>
                                                                <div class="fieldInput" style={{ width: '93%' }}>
                                                                    <input type='text' disabled value={`${extra.clave} ${extra.descricpion}`} />


                                                                </div>
                                                            </div>
                                                        </div>{
                                                            uid && requiereIVacustom.find(fic => fic === uid) &&
                                                            <div style={{ width: '16.5%', marginLeft: '2px' }}>
                                                                <div class="dataRow">
                                                                    <p className="fixedP">IVA</p>
                                                                    <div class="fieldInput">

                                                                        <select name='retencion' value={extra.customivar} onChange={e => changeIvaCustomExtra(e, index)} required >
                                                                            <option key={"retvalue4"} value={`0.16`} >{`16%`}</option>
                                                                            <option key={"retvalue125"} value={`0.08`} >{`8%`}</option>
                                                                            <option key="retvalue533" value={`0`} >{`Tasa 0%`}</option>
                                                                            <option key={"retvalue0"} value={`-1`} >{`No Objeto`}</option>

                                                                        </select>



                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {!traslado && <div style={{ width: '16.5%', marginLeft: '2px' }}>
                                                            <div class="dataRow">
                                                                <p className="fixedP">Retención</p>
                                                                <div class="fieldInput">

                                                                    <select name='retencion' value={extra.retencion} onChange={e => changeRetencionExtra(e, index)} required >
                                                                        <option key={"retvalue4"} value={`0.04`} >{`4%`}</option>
                                                                        <option key={"retvalue125"} value={`0.0125`} >{`1.25%`}</option>
                                                                        <option key="retvalue533" value={`5.33`} >{`5.33%`}</option>
                                                                        <option key={"retvalue0"} value={`0`} >{`0`}</option>

                                                                    </select>



                                                                </div>
                                                            </div>
                                                        </div>}

                                                    </div>
                                                })
                                            }


                                        </div>
                                    </div>
                                    
                                    {
                                        uid && requieredesglose.find(re => re === uid) &&
                                        <div class="dataTable oneColmargin">
                                            <div class="dataTitle"><p className="fixedP">Extras dentro de flete</p>
                                                <button type="button" class="boton plus-button" onClick={() => {
                                                    let aux = { clave: '', ivacustom: 0, descripcion: '', retencion: '0', retencionisrrate: 0, monto: 0.0, montos: '$0.0', cantidad: 1 };
                                                    let temp = [...extrasDentroFlete];
                                                    temp.push(aux);
                                                    setExtrasDentroFlete(temp);
                                                }} ></button>
                                            </div>

                                            <div class="dataBox">
                                                {
                                                    extrasDentroFlete.map((p, index) => {
                                                        return <div>
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <div style={{ width: '63.5%' }}>
                                                                    <div class="dataRow">
                                                                        <p className="fixedP">Concepto</p>
                                                                        <div class="fieldInput" style={{ width: '93%' }}>

                                                                            <select name='rfc' onChange={(e) => {
                                                                                let aux = conceptosDataExtra.find(f => f.id === e.target.value);
                                                                                let temp = [...extrasDentroFlete];
                                                                                if (aux) {
                                                                                    temp[index].clave = aux.clave;
                                                                                    temp[index].descripcion = aux.descripcion;
                                                                                    temp[index].perzonalizada = aux.descripcion;
                                                                                    temp[index].ivacustom = aux.ivacustom || 0;
                                                                                    setExtrasDentroFlete(temp);
                                                                                }

                                                                            }} value={p.id} required >
                                                                                <option key={"vaciocon"} value={``} >{``}</option>
                                                                                {
                                                                                    conceptosDataExtra.map((item, index) => {
                                                                                        return <option key={index} value={`${item.id}`} >{`${item.descripcion}`}</option>
                                                                                    })
                                                                                }
                                                                            </select>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div style={{ width: '18%', marginLeft: '2px' }}>
                                                                    <div class="dataRow">
                                                                        <p style={{ width: 'auto' }} className="fixedP">Monto</p>
                                                                        <div class="fieldInput">
                                                                            <CurrencyInput value={p.montos} onChangeEvent={(event, maskedvalue, floatvalue) => {
                                                                                let temp = [...extrasDentroFlete];
                                                                                temp[index].monto = floatvalue;
                                                                                temp[index].montos = maskedvalue;
                                                                                setExtrasDentroFlete(temp);
                                                                            }} prefix="$" />
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div style={{ width: '5.5%', marginTop: '8px', marginLeft: '2px' }}>
                                                                    <button class="minus-button" onClick={() => {
                                                                        let aux = [];
                                                                        extrasDentroFlete.forEach((element, i) => {
                                                                            if (i === index) {
                                                                                // aux.push(value);
                                                                            } else {
                                                                                aux.push(element);
                                                                            }
                                                                        });
                                                                        setExtrasDentroFlete(aux);
                                                                    }}><span></span></button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                </div> : <div>
                                    <ul class="list-group">

                                        <li class="list-group-item list-group-item-danger" style={{}}>La cuenta no tiene permitido timbrar</li>


                                    </ul>
                                </div>}
                            </div>
                        }
                    </LoadingOverlay>
                </ModalBody>
                <ModalFooter>
                    {/*allowTimbrado && !facturaArchivos && !traslado && <div style={{ marginRight: 'auto' }}> <button type="button" className="boton secBtn" onClick={() => {
                        const win = window.open('/ccpreview/' + recordedit.key + "?rfc=" + rfc + "&mp=" + metodoPago + "&ucfdi=" + usoCfdi + "&m=" + moneda + "&c=" + dolarprice + "&fp=" + formaPago + "&i=" + ivatasa + "&r=" + retencion + "&l=" + claveConcepto, "_blank");
                        win.focus();
                    }} >Vista Preliminar</button></div>*/}

                    {allowTimbrado && !facturaArchivos && !traslado && <div style={{ marginRight: 'auto' }}> <button type="button" className="boton secBtn" onClick={e => handlePreview()} >Vista Preliminar</button></div>}


                    <div style={{ marginRight: 'auto' }}>Timbres:{` ${ntibmres}`}</div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    {allowTimbrado && <button type="submit" className="boton secBtn"> Aceptar</button>}
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >
}
export default ModalCreateEdit;