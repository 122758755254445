import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



export default class Download extends React.Component {
    constructor(props) {
        super();
        let { data } = props;
        this.state = {
            dataSet1: data,
        }
        //   console.log(this.state);

    }

    componentDidUpdate = (prevProps) => {

    }
    /**
     * 
     * numEconomico: element.numEconomico,
                                                            propiedad: element.propiedad,
                                                            tipo: element.tipo,
                                                            placas: element.placas,
                                                            marca: element.marca,
                                                            year: element.year,
                                                            descripcion: element.descripcion,
                                                            status: element.active ? "Activo" : "Inactivo"
     */

    render() {
        return (
            <ExcelFile element={<div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>}>
                {
                    this.state.dataSet1.map(d => {
                        return <ExcelSheet key={`segmento${d.segmento}`} data={d.data} name={`${d.segmento}`}>
                            <ExcelColumn label="Segmento" value="segmento" />
                            <ExcelColumn label="Fecha" value="fecha" />
                            <ExcelColumn label="Viajes" value="viajes" />
                            <ExcelColumn label="Kms" value="kms" />
                            <ExcelColumn label="Ingresos" value="ingresosfix" />
                            <ExcelColumn label="Costos Fijos" value="costosFijosfix" />
                            <ExcelColumn label="Costos Variables" value="costosVariablesfix" />
                            <ExcelColumn label="Sueldos Op." value="sueldosfix" />
                            <ExcelColumn label="Comisiones Op." value="comisionfix" />
                            <ExcelColumn label="CF x Km" value="cfxkm" /> 
                            <ExcelColumn label="CV x Km" value="cvxkm" />
                            
                            
                        </ExcelSheet>
                    })
                }



            </ExcelFile>
        );
    }
}
