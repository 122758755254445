import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input';
import Swal from 'sweetalert2';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addIngreso, updateIngreso, addDestino, getAllDestinosByUser } from '../../../controllers/ingresos';
import { actualizaPagos } from '../../../controllers/viajes';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { getClientes } from '../../../controllers/clientes';
import { makeStyles } from '@material-ui/core/styles';
import { pagarViajes } from '../../../controllers/ingresos';
import { getViajesFacturadosByCliente, getViajesFacturadosByClienteByRange, getUbicaciones } from '../../../controllers/viajes';
import { getUserByUid } from '../../../controllers/usuarios';
import { addInvoice } from '../../../controllers/innvoices';
import Checkbox from '@material-ui/core/Checkbox';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import axios from 'axios';
import xml2js from 'xml2js';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'inline-block',
        float: 'right',
        width: '60%',
        height: 30,
        marginTop: 4,
        marginRight: 2
    }
}));
const ModalCreateEdit = (props) => {
    //constantes
    const CATALOGOS_TIPOS = ['Facturación por ventas', 'Renta de unidad', 'Otros ingresos'];
    const CATALOGO_METODOS_PAGO = ['Transferencia', 'Efectivo', 'Cheque'];
    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);
    const [destinosUser, setDestinosUser] = useState([]);
    //estados propios del formulario
    const [tipo, setTipo] = useState('Facturación por ventas');
    const [monto, setMonto] = useState('');
    const [montoFloat, setMontoFloat] = useState(0.0);
    const [iva, setIva] = useState('');
    const [ivaFloat, setIvaFloat] = useState(0.0);
    const [referencia, setReferencia] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [cliente, setCliente] = useState('-');
    const [clienteId, setClienteId] = useState("");
    const [metodoPago, setMetodoPago] = useState('Depósito');
    const [facturas, setFacturas] = useState([]);
    const [facturaid, setFacturaID] = useState(1);
    const [iva16, setIva16] = useState(true);
    const [destinoIngreso, setDestinoIngreso] = useState('');
    const [clientes, setClientes] = useState([]);
    const [facturasReft, setFacturasReft] = useState([]);
    const [usuarioobj, setUsuarioObj] = useState(null);
    const [segmentoNegocio, setSegmentoNegocio] = useState("");
    const [isComplementoPago, setIsComplementoPago] = useState(false);
    const [razonesSociales, setRazonesSociales] = useState([]);
    const [rfc, setRfc] = useState("");
    const [currencyMX, setCurrencyMX] = useState(true);

    const [dolarprice, setDolarPrice] = useState(20.0);

    const [fechaRangoStart, setFechaRangoStart] = useState(new Date());
    const [fechaRangoEnd, setFechaRangoEnd] = useState(new Date());
    const [totalNota, setTotalNota] = useState(0.0);
    const [totalNotaString, setTotalNotaString] = useState("$0.0");

    const [totalNotaDesc, setTotalNotaDesc] = useState(0.0);
    const [totalNotaDescString, setTotalNotaDescString] = useState("$0.0");
    const [porciento, setPorciento] = useState(10);

    const [ivatasa, setIvaTasa] = useState("16");
    const [retencion, setRetencion] = useState("0");


    const [localidades, setLocalidades] = useState([]);

    const [origen, setOrigen] = useState("");
    const [destino, setDestino] = useState("");
    const [origenId, setOrigenId] = useState("");
    const [destinoId, setDestinoId] = useState("");
    const [origenMunicipioSat, setOrigenMunicipioSat] = useState("");
    const [origenMunicipio, setOrigenMunicipio] = useState("");
    const [origenColonia, setOrigenColonia] = useState("");
    const [origenColoniaSat, setOrigenColoniaSat] = useState("");
    const [origenCiudadSat, setOrigenCiudadSat] = useState("");
    const [origenCiudad, setOrigenCiudad] = useState("");
    const [origenEstado, setOrigenEstado] = useState("");
    const [origenCP, setOrigenCP] = useState("");
    const [origenCalle, setOrigenCalle] = useState("");
    const [origenNumero, setOrigenNumero] = useState("");

    const [origenEstadoShort, setOrigenEstadoShort] = useState("");
    const [destinoMunicipioSat, setDestinoMunicipioSat] = useState("");
    const [destinoMunicipio, setDestinoMunicipio] = useState("");
    const [destinoColonia, setDestinoColonia] = useState("");
    const [destinoColoniaSat, setDestinoColoniaSat] = useState("");
    const [destinoCiudadSat, setDestinoCiudadSat] = useState("");
    const [destinoCiudad, setDestinoCiudad] = useState("");
    const [destinoEstado, setDestinoEstado] = useState("");
    const [destinoEstadoShort, setDestinoEstadoShort] = useState("");
    const [destinoCP, setDestinoCP] = useState("");
    const [destinoCalle, setDestinoCalle] = useState("");
    const [destinoNumero, setDestinoNumero] = useState("");

    const [items, setItems] = useState([{ descripcion: "", currency: '', currencyFloat: 0.0, unitpriceFloat: 0.0, unitprice: '', quanitty: 1 }]);

    const [subtotal, setSubtotal] = useState("");
    const [subtotalFloat, setSubtotalFloat] = useState(0.0);
    const [total, setTotal] = useState("");
    const [totalFloat, setTotalFloat] = useState(0.0);
    const [taxRate, setTaxRate] = useState(8);

    const [date, setDate] = useState("");
    const [invoiceNumber, setinvoiceNumber] = useState("");

    const [isViajeCargado, setIsViajeCargado] = useState(true);

    useEffect(() => {
        setMetodoPago('Depósito');
        setTipo('Facturación por ventas');
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);

    useEffect(() => {
        if (usuarioobj && usuarioobj.razones) {
            setRazonesSociales(usuarioobj.razones);
            if (usuarioobj.razones.length > 0 && usuarioobj.razones[0].Rfc) {
                setRfc(usuarioobj.razones[0].Rfc);
            }
            if (!isEdit && segmentoNegocio === "" && usuarioobj.segmento_negocio && usuarioobj.segmento_negocio.length > 0) {
                setSegmentoNegocio(usuarioobj.segmento_negocio[0].value);
            }
        }
    }, [usuarioobj])

    const getData = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let obuser = null;
            snapshot.forEach(document => {
                obuser = { ...document.data(), id: document.id }
                if (!obuser.segmento_negocio) {
                    obuser.segmento_negocio = [];
                }

            });
            setUsuarioObj(obuser);
        });
        await getClientes(uid).then(snapshot => {
            let aux = [];
            snapshot.forEach(document => {
                let o = { ...document.data() };
                o.id = document.id;
                aux.push(o);
            });
            let toset = aux.map((item, index) => {
                let ob = { ...item, name: item.nombre.trim() + " - " + item.estado_short.trim(), value: item.id, id: "user" + index };
                return ob;
            });
            setClientes(toset);
        }).catch(err => { });
        await getUbicaciones(uid).then(snapshot => {
            let aux = [];
            snapshot.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                aux.push(o);
            });
            let toset = aux.map((item, index) => {
                let num = item.numero || "";
                let idubica = item.nombre.trim() + " - " + item.calle.trim() + " " + num.trim();
                idubica = idubica.trim();
                let ob = { ...item, name: idubica, value: idubica };
                return ob;
            });
            setLocalidades(toset);

        }).catch(err => { });
    }
    useEffect(() => {
        if (uid) {
            getData(uid);
        }
    }, [uid]);

    useEffect(() => {
        if (facturasReft.length > 0) {

        }
    }, [facturasReft]);

    useEffect(() => {
        if (cliente && cliente != "" && clientes.length > 0) {
            let objf = clientes.find(element => element.name === cliente);
            if (objf) {
                if (objf.value != clienteId) {
                    // console.log("cliente info", objf);
                    setClienteId(objf.value);
                    // cargarFacturasReft(objf.value);
                }

            }
        }
    }, [cliente])

    const cargarFacturasReft = async (clienteid) => {
        console.log("cargando facturas de cliente", clienteid);
        getViajesFacturadosByCliente(clienteid).then(snapshot => {
            let aux = [];
            snapshot.forEach(doc => {
                let pendientes = 0.0;


                let o = doc.data();

                o.id = doc.id;
                o.key = doc.id;
                o.factura = o.general.nviaje;
                o.carta_porte = o.general.ncarta;
                o.cliente = o.general.cliente;
                o.total = o.detalles.tarifaFloat;
                o.fecha = o.fecha_facturado || null;
                if (o.pagos) {
                    //calculamos pendientes
                    let pagado = 0.0;
                    o.pagos.forEach(element => {
                        pagado += element.montoFloat;
                    });

                    if (o.factura_monto >= pagado) {
                        pendientes = o.factura_monto - pagado;
                    } else {
                        pendientes = 0.0;
                    }

                } else {
                    pendientes = o.factura_monto;
                }
                o.pendientes = pendientes;
                o.obj = { ...o };

                let nombreCP = "";
                if (o.general && o.general.ncarta) {
                    nombreCP = `  ${o.general.ncarta}`;
                }
                o.facturaname = `${o.prefijoFactura}-${o.folioFactura}${o.carta_porte}`;
                if (o.status !== "Pagado")
                    aux.push(o);

            });
            setFacturasReft(aux);
        }).catch(err => console.log(err));

    }
    const initValues = () => {
        /* if (props.facturasref) {
             setFacturasReft(props.facturasref);
         }*/

        if (isEdit && recordedit != null) {



        } else {
            setItems([{ descripcion: "", currency: '', currencyFloat: 0.0, unitpriceFloat: 0.0, unitprice: '', quanitty: 1 }]);
            setSubtotal("");
            setSubtotalFloat(0.0);
            setTotal("");
            setTotalFloat(0.0);
            setTaxRate(8);


        }
    }



    useEffect(() => {
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setFacturaID(1);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit && recordedit.facturas) {
            setFacturas(recordedit.facturas);
            initValues();
        }

    }, [recordedit]);


    const loadDestinos = () => {
        if (uid) {

            let destinos = [];
            getAllDestinosByUser(uid).then(snapshot => {
                snapshot.forEach(doc => {
                    let o = doc.data();
                    destinos.push(o);
                });
                setDestinosUser(destinos);
            }).catch(err => { console.log(err) });
        }
    }
    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
            loadDestinos();
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        setIsSearching(true);

        let obj = {
            uid,
            items,
            cliente,
            invoiceNumber,
            taxRate,
            clienteId,
            totalFloat,
            total,
            subtotal,
            subtotalFloat,
            referencia,
            date,
            isViajeCargado,
            origen,
            origenId,
            origenCiudad,
            origenEstado,
            origenMunicipio,
            origenColonia,
            origenCalle,
            origenCP,
            origenNumero,
            destino,
            destinoId,
            destinoCiudad,
            destinoEstado,
            destinoMunicipio,
            destinoColonia,
            destinoCalle,
            destinoCP,
            destinoNumero


        };
        if (isAdmin && adminCurrentUser) {
            obj.uid = adminCurrentUser;
        }

        await addInvoice(obj).then(resp => {
            setIsLoading(false);
            setIsSearching(false);
            setIsOpen(false);
            props.notify({ ...obj, id: recordedit.id });
            Swal.fire('Éxito', 'Datos registrados con éxito.', 'success');
        }).catch(err => console.log(err));

    }


    const onChangeDaterangeTwo = (fechas) => {
        if (!fechas || !fechas[0] || !fechas[1]) {
            return;
        }

        let start = new Date(fechas[0].getTime());
        let end = new Date(fechas[1].getTime());

        if (start)
            start.setHours(0, 0, 0);
        if (end)
            end.setHours(23, 59, 59);
        setFechaRangoStart(start);
        setFechaRangoEnd(end);

    };

    useEffect(() => {
        if (origen && destino) {
            let origenOBJ = localidades.find(element => element.name === origen);
            let destinoOBJ = localidades.find(element => element.name === destino);

        }
        if (origen) {
            let origenOBJ = localidades.find(element => element.name == origen);
            if (origenOBJ) {

                setOrigenId(origenOBJ.id);
                setOrigenCiudad(origenOBJ.localidad);
                setOrigenCiudadSat(origenOBJ.localidadSat || "");
                setOrigenEstado(origenOBJ.estado);
                setOrigenEstadoShort(origenOBJ.estado_short);
                setOrigenMunicipioSat(origenOBJ.municipioSat || "");
                setOrigenMunicipio(origenOBJ.municipio || "");
                setOrigenColonia(origenOBJ.colonia || "");
                setOrigenColoniaSat(origenOBJ.coloniaSat || "");
                setOrigenCP(origenOBJ.cp || "");
                setOrigenCalle(origenOBJ.calle || "");
                setOrigenNumero(origenOBJ.numero || "");

            }
        }
        if (destino) {
            let destinoOBJ = localidades.find(element => element.name == "" + destino);
            if (destinoOBJ) {
                console.log("se encontro destino", destino);
                setDestinoId(destinoOBJ.id);
                setDestinoCiudad(destinoOBJ.localidad);
                setDestinoEstado(destinoOBJ.estado);
                setDestinoEstadoShort(destinoOBJ.estado_short);
                setDestinoMunicipioSat(destinoOBJ.municipioSat || "");
                setDestinoCiudadSat(destinoOBJ.localidadSat || "");
                setDestinoMunicipio(destinoOBJ.municipio || "");
                setDestinoColonia(destinoOBJ.colonia || "");
                setDestinoColoniaSat(destinoOBJ.coloniaSat || "");
                setDestinoCP(destinoOBJ.cp || "");
                setDestinoCalle(destinoOBJ.calle || "");
                setDestinoNumero(destinoOBJ.numero || "");
            }
        }
    }, [origen, destino]);
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);


    const addFactura = () => {
        let nextfactid = facturaid;
        setFacturaID(facturaid + 1);//incrementamos

        let pend = '';
        let pendfloat = 0.0;
        let refe = '';
        let uuid = '';
        if (facturasReft && facturasReft.length > 0) {
            pendfloat = facturasReft[0].obj.pendientes;
            uuid = facturasReft[0].uuid;
            refe = facturasReft[0].id;
            let num = '$' + pendfloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            pend = num;
        }

        let factura = {
            temid: nextfactid,
            referencia: refe,
            monto: '',
            uuid,
            montoFloat: 0.0,
            pendiente: pend,
            pendienteFloat: pendfloat,
            difpendienteFloat: pendfloat,
            difpendiente: pend
        }
        setFacturas([...facturas, factura]);
    }
    const removeFactura = (element) => {
        let newArr = [...facturas];
        let foundIndex = newArr.findIndex(x => x.temid === element.temid);
        if (foundIndex >= 0) {
            newArr.splice(foundIndex, 1);
            setFacturas(newArr);
        }
    }
    const changeElmentFactura = (value, element) => {
        //
        let newArr = [...facturas];


        let foundIndex = newArr.findIndex(x => x.temid === element.temid);
        if (foundIndex >= 0) {
            newArr[foundIndex].referencia = value;
            if (facturasReft) {
                let objFactura = facturasReft.find(element => element.id == value);
                if (objFactura) {
                    newArr[foundIndex].pendienteFloat = objFactura.obj.pendientes;
                    newArr[foundIndex].monto = '';
                    newArr[foundIndex].uuid = objFactura.uuid || "";
                    newArr[foundIndex].montoFloat = 0.0;
                    let num = '$' + newArr[foundIndex].pendienteFloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                    newArr[foundIndex].pendiente = num;
                }


            }
            setFacturas(newArr);
        }


    }


    useEffect(() => {
        if (totalNota) {
            let num = '$' + parseFloat(totalNota).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            setTotalNotaString(num);

            if (porciento < 100 && porciento > 0) {
                let descuento = parseFloat(totalNota) * parseFloat(porciento) / 100.00;
                setTotalNotaDesc(descuento);
            }


        }

    }, [totalNota, porciento])
    useEffect(() => {
        if (facturas) {
            let totalaux = 0.0;
            facturas.forEach(e => {
                console.log("data factura", e);
                totalaux = parseFloat(totalaux) + parseFloat(e.pendienteFloat);
            });
            console.log("seting total", totalaux);
            setTotalNota(totalaux);
        }
    }, [facturas]);

    const handleChangeCurrencyFactura = (event, maskedvalue, floatvalue, element) => {

        let newArr = [...facturas];
        let foundIndex = newArr.findIndex(x => x.temid === element.temid);
        if (foundIndex >= 0) {

            newArr[foundIndex].monto = maskedvalue;
            newArr[foundIndex].montoFloat = floatvalue;

            if (newArr[foundIndex].pendienteFloat) {
                let dif = newArr[foundIndex].pendienteFloat - floatvalue;
                if (dif < 0) {
                    dif = 0.0;
                }
                let num = '$' + dif.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                newArr[foundIndex].difpendiente = num;
                newArr[foundIndex].difpendienteFloat = dif;
            }

            setFacturas(newArr);
        }
    }

    const refAfterEnter = useRef();
    const eventEnterOnFindCreate = (e) => {
        e.preventDefault();
        refAfterEnter.current.focus();
    }
    const classes = useStyles();

    const [isSearching, setIsSearching] = useState(false);


    const [currency, setCurrency] = useState("");
    const [currencyFloat, setCurrencyFloat] = useState(0.0);

    const procesBusqueda = async () => {
        const viajesClienteFacturados = [];
        await getViajesFacturadosByClienteByRange(clienteId, fechaRangoStart, fechaRangoEnd).then(snapshot => {

            //console.log("viajes encontrads del cliente",snapshot.docs.length);
            snapshot.forEach(doc => {
                let pendientes = 0.0;
                let o = { ...doc.data() };
                o.id = doc.id;
                if (o.facuturado) {

                    o.key = doc.id;
                    o.factura = o.general.nviaje;
                    o.carta_porte = o.general.ncarta;
                    o.uuid = o.facuturado.uuid || "";
                    o.cliente = o.general.cliente;
                    o.total = o.detalles.tarifaFloat;
                    o.fecha = o.fecha_facturado || null;
                    if (o.pagos) {
                        //calculamos pendientes
                        let pagado = 0.0;
                        o.pagos.forEach(element => {
                            pagado += element.montoFloat;
                        });

                        if (o.factura_monto >= pagado) {
                            pendientes = o.factura_monto - pagado;
                        } else {
                            pendientes = 0.0;
                        }

                    } else {
                        pendientes = o.factura_monto;
                    }
                    o.pendientes = pendientes;
                    o.obj = { ...o };

                    o.facturaname = `${o.prefijoFactura}-${o.folioFactura} ${o.carta_porte}`;
                    if (o.status !== "Pagado")
                        viajesClienteFacturados.push(o);

                }


            });

            let fixed = [];
            viajesClienteFacturados.forEach(async (obj) => {
                if (obj.facuturado) {
                    let xmlstring = obj.facuturado.cfdixml;
                    //let fixedXml=xmlstring.replace('cfdi:', '');
                    let fixedXml = xmlstring.split('cfdi:').join('');
                    fixedXml = xmlstring.split('$').join('obj');

                    let response = null;
                    await xml2js.parseString(fixedXml, async (err, result) => {
                        if (err) {
                            console.log(err);
                        }
                        else {
                            // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                            //let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                            let moneda = result['cfdi:Comprobante']['$']['Moneda'];
                            if (moneda === "USD") {
                                if (!currencyMX) {
                                    fixed.push(obj);
                                }
                            } else {
                                if (currencyMX) {
                                    fixed.push(obj);
                                }
                            }

                            console.log("primer xml", moneda);
                            //response = objdataxml;
                            // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                        }

                    });
                }
            })
            setFacturasReft(fixed);
            setIsSearching(false);
            console.log("viajes facturados", fixed.length);

        }).catch(err => console.log(err));
    }
    useEffect(() => {
        if (isSearching) {
            procesBusqueda();
        }
    }, [isSearching])

    const buscarFacturas = async () => {
        if (clienteId) {
            if (!isSearching) {
                setIsSearching(true);
            }
        } else {
            alert("selecciona un cliente");
        }
    }
    const handleChangeCurrency = (event, maskedvalue, floatvalue) => {
        setCurrency(maskedvalue);
        setCurrencyFloat(floatvalue);
    }

    const changeDescriptionItem = (index, value) => {
        let newArr = [...items];
        if (items[index]) {
            newArr[index].descripcion = value;
            setItems(newArr);
        }

    }

    const changeCurrencyItem = (index, value, valueFloat) => {
        let newArr = [...items];
        if (items[index]) {
            newArr[index].currency = value;
            newArr[index].currencyFloat = valueFloat;
            setItems(newArr);
        }

    }


    const changeUnitPriceItem = (index, value, valueFloat) => {
        let newArr = [...items];
        if (items[index]) {
            newArr[index].unitprice = value;
            newArr[index].unitpriceFloat = valueFloat;

            newArr[index].currencyFloat = parseFloat(newArr[index].quanitty) * parseFloat(valueFloat);
            newArr[index].currency = '$' + parseFloat(newArr[index].currencyFloat).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");;
            setItems(newArr);
        }

    }

    const changeQuantityItem = (index, value) => {
        let newArr = [...items];
        if (items[index]) {
            newArr[index].quanitty = value;


            newArr[index].currencyFloat = parseFloat(value) * parseFloat(newArr[index].currencyFloat);
            newArr[index].currency = '$' + parseFloat(newArr[index].currencyFloat).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");;

            setItems(newArr);
        }

    }




    const removeItem = (index) => {
        let newArr = [...items];

        if (index >= 0) {
            newArr.splice(index, 1);
            setItems(newArr);
        }
    }

    const addItem = () => {


        setItems([...items, { descripcion: "", currency: '', currencyFloat: 0.0 }])
    }

    useEffect(() => {
        let sub = 0;
        items.forEach(i => {
            sub = parseFloat(sub) + parseFloat(i.currencyFloat);

        })
        let tax = parseFloat(taxRate);
        setSubtotalFloat(sub.toFixed(2));
        let calculo = parseFloat(sub * tax / 100);
        let submastax = sub + calculo;
        setTotalFloat(submastax.toFixed(2));
    }, [items, taxRate])


    useEffect(() => {
        let num = '$' + parseFloat(subtotalFloat).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        setSubtotal(num);
    }, [subtotalFloat]);

    useEffect(() => {
        let num = '$' + parseFloat(totalFloat).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        setTotal(num);
    }, [totalFloat])

    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isSearching}
            spinner
            text='Procesando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "USA Invoice" : `USA Invoice `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP"> Configuration</p></div>

                            <div class="dataBox">

                                <div class="dataRow">
                                    <p className="fixedP">Bill to<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <Autocomplete
                                            id="combo-box-demo0c"
                                            className={"selectbusqueda"}
                                            options={clientes}
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) => option.name === value.name}
                                            style={{ height: '30px' }}
                                            onChange={e => setCliente(e.target.innerText)}
                                            renderInput={(params) => <TextField className="form-control" onChange={e => setCliente(e.target.value)}  {...params} variant="outlined" />}
                                        />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Invoice #<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <input type="number" style={{ width: "90%" }} value={invoiceNumber} onChange={e => setinvoiceNumber(e.target.value)} required />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Date<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <input type="date" className={"selectbusqueda"} value={date} onChange={e => setDate(e.target.value)} required />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Reference</p>
                                    <div class="fieldInput">
                                        <input type="text" placeholder='Ex. PO 12345' value={referencia} onChange={e => setReferencia(e.target.value)} className='form-control' required />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Load</p>
                                    <div class="fieldInput">
                                        <select value={isViajeCargado} onChange={(e) => setIsViajeCargado(e.target.value === "true" ? true : false)}>
                                            <option value={false}>Regular Invoice</option>
                                            <option value={true}>Load</option>
                                        </select>
                                    </div>
                                </div>
                                {isViajeCargado && <div class="dataRow">
                                    <p className="fixedP">Shipper</p>
                                    <div class="fieldInput">
                                        <Autocomplete
                                            id="combo-box-demo0"
                                            className='selectbusqueda'
                                            options={localidades}
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) => option.name === value.name}
                                            onChange={(e, val) => setOrigen(val.name)}
                                            renderInput={(params) => <TextField className="reducedselect" onChange={e => setOrigen(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                        />
                                    </div>
                                </div>}
                                {isViajeCargado && <div class="dataRow">
                                    <p className="fixedP">Consignee</p>
                                    <div class="fieldInput">
                                        <Autocomplete
                                            id="combo-box-demo0"
                                            className='selectbusqueda'
                                            options={localidades}
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) => option.name === value.name}
                                            style={{ padding: 0 }}
                                            onChange={(e, val) => setDestino(val.name)}
                                            renderInput={(params) => <TextField className="reducedselect" onChange={e => setDestino(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                        />
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP"> Totals</p></div>

                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Subtotal</p>
                                    <div class="fieldInput">
                                        <input type='text' value={subtotal} disabled ></input>
                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">TAX RATE %</p>
                                    <div class="fieldInput">
                                        <input type='number' step={0.01} min={0} max={100} value={taxRate} onChange={e => setTaxRate(e.target.value)} style={{ width: "90%" }}></input>
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Total</p>
                                    <div class="fieldInput">
                                        <input type='text' value={total} disabled ></input>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>



                    <div class="dataTable oneColmargin">
                        <div class="dataTitle"><p className="fixedP">Items</p>
                            <button type='button' class="boton plus-button" role='button' onClick={e => addItem()}></button>

                        </div>

                        <div class="dataBox">
                            <div className='col-12'>
                            {
                                items.map((item, index) => {
                                    return <div className='row'>
                                        <div className="form-group col-4">
                                            {index === 0 && <label>Description</label>}
                                            <input ref={refAfterEnter} type="text" name='descripcion' className="form-control" value={item.descripcion} onChange={e => changeDescriptionItem(index, e.target.value)} placeholder="Description" />
                                        </div>
                                        <div className="form-group col-2">
                                            {index === 0 && <label>Quantity</label>}
                                            <input type='number' min={1} step={1} className='form-control' value={item.quanitty} onChange={(e) => changeQuantityItem(index, e.target.value)} required />
                                        </div>
                                        <div className="form-group col-2">
                                            {index === 0 && <label>Unit Price</label>}
                                            <CurrencyInput className="form-control" value={item.unitprice} onChangeEvent={(e, mask, fval) => changeUnitPriceItem(index, mask, fval)} prefix="$" required />
                                        </div>
                                        <div className="form-group col-2">
                                            {index === 0 && <label>Amount (USD)</label>}
                                            <CurrencyInput className="form-control" disabled value={item.currency} onChangeEvent={(e, mask, fval) => changeCurrencyItem(index, mask, fval)} prefix="$" required />
                                        </div>

                                        <div className="form-group col-1">
                                            {index === 0 && <label>Remove</label>}
                                            <button type='button' class="minus-button" onClick={() => { removeItem(index) }} />

                                        </div>
                                    </div>
                                })
                            }
                            </div>
                        </div>
                    </div>





                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Cancelar</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalCreateEdit;