import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DatePicker from 'react-datepicker';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { tempRegistrarUbicacion, getCatalogoLocalidesGenerales, actualizaStatusViaje, addViaje, getViajeById, updateViaje, getViajeRepo, initViajeRepor, nextFolioCP, nextFolio, getUbicaciones, getClientes, getDistancia, registraNuevaDistancia, actualizaDistnacia, getActivos, getCargaById, confirmarCarga } from '../../../../controllers/viajes';
import { getOperadores } from '../../../../controllers/operadores';
import { getTarifarioByViajeData } from '../../../../controllers/tarifario';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import CurrencyInput from 'react-currency-input';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { firebase } from '../../../../configs/firebase';
import Swal from 'sweetalert2';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import AddLocationRounded from "@material-ui/icons/AddLocationRounded";
import DescriptionIcon from '@material-ui/icons/Description';
import ClearIcon from '@material-ui/icons/Clear';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SearchIcon from '@material-ui/icons/Search';
import ModalCreateActivo from '../../../activos/ModalCreateEdit';
import ModalCreateCliente from '../../../clientes/ModalCreateEdit';
import ModalCreateUbicacion from '../../../ubicaciones/ModalCreateEdit';
import ModalMercancias from '../../../mercancias/ModalCreateEdit';
import ModalFraccionesArancelarias from '../../../mercancias/ModalFraccionesArancelarias';
import { getUnidadByID, setViajeAsignadoUnidad, asignarPrePlan } from '../../../../controllers/unidades';
import { getCombustiblesPorViaje, addCombustible, updateCombustible } from '../../../../controllers/combustible';
import { getTabuladorOperadoresByRuta } from '../../../../controllers/tabuladoroperadores';
import { addCosto } from '../../../../controllers/costos';
import Combustible from '../../../combustible/content';
import { v4 as uuidv4 } from 'uuid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Rentabilidad from '../../rentabilidad';
import { getUserByUid, updateUserOperadoresTime, updateUserClientesTime, updateUserUbicacionesTime, updateViajesTime } from '../../../../controllers/usuarios';
import { getOperadorById } from '../../../../controllers/operadores';
import { despacharViaje } from '../../../../controllers/viajes';
import InputMask from 'react-input-mask';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Horarios from '../../horarios';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { getUidMaster } from '../../../../session/session_controller';
import { useIndexedDB } from 'react-indexed-db';
import ModalCartaPorteConfirm from '../../../cartaporte/ModalCartaPorteConfirm';
import ModalFacturar from '../../../facturas/ModalFacturar';
import axios from 'axios';
import { getConsultTime, getBussyTime, setConsultTime, setBussyTime } from '../../../../controllers/viajes';
import xml2js from 'xml2js';
import readXlsxFile from 'read-excel-file';
import ModalParadas from '../../modalParadas';
import queryString from 'query-string';

function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'} variant={'body2'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const getSteps = () => {
    return ['Planeado', 'Despachado', 'Entregado', 'Facturado', 'Pagado'];
}
const getStepContent = (step) => {
    switch (step) {
        case 0:
            return 'Planeado';
        case 1:
            return 'Despachado';
        case 2:
            return 'Entregado';
        case 3:
            return 'Facturado';
        case 4:
            return 'Pagado';
        default:
            return 'Unknown step';
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
    },
    paperrootfull: {
        width: '100%',
        margin: '5px',
        padding: '30px',
        display: 'flex'
    },

    paperroot: {
        width: '33%',
        display: 'flex'
    },
    paper: {
        minHeight: 80,
        height: 'auto',
        width: '100%',
    },
    paperscontainer: {
        // width: '100%'
        //overflow: 'hidden'
        flex: 1
    },
    title: {
        height: 30,
        backgroundColor: '#dcdcdc',
        paddingLeft: 10,
        paddingTop: 5,
    },
    label: {
        fontSize: '12px',
        paddingLeft: 3,
        paddingTop: 7,
        width: '38%',
    },

    input: {
        display: 'inline-block',
        float: 'right',
        width: '60%',
        height: 30,
        marginTop: 4,
        marginRight: 2
    },
    inputbig: {
        width: '100%',
        height: 30,
        marginTop: 4,
        marginRight: 2
    },
    inputsmall: {
        display: 'inline-block',
        float: 'right',
        width: '50%',
        height: 30,
        marginTop: 4,
        marginRight: 2
    },
    inputwithextra: {
        display: 'inline-block',
        float: 'right',
        width: '30%',
        height: 30,
        marginRight: 2
    },
    inputdate: {
        width: '99%',
        marginTop: 4,
        marginLeft: 0,
        marginRight: 2,
        height: '30px',
    }
}));


const CreateViajeContent = (props) => {

    const locations = useLocation();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();
    //let { id } = useParams();
    const CATALOGOS_CLASES_VIAJE = ['Seco - regular', 'Material peligroso', 'Hazmat', 'Refrigerado', 'Exceso de dimensiones - OD', 'Pipa - Líquidos/Gas', 'Plataforma', 'Intermodal', 'Marítimo-puertos'];
    const CATALOGOS_TIPOS = ['Local (-100 kms)', 'Foráneo (+100 kms)', 'Cruce Exportación', 'Cruce Importación', 'Colocación', 'Recolección', 'Exportación', 'Importación', 'Intermodal'];
    const CATALOGOS_RECOLECCION = ['Pre-cargado', 'En vivo', 'Swap (Quita-pon)'];
    const CATALOGO_TIPOS_LC = ['COSTO VARIABLE', 'COSTO_FIJO', 'INGRESO', 'COMBUSTIBLE', 'SUELDOS DE OPERADOR'];
    const CATALOGO_STATUS = ['Planeado', 'Despachado', 'Entregado', 'Facturado', 'Pagado'];
    const CATALOGO_CRUCES = [' San Ysidro, California ---  Tijuana, Baja California',
        ' Laredo, Texas --- Nuevo Laredo, Tamaulipas',
        ' Laredo, Texas --- Colombia, Nuevo León',
        ' Otay Mesa, California ---  Tijuana, Baja California',
        ' Tecate, California ---  Tecate, Baja California',
        ' Calexico, California ---  Mexicali, Baja California',
        ' Calexico East, California ---  Mexicali (Garita Este), Baja California',
        ' Andrade, California ---  Los Algodones, Baja California',
        ' San Luis, Arizona ---  San Luis Río Colorado, Sonora',
        ' San Luis, Arizona ---  San Luis Río Colorado (Garita II), Sonora',
        ' Lukeville, Arizona ---  Sonoita, Sonora',
        ' Sasabe, Arizona ---  Sásabe, Sonora',
        ' Nogales (Oeste), Arizona ---  Nogales (Oeste), Sonora',
        ' Nogales (Centro), Arizona ---  Nogales (Centro), Sonora',
        ' Naco, Arizona ---  Naco, Sonora',
        ' Douglas, Arizona ---  Agua Prieta, Sonora',
        ' Antelope Wells, Nuevo México ---  El Berrendo, Chihuahua',
        ' Columbus, Nuevo México ---  Puerto Palomas, Chihuahua',
        ' Santa Teresa, Nuevo México ---  San Jerónimo, Chihuahua',
        ' El Paso, Texas ---  Ciudad Juárez Garita I, Chihuahua',
        ' El Paso, Texas ---  Ciudad Juárez Garita II, Chihuahua',
        ' El Paso, Texas ---  Ciudad Juárez Garita III, Chihuahua',
        ' Fort Hancock, Texas ---  El Porvenir (Chihuahua), Chihuahua',
        ' Presidio, Texas ---  Ojinaga, Chihuahua',
        ' Eagle Pass, Texas ---  Piedras Negras, Coahuila',
    ];
    const CAT_UNIDADES = [
        {
            "codigo": "ACT",
            "descripcion": "Actividad"
        },
        {
            "codigo": "KT",
            "descripcion": "Bolsa"
        },
        {
            "codigo": "HEA",
            "descripcion": "CABEZA"
        },
        {
            "codigo": "BB",
            "descripcion": "CAJA"
        },
        {
            "codigo": "DAY",
            "descripcion": "DIA"
        },
        {
            "codigo": "DPC",
            "descripcion": "DOCENA"
        },
        {
            "codigo": "GLL",
            "descripcion": "GALON"
        },
        {
            "codigo": "GRM",
            "descripcion": "GRS"
        },
        {
            "codigo": "HUR",
            "descripcion": "HORA"
        },
        {
            "codigo": "KT",
            "descripcion": "JUEGO"
        },
        {
            "codigo": "KGM",
            "descripcion": "KG"
        },
        {
            "codigo": "LTR",
            "descripcion": "LITRO"
        },
        {
            "codigo": "LO",
            "descripcion": "LOTE"
        },
        {
            "codigo": "MTK",
            "descripcion": "M2"
        },
        {
            "codigo": "MTQ",
            "descripcion": "M3"
        },
        {
            "codigo": "MTR",
            "descripcion": "METRO"
        },
        {
            "codigo": "A9",
            "descripcion": "No Aplica"
        },
        {
            "codigo": "PR",
            "descripcion": "PAR"
        },
        {
            "codigo": "H87",
            "descripcion": "PIEZA"
        },
        {
            "codigo": "C9",
            "descripcion": "ROLLO"
        },
        {
            "codigo": "E48",
            "descripcion": "SERVICIO"
        },
        {
            "codigo": "TNE",
            "descripcion": "TONS"
        },
        {
            "codigo": "LN",
            "descripcion": "TRAMO"
        },
        {
            "codigo": "C62",
            "descripcion": "Unidad"
        }
    ]

    const CAT_PELIGROSOS = [{ clave: `0004`, descripcion: `PICRATO DE AMONIO seco o humedecido con menos de 10%, en masa, de agua` },
    { clave: `0005`, descripcion: `CARTUCHOS PARA ARMAS, con carga explosiva` },
    { clave: `0006`, descripcion: `CARTUCHOS PARA ARMAS, con carga explosiva` },
    { clave: `0007`, descripcion: `CARTUCHOS PARA ARMAS, con carga explosiva` },
    { clave: `0009`, descripcion: `MUNICIONES INCENDIARIAS con o sin carga detonante, carga expulsora o carga propulsora` },
    { clave: `0010`, descripcion: `MUNICIONES INCENDIARIAS con o sin carga detonante carga expulsora o carga propulsora` },
    { clave: `0012`, descripcion: `CARTUCHOS PARA ARMAS, CON PROYECTIL INERTE, o CARTUCHOS PARA ARMAS DE PEQUEÑO CALIBRE` },
    { clave: `0014`, descripcion: `CARTUCHOS PARA ARMAS, SIN BALA, o CARTUCHOS PARA ARMAS DE PEQUEÑO CALIBRE, SIN BALA` },
    { clave: `0015`, descripcion: `MUNICIONES FUMIGENAS con o sin carga detonante carga expulsora o carga propulsora` },
    { clave: `0016`, descripcion: `MUNICIONES FUMIGENAS con o sin carga detonante, carga expulsora o carga propulsora` },
    { clave: `0018`, descripcion: `MUNICIONES LACRIMOGENAS con carga detonante, carga expulsora o carga propulsora` },
    { clave: `0019`, descripcion: `MUNICIONES LACRIMOGENAS con carga detonante, carga expulsora o carga propulsora` },
    { clave: `0020`, descripcion: `MUNICIONES TOXICAS con carga detonante, carga expulsora o carga propulsora` },
    { clave: `0021`, descripcion: `MUNICIONES TOXICAS con carga detonante, carga expulsora o carga propulsora` },
    { clave: `0027`, descripcion: `POLVORA NEGRA en forma de granos o de polvo` },
    { clave: `0028`, descripcion: `POLVORA NEGRA COMPRIMIDA o POLVORA NEGRA EN COMPRIMIDOS` },
    { clave: `0029`, descripcion: `DETONADORES NO ELECTRICOS para voladuras` },
    { clave: `0030`, descripcion: `DETONADORES ELECTRICOS para voladuras` },
    { clave: `0033`, descripcion: `BOMBAS con carga explosiva` },
    { clave: `0034`, descripcion: `BOMBAS con carga explosiva` },
    { clave: `0035`, descripcion: `BOMBAS con carga explosiva` },
    { clave: `0037`, descripcion: `BOMBAS DE ILUMINACION PARA FOTOGRAFIA` },
    { clave: `0038`, descripcion: `BOMBAS DE ILUMINACION PARA FOTOGRAFIA` },
    { clave: `0039`, descripcion: `BOMBAS DE ILUMINACION PARA FOTOGRAFIA` },
    { clave: `0042`, descripcion: `PETARDOS sin detonador` },
    { clave: `0043`, descripcion: `DETONADORES CON EXPLOSIVO` },
    { clave: `0044`, descripcion: `CEBOS TIPO CAPSULA` },
    { clave: `0048`, descripcion: `CARGAS DE DEMOLICION` },
    { clave: `0049`, descripcion: `CARTUCHOS FULGURANTES` },
    { clave: `0050`, descripcion: `CARTUCHOS FULGURANTES` },
    { clave: `0054`, descripcion: `CARTUCHOS DE SEÑALES` },
    { clave: `0055`, descripcion: `CARTUCHOS VACIOS CEBADOS(CON FULMINANTE)` },
    { clave: `0056`, descripcion: `CARGAS DE PROFUNDIDAD` },
    { clave: `0059`, descripcion: `CARGAS HUECAS sin detonador` },
    { clave: `0060`, descripcion: `CARGAS EXPLOSIVAS PARA PETARDOS MULTIPLICADORES ` },
    { clave: `0065`, descripcion: `MECHA DETONANTE flexible` },
    { clave: `0066`, descripcion: `MECHA DE ENCENDIDO` },
    { clave: `0070`, descripcion: `CIZALLAS CORTACABLES CON CARGA EXPLOSIVA` },
    { clave: `0072`, descripcion: `CICLOTRIMETILENTRINITRAMINA (CICLONITA; RDX; HEXOGENO) HUMEDECIDA con no menos de 15%, en masa, de agua` },
    { clave: `0073`, descripcion: `DETONADORES PARA MUNICIONES` },
    { clave: `0074`, descripcion: `DIAZODINITROFENOL HUMEDECIDO con no menos de 40%, en masa, de agua o de una mezcla de alcohol y agua` },
    { clave: `0075`, descripcion: `DINITRATO DE DIETILENGLICOL DESENSIBILIZADO con no menos de 25%, en masa, inhibidor no volátil insoluble en agua` },
    { clave: `0076`, descripcion: `DINITROFENOL seco o humedecido con menos de 15%, en masa, de agua` },
    { clave: `0077`, descripcion: `DINITROFENOLATOS de metales alcalinos, secos o humedecidos con menos de 15%, en masa, de agua` },
    { clave: `0078`, descripcion: `DINITRORRESORCINOL seco o humedecido con menos de 15%, en masa, de agua` },
    { clave: `0079`, descripcion: `HEXANITRODIFENILAMINA (DIPICRILAMINA; HEXILO)` },
    { clave: `0081`, descripcion: `EXPLOSIVOS PARA VOLADURAS, TIPO A` },
    { clave: `0082`, descripcion: `EXPLOSIVOS PARA VOLADURAS, TIPO B` },
    { clave: `0083`, descripcion: `EXPLOSIVOS PARA VOLADURAS, TIPO C` },
    { clave: `0084`, descripcion: `EXPLOSIVOS PARA VOLADURAS, TIPO D` },
    { clave: `0092`, descripcion: `BENGALAS DE SUPERFICIE` },
    { clave: `0093`, descripcion: `BENGALAS AEREAS` },
    { clave: `0094`, descripcion: `POLVORA DE DESTELLOS` },
    { clave: `0099`, descripcion: `CARTUCHOS DE AGRIETAMIENTO, EXPLOSIVOS, sin detonador, para pozos de petróleo` },
    { clave: `0101`, descripcion: `MECHA NO DETONANTE` },
    { clave: `0102`, descripcion: `MECHA DETONANTE con recubrimiento metálico` },
    { clave: `0103`, descripcion: `MECHA DE IGNICION o INFLAMACION, tubular, con recubrimiento metálico` },
    { clave: `0104`, descripcion: `MECHA DETONANTE DE EFECTO REDUCIDO, con recubrimiento metálico` },
    { clave: `0105`, descripcion: `MECHA DE SEGURIDAD  (MECHA LENTA O MECHA BICKFORD)` },
    { clave: `0106`, descripcion: `ESPOLETA DETONANTE` },
    { clave: `0107`, descripcion: `ESPOLETA DETONANTE` },
    { clave: `0110`, descripcion: `GRANADAS, PARA PRACTICAS de mano o de fusil` },
    { clave: `0113`, descripcion: `GUANILNITROSAMINO-GUANILIDENHIDRACINA HUMEDECIDA con no menos de 30%, en masa, de agua` },
    { clave: `0114`, descripcion: `GUANILNITROSAMINOGUANILTETRACENO (TETRACENO) HUMEDECIDO con no menos de 30%, en masa, de agua o de una mezcla de alcohol y agua` },
    { clave: `0118`, descripcion: `HEXOLITA (HEXOTOL) seca o humedecida con menos de 15%, en masa, de agua` },
    { clave: `0121`, descripcion: `INICIADORES` },
    { clave: `0124`, descripcion: `DISPOSITIVOS PORTADORES DE CARGAS HUECAS, CARGADOS, para perforación de pozos de petróleo, sin detonador ` },
    { clave: `0129`, descripcion: `AZIDA DE PLOMO HUMEDECIDA con no menos de 20%, en masa, de agua o de una mezcla de alcohol y agua` },
    { clave: `0130`, descripcion: `ESTIFNATO DE PLOMO (TRINITRORRESORCINATO DE PLOMO) HUMEDECIDO con no menos de 20%, en masa, de agua o de una mezcla de alcohol y agua` },
    { clave: `0131`, descripcion: `ENCENDEDORES PARA MECHA DE SEGURIDAD` },
    { clave: `0132`, descripcion: `SALES METALICAS DEFLAGRANTES DE NITRO-DERIVADOS AROMATICOS, N.E.P` },
    { clave: `0133`, descripcion: `HEXANITRATO DE MANITOL (NITROMANITA) HUMEDECIDO con no menos de 40%, en masa, de agua o de una mezcla de alcohol y agua` },
    { clave: `0135`, descripcion: `FULMINATO DE MERCURIO HUMEDECIDO con no menos de 20%, en masa, de agua o de una mezcla de alcohol y agua` },
    { clave: `0136`, descripcion: `MINAS con carga explosiva` },
    { clave: `0137`, descripcion: `MINAS con carga explosiva` },
    { clave: `0138`, descripcion: `MINAS con carga explosiva` },
    { clave: `0143`, descripcion: `NITROGLICERINA DESENSIBILIZADA con un mínimo del 40%, en masa, de inhibidor no volátil insoluble en agua` },
    { clave: `0144`, descripcion: `NITROGLICERINA EN SOLUCION ALCOHOLICA con más de 1% pero no más de 10% de nitroglicerina` },
    { clave: `0146`, descripcion: `NITROALMIDON seco o humedecido con menos de 20%, en masa, de agua` },
    { clave: `0147`, descripcion: `NITROUREA` },
    { clave: `0150`, descripcion: `TETRANITRATO DE PENTAERITRITA (TETRANITRATO DE PENTAERITRITOL; PENTRITA TNPE) HUMEDECIDO con no menos de 25%, en masa, de agua, o TETRANITRATO DE PENTAERITRITO (TETRANITRATO DE PENTAERITRITOL; TNPE) DESENSIBILIZADO con no menos de 15%, en masa, de flemador` },
    { clave: `0151`, descripcion: `PENTOLITA seca o humedecida con menos de 15%, en masa, de agua` },
    { clave: `0153`, descripcion: `TRINITROANILINA (PICRAMIDA)` },
    { clave: `0154`, descripcion: `TRINITROFENOL (ACIDO PICRICO) seco o humedecido con menos de 30%, en masa, de agua` },
    { clave: `0155`, descripcion: `TRINITROCLOROBENCENO (CLORURO DE PICRILO)` },
    { clave: `0159`, descripcion: `PASTA DE POLVORA HUMEDECIDA con no menos de 25%, en masa, de agua` },
    { clave: `0160`, descripcion: `POLVORA SIN HUMO` },
    { clave: `0161`, descripcion: `POLVORA SIN HUMO` },
    { clave: `0167`, descripcion: `PROYECTILES con carga explosiva` },
    { clave: `0168`, descripcion: `PROYECTILES con carga explosiva` },
    { clave: `0169`, descripcion: `PROYECTILES con carga explosiva` },
    { clave: `0171`, descripcion: `MUNICIONES DE ILUMINACION con o sin carga detonante, carga expulsora o carga propulsora` },
    { clave: `0173`, descripcion: `DISPOSITIVOS EXPLOSIVOS DE LIBERACION` },
    { clave: `0174`, descripcion: `REMACHES EXPLOSIVOS` },
    { clave: `0180`, descripcion: `COHETES con carga explosiva` },
    { clave: `0181`, descripcion: `COHETES con carga explosiva` },
    { clave: `0182`, descripcion: `COHETES con carga explosiva` },
    { clave: `0183`, descripcion: `COHETES con cabeza inerte` },
    { clave: `0186`, descripcion: `MOTORES DE COHETE` },
    { clave: `0190`, descripcion: `MUESTRAS DE EXPLOSIVOS, excepto los explosivos iniciadores` },
    { clave: `0191`, descripcion: `ARTIFICIOS MANUALES DE PIROTECNIA PARA SEÑALES` },
    { clave: `0192`, descripcion: `PETARDOS DE SEÑALES EXPLOSIVAS PARA FERROCARRIL ` },
    { clave: `0193`, descripcion: `PETARDOS DE SEÑALES EXPLOSIVAS PARA FERROCARRIL` },
    { clave: `0194`, descripcion: `SEÑALES DE SOCORRO para barcos` },
    { clave: `0195`, descripcion: `SEÑALES DE SOCORRO para barcos` },
    { clave: `0196`, descripcion: `SEÑALES FUMIGENAS` },
    { clave: `0197`, descripcion: `SEÑALES FUMIGENAS` },
    { clave: `0204`, descripcion: `CARGAS EXPLOSIVAS PARA SONDEOS` },
    { clave: `0207`, descripcion: `TETRANITROANILINA` },
    { clave: `0208`, descripcion: `TRINITROFENILMETILNITRAMINA (TETRILO)` },
    { clave: `0209`, descripcion: `TRINITROTOLUENO (TNT) seco o humedecido con menos de 30%, en masa, de agua` },
    { clave: `0212`, descripcion: `TRAZADORES PARA MUNICIONES` },
    { clave: `0213`, descripcion: `TRINITROANISOL` },
    { clave: `0214`, descripcion: `TRINITROBENCENO seco o humedecido con menos de 30%, en masa, de agua` },
    { clave: `0215`, descripcion: `ACIDO TRINITROBENZOICO seco o humedecido con menos de 30%, en masa, de agua` },
    { clave: `0216`, descripcion: `TRINITRO-m-CRESOL` },
    { clave: `0217`, descripcion: `TRINITRONAFTALENO` },
    { clave: `0218`, descripcion: `TRINITROFENETOL` },
    { clave: `0219`, descripcion: `TRINITRORRESORCINOL  (TRINITRORRESORCINA, ACIDO ESTIFNICO) seco o humedecido con menos de 20%, en masa, de agua o de una mezcla de alcohol y agua` },
    { clave: `0220`, descripcion: `NITRATO DE UREA seco o humedecido con menos de 20%, en masa, de agua` },
    { clave: `0221`, descripcion: `CABEZAS DE COMBATE PARA TORPEDOS, con carga explosiva` },
    { clave: `0222`, descripcion: `NITRATO DE AMONIO con más de 0,2% de materias combustibles, incluyendo cualquier substancia orgánica calculada como carbono, con la exclusión de cualquier otra substancia añadida` },
    { clave: `0224`, descripcion: `AZIDA DE BARIO seca o humedecida con menos de 50%, en masa, de agua` },
    { clave: `0225`, descripcion: `PETARDOS MULTIPLICADORES CON DETONADOR` },
    { clave: `0226`, descripcion: `CICLOTETRAMETILENTETRANITRA-MINA (OCTOGENO; HMX) HUMEDECIDA con no menos de 15%, en masa, de agua` },
    { clave: `0234`, descripcion: `DINITRO-o-CRESOLATO SODICO seco o humedecido con menos de 15%, en masa, de agua` },
    { clave: `0235`, descripcion: `PICRAMATO DE SODIO seco o humedecido con menos de 20%, en masa, de agua` },
    { clave: `0236`, descripcion: `PICRAMATO DE CIRCONIO seco o humedecido con menos de 20%, en masa, de agua` },
    { clave: `0237`, descripcion: `CARGAS PERFILADAS DE DISEÑO FLEXIBLE ALINEADAS (MECHA DETONANTE PERFILADA FLEXIBLE) ` },
    { clave: `0238`, descripcion: `COHETES LANZACABOS` },
    { clave: `0240`, descripcion: `COHETES LANZACABOS` },
    { clave: `0241`, descripcion: `EXPLOSIVOS PARA VOLADURAS, TIPO E` },
    { clave: `0242`, descripcion: `CARGAS PROPULSORAS DE ARTILLERIA` },
    { clave: `0243`, descripcion: `MUNICIONES INCENDIARIAS DE FOSFORO BLANCO, con carga detonante, carga expulsora o carga propulsora` },
    { clave: `0244`, descripcion: `MUNICIONES INCENDIARIAS DE FOSFORO BLANCO, con carga detonante, carga expulsora o carga propulsora` },
    { clave: `0245`, descripcion: `MUNICIONES FUMIGENAS CON FOSFORO BLANCO, con carga dispersora, carga expulsora o carga propulsora` },
    { clave: `0246`, descripcion: `MUNICIONES FUMIGENAS CON FOSFORO BLANCO, con carga detonante, carga expulsora o carga propulsora` },
    { clave: `0247`, descripcion: `MUNICIONES INCENDIARIAS en forma de líquido o de gel, con carga detonante, carga expulsora o carga propulsora` },
    { clave: `0248`, descripcion: `DISPOSITIVOS ACTIVADOS POR AGUA, con carga detonante carga expulsora o carga propulsora` },
    { clave: `0249`, descripcion: `DISPOSITIVOS ACTIVADOS POR AGUA, con carga detonante carga expulsora o carga propulsora` },
    { clave: `0250`, descripcion: `MOTORES DE COHETE CON LIQUIDOS HIPERGOLICOS, con o sin carga expulsora` },
    { clave: `0254`, descripcion: `MUNICIONES DE LUMINACIÓN con o sin carga detonante, carga expulsora o carga propulsora` },
    { clave: `0255`, descripcion: `DETONADORES ELECTRICOS para voladuras` },
    { clave: `0257`, descripcion: `ESPOLETAS DETONANTES` },
    { clave: `0266`, descripcion: `OCTOLITA (OCTOL) seca o humidificada con menos de 15%, en masa, de agua` },
    { clave: `0267`, descripcion: `DETONADORES NO ELECTRICOS para voladuras` },
    { clave: `0268`, descripcion: `PETARDOS MULTIPLICADORES CON DETONADOR` },
    { clave: `0271`, descripcion: `CARGAS PROPULSORAS` },
    { clave: `0272`, descripcion: `CARGAS PROPULSORAS` },
    { clave: `0275`, descripcion: `CARTUCHOS DE ACCIONAMIENTO` },
    { clave: `0276`, descripcion: `CARTUCHOS DE ACCIONAMIENTO` },
    { clave: `0277`, descripcion: `CARTUCHOS PARA PERFORACIÓN DE  POZOS PETROLÍFEROS` },
    { clave: `0278`, descripcion: `CARTUCHOS PARA PERFORACIÓN DE  POZOS PETROLÍFEROS` },
    { clave: `0279`, descripcion: `CARGAS PROPULSORAS DE ARTILLERIA` },
    { clave: `0280`, descripcion: `MOTORES DE COHETE` },
    { clave: `0281`, descripcion: `MOTORES DE COHETE` },
    { clave: `0282`, descripcion: `NITROGUANIDINA (PICRITA) seca o humedecida con menos de 20%, en masa, de agua` },
    { clave: `0283`, descripcion: `PETARDOS MULTIPLICADORES (CARTUCHOS) sin detonador` },
    { clave: `0284`, descripcion: `GRANADAS de mano o de fusil, con carga explosiva` },
    { clave: `0285`, descripcion: `GRANADAS de mano o de fusil, con carga explosiva` },
    { clave: `0286`, descripcion: `CABEZAS DE COMBATE PARA COHETES, con carga explosiva` },
    { clave: `0287`, descripcion: `CABEZAS DE COMBATE PARA COHETES, con carga explosiva` },
    { clave: `0288`, descripcion: `MECHA DETONANTE PERFILADA  FLEXIBLE` },
    { clave: `0289`, descripcion: `MECHA DETONANTE flexible` },
    { clave: `0290`, descripcion: `CORDON (MECHA) DETONANTE con recubrimiento metálico` },
    { clave: `0291`, descripcion: `BOMBAS con carga explosiva` },
    { clave: `0292`, descripcion: `GRANADAS de mano o de fusil, con carga explosiva` },
    { clave: `0293`, descripcion: `GRANADAS de mano o de fusil, con carga explosiva` },
    { clave: `0294`, descripcion: `MINAS con carga explosiva` },
    { clave: `0295`, descripcion: `COHETES con carga explosiva` },
    { clave: `0296`, descripcion: `CARGAS EXPLOSIVAS PARA SONDEOS ` },
    { clave: `0297`, descripcion: `MUNICIONES DE LUMINACIÓN con o sin carga detonante, carga expulsora o carga propulsora` },
    { clave: `0299`, descripcion: `BOMBAS DE LUMINACIÓN PARA FOTOGRAFIA` },
    { clave: `0300`, descripcion: `MUNICIONES INCENDIARIAS con o sin carga detonante, carga expulsora o carga propulsora` },
    { clave: `0301`, descripcion: `MUNICIONES LACRIMOGENAS con carga detonante, carga expulsora o carga propulsora` },
    { clave: `0303`, descripcion: `MUNICIONES FUMIGENAS con o sin carga detonante, carga expulsora o carga propulsora` },
    { clave: `0305`, descripcion: `POLVORA DE DESTELLOS` },
    { clave: `0306`, descripcion: `TRAZADORES PARA MUNICIONES` },
    { clave: `0312`, descripcion: `CARTUCHOS DE SEÑALES` },
    { clave: `0313`, descripcion: `SEÑALES FUMIGENAS` },
    { clave: `0314`, descripcion: `ENCENDEDORES` },
    { clave: `0315`, descripcion: `ENCENDEDORES` },
    { clave: `0316`, descripcion: `ESPOLETAS DE IGNICION` },
    { clave: `0317`, descripcion: `ESPOLETAS DE IGNICION` },
    { clave: `0318`, descripcion: `GRANADAS DE EJERCICIOS, de mano o de fusil` },
    { clave: `0319`, descripcion: `CEBOS TUBULARES` },
    { clave: `0320`, descripcion: `CEBOS TUBULARES` },
    { clave: `0321`, descripcion: `CARTUCHOS PARA ARMAS, con carga explosiva` },
    { clave: `0322`, descripcion: `MOTORES DE COHETE CON LIQUIDOS HIPERGOLICOS, con o sin carga expulsora` },
    { clave: `0323`, descripcion: `CARTUCHOS DE ACCIONAMIENTO` },
    { clave: `0324`, descripcion: `PROYECTILES con carga explosiva` },
    { clave: `0325`, descripcion: `ENCENDEDORES` },
    { clave: `0326`, descripcion: `CARTUCHOS PARA ARMAS, SIN BALA` },
    { clave: `0327`, descripcion: `CARTUCHOS PARA ARMAS, SIN BALA, o (CARTUCHOS PARA ARMAS DE PEQUEÑO CALIBRE, SIN BALA)` },
    { clave: `0328`, descripcion: `CARTUCHOS PARA ARMAS, CON PROYECTIL INERTE` },
    { clave: `0329`, descripcion: `TORPEDOS con carga explosiva` },
    { clave: `0330`, descripcion: `TORPEDOS con carga explosiva` },
    { clave: `0331`, descripcion: `EXPLOSIVOS PARA VOLADURAS, TIPO B` },
    { clave: `0332`, descripcion: `EXPLOSIVOS PARA VOLADURAS, TIPO E` },
    { clave: `0333`, descripcion: `ARTIFICIOS DE PIROTECNIA` },
    { clave: `0334`, descripcion: `ARTIFICIOS DE PIROTECNIA` },
    { clave: `0335`, descripcion: `ARTIFICIOS DE PIROTECNIA` },
    { clave: `0336`, descripcion: `ARTIFICIOS DE PIROTECNIA` },
    { clave: `0337`, descripcion: `ARTIFICIOS DE PIROTECNIA` },
    { clave: `0338`, descripcion: `CARTUCHOS PARA ARMAS, SIN BALA, o (CARTUCHOS PARA ARMAS DE PEQUEÑO CALIBRE, SIN BALA)` },
    { clave: `0339`, descripcion: `CARTUCHOS PARA ARMAS, CON PROYECTIL INERTE, o CARTUCHOS PARA ARMAS DE PEQUEÑO CALIBRE` },
    { clave: `0340`, descripcion: `NITROCELULOSA seca o humedecida con menos de 25%, en masa, de agua (o de alcohol)` },
    { clave: `0341`, descripcion: `NITROCELULOSA sin modificar o plastificada con menos de 18%, en masa, de substancia plastificante` },
    { clave: `0342`, descripcion: `NITROCELULOSA HUMEDECIDA con un mínimo de 25%, en masa, de alcohol` },
    { clave: `0343`, descripcion: `NITROCELULOSA PLASTIFICADA con un mínimo de 18%, en masa, de substancia plastificante` },
    { clave: `0344`, descripcion: `PROYECTILES con carga explosiva` },
    { clave: `0345`, descripcion: `PROYECTILES inertes con trazador` },
    { clave: `0346`, descripcion: `PROYECTILES con carga detonante o carga expulsora` },
    { clave: `0347`, descripcion: `PROYECTILES con carga detonante o carga expulsora` },
    { clave: `0348`, descripcion: `CARTUCHOS PARA ARMAS, con carga explosiva` },
    { clave: `0349`, descripcion: `OBJETOS EXPLOSIVOS. N.E.P.` },
    { clave: `0350`, descripcion: `OBJETOS EXPLOSIVOS. N.E.P.` },
    { clave: `0351`, descripcion: `OBJETOS EXPLOSIVOS. N.E.P.` },
    { clave: `0352`, descripcion: `OBJETOS EXPLOSIVOS. N.E.P.` },
    { clave: `0353`, descripcion: `OBJETOS EXPLOSIVOS. N.E.P.` },
    { clave: `0354`, descripcion: `OBJETOS EXPLOSIVOS. N.E.P.` },
    { clave: `0355`, descripcion: `OBJETOS EXPLOSIVOS. N.E.P.` },
    { clave: `0356`, descripcion: `OBJETOS EXPLOSIVOS. N.E.P.` },
    { clave: `0357`, descripcion: `SUBSTANCIAS EXPLOSIVAS, N.E.P.` },
    { clave: `0358`, descripcion: `SUBSTANCIAS EXPLOSIVAS, N.E.P.` },
    { clave: `0359`, descripcion: `SUBSTANCIAS EXPLOSIVAS, N.E.P.` },
    { clave: `0360`, descripcion: `CONJUNTOS DE DETONADORES NO ELECTRICOS para voladuras` },
    { clave: `0361`, descripcion: `CONJUNTOS DE DETONADORES NO ELECTRICOS para voladuras` },
    { clave: `0362`, descripcion: `MUNICIONES PARA PRACTICAS` },
    { clave: `0363`, descripcion: `MUNICIONES DE PRUEBA` },
    { clave: `0364`, descripcion: `DETONADORES PARA MUNICIONES` },
    { clave: `0365`, descripcion: `DETONADORES PARA MUNICIONES` },
    { clave: `0366`, descripcion: `DETONADORES PARA MUNICIONES` },
    { clave: `0367`, descripcion: `ESPOLETAS DETONANTES` },
    { clave: `0368`, descripcion: `ESPOLETAS DE IGNICION` },
    { clave: `0369`, descripcion: `CABEZAS DE COMBATE PARA COHETES, con carga explosiva` },
    { clave: `0370`, descripcion: `CABEZAS DE COMBATE PARA COHETES, con carga detonante o carga expulsora` },
    { clave: `0371`, descripcion: `CABEZAS DE COMBATE PARA COHETES, con carga detonante o carga expulsora` },
    { clave: `0372`, descripcion: `GRANADAS DE EJERCICIOS, de mano o de fusil` },
    { clave: `0373`, descripcion: `ARTIFICIOS MANUALES DE PIROTECNIA PARA SEÑALES` },
    { clave: `0374`, descripcion: `CARGAS EXPLOSIVAS PARA SONDEOS` },
    { clave: `0375`, descripcion: `CARGAS EXPLOSIVAS PARA SONDEOS` },
    { clave: `0376`, descripcion: `CEBOS TUBULARES` },
    { clave: `0377`, descripcion: `CEBOS TIPO CAPSULA` },
    { clave: `0378`, descripcion: `CEBOS TIPO CAPSULA` },
    { clave: `0379`, descripcion: `CARTUCHOS VACIOS CON FULMINANTE` },
    { clave: `0380`, descripcion: `OBJETOS PIROFORICOS` },
    { clave: `0381`, descripcion: `CARTUCHOS DE ACCIONAMIENTO` },
    { clave: `0382`, descripcion: `COMPONENTES PARA CADENAS EXPLOSIVAS, N.E.P.` },
    { clave: `0383`, descripcion: `COMPONENTES PARA CADENAS EXPLOSIVAS, N.E.P.` },
    { clave: `0384`, descripcion: `COMPONENTES PARA CADENAS EXPLOSIVAS, N.E.P.` },
    { clave: `0385`, descripcion: `5-NITROBENZOTRIAZOL` },
    { clave: `0386`, descripcion: `ACIDO TRINITROBENCENOSULFONICO` },
    { clave: `0387`, descripcion: `TRINITROFLUORANONA` },
    { clave: `0388`, descripcion: `MEZCLAS DE TRINITROTOLUENO (TNT) Y TRINITROBENCENO o MEZCLAS DE TRINITROTOLUENO (TNT) Y HEXANITROETILBENCENO ` },
    { clave: `0389`, descripcion: `MEZCLAS DE TRINITROTOLUENO (TNT) CON TRINITROBENCENO Y HEXANITROESTILBENO` },
    { clave: `0390`, descripcion: `TRITONAL` },
    { clave: `0391`, descripcion: `MEZCLAS DE CICLOTRIMETILENTRINITRAMINA (CICLONITA, EXOGENO; RDX) Y CICLOTETRAMETILENTETRANITRAMINA (OCTOGENO, HMX) MEZCLAS HUMEDECIDAS con un mínimo de 15% en masa de agua, o MEZCLAS DE CICLOTETRAMETILENTRINITRAMINA (HMX; OCTOGENO) DESENSIBILIZADAS con no menos de10% en masa de inhibidor` },
    { clave: `0392`, descripcion: `HEXANITROESTILBENO` },
    { clave: `0393`, descripcion: `HEXOTONAL` },
    { clave: `0394`, descripcion: `TRINITRORRESORCINOL (ACIDO ESTIFNICO) HUMEDECIDO con no menos de 20% en masa de agua o de una mezcla de alcohol y agua` },
    { clave: `0395`, descripcion: `MOTORES DE COHETE, DE COMBUSTIBLE LIQUIDO` },
    { clave: `0396`, descripcion: `MOTORES DE COHETE, DE COMBUSTIBLE LIQUIDO` },
    { clave: `0397`, descripcion: `COHETES DE COMBUSTIBLE LIQUIDO, con carga explosiva` },
    { clave: `0398`, descripcion: `COHETES DE COMBUSTIBLE LIQUIDO, con carga explosiva` },
    { clave: `0399`, descripcion: `BOMBAS QUE CONTIENEN UN LIQUIDO INFLAMABLE, con carga explosiva` },
    { clave: `0400`, descripcion: `BOMBAS QUE CONTIENEN UN LIQUIDO INFLAMABLE, con carga explosiva` },
    { clave: `0401`, descripcion: `SULFURO DE DIPICRILO seco o humedecido con menos de 10%, en masa, de agua` },
    { clave: `0402`, descripcion: `PERCLORATO DE AMONIO` },
    { clave: `0403`, descripcion: `BENGALAS AEREAS` },
    { clave: `0404`, descripcion: `BENGALAS AEREAS` },
    { clave: `0405`, descripcion: `CARTUCHOS DE SEÑALES` },
    { clave: `0406`, descripcion: `DINITROSOBENCENO` },
    { clave: `0407`, descripcion: `ACIDO TETRAZOL-1-ACETICO` },
    { clave: `0408`, descripcion: `ESPOLETAS DETONANTES con dispositivos de protección` },
    { clave: `0409`, descripcion: `ESPOLETAS DETONANTES con dispositivos de protección` },
    { clave: `0410`, descripcion: `ESPOLETAS DETONANTES con dispositivos de protección` },
    { clave: `0411`, descripcion: `TETRANITRATO DE PENTAERITRITO (TETRANITRATO DE PENTAERITRITOL; TNPE) con un mínimo de 7%, en masa, de cera` },
    { clave: `0412`, descripcion: `CARTUCHOS PARA ARMAS, con carga explosiva` },
    { clave: `0413`, descripcion: `CARTUCHOS PARA ARMAS, SIN BALA` },
    { clave: `0414`, descripcion: `CARGAS PROPULSORAS DE ARTILLERIA` },
    { clave: `0415`, descripcion: `CARGAS PROPULSORAS` },
    { clave: `0417`, descripcion: `CARTUCHOS PARA ARMAS, CON PROYECTIL INERTE, o CARTUCHOS PARA ARMAS DE PEQUEÑO CALIBRE` },
    { clave: `0418`, descripcion: `BENGALAS DE SUPERFICIE` },
    { clave: `0419`, descripcion: `BENGALAS DE SUPERFICIE` },
    { clave: `0420`, descripcion: `BENGALAS AEREAS` },
    { clave: `0421`, descripcion: `BENGALAS AEREAS` },
    { clave: `0424`, descripcion: `PROYECTILES inertes con trazador` },
    { clave: `0425`, descripcion: `PROYECTILES inertes con trazador` },
    { clave: `0426`, descripcion: `PROYECTILES con carga detonante o carga expulsora` },
    { clave: `0427`, descripcion: `PROYECTILES con carga detonante o carga expulsora` },
    { clave: `0428`, descripcion: `OBJETOS PIROTECNICOS para usos técnicos` },
    { clave: `0429`, descripcion: `OBJETOS PIROTECNICOS para usos técnicos` },
    { clave: `0430`, descripcion: `OBJETOS PIROTECNICOS para usos técnicos` },
    { clave: `0431`, descripcion: `OBJETOS PIROTECNICOS para usos técnicos` },
    { clave: `0432`, descripcion: `OBJETOS PIROTECNICOS para usos técnicos` },
    { clave: `0433`, descripcion: `PASTA DE POLVORA HUMEDECIDA con un mínimo de 17%, en masa, de alcohol` },
    { clave: `0434`, descripcion: `PROYECTILES con carga detonante o carga expulsora` },
    { clave: `0435`, descripcion: `PROYECTILES con carga detonante o carga expulsora` },
    { clave: `0436`, descripcion: `COHETES con carga expulsora` },
    { clave: `0437`, descripcion: `COHETES con carga expulsora` },
    { clave: `0438`, descripcion: `COHETES con carga expulsora` },
    { clave: `0439`, descripcion: `CARGAS HUECAS sin detonador` },
    { clave: `0440`, descripcion: `CARGAS HUECAS sin detonador` },
    { clave: `0441`, descripcion: `CARGAS HUECAS sin detonador` },
    { clave: `0442`, descripcion: `CARGAS EXPLOSIVAS PARA USOS CIVILES sin detonador` },
    { clave: `0443`, descripcion: `CARGAS EXPLOSIVAS PARA USOS CIVILES sin detonador` },
    { clave: `0444`, descripcion: `CARGAS EXPLOSIVAS PARA USOS CIVILES sin detonador` },
    { clave: `0445`, descripcion: `CARGAS EXPLOSIVAS PARA USOS CIVILES, sin detonador` },
    { clave: `0446`, descripcion: `VAINAS COMBUSTIBLES VACIAS, SIN CEBO` },
    { clave: `0447`, descripcion: `VAINAS COMBUSTIBLES VACIAS, SIN CEBO` },
    { clave: `0448`, descripcion: `ACIDO 5-MERCAPTOTETRAZOL-1-ACETICO` },
    { clave: `0449`, descripcion: `TORPEDOS CON COMBUSTIBLE LIQUIDO, con o sin carga explosiva` },
    { clave: `0450`, descripcion: `TORPEDOS CON COMBUSTIBLE LIQUIDO, con cabeza inerte` },
    { clave: `0451`, descripcion: `TORPEDOS con carga explosiva` },
    { clave: `0452`, descripcion: `GRANADAS DE EJERCICIOS, de mano o de fusil` },
    { clave: `0453`, descripcion: `COHETES LANZACABOS` },
    { clave: `0454`, descripcion: `INICIADORES` },
    { clave: `0455`, descripcion: `DETONADORES NO ELECTRICOS para voladuras` },
    { clave: `0456`, descripcion: `DETONADORES ELECTRICOS para voladuras` },
    { clave: `0457`, descripcion: `CARGAS EXPLOSIVAS CON AGLUTINANTE PLASTICO` },
    { clave: `0458`, descripcion: `CARGAS EXPLOSIVAS CON AGLUTINANTE PLASTICO` },
    { clave: `0459`, descripcion: `CARGAS EXPLOSIVAS CON AGLUTINANTE PLASTICO` },
    { clave: `0460`, descripcion: `CARGAS EXPLOSIVAS CON AGLUTINANTE PLASTICO` },
    { clave: `0461`, descripcion: `COMPONENTES DE CADENAS DE EXPLOSIVOS N.E.P.` },
    { clave: `0462`, descripcion: `OBJETOS EXPLOSIVOS, N.E.P.` },
    { clave: `0463`, descripcion: `OBJETOS EXPLOSIVOS, N.E.P.` },
    { clave: `0464`, descripcion: `OBJETOS EXPLOSIVOS, N.E.P.` },
    { clave: `0465`, descripcion: `OBJETOS EXPLOSIVOS, N.E.P.` },
    { clave: `0466`, descripcion: `OBJETOS EXPLOSIVOS, N.E.P.` },
    { clave: `0467`, descripcion: `OBJETOS EXPLOSIVOS, N.E.P.` },
    { clave: `0468`, descripcion: `OBJETOS EXPLOSIVOS, N.E.P.` },
    { clave: `0469`, descripcion: `OBJETOS EXPLOSIVOS, N.E.P` },
    { clave: `0470`, descripcion: `OBJETOS EXPLOSIVOS, N.E.P` },
    { clave: `0471`, descripcion: `OBJETOS EXPLOSIVOS, N.E.P` },
    { clave: `0472`, descripcion: `OBJETOS EXPLOSIVOS, N.E.P` },
    { clave: `0473`, descripcion: `SUBSTANCIAS EXPLOSIVAS, N.E.P.` },
    { clave: `0474`, descripcion: `SUBSTANCIAS EXPLOSIVAS, N.E.P.` },
    { clave: `0475`, descripcion: `SUBSTANCIAS EXPLOSIVAS, N.E.P.` },
    { clave: `0476`, descripcion: `SUBSTANCIAS EXPLOSIVAS, N.E.P.` },
    { clave: `0477`, descripcion: `SUBSTANCIAS EXPLOSIVAS, N.E.P.` },
    { clave: `0478`, descripcion: `SUBSTANCIAS EXPLOSIVAS, N.E.P.` },
    { clave: `0479`, descripcion: `SUBSTANCIAS EXPLOSIVAS, N.E.P.` },
    { clave: `0480`, descripcion: `SUBSTANCIAS EXPLOSIVAS, N.E.P.` },
    { clave: `0481`, descripcion: `SUBSTANCIAS EXPLOSIVAS, N.E.P.` },
    { clave: `0482`, descripcion: `SUBSTANCIAS EXPLOSIVAS MUY INSENSIBLES (SUBSTANCIAS EMI), N.E.P.` },
    { clave: `0483`, descripcion: `CICLOTRIMETILENTRINITRAMINA (CICLONITA; HEXOGENO; RDX) DESENSIBILIZADA` },
    { clave: `0484`, descripcion: `CICLOTETRAMETILENTETRANITRA-MINA (OCTOGENO; HMX) DESENSIBILIZADA` },
    { clave: `0485`, descripcion: `SUBSTANCIAS EXPLOSIVAS, N.E.P.` },
    { clave: `0486`, descripcion: `OBJETOS EXPLOSIVOS EXTREMADAMENTE INSENSIBLES (ARTICULOS EEI)` },
    { clave: `0487`, descripcion: `SEÑALES FUMIGENAS` },
    { clave: `0488`, descripcion: `MUNICIONES PARA PRACTICAS` },
    { clave: `0489`, descripcion: `DINITROGLICOLURILO (DINGU)` },
    { clave: `0490`, descripcion: `NITROTRIAZOLONA (NTO)` },
    { clave: `0491`, descripcion: `CARGAS PROPULSORAS` },
    { clave: `0492`, descripcion: `PETARDOS DE SEÑALES EXPLOSIVAS PARA FERROCARRIL ` },
    { clave: `0493`, descripcion: `PETARDOS DE SEÑALES EXPLOSIVAS PARA FERROCARRIL ` },
    { clave: `0494`, descripcion: `DISPOSITIVOS PORTADORES DE CARGAS HUECAS, para perforación de pozos de petróleo` },
    { clave: `0495`, descripcion: `PROPULSANTE LIQUIDO` },
    { clave: `0496`, descripcion: `OCTONAL (ALDEHIDO N OCTILICO)` },
    { clave: `0497`, descripcion: `PROPULSANTE LIQUIDO` },
    { clave: `0498`, descripcion: `PROPULSANTE SOLIDO` },
    { clave: `0499`, descripcion: `PROPULSANTE SOLIDO` },
    { clave: `0500`, descripcion: `GRUPOS DE DETONADORES, NO ELECTRICOS para voladura` },
    { clave: `0501`, descripcion: `PROPULSANTE SOLIDO` },
    { clave: `0502`, descripcion: `COHETES con cabeza inerte` },
    { clave: `0503`, descripcion: `INFLADORES DE BOLSAS DE AIRE o MODULOS DE BOLSAS DE AIRE o PRETENSORES DE CINTURONES DE SEGURIDAD` },
    { clave: `0504`, descripcion: `1H-TETRAZOL` },
    { clave: `0505`, descripcion: `SEÑALES DE SOCORRO para barcos` },
    { clave: `0506`, descripcion: `SEÑALES DE SOCORRO PARA BARCOS` },
    { clave: `0507`, descripcion: `SEÑALES FUMIGENAS` },
    { clave: `0508`, descripcion: `1-HIDROXIBENZOTRIAZOL, ANHIDRO, seco o humedecido con menos del 20%, en masa, de agua` },
    { clave: `0509`, descripcion: `POLVORA SIN HUMO` },
    { clave: `1001`, descripcion: `ACETILENO DISUELTO` },
    { clave: `1002`, descripcion: `AIRE COMPRIMIDO` },
    { clave: `1003`, descripcion: `AIRE LIQUIDO REFRIGERADO` },
    { clave: `1005`, descripcion: `AMONIACO, ANHIDRO` },
    { clave: `1006`, descripcion: `ARGON COMPRIMIDO` },
    { clave: `1008`, descripcion: `TRIFLUORURO DE BORO` },
    { clave: `1009`, descripcion: `BROMOTRIFLUOROMETANO (GAS REFRIGERANTE R13B1)` },
    { clave: `1010`, descripcion: `BUTADIENOS ESTABILIZADOS o MEZCLA ESTABILIZADA DE BUTADIENOS E HIDROCARBUROS, que contengan más del 40% de butadienos.` },
    { clave: `1011`, descripcion: `BUTANO` },
    { clave: `1012`, descripcion: `BUTILENO` },
    { clave: `1013`, descripcion: `DIOXIDO DE CARBONO` },
    { clave: `1016`, descripcion: `MONOXIDO DE CARBONO COMPRIMIDO` },
    { clave: `1017`, descripcion: `CLORO` },
    { clave: `1018`, descripcion: `CLORODIFLUOROMETANO (GAS REFRIGERANTE R 22)` },
    { clave: `1020`, descripcion: `CLOROPENTAFLUOROETANO (GAS REFRIGERANTE R 115)` },
    { clave: `1021`, descripcion: `1-CLORO-1,2,2,2 TETRAFLUOROETANO (GAS REFRIGERANTE R124)` },
    { clave: `1022`, descripcion: `CLOROTRIFLUOROMETANO (GAS REFRIGERANTE R13)` },
    { clave: `1023`, descripcion: `GAS DE HULLA COMPRIMIDO` },
    { clave: `1026`, descripcion: `CIANOGENO` },
    { clave: `1027`, descripcion: `CICLOPROPANO` },
    { clave: `1028`, descripcion: `DICLORODIFLUOROMETANO (GAS REFRIGERANTE R 12)` },
    { clave: `1029`, descripcion: `DICLOROFLUOROMETANO (GAS REFRIGERANTE R 21)` },
    { clave: `1030`, descripcion: `1,1-DIFLUOROETANO (GAS REFRIGERANTE R 152a)` },
    { clave: `1032`, descripcion: `DIMETILAMINA ANHIDRA` },
    { clave: `1033`, descripcion: `ETER METILICO` },
    { clave: `1035`, descripcion: `ETANO` },
    { clave: `1036`, descripcion: `ETILAMINA` },
    { clave: `1037`, descripcion: `CLORURO DE ETILO` },
    { clave: `1038`, descripcion: `ETILENO LIQUIDO REFRIGERADO` },
    { clave: `1039`, descripcion: `ETER ETIL METILICO` },
    { clave: `1040`, descripcion: `OXIDO DE ETILENO u OXIDO DE ETILENO CON NITROGENO hasta una presión total de 1 MPa (10 bar) a 50°C` },
    { clave: `1041`, descripcion: `MEZCLA DE OXIDO DE ETILENO Y DIOXIDO DE CARBONO que contenga más de 9% pero no más de 87% de óxido de etileno` },
    { clave: `1043`, descripcion: `SOLUCIONES AMONIACALES FERTILIZANTES con amoniaco libre` },
    { clave: `1044`, descripcion: `EXTINTORES DE INCENDIOS con gas comprimido o licuado` },
    { clave: `1045`, descripcion: `FLUOR COMPRIMIDO` },
    { clave: `1046`, descripcion: `HELIO COMPRIMIDO` },
    { clave: `1048`, descripcion: `BROMURO DE HIDROGENO ANHIDRO (ACIDO BROMHIDRICO)` },
    { clave: `1049`, descripcion: `HIDROGENO COMPRIMIDO` },
    { clave: `1050`, descripcion: `CLORURO DE HIDROGENO ANHIDRO (ACIDO CLORHIDRICO)` },
    { clave: `1051`, descripcion: `CIANURO DE HIDROGENO ANHIDRO ESTABILIZADO con menos de 3% de agua (ACIDO CIANHIDRICO)` },
    { clave: `1052`, descripcion: `FLUORURO DE HIDROGENO ANHIDRO (ACIDO FLUORHIDRICO)` },
    { clave: `1053`, descripcion: `SULFURO DE HIDROGENO (ACIDO SULFHIDRICO)` },
    { clave: `1055`, descripcion: `ISOBUTILENO` },
    { clave: `1056`, descripcion: `CRIPTON COMPRIMIDO` },
    { clave: `1057`, descripcion: `ENCENDEDORES o RECARGAS DE ENCENDEDORES que contienen gas inflamable` },
    { clave: `1058`, descripcion: `MEZCLAS DE GASES LICUADOS no inflamables cargados con nitrógeno, dióxido de carbono o aire` },
    { clave: `1060`, descripcion: `MEZCLAS DE METILACETILENO Y PROPADIENO ESTABILIZADAS` },
    { clave: `1061`, descripcion: `METILAMINA ANHIDRA` },
    { clave: `1062`, descripcion: `BROMURO DE METILO` },
    { clave: `1063`, descripcion: `CLORURO DE METILO (GAS REFRIGERANTE R 40)` },
    { clave: `1064`, descripcion: `METILMERCAPTANO (SULFURO ACIDO DE METILO)` },
    { clave: `1065`, descripcion: `NEON COMPRIMIDO` },
    { clave: `1066`, descripcion: `NITROGENO COMPRIMIDO` },
    { clave: `1067`, descripcion: `TETROXIDO DE DINITROGENO (DIOXIDO DE NITROGENO)` },
    { clave: `1069`, descripcion: `CLORURO DE NITROSILO` },
    { clave: `1070`, descripcion: `OXIDO NITROSO` },
    { clave: `1071`, descripcion: `GAS DE PETROLEO COMPRIMIDO` },
    { clave: `1072`, descripcion: `OXIGENO COMPRIMIDO` },
    { clave: `1073`, descripcion: `OXIGENO LIQUIDO REFRIGERADO` },
    { clave: `1075`, descripcion: `GASES LICUADOS DEL PETROLEO` },
    { clave: `1076`, descripcion: `FOSGENO (CLORURO DE CARBONILO)` },
    { clave: `1077`, descripcion: `PROPILENO` },
    { clave: `1078`, descripcion: `GAS REFRIGERANTE N.E.P.` },
    { clave: `1079`, descripcion: `DIOXIDO DE AZUFRE` },
    { clave: `1080`, descripcion: `HEXAFLORURO DE AZUFRE` },
    { clave: `1081`, descripcion: `TETRAFLUOROETILENO INHIBIDO` },
    { clave: `1082`, descripcion: `TRIFLUORUROCLOROETILENO INHIBIDO` },
    { clave: `1083`, descripcion: `TRIMETILAMINA ANHIDRA` },
    { clave: `1085`, descripcion: `BROMURO DE VINILO ESTABILIZADO` },
    { clave: `1086`, descripcion: `CLORURO DE VINILO ESTABILIZADO` },
    { clave: `1087`, descripcion: `VINIL METIL ETER ESTABILIZADO` },
    { clave: `1088`, descripcion: `ACETAL` },
    { clave: `1089`, descripcion: `ACETALDEHIDO` },
    { clave: `1090`, descripcion: `ACETONA` },
    { clave: `1091`, descripcion: `ACEITES DE ACETONA` },
    { clave: `1092`, descripcion: `ACROLEINA ESTABILIZADA (ALDEHIDO ACRILICO)` },
    { clave: `1093`, descripcion: `ACRILONITRILO ESTABILIZADO` },
    { clave: `1098`, descripcion: `ALCOHOL ALÍLICO ` },
    { clave: `1099`, descripcion: `BROMURO DE ALILO (3 BROMO PROPENO)` },
    { clave: `1100`, descripcion: `CLORURO DE ALILO (3 CLORO PROPENO)` },
    { clave: `1104`, descripcion: `ACETATOS DE AMILO ` },
    { clave: `1105`, descripcion: `PENTANOLES (ALCOHOLES PENTILICOS)` },
    { clave: `1106`, descripcion: `AMILAMINA (2 AMINO PENTANO)` },
    { clave: `1107`, descripcion: `CLORURO DE AMILO ` },
    { clave: `1108`, descripcion: `1 PENTENO (n-AMILENO)` },
    { clave: `1109`, descripcion: `FORMIATOS DE AMILO ` },
    { clave: `1110`, descripcion: `n-AMILMETHILCETONA (NpentilimetilCETONA)` },
    { clave: `1111`, descripcion: `AMILMERCAPTANO` },
    { clave: `1112`, descripcion: `NITRATO DE  AMILO` },
    { clave: `1114`, descripcion: `BENCENO` },
    { clave: `1120`, descripcion: `BUTANOLES  ` },
    { clave: `1123`, descripcion: `ACETATOS DE BUTILO` },
    { clave: `1125`, descripcion: `n-BUTILAMINA` },
    { clave: `1126`, descripcion: `1-BROMOBUTANO` },
    { clave: `1127`, descripcion: `CLOROBUTANOS` },
    { clave: `1128`, descripcion: `FORMIATO DE n-BUTILO` },
    { clave: `1129`, descripcion: `BUTIRALDEHIDO` },
    { clave: `1130`, descripcion: `ALCANFOR LIQUIDO` },
    { clave: `1131`, descripcion: `DISULFURO DE CARBONO` },
    { clave: `1133`, descripcion: `ADHESIVOS que contengan líquidos inflamables` },
    { clave: `1134`, descripcion: `CLOROBENCENO` },
    { clave: `1135`, descripcion: `ETILENCLORHIDRINA` },
    { clave: `1136`, descripcion: `DESTILADOS DE ALQUITRAN DE HULLA, INFLAMABLE` },
    { clave: `1139`, descripcion: `SOLUCIONES PARA REVESTIMIENTOS (comprende los tratamientos de superficie o los revestimientos utilizados con fines industriales o de otra índole como revestimiento de bajos de vehículos, de bidones o de toneles)` },
    { clave: `1143`, descripcion: `CROTONALDEHIDO ESTABILIZADO` },
    { clave: `1144`, descripcion: `CROTONILENO (DIMETILACETILENO)` },
    { clave: `1145`, descripcion: `CICLOHEXANO` },
    { clave: `1146`, descripcion: `CICLOPENTANO` },
    { clave: `1147`, descripcion: `DECAHIDRONAFTALENO` },
    { clave: `1148`, descripcion: `DIACETONA ALCOHOL (4HIDROXI2CETO-METILPENTANO)` },
    { clave: `1149`, descripcion: `ETERES DIBUTILICOS (ETERES BUTILICOS)` },
    { clave: `1150`, descripcion: `1,2-DICLOROETILENO` },
    { clave: `1152`, descripcion: `DICLOROPENTANOS` },
    { clave: `1153`, descripcion: `ETER DIETILICO DEL ETILENGLICOL` },
    { clave: `1154`, descripcion: `DIETILAMINA` },
    { clave: `1155`, descripcion: `ETER DIETILICO (ETER ETILICO)` },
    { clave: `1156`, descripcion: `DIETILCETONA` },
    { clave: `1157`, descripcion: `DIISOBUTILCETONA` },
    { clave: `1158`, descripcion: `DIISOPROPILAMINA` },
    { clave: `1159`, descripcion: `ETER DIISOPROPILICO` },
    { clave: `1160`, descripcion: `DIMETILAMINA EN SOLUCION ACUOSA` },
    { clave: `1161`, descripcion: `CARBONATO DE METILO` },
    { clave: `1162`, descripcion: `DIMETILDICLOROSILANO` },
    { clave: `1163`, descripcion: `DIMETILHIDRAZINA ASIMETRICA` },
    { clave: `1164`, descripcion: `SULFURO DE METILO` },
    { clave: `1165`, descripcion: `DIOXANO (ETER DE DIOXIETILENO)` },
    { clave: `1166`, descripcion: `DIOXOLANO (ETER DIOXIETILMETILICO)` },
    { clave: `1167`, descripcion: `ETER (DI)VINILICO ESTABILIZADO` },
    { clave: `1169`, descripcion: `EXTRACTOS AROMATICOS LIQUIDOS` },
    { clave: `1170`, descripcion: `ETANOL (ALCOHOL ETILICO) o ETANOL EN SOLUCION (ALCOHOL ETILICO EN SOLUCION)` },
    { clave: `1171`, descripcion: `ETER MONOETILICO DEL ETILENGLICOL` },
    { clave: `1172`, descripcion: `ACETATO DEL ETER MONOETILICO DEL ETILENGLICOL` },
    { clave: `1173`, descripcion: `ACETATO DE ETILO` },
    { clave: `1175`, descripcion: `ETILBENCENO (FENILETANO)` },
    { clave: `1176`, descripcion: `BORATO DE ETILO` },
    { clave: `1177`, descripcion: `ACETATO DE ETILBUTILO (ACETATO DE 2-DIETILETILO)` },
    { clave: `1178`, descripcion: `2-ETILBUTIRALDEHIDO (DIETILACETALDEHIDO)` },
    { clave: `1179`, descripcion: `ETIL BUTIL ETER` },
    { clave: `1180`, descripcion: `BUTIRATO DE ETILO` },
    { clave: `1181`, descripcion: `CLOROACETATO DE ETILO` },
    { clave: `1182`, descripcion: `CLOROFORMIATO DE ETILO` },
    { clave: `1183`, descripcion: `ETILDICLOROSILANO` },
    { clave: `1184`, descripcion: `DICLORURO DE ETILENO` },
    { clave: `1185`, descripcion: `ETILENIMINA (AZIRIDINA) ESTABILIZADA` },
    { clave: `1188`, descripcion: `ÉTER MONOMETÍLICO DEL ETILENGLICOL` },
    { clave: `1189`, descripcion: `ACETATO DEL ÉTER MONOMETILICO DEL ETILENGLICOL` },
    { clave: `1190`, descripcion: `FORMIATO DE ETILO` },
    { clave: `1191`, descripcion: `ALDEHIDOS OCTILICOS` },
    { clave: `1192`, descripcion: `LACTATO DE ETILO` },
    { clave: `1193`, descripcion: `ETILMETILCETONA (METILETILCETONA)` },
    { clave: `1194`, descripcion: `NITRITO DE ETILO EN SOLUCION` },
    { clave: `1195`, descripcion: `PROPIONATO DE ETILO` },
    { clave: `1196`, descripcion: `ETILTRICLOROSILANO` },
    { clave: `1197`, descripcion: `EXTRACTOS DE SABORIZANTES LIQUIDOS` },
    { clave: `1198`, descripcion: `FORMALDEHIDO EN SOLUCION INFLAMABLE` },
    { clave: `1199`, descripcion: `FURALDEHIDOS` },
    { clave: `1201`, descripcion: `ACEITE DE FUSEL` },
    { clave: `1202`, descripcion: `GASOLEO o COMBUSTIBLE PARA MOTORES DIESEL o ACEITE MINERAL PARA CALDEO, LIGERO` },
    { clave: `1203`, descripcion: `COMBUSTIBLE PARA MOTORES o GASOLINA` },
    { clave: `1204`, descripcion: `NITROGLICERINA EN SOLUCION ALCOHOLICA con no más de 1% de nitroglicerina` },
    { clave: `1206`, descripcion: `HEPTANOS` },
    { clave: `1207`, descripcion: `HEXALDEHIDO (ALDEHIDO CAPROICO)` },
    { clave: `1208`, descripcion: `HEXANOS` },
    { clave: `1210`, descripcion: `TINTA DE IMPRENTA, inflamable o MATERIALES RELACIONADOS CON LA TINTA DE IMPRENTA (incluido diluyente de tinta de imprenta o producto reductor), inflamable` },
    { clave: `1212`, descripcion: `ISOBUTANOL (ALCOHOL ISOBUTILICO)` },
    { clave: `1213`, descripcion: `ACETATO DE ISOBUTILO` },
    { clave: `1214`, descripcion: `ISOBUTILAMINA` },
    { clave: `1216`, descripcion: `ISOOCTENO` },
    { clave: `1218`, descripcion: `ISOPRENO ESTABILIZADO` },
    { clave: `1219`, descripcion: `ISOPROPANOL (ALCOHOL ISOPROPILICO)` },
    { clave: `1220`, descripcion: `ACETATO DE ISOPROPILO` },
    { clave: `1221`, descripcion: `ISOPROPILAMINA` },
    { clave: `1222`, descripcion: `NITRATO DE ISOPROPILO` },
    { clave: `1223`, descripcion: `QUEROSENO` },
    { clave: `1224`, descripcion: `CETONAS LIQUIDAS, N.E.P.` },
    { clave: `1228`, descripcion: `MERCAPTANOS LIQUIDOS INFLAMABLES, TOXICOS, N.E.P. o MEZCLA DE MERCAPTANOS LIQUIDOS INFLAMABLES, TOXICOS, N.E.P.` },
    { clave: `1229`, descripcion: `OXIDO DE MESITILO` },
    { clave: `1230`, descripcion: `METANOL` },
    { clave: `1231`, descripcion: `ACETATO DE METILO` },
    { clave: `1233`, descripcion: `ACETATO DE METILAMILO` },
    { clave: `1234`, descripcion: `METILAL (DIMETOXIMETANO; FORMAL)` },
    { clave: `1235`, descripcion: `METILAMINA EN SOLUCION ACUOSA` },
    { clave: `1237`, descripcion: `BUTIRATO DE METILO` },
    { clave: `1238`, descripcion: `CLOROFORMIATO DE METILO` },
    { clave: `1239`, descripcion: `METIL CLOROMETIL ETER` },
    { clave: `1242`, descripcion: `METILDICLOROSILANO` },
    { clave: `1243`, descripcion: `FORMIATO DE METILO` },
    { clave: `1244`, descripcion: `METILHIDRAZINA` },
    { clave: `1245`, descripcion: `METILISOBUTILCETONA` },
    { clave: `1246`, descripcion: `METILISOPROPENILCETONA ESTABILIZADA` },
    { clave: `1247`, descripcion: `METACRILATO DE METILO MONOMERO ESTABILIZADO` },
    { clave: `1248`, descripcion: `PROPIONATO DE METILO` },
    { clave: `1249`, descripcion: `METILPROPILCETONA` },
    { clave: `1250`, descripcion: `METILTRICLOROSILANO` },
    { clave: `1251`, descripcion: `METILVINILCETONA, ESTABILIZADA` },
    { clave: `1259`, descripcion: `NIQUEL CARBONILO` },
    { clave: `1261`, descripcion: `NITROMETANO` },
    { clave: `1262`, descripcion: `OCTANOS` },
    { clave: `1263`, descripcion: `PINTURA (incluye pintura, laca, esmalte, colorante, goma laca, barniz, betún, encáustico, apresto líquido y base líquida para lacas base solvente) o PRODUCTOS PARA PINTURA (incluye compuestos disolventes o reductores de pintura)` },
    { clave: `1264`, descripcion: `PARALDEHIDO` },
    { clave: `1265`, descripcion: `PENTANOS líquidos` },
    { clave: `1266`, descripcion: `PRODUCTOS DE PERFUMERIA que contengan disolventes inflamables` },
    { clave: `1267`, descripcion: `PETROLEO BRUTO` },
    { clave: `1268`, descripcion: `DESTILADOS DE PETROLEO, N.E.P., o PRODUCTOS DE PETROLEO, N.E.P.` },
    { clave: `1272`, descripcion: `ACEITE DE PINO` },
    { clave: `1274`, descripcion: `n-PROPANOL (ALCOHOL PROPILICO NORMAL)` },
    { clave: `1275`, descripcion: `PROPIONALDEHIDO` },
    { clave: `1276`, descripcion: `ACETATO DE n-PROPILO` },
    { clave: `1277`, descripcion: `PROPILAMINA` },
    { clave: `1278`, descripcion: `1-CLOROPROPANO` },
    { clave: `1279`, descripcion: `1,2-DICLOROPROPANO` },
    { clave: `1280`, descripcion: `OXIDO DE PROPILENO` },
    { clave: `1281`, descripcion: `FORMIATOS DE PROPILO` },
    { clave: `1282`, descripcion: `PIRIDINA` },
    { clave: `1286`, descripcion: `ACEITE DE COLOFONIA` },
    { clave: `1287`, descripcion: `DISOLUCION DE CAUCHO` },
    { clave: `1288`, descripcion: `ACEITE DE ESQUISTO` },
    { clave: `1289`, descripcion: `METILATO SODICO EN SOLUCION alcohólica` },
    { clave: `1292`, descripcion: `SILICATO DE TETRAETILO` },
    { clave: `1293`, descripcion: `TINTURAS MEDICINALES` },
    { clave: `1294`, descripcion: `TOLUENO` },
    { clave: `1295`, descripcion: `TRICLOROSILANO` },
    { clave: `1296`, descripcion: `TRIETILAMINA` },
    { clave: `1297`, descripcion: `TRIMETILAMINA EN SOLUCION ACUOSA, con un máximo de 50%, en masa, de trimetilamina` },
    { clave: `1298`, descripcion: `TRIMETILCLOROSILANO` },
    { clave: `1299`, descripcion: `TREMENTINA` },
    { clave: `1300`, descripcion: `SUCEDANEO DE TREMENTINA` },
    { clave: `1301`, descripcion: `ACETATO DE VINILO ESTABILIZADO` },
    { clave: `1302`, descripcion: `VINIL ETIL ETER ESTABILIZADO` },
    { clave: `1303`, descripcion: `CLORURO DE VINILIDENO ESTABILIZADO` },
    { clave: `1304`, descripcion: `VINIL ISOBUTIL ETER ESTABILIZADO` },
    { clave: `1305`, descripcion: `VINILTRICLOROSILANO` },
    { clave: `1306`, descripcion: `PRODUCTOS LÍQUIDOS PARA LA CONSERVACIÓN DE LA MADERA` },
    { clave: `1307`, descripcion: `XILENOS` },
    { clave: `1308`, descripcion: `CIRCONIO EN SUSPENSION EN UN LIQUIDO INFLAMABLE` },
    { clave: `1309`, descripcion: `ALUMINIO EN POLVO, RECUBIERTO` },
    { clave: `1310`, descripcion: `PICRATO AMONICO HUMEDECIDO con un mínimo de 10%, en masa, de agua` },
    { clave: `1312`, descripcion: `BORNEOL` },
    { clave: `1313`, descripcion: `RESINATO DE CALCIO` },
    { clave: `1314`, descripcion: `RESINATO DE CALCIO FUNDIDO` },
    { clave: `1318`, descripcion: `RESINATO DE COBALTO, PRECIPITADO` },
    { clave: `1320`, descripcion: `DINITROFENOL HUMEDECIDO con un mínimo de 15%, en masa, de agua` },
    { clave: `1321`, descripcion: `DINITROFENOLATOS HUMEDECIDOS con no menos de 15%, en masa, de agua` },
    { clave: `1322`, descripcion: `DINITRORRESORCINOL (DINITRORRESORCINA) HUMEDECIDO con un mínimo de 15%, en masa, de agua` },
    { clave: `1323`, descripcion: `FERROCERIO` },
    { clave: `1324`, descripcion: `PELICULAS DE SOPORTE NITROCELULOSICO revestido de gelatina, con exclusión de los desechos` },
    { clave: `1325`, descripcion: `SOLIDO INFLAMABLE ORGANICO, N.E.P.` },
    { clave: `1326`, descripcion: `HAFNIO EN POLVO HUMEDECIDO con un mínimo de 25% de agua (debe haber un exceso visible de agua): a) producido mecánicamente, en partículas de menos de 53 micrones; b) producido químicamente, en partículas de menos de 840 micrones` },
    { clave: `1327`, descripcion: `HENO, PAJA o RASTROJO húmeda, mojada o contaminados con aceite.` },
    { clave: `1328`, descripcion: `HEXAMETILENOTETRAMINA` },
    { clave: `1330`, descripcion: `RESINATO DE MANGANESO` },
    { clave: `1331`, descripcion: `FOSFOROS DISTINTOS DE LOS DE SEGURIDAD` },
    { clave: `1332`, descripcion: `METALDEHIDO` },
    { clave: `1333`, descripcion: `CERIO, en placas, lingotes o barras` },
    { clave: `1334`, descripcion: `NAFTALENO BRUTO o NAFTALENO REFINADO` },
    { clave: `1336`, descripcion: `NITROGUANIDINA (PICRITA) HUMEDECIDA con no menos de 20%, en masa, de agua` },
    { clave: `1337`, descripcion: `NITROALMIDON HUMEDECIDO con no menos de 20%, en masa, de agua` },
    { clave: `1338`, descripcion: `FOSFORO AMORFO` },
    { clave: `1339`, descripcion: `HEPTASULFURO DE FOSFORO, que no contenga fósforo blanco o amarillo` },
    { clave: `1340`, descripcion: `PENTASULFURO DE FOSFORO, que no contenga fósforo blanco o amarillo` },
    { clave: `1341`, descripcion: `SESQUISULFURO DE FOSFORO, que no contenga fósforo blanco o amarillo` },
    { clave: `1343`, descripcion: `TRISULFURO DE FOSFORO, que no contenga fósforo blanco o amarillo` },
    { clave: `1344`, descripcion: `TRINITROFENOL HUMEDECIDO con no menos de 30%, en masa, de agua` },
    { clave: `1345`, descripcion: `DESECHOS DE CAUCHO o RECORTES DE CAUCHO, en polvo o en gránulos de 840 micrones como máximo, y que contienen más de 45% de caucho` },
    { clave: `1346`, descripcion: `SILICIO EN POLVO, AMORFO` },
    { clave: `1347`, descripcion: `PICRATO DE PLATA HUMEDECIDO con no menos de 30%, en masa, de agua` },
    { clave: `1348`, descripcion: `DINITRO-o-CRESOLATO DE SODIO HUMEDECIDO con no menos de 15%, en masa, de agua` },
    { clave: `1349`, descripcion: `PICRAMATO DE SODIO HUMEDECIDO con no menos de 20%, en masa, de agua` },
    { clave: `1350`, descripcion: `AZUFRE` },
    { clave: `1352`, descripcion: `TITANIO EN POLVO HUMEDECIDO con no menos de 25% de agua (debe haber un exceso visible de agua): a) producido mecánicamente, en partículas de menos de 53 micrones; b) producido químicamente, en partículas de menos de 840 micrones` },
    { clave: `1353`, descripcion: `FIBRAS o TEJIDOS IMPREGNADOS DE NITROCELULOSA POCO NITRADA N.E.P.` },
    { clave: `1354`, descripcion: `TRINITROBENCENO HUMEDECIDO con no menos de 30%, en masa, de agua` },
    { clave: `1355`, descripcion: `ACIDO TRINITROBENZOICO HUMEDECIDO con no menos de 30%, en masa, de agua` },
    { clave: `1356`, descripcion: `TRINITROTOLUENO (TNT) HUMEDECIDO con no menos de 30%, en masa, de agua` },
    { clave: `1357`, descripcion: `NITRATO DE UREA HUMEDECIDO con no menos de 20%, en masa, de agua` },
    { clave: `1358`, descripcion: `CIRCONIO EN POLVO, HUMEDECIDO con no menos de 25% de agua (debe haber un exceso visible de agua): a) producido mecánicamente, en partículas de menos de 53 micrones; b) producido químicamente, en partículas de menos de 840 micrones` },
    { clave: `1360`, descripcion: `FOSFURO DE CALCIO` },
    { clave: `1361`, descripcion: `CARBON animal o vegetal` },
    { clave: `1362`, descripcion: `CARBON ACTIVADO` },
    { clave: `1363`, descripcion: `COPRA` },
    { clave: `1364`, descripcion: `DESECHOS DE ACEITE DE ALGODÓN` },
    { clave: `1365`, descripcion: `ALGODÓN HUMEDO` },
    { clave: `1369`, descripcion: `p-NITROSODIMETILANILINA` },
    { clave: `1372`, descripcion: `FIBRAS DE ORIGEN ANIMAL o FIBRAS DE ORIGEN VEGETAL quemadas, húmedas o mojadas` },
    { clave: `1373`, descripcion: `FIBRAS o TEJIDOS DE ORIGEN ANIMAL o VEGETAL o SINTETICOS, N.E.P., impregnados de aceite` },
    { clave: `1374`, descripcion: `HARINA DE PESCADO (DESECHOS DE PESCADO) NO ESTABILIZADA` },
    { clave: `1376`, descripcion: `OXIDO DE HIERRO AGOTADO o HIERRO ESPONJOSO AGOTADO procedentes de la purificación de gas de hulla` },
    { clave: `1378`, descripcion: `CATALIZADOR DE METAL HUMEDECIDO con un exceso visible de líquido` },
    { clave: `1379`, descripcion: `PAPEL TRATADO CON ACEITES NO SATURADOS, no seco (incluso el papel carbón)` },
    { clave: `1380`, descripcion: `PENTABORANO` },
    { clave: `1381`, descripcion: `FOSFORO BLANCO o AMARILLO, SECO o BAJO AGUA o EN SOLUCION` },
    { clave: `1382`, descripcion: `SULFURO DE POTASIO ANHIDRO o SULFURO POTASICO con menos de 30% de agua de cristalización` },
    { clave: `1383`, descripcion: `METAL  PIROFÓRICO,   N.E.P..,  o ALEACIÓN PIROFÓRICA, N.E.P.` },
    { clave: `1384`, descripcion: `DITIONITO DE SODIO (hidrosulfito de sodio)` },
    { clave: `1385`, descripcion: `SULFURO SÓDICO ANHIDRO o SULFURO SÓDICO con menos del  30% de agua de cristalización.` },
    { clave: `1386`, descripcion: `TORTA OLEAGINOSA con más de 1,5% de aceite y un máximo de 11% de humedad` },
    { clave: `1387`, descripcion: `DESECHOS DE LANA, HUMEDOS` },
    { clave: `1389`, descripcion: `METAL ALCALINO, AMALGAMAS DE` },
    { clave: `1390`, descripcion: `AMIDAS DE METALES ALCALINOS` },
    { clave: `1391`, descripcion: `METALES ALCALINOS, DISPERSIÓN DE, o METALES ALCALINOTERREOS, DISPERSIÓN DE` },
    { clave: `1392`, descripcion: `METALES ALCALINOTERREOS, AMALGAMA DE` },
    { clave: `1393`, descripcion: `METALES  ALCALINOTERREOS, ALEACIÓN DE, N.E.P.` },
    { clave: `1394`, descripcion: `CARBURO ALUMINICO` },
    { clave: `1395`, descripcion: `ALUMINIO FERROSILICIO EN POLVO` },
    { clave: `1396`, descripcion: `ALUMINIO EN POLVO, NO RECUBIERTO` },
    { clave: `1397`, descripcion: `FOSFURO DE ALUMINIO` },
    { clave: `1398`, descripcion: `ALUMINIO SILICIO EN POLVO, NO RECUBIERTO` },
    { clave: `1400`, descripcion: `BARIO` },
    { clave: `1401`, descripcion: `CALCIO` },
    { clave: `1402`, descripcion: `CARBURO CALCICO` },
    { clave: `1403`, descripcion: `CIANAMIDA DE CALCIO con más de 0,1% de carburo de calcio` },
    { clave: `1404`, descripcion: `HIDRURO DE CALCIO` },
    { clave: `1405`, descripcion: `SILICATO DE CALCIO` },
    { clave: `1407`, descripcion: `CESIO` },
    { clave: `1408`, descripcion: `FERROSILICIO con el 30% o más pero menos de 90% de silicio` },
    { clave: `1409`, descripcion: `HIDRUROS METALICOS QUE REACCIONAN CON EL AGUA, N.E.P.` },
    { clave: `1410`, descripcion: `HIDRURO DE LITIO Y ALUMINIO` },
    { clave: `1411`, descripcion: `HIDRURO ETEREO DE LITIO Y ALUMINIO` },
    { clave: `1413`, descripcion: `BOROHIDRURO DE LITIO` },
    { clave: `1414`, descripcion: `HIDRURO DE LITIO` },
    { clave: `1415`, descripcion: `LITIO` },
    { clave: `1417`, descripcion: `SILICATO DE LITIO (LIITIOSILICIO)` },
    { clave: `1418`, descripcion: `MAGNESIO EN POLVO o ALEACIONES DE MAGNESIO EN POLVO` },
    { clave: `1419`, descripcion: `FOSFURO DE MAGNESIO Y ALUMINIO` },
    { clave: `1420`, descripcion: `POTASIO METALICO, ALEACIONES LIQUIDAS DE` },
    { clave: `1421`, descripcion: `METALES ALCALINOS, ALEACION LIQUIDA DE  N.E.P.` },
    { clave: `1422`, descripcion: `POTASIO Y SODIO, ALEACIONES DE` },
    { clave: `1423`, descripcion: `RUBIDIO` },
    { clave: `1426`, descripcion: `BOROHIDRURO DE SODIO` },
    { clave: `1427`, descripcion: `HIDRURO DE SODIO` },
    { clave: `1428`, descripcion: `SODIO` },
    { clave: `1431`, descripcion: `METILATO DE SODIO` },
    { clave: `1432`, descripcion: `FOSFURO DE SODIO` },
    { clave: `1433`, descripcion: `FOSFUROS ESTANICOS` },
    { clave: `1435`, descripcion: `ZINC, CENIZAS DE` },
    { clave: `1436`, descripcion: `ZINC EN POLVO` },
    { clave: `1437`, descripcion: `HIDRURO DE CIRCONIO` },
    { clave: `1438`, descripcion: `NITRATO DE ALUMINIO` },
    { clave: `1439`, descripcion: `DICROMATO DE AMONIO` },
    { clave: `1442`, descripcion: `PERCLORATO DE AMONIO` },
    { clave: `1444`, descripcion: `PERSULFATO DE AMONIO` },
    { clave: `1445`, descripcion: `CLORATO DE BARIO` },
    { clave: `1446`, descripcion: `NITRATO DE BARIO` },
    { clave: `1447`, descripcion: `PERCLORATO DE BARIO` },
    { clave: `1448`, descripcion: `PERMANGANATO DE BARIO` },
    { clave: `1449`, descripcion: `PEROXIDO DE BARIO` },
    { clave: `1450`, descripcion: `BROMATOS INORGANICOS, N.E.P.` },
    { clave: `1451`, descripcion: `NITRATO DE CESIO` },
    { clave: `1452`, descripcion: `CLORATO DE CALCIO` },
    { clave: `1453`, descripcion: `CLORITO DE CALCIO` },
    { clave: `1454`, descripcion: `NITRATO DE CALCIO` },
    { clave: `1455`, descripcion: `PERCLORATO DE CALCIO` },
    { clave: `1456`, descripcion: `PERMANGANATO DE CALCIO` },
    { clave: `1457`, descripcion: `PEROXIDO DE CALCIO` },
    { clave: `1458`, descripcion: `CLORATO Y BORATO, MEZCLA DE` },
    { clave: `1459`, descripcion: `CLORATO Y CLORURO DE MAGNESIO, MEZCLA DE` },
    { clave: `1461`, descripcion: `CLORATOS INORGANICOS, N.E.P.` },
    { clave: `1462`, descripcion: `CLORITOS INORGANICOS, N.E.O.N.` },
    { clave: `1463`, descripcion: `TRIOXIDO DE CROMO ANHIDRO` },
    { clave: `1465`, descripcion: `NITRATO DE DIDIMIO` },
    { clave: `1466`, descripcion: `NITRATO FERRICO` },
    { clave: `1467`, descripcion: `NITRATO DE GUANIDINA` },
    { clave: `1469`, descripcion: `NITRATO DE PLOMO II` },
    { clave: `1470`, descripcion: `PERCLORATO DE PLOMO` },
    { clave: `1471`, descripcion: `HIPOCLORITO DE LITIO, SECO, o MEZCLA DE HIPOCLORITO DE LITIO` },
    { clave: `1472`, descripcion: `PEROXIDO DE LITIO` },
    { clave: `1473`, descripcion: `BROMATO DE MAGNESIO` },
    { clave: `1474`, descripcion: `NITRATO DE MAGNESIO` },
    { clave: `1475`, descripcion: `PERCLORATO DE MAGNESIO` },
    { clave: `1476`, descripcion: `PEROXIDO DE MAGNESIO` },
    { clave: `1477`, descripcion: `NITRATOS INORGANICOS, N.E.P.` },
    { clave: `1479`, descripcion: `SOLIDO COMBURENTE, N.E.P.` },
    { clave: `1481`, descripcion: `PERCLORATOS INORGANICOS, N.E.P.` },
    { clave: `1482`, descripcion: `PERMANGANATOS INORGANICOS, N.E.P.` },
    { clave: `1483`, descripcion: `PERÓXIDOS INORGÁNICOS, N.E.P.` },
    { clave: `1484`, descripcion: `BROMATO DE POTASIO` },
    { clave: `1485`, descripcion: `CLORATO DE POTASIO` },
    { clave: `1486`, descripcion: `NITRATO DE POTASIO` },
    { clave: `1487`, descripcion: `MEZCLA DE NITRATO DE POTASIO Y NITRITO DE SODIO` },
    { clave: `1488`, descripcion: `NITRITO DE POTASIO` },
    { clave: `1489`, descripcion: `PERCLORATO DE POTASIO` },
    { clave: `1490`, descripcion: `PERMANGANATO DE POTASIO` },
    { clave: `1491`, descripcion: `PEROXIDO DE POTASIO` },
    { clave: `1492`, descripcion: `PERSULFATO DE POTASIO` },
    { clave: `1493`, descripcion: `NITRATO DE PLATA` },
    { clave: `1494`, descripcion: `BROMATO DE SODIO` },
    { clave: `1495`, descripcion: `CLORATO DE SODIO` },
    { clave: `1496`, descripcion: `CLORITO DE SODIO` },
    { clave: `1498`, descripcion: `NITRATO DE SODIO` },
    { clave: `1499`, descripcion: `MEZCLA DE NITRATO DE SODIO Y NITRATO DE POTASIO` },
    { clave: `1500`, descripcion: `NITRITO DE SODIO` },
    { clave: `1502`, descripcion: `PERCLORATO DE SODIO` },
    { clave: `1503`, descripcion: `PERMANGANATO DE SODIO` },
    { clave: `1504`, descripcion: `PEROXIDO DE SODIO` },
    { clave: `1505`, descripcion: `PERSULFATO DE SODIO` },
    { clave: `1506`, descripcion: `CLORATO DE ESTRONCIO` },
    { clave: `1507`, descripcion: `NITRATO DE ESTRONCIO` },
    { clave: `1508`, descripcion: `PERCLORATO DE ESTRONCIO` },
    { clave: `1509`, descripcion: `PEROXIDO DE ESTRONCIO` },
    { clave: `1510`, descripcion: `TETRANITROMETANO` },
    { clave: `1511`, descripcion: `UREA-AGUA OXIGENADA` },
    { clave: `1512`, descripcion: `NITRITO DE ZINC Y AMONIO` },
    { clave: `1513`, descripcion: `CLORATO DE ZINC` },
    { clave: `1514`, descripcion: `NITRATO DE ZINC` },
    { clave: `1515`, descripcion: `PERMANGANATO DE ZINC` },
    { clave: `1516`, descripcion: `PEROXIDO DE ZINC` },
    { clave: `1517`, descripcion: `PICRAMATO DE CIRCONIO HUMEDECIDO con un mínimo de 20%, en masa, de agua` },
    { clave: `1541`, descripcion: `CIANHIDRINA DE ACETONA, ESTABILIZADA` },
    { clave: `1544`, descripcion: `ALCALOIDES SOLIDOS, N.E.P. SALES DE ALCALOIDES SOLIDAS, N.E.P.` },
    { clave: `1545`, descripcion: `ISOTIOCIANATO DE ALILO ESTABILIZADO INHIBIDO` },
    { clave: `1546`, descripcion: `ARSENIATO DE AMONIO` },
    { clave: `1547`, descripcion: `ANILINA` },
    { clave: `1548`, descripcion: `CLORHIDRATO DE ANILINA` },
    { clave: `1549`, descripcion: `ANTIMONIO, COMPUESTO INORGANICO SOLIDO DE, N.E.P.` },
    { clave: `1550`, descripcion: `LACTATO DE ANTIMONIO` },
    { clave: `1551`, descripcion: `TARTRATO DE ANTIMONIO Y POTASIO` },
    { clave: `1553`, descripcion: `ACIDO ARSENOICO LIQUIDO` },
    { clave: `1554`, descripcion: `ACIDO ARSENOICO SOLIDO` },
    { clave: `1555`, descripcion: `BROMURO DE ARSENICO` },
    { clave: `1556`, descripcion: `ARSENICO, COMPUESTO LIQUIDO DE, N.E.P., INORGANICO, en particular arseniatos, N.E.P., arsenitos, N.E.P., sulfuros de arsénico, N.E.P.` },
    { clave: `1557`, descripcion: `ARSENICO, COMPUESTO SOLIDO DE, N.E.P., INORGANICO, en particular arseniatos N.E.P., arsenitos N.E.P., sulfuros de arsénico N.E.P.` },
    { clave: `1558`, descripcion: `ARSENICO` },
    { clave: `1559`, descripcion: `PENTOXIDO DE ARSENICO` },
    { clave: `1560`, descripcion: `TRICLORURO DE ARSENICO` },
    { clave: `1561`, descripcion: `TRIOXIDO DE ARSENICO` },
    { clave: `1562`, descripcion: `POLVO ARSENICAL` },
    { clave: `1564`, descripcion: `BARIO, COMPUESTO DE, N.E.P.` },
    { clave: `1565`, descripcion: `CIANURO DE BARIO` },
    { clave: `1566`, descripcion: `BERILIO, COMPUESTO DE, N.E.P.` },
    { clave: `1567`, descripcion: `BERILIO EN POLVO` },
    { clave: `1569`, descripcion: `BROMOACETONA` },
    { clave: `1570`, descripcion: `BRUCINA` },
    { clave: `1571`, descripcion: `AZIDA DE BARIO HUMIDIFICADA con un mínimo de 50%, en masa, de agua` },
    { clave: `1572`, descripcion: `ACIDO CACODILICO` },
    { clave: `1573`, descripcion: `ARSENIATO DE CALCIO` },
    { clave: `1574`, descripcion: `MEZCLAS DE ARSENIATO CALCICO Y ARSENITO CALCICO, SOLIDAS` },
    { clave: `1575`, descripcion: `CIANURO DE CALCIO` },
    { clave: `1577`, descripcion: `CLORODINITROBENCENOS LIQUIDOS` },
    { clave: `1578`, descripcion: `CLORONITROBENCENOS` },
    { clave: `1579`, descripcion: `CLORHIDRATO DE 4-CLORO-o-TOLUIDINA` },
    { clave: `1580`, descripcion: `CLOROPICRINA` },
    { clave: `1581`, descripcion: `MEZCLA DE CLOROPICRINA Y BROMURO DE METILO` },
    { clave: `1582`, descripcion: `MEZCLA DE CLOROPICRINA Y CLORURO DE METILO` },
    { clave: `1583`, descripcion: `MEZCLA DE CLOROPICRINA, N.E.P.` },
    { clave: `1585`, descripcion: `ACETOARSENITO DE COBRE` },
    { clave: `1586`, descripcion: `ARSENITO DE COBRE` },
    { clave: `1587`, descripcion: `CIANURO DE COBRE` },
    { clave: `1588`, descripcion: `CIANUROS INORGANICOS, SOLIDOS, N.E.P.` },
    { clave: `1589`, descripcion: `CLORURO DE CIANOGENO INHIBIDO` },
    { clave: `1590`, descripcion: `DICLOROANILINAS LIQUIDAS` },
    { clave: `1591`, descripcion: `o-DICLOROBENCENO` },
    { clave: `1593`, descripcion: `DICLOROMETANO` },
    { clave: `1594`, descripcion: `SULFATO DE DIETILO` },
    { clave: `1595`, descripcion: `SULFATO DE DIMETILO` },
    { clave: `1596`, descripcion: `DINITROANILINAS` },
    { clave: `1597`, descripcion: `DINITROBENCENOS LIQUIDOS` },
    { clave: `1598`, descripcion: `DINITRO-o-CRESOL` },
    { clave: `1599`, descripcion: `DINITROFENOL EN SOLUCION` },
    { clave: `1600`, descripcion: `DINITROTOLUENOS FUNDIDOS` },
    { clave: `1601`, descripcion: `DESINFECTANTE SOLIDO, TOXICO, N.E.P.` },
    { clave: `1602`, descripcion: `COLORANTE LIQUIDO, TOXICO, N.E.P., o MATERIA INTERMEDIA LIQUIDA PARA COLORANTE, LIQUIDA, TOXICA, N.E.P.` },
    { clave: `1603`, descripcion: `BROMOACETATO DE ETILO` },
    { clave: `1604`, descripcion: `ETILENDIAMINA` },
    { clave: `1605`, descripcion: `DIBROMURO DE ETILENO` },
    { clave: `1606`, descripcion: `ARSENIATO FERRICO` },
    { clave: `1607`, descripcion: `ARSENITO FERRICO` },
    { clave: `1608`, descripcion: `ARSENIATO FERROSO` },
    { clave: `1611`, descripcion: `TETRAFOSFATO DE HEXAETILO` },
    { clave: `1612`, descripcion: `MEZCLA DE TETRAFOSFATO DE HEXAETILO Y GAS COMPRIMIDO` },
    { clave: `1613`, descripcion: `ACIDO CIANHIDRICO EN SOLUCION ACUOSA (CIANURO DE HIDROGENO EN SOLUCION ACUOSA) con no más de 20% ácido cianhídrico` },
    { clave: `1614`, descripcion: `CIANURO DE HIDROGENO ESTABILIZADO con menos de 3% de agua y absorbido en una materia porosa inerte` },
    { clave: `1616`, descripcion: `ACETATO DE PLOMO` },
    { clave: `1617`, descripcion: `ARSENIATOS DE PLOMO` },
    { clave: `1618`, descripcion: `ARSENITOS DE PLOMO` },
    { clave: `1620`, descripcion: `CIANURO DE PLOMO` },
    { clave: `1621`, descripcion: `PURPURA DE LONDRES` },
    { clave: `1622`, descripcion: `ARSENIATO DE MAGNESIO` },
    { clave: `1623`, descripcion: `ARSENIATO DE MERCURIO` },
    { clave: `1624`, descripcion: `CLORURO DE MERCURIO MERCURICO` },
    { clave: `1625`, descripcion: `NITRATO MERCURICO` },
    { clave: `1626`, descripcion: `CIANURO DE MERCURIO Y POTASIO` },
    { clave: `1627`, descripcion: `NITRATO MERCURIOSO` },
    { clave: `1629`, descripcion: `ACETATO DE MERCURIO` },
    { clave: `1630`, descripcion: `CLORURO DE MERCURIO Y AMONIO` },
    { clave: `1631`, descripcion: `BENZOATO DE MERCURIO` },
    { clave: `1634`, descripcion: `BROMUROS DE MERCURIO` },
    { clave: `1636`, descripcion: `CIANURO DE MERCURIO` },
    { clave: `1637`, descripcion: `GLUCONATO DE MERCURIO` },
    { clave: `1638`, descripcion: `YODURO DE MERCURIO` },
    { clave: `1639`, descripcion: `NUCLEATO DE MERCURIO` },
    { clave: `1640`, descripcion: `OLEATO DE MERCURIO` },
    { clave: `1641`, descripcion: `OXIDO DE MERCURIO` },
    { clave: `1642`, descripcion: `OXICIANURO DE MERCURIO, DESENSIBILIZADO` },
    { clave: `1643`, descripcion: `YODURO DE MERCURIO Y POTASIO` },
    { clave: `1644`, descripcion: `SALICILATO DE MERCURIO` },
    { clave: `1645`, descripcion: `SULFATO DE MERCURIO` },
    { clave: `1646`, descripcion: `TIOCIANATO DE MERCURIO` },
    { clave: `1647`, descripcion: `BROMURO DE METILO y DIBROMURO DE ETILENO, MEZCLAS DE, LIQUIDO` },
    { clave: `1648`, descripcion: `ACETONITRILO` },
    { clave: `1649`, descripcion: `MEZCLAS ANTIDETONANTES PARA COMBUSTIBLES DE MOTORES` },
    { clave: `1650`, descripcion: `beta-NAFTILAMINA` },
    { clave: `1651`, descripcion: `NAFTILTIOUREA` },
    { clave: `1652`, descripcion: `NAFTILUREA` },
    { clave: `1653`, descripcion: `CIANURO DE NIQUEL` },
    { clave: `1654`, descripcion: `NICOTINA` },
    { clave: `1655`, descripcion: `COMPUESTOS DE NICOTINA, SOLIDOS, N.E.P. o PREPARADOS DE NICOTINA SOLIDOS N.E.P.` },
    { clave: `1656`, descripcion: `CLORHIDRATO DE NICOTINA líquida o CLORHIDRATO DE NICOTINA EN SOLUCION` },
    { clave: `1657`, descripcion: `SALICILATO DE NICOTINA` },
    { clave: `1658`, descripcion: `SULFATO DE NICOTINA EN SOLUCION` },
    { clave: `1659`, descripcion: `TARTRATO DE NICOTINA` },
    { clave: `1660`, descripcion: `OXIDO NITRICO COMPRIMIDO` },
    { clave: `1661`, descripcion: `NITROANILINAS (o-, m-, p-)` },
    { clave: `1662`, descripcion: `NITROBENCENO` },
    { clave: `1663`, descripcion: `NITROFENOLES (o-, m-, p-)` },
    { clave: `1664`, descripcion: `NITROTOLUENOS LIQUIDOS` },
    { clave: `1665`, descripcion: `NITROXILENOS LIQUIDOS` },
    { clave: `1669`, descripcion: `PENTACLOROETANO` },
    { clave: `1670`, descripcion: `PERCLOROMETILMERCAPTANO` },
    { clave: `1671`, descripcion: `FENOL SOLIDO` },
    { clave: `1672`, descripcion: `CLORURO DE FENILCARBILAMINA` },
    { clave: `1673`, descripcion: `FENILENDIAMINAS (o-, m-, p-)` },
    { clave: `1674`, descripcion: `ACETATO FENILMERCURICO` },
    { clave: `1677`, descripcion: `ARSENIATO DE POTASIO` },
    { clave: `1678`, descripcion: `ARSENITO DE POTASIO` },
    { clave: `1679`, descripcion: `CUPROCIANURO DE POTASIO` },
    { clave: `1680`, descripcion: `CIANURO DE POTASIO` },
    { clave: `1683`, descripcion: `ARSENITO DE PLATA` },
    { clave: `1684`, descripcion: `CIANURO DE PLATA` },
    { clave: `1685`, descripcion: `ARSENIATO DE SODIO` },
    { clave: `1686`, descripcion: `ARSENITO DE SODIO EN SOLUCION ACUOSA` },
    { clave: `1687`, descripcion: `AZIDA DE SODIO` },
    { clave: `1688`, descripcion: `CACODILATO DE SODIO` },
    { clave: `1689`, descripcion: `CIANURO DE SODIO` },
    { clave: `1690`, descripcion: `FLUORURO DE SODIO` },
    { clave: `1691`, descripcion: `ARSENITO DE ESTRONCIO` },
    { clave: `1692`, descripcion: `ESTRICNINA o SALES DE ESTRICNINA` },
    { clave: `1693`, descripcion: `GASES LACRIMÓGENOS, SUBSTANCIA LÍQUIDA PARA LA FABRICACIÓN DE, N.E.P.` },
    { clave: `1694`, descripcion: `CIANUROS DE BROMOBENCILO LIQUIDOS` },
    { clave: `1695`, descripcion: `CLOROACETONA ESTABILIZADA` },
    { clave: `1697`, descripcion: `CLOROACETOFENONA SOLIDA` },
    { clave: `1698`, descripcion: `DIFENILAMINOCLOROARSINA` },
    { clave: `1699`, descripcion: `DIFENILCLOROARSINA LIQUIDA` },
    { clave: `1700`, descripcion: `VELAS LACRIMOGENAS` },
    { clave: `1701`, descripcion: `BROMURO DE XILILO` },
    { clave: `1702`, descripcion: `1,1,2,2-TETRACLOROETANO` },
    { clave: `1704`, descripcion: `DITIOPIROFOSFATO DE TETRAETILO` },
    { clave: `1707`, descripcion: `TALIO, COMPUESTO DE, N.E.P.` },
    { clave: `1708`, descripcion: `TOLUIDINAS LIQUIDAS` },
    { clave: `1709`, descripcion: `TOLUILEN-2,4-DIAMINA SOLIDA` },
    { clave: `1710`, descripcion: `TRICLOROETILENO` },
    { clave: `1711`, descripcion: `XILIDINAS LIQUIDAS` },
    { clave: `1712`, descripcion: `ARSENIATO DE ZINC, ARSENITO DE ZINC o MEZCLAS DE ARSENIATO Y ARSENITO DE ZINC` },
    { clave: `1713`, descripcion: `CIANURO DE ZINC` },
    { clave: `1714`, descripcion: `FOSFURO DE ZINC` },
    { clave: `1715`, descripcion: `ANHIDRIDO ACETICO` },
    { clave: `1716`, descripcion: `BROMURO DE ACETILO` },
    { clave: `1717`, descripcion: `CLORURO DE ACETILO` },
    { clave: `1718`, descripcion: `FOSFATO ACIDO DE BUTILO` },
    { clave: `1719`, descripcion: `LIQUIDOS ALCALINO CAUSTICO N.E.P.` },
    { clave: `1722`, descripcion: `CLOROFORMIATO DE ALILO` },
    { clave: `1723`, descripcion: `YODURO DE ALILO` },
    { clave: `1724`, descripcion: `ALILTRICLOROSILANO ESTABILIZADO` },
    { clave: `1725`, descripcion: `BROMURO DE ALUMINIO ANHIDRO` },
    { clave: `1726`, descripcion: `CLORURO DE ALUMINIO ANHIDRO` },
    { clave: `1727`, descripcion: `HIDROGENODIFLUORURO DE AMONIO SOLIDO` },
    { clave: `1728`, descripcion: `AMILTRICLOROSILANO` },
    { clave: `1729`, descripcion: `CLORURO DE ANISOILO` },
    { clave: `1730`, descripcion: `PENTACLORURO DE ANTIMONIO LIQUIDO` },
    { clave: `1731`, descripcion: `PENTACLORURO DE ANTIMONIO EN SOLUCION` },
    { clave: `1732`, descripcion: `PENTAFLUORURO DE ANTIMONIO` },
    { clave: `1733`, descripcion: `TRICLORURO DE ANTIMONIO` },
    { clave: `1736`, descripcion: `CLORURO DE BENZOILO` },
    { clave: `1737`, descripcion: `BROMURO DE BENCILO` },
    { clave: `1738`, descripcion: `CLORURO DE BENCILO` },
    { clave: `1739`, descripcion: `CLOROFORMIATO DE BENCILO` },
    { clave: `1740`, descripcion: `HIDROGENODIFLUORUROS, N.E.P.` },
    { clave: `1741`, descripcion: `TRICLORURO DE BORO` },
    { clave: `1742`, descripcion: `TRIFLUORURO DE BORO Y ACIDO ACETICO, COMPLEJO LIQUIDO DE` },
    { clave: `1743`, descripcion: `TRIFLUORURO DE BORO Y ACIDO PROPIONICO, COMPLEJO DE` },
    { clave: `1744`, descripcion: `BROMO o BROMO EN SOLUCION` },
    { clave: `1745`, descripcion: `PENTAFLUORURO DE BROMO` },
    { clave: `1746`, descripcion: `TRIFLUORURO DE BROMO` },
    { clave: `1747`, descripcion: `BUTILTRICLOROSILANO` },
    { clave: `1748`, descripcion: `HIPOCLORITO  DE CALCIO SECO o DE HIPOCLORITO CALCICO EN MEZCLA  SECA, con más de 39% de cloro activo (8,8% de oxígeno activo)` },
    { clave: `1749`, descripcion: `TRIFLUORURO DE CLORO` },
    { clave: `1750`, descripcion: `ACIDO CLOROACETICO EN SOLUCION` },
    { clave: `1751`, descripcion: `ACIDO CLOROACETICO SOLIDO` },
    { clave: `1752`, descripcion: `CLORURO DE CLOROACETILO` },
    { clave: `1753`, descripcion: `CLOROFENILTRICLOROSILANO` },
    { clave: `1754`, descripcion: `ACIDO CLOROSULFONICO (con o sin trióxido de azufre)` },
    { clave: `1755`, descripcion: `ACIDO CROMICO EN SOLUCION` },
    { clave: `1756`, descripcion: `FLUORURO CROMICO SOLIDO` },
    { clave: `1757`, descripcion: `FLUORURO CROMICO EN SOLUCION` },
    { clave: `1758`, descripcion: `OXICLORURO DE CROMO (CLORURO DE CROMILO)` },
    { clave: `1759`, descripcion: `SOLIDO CORROSIVO, N.E.P.` },
    { clave: `1760`, descripcion: `LIQUIDO CORROSIVO, N.E.P.` },
    { clave: `1761`, descripcion: `CUPRIETILENDIAMINA EN SOLUCION` },
    { clave: `1762`, descripcion: `CICLOHEXENILTRICLOROSILANO` },
    { clave: `1763`, descripcion: `CICLOHEXILTRICLOROSILANO` },
    { clave: `1764`, descripcion: `ACIDO DICLOROACETICO` },
    { clave: `1765`, descripcion: `CLORURO DE DICLOROACETILO` },
    { clave: `1766`, descripcion: `DICLOROFENILTRICLOROSILANO` },
    { clave: `1767`, descripcion: `DIETILDICLOROSILANO` },
    { clave: `1768`, descripcion: `ACIDO DIFLUOROFOSFORICO ANHIDRO` },
    { clave: `1769`, descripcion: `DIFENILDICLOROSILANO` },
    { clave: `1770`, descripcion: `BROMURO DE DIFENILMETILO` },
    { clave: `1771`, descripcion: `DODECILTRICLOROSILANO` },
    { clave: `1773`, descripcion: `CLORURO FERRICO ANHIDRO` },
    { clave: `1774`, descripcion: `EXTINTORES DE INCENDIOS, CARGAS PARA, líquidos corrosivos` },
    { clave: `1775`, descripcion: `ACIDO FLUOROBORICO` },
    { clave: `1776`, descripcion: `ACIDO FLUOROFOSFORICO ANHIDRO` },
    { clave: `1777`, descripcion: `ACIDO FLUOROSULFONICO` },
    { clave: `1778`, descripcion: `ACIDO FLUOROSILICICO` },
    { clave: `1779`, descripcion: `ACIDO FORMICO` },
    { clave: `1780`, descripcion: `CLORURO DE FUMARILO` },
    { clave: `1781`, descripcion: `HEXADECILTRICLOROSILANO` },
    { clave: `1782`, descripcion: `ACIDO HEXAFLUOROFOSFORICO` },
    { clave: `1783`, descripcion: `HEXAMETILENDIAMINA EN SOLUCION` },
    { clave: `1784`, descripcion: `HEXILTRICLOROSILANO` },
    { clave: `1786`, descripcion: `MEZCLA DE ACIDO FLUORHIDRICO Y ACIDO SULFURICO` },
    { clave: `1787`, descripcion: `ACIDO YODHIDRICO` },
    { clave: `1788`, descripcion: `ACIDO BROMHIDRICO` },
    { clave: `1789`, descripcion: `ACIDO CLORHIDRICO` },
    { clave: `1790`, descripcion: `ACIDO FLUORHIDRICO EN SOLUCION con más de 60% ácido fluorhídrico` },
    { clave: `1790`, descripcion: `ACIDO FLUORHIDRICO EN SOLUCION con no más de 60% ácido fluorhídrico` },
    { clave: `1791`, descripcion: `HIPOCLORITOS EN SOLUCION` },
    { clave: `1792`, descripcion: `MONOCLORURO DE YODO` },
    { clave: `1793`, descripcion: `FOSFATO ACIDO DE ISOPROPILO` },
    { clave: `1794`, descripcion: `SULFATO DE PLOMO con más de 3% de ácido libre` },
    { clave: `1796`, descripcion: `ACIDO NITRANTE (ACIDO MIXTO), MEZCLA DE, con más de 50% ácido nítrico` },
    { clave: `1796`, descripcion: `ACIDO NITRANTE (ACIDO MIXTO), MEZCLA DE, con no más de 50% ácido nítrico` },
    { clave: `1798`, descripcion: `ACIDO NITROCLORHIDRICO` },
    { clave: `1799`, descripcion: `NONILTRICLOROSILANO` },
    { clave: `1800`, descripcion: `OCTADECILTRICLOROSILANO` },
    { clave: `1801`, descripcion: `OCTILTRICLOROSILANO` },
    { clave: `1802`, descripcion: `ACIDO PERCLORICO con un máximo de 50%, en masa, de ácido` },
    { clave: `1803`, descripcion: `ACIDO FENOLSULFONICO LIQUIDO` },
    { clave: `1804`, descripcion: `FENILTRICLOROSILANO` },
    { clave: `1805`, descripcion: `ÁCIDO FOSFÓRICO EN SOLUCIÓN` },
    { clave: `1806`, descripcion: `PENTACLORURO DE FOSFORO` },
    { clave: `1807`, descripcion: `PENTÓXIDO DE FÓSFORO (ANHIDRIDO FOSFÓRICO)` },
    { clave: `1808`, descripcion: `TRIBROMURO DE FOSFORO` },
    { clave: `1809`, descripcion: `TRICLORURO DE FOSFORO` },
    { clave: `1810`, descripcion: `OXICLORURO DE FOSFORO` },
    { clave: `1811`, descripcion: `HIDROGENODIFLUORURO DE POTASIO` },
    { clave: `1812`, descripcion: `FLUORURO DE POTASIO SOLIDO` },
    { clave: `1813`, descripcion: `HIDROXIDO DE POTASICO SOLIDO` },
    { clave: `1814`, descripcion: `HIDROXIDO DE POTASICO EN SOLUCION` },
    { clave: `1815`, descripcion: `CLORURO DE PROPIONILO` },
    { clave: `1816`, descripcion: `PROPILTRICLOROSILANO` },
    { clave: `1817`, descripcion: `CLORURO DE PIROSULFURILO` },
    { clave: `1818`, descripcion: `TETRACLORURO DE SILICIO` },
    { clave: `1819`, descripcion: `ALUMINATO DE SODIO EN SOLUCION` },
    { clave: `1823`, descripcion: `HIDROXIDO DE SODIO SOLIDO` },
    { clave: `1824`, descripcion: `HIDROXIDO DE SODIO EN SOLUCION` },
    { clave: `1825`, descripcion: `MONOXIDO DE SODIO` },
    { clave: `1826`, descripcion: `ACIDO NITRANTE (ACIDO MIXTO) AGOTADO, MEZCLA DE, con más de 50% ácido nítrico` },
    { clave: `1826`, descripcion: `ACIDO NITRANTE (ACIDO MIXTO) AGOTADO, MEZCLA DE, con no más de 50% ácido nítrico` },
    { clave: `1827`, descripcion: `CLORURO ESTANICO ANHIDRO` },
    { clave: `1828`, descripcion: `CLORUROS DE AZUFRE` },
    { clave: `1829`, descripcion: `TRIOXIDO DE AZUFRE ESTABILIZADO` },
    { clave: `1830`, descripcion: `ACIDO SULFURICO con más de 51% de ácido` },
    { clave: `1831`, descripcion: `ACIDO SULFURICO FUMANTE` },
    { clave: `1832`, descripcion: `ACIDO SULFURICO AGOTADO` },
    { clave: `1833`, descripcion: `ACIDO SULFUROSO` },
    { clave: `1834`, descripcion: `CLORURO DE SULFURILO` },
    { clave: `1835`, descripcion: `HIDROXIDO DE TETRAMETILAMONIO EN SOLUCION` },
    { clave: `1836`, descripcion: `CLORURO DE TIONILO` },
    { clave: `1837`, descripcion: `CLORURO DE TIOFOSFORILO` },
    { clave: `1838`, descripcion: `TETRACLORURO DE TITANIO` },
    { clave: `1839`, descripcion: `ACIDO TRICLOROACETICO` },
    { clave: `1840`, descripcion: `CLORURO DE ZINC (CINC)  EN SOLUCION` },
    { clave: `1841`, descripcion: `ALDEHIDATO AMONICO` },
    { clave: `1843`, descripcion: `DINITRO-o-CRESOLATO AMONICO, SOLIDO` },
    { clave: `1845`, descripcion: `DIOXIDO DE CARBONO SOLIDO (HIELO SECO)` },
    { clave: `1846`, descripcion: `TETRACLORURO DE CARBONO` },
    { clave: `1847`, descripcion: `SULFURO DE POTASICO HIDRATADO con un mínimo de 30% de agua de cristalización` },
    { clave: `1848`, descripcion: `ACIDO PROPIONICO, con no menos de 10% o menor de 90% de ácido en masa` },
    { clave: `1849`, descripcion: `SULFURO DE SODIO HIDRATADO con un mínimo de 30% de agua` },
    { clave: `1851`, descripcion: `MEDICAMENTO LIQUIDO, TOXICO N.E.P.` },
    { clave: `1854`, descripcion: `BARIO, ALEACIONES PIROFORICAS DE` },
    { clave: `1855`, descripcion: `CALCIO PIROFORICO o CALCIO, ALEACIONES PIROFORICAS DE` },
    { clave: `1856`, descripcion: `TRAPOS GRASIENTOS` },
    { clave: `1857`, descripcion: `DESECHOS TEXTILES HUMEDOS` },
    { clave: `1858`, descripcion: `HEXAFLUOROPROPILENO  (GAS REFRIGERANTE R 1216)` },
    { clave: `1859`, descripcion: `TETRAFLUORURO DE SILICIO COMPRIMIDO` },
    { clave: `1860`, descripcion: `FLUORURO DE VINILO ESTABILIZADO` },
    { clave: `1862`, descripcion: `CROTONATO DE ETILO` },
    { clave: `1863`, descripcion: `COMBUSTIBLE PARA MOTORES DE TURBINA DE AVIACION` },
    { clave: `1865`, descripcion: `NITRATO DE n-PROPILO` },
    { clave: `1866`, descripcion: `RESINA, SOLUCIONES DE, inflamables` },
    { clave: `1868`, descripcion: `DECABORANO` },
    { clave: `1869`, descripcion: `MAGNESIO o ALEACIONES DE MAGNESIO con más de 50% de magnesio en recortes, gránulos o tiras` },
    { clave: `1870`, descripcion: `BOROHIDRURO DE POTASICO` },
    { clave: `1871`, descripcion: `HIDRURO DE TITANIO` },
    { clave: `1872`, descripcion: `DIOXIDO DE PLOMO` },
    { clave: `1873`, descripcion: `ACIDO PERCLORICO con más de 50% pero no más de 72%, en masa, de ácido` },
    { clave: `1884`, descripcion: `OXIDO BARICO` },
    { clave: `1885`, descripcion: `BENCIDINA` },
    { clave: `1886`, descripcion: `CLORURO DE BENCILIDENO` },
    { clave: `1887`, descripcion: `BROMOCLOROMETANO` },
    { clave: `1888`, descripcion: `CLOROFORMO` },
    { clave: `1889`, descripcion: `BROMURO DE CIANOGENO` },
    { clave: `1891`, descripcion: `BROMURO DE ETILO` },
    { clave: `1892`, descripcion: `ETILDICLOROARSINA` },
    { clave: `1894`, descripcion: `HIDROXIDO FENILMERCURICO` },
    { clave: `1895`, descripcion: `NITRATO FENILMERCURICO` },
    { clave: `1897`, descripcion: `TETRACLOROETILENO` },
    { clave: `1898`, descripcion: `YODURO DE ACETILO` },
    { clave: `1902`, descripcion: `FOSFATO ACIDO DE DIISOOCTILO` },
    { clave: `1905`, descripcion: `ACIDO SELENICO` },
    { clave: `1906`, descripcion: `LODOS ACIDOS` },
    { clave: `1907`, descripcion: `CAL SODADA con más de 4% de hidróxido SODIO` },
    { clave: `1908`, descripcion: `CLORITOS EN SOLUCION` },
    { clave: `1910`, descripcion: `OXIDO DE CALCIO` },
    { clave: `1911`, descripcion: `DIBORANO  COMPRIMIDO` },
    { clave: `1912`, descripcion: `MEZCLAS DE CLORURO DE METILO Y CLORURO DE METILENO` },
    { clave: `1913`, descripcion: `NEON LIQUIDO REFRIGERADO` },
    { clave: `1914`, descripcion: `PROPIONATOS DE BUTILO` },
    { clave: `1915`, descripcion: `CICLOHEXANONA` },
    { clave: `1916`, descripcion: `ETER 2,2'-DICLORODIETILICO` },
    { clave: `1917`, descripcion: `ACRILATO DE ETILO ESTABILIZADO` },
    { clave: `1918`, descripcion: `ISOPROPILBENCENO` },
    { clave: `1919`, descripcion: `ACRILATO DE METILO ESTABILIZADO` },
    { clave: `1920`, descripcion: `NONANOS` },
    { clave: `1921`, descripcion: `PROPILENIMINA ESTABILIZADA` },
    { clave: `1922`, descripcion: `PIRROLIDINA` },
    { clave: `1923`, descripcion: `DITIONITO CALCICO (HIDROSULFITO CALCICO)` },
    { clave: `1928`, descripcion: `BROMURO DE METILMAGNESIO EN ETER ETILICO` },
    { clave: `1929`, descripcion: `DITIONITO  POTASICO (HIDROSULFITO POTASICO)` },
    { clave: `1931`, descripcion: `DITIONITO DE ZINC (CINC) (HIDROSULFITO DE CINC)` },
    { clave: `1932`, descripcion: `CIRCONIO, DESECHOS DE` },
    { clave: `1935`, descripcion: `CIANURO EN SOLUCION, N.E.P.` },
    { clave: `1938`, descripcion: `ACIDO BROMOACETICO` },
    { clave: `1939`, descripcion: `OXIBROMURO DE FOSFORO` },
    { clave: `1940`, descripcion: `ACIDO TIOGLICOLICO` },
    { clave: `1941`, descripcion: `DIBROMODIFLUOMETANO` },
    { clave: `1942`, descripcion: `NITRATO DE AMONIO con un máximo de 0,2% del material combustible, incluyendo cualquier substancia orgánica expresada en equivalente de carbono, con exclusión de cualquier otra substancia añadida` },
    { clave: `1944`, descripcion: `FÓSFOROS   DE    SEGURIDAD    (en estuches, cartones o cajas)` },
    { clave: `1945`, descripcion: `FÓSFOROS DE CERA "VESTA"` },
    { clave: `1950`, descripcion: `AEROSOLES` },
    { clave: `1951`, descripcion: `ARGON LIQUIDO REFRIGERADO` },
    { clave: `1952`, descripcion: `MEZCLA DE OXIDO DE ETILENO Y DIOXIDO DE CARBONO con un máximo de 9% de óxido de etileno` },
    { clave: `1953`, descripcion: `GAS COMPRIMIDO TOXICO, INFLAMABLE, N.E.P.` },
    { clave: `1954`, descripcion: `GAS COMPRIMIDO INFLAMABLE, N.E.P.` },
    { clave: `1955`, descripcion: `GAS COMPRIMIDO TOXICO, N.E.P.` },
    { clave: `1956`, descripcion: `GAS COMPRIMIDO, N.E.P.` },
    { clave: `1957`, descripcion: `DEUTERIO COMPRIMIDO` },
    { clave: `1958`, descripcion: `1,2-DICLORO-1,1,2,2-TETRAFLUORETANO (GAS REFRIGERANTE R 114)` },
    { clave: `1959`, descripcion: `1,1-DIFLUORETILENO (GAS REFRIGERANTE R 1132ª)` },
    { clave: `1961`, descripcion: `ETANO LIQUIDO REFRIGERADO` },
    { clave: `1962`, descripcion: `ETILENO` },
    { clave: `1963`, descripcion: `HELIO LIQUIDO REFRIGERADO` },
    { clave: `1964`, descripcion: `MEZCLA DE HIDROCARBUROS GASEOSOS COMPRIMIDOS, N.E.P.` },
    { clave: `1965`, descripcion: `MEZCLA DE HIDROCARBUROS GASEOSOS LICUADOS, N.E.P.` },
    { clave: `1966`, descripcion: `HIDROGENO LIQUIDO REFRIGERADO` },
    { clave: `1967`, descripcion: `INSECTICIDA GASEOSO TOXICO, N.E.P.` },
    { clave: `1968`, descripcion: `INSECTICIDA GASEOSO, N.E.P.` },
    { clave: `1969`, descripcion: `ISOBUTANO` },
    { clave: `1970`, descripcion: `CRIPTON LIQUIDO REFRIGERADO` },
    { clave: `1971`, descripcion: `METANO COMPRIMIDO o GAS NATURAL COMPRIMIDO con alta proporción de metano` },
    { clave: `1972`, descripcion: `METANO LIQUIDO REFRIGERADO o GAS NATURAL LIQUIDO REFRIGERADO con alta proporción de metano` },
    { clave: `1973`, descripcion: `MEZCLAS DE CLORODIFLUOMETANO Y CLOROPENTAFLUORETANO de punto de ebullición constante, con alrededor de 49% de clorodifluometano (GAS REFRIGERANTE R 502)` },
    { clave: `1974`, descripcion: `CLORODIFLUOBROMOMETANO (GAS REFRIGERANTE R 12B1)` },
    { clave: `1975`, descripcion: `MEZCLA DE OXIDO NITRICO Y TETROXIDO DE DINITROGENO (MEZCLA DE OXIDO NITRICO Y DIOXIDO DE NITROGENO)` },
    { clave: `1976`, descripcion: `OCTAFLUOCICLOBUTANO (GAS REFRIGERANTE RC 318)` },
    { clave: `1977`, descripcion: `NITROGENO LIQUIDO REFRIGERADO` },
    { clave: `1978`, descripcion: `PROPANO` },
    { clave: `1982`, descripcion: `TETRAFLUOROMETANO COMPRIMIDO (GAS REFRIGERANTE R 14 COMPRIMIDO)` },
    { clave: `1983`, descripcion: `1-CLORO-2,2,2-TRIFLUORETANO (GAS REFRIGERANTE R 133ª)` },
    { clave: `1984`, descripcion: `TRIFLUOROMETANO (GAS REFRIGERANTE R 23)` },
    { clave: `1986`, descripcion: `ALCOHOLES TOXICOS INFLAMABLES, N.E.P.` },
    { clave: `1987`, descripcion: `ALCOHOLES, N.E.P.` },
    { clave: `1988`, descripcion: `ALDEHIDOS TOXICOS INFLAMABLES, N.E.P.` },
    { clave: `1989`, descripcion: `ALDEHIDOS, N.E.P.` },
    { clave: `1990`, descripcion: `BENZALDEHIDO` },
    { clave: `1991`, descripcion: `CLOROPRENO ESTABILIZADO` },
    { clave: `1992`, descripcion: `LIQUIDO INFLAMABLE, TOXICO, N.E.P` },
    { clave: `1993`, descripcion: `LIQUIDO INFLAMABLE, TOXICO, N.E.P` },
    { clave: `1994`, descripcion: `HIERRO PENTACARBONILO` },
    { clave: `1999`, descripcion: `ALQUITRANES LIQUIDOS, incluso los aglomerantes para carreteras y los asfaltos rebajados` },
    { clave: `2000`, descripcion: `CELULOIDE en bloques, barras, rollos, hojas, tubos, etc., excepto los desechos` },
    { clave: `2001`, descripcion: `NAFTENATOS DE COBALTO, EN POLVO` },
    { clave: `2002`, descripcion: `CELULOIDE, DESECHOS DE` },
    { clave: `2004`, descripcion: `DIAMIDA MAGNESICA` },
    { clave: `2006`, descripcion: `PLASTICOS A BASE DE NITROCELULOSA QUE EXPERIMENTAN CALENTAMIENTO ESPONTANEO N.E.P.` },
    { clave: `2008`, descripcion: `CIRCONIO EN POLVO, SECO` },
    { clave: `2009`, descripcion: `CIRCONIO SECO, en láminas, tiras o alambre` },
    { clave: `2010`, descripcion: `HIDRURO DE MAGNESIO` },
    { clave: `2011`, descripcion: `FOSFURO DE MAGNESIO` },
    { clave: `2012`, descripcion: `FOSFURO DE POTASIO` },
    { clave: `2013`, descripcion: `FOSFURO DE ESTRONCIO` },
    { clave: `2014`, descripcion: `PEROXIDO DE HIDROGENO EN SOLUCION ACUOSA con no menos de 20% y un máximo de 60% de peróxido de hidrógeno (estabilizada según sea necesario)` },
    { clave: `2015`, descripcion: `PEROXIDO DE HIDROGENO ESTABILIZADO, o PEROXIDO DE HIDROGENO EN SOLUCION ACUOSA ESTABILIZADA con más de 60% de peróxido de hidrógeno` },
    { clave: `2016`, descripcion: `MUNICIONES TOXICAS NO EXPLOSIVAS, sin carga detonante ni carga expulsora, sin cebo` },
    { clave: `2017`, descripcion: `MUNICIONES LACRIMOGENAS NO EXPLOSIVAS, sin carga detonante ni carga expulsora, sin cebo` },
    { clave: `2018`, descripcion: `CLOROANILINAS SOLIDAS` },
    { clave: `2019`, descripcion: `CLOROANILINAS LIQUIDAS` },
    { clave: `2020`, descripcion: `CLOROFENOLES SOLIDOS` },
    { clave: `2021`, descripcion: `CLOROFENOLES LIQUIDOS` },
    { clave: `2022`, descripcion: `ACIDO CRESILICO` },
    { clave: `2023`, descripcion: `EPICLORHIDRINA` },
    { clave: `2024`, descripcion: `MERCURIO, COMPUESTO LIQUIDO N.E.P` },
    { clave: `2025`, descripcion: `MERCURIO, COMPUESTO SOLIDO N.E.P.` },
    { clave: `2026`, descripcion: `FENILMERCURICO, COMPUESTO, N.E.P.` },
    { clave: `2027`, descripcion: `ARSENITO DE SODIO SOLIDO` },
    { clave: `2028`, descripcion: `BOMBAS FUMÍGENAS NO EXPLOSIVAS que contienen un líquido corrosivo, sin dispositivo de cebado` },
    { clave: `2029`, descripcion: `HIDRAZINA ANHIDRA` },
    { clave: `2030`, descripcion: `HIDRAZINA EN SOLUCION ACUOSA con más de 37%, en masa, de hidracina` },
    { clave: `2031`, descripcion: `ACIDO NITRICO, excepto el ácido nítrico fumante rojo, con más de 70% ácido nítrico` },
    { clave: `2031`, descripcion: `ACIDO NITRICO, excepto el ácido nítrico fumante rojo, con no más de 70% ácido nítrico` },
    { clave: `2032`, descripcion: `ACIDO NITRICO FUMANTE ROJO` },
    { clave: `2033`, descripcion: `MONOXIDO DE POTASICO` },
    { clave: `2034`, descripcion: `MEZCLA DE HIDROGENO Y METANO, COMPRIMIDAS` },
    { clave: `2035`, descripcion: `1,1,1‑TRIFLUOROETANO (GAS REFRIGERANTE R 143 a)` },
    { clave: `2036`, descripcion: `XENON` },
    { clave: `2037`, descripcion: `RECIPIENTES PEQUEÑOS QUE CONTIENEN GAS, (CARTUCHOS DE GAS) sin dispositivo de descarga, irrellenables` },
    { clave: `2038`, descripcion: `DINITROTOLUENOS LIQUIDOS` },
    { clave: `2044`, descripcion: `2,2-DIMETILPROPANO diferente del pentano y del isopentano` },
    { clave: `2045`, descripcion: `ISOBUTIRALDEHIDO (ALDEHIDO ISOBUTILICO)` },
    { clave: `2046`, descripcion: `CIMENOS` },
    { clave: `2048`, descripcion: `DICICLOPENTADIENO` },
    { clave: `2049`, descripcion: `DIETILBENCENO` },
    { clave: `2050`, descripcion: `DIISOBUTILENO, COMPUESTOS ISOMERICOS DEL` },
    { clave: `2051`, descripcion: `2‑DIMETILAMINOETANOL` },
    { clave: `2052`, descripcion: `DIPENTENO` },
    { clave: `2053`, descripcion: `METILISOBUTILCARBINOL` },
    { clave: `2054`, descripcion: `MORFOLINA` },
    { clave: `2055`, descripcion: `ESTIRENO MONOMERO ESTABILIZADO` },
    { clave: `2056`, descripcion: `TETRAHIDROFURANO` },
    { clave: `2057`, descripcion: `TRIPROPILENO` },
    { clave: `2058`, descripcion: `VALERILALDEHIDO` },
    { clave: `2059`, descripcion: `NITROCELULOSA EN SOLUCION INFLAMABLE con no más de 12,6%, en masa, de nitrógeno y no más de 55% de nitrocelulosa` },
    { clave: `2067`, descripcion: `ABONOS A BASE DE NITRATO DE AMONICO` },
    { clave: `2071`, descripcion: `ABONOS A BASE DE NITRATO DE AMONICO` },
    { clave: `2073`, descripcion: `AMONIACO EN SOLUCION acuosa de densidad relativa inferior a 0,880 a 15°C, con más de 35% pero no más de 50% de amoníaco` },
    { clave: `2074`, descripcion: `ACRILAMIDA SOLIDA` },
    { clave: `2075`, descripcion: `CLORAL ANHIDRO ESTABILIZADO` },
    { clave: `2076`, descripcion: `CRESOLES LIQUIDOS` },
    { clave: `2077`, descripcion: `alfa-NAFTILAMINA` },
    { clave: `2078`, descripcion: `DIISOCIANATO DE TOLUENO, véase` },
    { clave: `2079`, descripcion: `DIETILENTRIAMINA` },
    { clave: `2186`, descripcion: `CLORURO DE HIDROGENO LIQUIDO REFRIGERADO` },
    { clave: `2187`, descripcion: `DIOXIDO DE CARBONO LIQUIDO REFRIGERADO` },
    { clave: `2188`, descripcion: `ARSINA` },
    { clave: `2189`, descripcion: `DICLOROSILANO` },
    { clave: `2190`, descripcion: `DIFLUORURO DE OXÍGENO, COMPRIMIDO` },
    { clave: `2191`, descripcion: `FLUORURO DE SULFURILO` },
    { clave: `2192`, descripcion: `GERMANIO` },
    { clave: `2193`, descripcion: `HEXAFLUORETANO (GAS REFRIGERANTE R 116)` },
    { clave: `2194`, descripcion: `HEXAFLUORURO DE SELENIO` },
    { clave: `2195`, descripcion: `HEXAFLUORURO DE TELURIO` },
    { clave: `2196`, descripcion: `HEXAFLUORURO DE TUNGSTENO` },
    { clave: `2197`, descripcion: `YODURO DE HIDROGENO ANHIDRO` },
    { clave: `2198`, descripcion: `PENTAFLUORURO DE FOSFORO` },
    { clave: `2199`, descripcion: `FOSFAMINA (FOSFINA)` },
    { clave: `2200`, descripcion: `PROPADIENO ESTABILIZADO` },
    { clave: `2201`, descripcion: `OXIDO NITROSO LIQUIDO REFRIGERADO` },
    { clave: `2202`, descripcion: `SELENIURO DE HIDROGENO ANHIDRO` },
    { clave: `2203`, descripcion: `SILANO` },
    { clave: `2204`, descripcion: `SULFURO DE CARBONILO` },
    { clave: `2205`, descripcion: `ADIPONITRILO` },
    { clave: `2206`, descripcion: `ISOCIANATOS TOXICOS, N.E.P. o ISOCIANATOS EN SOLUCION, TOXICOS, N.E.P.` },
    { clave: `2208`, descripcion: `HIPOCLORITO DE CALCICO EN MEZCLA SECA con más de 10% pero no más de 39% de cloro activo` },
    { clave: `2209`, descripcion: `FORMALDEHIDO EN SOLUCION con un mínimo del 25% de formaldehído` },
    { clave: `2210`, descripcion: `MANEB o PREPARADOS DE MANEB, con un mínimo de 60% de maneb` },
    { clave: `2211`, descripcion: `POLIMERO EN BOLITAS DILATABLES que desprenden vapores inflamables` },
    { clave: `2212`, descripcion: `ASBESTO AZUL (crocidolita) o ASBESTO MARRÓN (amosita, misorita)` },
    { clave: `2213`, descripcion: `PARAFORMALDEHIDO` },
    { clave: `2214`, descripcion: `ANHIDRIDO FTALICO con más de 0,05% de anhídrido maleico` },
    { clave: `2215`, descripcion: `ANHIDRIDO MALEICO` },
    { clave: `2216`, descripcion: `HARINA DE PESCADO (DESECHOS DE PESCADO) ESTABILIZADA` },
    { clave: `2217`, descripcion: `TORTA OLEAGINOSA con el 1,5% de aceite y el 11% de humedad como máximo` },
    { clave: `2218`, descripcion: `ACIDO ACRILICO ESTABILIZADO` },
    { clave: `2219`, descripcion: `ETER ALILGLICIDILICO (ALIL GLICIDIL ETER)` },
    { clave: `2222`, descripcion: `ANISOL` },
    { clave: `2224`, descripcion: `BENZONITRILO` },
    { clave: `2225`, descripcion: `CLORURO DE BENCENOSULFONILO` },
    { clave: `2226`, descripcion: `BENZOTRICLORURO` },
    { clave: `2227`, descripcion: `METACRILATO DE n-BUTILO ESTABILIZADO` },
    { clave: `2232`, descripcion: `2‑CLOROETANAL` },
    { clave: `2233`, descripcion: `CLOROANISIDINAS` },
    { clave: `2234`, descripcion: `CLOROBENZOTRIFLUORUROS` },
    { clave: `2235`, descripcion: `CLORUROS DE CLOROBENCILO, LIQUIDOS` },
    { clave: `2236`, descripcion: `ISOCIANATO DE 3-CLORO-4-METILFENILO,LIQUIDO` },
    { clave: `2237`, descripcion: `CLORONITROANILINAS` },
    { clave: `2238`, descripcion: `CLOROTOLUENOS` },
    { clave: `2239`, descripcion: `CLOROTOLUIDINAS SOLIDAS` },
    { clave: `2240`, descripcion: `ACIDO CROMOSULFURICO` },
    { clave: `2241`, descripcion: `CICLOHEPTANO` },
    { clave: `2242`, descripcion: `CICLOHEPTENO` },
    { clave: `2243`, descripcion: `ACETATO DE CICLOHEXILO` },
    { clave: `2244`, descripcion: `CICLOPENTANOL` },
    { clave: `2245`, descripcion: `CICLOPENTANONA` },
    { clave: `2246`, descripcion: `CICLOPENTENO` },
    { clave: `2247`, descripcion: `n-DECANO` },
    { clave: `2248`, descripcion: `DI-n-BUTILAMINA` },
    { clave: `2249`, descripcion: `ETER DICLORODIMETILICO SIMETRICO` },
    { clave: `2250`, descripcion: `ISOCIANATOS DE DICLOROFENILO` },
    { clave: `2251`, descripcion: `BICICLO [2.2.1] HEPTA‑2,5‑DIENO ESTABILIZADO (2,5-NORBORNADIENO ESTABILIZADO)` },
    { clave: `2252`, descripcion: `1,2-DIMETOXIETANO` },
    { clave: `2253`, descripcion: `N,N-DIMETILANILINA` },
    { clave: `2254`, descripcion: `FOSFOROS RESISTENTES AL VIENTO` },
    { clave: `2256`, descripcion: `CICLOHEXENO` },
    { clave: `2257`, descripcion: `POTASIO` },
    { clave: `2258`, descripcion: `1,2-PROPILENDIAMINA` },
    { clave: `2259`, descripcion: `TRIETILENTETRAMINA` },
    { clave: `2260`, descripcion: `TRIPROPILAMINA` },
    { clave: `2261`, descripcion: `XILENOLES SOLIDOS` },
    { clave: `2262`, descripcion: `CLORURO DE DIMETILCARBAMOILO` },
    { clave: `2263`, descripcion: `DIMETILCICLOHEXANOS` },
    { clave: `2264`, descripcion: `N,N DIMETIL-CICLOHEXILAMINA` },
    { clave: `2265`, descripcion: `N,N-DIMETILFORMAMIDA` },
    { clave: `2266`, descripcion: `DIMETIL-N-PROPILAMINA` },
    { clave: `2267`, descripcion: `CLORURO DE DIMETILTIOFOSFORILO` },
    { clave: `2269`, descripcion: `3,3' IMINODIPROPILAMINA` },
    { clave: `2270`, descripcion: `ETILAMINA EN SOLUCION ACUOSA con no menos de 50% pero no más de 70% de etilamina` },
    { clave: `2271`, descripcion: `ETILAMILCETONA` },
    { clave: `2272`, descripcion: `N-ETILANILINA` },
    { clave: `2273`, descripcion: `2-ETILANILINA` },
    { clave: `2274`, descripcion: `N-ETIL-N-BENCILANILINA` },
    { clave: `2275`, descripcion: `2-ETILBUTANOL` },
    { clave: `2276`, descripcion: `2-ETILHEXILAMINA` },
    { clave: `2277`, descripcion: `METACRILATO DE ETILO ESTABILIZADO` },
    { clave: `2278`, descripcion: `n-HEPTENO` },
    { clave: `2279`, descripcion: `HEXACLOROBUTADIENO` },
    { clave: `2280`, descripcion: `HEXAMETILENDIAMINA SOLIDA` },
    { clave: `2281`, descripcion: `DIISOCIANATO DE HEXAMETILENO` },
    { clave: `2282`, descripcion: `HEXANOLES` },
    { clave: `2283`, descripcion: `METACRILATO DE ISOBUTILO ESTABILIZADO` },
    { clave: `2284`, descripcion: `ISOBUTIRONITRILO` },
    { clave: `2285`, descripcion: `ISOCIANATOBENZOTRIFLUORUROS` },
    { clave: `2286`, descripcion: `PENTAMETILHEPTANO` },
    { clave: `2287`, descripcion: `ISOHEPTENOS` },
    { clave: `2288`, descripcion: `ISOHEXENOS` },
    { clave: `2289`, descripcion: `ISOFORONDIAMINA` },
    { clave: `2290`, descripcion: `DIISOCIANATO DE ISOFORONA` },
    { clave: `2291`, descripcion: `COMPUESTO DE PLOMO SOLUBLE, N.E.P.` },
    { clave: `2293`, descripcion: `4-METOXI-4-METIL-2-PENTANONA` },
    { clave: `2294`, descripcion: `N-METILANILINA` },
    { clave: `2295`, descripcion: `CLOROACETATO DE METILO` },
    { clave: `2296`, descripcion: `METILCICLOHEXANO` },
    { clave: `2297`, descripcion: `METILCICLOHEXANONA` },
    { clave: `2298`, descripcion: `METILCICLOPENTANO` },
    { clave: `2299`, descripcion: `DICLOROACETATO DE METILO` },
    { clave: `2300`, descripcion: `2-METIL-5-ETILPIRIDINA` },
    { clave: `2301`, descripcion: `2-METILFURANO` },
    { clave: `2302`, descripcion: `5-METIL-2-HEXANONA` },
    { clave: `2303`, descripcion: `ISOPROPENILBENCENO` },
    { clave: `2304`, descripcion: `NAFTALENO FUNDIDO` },
    { clave: `2305`, descripcion: `ACIDO NITROBENCENOSULFONICO` },
    { clave: `2306`, descripcion: `NITROBENZOTRIFLUORUROS LIQUIDOS` },
    { clave: `2307`, descripcion: `3-NITRO-4-CLOROBENZOTRIFLUORURO` },
    { clave: `2308`, descripcion: `ACIDO NITROSILSULFURICO LIQUIDO` },
    { clave: `2309`, descripcion: `OCTADIENO` },
    { clave: `2310`, descripcion: `PENTANO‑2,4‑DIENO` },
    { clave: `2311`, descripcion: `FENETIDINAS` },
    { clave: `2312`, descripcion: `FENOL FUNDIDO` },
    { clave: `2313`, descripcion: `PICOLINAS` },
    { clave: `2315`, descripcion: `DIFENILOS POLICLORADOS LIQUIDOS` },
    { clave: `2316`, descripcion: `CUPROCIANURO DE SODIO SOLIDO` },
    { clave: `2317`, descripcion: `CUPROCIANURO DE SODIO EN SOLUCION` },
    { clave: `2318`, descripcion: `HIDROSULFURO DE SODIO (SULFHIDRATO SODIO) con menos de 25% de agua de cristalización` },
    { clave: `2319`, descripcion: `HIDROCARBUROS TERPENICOS, N.E.P.` },
    { clave: `2320`, descripcion: `TETRAETILENPENTAMINA` },
    { clave: `2321`, descripcion: `TRICLOROBENCENOS LIQUIDOS` },
    { clave: `2322`, descripcion: `TRICLOROBUTENO` },
    { clave: `2323`, descripcion: `FOSFITO TRIETILICO` },
    { clave: `2324`, descripcion: `TRIISOBUTILENO` },
    { clave: `2325`, descripcion: `1,3,5-TRIMETILBENCENO` },
    { clave: `2326`, descripcion: `TRIMETILCICLOHEXILAMINA` },
    { clave: `2327`, descripcion: `TRIMETILHEXAMETILENDIAMINAS` },
    { clave: `2328`, descripcion: `DIISOCIANATO TRIMETILHEXAMETILENO` },
    { clave: `2329`, descripcion: `FOSFITO DE TRIMETILO` },
    { clave: `2330`, descripcion: `UNDECANO` },
    { clave: `2331`, descripcion: `CLORURO DE ZINC ANHIDRO` },
    { clave: `2332`, descripcion: `ACETALDOXIMA` },
    { clave: `2333`, descripcion: `ACETATO DE ALILO` },
    { clave: `2334`, descripcion: `ALILAMINA` },
    { clave: `2335`, descripcion: `ALIL ETIL ETER` },
    { clave: `2336`, descripcion: `FORMIATO DE ALILO` },
    { clave: `2337`, descripcion: `FENILMERCAPTANO` },
    { clave: `2338`, descripcion: `BENZOTRIFLUORURO` },
    { clave: `2339`, descripcion: `2-BROMOBUTANO` },
    { clave: `2340`, descripcion: `2-BROMOETIL ETIL ETER` },
    { clave: `2341`, descripcion: `1-BROMO-3-METILBUTANO` },
    { clave: `2342`, descripcion: `BROMOMETILPROPANOS` },
    { clave: `2343`, descripcion: `2-BROMOPENTANO` },
    { clave: `2344`, descripcion: `BROMOPROPANOS` },
    { clave: `2345`, descripcion: `3-BROMOPROPINO` },
    { clave: `2346`, descripcion: `BUTANODIONA` },
    { clave: `2347`, descripcion: `BUTILMERCAPTANO` },
    { clave: `2348`, descripcion: `ACRILATOS DE BUTILO ESTABILIZADOS` },
    { clave: `2350`, descripcion: `BUTIL METIL ETER` },
    { clave: `2351`, descripcion: `NITRITOS DE BUTILO` },
    { clave: `2352`, descripcion: `BUTIL VINIL ETER ESTABILIZADO` },
    { clave: `2353`, descripcion: `CLORURO DE BUTIRILO` },
    { clave: `2354`, descripcion: `CLOROMETIL ETIL ETER` },
    { clave: `2356`, descripcion: `2-CLOROPROPANO` },
    { clave: `2357`, descripcion: `CICLOHEXILAMINA` },
    { clave: `2358`, descripcion: `CICLOOCTATETRAENO` },
    { clave: `2359`, descripcion: `DIALILAMINA` },
    { clave: `2360`, descripcion: `ETER DIALILICO` },
    { clave: `2361`, descripcion: `DIISOBUTILAMINA` },
    { clave: `2362`, descripcion: `1,1-DICLOROETANO` },
    { clave: `2363`, descripcion: `ETILMERCAPTANO` },
    { clave: `2364`, descripcion: `n-PROPILBENCENO` },
    { clave: `2366`, descripcion: `CARBONATO DE DIETILO` },
    { clave: `2367`, descripcion: `alfa-METILVALERALDEHIDO` },
    { clave: `2368`, descripcion: `alfa-PINENO` },
    { clave: `2370`, descripcion: `1-HEXENO` },
    { clave: `2371`, descripcion: `ISOPENTENOS` },
    { clave: `2372`, descripcion: `1,2-DI-(DIMETILAMINO) ETANO` },
    { clave: `2373`, descripcion: `DIETOXIMETANO` },
    { clave: `2374`, descripcion: `3,3-DIETOXIPROPENO` },
    { clave: `2375`, descripcion: `SULFURO DE DIETILO` },
    { clave: `2376`, descripcion: `2,3-DIHIDROPIRANO` },
    { clave: `2377`, descripcion: `1,1-DIMETOXIETANO` },
    { clave: `2378`, descripcion: `2-DIMETILAMINOACETONITRILO` },
    { clave: `2379`, descripcion: `1,3-DIMETILBUTILAMINA` },
    { clave: `2380`, descripcion: `DIMETILDIETOXISILANO` },
    { clave: `2381`, descripcion: `DISULFURO DE DIMETILO` },
    { clave: `2382`, descripcion: `DIMETILHIDRAZINA SIMETRICA` },
    { clave: `2383`, descripcion: `DIPROPILAMINA` },
    { clave: `2384`, descripcion: `ETER DI-n-PROPILICO` },
    { clave: `2385`, descripcion: `ISOBUTIRATO DE ETILO` },
    { clave: `2386`, descripcion: `1-ETILPIPERIDINA` },
    { clave: `2387`, descripcion: `FLUOROBENCENO` },
    { clave: `2388`, descripcion: `FLUOROTOLUENOS` },
    { clave: `2389`, descripcion: `FURANO` },
    { clave: `2390`, descripcion: `2-YODOBUTANO` },
    { clave: `2391`, descripcion: `YODOMETILPROPANOS` },
    { clave: `2392`, descripcion: `YODOPROPANOS` },
    { clave: `2393`, descripcion: `FORMIATO DE ISOBUTILO` },
    { clave: `2394`, descripcion: `PROPIONATO DE ISOBUTILO` },
    { clave: `2395`, descripcion: `CLORURO DE ISOBUTIRILO` },
    { clave: `2396`, descripcion: `METACRILALDEHIDO ESTABILIZADO` },
    { clave: `2397`, descripcion: `3-METIL-2-BUTANONA` },
    { clave: `2398`, descripcion: `METIL-terc-BUTILETER` },
    { clave: `2399`, descripcion: `1-METILPIPERIDINA` },
    { clave: `2400`, descripcion: `ISOVALERATO DE METILO` },
    { clave: `2401`, descripcion: `PIPERIDINA` },
    { clave: `2402`, descripcion: `PROPANOTIOLES` },
    { clave: `2403`, descripcion: `ACETATO DE ISOPROPENILO` },
    { clave: `2404`, descripcion: `PROPIONITRILO` },
    { clave: `2405`, descripcion: `BUTIRATO DE ISOPROPILO` },
    { clave: `2406`, descripcion: `ISOBUTIRATO DE ISOPROPILO` },
    { clave: `2407`, descripcion: `CLOROFORMIATO DE ISOPROPILO` },
    { clave: `2409`, descripcion: `PROPIONATO DE ISOPROPILO` },
    { clave: `2410`, descripcion: `1,2,3,6-TETRAHIDROPIRIDINA` },
    { clave: `2411`, descripcion: `BUTIRONITRILO` },
    { clave: `2412`, descripcion: `TETRAHIDROTIOFENO` },
    { clave: `2413`, descripcion: `ORTOTITANATO TETRAPROPILICO` },
    { clave: `2414`, descripcion: `TIOFENO` },
    { clave: `2416`, descripcion: `BORATO DE TRIMETILO` },
    { clave: `2417`, descripcion: `FLUORURO DE CARBONILO` },
    { clave: `2418`, descripcion: `TETRAFLUORURO DE AZUFRE` },
    { clave: `2419`, descripcion: `BROMOTRIFLUORETILENO` },
    { clave: `2420`, descripcion: `HEXAFLUOROACETONA` },
    { clave: `2421`, descripcion: `TRIOXIDO DE NITROGENO` },
    { clave: `2422`, descripcion: `2-OCTAFLUOBUTENO (GAS REFRIGERANTE R 1318)` },
    { clave: `2424`, descripcion: `OCTAFLUOROPROPANO (GAS REFRIGERANTE R 218)` },
    { clave: `2426`, descripcion: `NITRATO DE AMONIO LIQUIDO (en solución concentrada caliente)` },
    { clave: `2427`, descripcion: `CLORATO DE POTASIO EN SOLUCION ACUOSA` },
    { clave: `2428`, descripcion: `CLORATO DE SODIO EN SOLUCION ACUOSA` },
    { clave: `2429`, descripcion: `CLORATO DE CALCIO EN SOLUCION ACUOSA` },
    { clave: `2430`, descripcion: `ALQUILFENOLES SOLIDOS, N.E.P. (incluidos los homólogos C2-C12)` },
    { clave: `2431`, descripcion: `ANISIDINAS` },
    { clave: `2432`, descripcion: `N,N-DIETILANILINA` },
    { clave: `2433`, descripcion: `CLORONITROTOLUENOS LIQUIDOS` },
    { clave: `2434`, descripcion: `DIBENCILDICLOROSILANO` },
    { clave: `2435`, descripcion: `ETILFENILDICLOROSILANO` },
    { clave: `2436`, descripcion: `ACIDO TIOACETICO` },
    { clave: `2437`, descripcion: `METILFENILDICLOROSILANO` },
    { clave: `2438`, descripcion: `CLORURO DE TRIMETILACETILO` },
    { clave: `2439`, descripcion: `HIDROGENODIFLUORURO DE SODIO` },
    { clave: `2440`, descripcion: `CLORURO ESTANICO PENTAHIDRATADO` },
    { clave: `2441`, descripcion: `TRICLORURO DE TITANIO PIROFORICO, o TRICLORURO DE TITANIO PIROFORICO EN MEZCLA` },
    { clave: `2442`, descripcion: `CLORURO DE TRICLOROACETILO` },
    { clave: `2443`, descripcion: `OXITRICLORURO DE VANADIO` },
    { clave: `2444`, descripcion: `TETRACLORURO DE VANADIO` },
    { clave: `2446`, descripcion: `NITROCRESOLES SOLIDO` },
    { clave: `2447`, descripcion: `FOSFORO BLANCO FUNDIDO` },
    { clave: `2448`, descripcion: `AZUFRE FUNDIDO` },
    { clave: `2451`, descripcion: `TRIFLUORURO DE NITROGENO` },
    { clave: `2452`, descripcion: `ETILACETILENO ESTABILIZADO` },
    { clave: `2453`, descripcion: `FLUORURO DE ETILO (GAS REFRIGERANTE R 161)` },
    { clave: `2454`, descripcion: `FLUORURO DE METILO (GAS REFRIGERANTE R 41)` },
    { clave: `2455`, descripcion: `NITRITO DE METILO` },
    { clave: `2456`, descripcion: `2-CLOROPROPENO` },
    { clave: `2457`, descripcion: `2,3-DIMETILBUTANO` },
    { clave: `2458`, descripcion: `HEXADIENO` },
    { clave: `2459`, descripcion: `2-METIL-1-BUTENO` },
    { clave: `2460`, descripcion: `2-METIL-2-BUTENO` },
    { clave: `2461`, descripcion: `METILPENTADIENO` },
    { clave: `2463`, descripcion: `HIDRURO DE ALUMINICO` },
    { clave: `2464`, descripcion: `NITRATO DE BERILIO` },
    { clave: `2465`, descripcion: `ACIDO DICLOROISOCIANURICO SECO, o ACIDO DICLOROISOCIANURICO, SALES DEL` },
    { clave: `2466`, descripcion: `SUPEROXIDO DE POTASIO` },
    { clave: `2468`, descripcion: `ACIDO TRICLOROISOCIANURICO SECO` },
    { clave: `2469`, descripcion: `BROMATO DE ZINC` },
    { clave: `2470`, descripcion: `FENILACETONITRILO LIQUIDO` },
    { clave: `2471`, descripcion: `TETROXIDO DE OSMIO` },
    { clave: `2473`, descripcion: `ARSANILATO DE SODIO` },
    { clave: `2474`, descripcion: `TIOFOSGENO` },
    { clave: `2475`, descripcion: `TRICLORURO DE VANADIO` },
    { clave: `2477`, descripcion: `ISOTIOCIANATO DE METILO` },
    { clave: `2478`, descripcion: `ISOCIANATOS INFLAMABLES, TOXICOS, N.E.P., o ISOCIANATOS EN SOLUCION, INFLAMABLES, TOXICOS, N.E.P.` },
    { clave: `2480`, descripcion: `ISOCIANATO DE METILO` },
    { clave: `2481`, descripcion: `ISOCIANATO DE ETILO` },
    { clave: `2482`, descripcion: `ISOCIANATO DE n-PROPILO` },
    { clave: `2483`, descripcion: `ISOCIANATO DE ISOPROPILO` },
    { clave: `2484`, descripcion: `ISOCIANATO DE terc-BUTILO` },
    { clave: `2485`, descripcion: `ISOCIANATO DE n-BUTILO` },
    { clave: `2486`, descripcion: `ISOCIANATO DE ISOBUTILO` },
    { clave: `2487`, descripcion: `ISOCIANATO DE FENILO` },
    { clave: `2488`, descripcion: `ISOCIANATO DE CICLOHEXILO` },
    { clave: `2490`, descripcion: `ETER DICLOROISOPROPíLICO` },
    { clave: `2491`, descripcion: `ETANOLAMINA o ETANOLAMINA EN SOLUCION` },
    { clave: `2493`, descripcion: `HEXAMETILENIMINA` },
    { clave: `2495`, descripcion: `PENTAFLUORURO DE YODO` },
    { clave: `2496`, descripcion: `ANHIDRIDO PROPIONICO` },
    { clave: `2498`, descripcion: `1,2,3,6-TETRAHIDROBENZALDEHIDO` },
    { clave: `2501`, descripcion: `OXIDO DE TRI-(1-AZIRIDINIL) FOSFINA EN SOLUCION` },
    { clave: `2502`, descripcion: `CLORURO DE VALERILO` },
    { clave: `2503`, descripcion: `TETRACLORURO DE CIRCONIO` },
    { clave: `2504`, descripcion: `TETRABROMOETANO` },
    { clave: `2505`, descripcion: `FLUORURO DE AMONIO` },
    { clave: `2506`, descripcion: `SULFATO ACIDO DE AMONIO` },
    { clave: `2507`, descripcion: `ACIDO CLOROPLATINICO SOLIDO` },
    { clave: `2508`, descripcion: `PENTACLORURO DE MOLIBDENO` },
    { clave: `2509`, descripcion: `SULFATO ACIDO DE POTASIO` },
    { clave: `2511`, descripcion: `ACIDO 2‑CLOROPROPIONICO` },
    { clave: `2512`, descripcion: `AMINOFENOLES (o-, m-, p-)` },
    { clave: `2513`, descripcion: `BROMURO DE BROMOACETILO` },
    { clave: `2514`, descripcion: `BROMOBENCENO` },
    { clave: `2515`, descripcion: `BROMOFORMO` },
    { clave: `2516`, descripcion: `TETRABROMURO DE CARBONO` },
    { clave: `2517`, descripcion: `1‑CLORO‑1,1‑DIFLUOROETANO (GAS REFRIGERANTE R 142 b)` },
    { clave: `2518`, descripcion: `1,5,9-CICLODODECATRIENO` },
    { clave: `2520`, descripcion: `CICLOOCTADIENOS` },
    { clave: `2521`, descripcion: `DICETENO ESTABILIZADO` },
    { clave: `2522`, descripcion: `METACRILATO DE 2-DIMETILAMINOETILICO` },
    { clave: `2524`, descripcion: `ORTOFORMIATO DE ETILO` },
    { clave: `2525`, descripcion: `OXALATO DE ETILO` },
    { clave: `2526`, descripcion: `FURFURILAMINA` },
    { clave: `2527`, descripcion: `ACRILATO DE ISOBUTILO ESTABILIZADO` },
    { clave: `2528`, descripcion: `ISOBUTIRATO DE ISOBUTILO` },
    { clave: `2529`, descripcion: `ACIDO ISOBUTIRICO` },
    { clave: `2531`, descripcion: `ACIDO METACRILICO ESTABILIZADO` },
    { clave: `2533`, descripcion: `TRICLOROACETATO DE METILO` },
    { clave: `2534`, descripcion: `METILCLOROSILANO` },
    { clave: `2535`, descripcion: `4-METILMORFOLINA (N‑METILMORFOLINA)` },
    { clave: `2536`, descripcion: `METILTETRAHIDROFURANO` },
    { clave: `2538`, descripcion: `NITRONAFTALENO` },
    { clave: `2541`, descripcion: `TERPINOLENO` },
    { clave: `2542`, descripcion: `TRIBUTILAMINA` },
    { clave: `2545`, descripcion: `HAFNIO EN POLVO, SECO` },
    { clave: `2546`, descripcion: `TITANIO EN POLVO SECO` },
    { clave: `2547`, descripcion: `SUPEROXIDO DE  SODIO` },
    { clave: `2548`, descripcion: `PENTAFLUORURO DE CLORO` },
    { clave: `2552`, descripcion: `HIDRATO DE HEXAFLUORACETONA, LIQUIDO` },
    { clave: `2554`, descripcion: `CLORURO DE METILALILO` },
    { clave: `2555`, descripcion: `NITROCELULOSA  CON no menos de 25%, en masa, de AGUA.` },
    { clave: `2556`, descripcion: `NITROCELULOSA CON no menos de 25%, en masa, de ALCOHOL y un máximo de 12,6%, en masa seca, de nitrógeno` },
    { clave: `2557`, descripcion: `NITROCELULOSA, con no menos de 12,6%, en masa seca, de nitrógeno, MEZCLA CON o SIN PLASTIFICANTE, CON o SIN PIGMENTO` },
    { clave: `2558`, descripcion: `EPIBROMHIDRINA` },
    { clave: `2560`, descripcion: `2-METIL-2-PENTANOL` },
    { clave: `2561`, descripcion: `3-METIL-1-BUTENO` },
    { clave: `2564`, descripcion: `ACIDO TRICLOROACETICO EN SOLUCION` },
    { clave: `2565`, descripcion: `DICICLOHEXILAMINA` },
    { clave: `2567`, descripcion: `PENTACLOROFENATO DE SODIO` },
    { clave: `2570`, descripcion: `CADMIO, COMPUESTO DE` },
    { clave: `2571`, descripcion: `ACIDOS ALQUILSULFURICOS` },
    { clave: `2572`, descripcion: `FENILHIDRAZINA` },
    { clave: `2573`, descripcion: `CLORATO DE TALIO` },
    { clave: `2574`, descripcion: `FOSFATO DE TRICRESILO con más de 3% de isómero orto` },
    { clave: `2576`, descripcion: `OXIBROMURO DE FOSFORO, FUNDIDO` },
    { clave: `2577`, descripcion: `CLORURO DE FENILACETILO` },
    { clave: `2578`, descripcion: `TRIOXIDO DE FOSFORO` },
    { clave: `2579`, descripcion: `PIPERAZINA` },
    { clave: `2580`, descripcion: `BROMURO DE ALUMINIO EN SOLUCION` },
    { clave: `2581`, descripcion: `CLORURO DE ALUMINIO EN SOLUCION` },
    { clave: `2582`, descripcion: `CLORURO FERRICO EN SOLUCION` },
    { clave: `2583`, descripcion: `ACIDOS ALQUILSULFONICOS SOLIDOS o ACIDOS ARILSULFONICOS SOLIDOS, con más de 5% de ácido sulfúrico libre` },
    { clave: `2584`, descripcion: `ACIDOS ALQUILSULFONICOS LIQUIDOS o ACIDOS ARILSULFONICOS LIQUIDOS, con más de 5% de ácido sulfúrico libre` },
    { clave: `2585`, descripcion: `ACIDOS ALQUILSULFONICOS SOLIDOS o ACIDOS ARILSULFONICOS SOLIDOS, con un máximo de 5% de ácido sulfúrico libre` },
    { clave: `2586`, descripcion: `ACIDOS ALQUILSULFONICOS LIQUIDOS o ACIDOS ARILSULFONICOS LIQUIDOS, con un máximo de 5% de ácido sulfúrico libre` },
    { clave: `2587`, descripcion: `BENZOQUINONA` },
    { clave: `2588`, descripcion: `PLAGUICIDA  SOLIDO,TOXICO, N.E.P.` },
    { clave: `2589`, descripcion: `CLOROACETATO DE VINILO` },
    { clave: `2590`, descripcion: `ASBESTO BLANCO (crisotilo, actinolita, antofilita, tremolita)` },
    { clave: `2591`, descripcion: `XENON LIQUIDO REFRIGERADO` },
    { clave: `2599`, descripcion: `CLOROTRIFLUOROMETANO Y TRIFLUOROMETANO EN MEZCLA AZEOTROPICA con aproximadamente el 60% de clorotrifluorometano (GAS REFRIGERANTE R 503)` },
    { clave: `2601`, descripcion: `CICLOBUTANO` },
    { clave: `2602`, descripcion: `DICLORODIFLUOMETANO Y DIFLUORETANO EN MEZCLA AZEOTROPICA con aproximadamente el 74% de diclorodifluometano (GAS REFRIGERANTE R 500)` },
    { clave: `2603`, descripcion: `CICLOHEPTATRIENO` },
    { clave: `2604`, descripcion: `DIETILETERATO DE TRIFLUORURO DE BORO` },
    { clave: `2605`, descripcion: `ISOCIANATO DE METOXIMETILO` },
    { clave: `2606`, descripcion: `ORTOSILICATO DE METILO` },
    { clave: `2607`, descripcion: `DIMERO DE LA ACROLEINA ESTABILIZADO` },
    { clave: `2608`, descripcion: `NITROPROPANOS` },
    { clave: `2609`, descripcion: `BORATO DE TRIALILO` },
    { clave: `2610`, descripcion: `TRIALILAMINA` },
    { clave: `2611`, descripcion: `CLORHIDRINA  PROPILENICA` },
    { clave: `2612`, descripcion: `METIL PROPIL ETER` },
    { clave: `2614`, descripcion: `ALCOHOL METALILICO` },
    { clave: `2615`, descripcion: `ETIL PROPIL ETER` },
    { clave: `2616`, descripcion: `BORATO DE TRIISOPROPILO` },
    { clave: `2617`, descripcion: `METILCICLOHEXANOLES inflamables` },
    { clave: `2618`, descripcion: `VINILTOLUENOS ESTABILIZADOS` },
    { clave: `2619`, descripcion: `BENCILDIMETILAMINA` },
    { clave: `2620`, descripcion: `BUTIRATOS DE AMILO` },
    { clave: `2621`, descripcion: `ACETILMETILCARBINOL` },
    { clave: `2622`, descripcion: `GLICIDALDEHIDO` },
    { clave: `2623`, descripcion: `YESCAS SOLIDAS con un líquido inflamable` },
    { clave: `2624`, descripcion: `SILICIURO DE MAGNESIO` },
    { clave: `2626`, descripcion: `ACIDO CLÓRICO EN SOLUCIÓN ACUOSA con un máximo del 10% de ácido clórico` },
    { clave: `2627`, descripcion: `NITRITOS INORGANICOS, N.E.P.` },
    { clave: `2628`, descripcion: `FLUOROACETATO DE POTASIO` },
    { clave: `2629`, descripcion: `FLUOROACETATO DE SODIO` },
    { clave: `2630`, descripcion: `SELENIATOS o SELENITOS` },
    { clave: `2642`, descripcion: `ACIDO FLUOROACETICO` },
    { clave: `2643`, descripcion: `BROMOACETATO DE METILO` },
    { clave: `2644`, descripcion: `YODURO DE METILO` },
    { clave: `2645`, descripcion: `BROMURO DE FENACILO` },
    { clave: `2646`, descripcion: `HEXACLOROCICLOPENTADIENO` },
    { clave: `2647`, descripcion: `MALONONITRILO` },
    { clave: `2648`, descripcion: `1,2-DIBROMO-3-BUTANONA` },
    { clave: `2649`, descripcion: `1,3-DICLOROACETONA` },
    { clave: `2650`, descripcion: `1,1-DICLORO-1-NITROETANO` },
    { clave: `2651`, descripcion: `4,4'-DIAMINODIFENILMETANO` },
    { clave: `2653`, descripcion: `YODURO DE BENCILO` },
    { clave: `2655`, descripcion: `FLUOROSILICATO DE POTASIO` },
    { clave: `2656`, descripcion: `QUINOLEINA` },
    { clave: `2657`, descripcion: `DISULFURO DE SELENIO` },
    { clave: `2659`, descripcion: `CLOROACETATO DE SODIO` },
    { clave: `2660`, descripcion: `NITROTOLUIDINAS (MONO)` },
    { clave: `2661`, descripcion: `HEXACLOROACETONA` },
    { clave: `2664`, descripcion: `DIBROMOMETANO` },
    { clave: `2667`, descripcion: `BUTILTOLUENOS` },
    { clave: `2668`, descripcion: `CLOROACETONITRILO` },
    { clave: `2669`, descripcion: `CLOROCRESOLES EN SOLUCION` },
    { clave: `2670`, descripcion: `CLORURO  CIANÚRICO` },
    { clave: `2671`, descripcion: `AMINOPIRIDINAS (o-, m-, p-)` },
    { clave: `2672`, descripcion: `AMONIACO EN SOLUCION acuosa de densidad relativa comprendida entre 0,880 y 0,957 a 15°C, con más de 10% pero no más de 35% de amoníaco` },
    { clave: `2673`, descripcion: `2-AMINO-4-CLOROFENOL` },
    { clave: `2674`, descripcion: `FLUOROSILICATO DE SODIO` },
    { clave: `2676`, descripcion: `ESTIBINA` },
    { clave: `2677`, descripcion: `HIDROXIDO DE RUBIDIO EN SOLUCION` },
    { clave: `2678`, descripcion: `HIDROXIDO DE RUBIDIO` },
    { clave: `2679`, descripcion: `HIDROXIDO DE LITIO EN SOLUCION` },
    { clave: `2680`, descripcion: `HIDROXIDO DE LITIO` },
    { clave: `2681`, descripcion: `HIDROXIDO DE CESIO EN SOLUCION` },
    { clave: `2682`, descripcion: `HIDROXIDO DE CESIO` },
    { clave: `2683`, descripcion: `SULFURO AMONICO EN SOLUCION` },
    { clave: `2684`, descripcion: `3-DIETILAMINOPROPILAMINA` },
    { clave: `2685`, descripcion: `N,N-DIETILETILENDIAMINA` },
    { clave: `2686`, descripcion: `2-DIETILAMINOETANOL` },
    { clave: `2687`, descripcion: `NITRITO DE DICICLOHEXILAMONIO` },
    { clave: `2688`, descripcion: `1-BROMO-3-CLOROPROPANO` },
    { clave: `2689`, descripcion: `alfa-MONOCLORHIDRINA DEL GLICEROL` },
    { clave: `2690`, descripcion: `N,n-BUTIL IMIDAZOL` },
    { clave: `2691`, descripcion: `PENTABROMURO DE FOSFORO` },
    { clave: `2692`, descripcion: `TRIBROMURO DE BORO` },
    { clave: `2693`, descripcion: `BISULFITOS EN SOLUCION ACUOSA, N.E.P.` },
    { clave: `2698`, descripcion: `ANHIDRIDOS TETRAHIDROFTALICOS con más de 0,05% de anhídrido maleico` },
    { clave: `2699`, descripcion: `ACIDO TRIFLUOROACETICO` },
    { clave: `2705`, descripcion: `1-PENTOL` },
    { clave: `2707`, descripcion: `DIMETILDIOXANOS` },
    { clave: `2709`, descripcion: `BUTILBENCENOS` },
    { clave: `2710`, descripcion: `DIPROPILCETONA` },
    { clave: `2713`, descripcion: `ACRIDINA` },
    { clave: `2714`, descripcion: `RESINATO DE ZINC` },
    { clave: `2715`, descripcion: `RESINATO DE ALUMINIO` },
    { clave: `2716`, descripcion: `1,4-BUTINODIOL` },
    { clave: `2717`, descripcion: `ALCANFOR sintético` },
    { clave: `2719`, descripcion: `BROMATO DE BARIO` },
    { clave: `2720`, descripcion: `NITRATO DE CROMO` },
    { clave: `2721`, descripcion: `CLORATO DE COBRE` },
    { clave: `2722`, descripcion: `NITRATO DE LITIO` },
    { clave: `2723`, descripcion: `CLORATO MAGNESICO` },
    { clave: `2724`, descripcion: `NITRATO DE MANGANESO` },
    { clave: `2725`, descripcion: `NITRATO DE NIQUEL` },
    { clave: `2726`, descripcion: `NITRITO DE NIQUEL` },
    { clave: `2727`, descripcion: `NITRATO DE TALIO` },
    { clave: `2728`, descripcion: `NITRATO DE CIRCONIO` },
    { clave: `2729`, descripcion: `HEXACLOROBENCENO` },
    { clave: `2730`, descripcion: `NITROANISOL LIQUIDO` },
    { clave: `2732`, descripcion: `NITROBROMOBENCENOS LIQUIDOS` },
    { clave: `2733`, descripcion: `AMINAS INFLAMABLES, CORROSIVAS, N.E.P. o POLIAMINAS INFLAMABLES, CORROSIVAS, N.E.P.` },
    { clave: `2734`, descripcion: `AMINAS LIQUIDAS, CORROSIVAS, INFLAMABLES, N.E.P. o POLIAMINAS LIQUIDAS, CORROSIVAS, INFLAMABLES, N.E.P.` },
    { clave: `2735`, descripcion: `AMINAS LIQUIDAS, CORROSIVAS, N.E.P. o POLIAMINAS LIQUIDAS, CORROSIVAS, N.E.P.` },
    { clave: `2738`, descripcion: `N-BUTILANILINA` },
    { clave: `2739`, descripcion: `ANHIDRIDO BUTIRICO` },
    { clave: `2740`, descripcion: `CLOROFORMIATO DE n-PROPILO` },
    { clave: `2741`, descripcion: `HIPOCLORITO DE BARIO con más de 22% de cloro activo` },
    { clave: `2742`, descripcion: `CLOROFORMIATOS TOXICOS, CORROSIVOS, INFLAMABLES, N.E.P.` },
    { clave: `2743`, descripcion: `CLOROFORMIATO DE n-BUTILO` },
    { clave: `2744`, descripcion: `CLOROFORMIATO DE CICLOBUTILO` },
    { clave: `2745`, descripcion: `CLOROFORMIATO DE CLOROMETILO` },
    { clave: `2746`, descripcion: `CLOROFORMIATO DE FENILO` },
    { clave: `2747`, descripcion: `CLOROFORMIATO DE terc-BUTILCICLOHEXILO` },
    { clave: `2748`, descripcion: `CLOROFORMIATO DE 2-ETILHEXILO` },
    { clave: `2749`, descripcion: `TETRAMETILSILANO` },
    { clave: `2750`, descripcion: `1,3-DICLORO-2-PROPANOL` },
    { clave: `2751`, descripcion: `CLORURO DE DIETILTIOFOSFORILO` },
    { clave: `2752`, descripcion: `1,2-EPOXI-3-ETOXIPROPANO` },
    { clave: `2753`, descripcion: `N-ETILBENCILTOLUIDINAS LIQUIDAS` },
    { clave: `2754`, descripcion: `N-ETILTOLUIDINAS` },
    { clave: `2757`, descripcion: `PLAGUICIDA A BASE DE CARBAMATO, SOLIDO, TOXICO` },
    { clave: `2758`, descripcion: `PLAGUICIDA A BASE DE CARBAMATO, LIQUIDO, INFLAMABLE, TOXICO, de punto de inflamación inferior a 23°C` },
    { clave: `2759`, descripcion: `PLAGUICIDA ARSENICAL SOLIDO, TOXICO` },
    { clave: `2760`, descripcion: `PLAGUICIDA ARSENICAL LIQUIDO, INFLAMABLE, TOXICO, de punto de inflamación inferior a 23°C` },
    { clave: `2761`, descripcion: `PLAGUICIDA ORGANOCLORADO, SOLIDO, TOXICO` },
    { clave: `2762`, descripcion: `PLAGUICIDA ORGANOCLORADO, LIQUIDO, INFLAMABLE, TOXICO, de punto de inflamación inferior a 23°C` },
    { clave: `2763`, descripcion: `PLAGUICIDA A BASE DE TRIAZINA, SOLIDO, TOXICO` },
    { clave: `2764`, descripcion: `PLAGUICIDA A BASE DE TRIAZINA, LIQUIDO, INFLAMABLE, TOXICO, de punto de inflamación inferior a 23°C` },
    { clave: `2771`, descripcion: `PLAGUICIDA A BASE DE TIOCARBAMATO, SOLIDO, TOXICO` },
    { clave: `2772`, descripcion: `PLAGUICIDA A BASE DE TIOCARBAMATO, LIQUIDO, INFLAMABLE, TOXICO, de punto de inflamación inferior a 23°C` },
    { clave: `2775`, descripcion: `PLAGUICIDA A BASE DE COBRE, SOLIDO, TOXICO` },
    { clave: `2776`, descripcion: `PLAGUICIDA A BASE DE COBRE, LIQUIDO, INFLAMABLE, TOXICO, de punto de inflamación inferior a 23°C` },
    { clave: `2777`, descripcion: `PLAGUICIDA A BASE DE MERCURIO, SOLIDO, TOXICO` },
    { clave: `2778`, descripcion: `PLAGUICIDA A BASE DE MERCURIO, LIQUIDO, INFLAMABLE, TOXICO, de punto de inflamación inferior a 23°C` },
    { clave: `2779`, descripcion: `PLAGUICIDA A BASE DE NITROFENOLES SUSTITUIDOS, SOLIDO, TOXICO` },
    { clave: `2780`, descripcion: `PLAGUICIDA A BASE DE NITROFENOLES SUSTITUIDOS, LIQUIDO, INFLAMABLE, TOXICO, de punto de inflamación inferior a 23°C` },
    { clave: `2781`, descripcion: `PLAGUICIDA A BASE DE DIPIRIDILO, SOLIDO, TOXICO` },
    { clave: `2782`, descripcion: `PLAGUICIDA A BASE DE DIPIRIDILO, LIQUIDO, INFLAMABLE, TOXICO, de punto de inflamación inferior a 23°C` },
    { clave: `2783`, descripcion: `PLAGUICIDA A BASE DE ORGANOFOSFORO, SOLIDO, TOXICO` },
    { clave: `2784`, descripcion: `PLAGUICIDA A BASE DE ORGANOFOSFORO, LIQUIDO, INFLAMABLE, TOXICO, de punto de inflamación inferior a 23°C` },
    { clave: `2785`, descripcion: `4-TIAPENTANAL` },
    { clave: `2786`, descripcion: `PLAGUICIDA A BASE DE ORGANOESTAÑO, SOLIDO, TOXICO` },
    { clave: `2787`, descripcion: `PLAGUICIDA A BASE DE ORGANOESTAÑO, LIQUIDO, INFLAMABLE, TOXICO, de punto de inflamación inferior a 23°C` },
    { clave: `2788`, descripcion: `COMPUESTO DE ORGANOESTAÑO, LIQUIDO, N.E.P.` },
    { clave: `2789`, descripcion: `ACIDO ACETICO GLACIAL o ACIDO ACETICO EN SOLUCION con más de 80%, en masa, de ácido` },
    { clave: `2790`, descripcion: `ACIDO ACETICO EN SOLUCION con no menos de 50% y un máximo de 80%, en masa, de ácido` },
    { clave: `2790`, descripcion: `ACIDO ACETICO EN SOLUCION con más de 10% y menos de 50%, en masa, de ácido` },
    { clave: `2793`, descripcion: `VIRUTAS, TORNEADURAS o RASPADURAS DE METALES FERROSOS en una forma susceptible de calentamiento espontáneo` },
    { clave: `2794`, descripcion: `ACUMULADORES ELECTRICOS DE ELECTROLITO LIQUIDO ACIDO` },
    { clave: `2795`, descripcion: `ACUMULADORES ELECTRICOS DE ELECTROLITO LIQUIDO ALCALINO` },
    { clave: `2796`, descripcion: `ACIDO SULFURICO con no más de 51% de ácido o ELECTROLITO ACIDO PARA BATERIAS` },
    { clave: `2797`, descripcion: `ELECTROLITO ALCALINO PARA ACUMULADORES` },
    { clave: `2798`, descripcion: `DICLOROFENILFOSFINA` },
    { clave: `2799`, descripcion: `TIODICLOROFENILFOSFINA` },
    { clave: `2800`, descripcion: `ACUMULADORES ELECTRICOS NO DERRAMABLES DE ELECTROLITO LIQUIDO` },
    { clave: `2801`, descripcion: `COLORANTE LIQUIDO CORROSIVO, N.E.P., MATERIA INTERMEDIA PARA COLORANTES, LIQUIDA, CORROSIVA, N.E.P.` },
    { clave: `2802`, descripcion: `CLORURO DE COBRE` },
    { clave: `2803`, descripcion: `GALIO` },
    { clave: `2805`, descripcion: `HIDRURO DE LITIO, FUNDIDO, SOLIDO` },
    { clave: `2806`, descripcion: `NITRURO DE LITIO` },
    { clave: `2807`, descripcion: `MATERIAL MAGNETIZADO` },
    { clave: `2809`, descripcion: `MERCURIO` },
    { clave: `2810`, descripcion: `LIQUIDO TOXICO, ORGANICO, N.E.P.` },
    { clave: `2811`, descripcion: `SOLIDO TOXICO, ORGANICO, N.E.P.` },
    { clave: `2812`, descripcion: `ALUMINATO DE SODIO SOLIDO` },
    { clave: `2813`, descripcion: `SOLIDO QUE REACCIONA CON EL AGUA, N E.P.` },
    { clave: `2814`, descripcion: `SUBSTANCIA INFECCIOSA PARA EL HOMBRE` },
    { clave: `2815`, descripcion: `N-AMINOETILPIPERAZINA` },
    { clave: `2817`, descripcion: `DIHIDROFLORURO AMONICO EN SOLUCION` },
    { clave: `2818`, descripcion: `POLISULFURO DE AMONIO EN SOLUCION` },
    { clave: `2819`, descripcion: `FOSFATO ACIDO DE AMILO` },
    { clave: `2820`, descripcion: `ACIDO BUTIRICO` },
    { clave: `2821`, descripcion: `FENOL EN SOLUCION` },
    { clave: `2822`, descripcion: `2-CLOROPIRIDINA` },
    { clave: `2823`, descripcion: `ACIDO CROTONICO SOLIDO` },
    { clave: `2826`, descripcion: `CLOROTIOFORMIATO DE ETILO` },
    { clave: `2829`, descripcion: `ACIDO CAPROICO` },
    { clave: `2830`, descripcion: `LITIOFERROSILICIO` },
    { clave: `2831`, descripcion: `1,1,1-TRICLOROETANO` },
    { clave: `2834`, descripcion: `ACIDO FOSFOROSO` },
    { clave: `2835`, descripcion: `HIDRURO DE SODIO ALUMINICO` },
    { clave: `2837`, descripcion: `BISULFATOS EN SOLUCION ACUOSA` },
    { clave: `2838`, descripcion: `BUTIRATO DE VINILO ESTABILIZADO` },
    { clave: `2839`, descripcion: `ALDOL` },
    { clave: `2840`, descripcion: `BUTIRALDOXIMA` },
    { clave: `2841`, descripcion: `DI-n-AMILAMINA` },
    { clave: `2842`, descripcion: `NITROETANO` },
    { clave: `2844`, descripcion: `CALCIOMANGANESOSILICIO` },
    { clave: `2845`, descripcion: `LIQUIDO PIROFORICO ORGANICO, N.E.P.` },
    { clave: `2846`, descripcion: `SOLIDO PIROFORICO ORGANICO N.E.P.` },
    { clave: `2849`, descripcion: `3-CLORO-1-PROPANOL` },
    { clave: `2850`, descripcion: `TETRAMERO DEL PROPILENO` },
    { clave: `2851`, descripcion: `TRIFLUORURO DE BORO DIHIDRATADO` },
    { clave: `2852`, descripcion: `SULFURO DE DIPICRILO HUMEDECIDO con no menos de 10%, en masa, de agua` },
    { clave: `2853`, descripcion: `FLUOROSILICATO  MAGNESICO` },
    { clave: `2854`, descripcion: `FLUOROSILICATO AMONICO` },
    { clave: `2855`, descripcion: `FLUOROSILICATO DE CINC` },
    { clave: `2856`, descripcion: `FLUOROSILICATOS, N.E.P.` },
    { clave: `2857`, descripcion: `MAQUINAS REFRIGERADORAS que contengan gases licuados no inflamables ni tóxicos, o amoníaco en solución (NU 2672)` },
    { clave: `2858`, descripcion: `CIRCONIO SECO, en forma de alambre enrollado, de láminas metálicas acabadas o de tiras (de un grosor inferior a 254 micrones pero no a 18 micrones)` },
    { clave: `2859`, descripcion: `METAVANADATO AMONICO` },
    { clave: `2861`, descripcion: `POLIVANADATO AMONICO` },
    { clave: `2862`, descripcion: `PENTOXIDO DE VANADIO no fundido` },
    { clave: `2863`, descripcion: `VANADATO DE SODIO Y AMONIO` },
    { clave: `2864`, descripcion: `METAVANADATO DE POTASIO` },
    { clave: `2865`, descripcion: `SULFATO DE HIDROXILAMINA` },
    { clave: `2869`, descripcion: `MEZCLAS DE TRICLORURO DE TITANIO` },
    { clave: `2870`, descripcion: `BOROHIDRURO DE ALUMINIO` },
    { clave: `2870`, descripcion: `BOROHIDRURO DE ALUMINIO, BOROHIDRURO DE ALUMINIO EN DISPOSITIVOS ` },
    { clave: `2872`, descripcion: `DIBROMOCLOROPROPANOS` },
    { clave: `2873`, descripcion: `DIBUTILAMINOETANOL` },
    { clave: `2874`, descripcion: `ALCOHOL FURFURILICO` },
    { clave: `2875`, descripcion: `HEXACLOROFENO` },
    { clave: `2876`, descripcion: `RESORCINOL` },
    { clave: `2878`, descripcion: `TITANIO, ESPONJA DE, EN GRANULOS o EN POLVO` },
    { clave: `2879`, descripcion: `OXICLORURO DE SELENIO` },
    { clave: `2880`, descripcion: `HIPOCLORITO DE  CALCIO HIDRATADO o HIPOCLORITO DE CALCIO HIDRATADO EN MEZCLA, con un mínimo de 5,5% y un máximo de 16% de agua` },
    { clave: `2881`, descripcion: `CATALIZADOR DE METAL SECO` },
    { clave: `2900`, descripcion: `SUBSTANCIA INFECCIOSA únicamente PARA LOS ANIMALES` },
    { clave: `2901`, descripcion: `CLORURO DE BROMO` },
    { clave: `2902`, descripcion: `PLAGUICIDA LIQUIDO, TOXICO, N.E.P.` },
    { clave: `2903`, descripcion: `PLAGUICIDA LIQUIDO, TOXICO, INFLAMABLE, N.E.P., de punto de inflamación no inferior a 23°C` },
    { clave: `2904`, descripcion: `CLOROFENOLATOS LIQUIDOS o FENOLATOS LIQUIDOS` },
    { clave: `2905`, descripcion: `CLOROFENOLATOS SOLIDOS o FENOLATOS SOLIDOS` },
    { clave: `2907`, descripcion: `MEZCLAS DE DINITRATO DE ISOSORBIDA con un mínimo de 60% de lactosa, manosa, almidón o fosfato ácido de calcio` },
    { clave: `2908`, descripcion: `MATERIALES RADIACTIVOS, BULTOS EXCEPTUADOS-EMBALAJES/ENVASES VACIOS` },
    { clave: `2909`, descripcion: `MATERIALES RADIACTIVOS, BULTOS EXCEPTUADOS-ARTICULOS MANUFACTURADOS DE URANIO NATURAL o URANIO EMPOBRECIDO  o TORIO NATURAL` },
    { clave: `2910`, descripcion: `MATERIALES RADIACTIVOS, BULTOS EXCEPTUADOS-CANTIDADES PEQUEÑAS DE MATERIALES` },
    { clave: `2911`, descripcion: `MATERIALES RADIACTIVOS, BULTOS EXCEPTUADOS-INSTRUMENTOS o ARTICULOS` },
    { clave: `2912`, descripcion: `MATERIALES RADIACTIVOS BAJA ACTIVIDAD ESPECIFICA (BAE-I) no fisionables o fisionables exceptuados` },
    { clave: `2913`, descripcion: `MATERIALES RADIACTIVOS, OBJETOS CONTAMINADOS EN LA SUPERFICIE (OCS-I u OCS-II), no fisionables o fisionables exceptuados` },
    { clave: ` 2915`, descripcion: ` MATERIALES RADIACTIVOS, BULTOS DE TIPO A, no en forma especial, no fisionables o fisionables exceptuados` },
    { clave: `2916`, descripcion: `MATERIALES RADIACTIVOS, BULTOS DE TIPO B(U), no fisionables o fisionables exceptuados` },
    { clave: `2917`, descripcion: `MATERIALES RADIACTIVOS, BULTOS DE TIPO B(M), no fisionables o fisionables exceptuados` },
    { clave: `2919`, descripcion: `MATERIALES RADIACTIVOS TRANSPORTADOS CON DISPOSICIONES ESPECIALES, no fisionables o fisionables exceptuados` },
    { clave: `2920`, descripcion: `LIQUIDO CORROSIVO INFLAMABLE, N.E.P.` },
    { clave: `2921`, descripcion: `SOLIDO CORROSIVO INFLAMABLE N.E.P.` },
    { clave: `2922`, descripcion: `LIQUIDO CORROSIVO, TOXICO, N.E.P.` },
    { clave: `2923`, descripcion: `SOLIDO CORROSIVO, TOXICO, N.E.P.` },
    { clave: `2924`, descripcion: `LIQUIDO INFLAMABLE, CORROSIVO, N.E.P.` },
    { clave: `2925`, descripcion: `SOLIDO INFLAMABLE, CORROSIVO, ORGANICO, N.E.P.` },
    { clave: `2926`, descripcion: `SOLIDO INFLAMABLE, TOXICO, ORGANICO, N.E.P.` },
    { clave: `2927`, descripcion: `LIQUIDO TOXICO, CORROSIVO, ORGANICO, N.E.P.` },
    { clave: `2928`, descripcion: `SOLIDO TOXICO, CORROSIVO, ORGANICO, N.E.P.` },
    { clave: `2929`, descripcion: `LIQUIDO TOXICO, INFLAMABLE, ORGANICO, N.E.P.` },
    { clave: `2930`, descripcion: `SOLIDO TOXICO, INFLAMABLE, ORGANICO, N.E.P.` },
    { clave: `2931`, descripcion: `SULFATO DE VANADILO` },
    { clave: `2933`, descripcion: `2-CLOROPROPIONATO DE METILO` },
    { clave: `2934`, descripcion: `2-CLOROPROPIONATO DE ISOPROPILO` },
    { clave: `2935`, descripcion: `2-CLOROPROPIONATO DE ETILO` },
    { clave: `2936`, descripcion: `ACIDO TIOLACTICO` },
    { clave: `2937`, descripcion: `ALCOHOL alfa-METILBENCILICO` },
    { clave: `2940`, descripcion: `9-FOSFABICICLONONANOS (FOSFINAS DE CICLOOCTADIENO)` },
    { clave: `2941`, descripcion: `FLUORANILINAS` },
    { clave: `2942`, descripcion: `2-TRIFLUOROMETILANILINA` },
    { clave: `2943`, descripcion: `TETRAHIDROFURFURILAMINA` },
    { clave: `2945`, descripcion: `N-METILBUTILAMINA` },
    { clave: `2946`, descripcion: `2-AMINO-5-DIETILAMINOPENTANO` },
    { clave: `2947`, descripcion: `CLOROACETATO DE ISOPROPILO` },
    { clave: `2948`, descripcion: `3-TRIFLUOROMETILANILINA` },
    { clave: `2949`, descripcion: `HIDROSULFURO DE SODIO (SULFHIDRATO SODICO) con un mínimo de 25% de agua de cristalización` },
    { clave: `2950`, descripcion: `GRANULOS DE MAGNESIO RECUBIERTOS en partículas de un mínimo de 149 micrones` },
    { clave: `2956`, descripcion: `5-terc-BUTIL-2,4,6-TRINITRO-m-XILENO (ALMIZCLE XILENO)` },
    { clave: `2965`, descripcion: `DIMETILETEARATO DE TRIFLUORURO DE BORO` },
    { clave: `2966`, descripcion: `TIOGLICOL` },
    { clave: `2967`, descripcion: `ACIDO SULFAMICO` },
    { clave: `2968`, descripcion: `MANEB ESTABILIZADO o PREPARADOS DE MANEB ESTABILIZADOS contra el calentamiento espontáneo` },
    { clave: `2969`, descripcion: `SEMILLAS DE RICINO o HARINA DE RICINO o TORTA DE RICINO o RICINO EN COPOS` },
    { clave: `2977`, descripcion: `MATERIALES  RADIACTIVOS, HEXAFLUORURO DE URANIO, FISIONABLE` },
    { clave: `2978`, descripcion: `MATERIALES  RADIACTIVOS, HEXAFLUORURO DE URANIO, no fisioble o fisioble exceptuado` },
    { clave: `2983`, descripcion: `OXIDO DE ETILENO Y OXIDO DE PROPILENO EN MEZCLA con un máximo de 30% de óxido de etileno` },
    { clave: `2984`, descripcion: `PEROXIDO DE HIDROGENO EN SOLUCION ACUOSA con un mínimo de 8% pero menos de 20% de peróxido de hidrógeno (estabilizada según sea necesario)` },
    { clave: `2985`, descripcion: `CLOROSILANOS, INFLAMABLES, CORROSIVOS, N.E.P.` },
    { clave: `2986`, descripcion: `CLOROSILANOS, CORROSIVOS, INFLAMABLES, N.E.P.` },
    { clave: `2987`, descripcion: `CLOROSILANOS, CORROSIVOS, N.E.P.` },
    { clave: `2988`, descripcion: `CLOROSILANOS QUE REACCIONAN CON EL AGUA, INFLAMABLES, CORROSIVOS, N.E.P.` },
    { clave: `2989`, descripcion: `FOSFITO DIBASICO DE PLOMO` },
    { clave: `2990`, descripcion: `APARATOS DE SALVAMENTO AUTOINFLABLES` },
    { clave: `2991`, descripcion: `PLAGUICIDA A BASE DE CARBAMATO, LIQUIDO, TOXICO, INFLAMABLE, de punto de inflamación no inferior a 23°C` },
    { clave: `2992`, descripcion: `PLAGUICIDA A BASE DE CARBAMATOS, LIQUIDO, TOXICO` },
    { clave: `2993`, descripcion: `PLAGUICIDA ARSENICAL, LIQUIDO, TOXICO, INFLAMABLE, de punto de inflamación no inferior a 23°C` },
    { clave: `2994`, descripcion: `PLAGUICIDA ARSENICAL, LIQUIDO, TOXICO` },
    { clave: `2995`, descripcion: `PLAGUICIDA ORGANOCLORADO, LIQUIDO, TOXICO, INFLAMABLE, de punto de inflamación no inferior a 23°C` },
    { clave: `2996`, descripcion: `PLAGUICIDA ORGANOCLORADO, LIQUIDO, TOXICO` },
    { clave: `2997`, descripcion: `PLAGUICIDA A BASE DE TRIAZINA, LIQUIDO, TOXICO, INFLAMABLE, de punto de inflamación no inferior a 23°C` },
    { clave: `2998`, descripcion: `PLAGUICIDA A BASE DE TRIAZINA, LIQUIDO, TOXICO` },
    { clave: `3005`, descripcion: `PLAGUICIDA A BASE DE TIOCARBAMATO, LIQUIDO, TOXICO, INFLAMABLE, de punto de inflamación no inferior a 23°C` },
    { clave: `3006`, descripcion: `PLAGUICIDA A BASE DE TIOCARBAMATO, LIQUIDO, TOXICO` },
    { clave: `3009`, descripcion: `PLAGUICIDA A BASE DE COBRE, LIQUIDO, TOXICO, INFLAMABLE, de punto de inflamación no inferior a 23°C` },
    { clave: `3010`, descripcion: `PLAGUICIDA A BASE DE COBRE, LIQUIDO, TOXICO` },
    { clave: `3011`, descripcion: `PLAGUICIDA A BASE DE MERCURIO, LIQUIDO, TOXICO, INFLAMABLE, de punto de inflamación no inferior a 23°C` },
    { clave: `3012`, descripcion: `PLAGUICIDA A BASE DE MERCURIO, LIQUIDO, TOXICO` },
    { clave: `3013`, descripcion: `PLAGUICIDA A BASE DE NITROFENOLES SUSTITUIDOS, LIQUIDO, TOXICO, INFLAMABLE, de punto de inflamación no inferior a 23°C` },
    { clave: `3014`, descripcion: `PLAGUICIDA A BASE DE NITROFENOLES SUSTITUIDOS, LIQUIDO, TOXICO` },
    { clave: `3015`, descripcion: `PLAGUICIDA A BASE DE DIPIRIDILO, LIQUIDO, TOXICO, INFLAMABLE, de punto de inflamación no inferior a 23°C` },
    { clave: `3016`, descripcion: `PLAGUICIDA A BASE DE DIPIRIDILO, LIQUIDO, TOXICO` },
    { clave: `3017`, descripcion: `PLAGUICIDA A BASE DE ORGANOFOSFORO, LIQUIDO, TOXICO, INFLAMABLE, de punto de inflamación no inferior a 23°C` },
    { clave: `3018`, descripcion: `PLAGUICIDA A BASE DE ORGANOFOSFORO, LIQUIDO, TOXICO` },
    { clave: `3019`, descripcion: `PLAGUICIDA A BASE DE ORGANOESTAÑO, LIQUIDO, TOXICO, INFLAMABLE, de punto de inflamación no inferior a 23°C` },
    { clave: `3020`, descripcion: `PLAGUICIDA A BASE DE ORGANOESTAÑO, LIQUIDO, TOXICO` },
    { clave: `3021`, descripcion: `PLAGUICIDA LIQUIDO, INFLAMABLE, TOXICO, N.E.P., de punto de inflamación inferior a 23°C` },
    { clave: `3022`, descripcion: `OXIDO DE 1,2-BUTILENO ESTABILIZADO` },
    { clave: `3023`, descripcion: `2-METIL-2-HEPTANOTIOL` },
    { clave: `3024`, descripcion: `PLAGUICIDA A BASE DE DERIVADOS DE LA CUMARINA, LIQUIDO, TOXICO, INFLAMABLE, de punto de inflamación inferior a 23°C` },
    { clave: `3025`, descripcion: `PLAGUICIDA A BASE DE DERIVADOS DE LA CUMARINA, LIQUIDO, TOXICO, INFLAMABLE, de punto de inflamación no inferior a 23°C` },
    { clave: `3026`, descripcion: `PLAGUICIDA A BASE DE DERIVADOS DE LA CUMARINA, LIQUIDO, TOXICO` },
    { clave: `3027`, descripcion: `PLAGUICIDA A BASE DE DERIVADOS DE LA CUMARINA, SOLIDO, TOXICO` },
    { clave: `3028`, descripcion: `ACUMULADORES ELECTRICOS SECOS QUE CONTIENEN HIDROXIDO DE POTASIO SOLIDO` },
    { clave: `3048`, descripcion: `PLAGUICIDA A BASE DE FOSFURO DE ALUMINIO` },
    { clave: `3054`, descripcion: `CICLOHEXILMERCAPTANO` },
    { clave: `3055`, descripcion: `2-(2-AMINOETOXI)ETANOL` },
    { clave: `3056`, descripcion: `n-HEPTALDEHIDO` },
    { clave: `3057`, descripcion: `CLORURO DE TRIFLUORACETILO` },
    { clave: `3064`, descripcion: `NITROGLICERINA EN SOLUCION ALCOHOLICA con más de 1% pero no más de 5% de nitroglicerina` },
    { clave: `3065`, descripcion: `BEBIDAS ALCOHOLICAS, con más de 70% de alcohol en volumen` },
    { clave: `3065`, descripcion: `BEBIDAS ALCOHOLICAS, con más de 24% pero no más de 70% de alcohol en volumen` },
    { clave: `3066`, descripcion: `PINTURA (incluye pintura, laca, esmalte, colorante, goma laca, barniz, betún, encáustico, apresto líquido y base líquida para lacas) o PRODUCTOS PARA PINTURA (incluye compuestos disolventes o reductores de pintura)` },
    { clave: `3070`, descripcion: `MEZCLA DE OXIDO DE ETILENO Y DICLORODIFLUOMETANO, con un máximo de 12,5% de óxido de etileno` },
    { clave: `3071`, descripcion: `MERCAPTANOS LIQUIDOS, TOXICOS, INFLAMABLES, N.E.P., o MEZCLA DE MERCAPTANOS LIQUIDOS, TOXICOS, INFLAMABLES, N.E.P.` },
    { clave: `3072`, descripcion: `APARATOS DE SALVAMENTO NO AUTOINFLABLES que contengan mercancías peligrosas como material accesorio` },
    { clave: `3073`, descripcion: `VINILPIRIDINAS ESTABILIZADAS` },
    { clave: `3077`, descripcion: `SUBSTANCIA SOLIDA POTENCIALMENTE PELIGROSA PARA EL MEDIO AMBIENTE, N.E.P.` },
    { clave: `3078`, descripcion: `CERIO, torneaduras o polvo granulado` },
    { clave: `3079`, descripcion: `METACRILONITRILO ESTABILIZADO` },
    { clave: `3080`, descripcion: `ISOCIANATOS TOXICOS, INFLAMABLES, N.E.P., o ISOCIANATOS EN SOLUCION, TOXICOS, INFLAMABLES, N.E.P.` },
    { clave: `3082`, descripcion: `SUBSTANCIA LIQUIDA POTENCIALMENTE PELIGROSAS PARA EL MEDIO AMBIENTE, N.E.P.` },
    { clave: `3083`, descripcion: `FLUORURO DE PERCLORILO` },
    { clave: `3084`, descripcion: `SOLIDO CORROSIVO COMBURENTE, N.E.P.` },
    { clave: `3085`, descripcion: `SOLIDO COMBURENTE, CORROSIVO, N.E.P.` },
    { clave: `3086`, descripcion: `SOLIDO TOXICO, COMBURENTE, N.E.P.` },
    { clave: `3087`, descripcion: `SOLIDO COMBURENTE, TOXICO, N.E.P.` },
    { clave: `3088`, descripcion: `SOLIDO ORGANICO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, N.E.P.` },
    { clave: `3089`, descripcion: `POLVOS METALICOS INFLAMABLES, N.E.P.` },
    { clave: `3090`, descripcion: `BATERIAS DE METAL LITIO (Incluidas las baterías de aleación de litio)` },
    { clave: `3091`, descripcion: `BATERIAS DE METAL LITIO INSTALADAS EN UN EQUIPO O BATERIAS DE  METAL LITIO EMBALADAS CON UN EQUIPO (Incluidas las baterías de aleación de litio)` },
    { clave: `3092`, descripcion: `1-METOXI-2-PROPANOL` },
    { clave: `3093`, descripcion: `LIQUIDO CORROSIVO COMBURENTE N.E.P.` },
    { clave: `3094`, descripcion: `LIQUIDO CORROSIVO QUE REACCIONA CON EL AGUA, N.E.P.` },
    { clave: `3095`, descripcion: `SOLIDO CORROSIVO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, N.E.P.` },
    { clave: `3096`, descripcion: `SOLIDO CORROSIVO QUE REACCIONA CON EL AGUA N.E.P.` },
    { clave: `3097`, descripcion: `SOLIDO INFLAMABLE, COMBURENTE, N.E.P.` },
    { clave: `3098`, descripcion: `LIQUIDO COMBURENTE, CORROSIVO, N.E.P.` },
    { clave: `3099`, descripcion: `LIQUIDO COMBURENTE, TOXICO, N.E.P.` },
    { clave: `3100`, descripcion: `SOLIDO COMBURENTE QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, N.E.P.` },
    { clave: `3101`, descripcion: `PEROXIDO ORGANICO LIQUIDO TIPO B` },
    { clave: `3102`, descripcion: `PEROXIDO ORGANICO SOLIDO TIPO B` },
    { clave: `3103`, descripcion: `PEROXIDO ORGANICO LIQUIDO TIPO C` },
    { clave: `3104`, descripcion: `PEROXIDO ORGANICO SOLIDO TIPO C` },
    { clave: `3105`, descripcion: `PEROXIDO ORGANICO LIQUIDO TIPO D` },
    { clave: `3106`, descripcion: `PEROXIDO ORGANICO SOLIDO TIPO D` },
    { clave: `3107`, descripcion: `PEROXIDO ORGANICO LIQUIDO TIPO E` },
    { clave: `3108`, descripcion: `PEROXIDO ORGANICO SOLIDO TIPO E` },
    { clave: `3109`, descripcion: `PEROXIDO ORGANICO LIQUIDO TIPO F` },
    { clave: `3110`, descripcion: `PEROXIDO ORGANICO SOLIDO TIPO F` },
    { clave: `3111`, descripcion: `PEROXIDO ORGANICO LIQUIDO TIPO B LIQUIDO, CON TEMPERATURA REGULADA` },
    { clave: `3112`, descripcion: `PEROXIDO ORGANICO LIQUIDO TIPO B SOLIDO, CON TEMPERATURA REGULADA` },
    { clave: `3113`, descripcion: `PEROXIDO ORGANICO LIQUIDO TIPO C, CON TEMPERATURA REGULADA` },
    { clave: `3114`, descripcion: `PEROXIDO ORGANICO SOLIDO TIPO C, CON TEMPERATURA REGULADA` },
    { clave: `3115`, descripcion: `PEROXIDO ORGANICO LIQUIDO TIPO D, CON TEMPERATURA REGULADA` },
    { clave: `3116`, descripcion: `PEROXIDO ORGANICO SOLIDO TIPO D, CON TEMPERATURA REGULADA` },
    { clave: `3117`, descripcion: `PEROXIDO ORGANICO LIQUIDO TIPO E, CON TEMPERATURA REGULADA` },
    { clave: `3118`, descripcion: `PEROXIDO ORGANICO SOLIDO TIPO E, CON TEMPERATURA REGULADA` },
    { clave: `3119`, descripcion: `PEROXIDO ORGANICO LIQUIDO TIPO F, CON TEMPERATURA REGULADA` },
    { clave: `3120`, descripcion: `PEROXIDO ORGANICO SOLIDO TIPO F, CON TEMPERATURA REGULADA` },
    { clave: `3121`, descripcion: `SOLIDO COMBURENTE QUE REACCIONA CON EL AGUA, N.E.P.` },
    { clave: `3122`, descripcion: `LIQUIDO TOXICO, OXIDANTE, N.E.P.` },
    { clave: `3123`, descripcion: `LIQUIDO TOXICO QUE REACCIONA CON EL AGUA, N.E.P.` },
    { clave: `3124`, descripcion: `SOLIDO TOXICO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, N.E.P.` },
    { clave: `3125`, descripcion: `SOLIDO TOXICO QUE REACCIONA CON EL AGUA, N.E.P` },
    { clave: `3126`, descripcion: `SOLIDO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, CORROSIVO, ORGANICO, N.E.P.` },
    { clave: `3127`, descripcion: `SOLIDO QUE EXPERIMENTA CALENTAMIENTO, COMBURENTE, N.E.P.` },
    { clave: `3128`, descripcion: `SOLIDO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, TOXICO, ORGANICO, N.E.P.` },
    { clave: `3129`, descripcion: `LIQUIDO QUE REACCIONA CON EL AGUA, CORROSIVO, N.E.P.` },
    { clave: `3130`, descripcion: `LIQUIDO QUE REACCIONA CON EL AGUA, TOXICO, N.E.P.` },
    { clave: `3131`, descripcion: `SOLIDO QUE REACCIONA CON EL AGUA, CORROSIVO, N.E.P.` },
    { clave: `3132`, descripcion: `SOLIDO QUE REACCIONA CON EL AGUA, INFLAMABLE, N.E.P.` },
    { clave: `3133`, descripcion: `SOLIDO QUE REACCIONA CON EL AGUA, N.E.P.` },
    { clave: `3134`, descripcion: `SOLIDO QUE REACCIONA CON EL AGUA, TOXICO, N.E.P.` },
    { clave: `3135`, descripcion: `SOLIDO QUE REACCIONA CON EL AGUA Y QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, N.E.P` },
    { clave: `3136`, descripcion: `TRIFLUOROMETANO LIQUIDO REFRIGERADO` },
    { clave: `3137`, descripcion: `SOLIDO COMBURENTE, INFLAMABLE, N.E.P.` },
    { clave: `3138`, descripcion: `MEZCLA LIQUIDA, REFRIGERADA, con no menos de 71,5% de etilen,  un máximo de 22.5% de acetileno y un máximo de 6% de propileno` },
    { clave: `3139`, descripcion: `LIQUIDO OXIDANTE, N.E.P.` },
    { clave: `3140`, descripcion: `ALCALOIDES LIQUIDOS N.E.P. o SALES DE ALCALOIDES LIQUIDAS N.E.P.` },
    { clave: `3141`, descripcion: `ANTIMONIO, COMPUESTO INORGANICO LIQUIDO, N.E.P.` },
    { clave: `3142`, descripcion: `DESINFECTANTE LIQUIDO, TOXICO, N.E.P.` },
    { clave: `3143`, descripcion: `COLORANTE SOLIDO TOXICO, N.E.P. o MATERIA INTERMEDIA PARA COLORANTES, SOLIDA, TOXICOS, N.E.P.` },
    { clave: `3144`, descripcion: `NICOTINA, COMPUESTO LIQUIDO N.E.P., o PREPARADO LIQUIDO A BASE  DE NICOTINA, N.E.P.` },
    { clave: `3145`, descripcion: `ALQUILFENOLES LIQUIDOS, N.E.P. (incluidos los homólogos C2 a C12)` },
    { clave: `3146`, descripcion: `COMPUESTO DE ORGANOESTAÑO SOLIDO, N.E.P.` },
    { clave: `3147`, descripcion: `COLORANTE SOLIDO, CORROSIVO, N.E.P. o MATERIA INTERMEDIA PARA COLORANTE, SOLIDA, CORROSIVAS, N.E.P.` },
    { clave: `3148`, descripcion: `LÍQUIDO QUE REACCIONA CON EL AGUA, N.E.P.` },
    { clave: `3149`, descripcion: `PEROXIDO DE HIDROGENO Y ACIDO PEROXIACETICO, EN MEZCLA, con ácido(s), agua y un máximo de 5% de ácido peroxiacético, ESTABILIZADA` },
    { clave: `3150`, descripcion: `DISPOSITIVOS PEQUEÑOS ACCIONADOS POR HIDROCARBUROS GASEOSOS o RECARGAS DE HIDROCARBUROS GASEOSOS PARA DISPOSITIVOS PEQUEÑOS, con dispositivo de descarga` },
    { clave: `3151`, descripcion: `DIFENILOS POLIHALOGENADOS LIQUIDOS o TERFENILOS POLIHALOGENADOS LIQUIDOS` },
    { clave: `3152`, descripcion: `DIFENILOS POLIHALOGENADOS SOLIDOS o TERFENILOS POLIHALOGENADOS SOLIDOS` },
    { clave: `3153`, descripcion: `PERFLUORO (ETER METILVINIILICO)` },
    { clave: `3154`, descripcion: `PERFLUORO (ETER ETILVINILICO)` },
    { clave: `3155`, descripcion: `PENTACLOROFENOL` },
    { clave: `3156`, descripcion: `GAS COMPRIMIDO, COMBURENTE, N.E.P.` },
    { clave: `3157`, descripcion: `GAS LICUADO, COMBURENTE, N.E.P.` },
    { clave: `3158`, descripcion: `GAS LICUADO, REFRIGERADO, N.E.P.` },
    { clave: `3159`, descripcion: `1,1,1,2-TETRAFLUOROETANO (GAS REFRIGERANTE R 134ª)` },
    { clave: `3160`, descripcion: `GAS LICUADO TOXICO, INFLAMABLE, N.E.P.` },
    { clave: `3161`, descripcion: `GAS LICUADO INFLAMABLE, N.E.P.` },
    { clave: `3162`, descripcion: `GAS LICUADO TOXICO N.E.P.` },
    { clave: `3163`, descripcion: `GAS LICUADO, N.E.P.` },
    { clave: `3164`, descripcion: `OBJETOS CON PRESION INTERIOR NEUMATICOS O HIDRAULICOS (que contienen gas ininflamable)` },
    { clave: `3165`, descripcion: `DEPOSITO DE COMBUSTIBLE DE GRUPO MOTOR DE CIRCUITO HIDRAULICO DE AERONAVE (que contiene una mezcla de hidracina anhidra y metilhidrazina) (combustible M86)` },
    { clave: `3166`, descripcion: `MOTOR DE COMBUSTION INTERNA O VEHICULO  PROPULSADO POR GAS INFLAMABLE o VEHICULO PROPULSADO POR LIQUIDO INFLAMABLE o MOTOR CON PILA DE COMBUSTIBLE PROPULSADO POR GAS INFLAMABLE o VEHICULO CON PILA DE COMBUSTIBLE PROPULSADO POR LIQUIDO INFLAMABLE` },
    { clave: `3167`, descripcion: `MUESTRAS DE GAS INFLAMABLE,  A PRESION NORMAL, N.E.P.,  que no sea  líquido refrigerado` },
    { clave: `3168`, descripcion: `MUESTRAS DE GAS TOXICO INFLAMABLE,  A PRESION NORMAL, N.E.P.,  que no sea  líquido refrigerado` },
    { clave: `3169`, descripcion: `MUESTRAS DE GAS TOXICO, A PRESION NORMAL, N.E.P.,  que no sea  líquido refrigerado` },
    { clave: `3170`, descripcion: `SUBPRODUCTOS DE LA FUNDICION DEL  ALUMINIO O SUBPRODUCTOS DE LA REFUNDICION DEL ALUMINIO` },
    { clave: `3171`, descripcion: `VEHICULO ACCIONADO POR BATERIA  O APARATO ACCIONADO POR BATERIA` },
    { clave: `3172`, descripcion: `TOXINAS EXTRAIDAS DE UN MEDIO VIVO, LIQUIDAS, N.E.P.` },
    { clave: `3174`, descripcion: `DISULFURO DE TITANIO` },
    { clave: `3175`, descripcion: `SOLIDO QUE CONTIENE LIQUIDO INFLAMABLE, N.E.P.` },
    { clave: `3176`, descripcion: `SOLIDO INFLAMABLE ORGANICO FUNDIDO N.E.P.` },
    { clave: `3178`, descripcion: `SOLIDO INFLAMABLE INORGANICO, N.E.P.` },
    { clave: `3179`, descripcion: `SOLIDO INFLAMABLE, INORGANICO TOXICO, N.E.P.` },
    { clave: `3180`, descripcion: `SOLIDO INFLAMABLE, INORGANICO CORROSIVO, N.E.P.` },
    { clave: `3181`, descripcion: `SALES METALICAS DE COMPUESTOS ORGANICOS, INFLAMABLES, N.E.P.` },
    { clave: `3182`, descripcion: `HIDRUROS METALICOS INFLAMABLES, N.E.P.` },
    { clave: `3183`, descripcion: `LIQUIDO ORGANICO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, N.E.P.` },
    { clave: `3184`, descripcion: `LIQUIDO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, TOXICO, ORGANICO N.E.P` },
    { clave: `3185`, descripcion: `LIQUIDO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, CORROSIVO, ORGANICO N.E.P.` },
    { clave: `3186`, descripcion: `LIQUIDO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, INORGANICO, N.E.P.` },
    { clave: `3187`, descripcion: `LIQUIDO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, TOXICO, INORGANICO N.E.P.` },
    { clave: `3188`, descripcion: `LIQUIDO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, CORROSIVO, INORGANICO N.E.P.` },
    { clave: `3189`, descripcion: `POLVO METALICO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, N.E.P.` },
    { clave: `3190`, descripcion: `SOLIDO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, INORGANICO, N.E.P.` },
    { clave: `3191`, descripcion: `SOLIDO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, TOXICO, INORGANICO, N.E.P.` },
    { clave: `3192`, descripcion: `SOLIDO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, CORROSIVO, INORGANICO, N.E.P.` },
    { clave: `3194`, descripcion: `LIQUIDO PIROFORICO INORGANICO N.E.P.` },
    { clave: `3200`, descripcion: `SOLIDO PIROFORICO INORGANICO N.E.P.` },
    { clave: `3205`, descripcion: `ALCOHOLATOS DE METALES ALCALINOTERREOS, N.E.P.` },
    { clave: `3206`, descripcion: `ALCOHOLATOS DE METALES ALCALINOS QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, CORROSIVOS, N.E.P.` },
    { clave: `3208`, descripcion: `SUBSTANCIA METALICA QUE REACCIONA CON EL AGUA, N.E.P.` },
    { clave: `3209`, descripcion: `SUBSTANCIA METALICA QUE REACCIONA CON EL AGUA Y QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, N.E.P.` },
    { clave: `3210`, descripcion: `CLORATOS INORGANICOS EN SOLUCION ACUOSA, N.E.P.` },
    { clave: `3211`, descripcion: `PERCLORATOS INORGANICOS EN SOLUCION ACUOSA, N.E.P.` },
    { clave: `3212`, descripcion: `HIPOCLORITOS INORGANICOS, N.E.P.` },
    { clave: `3213`, descripcion: `BROMATOS INORGANICOS EN SOLUCION ACUOSA, N.E.P.` },
    { clave: `3214`, descripcion: `PERMANGANATOS INORGANICOS EN SOLUCION ACUOSA, N.E.P.` },
    { clave: `3215`, descripcion: `PERSULFATOS INORGANICOS, N.E.P.` },
    { clave: `3216`, descripcion: `PERSULFATOS INORGANICOS EN SOLUCION ACUOSA, N.E.P.` },
    { clave: `3218`, descripcion: `NITRATOS INORGANICOS EN SOLUCION ACUOSA, N.E.P.` },
    { clave: `3219`, descripcion: `NITRITOS INORGANICOS EN SOLUCION ACUOSA, N.E.P.` },
    { clave: `3220`, descripcion: `PENTAFLUOROETANO (GAS REFRIGERANTE R125)` },
    { clave: `3221`, descripcion: `LIQUIDO DE REACCION ESPONTANEA TIPO B` },
    { clave: `3222`, descripcion: `SOLIDO DE REACCION ESPONTANEA TIPO B` },
    { clave: `3223`, descripcion: `LIQUIDO DE REACCION ESPONTANEA TIPO C` },
    { clave: `3224`, descripcion: `SOLIDO DE REACCION ESPONTANEA TIPO C` },
    { clave: `3225`, descripcion: `LIQUIDO DE REACCION ESPONTANEA TIPO D` },
    { clave: `3226`, descripcion: `SOLIDO DE REACCION ESPONTANEA TIPO D` },
    { clave: `3227`, descripcion: `LIQUIDO DE REACCION ESPONTANEA TIPO E` },
    { clave: `3228`, descripcion: `SOLIDO DE REACCION ESPONTANEA TIPO E` },
    { clave: `3229`, descripcion: `LIQUIDO DE REACCION ESPONTANEA TIPO F` },
    { clave: `3230`, descripcion: `SOLIDO DE REACCION ESPONTANEA TIPO F` },
    { clave: `3231`, descripcion: `LIQUIDO DE REACCION ESPONTANEA TIPO B, CON TEMPERATURA REGULADA` },
    { clave: `3232`, descripcion: `SOLIDO DE REACCION ESPONTANEA TIPO B, CON TEMPERATURA REGULADA` },
    { clave: `3233`, descripcion: `LIQUIDO DE REACCION ESPONTANEA TIPO C, CON TEMPERATURA REGULADA` },
    { clave: `3234`, descripcion: `SOLIDO DE REACCION ESPONTANEA TIPO C, CON TEMPERATURA REGULADA` },
    { clave: `3235`, descripcion: `LIQUIDO DE REACCION ESPONTANEA TIPO D, CON TEMPERATURA REGULADA` },
    { clave: `3236`, descripcion: `SOLIDO DE REACCION ESPONTANEA TIPO D, CON TEMPERATURA REGULADA` },
    { clave: `3237`, descripcion: `LIQUIDO DE REACCION ESPONTANEA TIPO E, CON TEMPERATURA REGULADA` },
    { clave: `3238`, descripcion: `SOLIDO DE REACCION ESPONTANEA TIPO E, CON TEMPERATURA REGULADA` },
    { clave: `3239`, descripcion: `LIQUIDO DE REACCION ESPONTANEA TIPO F, CON TEMPERATURA REGULADA` },
    { clave: `3240`, descripcion: `SOLIDO DE REACCION ESPONTANEA TIPO F, CON TEMPERATURA REGULADA` },
    { clave: `3241`, descripcion: `2-BROMO-2-NITROPROPANO-1,3-DIOL` },
    { clave: `3242`, descripcion: `AZODICARBONAMIDA` },
    { clave: `3243`, descripcion: `SOLIDOS QUE CONTIENEN LIQUIDO TOXICO, N.E.P.` },
    { clave: `3244`, descripcion: `SOLIDOS QUE CONTIENEN LIQUIDO CORROSIVO, N.E.P.` },
    { clave: `3245`, descripcion: `MICROORGANISMOS MODIFICADOS GENETICAMENTE u ORGANISMOS MODIFICADOS GENETICAMENTE` },
    { clave: `3246`, descripcion: `CLORURO DE METANOSULFONILO` },
    { clave: `3247`, descripcion: `PEROXOBORATO DE SODIO ANHIDRO` },
    { clave: `3248`, descripcion: `MEDICAMENTO LIQUIDO, INFLAMABLE, TOXICO, N.E.P.` },
    { clave: `3249`, descripcion: `MEDICAMENTO SOLIDO, TOXICO, N.E.P.` },
    { clave: `3250`, descripcion: `ACIDO CLOROACETICO FUNDIDO` },
    { clave: `3251`, descripcion: `MONONITRATO-5-DE ISOSORBIDA` },
    { clave: `3252`, descripcion: `DIFLUOROMETANO (GAS REFRIGERANTE R32)` },
    { clave: `3253`, descripcion: `TRIOXOSILICATO DE DISODIO` },
    { clave: `3254`, descripcion: `TRIBUTILFOSFANO` },
    { clave: `3255`, descripcion: `HIPOCLORITO DE terc-BUTIL` },
    { clave: `3256`, descripcion: `LIQUIDO A TEMPERTURA ELEVADA, INFLAMABLE, N.E.P., de punto de inflamación superior a 60°C a una temperatura igual o superior al punto de inflamación` },
    { clave: `3257`, descripcion: `LIQUIDO A TEMPERATURA ELEVADA, N.E.P., a una temperatura igual o superior a 100°C e inferior a su punto de inflamación (incluidos los metales fundidos, las sales fundidas, etc.)` },
    { clave: `3258`, descripcion: `SOLIDO A TEMPERATURA ELEVADA, N.E.P., a una temperatura igual o superior a 240°C` },
    { clave: `3259`, descripcion: `AMINAS SOLIDAS, CORROSIVAS, N.E.P., o POLIAMINAS SOLIDAS, CORROSIVAS, N.E.P.` },
    { clave: `3260`, descripcion: `SOLIDO CORROSIVO, ACIDO, INORGANICO, N.E.P.` },
    { clave: `3261`, descripcion: `SOLIDO CORROSIVO, ACIDO, ORGANICO, N.E.P.` },
    { clave: `3262`, descripcion: `SOLIDO CORROSIVO, BASICO, INORGANICO, N.E.P.` },
    { clave: `3263`, descripcion: `SOLIDO CORROSIVO, BASICO, ORGANICO, N.E.P.` },
    { clave: `3264`, descripcion: `LIQUIDO CORROSIVO, ACIDO, INORGANICO, N.E.P.` },
    { clave: `3265`, descripcion: `LIQUIDO CORROSIVO, ACIDO, ORGANICO, N.E.P.` },
    { clave: `3266`, descripcion: `LIQUIDO CORROSIVO, BASICO, INORGANICO, N.E.P.` },
    { clave: `3267`, descripcion: `LIQUIDO CORROSIVO, BASICO, ORGANICO, N.E.P.` },
    { clave: `3268`, descripcion: `INFLADORES DE BOLSAS NEUMÁTICAS O MÓDULOS DE BOLSAS NEUMÁTICAS O PRETENSORES DE CINTURONES DE SEGURIDAD` },
    { clave: `3269`, descripcion: `BOLSA  PARA RESINA POLIESTERICA` },
    { clave: `3270`, descripcion: `FILTROS DE MEMBRANAS NITROCELULOSICAS, con un máximo de 12.6% de nitrógeno, por masa seca` },
    { clave: `3271`, descripcion: `ETERES, N.E.P.` },
    { clave: `3273`, descripcion: `NITRILOS INFLAMABLES, TOXICOS, N.E.P.` },
    { clave: `3274`, descripcion: `ALCOHOLATOS EN SOLUCION, N.E.P. en alcohol` },
    { clave: `3275`, descripcion: `NITRILOS TOXICOS, INFLAMABLES, N.E.P.` },
    { clave: `3276`, descripcion: `NITRILOS TOXICOS, N.E.P.` },
    { clave: `3277`, descripcion: `CLOROFORMIATOS TOXICOS, CORROSIVOS, N.E.P.` },
    { clave: `3278`, descripcion: `COMPUESTO ORGANOFOSFORADO TÓXICO, LÍQUIDO N.E.P.` },
    { clave: `3279`, descripcion: `COMPUESTOS ORGANOFOSFORADOS TOXICOS, INFLAMABLES, N.E.P.` },
    { clave: `3280`, descripcion: `COMPUESTO ORGANOARSENICAL LIQUIDO N.E.P.,` },
    { clave: `3281`, descripcion: `CARBONILOS METALICOS, LIQUIDO N.E.P.` },
    { clave: `3282`, descripcion: `COMPUESTOS ORGANOMETALICOS TOXICOS,  LIQUIDO N.E.P.` },
    { clave: `3283`, descripcion: `COMPUESTO DE SELENIO, SOLIDO N.E.P.` },
    { clave: `3284`, descripcion: `COMPUESTO DE TELURIO, N.E.P.` },
    { clave: `3285`, descripcion: `COMPUESTO DE VANADIO N.E.P.` },
    { clave: `3286`, descripcion: `LIQUIDO INFLAMABLE, TOXICO, CORROSIVO, N.E.P.` },
    { clave: `3287`, descripcion: `LIQUIDO TOXICO, INORGANICO, N.E.P.` },
    { clave: `3288`, descripcion: `SOLIDO TOXICO, INORGANICO, N.E.P.` },
    { clave: `3289`, descripcion: `LIQUIDO TOXICO, CORROSIVO, INORGANICO, N.E.P.` },
    { clave: `3290`, descripcion: `SOLIDO TOXICO, CORROSIVO, INORGANICO, N.E.P.` },
    { clave: `3291`, descripcion: `DESECHOS CLIINICOS, N.E.P., o DESECHOS (BIO)MEDICOS, N.E.P., o DESECHOS MEDICOS REGULADOS, N.E.P.` },
    { clave: `3292`, descripcion: `BATERIAS QUE CONTIENEN SODIO o ELEMENTOS DE BATERIA QUE CONTIENEN SODIO` },
    { clave: `3293`, descripcion: `HIDRAZINA EN SOLUCION ACUOSA con un máximo de 37%, en masa, de hidracina` },
    { clave: `3294`, descripcion: `CIANURO DE HIDROGENO EN SOLUCION ALCOHOLICA, con no más de 45% de cianuro de hidrógeno` },
    { clave: `3295`, descripcion: `HIDROCARBUROS LIQUIDOS, N.E.P.` },
    { clave: `3296`, descripcion: `HEPTAFLUOROPROPANO (GAS REFRIGERANTE R 227)` },
    { clave: `3297`, descripcion: `MEZCLAS DE OXIDO DE ETILENO Y CLOROTETRAFLUOROETANO con no más de 8,8% de óxido de etileno` },
    { clave: `3298`, descripcion: `MEZCLAS DE OXIDO DE ETILENO Y PENTAFLUOROETANO con no más de 7,9% de óxido de etileno` },
    { clave: `3299`, descripcion: `MEZCLAS DE OXIDO DE ETILENO Y TETRAFLUOROETANO con no más de 5,6% de óxido de etileno` },
    { clave: `3300`, descripcion: `MEZCLAS DE OXIDO DE ETILENO Y DIOXIDO DE CARBONO, con no más de 87% de óxido de etileno` },
    { clave: `3301`, descripcion: `LIQUIDO CORROSIVO QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO, N.E.P.` },
    { clave: `3302`, descripcion: `ACRILATO 2-DIMETILAMINOETILICO` },
    { clave: `3303`, descripcion: `GAS COMPRIMIDO, TOXICO, OXIDANTE, N.E.P.` },
    { clave: `3304`, descripcion: `GAS COMPRIMIDO, TOXICO, CORROSIVO, N.E.P.` },
    { clave: `3305`, descripcion: `GAS COMPRIMIDO, TOXICO, INFLAMABLE, CORROSIVO, N.E.P.` },
    { clave: `3306`, descripcion: `GAS COMPRIMIDO, TOXICO, OXIDANTE, CORROSIVO, N.E.P.` },
    { clave: `3307`, descripcion: `GAS LICUADO, TOXICO, OXIDANTE, N.E.P.` },
    { clave: `3308`, descripcion: `GAS LICUADO, TOXICO, CORROSIVO, N.E.P.` },
    { clave: `3309`, descripcion: `GAS LICUADO, TOXICO, INFLAMABLE, CORROSIVO, N.E.P.` },
    { clave: `3310`, descripcion: `GAS LICUADO, TOXICO, OXIDANTE, CORROSIVO, N.E.P.` },
    { clave: `3311`, descripcion: `GAS, LIQUIDO REFRIGERADO, OXIDANTE, N.E.P.` },
    { clave: `3312`, descripcion: `GAS, LIQUIDO REFRIGERADO, INFLAMABLE, N.E.P.` },
    { clave: `3313`, descripcion: `PIGMENTOS ORGANICOS QUE EXPERIMENTAN UN CALENTAMIENTO ESPONTANEO` },
    { clave: `3314`, descripcion: `COMPUESTO PARA EL MOLDEADO DE PLASTICOS en forma de pasta, hoja o cuerda estirada que desprende vapores inflamables` },
    { clave: `3315`, descripcion: `MUESTRA QUIMICA TOXICA, líquida o sólida` },
    { clave: `3316`, descripcion: `EQUIPO QUÍMICO o BOTIQUÍN DE URGENCIA` },
    { clave: `3317`, descripcion: `2-AMINO-4,6-DINITROFENOL, HUMIDIFICADO con una proporción de agua, en masa, no inferior al 20%` },
    { clave: `3318`, descripcion: `SOLUCION ACUOSA DE AMONIACO, con una densidad relativa menor de 0,880 a 15°C, con más de 50% de amoniaco` },
    { clave: `3319`, descripcion: `MEZCLA DE NITROGLICERINA, DESENSIBILIZADA, SOLIDA, N.E.P. con más de 2% pero no más de 10%, en masa, de nitroglicerina` },
    { clave: `3320`, descripcion: `BOROHIDRURO SODIO Y SOLUCION DE HIDROXIDO SODIO con no más de 12% de borohidruro SODIO y no más de 40%, en masa, de hidróxido SODIO` },
    { clave: `3321`, descripcion: `MATERIALES RADIACTIVOS, BAJA ACTIVIDAD ESPECIFICA (BAE-II), no fisionables o fisionables exceptuados` },
    { clave: ` 3322`, descripcion: ` MATERIALES RADIACTIVOS, BAJA ACTIVIDAD ESPECIFICA (BAE-III), no fisionables o fisionables exceptuados` },
    { clave: ` 3323`, descripcion: ` MATERIALES RADIACTIVOS, BULTOS DE TIPO C, no fisionables o fisionables exceptuados` },
    { clave: ` 3324`, descripcion: ` MATERIALES RADIACTIVOS, BAJA ACTIVIDAD ESPECIFICA (BAE-II), FISIONABLES` },
    { clave: `3325`, descripcion: `MATERIALES RADIACTIVOS, BAJA ACTIVIDAD ESPECIFICA (BAE-III), FISIONABLES` },
    { clave: `3326`, descripcion: `MATERIALES RADIACTIVOS, OBJETOS CONTAMINADOS EN LA SUPERFICIE (OCS-I u OCS-II), FISIONABLES` },
    { clave: `3327`, descripcion: `MATERIALES RADIACTIVOS, BULTOS DE TIPO A, FISIONABLES, no en forma especial` },
    { clave: `3328`, descripcion: `MATERIALES RADIACTIVOS, BULTOS DE TIPO B(U), FISIONABLES` },
    { clave: `3329`, descripcion: `MATERIALES RADIACTIVOS, BULTOS DE TIPO B(M), FISIONABLES` },
    { clave: `3330`, descripcion: `MATERIALES RADIACTIVOS, BULTOS DE TIPO C, FISIONABLES` },
    { clave: `3331`, descripcion: `MATERIALES RADIACTIVOS TRANSPORTADOS EN VIRTUD DE ARREGLOS ESPECIALES, FISIONABLES` },
    { clave: `3332`, descripcion: `MATERIALES RADIACTIVOS, BULTO DE TIPO A, FORMA ESPECIAL, no fisionables o fisionables exceptuados` },
    { clave: `3333`, descripcion: `MATERIALES RADIACTIVOS, BULTO DE TIPO A, FORMA ESPECIAL, FISIONABLES` },
    { clave: `3334`, descripcion: `LIQUIDO REGULADO PARA AVIACIÓN, N.E.P.` },
    { clave: `3335`, descripcion: `SOLIDO REGULADO PARA AVIACIÓN, N.E.P.` },
    { clave: `3336`, descripcion: `MERCAPTANOS, LIQUIDOS, INFLAMABLES, N.E.P., o MEZCLA DE MERCAPTANOS, LIQUIDOS, INFLAMABLES, N.E.P.` },
    { clave: `3337`, descripcion: `GAS REFRIGERANTE R 404ª` },
    { clave: `3338`, descripcion: `GAS REFRIGERANTE R 407ª MEZCLA AZEOTROPICA` },
    { clave: `3339`, descripcion: `GAS REFRIGERANTE R 407B MEZCLA AZEOTROPICA` },
    { clave: `3340`, descripcion: `GAS REFRIGERANTE R 407C` },
    { clave: `3341`, descripcion: `DIOXIDO DE TIOUREA` },
    { clave: `3342`, descripcion: `XANTATOS` },
    { clave: `3343`, descripcion: `MEZCLA DE NITROGLICERINA, DESENSIBILIZADA, LIQUIDA, INFLAMABLE, N.E.P., con no más de 30% de nitroglicerina, en masa` },
    { clave: `3344`, descripcion: `TETRANITRATO DE PENTAERITRITA, (TETRANITRATO DE PENTAERITRITROL; TNPE) EN MEZCLA DE DESENSIBILIZADO, SOLIDO, N.E.P., con más de 10% pero no más de 20% en masa, de TNPE` },
    { clave: `3345`, descripcion: `PLAGUICIDA DERIVADO DEL ACIDO FENOXIACETICO, SOLIDO, TOXICO` },
    { clave: `3346`, descripcion: `PLAGUICIDA DERIVADO DEL ACIDO FENOXIACETICO, LIQUIDO, INFLAMABLE, TOXICO de punto de inflamación inferior a 23°C` },
    { clave: `3347`, descripcion: `PLAGUICIDA DERIVADO DEL ACIDO FENOXIACETICO, LIQUIDO, TOXICO, INFLAMABLE, de punto de inflamación igual o superior a 23°C` },
    { clave: `3348`, descripcion: `PLAGUICIDA DERIVADO DEL ACIDO FENOXIACETICO, LIQUIDO, TOXICO` },
    { clave: `3349`, descripcion: `PLAGUICIDA PIRETROIDEO, SOLIDO, TOXICO` },
    { clave: `3350`, descripcion: `PLAGUICIDA PIRETROIDEO, LIQUIDO, INFLAMABLE, TOXICO, de punto de inflamación inferior a 23°C` },
    { clave: `3351`, descripcion: `PLAGUICIDA PIRETROIDEO, LIQUIDO, TOXICO, INFLAMABLE, de punto de inflamación no inferior a 23°C` },
    { clave: `3352`, descripcion: `PLAGUICIDA PIRETROIDEO, LIQUIDO, TOXICO` },
    { clave: `3354`, descripcion: `GAS INSECTICIDA, INFLAMABLE, N.E.P.` },
    { clave: `3355`, descripcion: `GAS INSECTICIDA, TOXICO, INFLAMABLE, N.E.P.` },
    { clave: `3356`, descripcion: `GENERADOR QUIMICO DE OXIGENO` },
    { clave: `3357`, descripcion: `MEZCLA DE NITROGLICERINA, DESENSIBILIZADA, LIQUIDA, N.E.P. con un máximo de 30% de nitroglicerina, por masa` },
    { clave: `3358`, descripcion: `MAQUINAS REFRIGERADORAS que contengan gas líquido inflamable, no tóxico` },
    { clave: `3359`, descripcion: `UNIDAD DE TRANSPORTE DE MATERIALES SOMETIDA A FUMIGACION` },
    { clave: `3360`, descripcion: `FIBRAS DE ORIGEN VEGETAL, SECAS` },
    { clave: `3361`, descripcion: `CLOROSILANOS TOXICOS CORROSIVOS, N.E.P.` },
    { clave: `3362`, descripcion: `CLOROSILANOS TOXICOS CORROSIVOS INFLAMABLES, N.E.P.` },
    { clave: `3363`, descripcion: `MERCANCIAS PELIGROSAS EN MAQUINARIA o MERCANCIAS PELIGROSAS EN APARATOS` },
    { clave: `3364`, descripcion: `TRINITROFENOL (ACIDO PICRICO) HUMIDIFICADO con un mínimo de 10%, en masa, de agua` },
    { clave: `3365`, descripcion: `TRINITROCLOROBENCENO (CLORURO DE PICRILO) HUMIDIFICADO con un mínimo de 10%, en masa, de agua` },
    { clave: `3366`, descripcion: `TRINITROTOLUENO (TNT) HUMIDIFICADO con un mínimo de 10%, en masa, de agua` },
    { clave: `3367`, descripcion: `TRINITROBENCENO HUMIDIFICADO con un mínimo de 10%, en masa, de agua` },
    { clave: `3368`, descripcion: `ACIDO TRINITROBENZOICO HUIMIDIFICADO con un mínimo de 10%, en masa, de agua` },
    { clave: `3369`, descripcion: `DINITRO-o-CRESOLATO SODIO HUMIDIFICADO con un mínimo de 10%, en masa, de agua` },
    { clave: `3370`, descripcion: `NITRATO DE UREA HUMIDIFICADO con un mínimo de 10%, en masa, del agua` },
    { clave: `3371`, descripcion: `2-METILBUTANAL` },
    { clave: `3373`, descripcion: `SUBSTANCIA BIOLOGICA CATEGORIA B` },
    { clave: `3374`, descripcion: `ACETILENO EXENTO DE SOLVENTE` },
    { clave: `3375`, descripcion: `EMULSIÓN DE NITRATO DE AMONIO o SUSPENSIÓN o GEL, explosivos intermediarios para voladuras` },
    { clave: `3376`, descripcion: `4-NITROFENILHIDRAZINA con un máximo de 30%, en masa, de agua` },
    { clave: `3377`, descripcion: `PERBORATO DE SODIO MONOHIDRATADO` },
    { clave: `3378`, descripcion: `CARBONATO DE SODIO PEROXIHIDRATADO` },
    { clave: `3379`, descripcion: `EXPLOSIVO DESENSIBILIZADO, LIQUIDO, N.E.P.` },
    { clave: `3380`, descripcion: `EXPLOSIVO DESENSIBILIZADO, SOLIDO, N.E.P` },
    { clave: `3381`, descripcion: `LÍQUIDO TÓXICO POR INHALACIÓN,N.E.P.,con toxicidad por  inhalación inferior o igual a 200 ml/m3 y con concentración saturada de vapor superior o igual a 500 CL50 ` },
    { clave: `3382`, descripcion: `LÍQUIDO  TÓXICO POR INHALACIÓN, N.E.P., con toxicidad por inhalación inferior o igual a 1000 ml/m3 y con concentración saturada de vapor superior o igual a 10 CL50` },
    { clave: `3383`, descripcion: `LÍQUIDO TÓXICO POR INHALACION, INFLAMABLE,      N.E.P.,    CON TOXICIDAD POR INHALACION INFERIOR O IGUAL A 200 ml/m3 y con concentración     saturada     de     vapor superior o igual a 500 CL50` },
    { clave: `3384`, descripcion: `LÍQUIDO TÓXICO POR INHALACIÓN, INFLAMABLE, N.E.P., con toxicidad por inhalación Inferior o igual a 1000 ml/m3 y concentración saturada de vapor Superior o igual a 10 CL50` },
    { clave: `3385`, descripcion: `LÍQUIDO TÓXICO POR INHALACIÓN, HIDRORREACTIVO, N.E.P., con toxicidad por inhalación Inferior o igual a 200 ml/m3 y con Concentración saturada de vapor superior o igual a 500 CL50` },
    { clave: `3386`, descripcion: `LÍQUIDO TÓXICO POR INHALACIÓN, HIDRORREACTIVO, N.E.P., con toxicidad por inhalación Inferior o igual a 1000 ml/m3 y con concentración saturada de vapor Superior o igual a 10 CL50` },
    { clave: `3387`, descripcion: `LÍQUIDO TÓXICO POR INHALACIÓN, COMBURENTE, N.E.P., con toxicidad por inhalación inferior o igual a 200 ml/m3 y con Concentración saturada de vapor superior o igual a 500 CL50` },
    { clave: `3388`, descripcion: `LÍQUIDO TÓXICO POR INHALACIÓN, COMBURENTE, N.E.P., con toxicidad por inhalación Inferior o igual a 1000 ml/m3 y con concentración saturada de vapor superior o igual a 10 CL50` },
    { clave: `3389`, descripcion: `LÍQUIDO TÓXICO POR INHALACIÓN, CORROSIVO,N.E.P., Con toxicidad por inhalación inferior o igual a 200 ml/m3 y con concentración Saturada de vapor superior o igual a 500 CL50` },
    { clave: `3390`, descripcion: `LÍQUIDO  TÓXICO  POR INHALACIÓN, CORROSIVO, N.E.P., Con toxicidad por inhalación inferior o igual a 1000 ml/m3 y con concentración saturada de vapor superior o igual a  10 CL50` },
    { clave: `3391`, descripcion: `SUBSTANCIA  ORGANOMETALICA, SOLIDA, PIROFORICA` },
    { clave: `3392`, descripcion: `SUBSTANCIA ORGANOMETALICA, LIQUIDA,PIROFORICA` },
    { clave: `3393`, descripcion: `SUBSTANCIA ORGANOMETÁLICA, SÓLIDA, PIROFÓRICA HIDRORREACTIVA` },
    { clave: `3394`, descripcion: `SUBSTANCIA ORGANOMETALICA, LIQUIDA, PIROFORICA, HIDRORREACTIVA` },
    { clave: `3395`, descripcion: `SUBSTANCIA ORGANOMETALICA, SOLIDA, HIDRORREACTIVA` },
    { clave: `3396`, descripcion: `SUBSTANCIA ORGANOMETALICA, SOLIDA, HIDRORREACTIVA, INFLAMABLE` },
    { clave: `3397`, descripcion: `SUBSTANCIA ORGANOMETALICA SOLIDA, HIDRORREACTIVA, QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO` },
    { clave: `3398`, descripcion: `SUBSTANCIA ORGANOMETALICA, LIQUIDA, HIDRORREACTIVA` },
    { clave: `3399`, descripcion: `SUBSTANCIA ORGANOMETALICA, LIQUIDA, HIDRORREACTIVA, INFLAMABLE` },
    { clave: `3400`, descripcion: `SUBSTANCIA ORGANOMETALICA, SOLIDA, QUE EXPERIMENTA CALENTAMIENTO ESPONTANEO` },
    { clave: `3401`, descripcion: `METALES ALCALINOS, AMALGAMA SOLIDA DE` },
    { clave: `3402`, descripcion: `METALES ALCALINOTERREOS AMALGAMA SOLIDA DE` },
    { clave: `3403`, descripcion: `POTASIO METALICO, ALEACIONES SOLIDAS DE` },
    { clave: `3404`, descripcion: `POTASIO Y SODIO, ALEACIONES SOLIDAS DE` },
    { clave: `3405`, descripcion: `CLORATO DE BARIO EN SOLUCION` },
    { clave: `3406`, descripcion: `PERCLORATO DE BARIO EN SOLUCION` },
    { clave: `3407`, descripcion: `CLORATO Y CLORURO DE MAGNESIO EN SOLUCION, MEZCLA DE` },
    { clave: `3408`, descripcion: `PERCLORATO DE PLOMO EN SOLUCION` },
    { clave: `3409`, descripcion: `CLORONITROBENCENOS LIQUIDOS` },
    { clave: `3410`, descripcion: `CLORHIDRATO DE 4-CLORO-0-TOLUIDINA EN SOLUCION` },
    { clave: `3411`, descripcion: `beta-NAFTILAMINA EN SOLUCION` },
    { clave: `3412`, descripcion: `ACIDO FORMICO con mínimo de 10% y un máximo de 85%, en masa de ácido` },
    { clave: `3412`, descripcion: `ACIDO FORMICO con un mínimo de 5% y un máximo de 10%, en masa de  ácido` },
    { clave: `3413`, descripcion: `CIANURO POTASICO EN SOLUCION` },
    { clave: `3414`, descripcion: `CIANURO DE SODIO EN SOLUCION` },
    { clave: `3415`, descripcion: `FLORURO DE SODIO EN SOLUCION` },
    { clave: `3416`, descripcion: `CLOROACETOFENONA LIQUIDA` },
    { clave: `3417`, descripcion: `BROMURO DE XILILO, SOLIDO` },
    { clave: `3418`, descripcion: `TOLUILEN-2,4-DIAMINA EN SOLUCION` },
    { clave: `3419`, descripcion: `TRIFLUORURO DE BORO Y ACIDO ACETICO, COMPLEJO SOLIDO DE` },
    { clave: `3420`, descripcion: `TRIFLUORURO DE BORO Y ÁCIDO PROPIÓNICO, COMPLEJO SÓLIDO DE` },
    { clave: `3421`, descripcion: `HIDROGENODIFLURURO DE POTASIO EN SOLUCION` },
    { clave: `3422`, descripcion: `FLUORURO POTASICO EN SOLUCION` },
    { clave: `3423`, descripcion: `HIDROXIDO DE TETRAMETILAMONIO SOLIDO` },
    { clave: `3424`, descripcion: `DINITRO-0-CRESOLATO AMONICO EN SOLUCION` },
    { clave: `3425`, descripcion: `ACIDO BROMOACETICO SOLIDO` },
    { clave: `3426`, descripcion: `ACRILAMIDA EN SOLUCION` },
    { clave: `3427`, descripcion: `CLORUROS DE CLOROBENCILO, SOLIDOS` },
    { clave: `3428`, descripcion: `ISOCIANATO DE 3-CLORO-4- METILFENILO, SOLIDO` },
    { clave: `3429`, descripcion: `CLOROTOLUIDINAS LIQUIDAS` },
    { clave: `3430`, descripcion: `XILENOLES LIQUIDOS` },
    { clave: `3431`, descripcion: `NITROBENZOTRIFLUORUROS SOLIDOS` },
    { clave: `3432`, descripcion: `DIFELINOS POLICLORADOS SOLIDOS` },
    { clave: `3434`, descripcion: `NITROCRESOLES LIQUIDOS` },
    { clave: `3436`, descripcion: `HIDRATO DE HEXAFLUORACETONA, SOLIDO` },
    { clave: `3437`, descripcion: `CLOROCRESOLES SOLIDOS` },
    { clave: `3438`, descripcion: `ALCOHOL alfa-METILBENCILICO SOLIDO` },
    { clave: `3439`, descripcion: `NITRILOS TOXICOS SOLIDOS, N.E.P.` },
    { clave: `3440`, descripcion: `COMPUESTO DE SELENIO LIQUIDO, N,E.P.` },
    { clave: `3441`, descripcion: `CLORODINITROBECENOS SOLIDOS` },
    { clave: `3442`, descripcion: `DICLOROANILINAS SOLIDAS` },
    { clave: `3443`, descripcion: `DINITROBECENOS SOLIDOS` },
    { clave: `3444`, descripcion: `CLORHIDRATO DE NICOTINA SOLIDO` },
    { clave: `3445`, descripcion: `SULFATO DE NICOTINA SOLIDO` },
    { clave: `3446`, descripcion: `NITROTOLUENOS SOLIDOS` },
    { clave: `3447`, descripcion: `NITROXILEENOS SOLIDOS` },
    { clave: `3448`, descripcion: `GASES LACRIMÓGENOS, SUBSTANCIA SÓLIDA PARA LA FABRICACIÓN, N.E.P.` },
    { clave: `3449`, descripcion: `CIANUROS DE BROMOBENCILO SOLIDOS` },
    { clave: `3450`, descripcion: `DIFENICLOROARSINA SOLIDA` },
    { clave: `3451`, descripcion: `TOLUIDINAS SOLIDAS` },
    { clave: `3452`, descripcion: `XILIDINAS SOLIDAS` },
    { clave: `3453`, descripcion: `ÁCIDO FOSFÓRICO SÓLIDO` },
    { clave: `3454`, descripcion: `DINITROTOLUENOS SOLIDOS` },
    { clave: `3455`, descripcion: `CRESOLES SOLIDOS` },
    { clave: `3456`, descripcion: `ACIDO NITROSILSULFURICO SOLIDO` },
    { clave: `3457`, descripcion: `CLORONITROTOLUENOS SOLIDOS` },
    { clave: `3458`, descripcion: `NITROANISOL SOLIDO` },
    { clave: `3459`, descripcion: `NITROBROMOBENCENOS SOLIDOS` },
    { clave: `3460`, descripcion: `N-ETILBENCILTOLUIDINAS SOLIDAS` },
    { clave: `3462`, descripcion: `TOXINAS EXTRAIDAS DE UN MEDIO VIVO, SOLIDAS,N.E.P.` },
    { clave: `3463`, descripcion: `ACIDO PEOPIONICO con mínimo de 90%, en masa, de ácido` },
    { clave: `3464`, descripcion: `COMPUESTO ORGANOFOSFORADO TOXICO, SOLIDO, N.E.P.` },
    { clave: `3465`, descripcion: `COMPUESTO ORGANOARSENICAL, SOLIDO, N.E.P.` },
    { clave: `3466`, descripcion: `CARBONILOS METALICOS, SOLIDOS, N.E.P.` },
    { clave: `3467`, descripcion: `COMPUESTO ORGANOMETALICO TOXICO, SOLIDO, N.E.P.` },
    { clave: `3468`, descripcion: `HIDROGENO EN DISPOSITIVO DE ALMACENAMIENTO CON HIDRURO METALICO O HIDROGENO CON UN DISPOSITIVO DE ALMACENAMIENTO CON HIDRURO METALICO INSTALADO EN UN EQUIPO o HIGROGENO EN UN DISPOSITIVO DE ALMACENAMIENTO CON HIDRURO METALICO EMBALADO CON EQUIPO` },
    { clave: `3469`, descripcion: `PINTURAS INFLAMABLES, CORROSIVAS (incluidos pinturas, lacas, esmaltes, colores, goma laca, barnices, bruñidores, encáusticos, bases líquidas para lacas) o MATERIAL INFLAMABLE, CORROSIVO RELACIONADO CON PINTURAS (incluidos disolventes y diluyentes para pinturas)` },
    { clave: `3470`, descripcion: `PINTURAS CORROSIVAS INFLAMABLES (incluidos pinturas, lacas, esmaltes, colores, goma laca barnices, bruñidores, encáusticos, bases liquidas para lacas) o MATERIAL CORROSIVO, INFLAMABLE RELACIONADO CON PINTURAS (incluidos disolventes y diluyentes para pinturas)` },
    { clave: `3471`, descripcion: `HIDROGENODIFLUORUROS EN SOLUCION, N.E.P.` },
    { clave: `3472`, descripcion: `ACIDO CROTONICO LIQUIDO` },
    { clave: `3473`, descripcion: `CARTUCHOS PARA PILAS DE COMBUSTIBLE  o CARTUCHOS PARA PILAS DE COMBUSTIBLE INSTALADOS EN UN EQUIPO o CARTUCHOS PARA PILAS DE COMBUSTIBLE EMBALADOS CON UN EQUIPO,  que contienen líquidos inflamables)` },
    { clave: `3474`, descripcion: `1HIDROXIBENZOTRIAZOL, MONOHIDRATADO` },
    { clave: `3475`, descripcion: `MEZCLA DE ETANOL Y GASOLINA 0 MEZLA DE ETANOL Y COMBUSTIBLE PARA MOTORES Con más del 10% de etanol` },
    { clave: `3476`, descripcion: `CARTUCHOS PARA PILAS DE COMBUSTIBLE 0 CARTUCHOS PARA PILAS DE COMBUSTIBLE INSTALADOS EN UN EQUIPO o CARTUCHOS PARA PILAS DE COMBUSTIBLE CONTENIDOS EN UN EQUIPO, que contienen substancias que reaccionan con el agua` },
    { clave: `3477`, descripcion: `CARTUCHOS PARA PILAS DE  COMBUSTIBLE o CARTUCHOS PARA PILAS DE COMBUSTIBLE INSTALADOS EN UN EQUIPO o CARTUCHOS PARA PILAS DE COMBUSTIBLE EMBALADOS CON UN EQUIPO, que contienen SUBSTANCIAS corrosivas` },
    { clave: `3478`, descripcion: `CARTUCHOS PARA PILAS DE COMBUSTIBLE o CARTUCHOS PARA PILAS DE COMBUSTIBLE INSTALADOS EN UN EQUIPO o CARTUCHOS PARA PILAS D COMBUSTIBLES EMBALADOS CON UN EQUIPO, que contienen gas licuado inflamable` },
    { clave: `3479`, descripcion: `CARTUCHOS PARA PILAS DE COMBUSTIBLE o CARTUCHOS PARA PILAS DE COMBUSTIBLE INSTALADOS EN UN EQUIPO o CARTUCHOS PARA PILAS DE COMBUSTIBLE CONTENIDOS EN UN EQUIPO, que contienen hidrógeno en un hidruro metálico` },
    { clave: `3480`, descripcion: `BATERIAS DE ION LITIO (incluidas Las baterías polimétricas de ión litio)` },
    { clave: `3481`, descripcion: `BATERIAS DE ION LITIO INSTALADAS EN UN EQUIPO o BATERIAS DE ION LITIO EMBALADAS CON EQUIPO (incluidas las baterías poliméricas de ión litio)` },
    { clave: `3482`, descripcion: `DISPERSION DE METALES ALCALINOS, INFLAMABLE o DISPERSION DE METALES ALCALINOTERREOS, INFLAMABLE` },
    { clave: `3483`, descripcion: `MEZCLA ANTIDETONANTE PARA COMBUSTIBLES DE MOTORES, INFLAMABLE` },
    { clave: `3484`, descripcion: `HIDRAZINA EN SOLUCION ACUOSA, INFLAMABLE, con más del 37%, en masa, de hidrazina` },
    { clave: `3485`, descripcion: `HIPOCLORITO DE CALCIO SECO, CORROSIVO o HIPOCLORITO DE CALCICO EN MEZCLA SECA, CORROSIVO con más del 39% de cloro activo (8,8% de oxígeno activo)` },
    { clave: `3486`, descripcion: `HIPOCLORITO DE CALCICO EN MEZCLA SECA, CORROSIVO con más del 10% pero no más del 39% de cloro activo` },
    { clave: `3487`, descripcion: `HIPOCLORITO DE CALCICO HIDRATADO, CORROSIVO o HIPOCLORITO DE CALCIO HIDRATADO EN MEZCLA, CORROSIVO, con no menos del 5,5% pero no más del 16% de agua` },
    { clave: `3488`, descripcion: `LIQUIDO TOXICO POR INHALACION, INFLAMABLE, CORROSIVO, N.E.P., con toxicidad por inhalación inferior o igual a 200 ml/m3 y con concentración saturada de vapor superior o igual a 500 CL50` },
    { clave: `3489`, descripcion: `LIQUIDO TOXICO POR INHALACION, INFLAMABLE, CORROSIVO, N.E.P., con toxicidad por inhalación inferior o igual a 1.000 ml/m3 y con concentración saturada de vapor superior o igual a 10 CL50` },
    { clave: `3490`, descripcion: `LIQUIDO TOXICO POR INHALACION, HIDRORREACTIVO, INFLAMABLE, N.E.P., con toxicidad por inhalación inferior o igual a 200 ml/m3 y con concentración saturada de vapor superior o igual a 500 CL50` },
    { clave: `3491`, descripcion: `LIQUIDO TOXICO POR INHALACION, HIDRORREACTIVO, INFLAMABLE, N.E.P., con toxicidad por inhalación inferior o igual a 1.000 ml/m3 y con concentración saturada de vapor superior o igual a 10 CL50` },
    { clave: `3492`, descripcion: `LIQUIDO TOXICO POR INHALACION, CORROSIVO, N.E.P., con toxicidad por inhalación inferior o igual a 200 ml/m3 y con concentración saturada de vapor superior o igual a 500 CL50` },
    { clave: `3493`, descripcion: `LIQUIDO TOXICO POR INHALACION, CORROSIVO, INFLAMABLE, N.E.P., con toxicidad por inhalación inferior o igual a 1.000 ml/m3 y con concentración saturada de vapor superior o igual a 10 CL50` },
    { clave: `3494`, descripcion: `PETROLEO CRUDO ACIDO, INFLAMABLE, TOXICO` },
    { clave: `3495`, descripcion: `YODO` },
    ];
    const CAT_EMBALAJES = [{ clave: `1A1`, descripcion: `Bidones (Tambores) de Acero 1 de tapa no desmontable` },
    { clave: `1A2`, descripcion: `Bidones (Tambores) de Acero 1 de tapa desmontable` },
    { clave: `1B1`, descripcion: `Bidones (Tambores) de Aluminio de tapa no desmontable` },
    { clave: `1B2`, descripcion: `Bidones (Tambores) de Aluminio de tapa desmontable` },
    { clave: `1D`, descripcion: `Bidones (Tambores) de Madera contrachapada` },
    { clave: `1G`, descripcion: `Bidones (Tambores) de Cartón` },
    { clave: `1H1`, descripcion: `Bidones (Tambores) de Plástico de tapa no desmontable` },
    { clave: `1H2`, descripcion: `Bidones (Tambores) de Plástico de tapa desmontable` },
    { clave: `1N1`, descripcion: `Bidones (Tambores) de Metal que no sea acero ni aluminio de tapa no desmontable` },
    { clave: `1N2`, descripcion: `Bidones (Tambores) de Metal que no sea acero ni aluminio de tapa desmontable` },
    { clave: `3A1`, descripcion: `Jerricanes (Porrones) de Acero de tapa no desmontable` },
    { clave: `3A2`, descripcion: `Jerricanes (Porrones) de Acero de tapa desmontable` },
    { clave: `3B1`, descripcion: `Jerricanes (Porrones) de Aluminio de tapa no desmontable` },
    { clave: `3B2`, descripcion: `Jerricanes (Porrones) de Aluminio de tapa desmontable` },
    { clave: `3H1`, descripcion: `Jerricanes (Porrones) de Plástico de tapa no desmontable` },
    { clave: `3H2`, descripcion: `Jerricanes (Porrones) de Plástico de tapa desmontable` },
    { clave: `4A`, descripcion: `Cajas de Acero` },
    { clave: `4B`, descripcion: `Cajas de Aluminio` },
    { clave: `4C1`, descripcion: `Cajas de Madera natural ordinaria` },
    { clave: `4C2`, descripcion: `Cajas de Madera natural de paredes a prueba de polvos (estancas a los pulverulentos)` },
    { clave: `4D`, descripcion: `Cajas de Madera contrachapada` },
    { clave: `4F`, descripcion: `Cajas de Madera reconstituida` },
    { clave: `4G`, descripcion: `Cajas de Cartón` },
    { clave: `4H1`, descripcion: `Cajas de Plástico Expandido` },
    { clave: `4H2`, descripcion: `Cajas de Plástico Rígido` },
    { clave: `5H1`, descripcion: `Sacos (Bolsas) de Tejido de plástico sin forro ni revestimientos interiores` },
    { clave: `5H2`, descripcion: `Sacos (Bolsas) de Tejido de plástico a prueba de polvos (estancos a los pulverulentos)` },
    { clave: `5H3`, descripcion: `Sacos (Bolsas) de Tejido de plástico resistente al agua` },
    { clave: `5H4`, descripcion: `Sacos (Bolsas) de Película de plástico` },
    { clave: `5L1`, descripcion: `Sacos (Bolsas) de Tela sin forro ni revestimientos interiores` },
    { clave: `5L2`, descripcion: `Sacos (Bolsas) de Tela a prueba de polvos (estancos a los pulverulentos)` },
    { clave: `5L3`, descripcion: `Sacos (Bolsas) de Tela resistentes al agua` },
    { clave: `5M1`, descripcion: `Sacos (Bolsas) de Papel de varias hojas` },
    { clave: `5M2`, descripcion: `Sacos (Bolsas) de Papel de varias hojas, resistentes al agua` },
    { clave: `6HA1`, descripcion: `Envases y embalajes compuestos de Recipiente de plástico, con bidón (tambor) de acero` },
    { clave: `6HA2`, descripcion: `Envases y embalajes compuestos de Recipiente de plástico, con una jaula o caja de acero` },
    { clave: `6HB1`, descripcion: `Envases y embalajes compuestos de Recipiente de plástico, con un bidón (tambor) exterior de aluminio` },
    { clave: `6HB2`, descripcion: `Envases y embalajes compuestos de Recipiente de plástico, con una jaula o caja de aluminio` },
    { clave: `6HC`, descripcion: `Envases y embalajes compuestos de Recipiente de plástico, con una caja de madera` },
    { clave: `6HD1`, descripcion: `Envases y embalajes compuestos de Recipiente de plástico, con un bidón (tambor) de madera contrachapada` },
    { clave: `6HD2`, descripcion: `Envases y embalajes compuestos de Recipiente de plástico, con una caja de madera contrachapada` },
    { clave: `6HG1`, descripcion: `Envases y embalajes compuestos de Recipiente de plástico, con un bidón (tambor) de cartón` },
    { clave: `6HG2`, descripcion: `Envases y embalajes compuestos de Recipiente de plástico, con una caja de cartón` },
    { clave: `6HH1`, descripcion: `Envases y embalajes compuestos de Recipiente de plástico, con un bidón (tambor) de plástico` },
    { clave: `6HH2`, descripcion: `Envases y embalajes compuestos de Recipiente de plástico, con caja de plástico rígido` },
    { clave: `6PA1`, descripcion: `Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con un bidón (tambor) de acero` },
    { clave: `6PA2`, descripcion: `Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con una jaula o una caja de acero` },
    { clave: `6PB1`, descripcion: `Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con un bidón (tambor) exterior de aluminio` },
    { clave: `6PB2`, descripcion: `Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con una jaula o una caja de aluminio` },
    { clave: `6PC`, descripcion: `Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con una caja de madera` },
    { clave: `6PD 1`, descripcion: `Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con bidón (tambor) de madera contrachapada` },
    { clave: `6PD2`, descripcion: `Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con canasta de mimbre` },
    { clave: `6PG1`, descripcion: `Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con un bidón (tambor) de cartón` },
    { clave: `6PG2`, descripcion: `Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con una caja de cartón` },
    { clave: `6PH1`, descripcion: `Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con un envase y embalaje de plástico expandido` },
    { clave: `6PH2`, descripcion: `Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con un envase y embalaje de plástico rígido` },
    { clave: `7H1`, descripcion: `Bultos de Plástico` },
    { clave: `7L1`, descripcion: `Bultos de Tela` },
    { clave: `Z01`, descripcion: `No aplica ` },
    ];

    const classes = useStyles();
    const [isSaving, setIsSaving] = useState(false);
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenCPDialog, setIsOpenCPDialog] = useState(false);
    const [isOpenCCPDialog, setIsOpenCCPDialog] = useState(false);

    const [localidades, setLocalidades] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState("");
    const [clienteId, setClienteId] = useState("");
    const [referencia, setReferencia] = useState("");
    const [nviaje, setNviaje] = useState("");
    const [ncarta, setNcarta] = useState("");
    const [savedCP, setSavedCP] = useState(false);
    const [fecha, setFecha] = useState(new Date());
    const [terminal, setTerminal] = useState();
    const [terminales, setTerminales] = useState([]);
    const [status, setStatus] = useState("Planeado");
    const [statusInicial, setStatusInicial] = useState("Planeado");

    const [isViajeCargado, setIsViajeCargado] = useState(true);

    const [fechaOrigen, setFechaOrigen] = useState(new Date());
    const [fechaDestino, setFechaDestino] = useState(new Date());
    const [claseViaje, setClaseViaje] = useState('Seco - regular');
    const [tipo, setTipo] = useState('Local (-100 kms)');
    const [origen, setOrigen] = useState("");
    const [destino, setDestino] = useState("");
    const [origenId, setOrigenId] = useState("");
    const [destinoId, setDestinoId] = useState("");
    const [origenMunicipioSat, setOrigenMunicipioSat] = useState("");
    const [origenMunicipio, setOrigenMunicipio] = useState("");
    const [origenColonia, setOrigenColonia] = useState("");
    const [origenColoniaSat, setOrigenColoniaSat] = useState("");
    const [origenCiudadSat, setOrigenCiudadSat] = useState("");
    const [origenCiudad, setOrigenCiudad] = useState("");
    const [origenEstado, setOrigenEstado] = useState("");
    const [origenEstadoShort, setOrigenEstadoShort] = useState("");
    const [destinoMunicipioSat, setDestinoMunicipioSat] = useState("");
    const [destinoMunicipio, setDestinoMunicipio] = useState("");
    const [destinoColonia, setDestinoColonia] = useState("");
    const [destinoColoniaSat, setDestinoColoniaSat] = useState("");
    const [destinoCiudadSat, setDestinoCiudadSat] = useState("");
    const [destinoCiudad, setDestinoCiudad] = useState("");
    const [destinoEstado, setDestinoEstado] = useState("");
    const [destinoEstadoShort, setDestinoEstadoShort] = useState("");
    const [ruta, setRuta] = useState("");
    const [noSello, setNoSello] = useState("");
    const [noSello2, setNoSello2] = useState("");
    const [recoleccion, setRecoleccion] = useState("Pre-cargado");
    const [currencyMX, setCurrencyMX] = useState(true);
    const [distKM, setdistKM] = useState(true);
    const [distBase, setDistBase] = useState(0);
    const [distVacio, setDistVacio] = useState(0);
    const [distTotales, setDistTotales] = useState(0);

    const [distBase_km, setDistBaseKM] = useState(0);
    const [distVacio_km, setDistVacioKM] = useState(0);
    const [distTotales_km, setDistTotalesKM] = useState(0);
    const [distBase_mi, setDistBaseMI] = useState(0);
    const [distVacio_mi, setDistVacioMI] = useState(0);
    const [distTotales_mi, setDistTotalesMI] = useState(0);


    const [isInternational, setIsInternational] = useState(false);
    const [cruce, setCruce] = useState(" San Ysidro, California ---  Tijuana, Baja California");
    const [remolque1, setRemolque1] = useState("");
    const [remolque2, setRemolque2] = useState("");
    const [remolque3, setRemolque3] = useState("");
    const [remolque4, setRemolque4] = useState("");
    const [cantidadremoleques, setCantidaRemolques] = useState(2);
    const [otro, setOtro] = useState("");
    const [otro2, setOtro2] = useState("");
    const [otro3, setOtro3] = useState("");
    const [otro4, setOtro4] = useState("");
    const [cantidadAxiliares, setCantidadAuxiliares] = useState(3);

    const [operador1, setOperador1] = useState("N/A");
    const [operador2, setOperador2] = useState("N/A");
    const [operador1Id, setOperador1Id] = useState(0);
    const [operador2Id, setOperador2Id] = useState(0);
    const [unidad, setUnidad] = useState("");
    const [unidadId, setUnidadId] = useState(null);
    const [tarifaFloat, setTarifaFloat] = useState(0.01);
    const [tarifa, setTarifa] = useState("$0.01");
    const [ingresoXkilometroFloat, setIngresoXKilometroFloat] = useState(0);
    const [ingresoXkilometro, setIngresoXKilometro] = useState("");
    const [montoTotal, setMontoTotal] = useState("");
    const [montoTotalFloat, setMontoTotalFloat] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [cveSat, setCveSat] = useState("");
    const [pedimentos, setPedimentos] = useState([""]);
    const [peso, setPeso] = useState(0);
    const [isPesoKG, setIsPesoKG] = useState(true);
    const [cantidad, setCantidad] = useState("");
    const [comentarios, setComentarios] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [id, setId] = useState(null);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [user_viaje, setUserViaje] = useState(null);
    const [lineas_captura, setLineasCaptura] = useState([]);
    const [lcid, setLcId] = useState(1);
    const [operadores, setOperadores] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [isFull, setIsFull] = useState(false);
    const [listremolques, sertListRemolques] = useState([]);
    const [loadListremolques, setLoadListRemolques] = useState(false);
    const [listAuxiliares, setListAuxiliares] = useState([]);
    const [segmentoNegocio, setSegmentoNegocio] = useState("");
    const [messageRemolque1, setMessageRemoloque1] = useState("");
    const [messageRemolque2, setMessageRemoloque2] = useState("");
    const [messageRemolque3, setMessageRemoloque3] = useState("");
    const [messageRemolque4, setMessageRemoloque4] = useState("");
    const [messageAuxiliar1, setMessageAuxiliar1] = useState("");
    const [messageAuxiliar2, setMessageAuxiliar2] = useState("");
    const [messageAuxiliar3, setMessageAuxiliar3] = useState("");
    const [messageAuxiliar4, setMessageAuxiliar4] = useState("");


    const [isCreateActivoIntermodal, setIsCreateActivoIntermodal] = useState(null);

    const [isOpenCreateActivo, setIsOpenCreateActivo] = useState(false);
    const [isOpenCreateCliente, setIsOpenCreateCliente] = useState(false);
    const [isOpenCreateUbicacion, setIsOpenCreateUbicacion] = useState(false);
    const [usuarioobj, setUsuarioObj] = useState(null);

    const [precioDolar, setPrecioDolar] = useState(0.0);

    const [vajeAsOBJ, setViajeAsOBJ] = useState(null);

    const [isSearchModal, setIsSeachModal] = useState(false);

    const [mercancias, setMercancias] = useState([{ i: 0, descripcion: "", csat: "", pesoKG: 0.0, cantidad: 0, unidad: "Actividad", fraccion: "", uuidcomercioext: "" }]);
    const [objMercancia, setObjMercancia] = useState(null);
    const [objFraccionArancelaria, setObjFraccionArancelaria] = useState(null);
    const [isSearchFraccionModal, setIsSeachFraccionModal] = useState(false);
    const [isOpenParadas, setIsOpenParadas] = useState(false);

    const [isFormatoNuevo, setIsFormatoNuevo] = useState(false);
    const [tieneFormatos, setTieneFormatos] = useState(false);
    const [formatos, setFormatos] = useState([{ id: "1", tipo: "xml", nombre: "TPlus" }]);
    const [formatoActual, setFormatoActual] = useState({ id: "1", tipo: "xml", nombre: "TPlus" });

    const [tarifaOperador, setTarifaOperador] = useState([]);
    const [toperadorSelected, settoperadorSelected] = useState(null);

    const [tarifaTarifario, setTarifatarifario] = useState([]);
    const [ttarifarioSelected, setTtarifarioSelected] = useState(null);
    const [isinvalidtarifa, setIsinvalidtarifa] = useState(false);
    const [isHorarios, setIsHorarios] = useState(false);

    const [formatoXML, setFormatoXML] = useState("1");
    const [paradas, setParadas] = useState([]);
    const [desembarques, setDesembarques] = useState([]);

    useEffect(() => {
        if (paradas.length > 0) {
            let aux = [];
            aux.push({ nombre: destino, id: destinoId });
            paradas.forEach(element => {
                aux.push({ nombre: element.value, id: element.id });
            });
            //console.log("desembarques",aux);
            setDesembarques(aux);
        }
        if (isOpenParadas) {
            setIsOpenParadas(false);
        }
    }, [paradas]);

    useEffect(() => {
        if (objMercancia) {
            setIsSeachModal(true);
        }
    }, [objMercancia]);

    useEffect(() => {
        if (objFraccionArancelaria) {
            setIsSeachFraccionModal(true);
        }
    }, [objFraccionArancelaria]);


    let history = useHistory();

    const temporalSubirUbicacion = () => {
        let obj = {
            calle: "Carr a Villa de Garcia",
            estado: "Nuevo León",
            estado_short: 'N.L.',
            //internacional:true,
            localidad: 'Santa Catarina',
            nombre: 'Carrier Planta D',
            numero: 'KM.3.5',
            uid: 'bZxJtTabuLXmptcPCnwJPbz4Wti2'
        }
        tempRegistrarUbicacion(obj).then(r => {
            alert("ok")
        }).catch(err => {
            alert(err);
        });
    }

    const verificaDistancias = async () => {
        await getDistancia(uid, origen, destino).then(async (resp) => {
            if (resp.docs.length > 0) {
                const distviajeEncontrado = resp.docs[0].data();
                if (distviajeEncontrado.distancia != distBase) {
                    await actualizaDistnacia(resp.docs[0].id, distBase).then(resp => {
                        console.log("distancia actualizada");
                    }).catch(err => { });
                }
            } else {
                await registraNuevaDistancia(uid, origen, destino, distBase).then(resp => { console.log("nueva distancia agregada") }).catch(err => { console.log(err) });
            }
        }).catch(err => {
            console.log(err);
        });
    }
    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    const CP = () => {
        if (vajeAsOBJ) {
            //console.log(vajeAsOBJ);
            setIsOpenCPDialog(true);
        }
    }
    const CCP = () => {
        if (vajeAsOBJ) {
            console.log(vajeAsOBJ);
            setIsOpenCCPDialog(true);
        }
    }

    const WP = () => {
        if (vajeAsOBJ) {
            let o = { ...vajeAsOBJ };
            if (o) {
                var moment = require('moment'); // require
                let dataFechaOrigen = new Date();
                let dataFechaDestino = new Date();
                try {
                    dataFechaOrigen = o.datos_viaje.fechaOrigen ? o.datos_viaje.fechaOrigen.toDate() : new Date();
                } catch (exeption) { }
                try {
                    dataFechaDestino = o.datos_viaje.fechaDestino ? o.datos_viaje.fechaDestino.toDate() : new Date();
                } catch (exception) {

                }

                let fechaOrigenFormat = moment(dataFechaOrigen).format('DD/MM/YYYY');
                let fechaDestinoFormat = moment(dataFechaDestino).format('DD/MM/YYYY');
                //console.log("fecha origen format",fechaOrigenFormat);

                let operadorfixed = o.detalles.operador1;
                if (operadorfixed === 'N/A' && o.detalles.operador2 !== "N/A") {
                    operadorfixed = o.detalles.operador2;
                }

                let remolquesstring = '';
                if (o.carta_porte.messageRemolque1 !== '') {
                    remolquesstring += `*Remolque ${o.carta_porte.messageRemolque1}:* ${o.carta_porte.remolque1}\n`
                }
                if (o.carta_porte.messageRemolque2 !== '') {
                    remolquesstring += `*Remolque ${o.carta_porte.messageRemolque2}:* ${o.carta_porte.remolque2}\n`
                }
                if (o.carta_porte.messageRemolque3 !== '') {
                    remolquesstring += `*Remolque ${o.carta_porte.messageRemolque3}:* ${o.carta_porte.remolque3}\n`
                }
                if (o.carta_porte.messageRemolque4 !== '') {
                    remolquesstring += `*Remolque ${o.carta_porte.messageRemolque4}:* ${o.carta_porte.remolque4}\n`
                }

                let descripwp = "";
                if (o.detalles.comentarios && o.detalles.comentarios.trim() !== "") {
                    descripwp = `*Comentarios:* ${o.detalles.comentarios}`;
                }
                let textowp = `Fecha Origen: ${fechaOrigenFormat}\nViaje No. ${o.general.nviaje} / Carta Porte. ${o.general.ncarta}\nFecha Destino: ${fechaDestinoFormat}\n-------------------------\n*Operador:* ${operadorfixed} / ${o.datos_viaje.claseViaje}\n*Unidad:* ${o.detalles.unidad}\n*Origen:* ${o.datos_viaje.origen}\n*Destino:* ${o.datos_viaje.destino}\n${remolquesstring}${descripwp}`;
                navigator.clipboard.writeText(textowp);
                Toast.fire({
                    icon: 'success',
                    title: 'Información del viaje copiada al portapapeles..'
                })

            }
        }
    }

    const ActionCancelar = async (id) => {
        console.log("enviando a cancelar cfdi");
        await axios.post(`https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/cancelarcfdi`, { id, uid }).then(resp => {
            console.log("cancelado ok");
            if (resp.data.codigo == "000") {
                console.log("se cancelo el timbrado");
                // Swal.fire("Exito", `Se cancelo la factura`, 'success');
            } else {
                // Swal.fire("No se pudo cancelar", `${resp.data}`, 'warning');
            }

        }).catch(err => {
            console.log(err);
        });

        await actualizaStatusViaje(id, "Cancelado").then(resp => {
            setStatus("Cancelado");
            setStatusInicial("Cancelado");
            Swal.fire('Éxito', 'El viaje se ha cancelado con éxito serás regresado a la vista de viajes.', 'success').then(re => {
                history.push("/viajes");
            });
        }).catch(err => { });
    }
    const cancelar = () => {
        Swal.fire({
            title: '¿Esta seguro de cancelar el viaje?',
            text: "Seguro que deseas marcar este viaje como cancelado.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c666f',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si Cancelar',
            cancelButtonText: 'No Cancelar'
        }).then((result) => {
            if (result.value) {
                if (id) {

                    ActionCancelar(id);
                }
            }

        });
    }

    const processCombustibles = async (idViaje, objToSave) => {

        let currentCombustibles = [];

        await getCombustiblesPorViaje(uid, idViaje).then(snapshot => {
            snapshot.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                currentCombustibles.push(o);
            });

        }).catch(err => {
            console.log(err);
        });
        let toRegisterCombustible = [];
        let toUpdateCombustible = [];
        objToSave.lineas_captura.forEach(element => {
            if (element.tipo && element.tipo.toUpperCase() === 'COMBUSTIBLE') {
                let objexist = currentCombustibles.find(e => e.linea_captura === element.id);
                if (!objexist) {
                    toRegisterCombustible.push({ ...element, currencyMX });
                } else {
                    let obj = { ...element, idupdate: objexist.id, currencyMX }
                    toUpdateCombustible.push(obj);
                }
            }
        });
        console.log("Combustibles a agregar", toRegisterCombustible);
        let promisescombustiblesadd = [];
        //let promisesUpdateCombustibles=[];

        console.log("to update", toUpdateCombustible);
        console.log("to register", toRegisterCombustible);
        toUpdateCombustible.forEach(element => {
            let obCombToSave = {
                fechaCarga: element.fecha,
                isViajeAssigned: true,
                viaje_id: idViaje,
                linea_captura: element.id,
                unidad_id: unidadId,
                unidad: unidad,
                pago: '',
                cantidad: element.litros,
                uso: 'Diésel Unidad',
                costoFloat: element.costolitroFloat,
                costo: element.costolitro,
                total: element.monto,
                totalFloat: element.montoFloat,
                factura: element.descripcion,
                currencyMX: element.currencyMX,

                uid,
            };

            promisescombustiblesadd.push(updateCombustible(element.idupdate, obCombToSave));

        });

        await toRegisterCombustible.forEach(async (element) => {


            let obCombToSave = {
                fechaCarga: element.fecha,
                isViajeAssigned: true,
                viaje_id: idViaje,
                linea_captura: element.id,
                unidad_id: unidadId,
                unidad: { nombre: unidad },
                pago: '',
                cantidad: element.litros,
                uso: 'Diésel Unidad',
                costoFloat: element.costolitroFloat,
                costo: element.costolitro,
                total: element.monto,
                totalFloat: element.montoFloat,
                factura: element.descripcion,
                currencyMX: element.currencyMX,
                uid,
            };

            let costotoadd = {
                active: true,
                descripcion: 'Carga de combustible',
                fecha: new Date(obCombToSave.fechaCarga),
                monto: obCombToSave.total,
                montofloat: obCombToSave.totalFloat,
                nombre: 'Combustible',
                tipo: 'Combustible',
                uid,
            }
            let idcosto = null;
            await addCosto(costotoadd).then(resp => {
                idcosto = resp.id;
            }).catch(err => { });



            promisescombustiblesadd.push(addCombustible({ ...obCombToSave, costo_id: idcosto }));

        });
        await Promise.all(promisescombustiblesadd).then(resp => {
            //console.log("Ok agregados");
        }).catch(err => {
            console.log(err);
        });

    }
    const despacharpreplan = async (unidadAsOBj) => {
        console.log("despachando preplan 1");
        let operadorName = "";
        if (unidadAsOBj.operador) {
            await getOperadorById(unidadAsOBj.operador).then(doc => {
                let operador = doc.data();
                if (operador.nombre)
                    operadorName = operador.nombre;
                if (operador.apellidos) {
                    operadorName += " " + operador.apellidos;
                }
                //console.log("asignando operador", doc.data());
            }).catch(err => { });
        }
        if (unidadAsOBj && unidadAsOBj.preplan_viaje) {
            await despacharViaje(unidadAsOBj.preplan_viaje.id, unidadAsOBj, operadorName).then(resp => { }).catch(err => { console.log(err) });
            let uId = null;
            await setViajeAsignadoUnidad(unidadId, unidadAsOBj.preplan_viaje).then(resp => {
                uId = unidadId;

            }).catch(e => { console.log(e) });
            if (uId) {
                await asignarPrePlan(uId, null).then(r => { }).catch(ee => { });
            }
            Swal.fire('Éxito', 'El viaje pre-planeado ha despachado con éxito.', 'success');
        }

    }

    useEffect(() => {
        if (isCreateActivoIntermodal !== null) {
            setIsOpenCreateActivo(true);
        }

    }, [isCreateActivoIntermodal])

    const RemolqueAddClick = () => {
        if (claseViaje === "Intermodal" || claseViaje === "Marítimo-puertos") {
            setIsCreateActivoIntermodal(true);
        } else {
            setIsCreateActivoIntermodal(false);

        }


    }

    const presave = () => {
        setIsSaving(true);
    }

    useEffect(() => {
        if (isSaving) {
            save();
        }
    }, [isSaving])


    const save = async () => {

        if (!destino || !origen || destino === '' || origen === '') {
            Swal.fire("Invalido", "Debes especificar el origen y el destino ", 'error');
            return;
        }
        if (isinvalidtarifa) {
            Swal.fire("Invalido", "No se puede guardar si no hay tarifa seleccionada ", 'error');
            return;
        }
        if (clienteId === '') {
            Swal.fire("Invalido", "No se cargo correctamente el Cliente ", 'error');
            return;
        }

        let confirmncarta = ncarta;
        let confirmnviaje = nviaje;
        await getViajeRepo(uid).then(resp => {
            if (resp.size > 0) {
                //console.log("resp",resp.docs[0].data());
                let objuserviajelocal = { id: resp.docs[0].id, ...resp.docs[0].data() };
                if (objuserviajelocal) {
                    // console.log("user viaje", user_viaje);
                    let stringfolio = "";
                    if (objuserviajelocal.vfolio < 10) {
                        stringfolio = "000"
                    } else if (objuserviajelocal.vfolio < 100) {
                        stringfolio = "00"
                    } else if (objuserviajelocal.vfolio < 1000) {
                        stringfolio = "0"
                    } else {
                        stringfolio = ""
                    }

                    if (!isEdit) {
                        isViajeCargado ? setNviaje(objuserviajelocal.prefixvc.toUpperCase() + "-" + stringfolio + objuserviajelocal.vfolio) : setNviaje(objuserviajelocal.prefixv.toUpperCase() + "-" + stringfolio + objuserviajelocal.vfolio);;
                        if (isViajeCargado) {
                            confirmnviaje = objuserviajelocal.prefixvc.toUpperCase() + "-" + stringfolio + objuserviajelocal.vfolio;
                        }
                    }

                    let stringfoliocp = "";
                    if (objuserviajelocal.cfolio < 10) {
                        stringfoliocp = "000"
                    } else if (objuserviajelocal.cfolio < 100) {
                        stringfoliocp = "00"
                    } else if (objuserviajelocal.cfolio < 1000) {
                        stringfoliocp = "0"
                    } else {
                        stringfoliocp = ""
                    }
                    if (!isEdit || !savedCP) {
                        setNcarta(objuserviajelocal.prefixc.toUpperCase() + "-" + stringfoliocp + objuserviajelocal.cfolio);
                        confirmncarta = objuserviajelocal.prefixc.toUpperCase() + "-" + stringfoliocp + objuserviajelocal.cfolio;

                    }

                }

            }

        }).catch(err => {

        });
        //solo para esperar unos segundos al setNcarta y set nviaje
        //await new Promise(resolve => setTimeout(resolve, 1000));
        //await getViajeRepo(uid).then(resp => { }).catch(err => { });


        let completarviaje = false;
        if (status !== statusInicial) {
            if (status === "Entregado") {
                completarviaje = true;
                if (unidadId) {
                    getUnidadByID(unidadId).then(doc => {
                        let unidadAsOBj = doc.data();
                        unidadAsOBj.id = doc.id;
                        if (unidadAsOBj && unidadAsOBj.viaje_asignado) {

                            if (unidadAsOBj.preplan_viaje) {
                                Swal.fire({
                                    title: '¿Despachar nuevo viaje?',
                                    text: `La unidad tiene un viaje pre-planeado, desea despachar el nuevo viaje con la unidad ${unidadAsOBj.nombre}.`,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#1c666f',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Si despachar',
                                    cancelButtonText: 'No, Cancelar'
                                }).then((result) => {
                                    if (result.value) {
                                        console.log("despacchando preplan");
                                        despacharpreplan(unidadAsOBj);
                                    } else {
                                        //desasginar viaje a unidad
                                        console.log("desasignando viaje a unidad");
                                        setViajeAsignadoUnidad(unidadId, null).then(resp => { }).catch(e => { console.log(e) });
                                    }

                                });




                            } else {
                                //desasginar viaje a unidad
                                setViajeAsignadoUnidad(unidadId, null).then(resp => { }).catch(e => { console.log(e) });
                            }

                        }
                    }).catch(err => { console.log(err) });
                }
            }
        }
        let fixedOperador1Id = null;
        if (operador1Id === 0 && operador1 !== "N/A") {
            //verificar operador
            let findByName = operadores.find(element => element.nombre === operador1);
            if (findByName && findByName.id) {
                fixedOperador1Id = findByName.id;
            }
        }
        //return;
        let objToSave = {
            uid,
            status,
            ttarifarioSelected,
            dolarprice: precioDolar,
            lineas_captura,
            isInternational,
            segmentoNegocio,
            mercancias,
            isFormatoNuevo,
            isViajeCargado,
            toperadorSelected,
            paradas,
            tarifaOperador,
            fechafinalizacion: completarviaje ? new Date() : null,
            general: {
                referencia,
                nviaje: confirmnviaje,
                ncarta: savedCP ? confirmncarta : "",
                savedCP,
                fecha,
                terminal,
                isFull,
                cliente,
                clienteId,
            },
            datos_viaje: {
                origen,
                destino,
                origenId,
                destinoId,
                fechaOrigen,
                fechaDestino,
                claseViaje,
                tipo,
                ruta,
                noSello,
                noSello2,
                recoleccion
            },
            carta_porte: {
                cruce,
                distBase,
                distVacio,
                distTotales,
                distKM,
                distBase_km,
                distBase_mi,
                distVacio_km,
                distVacio_mi,
                distTotales_km,
                distTotales_mi,
                origenCiudad,
                origenEstado,
                origenEstadoShort,
                origenMunicipioSat,
                origenMunicipio,
                origenColonia,
                origenColoniaSat,
                origenCiudadSat,
                destinoCiudad,
                destinoEstado,
                destinoEstadoShort,
                destinoMunicipio,
                destinoMunicipioSat,
                destinoColonia,
                destinoColoniaSat,
                destinoCiudadSat,
                remolque1: remolque1 || "",
                remolque2: remolque2 || "",
                remolque3: remolque3 || "",
                remolque4: remolque4 || "",
                cantidadremoleques,
                otro: otro || "",
                otro2: otro2 || "",
                otro3: otro3 || "",
                otro4: otro4 || "",
                messageAuxiliar1,
                messageAuxiliar2,
                messageAuxiliar3,
                messageRemolque1,
                messageRemolque2,
                messageRemolque3,
                messageRemolque4,
                cantidadAxiliares
            }, detalles: {
                operador1,
                operador2,
                operador2Id,
                operador1Id: fixedOperador1Id || operador1Id,
                unidad,
                unidadId,
                tarifa,
                tarifaFloat,
                montoTotal,
                montoTotalFloat,
                ingresoXkilometro,
                ingresoXkilometroFloat,
                currencyMX,
                descripcion,
                cveSat,
                pedimentos,
                cantidad,
                comentarios,
                peso,
                isPesoKG,
            }
        }


        //console.log(objToSave);
        // return;
        setIsLoading(true);
        await verificaDistancias();


        if (!objToSave.general.savedCP && (objToSave.detalles.operador1 != 'N/A' || objToSave.detalles.operador2 != 'N/A') && objToSave.detalles.unidadId && objToSave.detalles.unidadId !== "") {
            if (user_viaje) {
                await nextFolioCP(user_viaje.id).then(resp => {
                    setSavedCP(true);
                    objToSave.general.savedCP = true;
                    objToSave.general.ncarta = confirmncarta;
                }).catch(err => {
                    console.log(err);
                })
            }
        }
        //proceso de verificar que nadie este registrando o actualizando
        // otro registro
        //comparar currentserver time con guardado servertime

        // console.log("obj",objToSave);
        //return;

        if (isEdit) {
            let erroronsave = false;
            objToSave.useredit = uidAltaEdit || uid;
            await processCombustibles(id, objToSave);
            updateViaje(id, objToSave).then(docuRef => {
                setIsSaving(false);
            }).catch(err => {
                erroronsave = true;
                console.log(err);
                setIsLoading(false);
                setIsSaving(false);
            });
            if (!erroronsave) {
                await getUserByUid(uidAltaEdit || uid).then(async (snapshot) => {
                    let iduser = null;
                    snapshot.forEach(document => {
                        iduser = document.id;

                    });
                    if (iduser)
                        await updateViajesTime(iduser).then(resp => {
                            console.log("actualizado time");
                        }).catch(err => {
                            console.log(err);
                        });
                }).catch(err => console.log(err));

            }
            setIsLoading(false);
            setIsSaving(false);

        } else {

            /*
                        let fconsultaserver = null;
                        let fconsulta = null;
                        let idConsulttime = null;
                        await getConsultTime(uid).then(snapshot => {
                            snapshot.forEach(document => {
                                let d = document.data();
                                idConsulttime = document.id;
                                if (d.time) {
            
                                    fconsultaserver = d.time.toDate().getTime();
                                }
                            })
                            //console.log("fconsulta",fconsulta);
                        }).catch(err => { console.log(err) });
                        if (idConsulttime) {
                            await setConsultTime(idConsulttime).then(resp => { console.log("setted time") }).catch(err => { console.log(err) });
                            await getConsultTime(uid).then(snapshot => {
            
                                snapshot.forEach(document => {
                                    let d = document.data();
                                    if (d.time) {
                                        fconsulta = d.time.toDate().getTime();
                                    }
                                })
                                // console.log("fconsulta",fconsulta);
                            }).catch(err => { console.log(err) });
                        }
                        if (fconsultaserver && fconsulta) {
                            let timedif = Math.abs(fconsultaserver - fconsulta);
                            console.log("milisegundos de diferencia", timedif);
                            if(timedif && timedif<3000){
                              await setTimeout(async () => {
                                await getViajeRepo(uid).then(resp => {
                                    if (resp.size > 0) {
                                        //console.log("resp",resp.docs[0].data());
                                        let objuserviajelocal = { id: resp.docs[0].id, ...resp.docs[0].data() };
                                        if (objuserviajelocal) {
                                            // console.log("user viaje", user_viaje);
                                            let stringfolio = "";
                                            if (objuserviajelocal.vfolio < 10) {
                                                stringfolio = "000"
                                            } else if (objuserviajelocal.vfolio < 100) {
                                                stringfolio = "00"
                                            } else if (objuserviajelocal.vfolio < 1000) {
                                                stringfolio = "0"
                                            } else {
                                                stringfolio = ""
                                            }
                        
                                            if (!isEdit) {
                                                isViajeCargado ? setNviaje(objuserviajelocal.prefixvc.toUpperCase() + "-" + stringfolio + objuserviajelocal.vfolio) : setNviaje(objuserviajelocal.prefixv.toUpperCase() + "-" + stringfolio + objuserviajelocal.vfolio);;
                                                if (isViajeCargado) {
                                                    confirmnviaje = objuserviajelocal.prefixvc.toUpperCase() + "-" + stringfolio + objuserviajelocal.vfolio;
                                                }
                                            }
                        
                                            let stringfoliocp = "";
                                            if (objuserviajelocal.cfolio < 10) {
                                                stringfoliocp = "000"
                                            } else if (objuserviajelocal.cfolio < 100) {
                                                stringfoliocp = "00"
                                            } else if (objuserviajelocal.cfolio < 1000) {
                                                stringfoliocp = "0"
                                            } else {
                                                stringfoliocp = ""
                                            }
                                            if (!isEdit || !savedCP) {
                                                setNcarta(objuserviajelocal.prefixc.toUpperCase() + "-" + stringfoliocp + objuserviajelocal.cfolio);
                                                confirmncarta = objuserviajelocal.prefixc.toUpperCase() + "-" + stringfoliocp + objuserviajelocal.cfolio;
                        
                                            }
                        
                                        }
                        
                                    }
                        
                                }).catch(err => {
                        
                                });
                              }, 1000);
                            }
                        }*/



            objToSave.useralta = uidAltaEdit || uid;
            let erroronsave = false;
            await addViaje(objToSave).then(async (docuRef) => {
                //console.log("registrado", docuRef.id);
                await getUserByUid(uidAltaEdit || uid).then(async (snapshot) => {
                    let iduser = null;
                    snapshot.forEach(document => {
                        iduser = document.id;

                    });
                    if (iduser)
                        await updateViajesTime(iduser).then(resp => {
                            console.log("actualizado time");
                        }).catch(err => {
                            console.log(err);
                        });
                }).catch(err => console.log(err));
                setIsLoading(false);
                setIsSaving(false);
                setIsEdit(true);
                setId(docuRef.id);
                setViajeAsOBJ({ ...objToSave, id: docuRef.id, key: docuRef.id });
                await processCombustibles(docuRef.id, objToSave);

                let queryparams = queryString.parse(locations.search);
                if (queryparams.carga) {
                    await confirmarCarga(queryparams.carga).then(resp => { }).catch(err => console.log(err));
                }

                //return;
                if (user_viaje) {
                    nextFolio(user_viaje.id).then(r => {
                        history.push("/viaje/" + docuRef.id);
                    }).catch(err => {
                        console.log(err);
                        setIsLoading(false);
                    });
                } else {
                    history.push("/viaje/" + docuRef.id);
                }



            }).catch(err => {
                console.log(err);
                erroronsave = true;
            });
            if (!erroronsave) {
                await getUserByUid(uidAltaEdit || uid).then(async (snapshot) => {

                    let iduser = null;
                    snapshot.forEach(document => {
                        iduser = document.id;

                    });
                    if (iduser)
                        await updateViajesTime(iduser).then(resp => {
                            console.log("actualizado time");
                        }).catch(err => {
                            console.log(err);
                        });
                }).catch(err => console.log(err));
            }
            setIsLoading(false);
        }

    }
    useEffect(() => {
        if (status && status != '') {
            let indextoset = CATALOGO_STATUS.findIndex((element) => element === status);
            setActiveStep(indextoset);
        }
    }, [status])
    useEffect(() => {
        console.log("lc", lineas_captura);
        console.log("id", lcid);
    }, [lineas_captura, lcid]);


    useEffect(() => {
        if (user_viaje) {
            // console.log("user viaje", user_viaje);
            let stringfolio = "";
            if (user_viaje.vfolio < 10) {
                stringfolio = "000"
            } else if (user_viaje.vfolio < 100) {
                stringfolio = "00"
            } else if (user_viaje.vfolio < 1000) {
                stringfolio = "0"
            } else {
                stringfolio = ""
            }

            if (!isEdit) {


                isViajeCargado ? setNviaje(user_viaje.prefixvc.toUpperCase() + "-" + stringfolio + user_viaje.vfolio) : setNviaje(user_viaje.prefixv.toUpperCase() + "-" + stringfolio + user_viaje.vfolio);;
            }

            let stringfoliocp = "";
            if (user_viaje.cfolio < 10) {
                stringfoliocp = "000"
            } else if (user_viaje.cfolio < 100) {
                stringfoliocp = "00"
            } else if (user_viaje.cfolio < 1000) {
                stringfoliocp = "0"
            } else {
                stringfoliocp = ""
            }
            if (!isEdit || !savedCP)
                setNcarta(user_viaje.prefixc.toUpperCase() + "-" + stringfolio + user_viaje.cfolio);

            if (user_viaje.terminales && user_viaje.terminales.length > 0) {
                setTerminales(user_viaje.terminales);
                setTerminal(user_viaje.terminales[0]);
            }

            /*  if (user_viaje.operadores) {
                  let aux = user_viaje.operadores;
                  aux.push('N/A');
                  setOperadores(aux);
                  setOperador2('N/A');
              }*/
            if (user_viaje.unidades) {
                //setUnidades(user_viaje.unidades);
            }
            if (user_viaje.formatos) {
                setTieneFormatos(true);

                let auxformatos = [{ id: "1", tipo: "xml", nombre: "TPlus" }];
                user_viaje.formatos.forEach(e => {
                    auxformatos.push({ ...e })
                })
                setFormatos(auxformatos);
            }


        }
    }, [user_viaje, isViajeCargado]);
    const getViajeRepoAction = () => {
        if (uid) {
            if (uid) {
                getViajeRepo(uid).then(resp => {

                    if (resp.size > 0) {
                        //console.log("resp",resp.docs[0].data());
                        let objuserviaje = { id: resp.docs[0].id, ...resp.docs[0].data() }
                        setUserViaje(objuserviaje);
                    }

                }).catch(err => {
                    console.log("viajerepo error", err);
                });
            }
        }
    }

    const checkIfTarifa = () => {
        if (clienteId !== "" && origenId !== "" && destinoId !== "" && segmentoNegocio !== "") {
            getTarifarioByViajeData(uid, clienteId, segmentoNegocio, origenId, destinoId).then(resp => {
                if (resp.docs.length > 0) {
                    setIsinvalidtarifa(false);
                    let aux = [];
                    let objselected = null;

                    resp.docs.forEach((element, indexx) => {
                        if (element.data().tarifa) {
                            let ob = {
                                index: indexx,
                                id: element.id,
                                tarifa: element.data().tarifa,
                                tarifaFloat: element.data().tarifaFloat,
                                currencyMx: element.data().currencyMx,
                                descripcion: "",
                                especial: element.data().isEspecial,
                                isEspecial: element.data().isEspecial,
                            }
                            aux.push(ob);
                            if (!ob.isEspecial) {
                                objselected = ob;
                            }

                        }
                    });
                    if (!props.id && objselected) {

                        setTtarifarioSelected(objselected);

                    }
                    else if (!props.id && aux.length > 0) {

                        setTtarifarioSelected(aux[0]);

                    }
                    setTarifatarifario(aux);
                } else {
                    setTarifatarifario([]);

                    setTtarifarioSelected(null);
                    setIsinvalidtarifa(true);

                }
            }).catch(err => { console.log(err) })
        }

    }

    useEffect(() => {

        if (ttarifarioSelected && tarifaTarifario.length > 0) {
            let objfindtarifario = tarifaTarifario.find(element => element.id === ttarifarioSelected.id);
            if (objfindtarifario) {
                setTarifa(objfindtarifario.tarifa);
                setTarifaFloat(objfindtarifario.tarifaFloat);
                setCurrencyMX(objfindtarifario.currencyMx);
            } else {
                console.log("no encontrado");
            }
        } else if (!isEdit) {
            setTarifa("");
            setTarifaFloat(0.0);
        }
    }, [ttarifarioSelected, tarifaTarifario])

    useEffect(() => {
        if (clienteId && origenId && destinoId && segmentoNegocio) {
            checkIfTarifa();
        }


    }, [clienteId, origenId, destinoId, segmentoNegocio]);
    useEffect(() => {
        console.log("asignando cliente");
        if (cliente && cliente != "" && clientes.length > 0) {
            let objf = clientes.find(element => element.name.trim() === cliente.trim());
            if (objf) {
                if (objf.value != clienteId) {
                    // console.log("cliente info", objf);
                    setClienteId(objf.value);
                    console.log("se asigno cliente id")
                }
            } else {
                console.log("no se encontro cliente ID");
                console.log("  cliente nombre", cliente.trim());
                console.log("  clientes", clientes);
            }
        }
    }, [cliente]);


    const initCatalogos = async () => {

        let iduser = null;
        let fechaUltimaOperadores = null;
        let fechaUltimaClientes = null;
        let fechaUltimaUbicaciones = null;
        await getUserByUid(uid).then(snapshot => {
            let obuser = null;
            snapshot.forEach(document => {
                iduser = document.id;
                obuser = { ...document.data(), id: document.id }
                if (!obuser.segmento_negocio) {
                    obuser.segmento_negocio = [];
                }
                if (obuser.dolarprice) {
                    setPrecioDolar(obuser.dolarprice);
                }
                if (obuser.changedOperadores) {
                    fechaUltimaOperadores = obuser.changedOperadores.toDate().getTime();
                }
                if (obuser.changedClientes) {
                    fechaUltimaClientes = obuser.changedClientes.toDate().getTime();
                }
                if (obuser.changedUbicaciones) {
                    fechaUltimaUbicaciones = obuser.changedUbicaciones.toDate().getTime();
                }
            });
            setUsuarioObj(obuser);
            if (!props.id && obuser.segmento_negocio && obuser.segmento_negocio.length > 0) {
                setSegmentoNegocio(obuser.segmento_negocio[0].value);
            }
        }).catch(err => { console.log(err) });


        if (!fechaUltimaClientes && iduser) {
            await updateUserClientesTime(iduser).then(resp => { }).catch(err => {
                console.log(err);
            });
            await GetClientesServer();
        } else {
            if (!localStorage.getItem("fechaclientes")) {
                localStorage.setItem("fechaclientes", `${fechaUltimaClientes}`);
                await GetClientesServer();
            } else {
                let fechaclienteslocal = localStorage.getItem("fechaclientes");
                if (fechaclienteslocal < fechaUltimaClientes) {
                    await GetClientesServer();
                    localStorage.setItem("fechaclientes", `${fechaUltimaClientes}`);
                } else {
                    await GetClientesLocales();
                }
            }
        }


        if (!fechaUltimaUbicaciones && iduser) {
            await updateUserUbicacionesTime(iduser).then(resp => { }).catch(err => {
                console.log(err);
            });
            await GetUbicacionesServer();
        } else {
            if (!localStorage.getItem("fechaubicaciones")) {
                localStorage.setItem("fechaubicaciones", `${fechaUltimaUbicaciones}`);
                await GetUbicacionesServer();
            } else {
                let fechaubicacioneslocal = localStorage.getItem("fechaubicaciones");
                if (fechaubicacioneslocal < fechaUltimaUbicaciones) {
                    await GetUbicacionesServer();
                    localStorage.setItem("fechaubicaciones", `${fechaUltimaUbicaciones}`);
                } else {
                    await GetUbicacionesLocales();
                }
            }
        }

        if (!fechaUltimaOperadores && iduser) {
            await updateUserOperadoresTime(iduser).then(resp => { }).catch(err => {
                console.log(err);
            });
            await GetOperadoresServer();
        } else {
            if (!localStorage.getItem("fechaoperadores")) {
                localStorage.setItem("fechaoperadores", `${fechaUltimaOperadores}`);
                await GetOperadoresServer();
            } else {
                let fechaoperadoreslocal = localStorage.getItem("fechaoperadores");
                if (fechaoperadoreslocal < fechaUltimaOperadores) {
                    await GetOperadoresServer();
                    localStorage.setItem("fechaoperadores", `${fechaUltimaOperadores}`);
                } else {
                    await GetOperadoresLocales();
                }
            }
        }
        let queryparams = queryString.parse(locations.search);
        if (queryparams.carga) {
            cargardatosdeCarga(queryparams.carga);
        }
    }
    const GetUbicacionesServer = async () => {
        console.log("getting clientes de server");
        const { clear } = useIndexedDB('ubicaciones');
        clear().then(() => {
            console.log('ubicaciones locales limpios');
        });
        await getUbicaciones(uid).then(snapshot => {
            let aux = [];
            snapshot.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                aux.push(o);
            });
            let toset = aux.map((item, index) => {
                let num = item.numero || "";
                let idubica = item.nombre.trim() + " - " + item.calle.trim() + " " + num.trim();
                idubica = idubica.trim();
                let ob = { ...item, name: idubica, value: idubica };
                return ob;
            });
            setLocalidades(toset);
            GuardarLocalmenteUbicaciones(toset);

        }).catch(err => { });

    }

    const GetClientesServer = async () => {
        console.log("getting clientes de server");
        const { clear } = useIndexedDB('clientes');

        await getClientes(uid).then(snapshot => {
            let aux = [];
            snapshot.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                aux.push(o);
            });
            let toset = aux.map((item, index) => {
                let ob = { ...item, name: item.nombre + " - " + item.estado_short, value: item.id, id: "user" + index };
                return ob;
            });
            if (toset.length > 0) {
                clear().then(() => {
                    console.log('clientes locales limpios');
                });
                GuardarLocalmenteClientes(toset);
            }
            setClientes(toset);

        }).catch(err => { });
    }
    const GetOperadoresServer = async () => {
        console.log("getting operadores de server");
        const { clear } = useIndexedDB('operadores');


        await getOperadores(uid).then().then(snapshot => {
            let aux = [];
            snapshot.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                aux.push(o);
            });
            let toset = aux.map((item, index) => {
                let ob = { id: item.id, nombre: item.nombre.trim() + " " + item.apellidos.trim() };
                return ob;
            });

            toset.push({ id: 0, nombre: "N/A" });
            if (toset.length > 1) {
                clear().then(() => {
                    console.log('operadores locales limpios');
                });
                GuardarLocalmenteOperadores(toset);
            }
            setOperadores(toset);
            setOperador2("N/A");


        }).catch(err => { });
    }
    useEffect(() => {
        if (uid) {
            initCatalogos();
            /* getUnidades(uid).then(snapshot => {
                 let aux = [];
                 snapshot.forEach(doc => {
                     let o = doc.data();
                     o.id = doc.id;
                     aux.push(o);
                 });
                 setUnidades(aux);
 
             }).catch(err => { });*/




            /**/



            getActivos(uid).then(snapshot => {
                let aux = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.id = doc.id;
                    aux.push(o);
                });
                let toset = aux.map((item, index) => {
                    let ob = { ...item, name: item.nombre, value: item.id, id: item.id, razon: item.razon || "" };
                    return ob;
                });
                let remolques = [];
                let otros = [];
                let unidadestoset = [{ name: "", value: "", id: null }];
                toset.forEach(element => {
                    // String.tolower
                    if (element.tipo.toLowerCase() === "remolque") {
                        remolques.push(element);
                    } else if (element.tipo.toLowerCase() === "unidad") {
                        unidadestoset.push(element);
                    } else {
                        otros.push(element)
                    }

                    // element.tipo.toLowerCase() === "remolque" ? remolques.push(element) : otros.push(element);
                });
                //unidadestoset.push("")
                unidadestoset = unidadestoset.sort(function (a, b) {
                    if (a.nombre > b.nombre) {
                        return 1;
                    }
                    if (a.nombre < b.nombre) {
                        return -1;
                    }
                    return 0;
                });

                sertListRemolques(remolques);
                setListAuxiliares(otros);
                setUnidades(unidadestoset);
                if (unidadestoset.length > 0) {
                    //setUnidad(unidadestoset[0].name);
                    // setUnidadId(unidadestoset[0].value);
                }
                setLoadListRemolques(true);

            }).catch(err => { console.log(err) });

            getViajeRepo(uid).then(resp => {

                if (resp.size > 0) {
                    //console.log("resp",resp.docs[0].data());
                    let objuserviaje = { id: resp.docs[0].id, ...resp.docs[0].data() }
                    setUserViaje(objuserviaje);
                } else {
                    /* initViajeRepor(uid).then(resp => {
                         getViajeRepoAction();
                     }).catch(err => {
 
                     });*/
                }

            }).catch(err => {
                console.log("viajerepo error", err);
            });





        }
    }, [uid]);


    const loadDataFromOBJ = (obj) => {
        console.log("datos del viaje en obj", obj);
        setIsEdit(true);
        setMercancias(obj.mercancias || [{ descripcion: "", csat: "", unidad: "Actividad", pesoKG: 0.0, cantidad: 0, fraccion: "", uuidcomercioext: "" }]);
        setViajeAsOBJ({ ...obj, key: props.id });
        setStatusInicial(obj.status);
        setStatus(obj.status);
        if (obj.ttarifarioSelected) {
            setTtarifarioSelected(obj.ttarifarioSelected);
        }

        settoperadorSelected(obj.toperadorSelected || null);
        setTarifaOperador(obj.tarifaOperador || []);
        setPrecioDolar(obj.dolarprice || precioDolar);
        //setTarifatarifario(obj.tarifaTarifario || []);
        /** general:{
                referencia,
                nviaje,
                fecha,
                terminal
            }, */
        setIsInternational(obj.isInternational);
        if (obj.lineas_captura) {
            setLineasCaptura(obj.lineas_captura);
            let maxfactid = 0;
            lineas_captura.forEach(element => {
                if (element.temid && element.temid >= maxfactid) {
                    maxfactid = element.temid;
                }
            });
            maxfactid += 2;
            setLcId(maxfactid);

        }
        setIsFull(obj.general.isFull || false);
        // setIsInternational(obj.)
        setReferencia(obj.general.referencia);
        setNviaje(obj.general.nviaje);
        setNcarta(obj.general.ncarta || "");
        setFecha(obj.general.fecha.toDate());
        setTerminal(obj.general.terminal);
        setCliente(obj.general.cliente || "");
        setClienteId(obj.general.clienteId || "");
        if (obj.general.savedCP != null) {
            setSavedCP(obj.general.savedCP);
        } else {
            setSavedCP(false);
        }

        /**
         * datos_viaje:{
            origen,
            destino,
            fechaOrigen,
            fechaDestino,
            tipo,
            ruta,
            noSello,
            recoleccion
        },
         */
        setOrigen(obj.datos_viaje.origen);
        setDestino(obj.datos_viaje.destino);
        setOrigenId(obj.datos_viaje.origenId || "");
        setDestinoId(obj.datos_viaje.destinoId || "");
        setFechaOrigen(obj.datos_viaje.fechaOrigen.toDate());
        setFechaDestino(obj.datos_viaje.fechaDestino.toDate());
        setTipo(obj.datos_viaje.tipo);
        setRuta(obj.datos_viaje.ruta);
        setNoSello(obj.datos_viaje.noSello);
        setSegmentoNegocio(obj.segmentoNegocio || "");

        setNoSello2(obj.datos_viaje.noSello2 || "");
        setRecoleccion(obj.datos_viaje.recoleccion);
        setClaseViaje(obj.datos_viaje.claseViaje || '');

        setParadas(obj.paradas || []);
        /**
         *  carta_porte: {
                distBase,
                distVacio,
                distTotales,
                distKM,
                origenCiudad,
                origenEstado,
                remolque1,
                remolque2,
                otro
            }
         */
        setCruce(obj.carta_porte.cruce || "");
        setDistBase(obj.carta_porte.distBase);
        setDistVacio(obj.carta_porte.distVacio);
        setDistTotales(obj.carta_porte.distTotales);
        setdistKM(obj.carta_porte.distKM);
        setOrigenCiudad(obj.carta_porte.origenCiudad);
        setOrigenEstado(obj.carta_porte.origenEstado);
        setOrigenMunicipio(obj.carta_porte.origenMunicipio || "");
        setOrigenMunicipioSat(obj.carta_porte.origenMunicipioSat || "");
        setOrigenCiudadSat(obj.carta_porte.origenCiudadSat || "");
        setOrigenEstadoShort(obj.carta_porte.origenEstadoShort || "");
        setOrigenColonia(obj.carta_porte.origenColonia || "");
        setOrigenColoniaSat(obj.carta_porte.origenColoniaSat || "");
        setDestinoCiudad(obj.carta_porte.destinoCiudad);
        setDestinoEstado(obj.carta_porte.destinoEstado);
        setDestinoEstadoShort(obj.carta_porte.destinoEstadoShort || "");
        setDestinoMunicipio(obj.carta_porte.destinoMunicipio || "");
        setDestinoMunicipioSat(obj.carta_porte.destinoMunicipioSat || "");
        setDestinoCiudadSat(obj.carta_porte.destinoCiudadSat || "");
        setDestinoColonia(obj.carta_porte.destinoColonia);
        setDestinoColoniaSat(obj.carta_porte.destinoColoniaSat);
        setRemolque1(obj.carta_porte.remolque1);
        setRemolque2(obj.carta_porte.remolque2);
        setRemolque3(obj.carta_porte.remolque3 || "");
        setRemolque4(obj.carta_porte.remolque4 || "");
        setCantidaRemolques(obj.carta_porte.cantidadremoleques || 2);
        setMessageRemoloque1(obj.carta_porte.messageRemolque1 || "");
        setMessageRemoloque2(obj.carta_porte.messageRemolque2 || "");
        setMessageRemoloque3(obj.carta_porte.messageRemolque3 || "");
        setMessageRemoloque4(obj.carta_porte.messageRemolque4 || "");
        setMessageAuxiliar1(obj.carta_porte.messageAuxiliar1 || "");
        setMessageAuxiliar2(obj.carta_porte.messageAuxiliar2 || "");
        setMessageAuxiliar3(obj.carta_porte.messageAuxiliar3 || "");
        setOtro(obj.carta_porte.otro);
        setOtro2(obj.carta_porte.otro2 || "");
        setOtro3(obj.carta_porte.otro3 || "");
        setOtro4(obj.carta_porte.otro4 || "");
        setCantidadAuxiliares(obj.carta_porte.cantidadAxiliares || 3);
        // setUnidadId(obj.unidadid)
        /**
         * detalles: {
                operador1,
                operador2,
                unidad,
                tarifa,
                currencyMX,
                descripcion,
                cantidad,
                comentarios
            }
         */
        setOperador1(obj.detalles.operador1);
        setOperador2(obj.detalles.operador2);
        setOperador1Id(obj.detalles.operador1Id);
        setOperador2Id(obj.detalles.operador2Id);
        setUnidad(obj.detalles.unidad);
        setUnidadId(obj.detalles.unidadId);
        setTarifa(obj.detalles.tarifa || "$0.01");

        setIsFormatoNuevo(obj.isFormatoNuevo || false);
        setIsViajeCargado(obj.isViajeCargado || false);

        setTarifaFloat(obj.detalles.tarifaFloat || 0.01);
        setCurrencyMX(obj.detalles.currencyMX);
        setDescripcion(obj.detalles.descripcion);
        setCveSat(obj.detalles.cveSat || "");
        setPedimentos(obj.detalles.pedimentos || [""]);
        setCantidad(obj.detalles.cantidad);
        setComentarios(obj.detalles.comentarios);
        setPeso(obj.detalles.peso);
        setIsPesoKG(obj.detalles.isPesoKG);
        setIngresoXKilometroFloat(obj.detalles.ingresoXkilometroFloat || 0);
        setIngresoXKilometro(obj.detalles.ingresoXkilometro || "");

        setLineasCaptura(obj.lineas_captura);

    }
    useEffect(() => {
        //setdistKM(currencyMX);
    }, [currencyMX]);
    useEffect(() => {
        if (isEdit) {

        }
    }, [distKM]);
    useEffect(() => {
        if (props.id) {

            getViajeById(props.id).then(resp => {
                //console.log(resp.data());
                setId(props.id);
                loadDataFromOBJ(resp.data());
            }).catch(err => {
                console.log("error", err);
            })
        }
    }, [props]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeTarifa = (event, maskedvalue, floatvalue) => {
        setTarifa(maskedvalue);
        setTarifaFloat(floatvalue);
    }

    const loadLocalidades = async () => {
        // console.log("cargando localdiades");
        let locations = [];
        await getCatalogoLocalidesGenerales().then(snapshot => {
            snapshot.forEach(doc => {
                let o = doc.data();
                locations = o.data;
            });
            let toset = locations.map((item, index) => {
                let ob = { ...item, name: item.descripcion, value: item.codigo };
                if (ob.value == 'TCN' || ob.value == 'ZLO' || ob.value == 'ESE') {
                    ob.value = +index;
                }
                return ob;
            });
            setLocalidades(toset);

        }).catch(err => {
            console.log(err);
        })

    }
    useEffect(() => {
        //  loadLocalidades();
        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {

                //setUser(userr);
                // setAdminCurrentUser(userr.uid);
                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);

            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });
        setLineasCaptura([]);
        setIsSaving(false);




    }, []);
    const cargardatosdeCarga = async (idcarga) => {
        let cargaactual = null;
        await getCargaById(idcarga).then(document => {
            console.log("datos de carga", document.data());
            cargaactual = { ...document.data() };

        }).catch(err => { console.log(err) });
        if (cargaactual) {

            console.log("se encontro carga");
            setIsFormatoNuevo(true);
            setCliente(cargaactual.cliente_nombre);
            setMercancias(cargaactual.mercancias);

            if (cargaactual.ruta) {
                if (cargaactual.ruta.segmento) {
                    setSegmentoNegocio(cargaactual.ruta.segmento);
                }
                setOrigen(cargaactual.ruta.origen);
                setDestino(cargaactual.ruta.destino);
            }

        }

    }
    const IOSSwitch = withStyles((theme) => ({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            '&$checked': {
                transform: 'translateX(16px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#0f7dd4',
                    opacity: 1,
                    border: 'none',
                },
            },

        },
        thumb: {
            width: 24,
            height: 24,
            backgroundColor: '#e1e7f7',
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid #0f7dd4`,
            backgroundColor: '#0f7dd4',
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},

    }))(Switch);

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {}

    }))(Switch);


    useEffect(() => {
        console.log("origen", origen);
        console.log("destino", destino);
        if (origen && destino) {



            let origenOBJ = localidades.find(element => element.name === origen);
            let destinoOBJ = localidades.find(element => element.name === destino);

            if (origenOBJ && destinoOBJ) {
                setRuta(origenOBJ.localidad + " - " + destinoOBJ.localidad);

                if ((origenOBJ.internacional && !destinoOBJ.internacional) || (!origenOBJ.internacional && destinoOBJ.internacional)) {
                    setIsInternational(true);
                } else {
                    setIsInternational(false);
                }
            }
            if (!isEdit) {
                console.log("getting distancia uid", uid);
                getDistancia(uid, origen, destino).then(resp => {
                    if (resp.size > 0) {
                        if (resp.docs[0].data().distancia)
                            setDistBase(resp.docs[0].data().distancia);
                    }
                }).catch(err => {
                    console.log(err);
                });
                if (origenOBJ && destinoOBJ && origenOBJ.localidad && destinoOBJ.localidad) {
                    getTabuladorOperadoresByRuta(uid, origenOBJ.localidad.toUpperCase() + " - " + destinoOBJ.localidad.toUpperCase(), segmentoNegocio).then(resp => {
                        if (resp.size > 0) {
                            let aux = [];
                            let objselected = null;
                            resp.docs.forEach((element, indexx) => {
                                if (element.data().tarifa) {
                                    let ob = {
                                        index: indexx,
                                        tarifa: element.data().tarifa,
                                        tarifaFloat: element.data().tarifaFloat,
                                        descripcion: "",
                                        especial: element.isEspecial || false,
                                        isEspecial: false,
                                    }
                                    aux.push(ob);
                                    if (!ob.isEspecial) {
                                        objselected = ob;
                                    }

                                }
                            });
                            if (objselected) {
                                settoperadorSelected(objselected);
                            }
                            else if (aux.length > 0)
                                settoperadorSelected(aux[0]);
                            setTarifaOperador(aux);

                        } else {
                            settoperadorSelected(null);
                            setTarifaOperador([]);
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                }

            }

        }
        if (origen) {
            let origenOBJ = localidades.find(element => element.name == origen);
            if (origenOBJ) {
                setOrigenId(origenOBJ.id);
                setOrigenCiudad(origenOBJ.localidad);
                setOrigenCiudadSat(origenOBJ.localidadSat || "");
                setOrigenEstado(origenOBJ.estado);
                setOrigenEstadoShort(origenOBJ.estado_short);
                setOrigenMunicipioSat(origenOBJ.municipioSat || "");
                setOrigenMunicipio(origenOBJ.municipio || "");
                setOrigenColonia(origenOBJ.colonia || "");
                setOrigenColoniaSat(origenOBJ.coloniaSat || "");

            }
        }
        if (destino) {
            let destinoOBJ = localidades.find(element => element.name == "" + destino);
            if (destinoOBJ) {
                console.log("se encontro destino", destino);
                setDestinoId(destinoOBJ.id);
                setDestinoCiudad(destinoOBJ.localidad);
                setDestinoEstado(destinoOBJ.estado);
                setDestinoEstadoShort(destinoOBJ.estado_short);
                setDestinoMunicipioSat(destinoOBJ.municipioSat || "");
                setDestinoCiudadSat(destinoOBJ.localidadSat || "");
                setDestinoMunicipio(destinoOBJ.municipio || "");
                setDestinoColonia(destinoOBJ.colonia || "");
                setDestinoColoniaSat(destinoOBJ.coloniaSat || "");
            } else {
                // console.log("no se encontro", destino);
                // console.log("localidades busqueda", localidades[2].name);
                /* let a = destino;
                 let b = localidades[2].name;
                 console.log("a", a);
                 console.log("b", b);
                 console.log("compara", a.trim() == b.trim());*/
            }
        }



    }, [origen, destino, segmentoNegocio]);

    useEffect(() => {
        if (isInternational) {
            let nextlcid = lcid;
            setLcId(lcid + 1);//incrementamos
            let lcnew = {
                temid: nextlcid,
                descripcion: 'Cruce',
                tipo: 'INGRESO',
                currencyMX: true,
                monto: '',
                montoFloat: 0.0,
            }
            setLineasCaptura([...lineas_captura, lcnew]);
        }
    }, [isInternational])

    const handleNoSello = e => {
        let val = e.target.value.toUpperCase();
        const regex = /^[0-9a-zA-Z(\-)(,)]+$/; //this will admit letters, numbers and dashes
        if (val.match(regex) || val === "") {
            setNoSello(val);
        }

    }
    const handleNoSello2 = e => {
        let val = e.target.value.toUpperCase();
        const regex = /^[0-9a-zA-Z(\-)(,)]+$/; //this will admit letters, numbers and dashes
        if (val.match(regex) || val === "") {
            setNoSello2(val);
        }

    }

    const handleMercanciaBTnCLiked = () => {

    }

    const activeAdded = (obj) => {

        if (obj && obj.tipo) {
            let ob = { ...obj, name: obj.nombre, value: obj.id, id: "usert" + 200 };
            try {
                if (obj.tipo.toLowerCase() === 'remolque') {
                    listremolques.push(ob);
                } else {
                    listAuxiliares.push(ob);
                }
            } catch (e) {

            }

        }
    }

    const clienteAdded = (item) => {
        if (item && item.nombre) {
            let ob = { ...item, name: item.nombre + " - " + item.estado_short, value: item.id, id: "useradded" + clientes.length + 1 };
            let clientesaux = [...clientes];
            clientesaux.push(ob);
            setClientes(clientesaux);
        }
    }
    const ubicacionAdded = (obj) => {

        /**
         *  let aux = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.id = doc.id;
                    aux.push(o);
                });
                let toset = aux.map((item, index) => {
                    let num = item.numero || "";
                    let idubica = item.nombre.trim() + " - " + item.calle.trim() + " " + num.trim();
                    let ob = { ...item, name: idubica, value: idubica };
                    return ob;
                });
         */
        if (obj && obj.nombre) {
            // let ob = { ...obj, name: obj.nombre, value: obj.id, id: "usert" + 200 };
            // let num = obj.numero || "";
            //let idubica = obj.nombre + " - " + obj.calle + " " + num;
            // let ob = { ...obj, name: idubica, value: idubica, id: "loc" + 300 };
            let item = { ...obj };
            let num = item.numero || "";
            let idubica = item.nombre.trim() + " - " + item.calle.trim() + " " + num.trim();
            let ob = { ...item, name: idubica, value: idubica };
            try {
                localidades.push(ob);
            } catch (e) {

            }

        }
    }
    const mercanciaEncontrada = (solicitante, obj) => {

        let newArr = [...mercancias];
        let foundIndex = newArr.findIndex(x => x.i === solicitante.i);
        if (foundIndex >= 0) {
            newArr[foundIndex].descripcion = obj.descripcion;
            newArr[foundIndex].csat = obj.codigo;
            if (obj.peligroso) {
                newArr[foundIndex].peligroso = "1";
            }
            setMercancias(newArr);
        }

        setIsSeachModal(false);


        //setDescripcion(obj.descripcion);
        // setCveSat(obj.codigo);
    }
    const fraccionEncontrada = (solicitante, obj) => {

        let newArr = [...mercancias];
        let foundIndex = newArr.findIndex(x => x.i === solicitante.i);
        if (foundIndex >= 0) {
            newArr[foundIndex].fraccion = obj.codigo;
            setMercancias(newArr);
        }

        setIsSeachFraccionModal(false);
    }

    useEffect(() => {
        if (distBase && distVacio) {
            setDistTotales(parseFloat(distBase) + parseFloat(distVacio));
        } else if (distBase) {
            setDistTotales(parseFloat(distBase));
        }

    }, [distBase, distVacio]);


    useEffect(() => {
        if (distKM) {
            setDistBaseKM(parseFloat(distBase));
            setDistVacioKM(parseFloat(distVacio));
            setDistTotalesKM(parseFloat(distTotales));
            const conversor = 0.621371;
            const db = distBase * conversor;
            const dv = distVacio * conversor;
            const dt = distTotales * conversor;
            setDistBaseMI(parseFloat(db.toFixed(2)));
            setDistVacioMI(parseFloat(dv.toFixed(2)));
            setDistTotalesMI(parseFloat(dt.toFixed(2)));

        } else {
            setDistBaseMI(parseFloat(distBase));
            setDistVacioMI(parseFloat(distVacio));
            setDistTotalesMI(parseFloat(distTotales));
            const conversor = 1.60934;
            const db = distBase * conversor;
            const dv = distVacio * conversor;
            const dt = distTotales * conversor;
            setDistBaseKM(parseFloat(db.toFixed(2)));
            setDistVacioKM(parseFloat(dv.toFixed(2)));
            setDistTotalesKM(parseFloat(dt.toFixed(2)));
        }

    }, [distKM, distTotales]);

    useEffect(() => {
        //console.log(distKM);
        //  console.log("distbase", distBase);
        // console.log("distbase KM", distBase_km);
        // console.log("distbase MI", distBase_mi);
    }, [distBase_km, distBase_mi])

    useEffect(() => {
        if (unidadId) {
            let uniobj = unidades.find(element => element.id === unidadId);
            if (uniobj) {
                setUnidad(uniobj.nombre);
            }
        }
    }, [unidadId])

    const calculateIngresoXKilometro = () => {
        if (!tarifaFloat || !distTotales) {
            return;
        }
        let extrasMX = 0;
        let extrasDLL = 0;
        lineas_captura.forEach(element => {
            if (element.tipo === 'INGRESO') {
                element.currencyMX ?
                    extrasMX += element.montoFloat :
                    extrasDLL += element.montoFloat;
            }
        });

        if (currencyMX && extrasDLL > 0) {
            let value = parseFloat(tarifaFloat / distTotales).toFixed(2);
            let num = '$' + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            setIngresoXKilometroFloat(parseFloat(value));

            let eDll = extrasDLL / distTotales;
            let numDll = '$' + parseFloat(eDll).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            setIngresoXKilometro(num + "   " + numDll + 'dll');
        } else if (!currencyMX && extrasMX > 0) {
            let value = parseFloat(tarifaFloat / distTotales).toFixed(2);
            let num = '$' + parseFloat(extrasMX / distTotales).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            setIngresoXKilometroFloat(parseFloat(value));

            let numDll = '$' + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            setIngresoXKilometro(num + "   " + numDll + 'dll');
        } else if (currencyMX && extrasMX > 0) {
            let value = parseFloat((tarifaFloat + extrasMX) / distTotales).toFixed(2);
            let num = '$' + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            setIngresoXKilometroFloat(parseFloat(value));
            setIngresoXKilometro(num);
        } else if (!currencyMX && extrasDLL > 0) {
            let value = parseFloat((tarifaFloat + extrasDLL) / distTotales).toFixed(2);
            let num = '$' + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            setIngresoXKilometroFloat(parseFloat(value));
            setIngresoXKilometro(num + "dll");
        }
        else {
            let value = parseFloat(tarifaFloat / distTotales).toFixed(2);
            let num = '$' + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            setIngresoXKilometroFloat(parseFloat(value));
            setIngresoXKilometro(num);
        }
    }
    useEffect(() => {
        calculateIngresoXKilometro();
    }, [tarifaFloat, distTotales, lineas_captura])


    const changeDescripcionLC = (event, element) => {
        //
        let newArr = [...lineas_captura];
        let foundIndex = newArr.findIndex(x => x.temid === element.temid);
        if (foundIndex >= 0) {
            newArr[foundIndex].descripcion = event.target.value;
            setLineasCaptura(newArr);
        }

    }

    const changelitrosLC = (event, element) => {
        //
        let newArr = [...lineas_captura];
        let foundIndex = newArr.findIndex(x => x.temid === element.temid);
        if (foundIndex >= 0) {
            newArr[foundIndex].litros = event.target.value;
            setLineasCaptura(newArr);
        }

    }
    const changefechaLC = (event, element) => {
        //
        let newArr = [...lineas_captura];
        let foundIndex = newArr.findIndex(x => x.temid === element.temid);
        if (foundIndex >= 0) {
            newArr[foundIndex].fecha = event.target.value;
            setLineasCaptura(newArr);
        }

    }
    const handleChangeCurrencyCostoLitroLC = (event, maskedvalue, floatvalue, element) => {
        let newArr = [...lineas_captura];
        let foundIndex = newArr.findIndex(x => x.temid === element.temid);
        if (foundIndex >= 0) {

            newArr[foundIndex].costolitro = maskedvalue;
            newArr[foundIndex].costolitroFloat = floatvalue;
            setLineasCaptura(newArr);
        }
    }

    const handleChangeTabulador = (value) => {
        if (tarifaOperador.length > 0) {
            let objfind = tarifaOperador[value];
            if (objfind) {
                settoperadorSelected(objfind);
            }
        }
    }
    const handleChangeTabuladorByValue = (value) => {
        // console.log("changing value", value);
        if (tarifaOperador.length > 0) {
            let objfind = tarifaOperador.find(element => element.tarifa === value);
            if (objfind) {
                settoperadorSelected(objfind);
            }
        }
    }

    /*const handleChangeTarifarioByValue = (value) => {
        // console.log("changing value", value);
        if (tarifaTarifario.length > 0) {
            let objfind = tarifaTarifario.find(element => element.tarifa === value);
            if (objfind) {
                setTtarifarioSelected(objfind);
            }
        }
    }*/

    const handleTabuladorCustom = (event) => {
        let tarifacustom = {
            index: 100, tarifa: event, tarifaFloat: parseFloat(event).toFixed(2), descripcion: '', especial: true
        }
        settoperadorSelected(tarifacustom);

    }

    /*const handleTarifarioCustom = (event) => {
        let tarifacustom = {
            index: 100, tarifa: event, tarifaFloat: parseFloat(event).toFixed(2), descripcion: '', especial: true
        }
        setTtarifarioSelected(tarifacustom);
    }*/


    const openMap = () => {
        window.open('https://www.google.com/maps/dir///@26.3037345,-105.3323355,6.08z', '_blank');
    }
    const changeTipoLC = (event, element) => {

        //
        let newArr = [...lineas_captura];
        let foundIndex = newArr.findIndex(x => x.temid === element.temid);
        if (foundIndex >= 0) {
            newArr[foundIndex].tipo = event.target.value;
            setLineasCaptura(newArr);
        }

    }


    const changeUnidadMercancias = (event, element) => {
        let newArr = [...mercancias];
        let foundIndex = newArr.findIndex(x => x.i === element.i);
        if (foundIndex >= 0) {
            newArr[foundIndex].unidad = event.target.value;
            setMercancias(newArr);
        }

    }
    const changeDesembarqueMercancias = (event, element) => {
        let newArr = [...mercancias];
        let foundIndex = newArr.findIndex(x => x.i === element.i);
        if (foundIndex >= 0) {
            newArr[foundIndex].destino = event.target.value;
            setMercancias(newArr);
        }

    }
    const changeCantidadMercancias = (event, element) => {
        let newArr = [...mercancias];
        let foundIndex = newArr.findIndex(x => x.i === element.i);
        if (foundIndex >= 0) {
            newArr[foundIndex].cantidad = event.target.value;
            setMercancias(newArr);
        }

    }
    useEffect(() => {
        if (operador1Id) {
            let findoperador = operadores.find(element => element.id === operador1Id);
            if (findoperador) {
                setOperador1(findoperador.nombre);
            }
        }
    }, [operador1Id]);
    useEffect(() => {
        if (operador2Id) {
            let findoperador = operadores.find(element => element.id === operador2Id);
            if (findoperador) {
                setOperador2(findoperador.nombre);
            }
        }
    }, [operador2Id]);

    const changeMercanciasOldFormat = (event, element) => {
        let newArr = [...mercancias];
        let foundIndex = newArr.findIndex(x => x.i === element.i);
        if (foundIndex >= 0) {
            newArr[foundIndex].descripcion = event.target.value;
            setMercancias(newArr);
        }

    }
    const changePesoMercancias = (event, element) => {
        let newArr = [...mercancias];
        let foundIndex = newArr.findIndex(x => x.i === element.i);
        if (foundIndex >= 0) {
            newArr[foundIndex].pesoKG = event.target.value;
            setMercancias(newArr);
        }

    }

    const changeUuidExteriorMercancias = (event, element) => {
        let newArr = [...mercancias];
        let foundIndex = newArr.findIndex(x => x.i === element.i);
        if (foundIndex >= 0) {
            newArr[foundIndex].uuidcomercioext = event.target.value;
            setMercancias(newArr);
        }

    }

    const changeCMaterialPeligroso = (value, element) => {
        //console.log("value",value);
        // return;
        let findPeligrosos = value;
        if (findPeligrosos && findPeligrosos.clave) {
            //console.log("se encontro", findPeligrosos);
            let newArr = [...mercancias];
            let foundIndex = newArr.findIndex(x => x.i === element.i);
            if (foundIndex >= 0) {
                newArr[foundIndex].c_MaterialPeligroso = findPeligrosos.clave;
                console.log("cambiando a ", findPeligrosos.clave);
                setMercancias(newArr);
            }
        } else {
            console.log("no se encontro");
        }
    }

    const changeCTipoEmbalaje = (value, element) => {
        //console.log("value",value);
        // return;
        let findPeligrosos = value;
        if (findPeligrosos && findPeligrosos.clave) {
            //console.log("se encontro", findPeligrosos);
            let newArr = [...mercancias];
            let foundIndex = newArr.findIndex(x => x.i === element.i);
            if (foundIndex >= 0) {
                newArr[foundIndex].c_TipoEmbalaje = findPeligrosos.clave;
                // console.log("cambiando a ", findPeligrosos.clave);
                setMercancias(newArr);
            }
        } else {
            console.log("no se encontro");
        }
    }


    useEffect(() => {

        if (isFull) {

            if (recoleccion === 'Swap (Quita-pon)') {
                setCantidadAuxiliares(4);
                setCantidaRemolques(4);
                setMessageRemoloque1("Vacío");
                setMessageRemoloque2("Vacío");
                setMessageRemoloque3("Cargado");
                setMessageRemoloque4("Cargado");
                setMessageAuxiliar1("Dolly");
                setMessageAuxiliar2("Dolly");
                setMessageAuxiliar3("Chasis 1");
                setMessageAuxiliar4("Chasis 2");

            } else {
                setCantidaRemolques(2);
                setCantidadAuxiliares(3);
                setMessageRemoloque1("Vacío");
                setMessageRemoloque2("Cargado");
                setMessageRemoloque3("");
                setMessageRemoloque4("");
                setMessageAuxiliar1("Dolly");
                setMessageAuxiliar2("");

            }
        } else {
            setCantidaRemolques(2);
            setCantidadAuxiliares(3);
            setMessageAuxiliar1("");
            setMessageAuxiliar2("");
            setMessageAuxiliar3("");
            setMessageAuxiliar4("");

            if (recoleccion === 'Swap (Quita-pon)') {
                setMessageRemoloque1("Vacío");
                setMessageRemoloque2("Cargado");

            } else {
                setMessageRemoloque1("Vacío");
                setMessageRemoloque2("Cargado");
                setMessageRemoloque3("");


            }
        }

    }, [recoleccion, isFull]);

    const handleChangeCurrencyLC = (event, maskedvalue, floatvalue, element) => {
        let newArr = [...lineas_captura];
        let foundIndex = newArr.findIndex(x => x.temid === element.temid);
        if (foundIndex >= 0) {

            newArr[foundIndex].monto = maskedvalue;
            newArr[foundIndex].montoFloat = floatvalue;
            setLineasCaptura(newArr);
        }
    }

    const handleChangeCurrencyTypeLC = (element) => {
        //
        let newArr = [...lineas_captura];
        let foundIndex = newArr.findIndex(x => x.temid === element.temid);
        if (foundIndex >= 0) {
            newArr[foundIndex].currencyMX = !newArr[foundIndex].currencyMX;
            setLineasCaptura(newArr);
        }

    }
    const removeMercancia = (element) => {
        let newArr = [...mercancias];
        let foundIndex = newArr.findIndex(x => x.i === element.i);
        if (foundIndex >= 0) {
            newArr.splice(foundIndex, 1);
            setMercancias(newArr);
        }
    }
    const removeLC = (element) => {
        let newArr = [...lineas_captura];
        let foundIndex = newArr.findIndex(x => x.temid === element.temid);
        if (foundIndex >= 0) {
            newArr.splice(foundIndex, 1);
            setLineasCaptura(newArr);
        }
    }
    const addLC = () => {
        let nextlcid = lcid;
        setLcId(lcid + 1);//incrementamos
        let lcnew = {
            id: uuidv4(),
            temid: nextlcid,
            descripcion: '',
            tipo: 'COSTO VARIABLE',
            currencyMX: true,
            monto: '',
            fecha: '',
            montoFloat: 0.0,
            costolitro: '',
            costolitroFloat: 0.0,
            litros: '',
        }
        setLineasCaptura([...lineas_captura, lcnew]);
    }
    const changePedimento = (index, value) => {
        let aux = [];
        pedimentos.forEach((element, i) => {
            if (i === index) {
                aux.push(value);
            } else {
                aux.push(element);
            }
        });
        setPedimentos(aux);
    }
    const removePedimento = (index) => {
        let aux = [];
        pedimentos.forEach((element, i) => {
            if (i === index) {
                // aux.push(value);
            } else {
                aux.push(element);
            }
        });
        setPedimentos(aux);
    }

    const addMercancia = () => {

        let inew = mercancias.length > 0 ? mercancias[mercancias.length - 1].i : 1;
        inew++;
        setMercancias([...mercancias, { i: inew, descripcion: "", csat: "", pesoKG: 0.0, cantidad: 0, unidad: "Actividad", fraccion: "", uuidcomercioext: "" }])
    }

    const refAfterEnter = useRef();
    const eventEnterOnFindCreate = (e) => {
        e.preventDefault();
        refAfterEnter.current.focus();
    }

    const GetOperadoresLocales = async () => {
        console.log("getting operadores localmente");
        const { getAll } = useIndexedDB('operadores');
        getAll().then(dataFromDB => {
            //if()
            setOperadores(dataFromDB);
        });
    }

    const GetClientesLocales = async () => {
        console.log("getting clientes localmente");
        const { getAll } = useIndexedDB('clientes');
        getAll().then(dataFromDB => {

            setClientes(dataFromDB);
        });
    }

    const GetUbicacionesLocales = async () => {
        console.log("getting ubicaciones localmente");
        const { getAll } = useIndexedDB('ubicaciones');
        getAll().then(dataFromDB => {
            setLocalidades(dataFromDB.map(element => {
                let aux = { ...element }
                aux.name = aux.name.trim();
                return aux;
            }));
        });
    }

    const GuardarLocalmenteUbicaciones = async (listaUbicaciones) => {
        const { add } = useIndexedDB('ubicaciones');
        let promises = [];
        listaUbicaciones.forEach(element => {
            promises.push(add({ ...element }))
        });
        Promise.all(promises).then(values => {
            console.log("agregados ubicaciones");
        });
    }
    const GuardarLocalmenteClientes = async (listaClientes) => {
        const { add } = useIndexedDB('clientes');
        let promises = [];
        listaClientes.forEach(element => {
            promises.push(add({ name: element.name, id: element.id, value: element.value }))
        });
        Promise.all(promises).then(values => {
            console.log("agregados clientes");
        });
    }
    const GuardarLocalmenteOperadores = async (listaOperadores) => {
        const { add } = useIndexedDB('operadores');
        let promises = [];
        listaOperadores.forEach(element => {
            promises.push(add({ nombre: element.nombre, id: element.id }))
        });
        Promise.all(promises).then(values => {
            console.log("agregados operadores");
        });
        /* add({ nombre: 'Prueba', id: '23423423' }).then(
             event => {
                 console.log('Guardado');
             },
             error => {
                 console.log(error);
             }
         );*/

        /*const { clear } = useIndexedDB('operadores');
        clear().then(() => {
            console.log('All Clear!');
          });/*

       /* const { getAll } = useIndexedDB('operadores');
        getAll().then(dataFromDB => {
            console.log("data from db",dataFromDB);
          });*/
    }
    const parseXMLdata = async (xmlstring) => {

        await xml2js.parseString(xmlstring, async (err, result) => {
            if (err) {
                console.log(err);
            }
            else {
                if (formatoActual && formatoActual.id === "1") {
                    console.log("formato xml 1");
                    if (result && result['rows']['row'] && Array.isArray(result['rows']['row'])) {
                        // console.log("rowsxml", result["rows"]['row']);

                        let inew = 100;
                        let temaux = [];
                        //  setMercancias([...mercancias, { i: inew, descripcion: "", csat: "", pesoKG: 0.0, cantidad: 0, unidad: "Actividad", fraccion: "", uuidcomercioext: "" }])

                        result["rows"]['row'].forEach(element => {
                            inew++;
                            let unidadobj = CAT_UNIDADES.find(f => f.codigo === element.c_unidad[0]);
                            console.log("elemento", unidadobj);
                            let nombreunidad = element.c_unidad[0];
                            if (unidadobj && unidadobj.descripcion) {
                                nombreunidad = unidadobj.descripcion;
                            }
                            let obj = { i: inew, descripcion: element.descripcion[0], csat: element.c_ClaveProdServCP[0], pesoKG: parseFloat(element.pesoKG[0]).toFixed(2), cantidad: parseInt(element.cantidad[0]), unidad: nombreunidad, fraccion: element.fraccionArancelaria[0], uuidcomercioext: element.folioFiscal[0] };
                            if (element && element.c_MaterialPeligroso && element.c_MaterialPeligroso[0] && element.c_MaterialPeligroso[0] !== "") {
                                console.log("si es peligroso");
                                obj.peligroso = 1;
                                let cpeligrosos = element.c_MaterialPeligroso[0];
                                while (cpeligrosos.length < 4) {
                                    cpeligrosos = "0" + cpeligrosos;
                                }
                                obj.c_MaterialPeligroso = element.c_MaterialPeligroso[0];
                                obj.c_TipoEmbalaje = element.c_TipoEmbalaje[0] || "";
                            } else {
                                console.log("no peligroso");
                            }
                            temaux.push(obj);
                            console.log(obj);
                        });
                        setMercancias(temaux);
                    }
                } else {
                    console.log("formato xml 2");
                    let reglasformato = "Report*Tablix3*Details2_Collection*Details2";
                    let tagitem = "$";
                    //let obj = { i: inew, descripcion: element.descripcion[0], csat: element.c_ClaveProdServCP[0], pesoKG: parseFloat(element.pesoKG[0]), cantidad: parseInt(element.cantidad[0]), unidad: nombreunidad, fraccion: element.fraccionArancelaria[0], uuidcomercioext: element.folioFiscal[0] };
                    let key_descripcion = "Descripcion";
                    let key_csat = "BienesTransp";
                    let key_pesoKG = "PesoEnKg";
                    let key_cantidad = "Cantidad";
                    let key_unidad = "ClaveUnidad";
                    let key_fraccion = "Fraccion";
                    let key_uuid = "NA";
                    let key_pedimento = "Pedimento";

                    let estructura = reglasformato.split("*");
                    console.log("esttructura", estructura);
                    if (estructura.length > 0) {
                        let aux = result;
                        estructura.forEach(e => {
                            if (aux[`${e}`])
                                aux = aux[`${e}`];
                            else {
                                if (aux.length > 0) {
                                    if (aux[0][`${e}`]) {
                                        aux = aux[0][`${e}`];


                                    }
                                }
                            }

                        })
                        console.log("aux", aux);
                        let inew = 100;
                        let temaux = [];
                        const mySetPedimentos = new Set();

                        if (Array.isArray(aux)) {
                            aux.forEach(e => {
                                console.log("e", e[`${tagitem}`]);
                                let obje = { isxml: "1", i: inew, descripcion: e[`${tagitem}`][`${key_descripcion}`], csat: e[`${tagitem}`][`${key_csat}`], pesoKG: parseFloat(e[`${tagitem}`][`${key_pesoKG}`]).toFixed(2), cantidad: parseInt(e[`${tagitem}`][`${key_cantidad}`]), unidad: e[`${tagitem}`][`${key_unidad}`], fraccion: e[`${tagitem}`][`${key_fraccion}`] || "", uuidcomercioext: e[`${tagitem}`][`${key_uuid}`] || "" };
                                inew++;
                                let auxpedi = e[`${tagitem}`][`${key_pedimento}`];
                                auxpedi = auxpedi.split(" ").join("");
                                if (auxpedi.length === 15) {
                                    let fixedpedi = `${auxpedi.substring(0, 2)}  ${auxpedi.substring(2, 4)}  ${auxpedi.substring(4, 8)}  ${auxpedi.substring(8, 15)}`;
                                    mySetPedimentos.add(fixedpedi);
                                }

                                temaux.push(obje);
                            });
                        }
                        let auxpedimentos = Array.from(mySetPedimentos);
                        if (auxpedimentos.length > 0) {
                            setPedimentos(auxpedimentos);
                        }
                        console.log("temaux", temaux);
                        setMercancias(temaux);

                    }

                }

                // response = result["cfdi:Comprobante"]["$"]["NoCertificado"];
            }

        });
    }
    const handleChangeFile = (event) => {
        //console.log(`Selected file - ${event.target.files[0]}`);
        //if (formatoXML === "1") {

        let file = event.target.files[0];
        if (file) {
            if (!formatoActual || (formatoActual.tipo === "xml")) {

                console.log("formato archivo xml", formatoActual);
                var reader = new FileReader();
                reader.readAsText(file, "UTF-8");
                reader.onload = function (evt) {
                    //console.log("xml", evt.target.result);
                    const xmlstring = evt.target.result;

                    parseXMLdata(xmlstring);


                    // document.getElementById("fileContents").innerHTML = evt.target.result;
                }
                reader.onerror = function (evt) {
                    // document.getElementById("fileContents").innerHTML = "error reading file";
                }
            } else if (formatoActual && formatoActual.tipo === "json") {
                console.log("formato archivo json");
                var reader = new FileReader();
                reader.readAsText(file, "UTF-8");
                reader.onload = function (evt) {
                    //console.log("xml", evt.target.result);
                    const jsonstring = evt.target.result;
                    let objToyota = JSON.parse(jsonstring);

                    if (objToyota) {

                        if (objToyota.Pedimento) {
                            //asignamos pedimento 
                            let aux = [objToyota.Pedimento];
                            let tosetaux = aux.map(tset => {
                                let a = tset.replaceAll(" ", "  ");
                                return a;
                            });
                            // console.log("pedimentos parsed",tosetaux);
                            setPedimentos(tosetaux);
                        }
                        if (objToyota.cartaPorteDetails) {
                            let aux = [];
                            objToyota.cartaPorteDetails.forEach((merc, i) => {
                                let claveselected = merc.ClaveUnidad || "";
                                let finded = null;
                                if (claveselected !== "") {
                                    finded = CAT_UNIDADES.find(e => e.codigo === claveselected);
                                    if (finded) {
                                        claveselected = finded.descripcion;
                                    }
                                }

                                let obj = { isxml: "finded" ? null : "1", i, descripcion: merc.C_Descripcion || "", csat: merc.BienesTransp || "", pesoKG: parseFloat(merc.PesoEnKG).toFixed(2), cantidad: parseInt(merc.Cantidad), unidad: claveselected, fraccion: merc.FraccionArancelaria || "", uuidcomercioext: "" };

                                if (merc.MaterialPeligroso !== "No") {
                                    obj.peligroso = 1;
                                    obj.c_MaterialPeligroso = merc.C_MaterialPeligroso || "";
                                    obj.c_TipoEmbalaje = merc.Embalaje || "";
                                }
                                aux.push(obj);
                            });
                            setMercancias([...mercancias, ...aux]);
                        }
                        console.log("JSONSTRING", objToyota);
                    }
                    // document.getElementById("fileContents").innerHTML = evt.target.result;
                }
                reader.onerror = function (evt) {
                    // document.getElementById("fileContents").innerHTML = "error reading file";
                }
            }
            else {
                console.log("formato archivo excel");
                let columnaTitulos = 0;
                readXlsxFile(file).then((rows) => {
                    console.log("rows", rows);
                    if (rows.length > 1) {
                        //buscamos titulo en row 0
                        let colClaveProducto = null;
                        let colDescripcion = null;
                        let colPeso = null;
                        let colCantidad = null;
                        let colFraccionArancelaria = null;
                        let colClaveUnidad = null;
                        let colMaterialPeligroso = null;
                        let colEmbalaje = null;
                        let colPedimento = null;

                        console.log("formato actual", formatoActual);
                        if (formatoActual.i !== null && formatoActual.i !== undefined) {
                            columnaTitulos = formatoActual.i;
                        }
                        console.log("analizando col", columnaTitulos);

                        rows[columnaTitulos].forEach((columna, index) => {
                            if (index === 0) {
                                console.log("checando", columna);
                                console.log("con", formatoActual.colClaveProducto);
                                console.log(columna === formatoActual.colClaveProducto)
                            }
                            if (columna === formatoActual.colClaveProducto) {
                                colClaveProducto = index;
                            }
                            if (columna === formatoActual.colDescripcion) {
                                colDescripcion = index;
                            }
                            if (columna === formatoActual.colPeso) {
                                colPeso = index;
                            }
                            if (columna === formatoActual.colCantidad) {
                                colCantidad = index;
                            }
                            if (columna === formatoActual.colFraccionArancelaria) {
                                colFraccionArancelaria = index;
                            }
                            if (columna === formatoActual.colClaveUnidad) {
                                colClaveUnidad = index;
                            }
                            if (columna === formatoActual.colMaterialPeligroso) {
                                colMaterialPeligroso = index;
                            }
                            if (columna === formatoActual.colEmbalaje) {
                                colEmbalaje = index;
                            }
                            if (formatoActual.colPedimento && columna === formatoActual.colPedimento) {
                                colPedimento = index;
                            }
                        });

                        if (colClaveProducto !== null) {
                            //let inew=0;
                            let aux = [];
                            let mapPedimentos = new Set();
                            rows.forEach((ele, i) => {
                                if (i > columnaTitulos) {
                                    let claveselected = ele[colClaveUnidad] || "";
                                    let finded = CAT_UNIDADES.find(e => e.codigo === claveselected);
                                    if (finded) {
                                        claveselected = finded.descripcion;
                                    }

                                    // let pesoFixed=
                                    let obj = { isxml: finded ? null : "1", i, descripcion: ele[colDescripcion] || "", csat: ele[colClaveProducto] || "", pesoKG: parseFloat(ele[colPeso]).toFixed(2), cantidad: parseInt(ele[colCantidad]), unidad: claveselected, fraccion: ele[colFraccionArancelaria] || "", uuidcomercioext: "" };

                                    if (ele[colEmbalaje] && ele[colMaterialPeligroso] && ele[colEmbalaje] !== "" && ele[colMaterialPeligroso] !== "") {
                                        obj.peligroso = 1;
                                        let peliaux= ele[colMaterialPeligroso];   
                                        let pel=`${peliaux}`;
                                        pel=pel.trim();           
                                        obj.c_MaterialPeligroso = pel;
                                        obj.c_TipoEmbalaje = ele[colEmbalaje] || "";
                                        console.log("material peligroso", obj.c_MaterialPeligroso);
                                        console.log("type material peligroso", typeof obj.c_MaterialPeligroso);
                                    }
                                    //
                                    if (colPedimento) {
                                        let pedia = ele[colPedimento];
                                        let pedi = `${pedia}`;
                                        let aux = pedi.replaceAll(" ", "");
                                        if (aux.length === 15) {
                                            obj.pedimento = aux;
                                            mapPedimentos.add(aux);
                                            //let fixedpedimento=`${aux.substring(0,2)}  ${aux.substring(2,4)}  ${aux.substring(4,8)}  ${aux.substring(8,15)}`;
                                            // mapPedimentos.add(fixedpedimento);
                                        }
                                    } else if (formatoActual.i_pedimento !== undefined && formatoActual.col_pedimento !== undefined) {
                                        console.log("pedimento custom", formatoActual.i_pedimento + " " + formatoActual.col_pedimento);
                                        let pedi = rows[formatoActual.i_pedimento][formatoActual.col_pedimento];
                                        let aux = pedi.replaceAll(" ", "");
                                        if (aux.length === 15) {
                                            mapPedimentos.add(aux);
                                            //let fixedpedimento=`${aux.substring(0,2)}  ${aux.substring(2,4)}  ${aux.substring(4,8)}  ${aux.substring(8,15)}`;
                                            // mapPedimentos.add(fixedpedimento);
                                        }
                                    }


                                    aux.push(obj);
                                }
                            });

                            let arraypedi = Array.from(mapPedimentos);
                            if (arraypedi.length > 0) {
                                setPedimentos(arraypedi.map(a => {
                                    return `${a.substring(0, 2)}  ${a.substring(2, 4)}  ${a.substring(4, 8)}  ${a.substring(8, 15)}`;

                                }));
                            }
                            setMercancias(aux);

                        } else {
                            console.log("no clave producto col");
                        }

                    }
                    // `rows` is an array of rows
                    // each row being an array of cells.
                })
            }


        }
    }

    return (
        <Fragment>
            {
                <div className="row">
                    <div className="col-12">

                        <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}><a className="label label-info" style={{ fontSize: 12 }} href="/viajes"><ArrowBackIcon /></a> &nbsp;Agregar Viaje</h3>
                        <div className="card">
                            <div className="card-header card-header-flex">

                            </div>
                            <LoadingOverlay
                                active={isLoading}
                                spinner
                                text='Actualizando datos, espere ...'
                            >
                                <div className="card-body">
                                    <div className={classes.root}>
                                        <AppBar position="static">
                                            <Tabs
                                                value={value}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                onChange={handleChange}
                                                aria-label="simple tabs example">
                                                <Tab label="Viaje" {...a11yProps(0)} />
                                                <Tab label="Rentabilidad" {...a11yProps(1)} />
                                                <Tab label="Gastos combustible" {...a11yProps(2)} />
                                            </Tabs>
                                        </AppBar>
                                        <TabPanel value={value} index={0}>
                                            <Grid item xs={2}>
                                                <label> <Typography component="div">
                                                    <Grid component="label" container alignItems="center" spacing={1}>
                                                        <Grid item style={{ fontSize: '12px' }}>&nbsp;&nbsp;CP</Grid>
                                                        <Grid item>
                                                            <IOSSwitch checked={isFormatoNuevo} onChange={() => setIsFormatoNuevo(!isFormatoNuevo)} name="checkedC" />
                                                        </Grid>
                                                        <Grid item style={{ fontSize: '12px' }}>CCP</Grid>
                                                    </Grid>
                                                </Typography></label>
                                            </Grid>

                                            <Stepper activeStep={activeStep}>
                                                {CATALOGO_STATUS.map((label, index) => {
                                                    const stepProps = {};
                                                    const labelProps = {};

                                                    return (
                                                        <Step key={label} {...stepProps}>
                                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>
                                            <div className="row" style={{ color: '#000', padding: '3px', marginLeft: '2px', marginBottom: '5px', width: '99%' }}>
                                                <div className="form-inline" style={{ marginLeft: '20px' }}>
                                                    <div className="form-group">
                                                        <label htmlFor="inputreferencia">No. Viaje</label>
                                                        {
                                                            isEdit ?
                                                                <input disabled type="text" id="inputreferencia" style={{ height: '30px', width: '100px', backgroundColor: '#afe8a1' }} value={nviaje} onChange={e => setNviaje(e.target.value)} className="form-control mx-sm-2" />
                                                                : <input disabled type="text" id="inputreferencia" style={{ height: '30px', width: '100px' }} value={nviaje} onChange={e => setNviaje(e.target.value)} className="form-control mx-sm-2" />

                                                        }

                                                    </div>
                                                    {


                                                        <div className="form-group">
                                                            <label> <Typography component="div">
                                                                <Grid component="label" container alignItems="center" spacing={1} style={{ margin: '1px' }}>
                                                                    <Grid item style={{ fontSize: '0.875rem' }}>Vacio</Grid>
                                                                    <Grid item>
                                                                        <AntSwitch checked={isViajeCargado} onChange={() => setIsViajeCargado(!isViajeCargado)} name="checkedCisfull" />
                                                                    </Grid>
                                                                    <Grid item style={{ fontSize: '0.875rem' }}>Cargado</Grid>
                                                                </Grid>
                                                            </Typography></label>
                                                            <label htmlFor="inputfecha">&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                                        </div>
                                                    }

                                                    <div className="form-group">
                                                        <label htmlFor="inputreferencia">Carta Porte</label>
                                                        {
                                                            savedCP ?
                                                                <input disabled type="text" id="inputreferencia" style={{ height: '30px', width: '100px', backgroundColor: '#afe8a1' }} value={ncarta} onChange={e => setNcarta(e.target.value)} className="form-control mx-sm-2" />
                                                                : <input disabled type="text" id="inputreferencia" style={{ height: '30px', width: '100px' }} value={ncarta} onChange={e => setNcarta(e.target.value)} className="form-control mx-sm-2" />

                                                        }

                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="inputreferencia">Tipo de viaje</label>
                                                        <select className={"form-control " + classes.input} style={{ fontSize: '14px', height: '30px', width: '170px', marginRight: '10px', marginLeft: '10px' }} name='clase' onChange={(e) => setTipo(e.target.value)} value={tipo} required >
                                                            {
                                                                CATALOGOS_TIPOS.map((item, index) => {
                                                                    return <option key={index} value={item}>{item}</option>
                                                                })
                                                            }
                                                        </select>

                                                    </div>
                                                    <div className="form-group">
                                                        <label> <Typography component="div">
                                                            <Grid component="label" container alignItems="center" spacing={1} style={{ margin: '1px' }}>
                                                                <Grid item style={{ fontSize: '0.875rem' }}>Sencillo</Grid>
                                                                <Grid item>
                                                                    <AntSwitch checked={isFull} onChange={() => setIsFull(!isFull)} name="checkedCisfull" />
                                                                </Grid>
                                                                <Grid item style={{ fontSize: '0.875rem' }}>Full</Grid>
                                                            </Grid>
                                                        </Typography></label>
                                                        <label htmlFor="inputfecha">&nbsp;&nbsp;&nbsp;&nbsp;Fecha</label>
                                                    </div>


                                                    <div className="form-group">

                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            style={{ height: '30px', width: '110px' }}
                                                            disabled
                                                            className={"form-control mx-sm-2"} selected={fecha} onChange={date => setFecha(date)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="inputreferencia">Terminal</label>
                                                        <select className={"form-control " + classes.input} style={{ fontSize: '14px', height: '30px', width: '120px', marginRight: '10px', marginLeft: '10px' }} name='clase' onChange={(e) => setTerminal(e.target.value)} value={terminal} required >
                                                            {
                                                                terminales.map((item, index) => {
                                                                    return <option key={index} value={item}>{item}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="inputreferencia">Referencia</label>
                                                        <input placeholder="Ej. P.O." type="text" id="inputreferencia" style={{ height: '30px', width: '110px' }} value={referencia} onChange={e => setReferencia(e.target.value)} className="form-control mx-sm-1" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="inputreferencia">Estatus</label>
                                                        <select className={"form-control " + classes.input} style={{ fontSize: '13px', height: '30px', width: '120px', marginRight: '10px', marginLeft: '10px' }} name='clase' onChange={(e) => setStatus(e.target.value)} value={status} required >
                                                            {
                                                                CATALOGO_STATUS.map((item, index) => {
                                                                    if (item === 'Facturado' || item === 'Pagado') {
                                                                        return <option key={index} value={item} disabled>{item}</option>
                                                                    } else
                                                                        return <option key={index} value={item}>{item}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    {/*<Grid item xs={2}>
                                                        <label> <Typography component="div">
                                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                                <Grid item style={{ fontSize: '12px' }}>&nbsp;&nbsp;$dll.</Grid>
                                                                <Grid item>
                                                                    <AntSwitch checked={currencyMX} onChange={() => setCurrencyMX(!currencyMX)} name="checkedC" />
                                                                </Grid>
                                                                <Grid item style={{ fontSize: '12px' }}>$mx</Grid>
                                                            </Grid>
                                                        </Typography></label>
                                                        </Grid>*/}
                                                    <div>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            className={classes.button}
                                                            onClick={() => presave()}
                                                            startIcon={<SaveIcon />}
                                                        >
                                                            Guardar
                                                        </Button>
                                                        {/*<Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            className={classes.button}
                                                            style={{ marginLeft: '10px' }}
                                                            onClick={() => setIsHorarios(true)}
                                                            startIcon={<AccessTimeIcon />}
                                                        >
                                                            Tiempos
                                                        </Button>*/}
                                                        {/*<Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            className={classes.button}
                                                            style={{ marginLeft: '10px' }}
                                                            onClick={() => setIsOpenCPDialog(true)}
                                                            startIcon={<AccessTimeIcon />}
                                                        >
                                                            CP
                                                        </Button>*/}


                                                        {isEdit && savedCP && <Button
                                                            variant="contained"
                                                            size="small"
                                                            color="primary"
                                                            style={{ marginLeft: '10px' }}
                                                            className={classes.button}
                                                            onClick={() => WP()}
                                                            startIcon={<WhatsAppIcon />}
                                                        >
                                                            WP
                                                        </Button>}
                                                        {isEdit && savedCP && !isFormatoNuevo && <Button
                                                            variant="contained"
                                                            size="small"
                                                            color="primary"
                                                            style={{ marginLeft: '10px' }}
                                                            className={classes.button}
                                                            onClick={() => CP()}
                                                            startIcon={<DescriptionIcon />}
                                                        >
                                                            CP
                                                        </Button>}

                                                        {isEdit && savedCP && isFormatoNuevo && <Button
                                                            variant="contained"
                                                            size="small"
                                                            color="primary"
                                                            style={{ marginLeft: '10px' }}
                                                            className={classes.button}
                                                            onClick={() => CCP()}
                                                            startIcon={<DescriptionIcon />}
                                                        >
                                                            CCP
                                                        </Button>}

                                                        {isEdit && <Button
                                                            variant="contained"
                                                            size="small"
                                                            style={{ marginLeft: '10px', backgroundColor: "#ff5722", color: 'white' }}
                                                            className={classes.button}
                                                            onClick={() => cancelar()}
                                                            startIcon={<ClearIcon />}
                                                        >
                                                            Cancelar
                                                        </Button>}
                                                        {/* <button onClick={temporalSubirUbicacion}>ubicacion</button>*/}
                                                    </div>
                                                </div>

                                            </div>

                                            <Grid container className={classes.root} spacing={0}>
                                                <Grid item xs={12}>
                                                    <Grid container className={classes.paperscontainer} justify="center" spacing={2}>
                                                        <Grid className={classes.paperroot} key={1} item>
                                                            <Paper className={classes.paper} elevation={3} style={{ backgroundColor: '#f0f8ff' }}>
                                                                <Grid item className={classes.title} xs={12}>
                                                                    <p style={{ fontWeight: 'bold' }}>Datos del viaje</p>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <div className="d-flex justify-content-between" style={{ width: '39%' }}>
                                                                            <label className={classes.label} style={{ fontSize: '12.01px' }}>Cliente</label>
                                                                            {!isEdit && <IconButton onClick={() => setIsOpenCreateCliente(true)} variant="contained" size="small" color="primary" aria-label="add">
                                                                                <AddIcon />
                                                                            </IconButton>}
                                                                        </div>
                                                                        {
                                                                            /**
                                                                             *  <Autocomplete
                                                                                    id="combo-box-demo0c"
                                                                                    options={listremolques}
                                                                                    getOptionLabel={(option) => option.name}
                                                                                    getOptionSelected={(option, value) => option.name === value.name}
                                                                                    defaultValue={listremolques.find(element => element.nombre === remolque1)}
                                                                                    className={classes.input}
                                                                                    style={{ height: '30px' }}
                                                                                    onChange={e => setRemolque1(e.target.innerText)}
                                                                                    renderInput={(params) => <TextField placeholder={messageRemolque1} className="reducedselect" onChange={e => setRemolque1(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                                /> :
                                                                                <input type="text" name='segmento' className={"form-control " + classes.input} disabled />
                                                                             */
                                                                            !isEdit && cliente &&
                                                                            <Autocomplete
                                                                                id="combo-box-demo0c"
                                                                                options={clientes}
                                                                                getOptionLabel={(option) => option.name}
                                                                                getOptionSelected={(option, value) => option.name === value.name}
                                                                                defaultValue={clientes.find(element => element.name.trim() === cliente.trim())}
                                                                                className={classes.input}
                                                                                style={{ height: '30px' }}
                                                                                onChange={(e, val) => setCliente(val ? val.name : "")}
                                                                                renderInput={(params) => <TextField className="reducedselect" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                            />}

                                                                        {
                                                                            !isEdit && !cliente &&
                                                                            <Autocomplete
                                                                                id="combo-box-demo0c"
                                                                                options={clientes}
                                                                                getOptionLabel={(option) => option.name}
                                                                                getOptionSelected={(option, value) => option.name === value.name}
                                                                                className={classes.input}
                                                                                style={{ height: '30px' }}
                                                                                onChange={(e, val) => setCliente(val ? val.name : "")}
                                                                                renderInput={(params) => <TextField className="reducedselect" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                            />
                                                                        }

                                                                        {isEdit && <input type="text" className={"form-control " + classes.input} value={cliente} onChange={e => setCliente(e.target.value)} variant="outlined" disabled />}


                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <div className="d-flex justify-content-between" style={{ width: '39%' }}>
                                                                            <label className={classes.label}>Origen</label>
                                                                            {!isEdit && <IconButton onClick={() => setIsOpenCreateUbicacion(true)} variant="contained" size="small" color="primary" aria-label="add">
                                                                                <AddIcon />
                                                                            </IconButton>}
                                                                        </div>

                                                                        {
                                                                            !isEdit && origen !== "" && <Autocomplete
                                                                                id="combo-box-demo0"
                                                                                options={localidades}
                                                                                getOptionLabel={(option) => option.name}
                                                                                getOptionSelected={(option, value) => option.name === value.name}
                                                                                defaultValue={localidades.find(element => element.name.trim() === origen.trim())}
                                                                                className={classes.input}
                                                                                style={{ height: '30px' }}
                                                                                onChange={(e, val) => setOrigen(val ? val.name : "")}
                                                                                renderInput={(params) => <TextField className="reducedselect" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                            />}
                                                                        {
                                                                            !isEdit && origen === "" && <Autocomplete
                                                                                id="combo-box-demo0"
                                                                                options={localidades}
                                                                                getOptionLabel={(option) => option.name}
                                                                                getOptionSelected={(option, value) => option.name === value.name}
                                                                                className={classes.input}
                                                                                style={{ height: '30px' }}
                                                                                onChange={(e, val) => setOrigen(val ? val.name : "")}
                                                                                renderInput={(params) => <TextField className="reducedselect" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                            />}

                                                                        {isEdit && <input type="text" className={"form-control " + classes.input} value={origen} onChange={e => setOrigen(e.target.value)} variant="outlined" disabled />}




                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <label className={classes.label}>Fecha Origen</label>
                                                                        <DatePicker
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className={"form-control " + classes.inputdate} selected={fechaOrigen} onChange={date => setFechaOrigen(date)} />

                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <div className="d-flex justify-content-between" style={{ width: '39%' }}>
                                                                            <label className={classes.label}>Destino</label>
                                                                            <IconButton onClick={() => setIsOpenParadas(true)} variant="contained" size="small" color="primary" aria-label="add">
                                                                                <AddLocationRounded />
                                                                            </IconButton>
                                                                            {!isEdit && <IconButton onClick={() => setIsOpenCreateUbicacion(true)} variant="contained" size="small" color="primary" aria-label="add">
                                                                                <AddIcon />
                                                                            </IconButton>}
                                                                        </div>
                                                                        {
                                                                            !isEdit && destino !== "" && <Autocomplete
                                                                                id="combo-box-demo"
                                                                                options={localidades}
                                                                                value={localidades.find(element => element.name == destino)}
                                                                                getOptionLabel={(option) => option.name}
                                                                                getOptionSelected={(option, value) => option.name === value.name}
                                                                                defaultValue={localidades.find(element => element.name.trim() === destino.trim())}
                                                                                className={classes.input}
                                                                                style={{ height: '30px' }}
                                                                                onChange={(e, val) => setDestino(val ? val.name : "")}
                                                                                renderInput={(params) => <TextField className="reducedselect" value={destino} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                            />}
                                                                        {
                                                                            !isEdit && destino === "" && <Autocomplete
                                                                                id="combo-box-demo"
                                                                                options={localidades}
                                                                                value={localidades.find(element => element.name == destino)}
                                                                                getOptionLabel={(option) => option.name}
                                                                                getOptionSelected={(option, value) => option.name === value.name}
                                                                                className={classes.input}
                                                                                style={{ height: '30px' }}
                                                                                onChange={(e, val) => setDestino(val ? val.name : "")}
                                                                                renderInput={(params) => <TextField className="reducedselect" value={destino} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                            />}
                                                                        {isEdit && <input type="text" className={"form-control " + classes.input} value={destino} onChange={e => setOrigen(e.target.value)} variant="outlined" disabled />}


                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <label className={classes.label}>Fecha Destino</label>
                                                                        <DatePicker
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className={"form-control " + classes.inputdate} selected={fechaDestino} onChange={date => setFechaDestino(date)} />

                                                                    </Grid>
                                                                </Grid>
                                                                {isViajeCargado && <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <label className={classes.label}>Clase de viaje</label>
                                                                        <select className={"form-control " + classes.input} style={{ fontSize: '14px' }} name='clase' onChange={(e) => setClaseViaje(e.target.value)} value={claseViaje} required >
                                                                            {
                                                                                CATALOGOS_CLASES_VIAJE.map((item, index) => {
                                                                                    return <option key={index} value={item}>{item}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </Grid>

                                                                </Grid>}

                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <label className={classes.label}>Ruteo</label>
                                                                        <input type="text" name='segmento' className={"form-control " + classes.input} value={ruta} onChange={e => setRuta(e.target.value)} disabled />
                                                                    </Grid>
                                                                </Grid>
                                                                {isViajeCargado && <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <label className={classes.label}>No. Sello {isFull && '1'} </label>
                                                                        <input type="text" name='segmento' className={"form-control " + classes.input} value={noSello} onChange={handleNoSello} />
                                                                    </Grid>
                                                                </Grid>}
                                                                {
                                                                    isFull && <Grid item xs={12}>
                                                                        <Grid
                                                                            container
                                                                            direction="row"
                                                                            justify="space-between"
                                                                            alignItems="center"
                                                                        >
                                                                            <label className={classes.label}>No. Sello 2</label>
                                                                            <input type="text" name='segmento' className={"form-control " + classes.input} value={noSello2} onChange={handleNoSello2} />
                                                                        </Grid>
                                                                    </Grid>
                                                                }

                                                                {isViajeCargado && <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <label className={classes.label}>Tipo de recolección</label>
                                                                        <select className={"form-control " + classes.input} style={{ fontSize: '14px' }} name='tipo' onChange={(e) => setRecoleccion(e.target.value)} value={recoleccion} required >
                                                                            {
                                                                                CATALOGOS_RECOLECCION.map((item, index) => {
                                                                                    return <option key={index} value={item}>{item}</option>
                                                                                })
                                                                            }
                                                                        </select>

                                                                    </Grid>
                                                                </Grid>}
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <label className={classes.label}>Segmento de negocio</label>
                                                                        <select className={"form-control " + classes.input} name='segmento' onChange={(e) => setSegmentoNegocio(e.target.value)} value={segmentoNegocio} required >
                                                                            {
                                                                                usuarioobj && usuarioobj.segmento_negocio.map((item, index) => {

                                                                                    return segmentoNegocio == item.value ?
                                                                                        <option key={index} value={item.value} selected>{item.value}</option> :
                                                                                        <option key={index} value={item.value}>{item.value}</option>

                                                                                })
                                                                            }
                                                                        </select>
                                                                    </Grid>
                                                                </Grid>
                                                                <br />
                                                            </Paper>
                                                        </Grid>
                                                        <Grid className={classes.paperroot} key={2} item>
                                                            <Paper className={classes.paper} elevation={3} style={{ backgroundColor: '#f0f8ff' }}>
                                                                <Grid item className={classes.title} xs={12}>
                                                                    <p style={{ fontWeight: 'bold' }}>Detalles</p>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <label style={{ width: 'auto' }} className={classes.label}>Distancia <a style={{ cursor: 'pointer', color: 'blue' }} onClick={() => openMap()}>(Google Maps)</a> </label>
                                                                    <input type="number" name='segmento' className={"form-control " + classes.inputwithextra} value={distBase} onChange={e => setDistBase(e.target.value)} />
                                                                    <label style={{ width: "auto", margin: 'auto', float: "right", marginTop: "5px" }}> {

                                                                        <Typography component="div">
                                                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                                                <Grid item style={{ fontSize: '11.73px' }}>mi.</Grid>
                                                                                <Grid item>
                                                                                    <AntSwitch checked={distKM} onChange={() => setdistKM(!distKM)} name="checkedC" />
                                                                                </Grid>
                                                                                <Grid item style={{ fontSize: '11.73px' }}>km.</Grid>
                                                                            </Grid>
                                                                        </Typography>}</label>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <label style={{ width: 'auto' }} className={classes.label}>Último vacio</label>
                                                                    <input type="number" name='segmento' className={"form-control " + classes.inputwithextra} value={distVacio} onChange={e => setDistVacio(e.target.value)} />
                                                                    <label style={{ width: "auto", margin: 'auto', float: "right", marginTop: "5px" }} >{

                                                                        <Typography component="div">
                                                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                                                <Grid item style={{ fontSize: '11.73px' }}>mi.</Grid>
                                                                                <Grid item>
                                                                                    <AntSwitch checked={distKM} onChange={() => setdistKM(!distKM)} name="checkedC" />
                                                                                </Grid>
                                                                                <Grid item style={{ fontSize: '11.73px' }}>km.</Grid>
                                                                            </Grid>
                                                                        </Typography>}</label>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <label style={{ width: 'auto' }} className={classes.label}>Totales</label>
                                                                    <input type="number" name='segmento' className={"form-control " + classes.inputwithextra} value={distTotales} disabled />
                                                                    <label style={{ width: "auto", margin: 'auto', float: "right", marginTop: "5px" }}>{

                                                                        <Typography component="div">
                                                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                                                <Grid item style={{ fontSize: '11.73px' }}>mi.</Grid>
                                                                                <Grid item>
                                                                                    <AntSwitch disabled checked={distKM} onChange={() => setdistKM(!distKM)} name="checkedC" />
                                                                                </Grid>
                                                                                <Grid item style={{ fontSize: '11.73px' }}>km.</Grid>
                                                                            </Grid>
                                                                        </Typography>}</label>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <label className={classes.label}>Origen</label>
                                                                        <input type="text" name='segmento' className={"form-control " + classes.input} value={origenCiudad != '' ? `${origenCiudad},${origenEstadoShort}` : '-'} disabled />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <label className={classes.label}>Destino</label>
                                                                        <input type="text" name='segmento' className={"form-control " + classes.input} value={destinoCiudad != '' ? `${destinoCiudad},${destinoEstadoShort}` : ''} disabled />
                                                                    </Grid>
                                                                </Grid>
                                                                {
                                                                    isInternational && <Grid item xs={12}>
                                                                        <Grid
                                                                            container
                                                                            direction="row"
                                                                            justify="space-between"
                                                                            alignItems="center"
                                                                        >
                                                                            <label className={classes.label}>Cruce</label>
                                                                            <select className={"form-control " + classes.input} name='tipo' onChange={(e) => setCruce(e.target.value)} value={cruce} required >
                                                                                {
                                                                                    CATALOGO_CRUCES.map((item, index) => {
                                                                                        return <option key={index} value={item}>{item}</option>
                                                                                    })
                                                                                }
                                                                            </select>

                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <div className="d-flex justify-content-between" style={{ width: '39%' }}>
                                                                            <label className={classes.label} style={{ fontSize: '12.01px', width: 'auto', lineHeight: 'normal', paddingTop: 2 }}>Remolque 1 </label>
                                                                            <IconButton onClick={() => RemolqueAddClick()} variant="contained" size="small" color="primary" aria-label="add">
                                                                                <AddIcon />
                                                                            </IconButton>

                                                                        </div>
                                                                        {
                                                                            loadListremolques ?
                                                                                <Autocomplete
                                                                                    id="combo-box-demo0c"
                                                                                    options={listremolques}
                                                                                    getOptionLabel={(option) => option.name}
                                                                                    getOptionSelected={(option, value) => option.name === value.name}
                                                                                    defaultValue={listremolques.find(element => element.nombre === remolque1)}
                                                                                    className={classes.input}
                                                                                    style={{ height: '30px' }}
                                                                                    onChange={(e, val) => setRemolque1(val ? val.name : "")}
                                                                                    renderInput={(params) => <TextField placeholder={messageRemolque1} className="reducedselect" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                                /> :
                                                                                <input type="text" name='segmento' className={"form-control " + classes.input} disabled />
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <div className="d-flex justify-content-between" style={{ width: '39%' }}>
                                                                            <label className={classes.label} style={{ fontSize: '12.01px', width: 'auto', lineHeight: 'normal', paddingTop: 3 }}>Remolque 2 </label>

                                                                            <IconButton onClick={() => setIsOpenCreateActivo(true)} variant="contained" size="small" color="primary" aria-label="add">
                                                                                <AddIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                        {

                                                                            loadListremolques ?
                                                                                <Autocomplete
                                                                                    id="combo-box-demo0c"
                                                                                    options={listremolques}
                                                                                    getOptionLabel={(option) => option.name}
                                                                                    getOptionSelected={(option, value) => option.name === value.name}
                                                                                    defaultValue={listremolques.find(element => element.nombre === remolque2)}
                                                                                    className={classes.input}
                                                                                    style={{ height: '30px' }}
                                                                                    onChange={(e, val) => setRemolque2(val ? val.name : "")}
                                                                                    renderInput={(params) => <TextField placeholder={messageRemolque2} className="reducedselect" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                                /> :
                                                                                <input type="text" name='segmento' className={"form-control " + classes.input} disabled />
                                                                        }

                                                                    </Grid>
                                                                </Grid>
                                                                {
                                                                    cantidadremoleques > 2 &&
                                                                    <Grid item xs={12}>
                                                                        <Grid
                                                                            container
                                                                            direction="row"
                                                                            justify="space-between"
                                                                            alignItems="center"
                                                                        >
                                                                            <div className="d-flex justify-content-between" style={{ width: '39%' }}>
                                                                                <label className={classes.label} style={{ fontSize: '12.01px', width: 'auto', lineHeight: 'normal', paddingTop: 3 }}>Remolque 3 </label>
                                                                                <IconButton onClick={() => setIsOpenCreateActivo(true)} variant="contained" size="small" color="primary" aria-label="add">
                                                                                    <AddIcon />
                                                                                </IconButton>
                                                                            </div>
                                                                            {

                                                                                loadListremolques ?
                                                                                    <Autocomplete
                                                                                        id="combo-box-demo0c"
                                                                                        options={listremolques}
                                                                                        getOptionLabel={(option) => option.name}
                                                                                        getOptionSelected={(option, value) => option.name === value.name}
                                                                                        defaultValue={listremolques.find(element => element.nombre === remolque3)}
                                                                                        className={classes.input}
                                                                                        style={{ height: '30px' }}
                                                                                        onChange={(e, val) => setRemolque3(val ? val.name : "")}
                                                                                        renderInput={(params) => <TextField className="reducedselect" placeholder={messageRemolque3} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                                    /> :
                                                                                    <input type="text" name='segmento' className={"form-control " + classes.input} disabled />
                                                                            }

                                                                        </Grid>
                                                                    </Grid>

                                                                }
                                                                {
                                                                    cantidadremoleques > 3 &&
                                                                    <Grid item xs={12}>
                                                                        <Grid
                                                                            container
                                                                            direction="row"
                                                                            justify="space-between"
                                                                            alignItems="center"
                                                                        >
                                                                            <div className="d-flex justify-content-between" style={{ width: '39%' }}>
                                                                                <label className={classes.label} style={{ fontSize: '12.01px', width: 'auto', lineHeight: 'normal', paddingTop: 3 }}>Remolque 4</label>
                                                                                <IconButton onClick={() => setIsOpenCreateActivo(true)} variant="contained" size="small" color="primary" aria-label="add">
                                                                                    <AddIcon />
                                                                                </IconButton>
                                                                            </div>
                                                                            {

                                                                                loadListremolques ?
                                                                                    <Autocomplete
                                                                                        id="combo-box-demo0c"
                                                                                        options={listremolques}
                                                                                        getOptionLabel={(option) => option.name}
                                                                                        getOptionSelected={(option, value) => option.name === value.name}
                                                                                        defaultValue={listremolques.find(element => element.nombre === remolque4)}
                                                                                        className={classes.input}
                                                                                        style={{ height: '30px' }}
                                                                                        onChange={(e, val) => setRemolque4(val ? val.name : "")}
                                                                                        renderInput={(params) => <TextField className="reducedselect" placeholder={messageRemolque4} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                                    /> :
                                                                                    <input type="text" name='segmento' className={"form-control " + classes.input} disabled />
                                                                            }

                                                                        </Grid>
                                                                    </Grid>

                                                                }
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <div className="d-flex justify-content-between" style={{ width: '39%' }}>
                                                                            <label className={classes.label} style={{ fontSize: '12.01px', width: 'auto', lineHeight: 'normal', paddingTop: 3 }}>Auxiliar 1</label>
                                                                            <IconButton onClick={() => setIsOpenCreateActivo(true)} variant="contained" size="small" color="primary" aria-label="add">
                                                                                <AddIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                        {

                                                                            loadListremolques ?
                                                                                <Autocomplete
                                                                                    id="combo-box-demo0c"
                                                                                    options={listAuxiliares}
                                                                                    getOptionLabel={(option) => option.name}
                                                                                    getOptionSelected={(option, value) => option.name === value.name}
                                                                                    defaultValue={listAuxiliares.find(element => element.nombre === otro)}
                                                                                    className={classes.input}
                                                                                    style={{ height: '30px' }}
                                                                                    onChange={(e, val) => setOtro(val ? val.name : "")}
                                                                                    renderInput={(params) => <TextField className="reducedselect" placeholder={messageAuxiliar1} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                                /> :
                                                                                <input type="text" name='segmento' className={"form-control " + classes.input} disabled />
                                                                        }

                                                                    </Grid>
                                                                </Grid>

                                                                {
                                                                    cantidadAxiliares > 1 &&
                                                                    <Grid item xs={12}>
                                                                        <Grid
                                                                            container
                                                                            direction="row"
                                                                            justify="space-between"
                                                                            alignItems="center"
                                                                        >
                                                                            <div className="d-flex justify-content-between" style={{ width: '39%' }}>
                                                                                <label className={classes.label} style={{ fontSize: '12.01px', width: 'auto', lineHeight: 'normal', paddingTop: 3 }}>Auxiliar 2</label>
                                                                                <IconButton onClick={() => setIsOpenCreateActivo(true)} variant="contained" size="small" color="primary" aria-label="add">
                                                                                    <AddIcon />
                                                                                </IconButton>
                                                                            </div>
                                                                            {

                                                                                loadListremolques ?
                                                                                    <Autocomplete
                                                                                        id="combo-box-demo0c"
                                                                                        options={listAuxiliares}
                                                                                        getOptionLabel={(option) => option.name}
                                                                                        getOptionSelected={(option, value) => option.name === value.name}
                                                                                        defaultValue={listAuxiliares.find(element => element.nombre === otro2)}
                                                                                        className={classes.input}
                                                                                        style={{ height: '30px' }}
                                                                                        onChange={(e, val) => setOtro2(val ? val.name : "")}
                                                                                        renderInput={(params) => <TextField className="reducedselect" placeholder={messageAuxiliar2} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                                    /> :
                                                                                    <input type="text" name='segmento' className={"form-control " + classes.input} disabled />
                                                                            }

                                                                        </Grid>
                                                                    </Grid>


                                                                }
                                                                {
                                                                    cantidadAxiliares > 2 &&
                                                                    <Grid item xs={12}>
                                                                        <Grid
                                                                            container
                                                                            direction="row"
                                                                            justify="space-between"
                                                                            alignItems="center"
                                                                        >
                                                                            <div className="d-flex justify-content-between" style={{ width: '39%' }}>
                                                                                <label className={classes.label} style={{ fontSize: '12.01px', width: 'auto', lineHeight: 'normal', paddingTop: 3 }}>Auxiliar 3</label>
                                                                                <IconButton onClick={() => setIsOpenCreateActivo(true)} variant="contained" size="small" color="primary" aria-label="add">
                                                                                    <AddIcon />
                                                                                </IconButton>
                                                                            </div>
                                                                            {

                                                                                loadListremolques ?
                                                                                    <Autocomplete
                                                                                        id="combo-box-demo0c"
                                                                                        options={listAuxiliares}
                                                                                        getOptionLabel={(option) => option.name}
                                                                                        getOptionSelected={(option, value) => option.name === value.name}
                                                                                        defaultValue={listAuxiliares.find(element => element.nombre === otro3)}
                                                                                        className={classes.input}
                                                                                        style={{ height: '30px' }}
                                                                                        onChange={(e, val) => setOtro3(val ? val.name : "")}
                                                                                        renderInput={(params) => <TextField className="reducedselect" placeholder={messageAuxiliar3} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                                    /> :
                                                                                    <input type="text" name='segmento' className={"form-control " + classes.input} disabled />
                                                                            }

                                                                        </Grid>
                                                                    </Grid>


                                                                }
                                                                {
                                                                    cantidadAxiliares > 3 &&
                                                                    <Grid item xs={12}>
                                                                        <Grid
                                                                            container
                                                                            direction="row"
                                                                            justify="space-between"
                                                                            alignItems="center"
                                                                        >
                                                                            <div className="d-flex justify-content-between" style={{ width: '39%' }}>
                                                                                <label className={classes.label} style={{ fontSize: '12.01px', width: 'auto', lineHeight: 'normal', paddingTop: 3 }}>Auxiliar 4</label>
                                                                                <IconButton onClick={() => setIsOpenCreateActivo(true)} variant="contained" size="small" color="primary" aria-label="add">
                                                                                    <AddIcon />
                                                                                </IconButton>
                                                                            </div>
                                                                            {

                                                                                loadListremolques ?
                                                                                    <Autocomplete
                                                                                        id="combo-box-demo0c"
                                                                                        options={listAuxiliares}
                                                                                        getOptionLabel={(option) => option.name}
                                                                                        getOptionSelected={(option, value) => option.name === value.name}
                                                                                        defaultValue={listAuxiliares.find(element => element.nombre === otro4)}
                                                                                        className={classes.input}
                                                                                        style={{ height: '30px' }}
                                                                                        onChange={(e, val) => setOtro4(val ? val.name : "")}
                                                                                        renderInput={(params) => <TextField className="reducedselect" placeholder={messageAuxiliar4} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                                    /> :
                                                                                    <input type="text" name='segmento' className={"form-control " + classes.input} disabled />
                                                                            }

                                                                        </Grid>
                                                                    </Grid>


                                                                }

                                                                <br />
                                                            </Paper>
                                                        </Grid>
                                                        <Grid className={classes.paperroot} key={3} item>
                                                            <Paper className={classes.paper} elevation={3} style={{ backgroundColor: '#f0f8ff' }}>
                                                                <Grid item className={classes.title} xs={12}>
                                                                    <p style={{ fontWeight: 'bold' }}>Carta Porte</p>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <label className={classes.label}>Operador 1</label>
                                                                        <select className={"form-control " + classes.input} name='operador1' onChange={(e) => setOperador1Id(e.target.value)} value={operador1Id} required >
                                                                            {
                                                                                operadores.map((item, index) => {
                                                                                    return <option key={index} value={item.id}>{item.nombre}</option>
                                                                                })
                                                                            }
                                                                        </select>

                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <label className={classes.label}>Operador 2</label>
                                                                        <select className={"form-control " + classes.input} name='operador2' onChange={(e) => setOperador2Id(e.target.value)} value={operador2Id} required >
                                                                            {
                                                                                operadores.map((item, index) => {
                                                                                    return <option key={index} value={item.id}>{item.nombre}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <label className={classes.label}>Comisión Operador</label>
                                                                        <Autocomplete
                                                                            style={{ padding: '0' }}
                                                                            id="free-solo-demo"
                                                                            className={"form-control " + classes.input}
                                                                            freeSolo
                                                                            options={tarifaOperador.map((option) => '' + option.tarifa)}
                                                                            value={toperadorSelected ? toperadorSelected.tarifa : ""}
                                                                            onKeyPress={(e) => { e.key === 'Enter' && eventEnterOnFindCreate(e) }}
                                                                            onChange={e => handleChangeTabuladorByValue(e.target.innerText)}
                                                                            renderInput={(params) => (
                                                                                <TextField style={{ padding: '0' }} {...params} name="metodopago" onChange={e => handleTabuladorCustom(e.target.value)} className="form-control" variant="outlined" required />

                                                                            )}
                                                                        />

                                                                        {/*
                                                                             
                                                                        <select className={"form-control " + classes.input} name='operador2' onChange={(e) => handleChangeTabulador(e.target.value)} value={toperadorSelected ? toperadorSelected.index : 0} required >
                                                                            {
                                                                                tarifaOperador.map((item, index) => {
                                                                                    return <option key={index} value={item.index}>{item.tarifa}</option>
                                                                                })
                                                                            }
                                                                        </select>*/}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <label className={classes.label}>Unidad</label>
                                                                        <select className={"form-control " + classes.input} name='unidad' onChange={(e) => setUnidadId(e.target.value)} value={unidadId} required >
                                                                            {
                                                                                unidades.map((item, index) => {
                                                                                    return <option key={index} value={item.id}>{item.nombre}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </Grid>
                                                                </Grid>
                                                                {
                                                                    isinvalidtarifa && <Grid item xs={12}>
                                                                        <label className={classes.label}>Tarifa</label>
                                                                        <input type="text" style={{ backgroundColor: "#f39679" }} className={"form-control " + classes.input} value="TARIFA NO REGISTRADA" />

                                                                    </Grid>
                                                                }
                                                                {isViajeCargado && !isinvalidtarifa && <Grid item xs={12}>
                                                                    <label className={classes.label}>Tarifa</label>
                                                                    {/*<CurrencyInput disabled className={"form-control " + classes.inputwithextra} style={{ backgroundColor: "#dbffcf", marginTop: "2px" }} value={tarifa} onChangeEvent={handleChangeTarifa} prefix="$" required />
                                                                    */}
                                                                    <select className={"form-control " + classes.inputwithextra} name='tipolc' style={{ backgroundColor: "#dbffcf", marginTop: "2px" }} onChange={(event) => {
                                                                        console.log("heerrrrrrrrrrrrr");
                                                                        setTtarifarioSelected(tarifaTarifario.find(element => element.id === event.target.value))

                                                                    }} value={ttarifarioSelected ? ttarifarioSelected.id : ""} required >
                                                                        {
                                                                            tarifaTarifario.map((item, index) => {
                                                                                return <option key={index} value={item.id}>{`${item.tarifa}`}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                    {/*<Autocomplete
                                                                        style={{ padding: '0' }}
                                                                        
                                                                        id="free-solo-demo"
                                                                        className={"form-control " + classes.inputwithextra}
                                                                        freeSolo
                                                                        options={tarifaTarifario.map((option) => '' + option.tarifa)}
                                                                        value={ttarifarioSelected ? ttarifarioSelected.tarifa : ""}
                                                                        // onKeyPress={(e) => { e.key === 'Enter' && eventEnterOnFindCreate(e) }}
                                                                        onChange={e => handleChangeTarifarioByValue(e.target.innerText)}
                                                                        renderInput={(params) => (
                                                                            <TextField style={{ padding: '0' }} {...params} name="metodopago" onChange={e => handleTarifarioCustom(e.target.value)} className="form-control" variant="outlined" required />

                                                                        )}
                                                                    />*/}


                                                                    <label> <Typography component="div">
                                                                        <Grid component="label" container alignItems="center" spacing={1}>
                                                                            <Grid item style={{ fontSize: '11.73px' }}>&nbsp;&nbsp;$dll.</Grid>
                                                                            <Grid item>
                                                                                <AntSwitch disabled checked={currencyMX} onChange={() => setCurrencyMX(!currencyMX)} name="checkedC" />
                                                                            </Grid>
                                                                            <Grid item style={{ fontSize: '11.73px' }}>$mx</Grid>
                                                                        </Grid>
                                                                    </Typography></label>
                                                                </Grid>}
                                                                {/*<Grid item xs={12}>
                                                                    <label className={classes.label}>Peso</label>
                                                                    <input type="number" name='segmento' className={"form-control " + classes.inputwithextra} value={peso} onChange={e => setPeso(e.target.value)} />
                                                                    <label> <Typography component="div">
                                                                        <Grid component="label" container alignItems="center" spacing={1}>
                                                                            <Grid item>&nbsp;&nbsp;lb.</Grid>
                                                                            <Grid item>
                                                                                <AntSwitch checked={isPesoKG} onChange={() => setIsPesoKG(!isPesoKG)} name="checkedC" />
                                                                            </Grid>
                                                                            <Grid item>kg.</Grid>
                                                                        </Grid>
                                                                    </Typography></label>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <label className={classes.label}>Cantidad</label>
                                                                        <input type="text" name='segmento' className={"form-control " + classes.input} value={cantidad} onChange={e => setCantidad(e.target.value)} />
                                                                    </Grid>
                                                                        </Grid>*/}

                                                                {<Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <div className="d-flex justify-content-between" style={{ width: '39%' }}>
                                                                            <label className={classes.label} style={{ fontSize: '12.01px' }}>Pedimento</label>
                                                                            <IconButton onClick={() => setPedimentos(pedimentos.length < 5 ? [...pedimentos, ""] : [...pedimentos])} variant="contained" size="small" color="primary" aria-label="add">
                                                                                <AddIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                        <InputMask className={"form-control " + classes.input} mask="99  99  9999  9999999" maskChar="_" value={pedimentos[0]} onChange={e => changePedimento(0, e.target.value)} />


                                                                    </Grid>
                                                                </Grid>}
                                                                {
                                                                    pedimentos.map((element, index) => {

                                                                        return index > 0 ? <Grid item key={`pedimient${index}`} xs={12}>
                                                                            <Grid
                                                                                container
                                                                                direction="row"
                                                                                justify="space-between"
                                                                                alignItems="center"
                                                                            >
                                                                                <div className="d-flex justify-content-between" style={{ width: '39%' }}>
                                                                                    <label className={classes.label} style={{ fontSize: '12.01px' }}>Pedimento</label>
                                                                                    <IconButton onClick={() => removePedimento(index)} variant="contained" size="small" color="#F00" aria-label="add">
                                                                                        <HighlightOffIcon />
                                                                                    </IconButton>
                                                                                </div>
                                                                                <InputMask className={"form-control " + classes.input} mask="99  99  9999  9999999" maskChar="_" value={element} onChange={e => changePedimento(index, e.target.value)} />


                                                                            </Grid>
                                                                        </Grid> : <></>
                                                                    })
                                                                }

                                                                <Grid item xs={12}>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <label className={classes.label}>Comentarios</label>
                                                                        <textarea type="text" name='comentarios' className={"form-control " + classes.input} value={comentarios} onChange={e => setComentarios(e.target.value)} placeholder="Comentarios" />

                                                                    </Grid>
                                                                </Grid>


                                                                <br />
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <br />

                                            {isViajeCargado && <Grid container className={classes.root} spacing={0}>
                                                <Grid item xs={12}>
                                                    <Grid container className={classes.paperscontainer} justify="center" spacing={2}>
                                                        <Grid className={classes.paperrootfull} key={1} item>
                                                            <Paper className={classes.paper} elevation={3} style={{ backgroundColor: '#f0f8ff' }}>
                                                                <Grid item className={classes.title} xs={12}>
                                                                    <p style={{ fontWeight: 'bold' }}>Mercancias</p>
                                                                    <button type="button" style={{ marginRight: '10px' }} className="btn btn-outline-dark btn-sm" onClick={() => addMercancia()}><i className="fa fa-plus-circle iconfa"></i> &nbsp;Agregar</button>

                                                                    {isFormatoNuevo && tieneFormatos &&
                                                                        <select className={"form-control " + classes.input} name='unidad' onChange={(e) => setFormatoActual(formatos.find(ee => ee.id === e.target.value))} value={formatoActual.id}  >
                                                                            {
                                                                                formatos.map((item, index) => {
                                                                                    return <option key={index} value={item.id}>{item.nombre}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    }
                                                                    {isFormatoNuevo &&
                                                                        <Button
                                                                            component="label"
                                                                            className="btn btn-outline-dark btn-sm"
                                                                            style={{ marginRight: '10px' }}
                                                                        >
                                                                            {formatoActual.tipo}
                                                                            <input
                                                                                type="file"
                                                                                accept={formatoActual.tipo === "xml" ? "text/xml" : formatoActual.tipo === "json" ? ".json" : ".xlsx, .xls"}
                                                                                hidden
                                                                                onChange={handleChangeFile}
                                                                            />
                                                                        </Button>
                                                                    }
                                                                </Grid>

                                                                <Grid item xs={12} style={{ marginTop: '40px', padding: '5px' }}>
                                                                    {
                                                                        mercancias.map((element, index) => {
                                                                            if (!isFormatoNuevo) {
                                                                                return <div key={`mercan${index}`} className="row" key={element.id}>
                                                                                    <div className="form-group col-2">
                                                                                        {index === 0 && <label>Mercancia</label>}
                                                                                        <input type="text" name='referencia' className="form-control" value={element.descripcion} onChange={e => changeMercanciasOldFormat(e, element)} required />
                                                                                    </div>

                                                                                    <div className="form-group col-2" style={{ flex: "0 0 15%", maxWidth: '15%' }}>
                                                                                        {index === 0 && <label>Unidad</label>}
                                                                                        <select className={"form-control "} name='tipolc' onChange={(e) => changeUnidadMercancias(e, element)} value={element.unidad} required >
                                                                                            {
                                                                                                CAT_UNIDADES.map((item, index) => {
                                                                                                    return <option key={index} value={item.descripcion}>{item.descripcion}</option>
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="form-group col-2" style={{ flex: "0 0 9.8%", maxWidth: '9.8%' }}>
                                                                                        {index === 0 && <label>Cantidad</label>}
                                                                                        <input type="number" name='referencia' className="form-control" value={element.cantidad} onChange={e => changeCantidadMercancias(e, element)} required />
                                                                                    </div>
                                                                                    <div className="form-group col-2" style={{ flex: "0 0 9.8%", maxWidth: '9.8%' }}>
                                                                                        {index === 0 && <label>Peso Kg.</label>}
                                                                                        <input type="number" name='referencia' className="form-control" value={element.pesoKG} onChange={e => changePesoMercancias(e, element)} required />
                                                                                    </div>




                                                                                    <div className="form-group col-1">
                                                                                        {(index === 0) && <div><label>Eliminar</label><br /></div>}
                                                                                        <IconButton size="small" className="btn-remove-factura" onClick={() => removeMercancia(element)} aria-label="borrar">
                                                                                            <CancelIcon />
                                                                                        </IconButton>
                                                                                    </div>

                                                                                </div>

                                                                            } else
                                                                                return <div><div className="row" key={element.id}>
                                                                                    <div className="form-group col-1" style={{ justifyItems: 'right', display: 'grid', flex: "0 0 70px" }}>
                                                                                        {index === 0 && <label>Buscar</label>}
                                                                                        <IconButton onClick={() => setObjMercancia({ ...element, index })} variant="contained" size="small" color="primary" aria-label="add">
                                                                                            <SearchIcon />
                                                                                        </IconButton>
                                                                                    </div>
                                                                                    <div className="form-group col-2">
                                                                                        {index === 0 && <label>Mercancia</label>}
                                                                                        <input disabled type="text" name='referencia' className="form-control" value={element.descripcion} onChange={e => console.log(e.target.value)} required />
                                                                                    </div>
                                                                                    <div className="form-group col-2">
                                                                                        {index === 0 && <label>Clave</label>}
                                                                                        <input disabled type="text" name='referencia' className="form-control" value={element.csat} onChange={e => console.log(e.target.value)} required />
                                                                                    </div>

                                                                                    <div className="form-group col-2" style={{ flex: "0 0 15%", maxWidth: '15%' }}>
                                                                                        {index === 0 && <label>Unidad</label>}
                                                                                        {
                                                                                            element.isxml ? <input disabled type="text" name='unidad' className="form-control" value={element.unidad} onChange={e => console.log(e.target.value)} required />
                                                                                                : <select className={"form-control "} name='tipolc' onChange={(e) => changeUnidadMercancias(e, element)} value={element.unidad} required >
                                                                                                    {
                                                                                                        CAT_UNIDADES.map((item, index) => {
                                                                                                            return <option key={index} value={item.descripcion}>{item.descripcion}</option>
                                                                                                        })
                                                                                                    }
                                                                                                </select>
                                                                                        }

                                                                                    </div>
                                                                                    <div className="form-group col-1" style={{ flex: "0 0 9.8%", maxWidth: '9.8%' }}>
                                                                                        {index === 0 && <label>Cantidad</label>}
                                                                                        <input type="number" name='referencia' className="form-control" value={element.cantidad} onChange={e => changeCantidadMercancias(e, element)} required />
                                                                                    </div>
                                                                                    <div className="form-group col-1" style={{ flex: "0 0 9.8%", maxWidth: '9.8%' }}>
                                                                                        {index === 0 && <label>Peso Kg.</label>}
                                                                                        <input type="number" name='referencia' className="form-control" value={element.pesoKG} onChange={e => changePesoMercancias(e, element)} required />
                                                                                    </div>

                                                                                    <div className="form-group col-1">
                                                                                        {(index === 0) && <div><label>Eliminar</label><br /></div>}
                                                                                        <IconButton size="small" className="btn-remove-factura" onClick={() => removeMercancia(element)} aria-label="borrar">
                                                                                            <CancelIcon />
                                                                                        </IconButton>
                                                                                    </div>
                                                                                </div>
                                                                                    <div className="row">
                                                                                        <div className="form-group col-1" style={{ justifyItems: 'right', display: 'grid', flex: "0 0 75px" }}></div>
                                                                                        {element.peligroso && <div className="form-group col-6">
                                                                                            <label>Material peligrosos</label>
                                                                                            <Autocomplete
                                                                                                id="combo-box-demo0c"
                                                                                                options={CAT_PELIGROSOS}
                                                                                                getOptionLabel={(option) => option.descripcion}
                                                                                                getOptionSelected={(option, value) => option.clave === value}
                                                                                                className={classes.inputbig}
                                                                                                value={CAT_PELIGROSOS.find(e => e.clave === element.c_MaterialPeligroso)}
                                                                                                style={{ height: '30px' }}
                                                                                                onChange={(e, value) => changeCMaterialPeligroso(value, element)}
                                                                                                renderInput={(params) => <TextField className="reducedselect" onChange={e => console.log(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                                            />
                                                                                        </div>}
                                                                                        {element.peligroso && <div className="form-group col-4">
                                                                                            <label>Embalaje</label>
                                                                                            <Autocomplete
                                                                                                id="combo-box-demo0c"
                                                                                                options={CAT_EMBALAJES}
                                                                                                getOptionLabel={(option) => option.descripcion}
                                                                                                getOptionSelected={(option, value) => option.clave === value}
                                                                                                className={classes.inputbig}
                                                                                                value={CAT_EMBALAJES.find(e => e.clave === element.c_TipoEmbalaje)}
                                                                                                style={{ height: '30px' }}
                                                                                                onChange={(e, value) => changeCTipoEmbalaje(value, element)}
                                                                                                renderInput={(params) => <TextField className="reducedselect" onChange={e => console.log(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                                            />

                                                                                        </div>}



                                                                                    </div>
                                                                                    <div className="row">
                                                                                        {isInternational && <div className="form-group col-1" style={{ justifyItems: 'right', display: 'grid', flex: "0 0 75px" }}>

                                                                                            <IconButton onClick={() => setObjFraccionArancelaria({ ...element, index })} variant="contained" size="small" color="primary" aria-label="add">
                                                                                                <SearchIcon />
                                                                                            </IconButton>
                                                                                        </div>}
                                                                                        {isInternational && <div className="form-group col-2">
                                                                                            <label>Fracción Arancelaria</label>
                                                                                            <input disabled placeholder="Fracc.Arancelaria" type="text" name='referencia' className="form-control" value={element.fraccion} onChange={e => console.log(e)} required />
                                                                                        </div>}
                                                                                        {isInternational && <div className="form-group col-2">
                                                                                            <label>Folio Fiscal</label>
                                                                                            <input type="text" placeholder="Folio Fiscal" name='referencia' className="form-control" value={element.uuidcomercioext} onChange={e => changeUuidExteriorMercancias(e, element)} required />
                                                                                        </div>}
                                                                                        {
                                                                                            paradas.length > 0 && <div className="form-group col-2">
                                                                                                <label>Destino</label>
                                                                                                <select className={"form-control "} name='tipolc' onChange={(e) => changeDesembarqueMercancias(e, element)} value={element.destino} required >
                                                                                                    {
                                                                                                        desembarques.map((item, index) => {
                                                                                                            return <option key={index} value={item.id}>{item.nombre}</option>
                                                                                                        })
                                                                                                    }
                                                                                                </select>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <hr />
                                                                                </div>
                                                                        })
                                                                    }
                                                                </Grid>
                                                                <br />
                                                            </Paper>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </Grid>}

                                            <br />
                                            {isViajeCargado && <Grid container className={classes.root} spacing={0}>
                                                <Grid item xs={12}>
                                                    <Grid container className={classes.paperscontainer} justify="center" spacing={2}>
                                                        <Grid className={classes.paperrootfull} key={1} item>
                                                            <Paper className={classes.paper} elevation={3} style={{ backgroundColor: '#f0f8ff' }}>
                                                                <Grid item className={classes.title} xs={12}>
                                                                    <p style={{ fontWeight: 'bold' }}>Lineas de captura</p>
                                                                    <button type="button" style={{ marginRight: '10px' }} className="btn btn-outline-dark btn-sm" onClick={() => addLC()}><i className="fa fa-plus-circle iconfa"></i> &nbsp;Agregar</button>
                                                                </Grid>

                                                                <Grid item xs={12} style={{ marginTop: '40px', padding: '5px' }}>
                                                                    {
                                                                        lineas_captura.map((element, index) => {
                                                                            return <div className="row" key={element.temid}>
                                                                                <div className="form-group col-2">
                                                                                    {(index === 0 || element.tipo.toUpperCase() === "COMBUSTIBLE") && <label>Descripción</label>}
                                                                                    {
                                                                                        element.tipo.toUpperCase() === "COMBUSTIBLE" ?
                                                                                            <input type="text" placeholder="Factura" name='referencia' className="form-control" value={element.descripcion} onChange={e => changeDescripcionLC(e, element)} required />
                                                                                            : <input type="text" name='referencia' className="form-control" value={element.descripcion} onChange={e => changeDescripcionLC(e, element)} required />

                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    element.tipo.toUpperCase() === "COMBUSTIBLE" &&
                                                                                    <div className="form-group col-1">
                                                                                        {(index === 0 || element.tipo.toUpperCase() === "COMBUSTIBLE") && <label>Litros</label>}
                                                                                        <input type="number" placeholder="Litros" name='referencia' className="form-control" value={element.litros} onChange={e => changelitrosLC(e, element)} required />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    element.tipo.toUpperCase() === "COMBUSTIBLE" &&
                                                                                    <div className="form-group col-1">
                                                                                        {(index === 0 || element.tipo.toUpperCase() === "COMBUSTIBLE") && <label>Costo x litro</label>}
                                                                                        <CurrencyInput className="form-control" value={element.costolitro} onChangeEvent={(event, maskedvalue, floatvalue) => handleChangeCurrencyCostoLitroLC(event, maskedvalue, floatvalue, element)} prefix="$" />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    element.tipo.toUpperCase() === "COMBUSTIBLE" &&
                                                                                    <div className="form-group col-2">
                                                                                        {(index === 0 || element.tipo.toUpperCase() === "COMBUSTIBLE") && <label>Fecha</label>}
                                                                                        <input type="date" placeholder="Fecha" name='fecha' className="form-control" value={element.fecha} onChange={e => changefechaLC(e, element)} required />
                                                                                    </div>
                                                                                }
                                                                                <div className="form-group col-2">
                                                                                    {(index === 0 || element.tipo.toUpperCase() === "COMBUSTIBLE") && <label>Tipo</label>}
                                                                                    <select className={"form-control "} name='tipolc' onChange={(e) => changeTipoLC(e, element)} value={element.tipo} required >
                                                                                        {
                                                                                            CATALOGO_TIPOS_LC.map((item, index) => {
                                                                                                return <option key={index} value={item}>{item}</option>
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                                <div className="form-group col-1">
                                                                                    {(index === 0 || element.tipo.toUpperCase() === "COMBUSTIBLE") && <label>Monto</label>}
                                                                                    <CurrencyInput className="form-control" value={element.monto} onChangeEvent={(event, maskedvalue, floatvalue) => handleChangeCurrencyLC(event, maskedvalue, floatvalue, element)} prefix="$" />
                                                                                </div>
                                                                                <div className="form-group col-1" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                                    {(index === 0 || element.tipo.toUpperCase() === "COMBUSTIBLE") && <div><label>Tipo de cambio</label><br /></div>}
                                                                                    <label> <Typography component="div">
                                                                                        <Grid component="label" container alignItems="center" spacing={1}>
                                                                                            <Grid item>&nbsp;&nbsp;$dll</Grid>
                                                                                            <Grid item>
                                                                                                <AntSwitch checked={currencyMX} disabled name="checkedC" />
                                                                                            </Grid>
                                                                                            <Grid item>$mx</Grid>
                                                                                        </Grid>
                                                                                    </Typography></label>
                                                                                </div>
                                                                                <div className="form-group col-1">
                                                                                    {(index === 0 || element.tipo.toUpperCase() === "COMBUSTIBLE") && <div><label>Eliminar</label><br /></div>}
                                                                                    <IconButton size="small" className="btn-remove-factura" onClick={() => removeLC(element)} aria-label="borrar">
                                                                                        <CancelIcon />
                                                                                    </IconButton>
                                                                                </div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        size="small"
                                                                        className={classes.button}
                                                                        onClick={() => presave()}
                                                                        startIcon={<SaveIcon />}
                                                                    >
                                                                        Guardar
                                                                    </Button>
                                                                </Grid>
                                                                <br />
                                                            </Paper>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            }

                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <Rentabilidad vajeAsOBJ={vajeAsOBJ} />
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            <Combustible iddeviaje={id || "000"}></Combustible>
                                        </TabPanel>
                                    </div>
                                </div>


                            </LoadingOverlay>
                        </div>
                    </div>
                    {/**MODAL HERE */}
                    <ModalCartaPorteConfirm
                        findRecord={(record) => { console.log("") }}
                        currenteditrecrod={vajeAsOBJ}
                        currentDate={new Date()}
                        isEdit={false}
                        isOpen={isOpenCPDialog}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={null}
                        isAdmin
                        notify={() => console.log("notify")}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setIsOpenCPDialog(false)} />
                    <ModalFacturar
                        findRecord={(record) => { console.log("") }}
                        currenteditrecrod={vajeAsOBJ}
                        currentDate={new Date()}
                        isEdit={false}
                        isOpen={isOpenCCPDialog}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={null}
                        isAdmin
                        notify={() => console.log("notify")}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setIsOpenCCPDialog(false)} />
                    <Horarios
                        findRecord={e => console.log(e)}
                        currenteditrecrod={id}
                        currentDate={new Date()}
                        isEdit={false}
                        isOpen={isHorarios}
                        uid={uid}
                        adminCurrentUser={null}
                        isAdmin={false}
                        notify={(solicitante, obj) => { }}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => {
                            setIsHorarios(false);
                        }} />
                    <ModalCreateActivo
                        findRecord={e => console.log(e)}
                        currenteditrecrod={null}
                        currentDate={new Date()}
                        isEdit={false}
                        isOpen={isOpenCreateActivo}
                        uid={uid}
                        isCreateActivoIntermodal={isCreateActivoIntermodal}
                        adminCurrentUser={null}
                        isAdmin={false}
                        notify={(obj) => activeAdded(obj)}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => {
                            setIsOpenCreateActivo(false)
                            setIsCreateActivoIntermodal(null)
                        }} />

                    <ModalCreateCliente
                        findRecord={e => console.log(e)}
                        currenteditrecrod={null}
                        currentDate={new Date()}
                        isEdit={false}
                        isOpen={isOpenCreateCliente}
                        uid={uid}
                        adminCurrentUser={null}
                        isAdmin={false}
                        notify={(obj) => clienteAdded(obj)}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setIsOpenCreateCliente(false)} />

                    <ModalCreateUbicacion
                        findRecord={e => console.log(e)}
                        currenteditrecrod={null}
                        currentDate={new Date()}
                        isEdit={false}
                        isOpen={isOpenCreateUbicacion}
                        uid={uid}
                        adminCurrentUser={null}
                        isAdmin={false}
                        notify={(obj) => ubicacionAdded(obj)}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setIsOpenCreateUbicacion(false)} />

                    <ModalMercancias
                        findRecord={e => console.log(e)}
                        currenteditrecrod={objMercancia}
                        currentDate={new Date()}
                        isEdit={false}
                        isOpen={isSearchModal}
                        uid={uid}
                        adminCurrentUser={null}
                        isAdmin={false}
                        notify={(solicitante, obj) => mercanciaEncontrada(solicitante, obj)}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => {
                            setObjMercancia(null);
                            setIsSeachModal(false);
                        }} />
                    <ModalFraccionesArancelarias
                        findRecord={e => console.log(e)}
                        currenteditrecrod={objFraccionArancelaria}
                        currentDate={new Date()}
                        isEdit={false}
                        isOpen={isSearchFraccionModal}
                        uid={uid}
                        adminCurrentUser={null}
                        isAdmin={false}
                        notify={(solicitante, obj) => fraccionEncontrada(solicitante, obj)}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => {
                            setObjFraccionArancelaria(null);
                            setIsSeachFraccionModal(false);
                        }} />

                    <ModalParadas
                        localidades={localidades}
                        isOpen={isOpenParadas}
                        paradas={paradas}
                        notify={(p) => setParadas(p)}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => {
                            setIsOpenParadas(false);
                        }} />



                </div>
            }
        </Fragment>)
}
export default CreateViajeContent;