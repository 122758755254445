import React, { useState } from 'react';

import FacturasContent from './content'; 

import HeaderNew from '../utils/HeaderNew';
import SideBarNew from '../utils/NewSideBar';

const Facturas = props => {
    const [isToggle, setIsToggle] = useState(false);

    return (
        <div>
            <HeaderNew isToggle={isToggle} setIsToggle={setIsToggle} />
            <main>
                <SideBarNew isToggle={isToggle} />

                <div class="wrapper">
                    <div style={{ minWidth: '86vw',width:'auto', minHeight: '95vh',height:"auto" }}>
                        <FacturasContent />
                    </div>

                </div>
            </main>
        </div>
    )

}

export default Facturas;