import { firebase } from '../configs/firebase';
export const getActivos = (uid) => {
    var db = firebase.firestore();
    return db.collection("activos_user").where("uid", "==", uid).get();
}

export const addActivo = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('activos_user').add(dataaux);
}

export const updateActivo= (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date()};
    var db = firebase.firestore();
    return db.collection('activos_user').doc(id).update(dataaux);
}

export const deleteActivo = (id) => {
    var db = firebase.firestore();
    return db.collection('activos_user').doc(id).update({uid:"deleted"});
}