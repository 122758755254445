import React, { Component, Fragment } from 'react';
import {  doLogout } from './../../session/session_controller';
import Footer from '../utils/Footer';
import Header from '../utils/Header';
import ProSideBar from '../utils/ProSideBar';
import Content from './content';


export default class index extends Component {

    constructor() {
        super();
        let localStorageProperty = JSON.parse(localStorage.getItem('navleft_marketpoint'));
        if (localStorageProperty === null) {
            localStorage.setItem('navleft_marketpoint', true);
            localStorageProperty = true;
        }

        this.state = {
            isNotFolded: localStorageProperty,
        }
    }

    foldBar=()=>{
        let currentstatebar=this.state.isNotFolded;
        currentstatebar=!currentstatebar;
        this.setState({isNotFolded:currentstatebar},()=>{
            localStorage.setItem('navleft_marketpoint', currentstatebar);
        });
    }


    handleLogOut = () => {
        doLogout();
    }   


    
    render() {
        return (
            <Fragment>
                {/* //////////// LOADING
                <div className="loading"></div>
                {/* //////////// END LOADING */ }
                <div className="air__layout air__layout--hasSider">

                <ProSideBar isNotFolded={this.state.isNotFolded}></ProSideBar>
                    {/* //////////// CONTENT RIGHT */}
                    <div className="air__layout">
                        {/* /////// HEADER */}
                        <Header foldBar={this.foldBar}></Header>
                        {/* /////// END HEADER */}
                        {/* /////// MAIN CONTENT */}
                       <Content></Content>
                        {/* /////// END MAIN CONTENT */}
                        {/* /////// FOOTER */}
                        <Footer>
                           
                        </Footer>
                        {/* /////// END FOOTER */}
                    </div>
                    {/* //////////// END CONTENT RIGHT */}
                </div>
            </Fragment>
        )
    }
}