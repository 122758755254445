import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import InputMask from 'react-input-mask';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',

        height: '200px',
        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
    },
}));
const ModalCreateEdit = (props) => {
    //constantes
    const CATALOGOS_TIPO_PAGO = ['Manual', 'Por Porcentaje', 'Por Kilómetro', 'Otro'];
    const CATALOGOS_TIPO_LICENCIA = ['A', 'B', 'C', 'D', 'E', 'F'];
    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);

    //estados propios del formulario
    const [codigoSearch, setCodigoSearch] = useState(true);
    const [busqueda, setBusqueda] = useState("");
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);


    const initValues = () => {
        setCodigoSearch(true);
    }

    useEffect(() => {
        setBusqueda("");
    }, [codigoSearch]);

    useEffect(() => {
        setUid(props.uid);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);
        setProductos([]);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit) {
            initValues();
        }

    }, [recordedit]);


    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();
        //setIsOpen(false);

    }
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const classes = useStyles();

    const clicked = (obj) => {
        props.notify(recordedit, obj);
    }

    const buscar = async () => {

        let tipobusqueda = codigoSearch ? "clave" : "descripcion";

        setIsLoading(true);
        await axios.get(`https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/arancelarias?${tipobusqueda}=${busqueda}`).then(resp => {
            console.log("respuesta busqueda", resp.data);
            if (resp.data && resp.data.length > 0) {
                setProductos(resp.data);
            }
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
            console.log("error", err);
        });
    }

    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Buscando, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Añadir Fraccion Arancelaria" : `Editar Fraccion Arancelaria `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <div className="row">
                        <div className="form-group col-9">
                            <label> <Typography component="div">
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item style={{ fontSize: '14px' }}>Descripcion</Grid>
                                    <Grid item>
                                        <AntSwitch checked={codigoSearch} onChange={() => setCodigoSearch(!codigoSearch)} name="checkedC" />
                                    </Grid>
                                    <Grid item style={{ fontSize: '14px' }}>Codigo</Grid>
                                </Grid>
                            </Typography></label>
                            {
                                codigoSearch ? <InputMask className={"form-control "} mask="99999999" maskChar="_" value={busqueda} onChange={e => setBusqueda(e.target.value)} />
                                    : <input type="text" name='cliente' className="form-control" value={busqueda} onChange={e => setBusqueda(e.target.value)} required />
                            }

                        </div>
                        <div className="form-group col-3">
                            <div className="btn btn-primary" onClick={() => buscar()}> Buscar</div>

                        </div>
                    </div>
                    <div className={classes.root}>

                        <List component="nav" aria-label="primary mailbox folders">
                            {
                                productos.map(element => {
                                    return <ListItem style={{ backgroundColor: "#ddf3de" }} button>
                                        <ListItemText style={{ color: "#000" }} onClick={() => clicked(element)} primary={`${element.codigo} - ${element.descripcion}`} />
                                    </ListItem>
                                })
                            }


                        </List>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalCreateEdit;