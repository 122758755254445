import React, { useEffect, useState } from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { getNominaById } from '../../../controllers/nomina';
import { getUserByUid } from '../../../controllers/usuarios';
import moment from 'moment';
import { getOperadorById } from '../../../controllers/operadores';
//var QRCode = require('qrcode.react');
// Create styles
const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        marginLeft: 15,
        paddingRight: 15,
        // backgroundColor: '#E4E4E4'
    },
    sectionHeader: {
        width: '98%',
        flexDirection: 'row',
    },
    sectionLogo: {
        margin: 0,
        padding: 0,
        width: '20%',
        //border: '1px solid black',
        flexGrow: 1
    },
    sectionUserInfo: {
        width: '50%',
        margin: 10,
        padding: 10,
        flexGrow: 2
    },
    sectionConceptos: {
        width: '98%',
        margin: 2,
        padding: 5,
    },
    sectionRowConcepto: {
        width: '98%',
        padding: "3px",
        borderBottom: '1px solid black',
        flexDirection: 'row',
    },
    sectionDescripcionConcepto: {
        width: '80%',
        fontSize: '7px',
    },
    sectionMontoConcepto: {
        width: '18%',
        fontSize: '7px',
    },

});
const Reporte = props => {

    const data = props.objdata;
    let fechaImpresion = moment(new Date()).format('DD/MM/YYYY HH:mm');
    let fechaInicio = moment(data.startDate.toDate()).format('DD/MM/YYYY');
    let fechaFin = moment(data.endDate.toDate()).format('DD/MM/YYYY');
    let totalConceptos = parseFloat(data.totalConceptos).toFixed(2) || 0.0;
    let totalCargos = parseFloat(data.totalCargos).toFixed(2) || 0.0;
    let totalFijos = parseFloat(data.totalfijos).toFixed(2) || 0.0;


    let totalConceptosstring = parseFloat(data.totalConceptos).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    let totalcargosstring = parseFloat(data.totalCargos).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    let totalFijosstring = parseFloat(totalFijos).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

    let adicionales = [];
    let totalesadicionales = 0.0;
    let totales = (totalConceptos - totalCargos);

    if (!isNaN(totalFijos)) {
        //totales += parseFloat(totalFijos);
    }


    if (data.adicionales) {
        data.adicionales.forEach(element => {
            let montoasFloat = 0.0;
            if (typeof element.monto === 'string' || element.monto instanceof String) {
                montoasFloat = parseFloat(element.monto);
            } else {
                montoasFloat = element.monto;
            }
            adicionales.push(element);
            totalesadicionales += montoasFloat;
            totales += montoasFloat;
        });
    }
    /* data.conceptos.forEach(element => {
         if (element.viajeobj && element.viajeobj.lineas_captura) {
             element.viajeobj.lineas_captura.forEach(linea => {
                 if (linea.tipo === "SUELDOS DE OPERADOR") {
                     totalesadicionales += linea.montoFloat;
                     totales+=linea.montoFloat;
                     adicionales.push({ descripcion: linea.descripcion + `   ${element.viajeobj.general.nviaje}   ${element.viajeobj.general.ncarta}`, monto: linea.montoFloat });
                 }
             });
         }
     });*/

    let totalesadicionalestring = totalesadicionales.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    let totalesstring = totales.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    useEffect(() => {
        // console.log("props", props); https://i.ibb.co/xGTh7HL/LOGO-Transportes-500x362.png
    }, []);

    return <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.sectionHeader}>
                <View style={styles.sectionLogo}>

                    <Image style={{ width: '100px', height: "80px" }} src={data.logourl || "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png"}></Image>
                </View>
                <View style={styles.sectionUserInfo}>
                    <Text style={{ fontSize: '12px', marginTop: "5px" }}>Bitácora de Operador: {data.operadorName ? data.operadorName.toUpperCase() : ""}</Text>
                    <Text style={{ fontSize: '12px', marginTop: "5px" }}>De: {fechaInicio} A: {fechaFin}</Text>
                    <Text style={{ fontSize: '10px', marginTop: "5px" }}>Fecha de impresión: {fechaImpresion}</Text>
                     <Text style={{ fontSize: '10px', marginTop: "5px" }}>No. Liquidación: {data.numeroLiquidacion||""}</Text>
                      {data.cuenta && data.cuenta.length>0 && <Text style={{ fontSize: '10px', marginTop: "5px" }}>Cuenta: {data.cuenta||""}</Text>}
                </View>


            </View>
            <View style={styles.sectionConceptos}>
                <Text style={{ fontSize: '11px', marginTop: "1px" }}>VIAJES</Text>
                {data.conceptos.map(element => {
                    let montostring = "";
                    if (typeof element.monto === 'string' || element.monto instanceof String) {
                        montostring = parseFloat(element.monto).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                    } else {
                        montostring = element.monto.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                    }

                    return <View style={styles.sectionRowConcepto}>
                        <Text style={styles.sectionDescripcionConcepto}>{element.descripcion}</Text>
                        <Text style={styles.sectionMontoConcepto}> ${montostring}</Text>
                    </View>
                })}

            </View>
            
            <View style={styles.sectionConceptos}>
                <Text style={{ fontSize: '11px', marginTop: "1px" }}>GASTOS COMPROBADOS</Text>
                {adicionales.map(element => {
                    let montostring = "";
                    if (typeof element.monto === 'string' || element.monto instanceof String) {
                        montostring = parseFloat(element.monto).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                    } else {
                        montostring = element.monto.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                    }

                    return <View style={styles.sectionRowConcepto}>
                        <Text style={styles.sectionDescripcionConcepto}>{element.descripcion}</Text>
                        <Text style={styles.sectionMontoConcepto}> ${montostring}</Text>
                    </View>
                })}


            </View>
            <View style={styles.sectionConceptos}>
                <Text style={{ fontSize: '11px', marginTop: "1px" }}>DEDUCCIONES / ANTICIPOS DE GASTOS</Text>
                {data.cargos.map(element => {
                    let montostring = element.monto.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                    return <View style={styles.sectionRowConcepto}>
                        <Text style={styles.sectionDescripcionConcepto}>{element.descripcion}</Text>
                        <Text style={styles.sectionMontoConcepto}> ${montostring}</Text>
                    </View>
                })}

            </View>
            <View style={styles.sectionConceptos}>
                <Text style={{ fontSize: '11px', marginTop: "1px" }}>Totales</Text>
                <View style={styles.sectionRowConcepto}>
                    <Text style={styles.sectionDescripcionConcepto}>Viajes:</Text>
                    <Text style={styles.sectionMontoConcepto}> ${totalConceptosstring}</Text>
                </View>
                {/*<View style={styles.sectionRowConcepto}>
                    <Text style={styles.sectionDescripcionConcepto}>Fijos:</Text>
                    <Text style={styles.sectionMontoConcepto}> ${isNaN(totalFijosstring) ? `0.0` : totalFijosstring}</Text>
            </View>*/}

                {
                    adicionales.length > 0 && <View style={styles.sectionRowConcepto}>
                        <Text style={styles.sectionDescripcionConcepto}>Gastos Comprobados:</Text>
                        <Text style={styles.sectionMontoConcepto}> ${totalesadicionalestring}</Text>
                    </View>
                }

                <View style={styles.sectionRowConcepto}>
                    <Text style={styles.sectionDescripcionConcepto}>Deducciones/Anticipos:</Text>
                    <Text style={styles.sectionMontoConcepto}> -${totalcargosstring}</Text>
                </View>
                <View style={styles.sectionRowConcepto}>
                    <Text style={styles.sectionDescripcionConcepto}>Total a pagar:</Text>
                    <Text style={styles.sectionMontoConcepto}> ${totalesstring}</Text>
                </View>
            </View>
            <View style={styles.sectionConceptos}>
                <Text style={{ fontSize: '11px', marginTop: "1px" }}></Text>
            
            </View>
            <View style={styles.sectionConceptos}>
                <Text style={{ fontSize: '11px', marginTop: "1px" }}>{data.operadorName ? data.operadorName.toUpperCase() : ""}</Text>
                

            </View>
        </Page>
    </Document>;
};

const PDFView = props => {
    const params = useParams();
    const [id, setId] = useState(params.id || null);
    const [objData, setObjData] = useState(null);


    const getData = async () => {
        let r = null;
        if (id) {
            await getNominaById(id).then(document => {
                r = document.data();
            }).catch(err => { console.log(err) });

            if(r.operador && r.operador!==""){
                await getOperadorById(r.operador).then(document=>{
                    let operadorData=document.data();
                    if(operadorData.cuenta){
                        r.cuenta=operadorData.cuenta;
                    }
                })
            }

            if (r.uid) {
                let userObj = null;
                await getUserByUid(r.uid).then(snapshot => {
                    if (snapshot.docs.length > 0) {
                        userObj = snapshot.docs[0].data();
                    }
                }).catch(err => { console.log(err) });
                if (userObj && userObj.logourl) {
                    r.logourl = userObj.logourl;
                }else{
                    console.log("no userobj");
                }
            }

            let sbase=r.salariobaseFloat || 0.00;
            let scomp=r.compensacionFloat || 0.00;
            
            let adicionales = [ ...r.adicionales];
            r.adicionales = adicionales;
            // data.salariobase
            setObjData(r);
        }

    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (objData) {
            // console.log(objData);
        }
    }, [objData])

    return (
        <div>
            {
                objData &&
                <PDFViewer style={{ width: '100vw', height: '99vh' }}>
                    <Reporte objdata={objData} algo="hola mundo" />
                </PDFViewer>
            }
        </div>);

}

export default PDFView;