import React, { useState, useEffect, Fragment } from 'react';
import { firebase } from '../../../configs/firebase';
import { getUserByUid } from '../../../controllers/usuarios';
import { getAllUsuarios } from '../../../controllers/usuarios';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import DatePicker from 'react-datepicker';
import { addDays } from "date-fns";
import CurrencyInput from 'react-currency-input';
import '@brainhubeu/react-carousel/lib/style.css';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import { getUidMaster } from '../../../session/session_controller';
import { getAllViajesTimbradosByUserDateFilter, getAllViajesByUserDateFilter } from '../../../controllers/viajes';
import { getAllIngresosByUserDateFilter } from '../../../controllers/ingresos';
import { getAllCostosByUserDateFilter, getAllCostosCombustibleByUserDateFilter, getAllCostosRecurrentesByUserDateFilter } from '../../../controllers/costos';
import { getOperadores } from '../../../controllers/operadores';
import { getInvoicesDateFilter, getCfdiSolos, getCfdiSolosByCliente } from '../../../controllers/innvoices';
import { addResumen, addResumenRutas, getResumenUser } from '../../../controllers/resumen';
import Download from './ExcelExport';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import xml2js from 'xml2js';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
    },
    paperrootfull: {
        width: '100%',
        margin: '5px',
        padding: '30px',
        display: 'flex'
    },

    paperroot: {
        width: '33%',
        display: 'flex'
    },
    paper: {
        minHeight: 80,
        height: 'auto',
        width: '100%',
    },
    paperscontainer: {
        // width: '100%'
        //overflow: 'hidden'
        flex: 1
    },
    title: {
        height: 30,
        backgroundColor: '#dcdcdc',
        paddingLeft: 10,
        paddingTop: 5,
    },
    label: {
        fontSize: '12px',
        paddingLeft: 3,
        paddingTop: 7,
        width: '38%',
    },

    input: {
        display: 'inline-block',
        float: 'right',
        width: '60%',
        height: 30,
        marginTop: 4,
        marginRight: 2
    },
    inputsmall: {
        display: 'inline-block',
        float: 'right',
        width: '50%',
        height: 30,
        marginTop: 4,
        marginRight: 2
    },
    inputwithextra: {
        display: 'inline-block',
        float: 'right',
        width: '30%',
        height: 30,
        marginRight: 2
    },
    inputdate: {
        width: '99%',
        marginTop: 4,
        marginLeft: 0,
        marginRight: 2,
        height: '30px',
    }
}));
const CostoXKmContent = (props) => {
    const classes = useStyles();
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [firstDay, setFirstDay] = useState(null);
    const [lastDay, setLastDay] = useState(null);
    const [user, setUser] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isGerencia, setIsGerencia] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());

    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState("Consultando datos, espere ...");

    const [resumenLineas, setResumenLienas] = useState([]);


    const [progreso, setProgreso] = useState(0);

    const [dataRutas, setDataRutas] = useState([]);

    const [dataExcel, setDataExcel] = useState(null);

    const [fechaRangoStart, setFechaRangoStart] = useState(new Date());
    const [fechaRangoEnd, setFechaRangoEnd] = useState(new Date());

    const requiereControlPropioSUeldosOperador = ['RZHc17ViPqQTIJ7VShGfbBRwXBw1'];
    const requiereConsultaPorTiempoCustom = ['31RxHqtBOmbgOWWeMCKnb1DuEHc2'];

    /*const [costosSegmentosNegocio, setCostosSegmentosNegocio] = useState([
        { ukm: '-$1.50', ut: '-$115,839.09', cf: '$19.92', cv: '$20.72', so: '$9.43', nombre: 'Tracto Cruce', kms: 77380, ingresosFloat: 3758447.25, ingresos: '$3,758,447.25', costosfijoFloat: 1541265.55, costoFijo: '$1,541,265.55', costovariableFloat: 1603225.69, costovariable: '$1,603,225.69', sueldooperadorFloat: 729791.10, sueldooperador: '$729,791.10', costoxkm: '$63.20' },
        { ukm: '-$9.88', ut: '-$275,220.46', cf: '$15.09', cv: '$17.72', so: '$14.22', nombre: 'Tracto Local', kms: 27860, ingresosFloat: 3758447.25, ingresos: '$1,034,902.00', costosfijoFloat: 420345.15, costoFijo: '$420,345.15', costovariableFloat: 493651.93, costovariable: '$493,651.93', sueldooperadorFloat: 396125.38, sueldooperador: '$396,125.38', costoxkm: '$63.20' },
        { ukm: '$12.27', ut: '$238,877.20', cf: '$31.65', cv: '$16.06', so: '$6.29', nombre: 'Rabón', kms: 19479, ingresosFloat: 3758447.25, ingresos: '$1,488,150.45', costosfijoFloat: 616506.22, costoFijo: '$616,506.22', costovariableFloat: 312752.98, costovariable: '$312,752.98', sueldooperadorFloat: 320013.97, sueldooperador: '$320,013.97', costoxkm: '$40.23' },
        // { ukm: '-$17.43', ut: '-$33,749.38', cf: '$14.35', cv: '$10.15', so: '$16.43', nombre: 'Panel', kms: 15618, ingresosFloat: 3758447.25, ingresos: '$514,748.70', costosfijoFloat: 224,184.08, costoFijo: '$224,184.08', costovariableFloat: 158510.33, costovariable: '$158,510.33', sueldooperadorFloat: 98304.91, sueldooperador: '$98,304.91', costoxkm: '$68.90' },
    ]);*/

    const [costosSegmentosNegocio, setCostosSegmentosNegocio] = useState([]);
    const [denys, setDenys] = useState([]);
    const [allows, setAllows] = useState([]);
    const [dolarprice, setDolarPrice] = useState(19.50);
    const [impuesto, setImpuestos] = useState(1);
    const [busquedatipo, setBusquedatipo] = useState("mes");

    const validateAdmin = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (user.type && user.type === "administrador") {
                    isadmin = true;
                    setIsGerencia(true);
                }
            });
            if (isadmin) {
                setAdminCurrentUser(uid);
                setIsAdmin(true);
            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                setIsAdmin(false);
                setUserlist([]);
                // this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }

    const onChangeDaterangeTwo = (fechas) => {
        if (!fechas || !fechas[0] || !fechas[1]) {
            return;
        }

        let start = new Date(fechas[0].getTime());
        let end = new Date(fechas[1].getTime());

        if (start)
            start.setHours(0, 0, 0);
        if (end)
            end.setHours(23, 59, 59);
        setFechaRangoStart(start);
        setFechaRangoEnd(end);

        if (start && end) {
            if (start.getTime() <= end.getTime()) {

                setTimeout(() => {
                    console.log("buscar por rango");
                    // BuscarPorRango(start, end);

                    if (fecha && uid) {
                        let fFix = `${moment(start).format("dd-MM-YYYY")}:${moment(end).format("dd-MM-YYYY")}`

                        consultaMes(fFix);
                    }
                }, 500);
            }
        }

    };
    const consultaMes = async (mes) => {
        if (uid) {
            let objresponse = null;
            await getResumenUser(uid, mes).then(resp => {
                if (resp.docs.length === 0) {
                    setDataExcel(null);
                }
                resp.forEach(doc => {
                    objresponse = doc.data();
                    objresponse.id = doc.id;
                });

                let dataexcel = [];
                if (objresponse) {
                    console.log("datos a excel", objresponse);
                    objresponse.resumenLineas.forEach(x => {
                        let dobj = {
                            segmento: x.segmento, data: [{
                                ...x,
                                fecha: objresponse.fecha,
                                ingresosfix: parseFloat(parseFloat(x.ingresos).toFixed(2)),
                                costosVariablesfix: parseFloat(parseFloat(x.costosVariables).toFixed(2)),
                                costosFijosfix: parseFloat(parseFloat(x.costosFijos).toFixed(2)),
                                sueldosfix: parseFloat(parseFloat(x.sueldos).toFixed(2)),
                                comisionfix: parseFloat(parseFloat(x.comision).toFixed(2)),
                                cfxkm: 0,
                                cvxkm: 0,
                                soxkm: 0,
                                uxkm: 0,
                                ur: 0
                            }]
                        };

                        if (dobj.data[0].kms != 0) {
                            console.log(`setting ${dobj.data[0].costosFijosfix}  /   ${dobj.data[0].kms} `);
                            dobj.data[0].cfxkm = parseFloat(dobj.data[0].costosFijosfix / dobj.data[0].kms).toFixed(2);
                            dobj.data[0].cvxkm = parseFloat(dobj.data[0].costosVariablesfix / dobj.data[0].kms).toFixed(2);
                        }
                        console.log("cfxkm", dobj.data[0].cfxkm);
                        dataexcel.push(dobj);
                    });
                    console.log("datos a excel2", dataexcel);
                    if (dataexcel.length > 0) {
                        setDataExcel(dataexcel);
                    }
                } else {
                    setDataExcel(null);
                }
            });
        }
    }

    useEffect(() => {
        if (uid) {
            if (localStorage.getItem('deny')) {
                let auxdeny = localStorage.getItem('deny').split(":");
                setDenys(auxdeny);
            }
            if (localStorage.getItem('allow')) {
                let allows = localStorage.getItem('allow').split(":");
                setAllows(allows);
            }
            getUserByUid(uid).then(snapshot => {
                if (snapshot.docs.length > 0) {
                    let userObj = snapshot.docs[0].data();
                    if (userObj.segmento_negocio) {
                        let segmentos = userObj.segmento_negocio.map(element => { return element.value });
                        let costos = [];
                        segmentos.forEach(element => {
                            costos.push({ ukm: '$0.00', ut: '$0.00', cf: '$0.00', cv: '$0.00', so: '$0.00', nombre: `${element}`, kms: 0, ingresosFloat: 0, ingresos: '$0.00', costosfijoFloat: 0, costoFijo: '$0.00', costovariableFloat: 0, costovariable: '$0.00', sueldooperadorFloat: 0, sueldooperador: '$0.00', costoxkm: '$0.00' },
                            )
                        });
                        setCostosSegmentosNegocio(costos);
                        // segmentos = userObj.segmento_negocio.map(element => { return element.value });
                    }

                }
            }).catch(err => { console.log(err) });
        }
    }, [uid])
    const actionConsultar = async () => {
        console.log("consultando");
        setIsLoading(true);


        /*  console.log("primer dia del mes",moment(fDay).format("DD/MM/YYYY"));
          console.log("primer 10 del mes",moment(diadiez).format("DD/MM/YYYY"));
          console.log("primer 20 del mes",moment(diaveinte).format("DD/MM/YYYY"));
          console.log("ultimo dia del mes",moment(lDay).format("DD/MM/YYYY"));*/

        //consultando segmetnos de negocio
        let segmentos = [];
        await getUserByUid(uid).then(snapshot => {
            if (snapshot.docs.length > 0) {
                let userObj = snapshot.docs[0].data();
                if (userObj.segmento_negocio) {
                    segmentos = userObj.segmento_negocio.map(element => { return element.value });
                }

            }
        }).catch(err => { console.log(err) });
        console.log("segmentos", segmentos);

        let resumen = [];
        segmentos.forEach(element => {
            resumen.push({ segmento: element, kms: 0, comision: 0, viajes: 0, tarifas: 0, costosFijos: 0, costosVariables: 0, sueldos: 0, ingresos: 0 });
        });


        const updateResumeValue = (index, obj) => {
            resumen[index].viajes = resumen[index].viajes + 1;

            if (obj.distTotales_km)
                resumen[index].kms = resumen[index].kms + parseFloat(obj.distTotales_km);
            if (obj.comision)
                resumen[index].comision = resumen[index].comision + parseFloat(obj.comision);
            let montosumar = obj.factura_monto;
            if (obj.moneda === "USD") {

                console.log(`convirtiendo a pesos ${montosumar} dlls = ${montosumar * parseFloat(dolarprice)}`)

                montosumar = montosumar * parseFloat(dolarprice);
            }
            resumen[index].tarifas = resumen[index].tarifas + montosumar;


            //console.log("updating", index, montosumar);
            resumen[index].ingresos = resumen[index].ingresos + montosumar;

        }


        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        console.log("last day mes", lDay);
        console.log("last day", lDay.getDate())

        let diadiez = new Date(fDay.getFullYear(), fDay.getMonth(), fDay.getDate() + 10);
        let diaveinte = new Date(fDay.getFullYear(), fDay.getMonth(), fDay.getDate() + 20);

        let diasfor = [3, 6, 9, 12, 15, 18, 21, 24, 27, 30];
        let inicioaux = 1;
        let listaIDS = [];
        let prog = 0;

        const getRfcTimbradoFromXML = async (xmldata) => {
            // console.log("data xml",xmldata);

            // let nombreCliente = "";
            let rfcEmisor = "";
            await xml2js.parseString(xmldata, async (err, result) => {
                if (err) {
                    console.log(err);
                }
                else {
                    // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                    //  let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                    //console.log("emisor xml", result['cfdi:Comprobante']['cfdi:Emisor'][0]['$']);
                    //  console.log("nombre", result['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc']);
                    rfcEmisor = result['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc'];
                    // nombreCliente = result['cfdi:Comprobante']['cfdi:Receptor'][0]['$']['Nombre'];
                    // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                }

            });
            //  console.log("rfc emite", rfcEmisor);
            return rfcEmisor;
        }
        const getClienteInfoFromXML = async (xmldata) => {
            // console.log("data xml",xmldata);

            let nombreCliente = "";
            let rfcEmisor = "";
            await xml2js.parseString(xmldata, async (err, result) => {
                if (err) {
                    console.log(err);
                }
                else {
                    // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                    //  let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                    //console.log("emisor xml", result['cfdi:Comprobante']['cfdi:Emisor'][0]['$']);
                    let rfcEmisor = result['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc']
                    nombreCliente = result['cfdi:Comprobante']['cfdi:Receptor'][0]['$']['Nombre'];
                    // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                }

            });
            return nombreCliente;
        }
        const getClienteInfoTotalFromXML = async (xmldata) => {
            // console.log("data xml",xmldata);

            let total = "";
            let moneda = "";
            await xml2js.parseString(xmldata, async (err, result) => {
                if (err) {
                    console.log(err);
                }
                else {
                    // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                    //  let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                    // console.log("primer xml", result['cfdi:Comprobante']["$"]["Total"]);
                    //SubTotal
                    total = impuesto == 1 ? result['cfdi:Comprobante']["$"]["Total"] : result['cfdi:Comprobante']["$"]["SubTotal"];
                    moneda = result['cfdi:Comprobante']["$"]["Moneda"];
                    //nombreCliente=result['cfdi:Comprobante']['cfdi:Receptor'][0]['$']['Nombre'];
                    // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                }

            });
            return { total, moneda };
        }

        const rutas = new Map();
        let sumaIsCfidi = 0;
        let sumaNoiscfdi = 0;
        let sumaCancelados = 0;
        const procesarRespuesta = async (snapshot, isCFDI) => {
            //console.log(`prosesando ${isCFDI} ${snapshot.docs.length}`)
            if (isCFDI === true) {
                sumaIsCfidi += snapshot.docs.length;
            } else {
                sumaNoiscfdi += snapshot.docs.length;
            }
            let docs = [];
            snapshot.forEach(doc => { docs.push(doc) });
            for await (let doc of docs) {
                let pendientes = 0.0;
                let o = doc.data();
                let odt = {};
                odt.id = doc.id;
                odt.key = doc.id;
                if (o.status === 'Cancelado' || o.facturaCancelada === true || o.cancelada) {
                    sumaCancelados++;
                    console.log("Cancelados", sumaCancelados);
                    continue;
                }
                if (!o.facuturado) {
                    if (!isCFDI) {
                        odt.segmentoNegocio = o.segmentoNegocio;
                        odt.factura = o.general.nviaje;
                        odt.carta_porte = o.general.ncarta;
                        odt.cancelada = o.facturaCancelada || false;
                        odt.cliente = o.general.cliente;
                        odt.clienteId = o.general.clienteId;
                        odt.referencia = o.general.referencia;
                        odt.total = o.detalles.tarifaFloat;


                        let totalf = parseFloat(odt.total);
                        odt.factura_monto = totalf;

                        // odt.moneda = o.detalles.currencyMX ? "MXN" : "USD";
                        odt.fecha = o.datos_viaje.fechaOrigen || null;
                        odt.fechaformat = o.datos_viaje.fechaOrigen ? moment(o.datos_viaje.fechaOrigen.toDate()).format("DD-MM-YYYY") : "";
                        odt.fechaformat2 = o.datos_viaje.fechaOrigen ? moment(o.datos_viaje.fechaOrigen.toDate()).format("DD/MM/YYYY") : "";
                        odt.nfactura = o.folioFactura ? `F-${o.folioFactura}` : "";
                        odt.comision = 0.00;
                        if (o.toperadorSelected && o.toperadorSelected.tarifaFloat && !isNaN(o.toperadorSelected.tarifaFloat)) {

                            odt.comision = parseFloat(o.toperadorSelected.tarifaFloat);
                            // console.log("hay comision", odt.id);
                        }



                        odt.distBase_km = o.carta_porte.distBase_km;
                        odt.distVacio_km = o.carta_porte.distVacio_km;
                        odt.distTotales_km = o.carta_porte.distTotales_km;

                        let origenciudad = "";
                        let destinociudad = "";
                        let ruteo = o.datos_viaje.ruta;
                        let ruteosplit = ruteo.split("-");
                        if (ruteosplit.length > 1) {
                            origenciudad = ruteosplit[0];
                            destinociudad = ruteosplit[1];
                        }
                        let objRuta = {
                            segmentoNegocio: odt.segmentoNegocio,
                            cliente: odt.cliente,
                            clienteId: odt.clienteId,
                            distancia: o.carta_porte.distTotales_km,
                            origen: origenciudad,
                            origenId: o.datos_viaje.origenId,
                            destino: destinociudad,
                            destinoId: o.datos_viaje.destinoId,
                            ruta: o.datos_viaje.ruta,
                            tarifaSelected: o.ttarifarioSelected,
                            count: 1
                        }
                        if (o.ttarifarioSelected && o.ttarifarioSelected.id) {
                            if (rutas.get(o.ttarifarioSelected.id)) {
                                let objAux = rutas.get(o.ttarifarioSelected.id);
                                rutas.set(o.ttarifarioSelected.id, {
                                    ...objAux,
                                    count: objAux.count + 1,
                                    distancia: objAux.distancia + objRuta.distancia || 0
                                });
                            } else {
                                rutas.set(o.ttarifarioSelected.id, objRuta);
                            }

                        }

                    }
                } else {


                    if (isCFDI) {
                        console.log("datos de cfid nomral", o);
                        odt.nfactura = `F-${o.folioFactura}`;
                        odt.carta_porte = "";
                        odt.cliente = o.cliente;
                        odt.referencia = o.referencia || "";
                        odt.fecha = o.fecha_facturado || null;
                        odt.fechaformat = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD-MM-YYYY") : "";
                        odt.fechaformat2 = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD/MM/YYYY") : "";
                        odt.total = parseFloat(o.total) || 0.00;
                        odt.factura_monto = odt.total;
                        odt.moneda = o.moneda || "";
                        odt.distBase_km = "";
                        odt.distVacio_km = "";
                        odt.distTotales_km = "";
                        odt.segmentoNegocio = o.segmentoNegocio;
                    } else {
                        odt.segmentoNegocio = o.segmentoNegocio;
                        odt.factura = o.general.nviaje;
                        odt.carta_porte = o.general.ncarta;
                        odt.cancelada = o.facturaCancelada || false;
                        odt.cliente = o.general.cliente;
                        odt.clienteId = o.general.clienteId;
                        odt.referencia = o.general.referencia;
                        odt.total = o.detalles.tarifaFloat;
                        //odt.moneda = o.detalles.currencyMX ? "MXN" : "USD";
                        odt.fecha = o.fecha_facturado || null;
                        odt.fechaformat = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD-MM-YYYY") : "";
                        odt.fechaformat2 = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD/MM/YYYY") : "";
                        odt.nfactura = o.folioFactura ? `F-${o.folioFactura}` : "";
                        odt.comision = 0.00;
                        if (o.toperadorSelected && o.toperadorSelected.tarifaFloat && !isNaN(o.toperadorSelected.tarifaFloat)) {
                            odt.comision = parseFloat(o.toperadorSelected.tarifaFloat);
                        }

                        odt.distBase_km = o.carta_porte.distBase_km;
                        odt.distVacio_km = o.carta_porte.distVacio_km;
                        odt.distTotales_km = o.carta_porte.distTotales_km;

                        let origenciudad = "";
                        let destinociudad = "";
                        let ruteo = o.datos_viaje.ruta;
                        let ruteosplit = ruteo.split("-");
                        if (ruteosplit.length > 1) {
                            origenciudad = ruteosplit[0];
                            destinociudad = ruteosplit[1];
                        }
                        let objRuta = {
                            segmentoNegocio: odt.segmentoNegocio,
                            cliente: odt.cliente,
                            clienteId: odt.clienteId,
                            distancia: o.carta_porte.distTotales_km,
                            origen: origenciudad,
                            origenId: o.datos_viaje.origenId,
                            destino: destinociudad,
                            destinoId: o.datos_viaje.destinoId,
                            ruta: o.datos_viaje.ruta,
                            tarifaSelected: o.ttarifarioSelected,
                            count: 1
                        }
                        if (o.ttarifarioSelected && o.ttarifarioSelected.id) {
                            if (rutas.get(o.ttarifarioSelected.id)) {
                                let objAux = rutas.get(o.ttarifarioSelected.id);
                                rutas.set(o.ttarifarioSelected.id, {
                                    ...objAux,
                                    count: objAux.count + 1,
                                    distancia: objAux.distancia + objRuta.distancia || 0
                                });
                            } else {
                                rutas.set(o.ttarifarioSelected.id, objRuta);
                            }

                        }

                    }


                    if (o.facuturado) {
                        // console.log("test");
                        odt.cliente = await getClienteInfoFromXML(o.facuturado.cfdixml);
                        odt.rfcTimbrado = await getRfcTimbradoFromXML(o.facuturado.cfdixml);
                        let auxdatatotal = await getClienteInfoTotalFromXML(o.facuturado.cfdixml);
                        o.moneda = auxdatatotal.moneda;
                        odt.moneda = auxdatatotal.moneda;

                        if (!o.total || o.total === "") {

                            //  o.total= await getClienteInfoTotalFromXML(o.facuturado.cfdixml)
                            let totalf = auxdatatotal.total;

                            totalf = parseFloat(totalf);
                            odt.factura_monto = totalf;
                        }
                    }
                }

                if (o.pagos) {
                    //calculamos pendientes
                    let pagado = 0.0;
                    o.pagos.forEach(element => {
                        pagado += element.montoFloat;
                    });

                    if (o.factura_monto >= pagado) {
                        pendientes = o.factura_monto - pagado;
                    } else {
                        pendientes = 0.0;
                    }

                } else {
                    pendientes = o.factura_monto;
                }
                odt.pendientes = pendientes;
                odt.obj = { ...odt };

                let extraenviajeCostoFijo = 0;
                let extraenviajeCostoVariable = 0;
                let extraenviajeSueldoOperador = 0;
                if (o.lineas_captura) {
                    o.lineas_captura.forEach(lc => {

                        let monto = lc.montoFloat;
                        if (!lc.currencyMX) {
                            monto = monto * dolarprice;
                        }
                        if (lc.tipo === "COSTO VARIABLE") {
                            extraenviajeCostoVariable += monto;
                        } else if (lc.tipo === "COSTO_FIJO") {
                            extraenviajeCostoFijo += monto;
                        } else if (lc.tipo === "SUELDOS DE OPERADOR") {
                            extraenviajeSueldoOperador += monto;
                        }


                    });
                }
                odt.operador = "";
                odt.unidad = "";
                if (o.dataTimbrada) {
                    if (o.dataTimbrada.transportista) {
                        odt.operador = o.dataTimbrada.transportista.Nombre;
                    }

                    odt.unidad = o.dataTimbrada.NumeroEconomico;
                } else if (o.isFormatoNuevo === false) {
                    odt.operador = o.detalles.operador1;
                    if (o.detalles.operador1 === "N/A" && o.detalles.operador2 !== "N/A") {
                        odt.operador = o.detalles.operador2;
                    }
                    odt.unidad = o.detalles.unidad;
                }

                odt.extraenviajeCostoFijo = extraenviajeCostoFijo;
                odt.extraenviajeCostoVariable = extraenviajeCostoVariable;
                odt.extraenviajeSueldoOperador = extraenviajeSueldoOperador;

                odt.origen = "";
                odt.destino = "";
                if (o.datos_viaje) {
                    odt.origen = o.datos_viaje.origen;
                    odt.destino = o.datos_viaje.destino;
                }

                odt.status = o.status;
                if (odt.cancelada) {
                    odt.status = "Cancelado";
                }


                odt.facuturado = o.facuturado ? { uuid: o.facuturado.uuid, cfdixml: o.facuturado.cfdixml } : null;
                odt.facturaname = `${o.prefijoFactura}-${o.folioFactura}`;

                //console.log("pushing",odt);
                if (uid === "LOZJRkQRLvbN2mWSjX4MFNr1qvj2" || odt.rfcTimbrado !== "EKU9003173C9") {
                    listaIDS.push(odt);
                }

            }

            console.log("total de cancelados", sumaCancelados);
        }

        let idConsulta = getUidMaster() ? getUidMaster() : user.uid;

        if (busquedatipo === "mes") {
            for await (let a of diasfor) {
                prog += 9;
                setProgreso(prog);
                fDay = new Date(fecha.getFullYear(), fecha.getMonth(), inicioaux);
                if (a === 30) {
                    diadiez = new Date(fDay.getFullYear(), fDay.getMonth() + 1, 0);
                    inicioaux = a + 1;
                } else {
                    diadiez = new Date(fDay.getFullYear(), fDay.getMonth(), fDay.getDate() + 2);
                    inicioaux = a + 1;
                }

                //periodo
                let inicio = new Date(fDay);
                inicio.setHours(0, 0, 0);
                let fin = new Date(diadiez);
                fin.setHours(23, 59, 59);
                console.log("periodo inicio", inicio);
                console.log("periodo fin", fin);

                await getAllViajesTimbradosByUserDateFilter(idConsulta, inicio, fin).then(async (snapshot) => {
                    await procesarRespuesta(snapshot, false);
                });


                await getCfdiSolos(idConsulta, inicio, fin).then(async (snapshot) => {
                    await procesarRespuesta(snapshot, true);
                });

            }
        } else if (busquedatipo === "rango") {
            //periodo
            let inicio = new Date(fechaRangoStart);
            inicio.setHours(0, 0, 0);
            let fin = new Date(fechaRangoEnd);
            fin.setHours(23, 59, 59);
            console.log("periodo inicio", inicio);
            console.log("periodo fin", fin);

            await getAllViajesTimbradosByUserDateFilter(idConsulta, inicio, fin).then(async (snapshot) => {
                await procesarRespuesta(snapshot, false);
            });


            await getCfdiSolos(idConsulta, inicio, fin).then(async (snapshot) => {
                await procesarRespuesta(snapshot, true);
            });

        }
        console.log(`desglose iscfdi ${sumaIsCfidi}   no ${sumaNoiscfdi}`)

        console.log("datos de consulta len", listaIDS.length);
        let extrasaux = [];

        listaIDS.forEach(elemento => {
            // console.log("elemento en listaIDS",elemento);
            if (elemento) {
                if ((!elemento.segmentoNegocio || elemento.segmentoNegocio === "") && segmentos.length > 0) {
                    elemento.segmentoNegocio = segmentos[0];
                }

                let segmentoIndex = segmentos.findIndex(e => e === elemento.segmentoNegocio);
                /**  odt.extraenviajeCostoFijo=extraenviajeCostoFijo;
                 odt.extraenviajeCostoVariable=extraenviajeCostoVariable;
                 odt.extraenviajeSueldoOperador=extraenviajeSueldoOperador; */
                if (elemento.extraenviajeCostoFijo && elemento.extraenviajeCostoFijo > 0) {
                    extrasaux.push({ segmentoIndex, tipo: 'fijo', monto: elemento.extraenviajeCostoFijo });
                }

                if (elemento.extraenviajeCostoVariable && elemento.extraenviajeCostoVariable > 0) {
                    extrasaux.push({ segmentoIndex, tipo: 'variable', monto: elemento.extraenviajeCostoVariable });
                }

                if (elemento.extraenviajeSueldoOperador && elemento.extraenviajeSueldoOperador > 0) {
                    extrasaux.push({ segmentoIndex, tipo: 'sueldo', monto: elemento.extraenviajeSueldoOperador });
                }


                if (segmentoIndex >= 0) {
                    // console.log("updating",elemento);
                    updateResumeValue(segmentoIndex, elemento);
                } else {
                    console.log("no index");
                }
            } else {
                console.log("no segmento negocio", elemento);
            }
        });


        //calculo porsentaje de ingresos
        let ingresostotales = 0;
        let fijostotales = 0;
        resumen.forEach(r => {
            ingresostotales += r.ingresos;
            //fijostotales+=r.costosFijos;
        });
        console.log("el total de ingresos es", ingresostotales);



        console.log("resumen porcentaje", resumen);

        //console.log("rutas",rutas);
        let tempDataRutas = [];
        rutas.forEach(e => {
            tempDataRutas.push(e);
        })
        setDataRutas(tempDataRutas);
        console.log("dataRutas", tempDataRutas);





        fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);

        let sumavariables = 0;
        console.log("consultando", fDay);
        console.log("consultando", lDay);

        let auxxxxfijos=0;
        let auxxcheckfijos=[];
        await getAllCostosByUserDateFilter(uid, fDay, lDay).then(snapshot => {
            console.log("costos no recurrentes encontrados", snapshot.docs.length);
            snapshot.forEach(document => {
                let dobj = document.data();
                let segmentoIndex = segmentos.findIndex(e => e === dobj.segmentoNegocio);

                if (segmentoIndex >= 0) {
                    if (dobj.tipo && dobj.tipo.trim() === "Costo Variable") {

                        //   console.log("variable encontrado", dobj.montofloat)
                        sumavariables += dobj.montofloat;
                        resumen[segmentoIndex].costosVariables = resumen[segmentoIndex].costosVariables + dobj.montofloat;
                    }
                    else if (dobj.tipo && dobj.tipo.trim() === "Combustible" || dobj.tipo.trim() === "Combustible ") {
                        // console.log("combustible enocntrado", dobj.montofloat);
                        resumen[segmentoIndex].costosVariables = resumen[segmentoIndex].costosVariables + dobj.montofloat;
                    }
                    else if (dobj.tipo && dobj.tipo.trim() === "Mantenimiento") {
                        //console.log("combustible enocntrado", dobj.montofloat);
                        resumen[segmentoIndex].costosVariables = resumen[segmentoIndex].costosVariables + dobj.montofloat;
                    }
                    else if (dobj.tipo === "Sueldo de Operador") {
                        console.log("SUELDO DE OPERADOR");
                        console.log("sueldo operador sumando");
                        resumen[segmentoIndex].sueldos = resumen[segmentoIndex].sueldos + dobj.montofloat;
                    } else {
                      //  console.log("sumadno fijo", resumen[segmentoIndex].costosFijos, dobj.montofloat);
                      auxxxxfijos+=dobj.montofloat;
                      auxxcheckfijos.push(dobj.nombre);
                        resumen[segmentoIndex].costosFijos = resumen[segmentoIndex].costosFijos + dobj.montofloat;
                    }

                }else if (dobj.segmentoNegocio===""){
                    resumen[0].costosFijos = resumen[0].costosFijos + dobj.montofloat;
                    auxxxxfijos+=dobj.montofloat;
             
                }
                //console.log(dobj);

            });
        }).catch(err => { console.log(err) });
       // console.log("sumavariables", sumavariables);
       // console.log("fijossss",auxxxxfijos);
       // console.log("auxxcheckfijos",auxxcheckfijos);
        await getAllCostosCombustibleByUserDateFilter(uid, fDay, lDay).then(snapshot => {
            console.log("costos de cobustible", snapshot.docs.length);
            snapshot.forEach(document => {
                let dobj = document.data();
                let segmentoIndex = 0;
                if (dobj.unidad) {
                    segmentoIndex = segmentos.findIndex(e => e === dobj.unidad.segmentoNegocio);
                    if (segmentoIndex < 0) {

                        //console.log("no se encontro", dobj.unidad.segmentoNegocio);
                        segmentoIndex = 0;
                    }
                }
                // console.log("sumando combustibleeeee", dobj.totalFloat);
                // console.log("en indice", segmentoIndex);

                resumen[segmentoIndex].costosVariables = resumen[segmentoIndex].costosVariables + dobj.totalFloat;

            });
        }).catch(err => { console.log(err) });;
        let requiereAjusteTiempo = true;
        if (requiereAjusteTiempo) {
            function addHoursToDate(objDate, intHours) {
                var numberOfMlSeconds = objDate.getTime();
                var addMlSeconds = (intHours * 60) * 60000;
                var newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
                return newDateObj;
            }
            function subtractTimeFromDate(objDate, intHours) {
                var numberOfMlSeconds = objDate.getTime();
                var addMlSeconds = (intHours * 60) * 60000;
                var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
                return newDateObj;
            }
            fDay = addHoursToDate(fDay, 25);
            lDay = subtractTimeFromDate(lDay, 25);
            //console.log("fechas",`${fDay}    ${lDay}`);

        }
        console.log("buscando costos recurrentes", `${uid}, ${fDay}, ${lDay}`);
        let auxxxxfijosrecurrentes=0;
        await getAllCostosRecurrentesByUserDateFilter(uid, fDay, lDay).then(snapshot => {
            console.log("costos Recurrentes encontrados", snapshot.docs.length);

            snapshot.forEach(document => {

                let dobj = document.data();

                if (dobj.fecha_inicio.toDate().getTime() <= fDay.getTime()) {

                    // console.log("recurrente",dobj);
                    let segmentoIndex = segmentos.findIndex(e => e === dobj.segmentoNegocio);
                    if (segmentoIndex >= 0) {
                        if (dobj.tipo === "Costo Variable") {
                            resumen[segmentoIndex].costosVariables = resumen[segmentoIndex].costosVariables + dobj.montofloat;
                        } else if (dobj.tipo === "Sueldo de Operador") {
                            console.log("sueldo operador sumando");
                            resumen[segmentoIndex].sueldos = resumen[segmentoIndex].sueldos + dobj.montofloat;
                        } else {
                            console.log("sumando a fijos", `anterior:${resumen[segmentoIndex].costosFijos} sumarle ${dobj.montofloat} i:${segmentoIndex} ${dobj.segmentoNegocio}`);
                            resumen[segmentoIndex].costosFijos = resumen[segmentoIndex].costosFijos + dobj.montofloat;
                            auxxxxfijosrecurrentes+=dobj.montofloat;
                        }

                    }
                    //  console.log(dobj);
                }
            });
        }).catch(err => { console.log(err) });
        console.log("fijossss",auxxxxfijosrecurrentes);
        resumen.forEach(r => {
            // ingresostotales+=r.ingresos;
            console.log("sumando", r.costosFijos);
            fijostotales += r.costosFijos;
        });
        let auxiiresumen = [];

        /**
         * get pagos fijos operadores
         */
        let diasdelmes = moment(fDay).daysInMonth();
        let sarlariobase = 0;
        await getOperadores(uid).then(resp => {
            resp.forEach(docoperador => {
                let dataoperador = docoperador.data();
                if (dataoperador.active !== false && dataoperador.sueldoBaseFloat && dataoperador.sueldoBaseFloat > 0) {
                    //SALARIO SEMANAL
                    let salarioDiario = dataoperador.sueldoBaseFloat / 7;
                    sarlariobase += salarioDiario * diasdelmes;
                    // resumen[segmentoIndex].sueldos = resumen[segmentoIndex].sueldos + salarioDiario*diasdelmes;
                }
            })
        });

        console.log("salariobase", sarlariobase);
        console.log("resumen", resumen);
        console.log("fijostotales", fijostotales);
        resumen.forEach((r, inde) => {
            // totales   100
            // segmento  x

            let aaa = { ...r };
            let porc = r.ingresos * 100 / ingresostotales;
            // console.log("porcentaje", porc);
            //sueldos
            if (isNaN(aaa.comision)) {
                aaa.comision = 0;
            }
            console.log("sumando salarios", `comision:${aaa.comision} + ${sarlariobase}x${porc / 100} porcentual`)
            aaa.comision = aaa.comision + sarlariobase * (porc / 100);





            aaa.porcentaje = porc;
            aaa.costosFijos = fijostotales * r.ingresos / ingresostotales;

            //console.log("considerar extras",extrasaux);
            let sumavariablesenviaje = 0;
            extrasaux.forEach(ex => {
                if (ex.segmentoIndex === inde) {
                    if (ex.tipo === 'fijo') {
                        aaa.costosFijos = aaa.costosFijos + ex.monto;
                    } else if (ex.tipo === 'variable') {
                        console.log("sumando variable", ex.monto);
                        sumavariablesenviaje += ex.monto;
                        aaa.costosVariables = aaa.costosVariables + ex.monto;
                    } else if (ex.tipo === 'sueldo') {
                        aaa.comision = aaa.comision + parseFloat(ex.monto);
                    }
                } else {
                    console.log("no index", ex);
                }
            })
            console.log("sumavariablesenviaje", sumavariablesenviaje);
            auxiiresumen.push(aaa);

        });



        resumen = [...auxiiresumen];
        console.log("resumen", resumen);

        setResumenLienas(resumen);



        resumen.forEach((element) => {
            let index = costosSegmentosNegocio.findIndex(e => e.nombre === element.segmento);
            if (index >= 0)

                changeKms(index, parseFloat(element.kms).toFixed(2));
            let montomask = '$' + element.ingresos.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            // handleChangeIngresos(0,montomask,element.ingresos,index);

            let montomaskCostosFijos = '$' + element.costosFijos.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            let montomaskCostosVariables = '$' + element.costosVariables.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            let fixsueldoscostos = parseFloat(element.comision || 0) + parseFloat(element.sueldos || 0);
            //
            if (uid && requiereControlPropioSUeldosOperador.find(fso => fso === uid)) {
                fixsueldoscostos = parseFloat(element.sueldos || 0);
            }
            let montomaskSueldos = '$' + fixsueldoscostos.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");



            let objToChange = {
                kms: parseFloat(element.kms).toFixed(2),
                ingresosFloat: element.ingresos,
                ingresos: montomask,
                costoFijosFloat: element.costosFijos,
                costoFijos: montomaskCostosFijos,
                costoVariablesFloat: element.costosVariables,
                costoVariables: montomaskCostosVariables,
                sueldos: montomaskSueldos,
                sueldosFloat: fixsueldoscostos,
                index
            }
            console.log(`${element.costosFijos} ${montomaskCostosFijos} `);
            changeValueByOBJ(objToChange);
            // handleChangeCostofijo(0,montomaskCostosFijos,element.costosFijos,index);
            //const handleChangeIngresos = (event, maskedvalue, floatvalue, index) => {
            // (index, parseFloat(element.kms).toFixed(2));
        });




        await setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }

    useEffect(() => {
        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {
                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);

                setUser(userr);
                // setAdminCurrentUser(userr.uid);

                if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                    // this.validateAdmin(user.uid);
                    //console.log("validando admin");
                    validateAdmin(userr.uid);
                }
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });

    }, []);


    useEffect(() => {
        if (fecha && uid) {
            consultaMes(moment(fecha).format("MM-YYYY"));
        }
    }, [fecha])

    const changeKms = (index, value) => {
        if (costosSegmentosNegocio[index]) {
            //let newArr = [...costosSegmentosNegocio];
            //newArr[index].kms = value;
            // setCostosSegmentosNegocio(newArr);

            let obj = { ...costosSegmentosNegocio[index] };
            obj.index = index;
            obj.kms = value;
            changeValueByOBJ(obj);
        }
    }


    useEffect(() => {
        if (costosSegmentosNegocio) {

        }
    }, [costosSegmentosNegocio])
    const changeValueByOBJ = (obj) => {
        console.log("cambiando");
        if (costosSegmentosNegocio[obj.index]) {
            let newArr = [...costosSegmentosNegocio];
            newArr[obj.index].ingresos = obj.ingresos;
            newArr[obj.index].ingresosFloat = obj.ingresosFloat;
            newArr[obj.index].kms = obj.kms;
            newArr[obj.index].costoFijos = obj.costoFijos;
            newArr[obj.index].costoFijosFloat = obj.costoFijosFloat;
            newArr[obj.index].costoVariables = obj.costoVariables;
            newArr[obj.index].costoVariablesFloat = obj.costoVariablesFloat;
            newArr[obj.index].sueldos = obj.sueldos;
            newArr[obj.index].sueldosFloat = obj.sueldosFloat;

            //calculo de utilidad
            let ingresoxkm = obj.ingresosFloat ? obj.ingresosFloat / obj.kms : 0;
            let cfxkm = obj.costoFijosFloat ? obj.costoFijosFloat / obj.kms : 0;
            let cvxkm = obj.costoVariablesFloat ? obj.costoVariablesFloat / obj.kms : 0;
            let soxkm = obj.sueldosFloat ? obj.sueldosFloat / obj.kms : 0;
            let utilidad = ingresoxkm - cfxkm - cvxkm - soxkm;

            newArr[obj.index].ukmFloat = 0.00;
            newArr[obj.index].ukm = "$0.00";
            newArr[obj.index].utFloat = 0.00;
            newArr[obj.index].ut = "$0.00";
            if (!isNaN(utilidad)) {
                newArr[obj.index].ukmFloat = utilidad.toFixed(2);
                newArr[obj.index].ukm = "$" + utilidad.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

                let utreal = utilidad * obj.kms;
                newArr[obj.index].utFloat = utreal.toFixed(2);
                newArr[obj.index].ut = "$" + utreal.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            } else {
                console.log("es NaN!!!!");
            }


            setCostosSegmentosNegocio(newArr);
        } else {
            console.log("no encontrado")
        }
    }
    const handleChangeIngresos = (event, maskedvalue, floatvalue, index) => {
        if (costosSegmentosNegocio[index]) {
            //let newArr = [...costosSegmentosNegocio];
            // newArr[index].ingresos = maskedvalue;
            // newArr[index].ingresosFloat = floatvalue;
            // setCostosSegmentosNegocio(newArr);
            let obj = { ...costosSegmentosNegocio[index] };
            obj.index = index;
            obj.ingresos = maskedvalue;
            obj.ingresosFloat = floatvalue;
            changeValueByOBJ(obj);
        }
    }
    const handleChangeCostofijo = (event, maskedvalue, floatvalue, index) => {
        if (costosSegmentosNegocio[index]) {
            /* console.log("setting");
             let newArr = [...costosSegmentosNegocio];
             newArr[index].costofijo = maskedvalue;
             newArr[index].costosfijoFloat = floatvalue;
             setCostosSegmentosNegocio(newArr);*/
            let obj = { ...costosSegmentosNegocio[index] };
            obj.index = index;
            obj.costoFijos = maskedvalue;
            obj.costoFijosFloat = floatvalue;
            changeValueByOBJ(obj);
        } else {
            console.log("no se encontro index")
        }
    }


    const handleChangeCostovariable = (event, maskedvalue, floatvalue, index) => {
        if (costosSegmentosNegocio[index]) {
            let obj = { ...costosSegmentosNegocio[index] };
            obj.index = index;
            obj.costoVariables = maskedvalue;
            obj.costoVariablesFloat = floatvalue;
            changeValueByOBJ(obj);
        }
    }
    const handleChangeSueldoOperador = (event, maskedvalue, floatvalue, index) => {
        if (costosSegmentosNegocio[index]) {
            let obj = { ...costosSegmentosNegocio[index] };
            obj.index = index;
            obj.sueldos = maskedvalue;
            obj.sueldosFloat = floatvalue;
            changeValueByOBJ(obj);
        }
    }
    const handleDateChanged = (date) => {
        let fDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        lDay.setHours(23, 59, 59);
        setFirstDay(fDay);
        setLastDay(lDay);
        setCurrentDate(date);
        setFecha(date);
    }
    const save = async () => {
        if (resumenLineas.length > 0) {
            //mix datos rutas                                        
            let datosRutas = dataRutas.map(r => {
                let o = { ...r };
                let findR = resumenLineas.find(l => l.segmento === o.segmentoNegocio);

                if (findR) {
                    let cfxkm = findR.costosFijos / findR.kms;
                    let cvxkm = findR.costosFijos / findR.kms;
                    let soxkm = findR.costosVariables / findR.kms;
                    o.cfxkm = cfxkm || 0.00;
                    o.cvxkm = cvxkm || 0.00;
                    o.soxkm = soxkm || 0.00;
                    o.resumen = findR;
                }
                return o;
            })


            // console.log("datos ruta", datosRutas);

            let auxresumenlienas = { ...resumenLineas };
            console.log(auxresumenlienas);
            let i = 0;
            let auxxx = [];
            while (auxresumenlienas[i] !== undefined) {
                let cc = auxresumenlienas[i];
                let findcosto = costosSegmentosNegocio.find(dd => dd.nombre === cc.segmento);
                if (findcosto) {
                    cc.kms = parseFloat(findcosto.kms);
                }
                auxxx.push(cc);
                i++;
            }

            let fechaFix = moment(fecha).format("MM-YYYY");
            if (busquedatipo === "rango") {
                fechaFix = `${moment(fechaRangoStart).format("dd-MM-YYYY")}:${moment(fechaRangoEnd).format("dd-MM-YYYY")}`
            }

            let dataToSave = {
                uid,
                dolarprice,
                fecha: fechaFix,
                resumenLineas: auxxx
            }

            let dataToSaveRutas = {
                uid,
                dolarprice,
                fecha: fechaFix,
                datosRutas
            }

            //console.log(dataToSave);
            // return;

            await addResumen(dataToSave).then(snapshot => {
                console.log("added");
            }).catch(err => console.log(err));

            await addResumenRutas(dataToSaveRutas).then(snapshot => {
                console.log("added rutas");
            }).catch(err => console.log(err));

            if (fecha && uid) {
                consultaMes(moment(fecha).format("MM-YYYY"));
            }

            Swal.fire('Éxito', 'Se ha guardaro el cierre de mes de Costo Por Kilómetro.', 'success');
        }

    }
    return (
        <Fragment>
            {
                <LoadingOverlay
                    active={isLoading}
                    spinner
                    text={loadingText}
                >
                    <div className="row">
                        <div className="col-12">
                            <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>&nbsp;Costo x Km.</h3>
                            <div className="card">
                                <div className="card-header card-header-flex">

                                    <div className="d-flex px-3 row justify-content-between align-items-center">
                                        <div className="col-auto pl-0">


                                        </div>
                                        <div>

                                        </div>


                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="air__layout__content p-5">
                                        <div className="air__utils__content">
                                            <div className="row card-panels">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-2"><h3>Calcular Costo x KM</h3></div>
                                                        <div className="col-1">
                                                            <div style={{ display: 'flex' }}>
                                                                <p className="fixedP">DLL.</p>
                                                                <div class="fieldInput">
                                                                    <input style={{ width: "90%" }} type="number" name='dll' step={0.01} value={dolarprice} onChange={e => { setDolarPrice(parseFloat(e.target.value)) }} placeholder="Dll" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <div style={{ display: 'flex' }}>
                                                                <p className="fixedP" style={{ width: "250px" }}>Ingresos con Impuestos</p>
                                                                <div class="fieldInput">
                                                                    <select value={impuesto} onChange={e => setImpuestos(e.target.value)}>
                                                                        <option value={1}>Si</option>
                                                                        <option value={2}>No</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {uid && requiereConsultaPorTiempoCustom.find(fir => fir === uid) &&
                                                            <div className="col-1">
                                                                <div style={{ display: 'flex', width: '135px' }}>
                                                                    <p className="fixedP" style={{ width: "40px" }}>Tipo</p>
                                                                    <div class="fieldInput">
                                                                        <select value={busquedatipo} style={{ width: '90%' }} onChange={e => setBusquedatipo(e.target.value)}>
                                                                            <option value={`mes`}>Mes</option>
                                                                            <option value={`rango`}>Rango</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {busquedatipo === "mes" && <div className="col-2"> <DatePicker
                                                            className="form-control customdatepicker"
                                                            locale="es"
                                                            selected={currentDate}
                                                            onChange={date => handleDateChanged(date)}
                                                            maxDate={addDays(new Date(), 1)}
                                                            dateFormat="MMMM yyyy"
                                                            showMonthYearPicker
                                                        /></div>}
                                                        {
                                                            uid && requiereConsultaPorTiempoCustom.find(fir => fir === uid) &&
                                                            busquedatipo === "rango" &&
                                                            <div className="col-2">  <DateRangePicker
                                                                style={{ marginTop: '5px' }}
                                                                clearIcon={null}
                                                                value={[fechaRangoStart, fechaRangoEnd]}
                                                                onChange={onChangeDaterangeTwo}
                                                            /></div>
                                                        }
                                                        <div className="col-1">
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                className={classes.button}
                                                                onClick={() => actionConsultar()}
                                                                startIcon={<SearchIcon />}
                                                            >
                                                                Consultar
                                                            </Button>
                                                        </div>
                                                        <div className="col-1">
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                className={classes.button}
                                                                onClick={() => save()}
                                                                startIcon={<SaveIcon />}
                                                            >
                                                                Guardar
                                                            </Button>
                                                        </div>

                                                        {
                                                            dataExcel && <div className="col-1"><Download uid={uid} data={dataExcel} /> </div>
                                                        }
                                                    </div>


                                                    <div className="row">
                                                        {
                                                            costosSegmentosNegocio.map((element, index) => {
                                                                return <div className="col-4 col-lg-4">
                                                                    <Card>
                                                                        <CardContent>
                                                                            <Typography sx={{ fontSize: 28 }} color="text.secondary" gutterBottom>
                                                                                {element.nombre}
                                                                            </Typography>

                                                                            <table style={{ width: '100%' }}>
                                                                                <tr>
                                                                                    <td><Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                                                                        Kilometros totales
                                                                                    </Typography></td>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            <input placeholder="Kms." type="number" id="inputreferencia" value={element.kms} onChange={e => changeKms(index, e.target.value)} className="form-control mx-sm-1" />
                                                                                        </div>

                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                                                                        Ingresos Totales
                                                                                    </Typography></td>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            <CurrencyInput disabled className="form-control" value={element.ingresos} onChangeEvent={(event, maskedvalue, floatvalue) => handleChangeIngresos(event, maskedvalue, floatvalue, index)} prefix="$" />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                                                                        Costos Fijos Totales
                                                                                    </Typography></td>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            <CurrencyInput disabled className="form-control" value={element.costoFijos} onChangeEvent={(event, maskedvalue, floatvalue) => handleChangeCostofijo(event, maskedvalue, floatvalue, index)} prefix="$" />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                                                                        Costos Variables Totales
                                                                                    </Typography></td>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            <CurrencyInput disabled className="form-control" value={element.costoVariables} onChangeEvent={(event, maskedvalue, floatvalue) => handleChangeCostovariable(event, maskedvalue, floatvalue, index)} prefix="$" />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                                                                        Sueldos de operador Totales
                                                                                    </Typography></td>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            <CurrencyInput disabled className="form-control" value={element.sueldos} onChangeEvent={(event, maskedvalue, floatvalue) => handleChangeSueldoOperador(event, maskedvalue, floatvalue, index)} prefix="$" />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <br />
                                                                                <tr>
                                                                                    <td><Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                                                                        Ingreso x Km
                                                                                    </Typography></td>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            <CurrencyInput disabled className="form-control" value={element.ingresosFloat / element.kms} onChangeEvent={(event, maskedvalue, floatvalue) => handleChangeSueldoOperador(event, maskedvalue, floatvalue, index)} prefix="$" />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ paddingLeft: '30px' }}><Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                                                                        - CF x Km
                                                                                    </Typography></td>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            <input placeholder="Costo Fijo x KM" type="text" disabled className="form-control" value={!isNaN(element.costoFijosFloat / element.kms) ? parseFloat(element.costoFijosFloat / element.kms).toFixed(2) : 0.00} onChangeEvent={(event, maskedvalue, floatvalue) => handleChangeSueldoOperador(event, maskedvalue, floatvalue, index)} prefix="$" />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ paddingLeft: '30px' }}><Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                                                                        - CV x Km
                                                                                    </Typography></td>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            <input placeholder="Costo Variable x km" type="text" disabled className="form-control" value={!isNaN(element.costoVariablesFloat / element.kms) ? parseFloat(element.costoVariablesFloat / element.kms).toFixed(2) : 0.00} onChangeEvent={(event, maskedvalue, floatvalue) => handleChangeSueldoOperador(event, maskedvalue, floatvalue, index)} prefix="$" />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ paddingLeft: '30px' }}><Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                                                                        - SO x Km
                                                                                    </Typography></td>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            <input placeholder="Sueldo operado x km" type="text" disabled className="form-control" value={!isNaN(element.sueldosFloat / element.kms) ? parseFloat(element.sueldosFloat / element.kms).toFixed(2) : 0.00} onChangeEvent={(event, maskedvalue, floatvalue) => handleChangeSueldoOperador(event, maskedvalue, floatvalue, index)} prefix="$" />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                                                                        Utilidad Mensual x Km
                                                                                    </Typography></td>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            <input placeholder="Utilidad x KM" type="text" disabled className="form-control" value={element.ukm} onChangeEvent={(event, maskedvalue, floatvalue) => handleChangeSueldoOperador(event, maskedvalue, floatvalue, index)} prefix="$" />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                                                                        Utilidad Real del mes
                                                                                    </Typography></td>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            <input placeholder="Utilidad" type="text" disabled className="form-control" value={element.ut} onChangeEvent={(event, maskedvalue, floatvalue) => handleChangeSueldoOperador(event, maskedvalue, floatvalue, index)} prefix="$" />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                            </table>


                                                                        </CardContent>

                                                                    </Card>
                                                                </div>
                                                            })
                                                        }


                                                    </div>

                                                </div>
                                                <hr />


                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>

                    </div>
                </LoadingOverlay>
            }
        </Fragment >
    );
}
export default CostoXKmContent;