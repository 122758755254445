import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getUserByUid } from '../../../controllers/usuarios';
import { updateDolarPrice } from '../../../controllers/viajes';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import InputMask from 'react-input-mask';

const ModalCreateEdit = (props) => {


    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);

    const [dolarprice, setDolarPrice] = useState(18);
    const [usuarioID, setUsuarioID] = useState(null);


    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    useEffect(() => {
        setIsOpen(props.isOpen);
        //setRegimenFiscalDescripcion("601 - General Ley de las Personas Morales");


    }, []);



    const initValues = async () => {

        // setRegimenFiscalDescripcion("601 - General Ley de las Personas Morales");

        getUserByUid(uid).then(resp => {
            if (resp && resp.docs.length > 0) {
                let data = resp.docs[0].data();
                if (data.master) {
                    getUserByUid(data.master).then(resp2 => {
                        let data2 = resp2.docs[0].data();
                        setUsuarioID(resp2.docs[0].id);
                        if (data2.dolarprice) {
                            setDolarPrice(data2.dolarprice);
                        }
                    });
                } else {
                    setUsuarioID(resp.docs[0].id);
                    if (data.dolarprice) {
                        setDolarPrice(data.dolarprice);
                    }
                }


            }
        });
    }

    useEffect(() => {

        setUid(props.uid);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);

        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);

    }, [props]);




    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');
            }

            if (props.uid) {
                console.log("buscar dolar de uid", props.uid);
            }
        }

    }, [isOpen]);



    const handleSumbit = async (e) => {
        e.preventDefault();



        if (!isNaN(dolarprice)) {
            let dolarpricefloat = parseFloat(parseFloat(dolarprice).toFixed(2));
            console.log("submiting", uid, dolarpricefloat, usuarioID);
            if (usuarioID) {
                updateDolarPrice(usuarioID, dolarpricefloat).then(resp => {
                    Swal.fire("Tipo de cambio actualizado", `Se ha actualizado el tipo de cambio a $${dolarprice} `, 'success');
                });
            }


        }


    }




    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "" : `Configuración de la cuenta`}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <h1>Tipo de cambio de dolar</h1><br />

                    <div className="row">

                        <div className="form-group col-3">
                            <label>Dolar </label><label className="requiredlabel">*</label>
                            <input required autoComplete="off" type="number" step={0.01} name='marca' className="form-control" value={dolarprice} onChange={e => setDolarPrice(e.target.value)} />
                        </div>


                    </div>



                    <hr />
                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >
}
export default ModalCreateEdit;