import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
//import 'firebase/storage';     // for storage
//import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
//import 'firebase/messaging';   // for cloud messaging
//import 'firebase/functions';   // for cloud functions

var firebaseConfig = {
  apiKey: "AIzaSyBggy4Slhp2FpWxLkHROeoY7uP3kPx4ax8",
  authDomain: "transport-plus-98f66.firebaseapp.com",
  projectId: "transport-plus-98f66",
  storageBucket: "transport-plus-98f66.appspot.com",
  messagingSenderId: "616346110197",
  appId: "1:616346110197:web:f8b504800e37192abbddbc",
  measurementId: "G-CDZFYJQ1JZ"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//const googleAuthProvider=new firebase.auth.GooogleAuthProvider();

export {firebase};