import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



export default class Download extends React.Component {
    constructor(props)
    {
        super();
        let {data}=props;
        this.state = {
            dataSet1: data,
        }
     //   console.log(this.state);

    }

    componentDidUpdate=(prevProps)=>{
        
    }
    /**
     * 
     * numEconomico: element.numEconomico,
                                                            propiedad: element.propiedad,
                                                            tipo: element.tipo,
                                                            placas: element.placas,
                                                            marca: element.marca,
                                                            year: element.year,
                                                            descripcion: element.descripcion,
                                                            status: element.active ? "Activo" : "Inactivo"
     */
    
    render() {
        return (
            <ExcelFile  element={<div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>}>
                <ExcelSheet data={this.state.dataSet1} name="Clientes"> 
                    <ExcelColumn label="Nombre" value="nombre"/>
                    <ExcelColumn label="RFC" value="rfc"/>
                    <ExcelColumn label="Localidad" value="localidad"/>
                    <ExcelColumn label="Estado" value="estado"/>
                </ExcelSheet>
               
            </ExcelFile>
        );
    }
}
