import React, { useState, useEffect, Fragment } from 'react';
import { firebase } from '../../../configs/firebase';
//import { deleteIngreso } from '../../../controllers/ingresos';
import { getUserByUid, getAllUsuarios, updateViajesTime, updateViajesTimeByUid } from '../../../controllers/usuarios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
//import DatePicker from 'react-datepicker';
import { addDays } from "date-fns";
import { HotTable, HotColumn } from '@handsontable/react';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { registerLanguageDictionary } from 'handsontable/i18n';
import esMX from 'handsontable/i18n/languages/es-MX';
import { useHistory } from 'react-router-dom';
//import ModalCreateEdit from '../ModalCreateEdit';
import { getAllUserViajes, getAllViajesByUserDateFilter, getViajeRepo, nextFolio, addViaje, getLastUpdatesViajes, getViajeById, getLastUpdatesViajesByTimeServer, getPendientes } from '../../../controllers/viajes';
import { ActionRender, DateRender, MoneyRender, InputKmsRender, CombustibleRecordRender, KmsRealesRecordRender } from './ActionsRender';
import Download from './ExcelExport';
import ModalFacturar from '../../facturas/ModalFacturar';
import ModalCreateEdit from '../../cartasportecrud/ModalCreateEdit';
import ModalEncuesta from '../../encuesta/ModalCreateEdit';
import ModalCartaPorteConfirm from '../../cartaporte/ModalCartaPorteConfirm';
import ModalCargas from '../modalcargas';
import ModalCombustible from '../modalCombustible';
import ModalKmsReales from '../modalkmsreales';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MUIDataTable from "mui-datatables";
import CustomToolbar from './CustomToolbar';
import CustomToolbarSelect from './CustomToolbarSelect';
import { getUidMaster, checkMaster } from '../../../session/session_controller';
import { useIndexedDB } from 'react-indexed-db';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Badge from '@material-ui/core/Badge';
//import {BorradoMasivo} from '../../../controllers/viajes';
//import { ActualizacionMasivaTemporal } from '../../../controllers/viajes';
//import {CopyUsuario,CopyUsuarioUser} from '../../../controllers/viajes';
//import { ActualizacionMasivaTemporal } from '../../../controllers/operadores';
//import { ActualizacionMasivaTemporalRemolques } from '../../../controllers/viajes';
//import { ActualizacionMasivaTemporalUbicaciones } from '../../../controllers/viajes';
import { auxiliarTareasBorradoExpoTractos, ActualizacionMasivaTemporalBorradoTractosXPOU, auxiliarTareasAA, auxiliarTareasBB, auxGetViajesPagados, actualizaANoPagado, auxiliarTareas, auxiliarTareasCfdiNormal, auxiliarTareas2, auxiliarTareas2CfdiNormal, auxiliarTareas3, auxiliarTareas4, auxiliarTareas5 } from '../../../controllers/viajes';
import axios from 'axios';

const requiereSubliente = ['HptPbvVxYYUjUzp7Nt3TtmyAt0x1','31RxHqtBOmbgOWWeMCKnb1DuEHc2'];

const Table = React.memo(
    (props) => {
        const { viajes } = props;
        const { handleFacturar } = props;
        const { handleCP } = props;
        const { handleCopy } = props;
        const { handleCopy2 } = props;
        const { handleViewFromId } = props;
        const { handleCargaCombustible } = props;
        const { handleKmsReales } = props;
        const { handleWPId } = props;
        const { handlechangeKMSReales } = props;
        const { uid } = props;
        console.log("tabla render");
        return (
            <HotTable settings={{
                colHeaders: true,
                rowHeaders: true,
                //  autoRowSize: false,
                columnSorting: true,
                dropdownMenu: ['filter_by_condition',
                    'filter_by_value',
                    'filter_action_bar'],
                filters: true,
                manualColumnResize: true,
                colWidths: [110, 110, 110, 110, 110, 110, 280, 110, 110, 280, 180, 180, 60, 120, 150, 120],
                //para ancho total                                    
                minSpareRows: 1,          // almenos una fila
                //    autoColumnSize: true,     // 
                //  stretchH: 'all',          // 
                // disableVisualSelection: true,
                currentRowClassName: 'currentRowHightlight',
                licenseKey: "non-commercial-and-evaluation",
                language: "es-MX"
            }} data={viajes} colHeaders={true} rowHeaders={false} width="100%" height="auto" >

                <HotColumn className="htCenter htMiddle" readOnly title="Viaje" data="info.viaje" />
                <HotColumn className="htCenter htMiddle" readOnly title="CP" data="info.ncarta" />
                <HotColumn className="htCenter htMiddle" readOnly title="L.Negocio" data="segmentoNegocio" />
                <HotColumn className="htCenter htMiddle" readOnly title="Estatus" data="status" />

                <HotColumn className="htCenter htMiddle" render readOnly title="Recolección" data="recoleccion" >
                    <DateRender hot-renderer></DateRender>
                </HotColumn>
                <HotColumn className="htCenter htMiddle" render readOnly title="Entrega" data="entrega" >
                    <DateRender hot-renderer></DateRender>
                </HotColumn>
                <HotColumn className="htCenter htMiddle" readOnly title="Operador" data="info.operador" />
                <HotColumn className="htCenter htMiddle" readOnly title="Unidad" data="info.unidad" />
                <HotColumn className="htCenter htMiddle" readOnly title="Remolque" data="remolques" />
                <HotColumn className="htCenter htMiddle" readOnly title="Cliente" data="info.cliente" />
                <HotColumn className="htCenter htMiddle" readOnly title="Origen" data="origenformat" />
                <HotColumn className="htCenter htMiddle" readOnly title="Destino" data="destinoformat" />
                <HotColumn className="htCenter htMiddle" readOnly title="Kms." data="info.km" />
                <HotColumn className="htCenter htMiddle" render readOnly title="KmsReal" data="dataActions" >
                    <KmsRealesRecordRender handleKmsReales={handleKmsReales} handleViewFromId={handleViewFromId} hot-renderer></KmsRealesRecordRender>
                </HotColumn>
                {/* <HotColumn className="htCenter htMiddle" render readOnly title="Reg.Combustible" data="dataActions" >
                    <CombustibleRecordRender handleCargaCombustible={handleCargaCombustible} handleViewFromId={handleViewFromId} hot-renderer></CombustibleRecordRender>
                </HotColumn> */}

                <HotColumn className="htCenter htMiddle" readOnly title="Acciones" data="dataActions"  >
                    <ActionRender hot-renderer handleFacturar={handleFacturar} handleCP={handleCP} handleCopy={handleCopy} handleViewFromId={handleViewFromId} handleWPId={handleWPId} handleDeleteFromId={() => alert("here")} />
                </HotColumn>
            </HotTable>
        );
    }, (prevProps, nextProps) => {
        //si retornamos true no se renderiza
        //si retornamos false se renderiza
        return false;
        if (JSON.stringify(prevProps.viajes) === JSON.stringify(nextProps.viajes)) {
            return true;
        } else {
            return false;
        }
    });
const IngresoContent = (props) => {
    let history = useHistory();
    registerLanguageDictionary(esMX);
    registerLocale('es', es)


    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [fechaRangoStart, setFechaRangoStart] = useState(new Date());
    const [fechaRangoEnd, setFechaRangoEnd] = useState(new Date());
    const [fecha, setFecha] = useState(new Date());
    const [firstDay, setFirstDay] = useState(null);
    const [lastDay, setLastDay] = useState(null);
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [querysearch, setQuerysearch] = useState('');
    const [meslocal, setMesLocal] = useState('');

    const [isrefreshing, setIsRefreshing] = useState(false);


    const [viajes, setViajes] = useState([]);
    const [viajesNoFilter, setViajesNofilter] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenCP, setIsOpenCP] = useState(false);
    const [isOpenCargas, setIsOpenCargas] = useState(false);
    const [isOpenCPDialog, setIsOpenCPDialog] = useState(false);
    const [isOpenCombustible, setIsOpenCombustible] = useState(false);
    const [isOpenKmsReales, setIsOpenKmsreales] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditCP, setIsEditCP] = useState(false);
    const [currenteditrecrod, setCurrentEditRecord] = useState(null);
    const [currenteditrecrodCombustible, setCurrentEditRecordCombustible] = useState(null);
    const [currenteditrecrodKmsReales, setCurrentEditRecordKmsReales] = useState(null);
    const [currenteditrecrodCP, setCurrentEditRecordCP] = useState(null);
    const [cargarDatosPeridodo, setCargarDatosPeddridodo] = useState("0");
    const [tipoTabla, setTipoTabla] = useState("0");
    const [firstRenderFinish, setFirstRenderFinis] = useState(false);

    const [pendientes, setPendientes] = useState([]);

    const [openEncuesta, setOpenEncuesta] = useState(false);
    const handleCargaCombustible = id => {
        //   history.push('/cporte/' + id);
        console.log("clic facturar", id);
        let o = findRecord(id);
        if (o) {
            setCurrentEditRecordCombustible(o);
        } else {
            console.log("no se encontro registro");
        }
    }
    const parseSubViajes = arraaux => {
        let original = [...arraaux];
        let fixaux = [];
        arraaux.forEach(aa => {
            if (aa.isSubViaje) {
                console.log("viaje como subviaje");
                let originalIndex = original.findIndex(f => !f.isSubViaje && f.info.viaje === aa.info.viaje);
                if (originalIndex >= 0) {
                    original[originalIndex].kms += aa.kms;
                    original[originalIndex].info.km += aa.info.km;
                    if (original[originalIndex].info.ncarta && aa.info.ncarta)
                        original[originalIndex].info.ncarta = original[originalIndex].info.ncarta + "\n" + aa.info.ncarta;
                    //console.log("datos de esto",original[originalIndex].info);
                    //  original.general.ncarta=original.general.ncarta+" **";

                }
            }
        });
        original.forEach(orig => {
            if (!orig.isSubViaje) {
                fixaux.push(orig);
            }
        });
        return fixaux;
    }
    const handleKmsReales = id => {
        //   history.push('/cporte/' + id);
        console.log("clic facturar", id);
        let o = findRecord(id);
        if (o) {
            setCurrentEditRecordKmsReales(o);
        } else {
            console.log("no se encontro registro");
        }
    }
    const handlechangeKMSReales = (id, value) => {
        console.log("cambiando ", `${id} valor kmsreales ${value}`);
        let auxviajes = [];
        viajes.forEach(v => {
            let cpv = { ...v };
            if (cpv.index === id) {
                console.log("se encontro concidencia");
                cpv.dataActions.kmsreales = parseFloat(value);
            }
            auxviajes.push(cpv);
        });
        console.log(auxviajes);
        setViajes(auxviajes);
        setIsOpenKmsreales(false);
        setCurrentEditRecordKmsReales(null);
    }
    useEffect(() => {
        console.log("viajes cambio");
    }, [viajes])
    const checkUser = async (uid) => {
        console.log("checking user");
        let fechaUltimoViajes = null;
        let iddocuser = null;
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                iddocuser = doc.id;
                if (user.changedViajes) {
                    fechaUltimoViajes = user.changedViajes.toDate().getTime();
                }
            });
        }).catch();

        let idTocheckTIme = uid;
        if (getUidMaster(uid)) {
            idTocheckTIme = getUidMaster(uid);
        }


        if (!fechaUltimoViajes && iddocuser) {
            await updateViajesTime(iddocuser).then(resp => { }).catch(err => {
                console.log(err);
            });

            // await GetClientesServer();
        } else {
            //  console.log("chakando fechaviajes");
            var moment = require('moment'); // require
            let fechaactual = new Date();

            if (!localStorage.getItem("fechaviajes") || !localStorage.getItem("mesCargado") || localStorage.getItem("mesCargado") !== moment(fechaactual).format("MM/YYYY")) {
                console.log("consultando viajes al servidor 1");
                localStorage.setItem("fechaviajes", `${fechaUltimoViajes}`);
                await GetData();

                //  await GetClientesServer();
            } else {
                console.log("si existe fechaviajes");
            }

            let fechaviajeslocal = localStorage.getItem("fechaviajes");

            //console.log("fecha local", fechaviajeslocal);
            //console.log("fecha server", fechaUltimoViajes);
            console.log("get4");
            await GetViajesLocales();

            /*if (fechaviajeslocal < fechaUltimoViajes) {
                console.log("consultando viajes al servidor 2");
                await GetActualizados(fechaviajeslocal, fechaUltimoViajes);
                // await GetClientesServer();
                console.log("consultando viajes localmente y despues ultimos");
                await GetViajesLocales();

                // localStorage.setItem("fechaviajes", `${fechaUltimoViajes}`);
            } else {
                //  await GetClientesLocales();
                console.log("consultando viajes localmente");
                await GetViajesLocales();

            }*/

        }
        // getData(user);
    }
    useEffect(() => {
        if (isrefreshing) {
            console.log("get1");
            GetViajesLocales();
        }
    }, [isrefreshing]);
    const ClickRefresh = () => {
        if (!isrefreshing) {
            setIsRefreshing(true);
        }
    }
    const UpdateSingle = async (objV) => {
        const { update } = useIndexedDB('viajes');
        update(objV).then(event => {
            console.log("actualizado single");

        });

    }
    const GetActualizados = async (fechalocal) => {
        const { add } = useIndexedDB('viajes');
        const { update } = useIndexedDB('viajes');

        let usertoconsult = { ...user };
        if (isAdmin && adminCurrentUser) {
            usertoconsult.uid = adminCurrentUser;
        }

        if (getUidMaster(usertoconsult.uid)) {
            usertoconsult.uid = getUidMaster(usertoconsult.uid);
        }
        console.log("tratando de getnuevos", fechalocal);
        let fechaa = new Date();
        fechaa.setTime(fechalocal);
        let totalnuevos = 0;
        if (usertoconsult && usertoconsult.uid && fechaa)
            await getLastUpdatesViajesByTimeServer(usertoconsult.uid, fechaa).then(snapshot => {
                let index = viajes.length;

                snapshot.forEach(document => {
                    index++;
                    let objV = ParseDocumentToObjViajes(document, index);
                    console.log("buscando localmente", objV.key);
                    let dif1 = objV.timechanged;
                    let dif2 = objV.timecreated;
                    let dif = Math.abs(dif2 - dif1);
                    totalnuevos++;
                    console.log("dif", dif);
                    if (dif <= 100) {
                        //alta\
                        console.log("alta de uno nuevo");
                        add(objV).then(event => {
                            console.log("agregado nuevo");
                        });

                    } else {
                        //actualizacion
                        console.log("hay que actualizar");
                        update(objV).then(event => {
                            console.log("actualizado");

                        });

                    }

                    //console.log("buscando localmente",getByID(objV.key));
                });
                // localStorage.setItem("fechaviajes", `${fechaserver}`);
            }).catch(err => {
                console.log(err);
            });

        return totalnuevos;

    }
    const validateAdmin = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (user.type && user.type === "administrador") {
                    isadmin = true;

                }

            });
            if (isadmin) {
                setAdminCurrentUser(uid);
                setIsAdmin(true);
            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                setIsAdmin(false);
                setUserlist([]);
                // this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }

    useEffect(() => {
        if (isAdmin) {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                setUserlist(a);
            }).catch(e => {
                console.log(e);
                //this.setState({isLoading:false,})
            });
        }
    }, [isAdmin]);
    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    useEffect(() => {


        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        lDay.setHours(23, 59, 59);
        setFirstDay(fDay);
        setLastDay(lDay);

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {
                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);
                setUser(userr);
                // setAdminCurrentUser(userr.uid);



                if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                    // this.validateAdmin(user.uid);
                    //console.log("validando admin");
                    validateAdmin(userr.uid);
                }
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });
        setFirstRenderFinis(true);
    }, []);


    useEffect(() => {
        /*if (querysearch.trim() === '') {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                aux.push(element);
            });
            setIngresos(aux);
        } else {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                let query = querysearch.trim().toUpperCase();
                if (element.cliente.toUpperCase().includes(query) ||
                    element.referencia.toUpperCase().includes(query) ||
                    element.tipo.toUpperCase().includes(query) ||
                    element.monto.includes(query) ||
                    element.iva.includes(query) ||
                    element.descripcion.toUpperCase().includes(query)) {
                    aux.push(element);
                }

            });
            setIngresos(aux);
        }*/
    }, [querysearch]);

    useEffect(() => {
        if (user && user.uid !== adminCurrentUser) {
            //console.log("get data de usuario useeffect");
            getUidMaster() ? checkUser(getUidMaster()) :
                checkUser(user.uid);

            // getData(user);
        }
    }, [user, adminCurrentUser]);

    useEffect(() => {
        if (firstRenderFinish) {
            //console.log("here cambio en datos periodod");
            localStorage.removeItem('lastviajes');
            localStorage.removeItem('lastviajesexpiration');
            //console.log("get data de cambio carga datos periodo");
            // getData();
        }
        GetViajesLocales();

    }, [cargarDatosPeridodo]);



    /*useEffect(() => {
        if (firstRenderFinish) {
            //console.log("here cambio en datos periodod");
            localStorage.removeItem('lastviajes');
            localStorage.removeItem('lastviajesexpiration');
            //console.log("get data de cambio carga datos periodo");
            // getData();
        }

    }, [cargarDatosPeridodo]);*/

    const GuardarLocalmenteViajes = async (listaViajes) => {
        const { add } = useIndexedDB('viajes');
        let promises = [];
        listaViajes.forEach(element => {
            promises.push(add({ ...element }))
        });
        await Promise.all(promises).then(values => {
            console.log("agregados viajes localmente", listaViajes.length);
        });
        var moment = require('moment'); // require
        let fechaactual = new Date();
        localStorage.setItem('mesCargado', moment(fechaactual).format("MM/YYYY"));
    }
    const GetViajesLocales = async () => {
        //   setCurrentDate(new Date());
        buscarPendientes();
        console.log("getting viajes localmente");
        const { getAll } = useIndexedDB('viajes');
        let aux = [];
        setIsLoading(true);
        let maxseconds = 0;
        await getAll().then(dataFromDB => {
            console.log("size local", dataFromDB.length);
            dataFromDB.forEach(element => {
                if (element.changed && element.changed.seconds) {
                    if (element.changed.seconds > maxseconds) {
                        maxseconds = element.changed.seconds;
                        //console.log("maxseconds",maxseconds);
                    }
                }

                let o = { ...element };
                console.log("VIAJE LOCAL", o);
                let statusfix = o.status;
                if (o.facuturado) {
                    if (o.status == "Entregado" || o.status == "Despachado") {
                        statusfix = "Facturado/" + o.status;
                    }
                }

                o.status = statusfix;
                let auxremolque1 = o.remolque1;

                if (o.contenedor1 && o.contenedor1 !== "") {
                    auxremolque1 = o.contenedor1;
                }

                o.info = {
                    viaje: o.nviaje,
                    carta: o.ncarta,
                    estatus: statusfix,
                    recoleccion: o.recoleccion,
                    entrega: o.entrega,
                    operador: o.operador,
                    unidad: o.unidad,
                    remolque1: auxremolque1 || "",
                    remolque2: o.remolque2 || "",
                    remolque3: o.remolque3 || "",
                    remolque4: o.remolque4 || "",
                    detalles: o.comentarios,
                    origen: o.origenformat,
                    destino: o.destinoformat,
                    km: o.kms,
                    kmreales: o.kmsreales || o.kms,
                    referencia: o.referencia,
                    total: o.total,
                    tarifa: o.total,
                    subTarifa: o.subTarifa || "",
                    moneda: o.moneda,
                    ingresokm: o.ingresoxkm,
                    comision: o.comisionoperador,
                    cliente: o.cliente,
                    subcliente: o.subcliente || "",
                    ncarta: o.ncarta,
                    tipoviaje: o.tipoviaje,
                    comisionoperador: o.comisionoperador,
                    segmentoNegocio: o.segmentoNegocio

                }

                aux.push(o);
            });



            // setOperadores(dataFromDB);
        }).catch(err => {
            console.log("err cargalocal", err);
            setIsLoading(false);
        });
        if (maxseconds > 0) {
            var moment = require('moment'); // require
            let fechaa = new Date(maxseconds * 1000);
            console.log("fecha mas reciente", moment(fechaa).format("DD/MM/YYYY hh:mm:ss"))
            let totalactualizados = await GetActualizados(fechaa);
            if (totalactualizados > 0) {
                aux = [];
                await getAll().then(dataFromDB => {

                    dataFromDB.forEach(element => {
                        let o = { ...element };
                        let auxremolque1 = o.remolque1;

                        if (o.contenedor1 && o.contenedor1 !== "") {
                            auxremolque1 = o.contenedor1;
                        }
                        o.info = {
                            viaje: o.nviaje,
                            carta: o.ncarta,
                            estatus: o.status,
                            recoleccion: o.recoleccion,
                            entrega: o.entrega,
                            operador: o.operador,
                            unidad: o.unidad,
                            remolque1: auxremolque1 || "",
                            remolque2: o.remolque2 || "",
                            remolque3: o.remolque3 || "",
                            remolque4: o.remolque4 || "",
                            detalles: o.comentarios,
                            origen: o.origenformat,
                            destino: o.destinoformat,
                            km: o.kms,
                            kmreales: o.kmsreales || o.kms,
                            referencia: o.referencia,
                            total: o.total,
                            tarifa: o.total,
                            subTarifa: o.subTarifa || "",
                            moneda: o.moneda,
                            ingresokm: o.ingresoxkm,
                            comision: o.comisionoperador,
                            cliente: o.cliente,
                            subcliente: o.subcliente,
                            ncarta: o.ncarta,
                            tipoviaje: o.tipoviaje,
                            comisionoperador: o.comisionoperador,
                            segmentoNegocio: o.segmentoNegocio

                        }

                        aux.push(o);
                    });
                    // setOperadores(dataFromDB);
                }).catch(err => {
                    console.log("err cargalocal", err);
                    setIsLoading(false);
                });
            }

        }

        console.log("asignando a tabla");

        aux = parseSubViajes(aux);
        console.log("despues de parse", aux);


        if (cargarDatosPeridodo === "1") {
            //mes
            // console.log("m");

            setViajes(aux);
            setViajesNofilter(aux);
        } else {
            var moment = require('moment'); // require

            let todisplay = [];
            // console.log("primer elemento", aux[0].recoleccion);
            aux.forEach(element => {
                if (element.recoleccion && element.recoleccion === moment(new Date()).format('DD/MM/YYYY')) {
                    todisplay.push(element);
                }
            });
            console.log("m else", todisplay);
            setViajes(todisplay.sort((a, b) => {
                if (a.nviaje > b.nviaje) {
                    return 1;
                }
                if (a.nviaje < b.nviaje) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            }));
            setViajesNofilter(todisplay);
        }

        setIsRefreshing(false);
        setIsLoading(false);


    }
    var moment = require('moment');
    const ParseDocumentToObjViajes = (doc, index) => {
        // require
        let o = doc.data();
        o.index = doc.id;
        o.n = index;
        o.id = doc.id;
        o.key = doc.id;
        o.timecreated = o.createAt ? o.createAt.toDate().getTime() : 0;
        o.timechanged = o.changed ? o.changed.toDate().getTime() : 0;

        if (o.datos_viaje.fechaOrigen) {


            o.fechaformat = moment(o.datos_viaje.fechaOrigen.toDate()).format('DD/MM/YYYY');
        }
        else {
            o.fechaformat = "";
        }
        let ciudad = o.carta_porte.origenCiudad || "";
        let estado = o.carta_porte.origenEstadoShort || "";
        let ciudaddestino = o.carta_porte.destinoCiudad || "";
        let estadodestino = o.carta_porte.destinoEstadoShort || "";

        o.origenformat = ciudad + "," + estado;
        o.destinoformat = ciudaddestino + "," + estadodestino;
        //data table new

        o.nviaje = o.general.nviaje;
        o.ncarta = o.general.ncarta;



        if (o.datos_viaje.fechaOrigen) {
            o.recoleccion = moment(o.datos_viaje.fechaOrigen.toDate()).format('DD/MM/YYYY');
        } else {
            o.recoleccion = "";
        }
        if (!o.datos_viaje.fechaDestino) {
            console.log("error en ID:", o.id)
        }
        if (o.datos_viaje.fechaDestino) {
            o.entrega = moment(o.datos_viaje.fechaDestino.toDate()).format('DD/MM/YYYY');
        }
        else {
            o.entrega = "";
        }
        if (o.datos_viaje.fechaOrigen) {
            o.fechaconsulta = o.datos_viaje.fechaOrigen.toDate().getTime() + 1;
        } else {
            o.fechaconsulta = 10000;
        }



        o.operador = o.detalles.operador1;
        o.unidad = o.detalles.unidad;
        o.cliente = o.general.cliente;
        o.subcliente = o.general.subcliente || "";
        o.updatetime = o.updateAt ? o.updateAt.toDate().getTime() : 0;
        o.tipoviaje = o.datos_viaje.tipo;
        o.remolque1 = o.carta_porte.remolque1;
        o.remolque2 = o.carta_porte.remolque2;
        o.contenedor1 = o.carta_porte.contenedor1 || "";
        o.moneda = o.detalles.currencyMX ? "MXN" : "USD";

        // o.origenformat = ciudad + "," + estado;
        //o.destinoformat = ciudaddestino + "," + estadodestino;
        o.kms = o.carta_porte.distTotales_km;
        o.referencia = o.general.referencia;
        o.total = o.detalles.tarifa;
        o.subTarifa = o.detalles.subTarifa || "";
        o.ingresoxkm = o.detalles.ingresoXkilometro;
        o.comentarios = o.detalles.comentarios;


        o.tipoviaje = o.datos_viaje.tipo;
        o.comisionoperador = o.toperadorSelected ? `$${parseFloat(o.toperadorSelected.tarifaFloat).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}` : "";


        if (o.general.ncarta) {
            o.id = doc.id + "-cporte";
        }



        // index++;
        if (o.status === 'Cancelado') {
            o.id = doc.id + "-Cancelado";
        }

        let auxremolque1 = o.carta_porte.remolque1;
        if (o.contenedor1 && o.contenedor1 !== "") {
            auxremolque1 = o.contenedor1;
        }
        //console.log("DATOOOS REMOLQIE",auxremolque1);


        o.info = {
            viaje: o.general.nviaje,
            carta: o.general.ncarta,
            estatus: o.status,
            recoleccion: o.recoleccion,
            entrega: o.entrega,
            operador: o.detalles.operador1,
            unidad: o.detalles.unidad,
            remolque1: auxremolque1 || "",
            remolque2: o.carta_porte.remolque2 || "",
            remolque3: o.carta_porte.remolque3 || "",
            remolque4: o.carta_porte.remolque4 || "",
            detalles: o.detalles.comentarios,
            origen: o.carta_porte.origenCiudad + ' - ' + o.carta_porte.origenEstadoShort,
            destino: o.carta_porte.destinoCiudad + ' - ' + o.carta_porte.destinoEstadoShort,
            km: o.carta_porte.distTotales_km,
            kmreales: o.kmsreales || o.carta_porte.distTotales_km,
            referencia: o.general.referencia,
            total: o.detalles.montoTotalFloat,
            subTarifa: o.detalles.subTarifa || "",
            tarifa: o.detalles.tarifa,
            moneda: o.detalles.currencyMX ? "MXN" : "USD",
            ingresokm: o.detalles.ingresoXkilometroFloat,
            comision: o.toperadorSelected ? parseFloat(o.toperadorSelected.tarifaFloat).toFixed(2) : 0.0,
            cliente: o.general.cliente,
            subcliente: o.general.subcliente || "",
            ncarta: o.general.ncarta,
            tipoviaje: o.datos_viaje.tipo,
            comisionoperador: o.toperadorSelected ? `$${parseFloat(o.toperadorSelected.tarifaFloat).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}` : "",
            segmentoNegocio: o.segmentoNegocio

        }

        let remolquesjuntos = o.info.remolque1 + " " + o.info.remolque2;
        remolquesjuntos = remolquesjuntos.trim();

        if (o.datos_viaje.claseViaje === "Marítimo-puertos" && o.carta_porte.contenedor1) {
            remolquesjuntos = `${o.carta_porte.contenedor1 || ""}`;
            o.info.remolque1 = o.carta_porte.contenedor1;
            o.contenedor1 = remolquesjuntos;
        }
        o.remolques = remolquesjuntos;



        delete o.lineas_captura;
        delete o.mercancias;
        delete o.detalles;
        delete o.carta_porte;
        delete o.general;
        delete o.datos_viaje;

        o.dataActions = { id: o.id, kmsreales: o.kmsreales || 0, formatoNuevo: o.isFormatoNuevo ? true : false, isCP: o.info.ncarta != "", isCargado: o.isViajeCargado, complementos: o.complementos || [] };
        console.log("returning o.dataactions", o.dataActions);
        return o;
    }
    const GetDataOtroMes = async (usertodisplay) => {
        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }

            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }

            let a = [];
            let va = [];
            let index = 0;
            var moment = require('moment'); // require

            let fDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            let lDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

            let inicioconsulta = fDay;
            let finconsulta = lDay;

            inicioconsulta.setHours(0, 0, 0);
            setIsLoading(true);


            console.log("iniciando consulta de otro mes");
            let tempAux = [];
            await getAllViajesByUserDateFilter(usertoconsult.uid, inicioconsulta, finconsulta).then(snapshot => {
                console.log("iniciando consulta de otro mes");
                snapshot.forEach(doc => {
                    index++;
                    a.push(ParseDocumentToObjViajes(doc, index));
                });
                // localStorage.setItem('lastviajes', JSON.stringify(a));


            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar los viajes, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });
            console.log("finalizando consulta de otro mes")


            //  GuardarLocalmenteViajes(a);
            setIsLoading(false);
            //buscar updates

        } else {
            //console.log("user null");
        }
    }
    const GetData = async (usertodisplay) => {

        console.log("getting viajes de server");
        const { clear } = useIndexedDB('viajes');

        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }

            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }

            let a = [];
            let va = [];
            let index = 0;
            var moment = require('moment'); // require
            setMesLocal(moment(currentDate).format("MM/YYYY"));
            //let inicioconsulta = firstDay;
            //let finconsulta = lastDay;

            let inicioconsulta = new Date();
            let finconsulta = new Date();



            /*if (cargarDatosPeridodo === "0") {
                inicioconsulta = new Date();
                finconsulta = new Date();
            }*/

            inicioconsulta.setHours(0, 0, 0);
            finconsulta.setHours(23, 59, 59);

            setIsLoading(true);

            /*let expirationcompare = new Date();
            let nowcompare = new Date();

            if (localStorage.getItem('lastviajesexpiration')) {
                expirationcompare.setTime(parseInt(localStorage.getItem('lastviajesexpiration')));
            }


            if (nowcompare.getTime() <= expirationcompare.getTime() && localStorage.getItem('lastviajes')) {
                let arraymemo = JSON.parse(localStorage.getItem('lastviajes'));
                va = [...arraymemo];
            }


            if (va.length > 0) {
                if (va[va.length - 1].fechaconsulta) {
                    let copy = new Date();
                    copy.setTime(va[va.length - 1].fechaconsulta);
                    inicioconsulta = copy;
                }
            }*/
            //let tempAux = [];
            await getAllViajesByUserDateFilter(usertoconsult.uid, inicioconsulta, finconsulta).then(snapshot => {

                snapshot.forEach(doc => {
                    index++;
                    a.push(ParseDocumentToObjViajes(doc, index));
                });
                // localStorage.setItem('lastviajes', JSON.stringify(a));


            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar los viajes, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });


            if (a.length > 0) {
                clear().then(() => {
                    console.log('viajes locales limpios');
                });
                await GuardarLocalmenteViajes(a);
            } else {

            }

            //setIsLoading(false);
            //buscar updates

        } else {
            //console.log("user null");
        }
    }


    useEffect(() => {
        if (viajes) {
            //  buscarActualizados();
        }
    }, [viajesNoFilter]);

    const refresh = () => {
        GetActualizados(new Date());
        // localStorage.removeItem('lastviajes');
        // localStorage.removeItem('lastviajesexpiration');
        //console.log("get data de refreshing");
        // getData(user);
    }



    const handleDateChanged = (date) => {

        setCurrentDate(date);
    }

    useEffect(() => {
        if (currentDate) {
            let fDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            let lDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            lDay.setHours(23, 59, 59);
            setFirstDay(fDay);
            setLastDay(lDay);
        }

        var moment = require('moment'); // require
        let mesSelected = moment(currentDate).format("MM/YYYY");
        let meslocall = moment(new Date()).format("MM/YYYY");
        console.log("currentedateChange", mesSelected);
        if (cargarDatosPeridodo === "1") {
            if (mesSelected !== meslocall) {
                console.log("meses diferentes", mesSelected);
                console.log("a", meslocall);
                GetDataOtroMes();
            } else {
                console.log("get3");
                GetViajesLocales();
            }

        }

    }, [currentDate]);

    const findRecord = (id) => {
        let obj = viajes.find(element => element.key === id);
        return obj;
    }


    useEffect(() => {
        if (currenteditrecrodCP != null) {
            if (currenteditrecrodCP.none) {
                setIsEditCP(false);
            } else {
                setIsEditCP(true);
            }
            setIsOpenCPDialog(true);
        }
    }, [currenteditrecrodCP]);

    useEffect(() => {
        if (currenteditrecrodCombustible != null) {
            setIsOpenCombustible(true);
        }
    }, [currenteditrecrodCombustible]);

    useEffect(() => {
        if (currenteditrecrodKmsReales != null) {
            setIsOpenKmsreales(true);
        }
    }, [currenteditrecrodKmsReales])

    useEffect(() => {
        if (currenteditrecrod != null) {
            if (currenteditrecrod.none) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
            }
            setIsOpen(true);
        }
    }, [currenteditrecrod])

    const handleCreate = () => {
        history.push('/viaje');
    }
    const handleViewFromId = id => {
        // history.push('/viaje/' + id);
        let dtime = new Date().getTime();
        const win = window.open('/viaje/' + id + "?d=" + dtime, "_blank");
        win.focus();
    }
    const handleViewFromIndex = index => {
        const objViaje = viajes[index];
        if (objViaje) {
            history.push('/viaje/' + objViaje.key);
        }

    }

    const handleCopy = async (id) => {

        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            const { value: ncopias } = await Swal.fire({
                title: 'Deseas copiar el viaje?',
                icon: 'question',
                input: 'range',
                inputLabel: 'No. de copias',
                inputAttributes: {
                    min: 1,
                    max: 3,
                    step: 1
                },
                showCancelButton: true,
                confirmButtonColor: '#1c666f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si Copiar',
                cancelButtonText: 'Cancelar',
                inputValue: 1
            });
            if (ncopias) {

                let original = null;
                await getViajeById(id).then(document => {
                    original = { ...document.data() }
                    original.id = document.id;
                    original.key = document.id;
                }).catch(err => {

                });
                if (original) {
                    console.log("original", original);
                }
                let objuserviaje = null;
                await getViajeRepo(uid).then(snapshot => {
                    if (snapshot) {
                        snapshot.forEach(document => {
                            objuserviaje = { ...document.data(), id: document.id };

                        });
                    }
                }).catch(err => { console.log(err) });

                if (objuserviaje) {
                    let vfolio = parseInt(objuserviaje.vfolio);
                    let prefixv = objuserviaje.prefixvc;

                    let aux = [];
                    for (let index = 0; index < ncopias; index++) {
                        let nuevoNviaje = "" + prefixv + "-" + vfolio;
                        let copia = {
                            ...original,
                            id: null,
                            key: null,
                            fechafinalizacion: null,
                            facuturado: null,
                            general: { ...original.general, nviaje: nuevoNviaje, ncarta: "", savedCP: false },
                            detalles: { ...original.detalles, operador1Id: "", operador2Id: "", operador1: 'N/A', operador2: 'N/A', unidad: "", unidadId: null },
                            status: 'Planeado',

                        };
                        delete copia.facuturado;
                        //console.log("copia", copia);
                        await addViaje(copia).then(async (docuRef) => {
                            console.log("copia agregada");
                            await nextFolio(objuserviaje.id).then(r => {
                            }).catch(err => {
                                console.log(err);
                                setIsLoading(false);
                            });

                        });
                        await updateViajesTimeByUid(original.uid);
                        checkUser(original.uid);

                        vfolio++;
                        aux.push(copia)

                    }

                }

            }

        }

    }
    const handleCopy2 = async (id) => {

        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            const { value: ncopias } = await Swal.fire({
                title: 'Deseas copiar el viaje?',
                icon: 'question',
                input: 'range',
                inputLabel: 'No. de copias',
                inputAttributes: {
                    min: 1,
                    max: 3,
                    step: 1
                },
                showCancelButton: true,
                confirmButtonColor: '#1c666f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si Copiar',
                cancelButtonText: 'Cancelar',
                inputValue: 1
            });
            if (ncopias) {

                let original = null;
                await getViajeById(id).then(document => {
                    original = { ...document.data() }
                    original.id = document.id;
                    original.key = document.id;
                }).catch(err => {

                });
                if (original) {
                    console.log("original", original);
                }
                let objuserviaje = null;
                await getViajeRepo(uid).then(snapshot => {
                    if (snapshot) {
                        snapshot.forEach(document => {
                            objuserviaje = { ...document.data(), id: document.id };

                        });
                    }
                }).catch(err => { console.log(err) });

                if (objuserviaje) {
                    let vfolio = parseInt(objuserviaje.vfolio);
                    let prefixv = objuserviaje.prefixvc;

                    let aux = [];
                    for (let index = 0; index < ncopias; index++) {
                        let nuevoNviaje = "" + prefixv + "-" + vfolio;
                        let copia = {
                            ...original,
                            id: null,
                            key: null,
                            fechafinalizacion: null,
                            facuturado: null,
                            isSubViaje: true,
                            general: { ...original.general, ncarta: "", savedCP: false },
                            detalles: { ...original.detalles, operador1Id: "", operador2Id: "", operador1: 'N/A', operador2: 'N/A', unidad: "", unidadId: null },
                            status: 'Planeado',

                        };
                        delete copia.facuturado;
                        //console.log("copia", copia);
                        await addViaje(copia).then(async (docuRef) => {
                            console.log("copia agregada");
                            await nextFolio(objuserviaje.id).then(r => {
                            }).catch(err => {
                                console.log(err);
                                setIsLoading(false);
                            });

                        });
                        await updateViajesTimeByUid(original.uid);
                        checkUser(original.uid);

                        vfolio++;
                        aux.push(copia)

                    }

                }

            }

        }

    }
    const getObjByIndex = index => {
        return viajes[index];
    }
    const handleCopyByIndex = async (index) => {
        let objViaje = viajes[index];
        await getViajeById(objViaje.key).then(document => {
            objViaje = { ...document.data() };
            objViaje.key = document.id;
            objViaje.id = document.id;
        }).catch(err => { console.log("error al copiar", err) })
        console.log("tratando de agregar copia", objViaje);
        if (objViaje && user != null) {
            let id = objViaje.key;
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            const { value: ncopias } = await Swal.fire({
                title: 'Deseas copiar el viaje?',
                icon: 'question',
                input: 'range',
                inputLabel: 'No. de copias',
                inputAttributes: {
                    min: 1,
                    max: 3,
                    step: 1
                },
                showCancelButton: true,
                confirmButtonColor: '#1c666f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si Copiar',
                cancelButtonText: 'Cancelar',
                inputValue: 1
            });
            if (ncopias) {
                let original = findRecord(id);
                if (original) {
                    console.log("original", original);
                }
                let objuserviaje = null;
                await getViajeRepo(usertoconsult.uid).then(snapshot => {
                    if (snapshot) {
                        snapshot.forEach(document => {
                            objuserviaje = { ...document.data(), id: document.id };

                        });
                    }
                }).catch(err => { console.log(err) });

                if (objuserviaje) {
                    let vfolio = parseInt(objuserviaje.vfolio);
                    let prefixv = objuserviaje.prefixvc;

                    let aux = [];
                    for (let index = 0; index < ncopias; index++) {
                        let nuevoNviaje = "" + prefixv + "-" + vfolio;
                        let copia = {
                            ...original,
                            id: null,
                            key: null,
                            fechafinalizacion: null,
                            facuturado: null,
                            general: { ...original.general, nviaje: nuevoNviaje, ncarta: "", savedCP: false },
                            detalles: { ...original.detalles, operador1: 'N/A', operador2: 'N/A', unidad: "", unidadId: null },
                            status: 'Planeado',

                        };
                        delete copia.facuturado;
                        //console.log("copia", copia);
                        await addViaje(copia).then(async (docuRef) => {
                            console.log("copia agregada");
                            await nextFolio(objuserviaje.id).then(r => {
                                console.log("next folio");
                            }).catch(err => {
                                console.log(err);
                                setIsLoading(false);
                            });
                        });

                        vfolio++;
                        aux.push(copia)

                    }
                    refresh();

                }

            }

        }

    }

    const handleCP = id => {
        //   history.push('/cporte/' + id);
        // const win = window.open('/cporte/' + id, "_blank");
        // win.focus();

        let o = findRecord(id);
        let date = new Date();

        if (o) {
            /* if (o.facturamaCP) {
                 const win = window.open('/cporte/' + id + "?d=" + date.getTime(), "_blank");
                 win.focus();
             }
             else*/
            setCurrentEditRecordCP(o);
        } else {
            console.log("no se encontro registro");
        }
    }

    const handleFacturar = id => {
        //   history.push('/cporte/' + id);
        console.log("clic facturar", id);
        let o = findRecord(id);
        if (o) {
            setCurrentEditRecord(o);
        } else {
            console.log("no se encontro registro");
        }
    }


    useEffect(() => {
        if (!isOpen && currenteditrecrod) {
            setCurrentEditRecord(null);
        }
    }, [isOpen]);

    useEffect(() => {
        if (!isOpenCPDialog && currenteditrecrodCP) {
            setCurrentEditRecordCP(null);
        }
    }, [isOpenCPDialog]);

    const handleWPId = async (id) => {
        let ofind = findRecord(id);
        let o = null;
        await getViajeById(id).then(document => {
            o = { ...document.data() };
            o.id = document.id;
            o.key = document.id;
        }).catch(err => {

        });


        if (o) {
            var moment = require('moment'); // require
            let dataFechaOrigen = o.datos_viaje.fechaOrigen ? o.datos_viaje.fechaOrigen.toDate() : new Date();
            let dataFechaDestino = o.datos_viaje.fechaDestino ? o.datos_viaje.fechaDestino.toDate() : new Date();

            let fechaOrigenFormat = moment(dataFechaOrigen).format('DD/MM/YYYY');
            let fechaDestinoFormat = moment(dataFechaDestino).format('DD/MM/YYYY');
            //console.log("fecha origen format",fechaOrigenFormat);

            let operadorfixed = o.detalles.operador1;
            if (operadorfixed === 'N/A' && o.detalles.operador2 !== "N/A") {
                operadorfixed = o.detalles.operador2;
            }

            let remolquesstring = '';
            if (o.carta_porte.messageRemolque1 !== '') {
                remolquesstring += `*Remolque ${o.carta_porte.messageRemolque1}:* ${o.carta_porte.remolque1}\n`
            }
            if (o.carta_porte.messageRemolque2 !== '') {
                remolquesstring += `*Remolque ${o.carta_porte.messageRemolque2}:* ${o.carta_porte.remolque2}\n`
            }
            if (o.carta_porte.messageRemolque3 !== '') {
                remolquesstring += `*Remolque ${o.carta_porte.messageRemolque3}:* ${o.carta_porte.remolque3}\n`
            }
            if (o.carta_porte.messageRemolque4 !== '') {
                remolquesstring += `*Remolque ${o.carta_porte.messageRemolque4}:* ${o.carta_porte.remolque4}\n`
            }

            if (o.datos_viaje.claseViaje === "Marítimo-puertos") {
                remolquesstring = `*Remolque :* ${o.carta_porte.contenedor1 || ""} placas:${o.carta_porte.placas1 || ""}\n`
            }

            let descripwp = "";
            if (o.detalles.comentarios && o.detalles.comentarios.trim() !== "") {
                descripwp = `*Comentarios:* ${o.detalles.comentarios}`;
            }
            let textowp = `Fecha Origen: ${fechaOrigenFormat}\nViaje No. ${o.general.nviaje} / Carta Porte. ${o.general.ncarta}\nFecha Destino: ${fechaDestinoFormat}\n-------------------------\n*Operador:* ${operadorfixed} / ${o.datos_viaje.claseViaje}\n*Unidad:* ${o.detalles.unidad}\n*Origen:* ${o.datos_viaje.origen}\n*Destino:* ${o.datos_viaje.destino}\n${remolquesstring}${descripwp}`;
            navigator.clipboard.writeText(textowp);
            Toast.fire({
                icon: 'success',
                title: 'Información del viaje copiada al portapapeles..'
            })

        }
    }
    const handleDeleteFromId = id => {

    }
    const buscarPendientes = () => {
        if (uid) {
            getPendientes(uid).then(snapshot => {
                console.log("Cantidad de pendientes", snapshot.docs.length);
                let aux = [];
                snapshot.forEach(doc => {
                    let o = { ...doc.data(), id: doc.id };
                    aux.push(o);
                });
                setPendientes(aux);
            }).catch(err => console.log(err));
        }
    }
    const BuscarPorRango = async (fechainicio, fechafinal) => {

        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }

            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }

            let a = [];
            let va = [];
            let index = 0;
            var moment = require('moment'); // require

            //let fDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            // let lDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

            let inicioconsulta = fechainicio;
            let finconsulta = fechafinal;

            inicioconsulta.setHours(0, 0, 0);
            setIsLoading(true);


            console.log("iniciando consulta de otro mes");
            let tempAux = [];
            await getAllViajesByUserDateFilter(usertoconsult.uid, inicioconsulta, finconsulta).then(snapshot => {
                console.log("iniciando consulta de otro mes");
                snapshot.forEach(doc => {
                    index++;
                    a.push(ParseDocumentToObjViajes(doc, index));
                });
                // localStorage.setItem('lastviajes', JSON.stringify(a));


            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar los viajes, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });
            console.log("finalizando consulta de otro mes")

            setViajes(a);
            setViajesNofilter(a);
            //  GuardarLocalmenteViajes(a);
            setIsLoading(false);
            //buscar updates

        }

    }
    const onChangeDaterange = (dates) => {
        const [start, end] = dates;
        if (start)
            start.setHours(0, 0, 0);
        if (end)
            end.setHours(23, 59, 59);
        setFechaRangoStart(start);
        setFechaRangoEnd(end);

        if (start && end) {
            if (start.getTime() <= end.getTime()) {

                setTimeout(() => {
                    console.log("buscar por rango");
                    BuscarPorRango(start, end);
                }, 500);
            }
        }

    };

    const onChangeDaterangeTwo = (fechas) => {
        if (!fechas || !fechas[0] || !fechas[1]) {
            return;
        }

        let start = new Date(fechas[0].getTime());
        let end = new Date(fechas[1].getTime());

        if (start)
            start.setHours(0, 0, 0);
        if (end)
            end.setHours(23, 59, 59);
        setFechaRangoStart(start);
        setFechaRangoEnd(end);

        if (start && end) {
            if (start.getTime() <= end.getTime()) {

                setTimeout(() => {
                    console.log("buscar por rango");
                    BuscarPorRango(start, end);
                }, 500);
            }
        }

    };

    /*const prosesarPeticion = async()=>{
        const datatemp = [
            "1215",
            "1216",
            "1217",
            "1218",
            "1222",
            "1236",
            "1242",
            "1249",
            "1266",
            "1271",
            "1273",
            "1274",
            "1275",
            "1276",
            "1277",
            "1280",
            "1286",
            "1291",
            "1307",
            "1321",
            "1331",
            "1344",
            "1360",
            "1361",
            "1366",
            "1373",
            "1374",
            "1375",
            "1376",
            "1377",
            "1378",
            "1379",
            "1383",
            "1397",
            "1410",
            "1411",
            "1427",
            "1436",
            "1439",
            "1454",
            "1471",
            "1473",
            "1476",
            "1480",
            "1482",
            "1486",
            "1489",
            "1491",
            "1504",
            "1520",
            "1522",
            "1530",
            "1540",
            "1556",
            "1563",
            "1572",
            "1583",
            "1595",
            "1621",
            "1648",
            "1662",
            "1665",
            "1673",
            "1674",
            "1678",
            "1686",
            "1702",
            "1710",
        ];

       await datatemp.forEach(async(e)=>{
           await auxGetViajesPagados("BrZNecrFhCX9aWWjLJQy2B8EAAp2", `CP-${e}`).then(resp => {
                resp.forEach(docu => {
                    console.log("actualizando", docu.id);
                    actualizaANoPagado(docu.id).then(respupdate => {
                        console.log("actualizado");
                    }).catch(errorr => { console.log(errorr) });
                });
    
            });
        })
        
    }*/
    return (
        <Fragment>
            {
                <div className="row">
                    <div className="col-12">

                        <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>&nbsp;Módulo de Viajes</h3>
                        <div className="card">
                            <div className="card-header card-header-flex">

                                <div className="d-flex px-3 row  align-items-center">
                                    <div className="col-auto pl-0">
                                        <div style={{ display: 'flex' }}>


                                            <button class="boton primBtn add ms-right" onClick={() => handleCreate()}><span>Agregar Viaje</span></button>
                                            {/* let idss=[];
                                                    let maxdeletes=0;
                                                    await auxiliarTareasBorradoExpoTractos().then(resp=>{
                                                        console.log("remolques totales",resp.docs.length);
                                                        resp.forEach(doc=>{
                                                           let dataRemolque=doc.data();
                                                           if(maxdeletes<300 &&  (dataRemolque.nombre.startsWith("XPO") || dataRemolque.nombre.startsWith("RS") )){
                                                            idss.push(doc.id);
                                                            maxdeletes++;
                                                           }
                                                        });
                                                    });
                                                  console.log("XPOUS",idss.length);
                                                 await ActualizacionMasivaTemporalBorradoTractosXPOU(idss).then(resp=>{console.log("ok finish deleted")});
                                                 console.log("...");
                                                  <button type="button" style={{ marginRight: '10px' }} className="btn btn-outline-dark btn-sm" onClick={() => setIsOpenCP(true)}><i className="fa fa-plus-circle iconfa"></i> &nbsp;Agregar Carta Porte</button>*/}
                                            {<button class="boton primBtn add ms-right" onClick={() => setIsOpenCargas(true)}><span>Cargas Portal</span></button>
                                            }
                                            {
                                                /**
                                                 *  let idss=[];
                                                    let maxdeletes=0;
                                                    await auxiliarTareasBorradoExpoTractos().then(resp=>{
                                                        console.log("remolques totales",resp.docs.length);
                                                        resp.forEach(doc=>{
                                                           let dataRemolque=doc.data();
                                                           if(maxdeletes<300 &&  (dataRemolque.propiedad === "Otro" )){
                                                            idss.push(doc.id);
                                                            maxdeletes++;
                                                           }
                                                        });
                                                    });
                                                  console.log("XPOUS",idss.length);
                                                 await ActualizacionMasivaTemporalBorradoTractosXPOU(idss).then(resp=>{console.log("ok finish deleted")});
                                                 console.log("...");
                                                   
                                                 * <Badge badgeContent={pendientes.length} color="primary">
                                                    <LocalShippingIcon />
                                                </Badge>
                                                 */


                                                /*<button type="button" style={{ marginRight: '10px' }} className="btn btn-outline-dark btn-sm" onClick={async () => {
                                                    //  ActualizacionMasivaTemporal().then(resp=>console.log("okkk")).catch(er=>console.log(er));
                                                    // prosesarPeticion();
                                                    //auxtareas
                                                    


                                                  //  ActualizacionMasivaTemporal().then(resp=>{console.log("ok")});

                                                   // ActualizacionMasivaTemporalRemolques().then(resp=>{console.log("ok")});
                                                    //  ActualizacionMasivaTemporalUbicaciones().then(resp=>{console.log("ok")});
                                                   
                                                    //auxiliarTareas()
                                                    // auxiliarTareasCfdiNormal
                                                    //auxiliarTareas2CfdiNormal
                                                    // setOpenEncuesta(!openEncuesta);

                                                }}><i className="fa fa-plus-circle ico nfa"></i> &nbsp;TEst</button> */}


                                            {
                                                !isLoading && viajes.length > 0 && <Download uid={uid || ""} data={
                                                    viajes.map(element => {
                                                        return {
                                                            ...element.info
                                                        }
                                                    })
                                                } className="" />
                                            }
                                            {/*<TextField
                                            style={{ height: '20px' }}
                                            variant="outlined"
                                            size="small"
                                            id="input-with-icon-textfield"
                                            label="Buscar"
                                            value={querysearch}
                                            onChange={(e) => setQuerysearch(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />*/}
                                        </div>
                                    </div>

                                    <div style={{ marginLeft: '15px' }} className="row">
                                        {/* <div className="col-1">
                                            <Tooltip title={"Refrescar"}>
                                                <IconButton onClick={() => ClickRefresh()}>
                                                    <CachedIcon style={{
                                                        color: "#4a5dc7",

                                                    }} size="small" aria-label="Refrescar" />
                                                </IconButton>
                                            </Tooltip>
                                                </div>*/}
                                        <div>
                                            <FormControl component="fieldset">
                                                <RadioGroup row aria-label="periodo" name="periodo" value={cargarDatosPeridodo} onChange={e => setCargarDatosPeddridodo(e.target.value)}>
                                                    <FormControlLabel value="0" control={<Radio />} label="Día actual" />
                                                    <FormControlLabel value="1" control={<Radio />} label="Rango" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                        {cargarDatosPeridodo === "1" && <div style={{ paddingTop: '5px' }} className="col-6">
                                            <DateRangePicker
                                                style={{ marginTop: '5px' }}
                                                clearIcon={null}
                                                value={[fechaRangoStart, fechaRangoEnd]}
                                                onChange={onChangeDaterangeTwo}
                                            />
                                            { /*<DatePicker
                                                selected={fechaRangoStart}
                                                onChange={onChangeDaterange}
                                                startDate={fechaRangoStart}
                                                endDate={fechaRangoEnd}
                                                selectsRange={true}
                                                withPortal
                                                //inline
                                            />*/}
                                        </div>}
                                        {/*<div className="col-6">
                                            {
                                                isAdmin ? <div>

                                                    <select className="form-control" name='adminCurrentUser' onChange={(e) => setAdminCurrentUser(e.target.value)} value={adminCurrentUser} >
                                                        {
                                                            userlist.map((item, index) => {
                                                                return <option key={index} value={item.uid}>{item.email}</option>

                                                            })
                                                        }
                                                    </select>
                                                </div> : <div></div>
                                            }

                                            <DatePicker
                                                className="form-control customdatepicker"
                                                locale="es"
                                                selected={currentDate}
                                                onChange={date => handleDateChanged(date)}
                                                //maxDate={addDays(new Date(), 1)}
                                                dateFormat="MMMM yyyy"
                                                showMonthYearPicker
                                            />
                                        </div>*/}

                                    </div>

                                    <div style={{ marginLeft: '75px' }}>
                                        <FormControl component="fieldset">
                                            <RadioGroup row aria-label="tabletype" name="tabletype" value={tipoTabla} onChange={e => setTipoTabla(e.target.value)}>
                                                <FormControlLabel value="0" control={<Radio />} label="Tabla 1" />
                                                <FormControlLabel value="1" control={<Radio />} label="Tabla 2" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>

                                </div>
                            </div>
                            <LoadingOverlay
                                active={isLoading}
                                spinner
                                text='Actualizando datos, espere ...'
                            >
                                <div className="card-body">
                                    {viajes && tipoTabla === "0" ?
                                        <Table uid viajes={viajes} handleKmsReales={handleKmsReales} handleCargaCombustible={handleCargaCombustible} handlechangeKMSReales={handlechangeKMSReales} handleFacturar={handleFacturar} handleCP={handleCP} handleCopy={handleCopy} handleCopy2={handleCopy2} handleViewFromId={handleViewFromId} handleWPId={handleWPId} handleDeleteFromId={() => alert("here")} />
                                        : <></>
                                    }
                                </div>


                            </LoadingOverlay>
                        </div>
                    </div>
                    {/**MODAL HERE */}
                    <ModalCombustible
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrodCombustible}
                        currentDate={currentDate}
                        isEdit={isEditCP}
                        isOpen={isOpenCombustible}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={() => console.log("notify")}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => {
                            setCurrentEditRecordCombustible(null);
                            setIsOpenCombustible(false);
                        }} />
                    <ModalKmsReales
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrodKmsReales}
                        currentDate={currentDate}
                        isEdit={isEditCP}
                        isOpen={isOpenKmsReales}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={(id, kmsreales) => {
                            console.log("notify", `${id} ${kmsreales}`)
                            handlechangeKMSReales(id, kmsreales);
                        }}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => {
                            setCurrentEditRecordKmsReales(null);
                            setIsOpenKmsreales(false);
                        }} />
                    <ModalCargas
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrod}
                        currentDate={currentDate}
                        cargas={pendientes}
                        isEdit={isEdit}
                        isOpen={isOpenCargas}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={() => console.log("notify")}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setIsOpenCargas(false)} />

                    <ModalCreateEdit
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrod}
                        currentDate={currentDate}
                        isEdit={isEdit}
                        isOpen={isOpenCP}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={() => console.log("notify")}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setIsOpenCP(false)} />

                    <ModalEncuesta
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrod}
                        currentDate={currentDate}
                        isEdit={isEdit}
                        isOpen={openEncuesta}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={() => console.log("notify")}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setOpenEncuesta(false)} />

                    <ModalCartaPorteConfirm
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrodCP}
                        currentDate={currentDate}
                        isEdit={isEditCP}
                        isOpen={isOpenCPDialog}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={() => console.log("notify")}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setIsOpenCPDialog(false)} />
                    <ModalFacturar
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrod}
                        currentDate={currentDate}
                        isEdit={isEdit}
                        isOpen={isOpen}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        userobj={user ? user : null}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={async (data) => {
                            if (data && data == "Ok") {
                                refresh();
                            }

                        }}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => {
                            setIsOpen(false)
                            setCurrentEditRecord(null);
                        }} />
                </div>
            }
        </Fragment>
    );
}
export default IngresoContent;