import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
//import { addIngreso, updateIngreso, addDestino, getAllDestinosByUser } from '../../../controllers/ingresos';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { addUbicacion, updateUbicacion, deleteUbicacion } from '../../../controllers/ubicaciones';
import { updateUserUbicacionesTimeByUid } from '../../../controllers/usuarios';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from 'axios';
import InputMask from 'react-input-mask';

const ModalCreateEdit = (props) => {
    //constantes
    const CATALOGOS_TIPOS = ['Facturación por ventas', 'Otros ingresos'];
    const CATALOGO_METODOS_PAGO = ['Depósito', 'Efectivo', 'Cheque'];
    const CATALOGO_ESTADOS = [
        { estado: '', estado_short: '' },
        { estado: 'Aguascalientes', estado_short: 'AGU' },
        { estado: 'Baja California', estado_short: 'BCN' },
        { estado: 'Baja California Sur', estado_short: 'BCS' },
        { estado: 'Campeche', estado_short: 'CAM' },
        { estado: 'Chiapas', estado_short: 'CHP' },
        { estado: 'Chihuahua', estado_short: 'CHH' },
        { estado: 'Coahuila', estado_short: 'COA' },
        { estado: 'Colima', estado_short: 'COL' },
        { estado: 'Ciudad de México', estado_short: 'DIF' },
        { estado: 'Durango', estado_short: 'DUR' },
        { estado: 'Guanajuato', estado_short: 'GUA' },
        { estado: 'Guerrero', estado_short: 'GRO' },
        { estado: 'Hidalgo', estado_short: 'HID' },
        { estado: 'Jalisco', estado_short: 'JAL' },
        { estado: 'Estado de México', estado_short: 'MEX' },
        { estado: 'Michoacán', estado_short: 'MIC' },
        { estado: 'Morelos', estado_short: 'MOR' },
        { estado: 'Nayarit', estado_short: 'NAY' },
        { estado: 'Nuevo León', estado_short: 'NLE' },
        { estado: 'Oaxaca', estado_short: 'OAX' },
        { estado: 'Puebla', estado_short: 'PUE' },
        { estado: 'Querétaro', estado_short: 'QUE' },
        { estado: 'Quintana Roo', estado_short: 'ROO' },
        { estado: 'San Luis Potosí', estado_short: 'SLP' },
        { estado: 'Sinaloa', estado_short: 'SIN' },
        { estado: 'Sonora', estado_short: 'SON' },
        { estado: 'Tabasco', estado_short: 'TAB' },
        { estado: 'Tamaulipas', estado_short: 'TAM' },
        { estado: 'Tlaxcala', estado_short: 'TLA' },
        { estado: 'Veracruz', estado_short: 'VER' },
        { estado: 'Yucatán', estado_short: 'YUC' },
        { estado: 'Zacatecas', estado_short: 'ZAC' }
    ];
    const CATALOGO_ESTADOS_USA = [
        { estado: '', estado_short: '' },
        { estado: 'Alabama', estado_short: 'AL' },
        { estado: 'Alaska', estado_short: 'AK' },
        { estado: 'Arizona', estado_short: 'AZ' },
        { estado: 'Arkansas', estado_short: 'AR' },
        { estado: 'California', estado_short: 'CA' },
        { estado: 'Carolina del Norte', estado_short: 'NC' },
        { estado: 'Carolina del Sur', estado_short: 'SC' },
        { estado: 'Colorado', estado_short: 'CO' },
        { estado: 'Connecticut', estado_short: 'CT' },
        { estado: 'Dakota del Norte', estado_short: 'ND' },
        { estado: 'Dakota del Sur', estado_short: 'SD' },
        { estado: 'Delaware', estado_short: 'DE' },
        { estado: 'Florida', estado_short: 'FL' },
        { estado: 'Georgia', estado_short: 'GA' },
        { estado: 'Hawái', estado_short: 'HI' },
        { estado: 'Idaho', estado_short: 'ID' },
        { estado: 'Illinois', estado_short: 'IL' },
        { estado: 'Indiana', estado_short: 'IN' },
        { estado: 'Iowa', estado_short: 'IA' },
        { estado: 'Kansas', estado_short: 'KS' },
        { estado: 'Kentucky', estado_short: 'KY' },
        { estado: 'Luisiana', estado_short: 'LA' },
        { estado: 'Maine', estado_short: 'ME' },
        { estado: 'Maryland', estado_short: 'MD' },
        { estado: 'Massachusetts', estado_short: 'MA' },
        { estado: 'Míchigan', estado_short: 'MI' },
        { estado: 'Minnesota', estado_short: 'MN' },
        { estado: 'Misisipi', estado_short: 'MS' },
        { estado: 'Misuri', estado_short: 'MO' },
        { estado: 'Montana', estado_short: 'MT' },
        { estado: 'Nebraska', estado_short: 'NE' },
        { estado: 'Nevada', estado_short: 'NV' },
        { estado: 'Nueva Jersey', estado_short: 'NJ' },
        { estado: 'Nueva York', estado_short: 'NY' },
        { estado: 'Nuevo Hampshire', estado_short: 'NH' },
        { estado: 'Nuevo México', estado_short: 'NM' },
        { estado: 'Ohio', estado_short: 'OH' },
        { estado: 'Oklahoma', estado_short: 'OK' },
        { estado: 'Oregón', estado_short: 'OR' },
        { estado: 'Pensilvania', estado_short: 'PA' },
        { estado: 'Rhode Island', estado_short: 'RI' },
        { estado: 'Tennessee', estado_short: 'TN' },
        { estado: 'Texas', estado_short: 'TX' },
        { estado: 'Utah', estado_short: 'UT' },
        { estado: 'Vermont', estado_short: 'VT' },
        { estado: 'Virginia', estado_short: 'VA' },
        { estado: 'Virginia Occidental', estado_short: 'WV' },
        { estado: 'Washington', estado_short: 'WA' },
        { estado: 'Wisconsin', estado_short: 'WI' },
        { estado: 'Wyoming', estado_short: 'WY' },


    ];
    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);

    //estados propios del formulario
    const [active, setActive] = useState(true);
    const [nombre, setNombre] = useState("");

    const [internacional, setInternacional] = useState(false);
    const [estado, setEstado] = useState();
    const [estadoSat, setEstadoSat] = useState();
    const [estado_short, setEstadoShort] = useState();
    const [localidad, setLocalidad] = useState("");
    const [localidadSat, setLocalidadSat] = useState("");
    const [isLocalidadData, setIsLocalidadData] = useState(true);
    const [localidadesList, setLocalidadesList] = useState([]);
    const [municipio, setMunicipio] = useState("");
    const [municipioSat, setMunicipioSat] = useState("");
    const [calle, setCalle] = useState("");
    const [numero, setNumero] = useState("");
    const [numeroInterior, setNumeroInterior] = useState("");
    const [colonia, setColonia] = useState("");
    const [coloniaSat, setColoniaSat] = useState("");
    const [colonias, setColonias] = useState([]);
    const [cp, setCP] = useState("");

    const [telefono, setTelefono] = useState("");
    const [rfc, setRfc] = useState("");

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);
    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);


    const initValues = () => {

        if (isEdit && recordedit != null) {
            setNombre(recordedit.nombre || "");
            setActive(recordedit.active);

            setInternacional(recordedit.internacional);
            setEstadoShort(recordedit.estado_short || "");
            setEstado(recordedit.estado || "");
            setLocalidad(recordedit.localidad || "");
            setLocalidadSat(recordedit.localidadSat || "");
            setCalle(recordedit.calle || "");
            setNumero(recordedit.numero || "");
            setNumeroInterior(recordedit.numeroInterior || "");
            setCP(recordedit.cp || "");
            setColonia(recordedit.colonia || "");
            setColoniaSat(recordedit.coloniaSat || "");
            setColonias(recordedit.colonias || []);
            setMunicipioSat(recordedit.municipioSat || "");
            setMunicipio(recordedit.municipio || "");
            setTelefono(recordedit.telefono || "");

            setRfc(recordedit.rfc || "");

        } else {
            /**
             *  nombre,
            active,
            internacional,
            estado,
            estado_short,
            localidad,
            calle,
            numero,
            cp,
            telefono,
             */
            setIsLocalidadData(true);
            setLocalidadesList([]);
            setNombre("");
            setActive(true);
            setInternacional(false);
            setEstadoShort("");
            setEstado("");
            setLocalidad("");
            setLocalidadSat("");
            setCalle("");
            setNumero("");
            setNumeroInterior("");
            setCP("");
            setMunicipio("");
            setMunicipioSat("");
            setColonia("");
            setColonias([]);
            setColoniaSat("");
            setTelefono("");
            setRfc("");
        }
    }


    useEffect(() => {
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit) {
            initValues();
        }

    }, [recordedit]);


    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();
        //setIsOpen(false);
        let obj = {
            nombre:nombre.trim(),
            active,
            internacional,
            estado,
            estado_short,
            localidad: isLocalidadData ? localidad : municipio || "",
            localidadSat: isLocalidadData ? localidadSat || "" : "",
            municipio: municipio || "",
            municipioSat: municipioSat || "",
            calle,
            numero,
            numeroInterior,
            colonia: colonia || "",
            colonias,
            coloniaSat: coloniaSat || "",
            cp,
            telefono,
            rfc,
            uid,
        }

        if (isAdmin && adminCurrentUser) {
            obj.uid = adminCurrentUser;
        }

        //validaciones de formulario

        if (isEdit && recordedit.id) {
            // alert(recordedit.id);
            obj.useredit = uidAltaEdit || uid;
            setIsLoading(true);
            updateUbicacion(recordedit.id, obj).then(resp => {
                updateUserUbicacionesTimeByUid(uid);
                setIsLoading(false);
                setIsOpen(false);
                props.notify({ ...obj, id: recordedit.id });
                Swal.fire('Éxito', 'La ubicación se ha actualizado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                console.log(err);
                Swal.fire("Error", "Error de al actualizar la ubicación, comprueba tu conexión ", 'error');
            })
        } else if (!isEdit) {
            obj.useralta = uidAltaEdit || uid;
            setIsLoading(true);
            addUbicacion(obj).then(resp => {
                updateUserUbicacionesTimeByUid(uid);
                setIsLoading(false);
                setIsOpen(false);
                props.notify({ ...obj, id: resp.id });
                Swal.fire('Éxito', 'La ubicación se ha registrado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                console.log("error", err.toString());
                Swal.fire("Error", "Error de al registrar la ubicación, comprueba tu conexión ", 'error');
            })

        }

    }

    const changeEstadoMX = value => {
        let obj_estado = CATALOGO_ESTADOS.find(element => element.estado === value);
        if (obj_estado) {
            setEstado(obj_estado.estado);
            setEstadoShort(obj_estado.estado_short);
        }
    }
    const changeEstadoUSA = value => {
        let obj_estado = CATALOGO_ESTADOS_USA.find(element => element.estado === value);
        if (obj_estado) {
            setEstado(obj_estado.estado);
            setEstadoShort(obj_estado.estado_short);
        }
    }


    useEffect(() => {

        if (!internacional && cp.split('_').join('').length >= 5) {
            consultarInfoFromCP();
        }
    }, [cp]);
    useEffect(() => {
        if (!internacional) {
            const c = colonias.find(element => element.claveColonia === coloniaSat);
            if (c) {
                setColonia(c.colonia);
            }
        }
    }, [coloniaSat]);

    //ej 83377
    const consultarLocalidadesDeEstado = async (clveEstado) => {
        console.log("consultando de estado", clveEstado);
        await axios.get(`https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/localidades?estado=${clveEstado}`).then(resp => {
            // console.log("respuesta", resp.data);
            if (resp.data && resp.data.length > 0) {
                console.log(resp.data);
                setLocalidadesList([{ clave: "", nombre: "" }, ...resp.data.map(e => {
                    let o = {
                        clave: e.c_localidad,
                        nombre: e.nombre
                    }
                    return o;
                })])
            }
        }).catch(err => {
            console.log("error", err.toString());
        });
    }
    const consultarInfoFromCP = async () => {
        setIsLoading(true);
        //https://us-central1-transport-plus-98f66.cloudfunctions.net/app
        await axios.get(`https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/cpostal?cp=${cp}`).then(resp => {
            // console.log("respuesta", resp.data);
            if (resp.data && resp.data.length > 0) {
                setLocalidad(resp.data[0].localidad);
                setMunicipio(resp.data[0].municipio);
                setEstado(resp.data[0].estado);
                setEstadoShort(resp.data[0].claveEstado);
                setMunicipioSat(resp.data[0].claveMunicipio);
                if (resp.data[0].claveLocalidad && resp.data[0].claveLocalidad !== "") {
                    setIsLocalidadData(true);
                    setLocalidadSat(resp.data[0].claveLocalidad);
                } else {
                    setIsLocalidadData(false);
                    //  consultarLocalidadesDeEstado(resp.data[0].claveEstado);

                }

                let coloniasAux = resp.data.map(element => {
                    return { claveColonia: element.claveColonia, colonia: element.colonia }
                });
                setColonias([{ claveColonia: "", colonia: "" }, ...coloniasAux]);
                console.log("datos ", resp.data);
            }
            setIsLoading(false);


        }).catch(err => {
            setIsLoading(false);
            console.log("error", err.toString());
            if (err.toString().includes("Network Error")) {
                Swal.fire("Error", "Error de conexión, no fue posible conectar con el servidor, revisa tu conexión", 'error');
            } else {
                Swal.fire("Error", "No se pudo consultar", 'error');
            }
        });
    }
    /* useEffect(() => {
 
         if (estado_short !== '') {
             consutlarInfoEstado();
         }
     }, [estado_short]);
     useEffect(() => {
         if(localidadSat && localidadSat!==''){
             let objLocalidad=localidadesSat.find(element=>element.Value===localidadSat);
             if(objLocalidad){
                 setLocalidad(objLocalidad.Name);
             }
         }
        
     }, [localidadSat]);*/

    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Añadir Ubicación (Origen,Destino)" : `Editar ubicación `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP">Ubicación</p></div>
                            <div class="dataBox">
                                 
                                <div class="dataRow">
                                    <p className="fixedP">Nombre del Sitio<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <input type="text" minlength="2" name='cliente' value={nombre} onChange={e => setNombre(e.target.value)} />
                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">País</p>
                                    <div class="fieldInput">
                                        <select name='segmento' onChange={(e) => setInternacional(e.target.value == "1")} value={internacional ? "1" : "2"} required >
                                            <option key={'simex'} value={"1"} >USA</option>
                                            <option key={'nousa'} value={"2"}>MEX</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">C.P.</p>
                                    <div class="fieldInput">
                                        <InputMask style={{ width: '90%' }} mask="99999" maskChar="_" value={cp} onChange={e => setCP(e.target.value)} />
                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">Estado</p>
                                    <div class="fieldInput">
                                        {
                                            internacional ?
                                                <select name='tipo' onChange={(e) => changeEstadoUSA(e.target.value)} value={estado} required >
                                                    {
                                                        CATALOGO_ESTADOS_USA.map((item, index) => {
                                                            return <option key={index} value={item.estado}>{item.estado}</option>
                                                        })
                                                    }
                                                </select> :
                                                <input type="text" disabled value={estado} onChange={(e) => console.log(e.target.value)} />
                                        }
                                    </div>
                                </div>
                                {!internacional && <div class="dataRow">
                                    <p className="fixedP">Municipio<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <input type="text" disabled value={municipio} onChange={(e) => console.log(e.target.value)} />
                                    </div>
                                </div>
                                }
                                <div class="dataRow">
                                    <p className="fixedP">Localidad<label className="requiredlabel">*</label></p>

                                    {internacional ?
                                        <div class="fieldInput"> <input type="text" minlength="1" name='localidad' value={localidad} onChange={e => setLocalidad(e.target.value)} /> </div>
                                        : <div class="fieldInput">
                                            {isLocalidadData ? <input type="text" disabled value={localidad} onChange={(e) => console.log(e.target.value)} /> : <div></div>


                                            }</div>
                                    }

                                </div>
                            </div>
                        </div>
                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP"> Dirección</p></div>
                            <div class="dataBox">
                                {!internacional && <div class="dataRow">
                                    <p className="fixedP">Colonia<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <select name='tipo' onChange={(e) => setColoniaSat(e.target.value)} value={coloniaSat} required >
                                            {
                                                colonias.map((item, index) => {
                                                    return <option key={index} value={item.claveColonia}>{item.colonia}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>}

                                <div class="dataRow">
                                    {internacional ? <p className="fixedP">Dirección<label className="requiredlabel">*</label></p> : <p className="fixedP">Calle<label className="requiredlabel">*</label></p>}
                                    <div class="fieldInput">
                                        <input required type="text" minlength="1" name='cliente' value={calle} onChange={e => setCalle(e.target.value)} />
                                    </div>
                                </div>

                                {!internacional && <div class="dataRow">
                                    <p className="fixedP">Número.Ext<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <input required type="text" name='cliente' value={numero} onChange={e => setNumero(e.target.value)} />
                                    </div>
                                </div>}
                                {!internacional && <div class="dataRow">
                                    <p className="fixedP">Número.Int</p>
                                    <div class="fieldInput">
                                        <input type="text" name='cliente' value={numeroInterior} onChange={e => setNumeroInterior(e.target.value)} />
                                    </div>
                                </div>}

                                <div class="dataRow">
                                    <p className="fixedP">Teléfono<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                    <input required type="phone" style={{width:'90%'}} name='cliente' value={telefono} onChange={e => setTelefono(e.target.value)} />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Rfc/TAXID<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                    <input type="text" minlength="1" name='cliente' value={rfc} onChange={e => setRfc(e.target.value)} />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Estatus</p>
                                    <div class="fieldInput">
                                        <select name='segmento' onChange={(e) => setActive(e.target.value == "1")} value={active ? "1" : "2"} required >
                                            <option key={'sistatus'} value={"1"} >Activo</option>
                                            <option key={'nostatus'} value={"2"}>Inactivo</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">


                    </div>
                    <div className="row">
                        {/*<div className="form-group col-4">
                            <label>Teléfono</label>
                            <PhoneInput
                                onlyCountries={['mx', 'us']}
                                country={'mx'}
                                countryCodeEditable={false}
                                value={telefono}
                                onChange={phone => setTelefono(phone)}
                            />   </div>*/}
                      
                       
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalCreateEdit;