import React, { useEffect, useState } from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import { useParams, useLocation } from 'react-router-dom';
import { getViajeById } from '../../controllers/viajes';
import { getClienteByID } from '../../controllers/clientes';
import { getUbicacionesById } from '../../controllers/ubicaciones';
import { getUnidadByID, getRemolquesByNombreAndUid } from '../../controllers/unidades';
import { getUserByUid } from '../../controllers/usuarios';
import { getOperadorById } from '../../controllers/operadores';
import moment from 'moment';
import qrcode from 'qrcode.react';
import queryString from 'query-string';
import xml2js from 'xml2js';
import { NumeroALetras } from './numaletras';
import { parseBooleans } from 'xml2js/lib/processors';
import zIndex from '@material-ui/core/styles/zIndex';
import { matchPath } from 'react-router-dom/cjs/react-router-dom.min';
import { firebase } from '../../configs/firebase';
import { getUidMaster, checkMaster } from '../../session/session_controller';
import Contrato1 from './contrato1';
//var QRCode = require('qrcode.react');
// Create styles
const requiereDescargaDirecta = ['oQ2vlHtaUfU3cOYeLTpBdFIH7QH2'];
const requiereTituloCARTAPORTE = ['GURJ810628GYA'];
const requiereContrato1 = ['HptPbvVxYYUjUzp7Nt3TtmyAt0x1'];

Font.registerHyphenationCallback(word => [word]);

const CAT_UNIDADES = [
    {
        "codigo": "ACT",
        "descripcion": "Actividad"
    },
    {
        "codigo": "KT",
        "descripcion": "Bolsa"
    },
    {
        "codigo": "HEA",
        "descripcion": "CABEZA"
    },
    {
        "codigo": "XBX",
        "descripcion": "CAJA"
    },
    {
        "codigo": "DAY",
        "descripcion": "DIA"
    },
    {
        "codigo": "DPC",
        "descripcion": "DOCENA"
    },
    {
        "codigo": "GLL",
        "descripcion": "GALON"
    },
    {
        "codigo": "GRM",
        "descripcion": "GRS"
    },
    {
        "codigo": "HUR",
        "descripcion": "HORA"
    },
    {
        "codigo": "KT",
        "descripcion": "JUEGO"
    },
    {
        "codigo": "XPJ",
        "descripcion": "Canastilla"
    },
    {
        "codigo": "KGM",
        "descripcion": "KG"
    },
    {
        "codigo": "LTR",
        "descripcion": "LITRO"
    },
    {
        "codigo": "LO",
        "descripcion": "LOTE"
    },
    {
        "codigo": "MTK",
        "descripcion": "M2"
    },
    {
        "codigo": "MTQ",
        "descripcion": "M3"
    },
    {
        "codigo": "MTR",
        "descripcion": "METRO"
    },
    {
        "codigo": "LM",
        "descripcion": "METRO LINEAL"
    },
    {
        "codigo": "A9",
        "descripcion": "No Aplica"
    },
    {
        "codigo": "PR",
        "descripcion": "PAR"
    },
    {
        "codigo": "H87",
        "descripcion": "PIEZA"
    },
    {
        "codigo": "C9",
        "descripcion": "ROLLO"
    },
    {
        "codigo": "E48",
        "descripcion": "SERVICIO"
    },
    {
        "codigo": "TNE",
        "descripcion": "TONS"
    },
    {
        "codigo": "LN",
        "descripcion": "TRAMO"
    },
    {
        "codigo": "C62",
        "descripcion": "Unidad"
    },
    {
        "codigo": "XME",
        "descripcion": "Contenedor de metal"
    },
    {
        "codigo": "LBR",
        "descripcion": "Libra"
    },
    {
        "codigo": "M91",
        "descripcion": "Libra por libra"
    },
    {
        "codigo": "FOT",
        "descripcion": "Pie"
    },
    {
        "codigo": "X8A",
        "descripcion": "PALLET DE MADERA"
    },
    {
        "codigo": "XUN",
        "descripcion": "Unidad"
    },
    {
        "codigo": "XKI",
        "descripcion": "Kit"
    }, {
        "codigo": "CG",
        "descripcion": "Tarjeta"
    },
    {
        "codigo": "MIL",
        "descripcion": "MIL"
    }

];
const CAT_TIPOMATERIA = [
    { "cve": '01', "descripcion": 'Materia Prima' },
    { "cve": '02', "descripcion": 'Materia Procesada' },
    { "cve": '03', "descripcion": 'Materia Terminada' },
    { "cve": '04', "descripcion": 'Materia para Industria Manufacturera' },
    { "cve": '05', "descripcion": 'Otro' },
];
const CAT_TIPORELACION = [
    { value: '', descripcion: '' },
    { value: '01', descripcion: 'Notas de Crédito de Documentos Relacionados' },
    { value: '02', descripcion: 'Notas de Débito de los Documentos Relacionados' },
    { value: '03', descripcion: 'Devolución de Mercancías sobre Facturas o Traslados Previos' },
    { value: '04', descripcion: 'Sustitución de los CFDI Previos' },
    { value: '05', descripcion: 'Traslados de Mercancías Facturados Previamente' },
    { value: '06', descripcion: 'Factura Generada por los Traslados Previos' },
    { value: '07', descripcion: 'CFDI por Aplicación de Anticipo' },
    { value: '08', descripcion: 'Facturas Generadas por Pagos en Parcialidades' },
    { value: '09', descripcion: 'Factura Generada por Pagos Diferidos' }
];
const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        marginTop: 15,
        paddingBottom: '20px',
        marginLeft: 15,
        paddingRight: 15,

        // backgroundColor: '#E4E4E4'
    },
    sectionHeader: {
        width: '98%',
        flexDirection: 'row',
    },
    sectionLogo: {
        margin: 0,
        padding: '0',
        width: '13%',
        // border: '1px solid black',
        flexGrow: 1
    },
    sectionUserInfo: {
        width: '45%',
        margin: 2,
        padding: 2,
        flexGrow: 2
    },
    sectionCPinfo: {
        margin: 2,
        width: '30%',
        padding: 0,
        //border: '1px solid black',
        flexGrow: 1
    },
    sectionFolioTopSmall: {
        // margin: 10,
        width: '9.11%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTopCompenzaSmall: {
        // margin: 10,
        width: '13.22%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTopBigDetail: {
        // margin: 10,
        width: '36.22%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTop: {
        // margin: 10,
        width: '11.11%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTopMedium: {
        // margin: 10,
        width: '18.5vw',
        height: "35px",
        borderRight: '1px solid black',
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
    },
    sectionFolioTopMediumNoBorder: {
        // margin: 10,
        width: '18.5vw',
        height: "35px",
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
    },
    sectionSellosBottomFirst: {
        // margin: 10,
        marginTop: '0px',
        width: 'auto',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionSellosBottom: {
        // margin: 10,
        width: 'auto',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionComentariosBottom: {
        // margin: 10,
        width: '95vw',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionSellosBottomLast: {
        // margin: 10,
        width: 'auto',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionClientePaga: {
        // margin: 10,
        width: '95vw',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionClientePagaHeader: {
        width: '100%',
        height: "auto",
        borderBottom: '1px solid black',
        //   flexGrow: 1
    },

    sectionCol: {
        margin: 0,
        width: '10vw',
        height: "140px",
        border: '1px solid black',

        // flexGrow: 1
    },
    sectionColMiddle: {
        margin: 0,
        width: '13vw',
        height: "140px",
        borderRight: '1px solid black',
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        // flexGrow: 1
    },
    sectionColMiddleX: {
        margin: 0,
        width: '33vw',
        height: "140px",
        borderRight: '1px solid black',
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        // flexGrow: 1
    },
    sectionOrigenDestino: {
        marginRight: 5,
        width: '47vw',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionLabelInfo: {
        width: '14%',
        height: "auto",
        textAlign: 'right',
        backgroundColor: "#d9d9d9",
        color: "black"
    },
    sectionLabelInfosmall: {
        width: '29%',
        height: "auto",
        textAlign: 'right',
        backgroundColor: "#d9d9d9",
        color: "black"
    },
    sectionInfoSmall: {
        width: '71%',
        marginLeft: '5px',
        height: "auto",
        textAlign: 'left',
        color: "black"
    },
    sectionInfoNoventa: {
        width: '85%',
        marginLeft: '5px',
        height: "auto",
        textAlign: 'left',
        color: "black"
    },
    sectionInfoCuarenta: {
        width: '35%',
        marginLeft: '5px',
        height: "auto",
        textAlign: 'left',
        color: "black"
    },
    sectionClientePagaHeader: {
        width: '100%',
        height: "auto",
        borderBottom: '1px solid black',
        backgroundColor: "#d9d9d8",
        color: "#000"
        //   flexGrow: 1
    },
    sectionClientePagaHeader2: {
        width: '100%',
        height: "auto",
        borderBottom: '1px solid black',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: "#d9d9d8",
        color: "#000"
        //   flexGrow: 1
    },
    sectionbajoconceptoizquierda: {
        margin: "0px",
        width: '69vw',
        height: "55px",
        //  border: '1px solid black',
    },
    sectionbajoconceptoderecha: {
        margin: 0,
        width: '26vw',
        height: "50px",
        borderRight: '1px solid black',
        borderLeft: '1px solid black',
        borderBottom: '1px solid black',
    },
    docutitulo: {
        margin: 5,
        fontSize: 10,
        fontWeight: 900,
        width: '10vw',
        height: "auto",
    },
    docutexto: {
        margin: 5,
        fontSize: 9,
        width: '80vw',
        height: "auto",
    }
});
const CartaPorte = props => {
    const CATALOGO_REGIMENESFISCALES = [
        {
            "codigo": "",
            "descripcion": "",
            "campo1": "Sí",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "622",
            "descripcion": "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
            "campo1": "Sí",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "606",
            "descripcion": "Arrendamiento",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "609",
            "descripcion": "Consolidación",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "624",
            "descripcion": "Coordinados",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "629",
            "descripcion": "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "608",
            "descripcion": "Demás ingresos",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "630",
            "descripcion": "Enajenación de acciones en bolsa de valores",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "601",
            "descripcion": "General de Ley Personas Morales",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "628",
            "descripcion": "Hidrocarburos",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "621",
            "descripcion": "Incorporación Fiscal",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "611",
            "descripcion": "Ingresos por Dividendos (socios y accionistas)",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "614",
            "descripcion": "Ingresos por intereses",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "623",
            "descripcion": "Opcional para Grupos de Sociedades",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "612",
            "descripcion": "Personas Físicas con Actividades Empresariales y Profesionales",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "603",
            "descripcion": "Personas Morales con Fines no Lucrativos",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "607",
            "descripcion": "Régimen de Enajenación o Adquisición de Bienes",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "625",
            "descripcion": "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "615",
            "descripcion": "Régimen de los ingresos por obtención de premios",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "610",
            "descripcion": "Residentes en el Extranjero sin Establecimiento Permanente en México",
            "campo1": "Sí",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "616",
            "descripcion": "Sin obligaciones fiscales",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "620",
            "descripcion": "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "605",
            "descripcion": "Sueldos y Salarios e Ingresos Asimilados a Salarios",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "626",
            "descripcion": "Régimen Simplificado de Confianza",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        }
    ];
    const CAT_METODOSPAGO = [
        {
            "Name": "Pago en parcialidades ó diferido",
            "Value": "PPD"
        },
        {
            "Name": "Pago en una sola exhibición",
            "Value": "PUE"
        }
    ];
    const CAT_USOCFDI = [
        {
            "Natural": true,
            "Moral": false,
            "Name": "Sin efectos fiscales.",
            "Value": "S01"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Honorarios médicos, dentales y gastos hospitalarios.",
            "Value": "D01"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos médicos por incapacidad o discapacidad",
            "Value": "D02"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos funerales.",
            "Value": "D03"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Donativos.",
            "Value": "D04"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
            "Value": "D05"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Aportaciones voluntarias al SAR.",
            "Value": "D06"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Primas por seguros de gastos médicos.",
            "Value": "D07"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos de transportación escolar obligatoria.",
            "Value": "D08"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
            "Value": "D09"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Pagos por servicios educativos (colegiaturas)",
            "Value": "D10"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Adquisición de mercancias",
            "Value": "G01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Devoluciones, descuentos o bonificaciones",
            "Value": "G02"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Gastos en general",
            "Value": "G03"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Construcciones",
            "Value": "I01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Mobilario y equipo de oficina por inversiones",
            "Value": "I02"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Equipo de transporte",
            "Value": "I03"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Equipo de computo y accesorios",
            "Value": "I04"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Dados, troqueles, moldes, matrices y herramental",
            "Value": "I05"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Comunicaciones telefónicas",
            "Value": "I06"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Comunicaciones satelitales",
            "Value": "I07"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Otra maquinaria y equipo",
            "Value": "I08"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Por definir",
            "Value": "P01"
        }
    ]
    const CAT_FORMASPAGO = [
        {
            "Name": "Efectivo",
            "Value": "01"
        },
        {
            "Name": "Cheque nominativo",
            "Value": "02"
        },
        {
            "Name": "Transferencia electrónica de fondos",
            "Value": "03"
        },
        {
            "Name": "Tarjeta de crédito",
            "Value": "04"
        },
        {
            "Name": "Monedero electrónico",
            "Value": "05"
        },
        {
            "Name": "Dinero electrónico",
            "Value": "06"
        },
        {
            "Name": "Vales de despensa",
            "Value": "08"
        },
        {
            "Name": "Dación en pago",
            "Value": "12"
        },
        {
            "Name": "Pago por subrogación",
            "Value": "13"
        },
        {
            "Name": "Pago por consignación",
            "Value": "14"
        },
        {
            "Name": "Condonación",
            "Value": "15"
        },
        {
            "Name": "Compensación",
            "Value": "17"
        },
        {
            "Name": "Novación",
            "Value": "23"
        },
        {
            "Name": "Confusión",
            "Value": "24"
        },
        {
            "Name": "Remisión de deuda",
            "Value": "25"
        },
        {
            "Name": "Prescripción o caducidad",
            "Value": "26"
        },
        {
            "Name": "A satisfacción del acreedor",
            "Value": "27"
        },
        {
            "Name": "Tarjeta de débito",
            "Value": "28"
        },
        {
            "Name": "Tarjeta de servicios",
            "Value": "29"
        },
        {
            "Name": "Aplicación de anticipos",
            "Value": "30"
        },
        {
            "Name": "Intermediarios",
            "Value": "31"
        },
        {
            "Name": "Por definir",
            "Value": "99"
        }
    ]
    const data = props.objdata;
    const reqcontrato1 = props.contrato1;
    console.log(data.idDeCCP);

    let dateIn = data.datos_viaje.fechaDestino.toDate();
    let dateOut = data.datos_viaje.fechaOrigen.toDate();
    if (data && data.datatimbrado && data.datatimbrado.FechaTimbrado) {
        let datex = new Date(data.datatimbrado.FechaTimbrado);
        // dateIn.setHours(datex.getHours(), datex.getMinutes());
        // dateOut.setHours(datex.getHours(), datex.getMinutes());
    }

    let fechaEntrega = moment(dateIn).format('DD/MM/YYYY HH:mm');
    let fechaOrigen = moment(dateOut).format('DD/MM/YYYY HH:mm');

    let datacliente = data.clienteinfo;
    let dataviaje = data.datos_viaje;
    let datacp = data.carta_porte;
    let origeninfo = data.origeninfo;
    let destinoinfo = data.destinoinfo;
    let usuarioInfo = data.usuarioInfo;
    let unidadinfo = data.unidadinfo;
    let operadorinfo = data.operadorinfo;
    let paradasinfo = data.paradas;

    let remolqueString = "";
    let comentarios = data.detalles.comentarios;
    if (data.carta_porte) {
        if (data.carta_porte.remolque1 !== "") {
            remolqueString += `${data.carta_porte.messageRemolque1}:${data.carta_porte.remolque1} `;
        }
        if (data.carta_porte.remolque2 !== "") {
            remolqueString += `${data.carta_porte.messageRemolque2}:${data.carta_porte.remolque2} `;
        }
        if (data.carta_porte.remolques) {

        }

    }


    if (!datacliente) {
        datacliente = {};
    }
    if (!origeninfo) {
        origeninfo = {};
    }
    if (!destinoinfo) {
        destinoinfo = {};
    }
    if (!usuarioInfo) {
        //usuarioInfo = { razon: "NORAZON" };
    }

    let datatimbre = {
        TaxStamp: {}
    };
    if (data.facturamaCP) {
        datatimbre = data.facturamaCP;
    }
    let cpFactOBJ = null;
    if (data.facuturado) {
        cpFactOBJ = data.facuturado;

        let xmlData = data.facuturado.timbreXML;



    }
    const [credenciales, setCredenciales] = useState(usuarioInfo.credenciales || null);

    const [logotipo, setLogotipo] = useState(usuarioInfo.credenciales.logotipo ? usuarioInfo.credenciales.logotipo : usuarioInfo.logotipo || null);

    const [referencia, setReferencia] = useState(data.general.referencia || "");
    const [claseViaje, setClaseViaje] = useState(data.datos_viaje.claseViaje || "");
    const [ncarta, setNcarta] = useState(data.general.ncarta || "-");
    const [nviaje, setNviaje] = useState(data.general.nviaje || "-");
    const [unidad, setUnidad] = useState(data.detalles.unidad || "-");

    const [versionCCP, setVersionCCP] = useState(data.versionCCP || "2.0");
    const [idCCP, setIDCCP] = useState(data.datatimbrado.idDeCCP || "--");
    const [NumTotalMercancias, setNumtotalMercancias] = useState(data.datatimbrado.NumTotalMercancias || "-");
    const [PesoBrutoTotal, setPesoBrutoTotal] = useState(data.datatimbrado.PesoBrutoTotal || "-");
    const [regimenAduanero, setRegimenAduanero] = useState(data.datatimbrado.regimenAduanero || "-");

    const [unidadplacas, setUnidadplacas] = useState(unidadinfo ? unidadinfo.placas : "");
    const [unidadyear, setUnidadYear] = useState(unidadinfo ? unidadinfo.year : "");
    const [aseguradora, setaseguradora] = useState(unidadinfo ? unidadinfo.aseguradora : "");
    const [aseguradoraMedioAmbiente, setaseguradoraMedioAmbiente] = useState(unidadinfo ? unidadinfo.aseguradoraMedioAmbiente : "");
    const [poliza, setpoliza] = useState(unidadinfo ? unidadinfo.poliza : "");
    const [polizaMedioAmbiente, setpolizaMedioAmbiente] = useState(unidadinfo ? unidadinfo.polizaMedioAmbiente : "");
    const [tipoSct, settipoSct] = useState(unidadinfo ? unidadinfo.tipoSct : "");
    const [tipoUnidad, settipoUnidad] = useState(unidadinfo ? unidadinfo.tipoUnidad : "");
    const [sct, setsct] = useState(unidadinfo ? unidadinfo.sct : "");

    const [operadorlicencia, setOperadorLicencia] = useState(operadorinfo ? `${operadorinfo.tipoLicencia}-${operadorinfo.noLicencia}` : "");
    const [operadornombre, setOperadorNombre] = useState(operadorinfo ? `${operadorinfo.nombre} ${operadorinfo.apellidos}` : "");
    const [operadorrfc, setOperadorRFC] = useState(operadorinfo ? `${operadorinfo.rfc}` : "");
    const [operadorDireccion, setOperadorDireccion] = useState(operadorinfo ? `${operadorinfo.calle || ""} ${operadorinfo.numero || ""},${operadorinfo.colonia || ""} ${operadorinfo.localidad || ""} ${operadorinfo.cp || ""} ${operadorinfo.internacional ? "USA" : "México"}` : "");

    const [cantidad, setCantidad] = useState(data.detalles.cantidad || "");
    const [descripcion, setDescripcion] = useState(data.detalles.descripcion || "");

    const [folioFiscal, setFolioFiscal] = useState(cpFactOBJ ? cpFactOBJ.uuid : "-");
    const [serieSat, setSerieSat] = useState(data.datatimbrado ? data.datatimbrado.NoCertificadoSAT : "-");
    const [fechaTimbrado, setFechaTimbrado] = useState(data.datatimbrado ? data.datatimbrado.FechaTimbrado : "-");
    const [fechaCer, setFechaCer] = useState(cpFactOBJ ? cpFactOBJ.fecha : "-");

    console.log("data.datatimbrado.MetodoPago", data.datatimbrado.MetodoPago);
    const [metodoPago, setMetodoPago] = useState(data.datatimbrado ? `${data.datatimbrado.MetodoPago} - ${CAT_METODOSPAGO.find(e => e.Value === data.datatimbrado.MetodoPago).Name}` : "-");
    const [formaPago, setFormaPago] = useState(data.datatimbrado ? `${data.datatimbrado.FormaPago} - ${CAT_FORMASPAGO.find(e => e.Value === data.datatimbrado.FormaPago).Name}` : "-");
    const [condicionesPago, setCondicionesPago] = useState(data.datatimbrado ? data.datatimbrado.CondicionesDePago : "")
    const [usoCfdi, setUsoCfdi] = useState(data.datatimbrado ? `${data.datatimbrado.UsoCFDI} - ${CAT_USOCFDI.find(e => e.Value === data.datatimbrado.UsoCFDI) ? CAT_USOCFDI.find(e => e.Value === data.datatimbrado.UsoCFDI).Name : ""}` : "-");
    const [moneda, setMoneda] = useState(data.datatimbrado ? data.datatimbrado.Moneda : "-");
    const [tipoCambio, setTipoCambio] = useState(data.datatimbrado ? data.datatimbrado.TipoCambio : "-");

    const [regimenFiscalReceptor, setRegimenFiscalReceptor] = useState(data.datatimbrado ? data.datatimbrado.RegimenFiscalReceptor != "" ? `${data.datatimbrado.RegimenFiscalReceptor} - ${CATALOGO_REGIMENESFISCALES.find(e => e.codigo === data.datatimbrado.RegimenFiscalReceptor) ? CATALOGO_REGIMENESFISCALES.find(e => e.codigo === data.datatimbrado.RegimenFiscalReceptor).descripcion : ""}` : "" : "");
    const [regimenFiscalEmisor, setRegimenFiscalEmisor] = useState(data.datatimbrado ? data.datatimbrado.RegimenFiscalEmisor != "" ? `${data.datatimbrado.RegimenFiscalEmisor} - ${CATALOGO_REGIMENESFISCALES.find(e => e.codigo === data.datatimbrado.RegimenFiscalEmisor) ? CATALOGO_REGIMENESFISCALES.find(e => e.codigo === data.datatimbrado.RegimenFiscalEmisor).descripcion : ""}` : "" : "");


    const [subtotal, setsubtotal] = useState(data.datatimbrado ? data.datatimbrado.SubTotal : ".00");
    const [total, settotal] = useState(data.datatimbrado ? data.datatimbrado.Total : ".00");

    const [serieEmisor, setSerieEmisor] = useState(data.datatimbrado ? data.datatimbrado.NoCertificado : "-");

    const [sellocfdi, setSelloCfdi] = useState(cpFactOBJ ? cpFactOBJ.selloCFD : "-");
    const [sellosat, setSelloSat] = useState(cpFactOBJ ? cpFactOBJ.selloSAT : "-");
    const [cadenaOriginal, setCadenaOriginal] = useState(cpFactOBJ ? cpFactOBJ.cadenaOriginal : "-");


    const [operador, setOperador1] = useState(data.detalles.operador1 || "-");
    const [isInternational, setIsInternacional] = useState(data.isInternational);

    const [kms, setKms] = useState(data.carta_porte.distBase_km);

    const [fecha, setFecha] = useState(moment(data.createAt.toDate()).format('DD/MM/YYYY') || "-");
    const [clientenombre, setClienteNombre] = useState(datacliente.nombre || "-");
    const [clientenumero, setClienteNumero] = useState(datacliente.numero || "");
    const [clientecalle, setClientecalle] = useState(datacliente.calle || "");
    let auxcolonia = datacliente.colonia;
    if (auxcolonia === "" && datacliente.coloniaSat && datacliente.colonias) {
        let colfinded = datacliente.colonias.find(col => col.claveColonia === datacliente.coloniaSat);
        if (colfinded) {
            auxcolonia = colfinded.colonia || "";
        }
    }
    const [clientecolonia, setClientecolonia] = useState(auxcolonia || "");
    const [clientelocalidad, setClienteLocalidad] = useState(datacliente.localidad || "");
    const [clientecp, setClientecp] = useState(datacliente.cp || "");
    const [clienterfc, setClienterfc] = useState(datacliente.rfc || "XEXX010101000");
    const [clientestado, setClienteestado] = useState(datacliente.estado || "");

    const [origenInternacional, setOrigenInternaciona] = useState(origeninfo.internacional);
    const [origenlocation, setOrigenlocation] = useState(origeninfo.nombre || "");
    const [origenciudad, setOrigenCiudad] = useState(origeninfo.localidad || "");
    const [origenestado, setOrigenEstado] = useState(origeninfo.estado || "");
    const [origenumero, setOrigennumero] = useState(origeninfo.numero || "");
    const [origenumeroInteior, setOrigennumeroInterior] = useState(origeninfo.numeroInterior || "");
    const [origencalle, setOrigencalle] = useState(origeninfo.calle || "");
    const [origencolonia, setOrigencolonia] = useState(origeninfo.colonia || "");
    const [origencp, setOrigencp] = useState(origeninfo.cp || "");
    const [origenrfc, setOrigenrfc] = useState(origeninfo.rfc || "");
    const [origennombre, setOrigennombre] = useState(origeninfo.nombre || "");

    const [destinoInternacional, setDestinoInternaciona] = useState(destinoinfo.internacional);
    const [destinolocation, setDestinolocation] = useState(destinoinfo.nombre || "");
    const [destinociudad, setDestinoCiudad] = useState(destinoinfo.localidad || "");
    const [destinoestado, setDestinoEstado] = useState(destinoinfo.estado || "");
    const [destinonumero, setDestinonumero] = useState(destinoinfo.numero || "");
    const [destinonumeroInteior, setDestinonumeroInterior] = useState(destinoinfo.numeroInterior || "");
    const [destinocalle, setDestinocalle] = useState(destinoinfo.calle || "");
    const [destinocolonia, setDestinocolonia] = useState(destinoinfo.colonia || "");
    const [destinocp, setDestinocp] = useState(destinoinfo.cp || "");
    const [destinorfc, setDestinoRfc] = useState(destinoinfo.rfc || "");
    const [destinnombre, setDestinonombre] = useState(destinoinfo.nombre || "");

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    let sellosfixed = ``;
    if (data.datos_viaje.noSello && data.datos_viaje.noSello !== "") {
        sellosfixed = data.datos_viaje.noSello;
    }
    if (data.datos_viaje.noSello2 && data.datos_viaje.noSello2 !== "") {
        sellosfixed += " " + data.datos_viaje.noSello2;
    }

    const [sellos, setSellos] = useState(sellosfixed || "");

    const [datosTimbre, setDatosTimbre] = useState(data.facuturado || null);

    let pedis = ""
    data.detalles.pedimentos.forEach(element => {
        let e = element.split(" ").join("");
        pedis += e + " ";
    })
    pedis = pedis.trim();
    const [pedimentos, setPedimentos] = useState(pedis || "");
    const [tiposervicio, setTiposervicio] = useState(data.datos_viaje.tipo === "Cruce" ? "Local (-100 kms)" : data.datos_viaje.tipo || "");
    const [mercancias, setMercancias] = useState(data.mercancias || []);
    const [paradas, setParadas] = useState(data.paradas || []);
    const [extrasDentroFlete, setExtrasDentroFlete] = useState(data.extrasDentroFlete || []);
    const [pesoBruto, setPesoBruto] = useState(data.pesoBruto || 0);
    const [conceptos, setConceptos] = useState(data.datatimbrado.Conceptos || []);
    const [impuestos, setImpuestos] = useState(data.datatimbrado.Impuestos || []);

    const [relacionados, setRelacionados] = useState(data.datatimbrado.relacionados || null);

    const [razon, setRazon] = useState(usuarioInfo.credenciales.Razon || "");
    const [rfcuser, setRfcUser] = useState(usuarioInfo.credenciales.Rfc || "");
    let direcccionfixed = `${usuarioInfo.credenciales.direccion.calle} ${usuarioInfo.credenciales.direccion.numero} Colonia ${usuarioInfo.credenciales.direccion.colonia} CP ${usuarioInfo.credenciales.direccion.cp} ${usuarioInfo.credenciales.direccion.localidad} ${usuarioInfo.credenciales.direccion.estado}`;
    const [dirCalle, setDirCalle] = useState(usuarioInfo.credenciales.direccion.calle || " ");
    const [dirNumero, setDirNumero] = useState(usuarioInfo.credenciales.direccion.numero || " ");
    const [dirColonia, setDirColonia] = useState(usuarioInfo.credenciales.direccion.colonia || " ");
    const [dirCp, setDirCp] = useState(usuarioInfo.credenciales.direccion.cp || " ");
    const [dirLocalidad, setDirLocalidad] = useState(usuarioInfo.credenciales.direccion.localidad || " ");
    const [dirEstado, setDirEstado] = useState(usuarioInfo.credenciales.direccion.estado || " ");

    const [nombreRemolque1, setNombreremolque1] = useState(data.remolque1 ? data.remolque1.nombre : "");
    const [nombreRemolque2, setNombreremolque2] = useState(data.remolque2 ? data.remolque2.nombre : "");
    const [placasRemolque1, setPlacasremolque1] = useState(data.remolque1 ? data.remolque1.placas : "");
    const [placasRemolque2, setPlacasremolque2] = useState(data.remolque2 ? data.remolque2.placas : "");

    const [direccioncp, setdireccioncp] = useState(direcccionfixed || "--");
    console.log("seting showtarifa", data.showTarifa);
    const [showTarifa, setShowTarifa] = useState(data.showTarifa);
    const [tarifa, setTarifa] = useState(data.detalles.tarifaFloat);

    //let cadenaqr="https://verificacfdi.facturaelectronica.sat.gob.mx/default.aspx?id=abc\&re="+rfcuser+"\&rr="+clienterfc+"\&tt=0.000000\&fe=SeQDTA==";
    const [dataqr, setDataQR] = useState(`${data.id}` || "abc");

    const requiereAjusteLetraRazonSocial = [{ razon: 'JORGE LUIS MARTINEZ VENEGAS', fontSize: '13px' }];

    useEffect(() => {
        // console.log("props", props);
    }, []);

    const formatoMoneda = (valor) => {



        let fixtostring = "" + valor;
        let fixtofloat = parseFloat(fixtostring);
        let toMoney = '$' + fixtofloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        if (!showTarifa) {
            toMoney = "$0.00"
        }
        return toMoney;

    }
    return <Document key={`document01`}>
        <Page key={`page01`} size="A4" style={styles.page}>
            <View style={styles.sectionHeader}>
                <View style={styles.sectionLogo}>
                    <Image style={{ width: '100%', height: "auto", marginTop: '10px' }} src={`data:image/png;base64, ${logotipo || ""}`}></Image>
                </View>
                <View style={styles.sectionUserInfo}>
                    {requiereAjusteLetraRazonSocial.find(rf => rf.razon === razon) ?
                        <Text style={{ fontSize: requiereAjusteLetraRazonSocial.find(rf => rf.razon === razon).fontSize, fontWeight: 'bold', whiteSpace: 'nowrap', overflowWrap: 'keep-all' }}>{razon}</Text> :
                        <Text style={{ fontSize: '10px', fontWeight: 'bold', whiteSpace: 'nowrap', overflowWrap: 'keep-all' }}>{razon}</Text>}
                    <Text style={{ fontSize: '7px', marginTop: '2px' }}>{rfcuser}</Text>
                    <Text style={{ fontSize: '7px', marginTop: '2px' }}>{regimenFiscalEmisor != "" ? `Régimen: ${regimenFiscalEmisor}` : ""}</Text>
                    <Text style={{ fontSize: '7px', marginTop: "2px" }}>{`${dirCalle} ${dirNumero}`}</Text>
                    <Text style={{ fontSize: '7px', marginTop: "2px" }}>{`${dirColonia}`}</Text>
                    <Text style={{ fontSize: '7px', marginTop: "2px" }}>{`${dirLocalidad}, ${dirEstado}`}</Text>
                    <Text style={{ fontSize: '7px', marginTop: "2px" }}>{`${dirCp}`}</Text>
                    <Text style={{ fontSize: '7px', marginTop: "8px" }}>{referencia !== "" ? "Referencia:" + referencia : ""} </Text>
                    {
                        data.facturaCancelada && <Text style={{ fontSize: '11px', marginTop: "2px", color: "#F00" }}>{`CFDI ENVIADO A CANCELACIÓN`}</Text>
                    }
                </View>
                <View style={styles.sectionCPinfo}>


                    <Text style={{ fontSize: '7px', textAlign: 'center' }}>{`Carta Porte-Factura:`} <Text style={{ color: "#F00" }}>{ncarta}</Text></Text>
                    <Text style={{ fontSize: '9px', fontWeight: 'extrabold', textAlign: 'center' }}>Folio Fiscal</Text>
                    <Text style={{ fontSize: '6px', textAlign: 'center' }}>{folioFiscal}</Text>
                    <Text style={{ fontSize: '9px', fontWeight: 'extrabold', textAlign: 'center' }}>No. Certificado Digital</Text>
                    <Text style={{ fontSize: '6px', textAlign: 'center' }}>{serieEmisor}</Text>
                    <Text style={{ fontSize: '9px', fontWeight: 'extrabold', textAlign: 'center' }}>No. Certificado SAT</Text>
                    <Text style={{ fontSize: '6px', textAlign: 'center' }}>{serieSat}</Text>
                    <Text style={{ fontSize: '9px', fontWeight: 'extrabold', textAlign: 'center' }}>Fecha Timbrado</Text>
                    <Text style={{ fontSize: '6px', textAlign: 'center' }}>{fechaTimbrado}</Text>
                </View>
            </View>
            <Text style={{ fontSize: '8px', fontWeight: 'bold', marginLeft: 1 }}>Tipo de Comprobante: Ingreso </Text>
            <View style={{ flexDirection: 'row', marginTop: 3 }}>
                <View style={{ flexDirection: 'row', marginTop: 0 }}>
                    <View style={{
                        marginRight: 0,
                        width: '47vw',
                        height: "auto",
                        border: '1px solid black',
                        borderRight: '0px solid black'
                        // flexGrow: 1
                    }}>
                        <View style={{
                            width: '100%',
                            height: "auto",
                            borderBottom: '1px solid black',
                            justifyContent: 'space-between',
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundColor: "#d9d9d8",
                            color: "#000" //li color letras
                            //   flexGrow: 1
                            //color grisesito d9d9d9 8 d9d9d8 para dif 
                        }}>
                            <Text style={{ width: '200px', fontSize: '9px', fontWeight: 'bold', marginLeft: 1 }}>Datos del Cliente</Text>

                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 0 }}>
                            <View style={styles.sectionLabelInfosmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>RFC:</Text>
                            </View>
                            <View style={styles.sectionInfoSmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{clienterfc}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 0 }}>
                            <View style={styles.sectionLabelInfosmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Nombre:</Text>
                            </View>
                            <View style={styles.sectionInfoSmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{clientenombre}</Text>
                            </View>
                        </View>
                        {regimenFiscalReceptor != "" && <View style={{ flexDirection: 'row', marginTop: 0 }}>
                            <View style={styles.sectionLabelInfosmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Régimen Fiscal:</Text>
                            </View>
                            <View style={styles.sectionInfoSmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{regimenFiscalReceptor}</Text>
                            </View>
                        </View>}
                        <View style={{ flexDirection: 'row', marginTop: 0 }}>
                            <View style={styles.sectionLabelInfosmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Calle:</Text>
                            </View>
                            <View style={styles.sectionInfoSmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{`${clientecalle} ${clientenumero}`}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 0 }}>
                            <View style={styles.sectionLabelInfosmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Colonia:</Text>
                            </View>
                            <View style={styles.sectionInfoSmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{clientecolonia || "No Aplica"}</Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', marginTop: 0 }}>
                            <View style={styles.sectionLabelInfosmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Localidad:</Text>
                            </View>
                            <View style={styles.sectionInfoSmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{`${clientecp},${clientelocalidad}`}</Text>
                            </View>
                        </View>

                    </View>
                    <View style={{
                        marginRight: 0,
                        width: '47.8vw',
                        height: "auto",
                        border: '1px solid black',
                        borderLeft: '0px solid black'
                        // flexGrow: 1
                    }}>
                        <View style={{
                            width: '100%',
                            height: "auto",
                            borderBottom: '1px solid black',
                            justifyContent: 'space-between',
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundColor: "#d9d9d8",
                            color: "#000"
                            //   flexGrow: 1
                        }}>
                            <Text style={{ width: '200px', fontSize: '9px', fontWeight: 'bold', marginLeft: 1 }}> </Text>

                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 0 }}>
                            <View style={styles.sectionLabelInfosmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Método de pago:</Text>
                            </View>
                            <View style={styles.sectionInfoSmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{metodoPago}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 0 }}>
                            <View style={styles.sectionLabelInfosmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Uso de CFDI:</Text>
                            </View>
                            <View style={styles.sectionInfoSmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{usoCfdi}</Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', marginTop: 0 }}>
                            <View style={styles.sectionLabelInfosmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Moneda:</Text>
                            </View>
                            <View style={styles.sectionInfoSmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{moneda}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 0 }}>
                            <View style={styles.sectionLabelInfosmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Tipo de Cambio:</Text>
                            </View>
                            <View style={styles.sectionInfoSmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{tipoCambio}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 0 }}>
                            <View style={styles.sectionLabelInfosmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Forma de pago:</Text>
                            </View>
                            <View style={styles.sectionInfoSmall}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{formaPago}</Text>
                            </View>
                        </View>
                        {
                            condicionesPago && condicionesPago !== "" ? <View style={{ flexDirection: 'row', marginTop: 0 }}>
                                <View style={styles.sectionLabelInfosmall}>
                                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{'Cond. de Pago'}</Text>
                                </View>
                                <View style={styles.sectionInfoSmall}>
                                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{condicionesPago}</Text>
                                </View>
                            </View> : <View style={{ flexDirection: 'row', marginTop: 0 }}>
                                <View style={styles.sectionLabelInfosmall}>
                                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{' '}</Text>
                                </View>
                                <View style={styles.sectionInfoSmall}>
                                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{' '}</Text>
                                </View>
                            </View>
                        }


                    </View>
                </View>

            </View>



            {
                relacionados && relacionados[0] && <View style={{ flexDirection: 'row', marginTop: 1, width: "95vw" }} wrap={false} >
                    <View style={styles.sectionComentariosBottom}>
                        <View style={styles.sectionClientePagaHeader}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', margin: 'auto' }}>CFDIS RELACIONADOS </Text>
                        </View>
                        <Text style={{ fontSize: '7px', margin: 2 }}>{`Tipo de relación: ${relacionados[0].tiporelacion} - ${CAT_TIPORELACION.find(rel => rel.value === relacionados[0].tiporelacion).descripcion}`}</Text>
                        {
                            relacionados.map(rel => {
                                return <Text style={{ fontSize: '7px', margin: 2 }}>{`UUID: ${rel.uuid}`}</Text>
                            })
                        }

                    </View>
                </View>
            }
            <View style={{ flexDirection: 'row', marginTop: 3 }}>
                <View style={styles.sectionOrigenDestino}>
                    <View style={styles.sectionClientePagaHeader2}>
                        <Text style={{ width: '100px', fontSize: '9px', fontWeight: 'bold', marginLeft: 1 }}>Origen</Text>
                        <Text style={{ width: '75px', fontSize: '9px', fontWeight: 'bold' }}>ID: OR000001</Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>RFC/TAX:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{origenrfc !== "" ? origenrfc : origenInternacional ? 'XEXX010101000' : 'XAXX010101000'}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Nombre:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{origenlocation}</Text>
                        </View>
                    </View>

                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Dirección:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{`${origenumero} ${(origenumeroInteior && origenumeroInteior.trim() != '') ? `Int.${origenumeroInteior}` : ""} ${origencalle} ${origencolonia}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>CP:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{`${origencp}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Localidad:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{`${origenciudad}, ${origenestado}, ${origenInternacional ? "USA" : "México"}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Fecha:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{`${fechaOrigen}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Kms:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>0.0</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.sectionOrigenDestino}>
                    <View style={styles.sectionClientePagaHeader2}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1 }}>Destino</Text>
                        <Text style={{ width: '75px', fontSize: '9px', fontWeight: 'bold' }}>{`ID: DE${zeroPad(2 + paradas.length, 6)}`}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>RFC/TAX:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{destinorfc !== "" ? destinorfc : destinoInternacional ? 'XEXX010101000' : 'XAXX010101000'}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Nombre:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{destinolocation}</Text>
                        </View>
                    </View>

                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Dirección:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{`${destinonumero} ${(destinonumeroInteior && destinonumeroInteior.trim() != '') ? `Int.${destinonumeroInteior}` : ""} ${destinocalle} ${destinocolonia}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>CP:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{`${destinocp}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Localidad:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{`${destinociudad}, ${destinoestado}, ${destinoInternacional ? "USA" : "México"}`}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Fecha:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{fechaEntrega}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0 }}>
                        <View style={styles.sectionLabelInfosmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>Kms:</Text>
                        </View>
                        <View style={styles.sectionInfoSmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 0 }}>{parseFloat(kms).toFixed(2)}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 10, width: "95vw", backgroundColor: "#d9d9d9" }}>
                <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>R E P A R T O S</Text>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>

                <View style={{ width: '10%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>KM</Text>
                    </View>
                </View>
                <View style={{ width: '20%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Nombre</Text>
                    </View>
                </View>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>RFC</Text>
                    </View>
                </View>
                <View style={{ width: '47%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Dirección</Text>
                    </View>
                </View>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>ID</Text>
                    </View>
                </View>

            </View>
            {paradas.length === 0 && <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                    <View style={{ width: '10%', height: "auto" }}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>-</Text>
                    </View>
                    <View style={{ width: '20%', height: "auto" }}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>-</Text>
                    </View>
                    <View style={{ width: '12%', height: "auto" }}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>-</Text>
                    </View>
                    <View style={{ width: '47%', height: "auto" }}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>-</Text>
                    </View>
                    <View style={{ width: '12%', height: "auto" }}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>-</Text>
                    </View>
                </View>
            </View>
            }
            {
                paradas.map((e, index) => {
                    return <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                        <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                            <View style={{ width: '10%', height: "auto" }}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{e.kms || ""}</Text>
                            </View>
                            <View style={{ width: '20%', height: "auto" }}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{e.nombre || ""}</Text>
                            </View>
                            <View style={{ width: '12%', height: "auto" }}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{e.rfc || ""}</Text>
                            </View>
                            <View style={{ width: '47%', height: "auto" }}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{e.internacional ?
                                    `${e.numero} ${e.calle}. ${e.localidad} ${e.cp}` :
                                    `${e.calle} #${e.numero}, ${e.colonia}. ${e.localidad} ${e.cp}`}</Text>
                            </View>
                            <View style={{ width: '12%', height: "auto" }}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{`DE${zeroPad(2 + index, 6)}`}</Text>
                            </View>
                        </View>
                    </View>
                })
            }
            {data.detalles.comentarios !== "" && <View style={{ flexDirection: 'row', marginTop: 1, width: "95vw" }} wrap={false} >
                <View style={styles.sectionComentariosBottom}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', margin: 'auto' }}>Comentarios</Text>
                    </View>
                    <Text style={{ fontSize: '7px', margin: 2 }}>{data.detalles.comentarios}</Text>
                </View>
            </View>
            }
            {
                requiereTituloCARTAPORTE.find(f => f === rfcuser) && <View style={{ flexDirection: 'row', marginTop: 3, width: "95vw", backgroundColor: "#d9d9d9" }}>
                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>  C A R T A  P O R T E  </Text>
                </View>
            }

            {
                (versionCCP === "3.0" || versionCCP === "3.1") && <View style={{ flexDirection: 'row', marginTop: 3, width: "95vw", backgroundColor: "#d9d9d9" }}>
                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>  C A R T A  P O R T E  </Text>
                </View>
            }
            {
                (versionCCP === "3.0" || versionCCP === "3.1") && idCCP &&
                <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                    <View style={{ flexDirection: 'col', marginTop: 0, width: "75vw" }}>
                        <View style={{ flexDirection: 'row', marginTop: 0, width: "75vw" }}>
                            <View style={{ width: '42%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>idCCP</Text>
                                </View>
                            </View>
                            <View style={{ width: '22%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Versión</Text>
                                </View>
                            </View>
                            <View style={{ width: '22%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Regimen Aduanero</Text>
                                </View>
                            </View>

                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 0, width: "75vw" }} wrap={false}>
                            <View style={{ width: '42%', height: "auto" }}>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{idCCP}</Text>
                                </View>
                            </View>
                            <View style={{ width: '22%', height: "auto" }}>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{versionCCP}</Text>
                                </View>
                            </View>
                            <View style={{ width: '22%', height: "auto" }}>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{regimenAduanero}</Text>
                                </View>
                            </View>



                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 0, width: "75vw" }}>
                            <View style={{ width: '42%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Internacional</Text>
                                </View>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{isInternational ? 'Si' : "No"}</Text>
                                </View>
                            </View>
                            <View style={{ width: '22%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Tipo</Text>
                                </View>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{isInternational ? origenInternacional ? 'Entrada' : 'Salida' : "-"}</Text>
                                </View>
                            </View>
                            <View style={{ width: '22%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>País</Text>
                                </View>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{isInternational ? 'USA' : "-"}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 0, width: "75vw" }}>
                            <View style={{ width: '42%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Peso Bruto Total</Text>
                                </View>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{PesoBrutoTotal}</Text>
                                </View>
                            </View>
                            <View style={{ width: '22%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Unidad de Peso</Text>
                                </View>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{"KGM"}</Text>
                                </View>
                            </View>
                            <View style={{ width: '22%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Total de Mercancias</Text>
                                </View>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{NumTotalMercancias}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 0, width: "15vw" }}>
                        <View style={{ flexDirection: 'row', marginTop: 0, width: "15vw" }}>
                            {datosTimbre && <Image style={{ marginLeft: "0px", width: '90%', height: "auto", marginTop: '-10px' }} src={`https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/qrgeneratorccpcartaporte?content=${dataqr}`}></Image>
                            }
                        </View>
                    </View>
                </View>

            }


            <View style={{ flexDirection: 'row', marginTop: 3, width: "95vw", backgroundColor: "#d9d9d9" }}>
                <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>U N I D A D  /  R E M O L Q U E S  /  S E G U R O S</Text>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Internacional</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{isInternational ? 'Si' : "No"}</Text>
                    </View>
                </View>
                <View style={{ width: '6%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Tipo</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{isInternational ? origenInternacional ? 'Entrada' : 'Salida' : "-"}</Text>
                    </View>
                </View>
                <View style={{ width: '6%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>País</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{isInternational ? 'USA' : "-"}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Medio o Vía</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{'Autotransporte'}</Text>
                    </View>
                </View>
                <View style={{ width: '7%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Config.</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{data.dataTimbrada.TipoAutoTransporte}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Permiso SCT</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{data.dataTimbrada.PermisoSCT}</Text>
                    </View>
                </View>
                <View style={{ width: '24%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>No. Permiso</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{data.dataTimbrada.NumeroPermisoSCT}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTopCompenzaSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Aseguradora</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{data.dataTimbrada.NombreAseguradoraResponsabilidadCivil}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Póliza</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{data.dataTimbrada.NumeroPolizaResponsabilidadCivil}</Text>
                    </View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 3, width: "95vw" }}>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Unidad</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{data.dataTimbrada.NumeroEconomico}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Placas</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{data.dataTimbrada.Placa}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Año</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{data.dataTimbrada.Anio}</Text>
                    </View>
                </View>

                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Remolque 1</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{nombreRemolque2 != "" ? nombreRemolque2 : data.dataTimbrada.NumeroEconomicoRemolque1}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTopSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Placas</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{placasRemolque2 != "" ? placasRemolque2 : data.dataTimbrada.PlacaRemolque1}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Remolque 2</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{data.dataTimbrada.NumeroEconomicoRemolque2 && nombreRemolque2 ? data.dataTimbrada.NumeroEconomicoRemolque2 : nombreRemolque2}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Placas</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{data.dataTimbrada.PlacaRemolque2 && placasRemolque2 ? data.dataTimbrada.PlacaRemolque2 : placasRemolque2}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTopCompenzaSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Aseguradora MA</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{data.dataTimbrada.NombreAseguradoraMedioAmbiente}</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTop}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Póliza MA</Text>
                    </View>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', weight: "100%", position: "relative", margin: 'auto' }}>{data.dataTimbrada.NumeroPolizaMedioAmbiente}</Text>
                    </View>
                </View>
            </View>

            <View style={{ flexDirection: 'row', marginTop: 10, width: "95vw", backgroundColor: "#d9d9d9" }}>
                <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>M E R C A N C I A S <Text style={{ fontSize: '9px', color: "#F00" }}>{(claseViaje == 'Hazmat' || claseViaje == 'Material peligroso') ? ` / ${claseViaje}` : ''}</Text></Text>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Unidad</Text>
                    </View>

                </View>
                <View style={{ width: '7%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Cant.</Text>
                    </View>

                </View>
                <View style={{ width: '36%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Descripción</Text>
                    </View>

                </View>
                <View style={{ width: '4%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Valor</Text>
                    </View>

                </View>
                <View style={styles.sectionFolioTopSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Fracc. Ar.</Text>
                    </View>

                </View>
                <View style={{ width: '17%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Pedimento</Text>
                    </View>

                </View>
                <View style={styles.sectionFolioTopSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Tipo Materia</Text>
                    </View>
                </View>
                {/*<View style={styles.sectionFolioTopSmall}>
<View style={styles.sectionClientePagaHeader}>
<Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Embalaje</Text>
</View>

</View>*/}
                <View style={{ width: '7%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Peso kg.</Text>
                    </View>

                </View>
            </View>
            {
                mercancias.map(m => {
                    return <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }} wrap={false}>
                        <View style={{ width: '12%', height: "auto" }}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{
                                    CAT_UNIDADES.find(e => e.descripcion === m.unidad) ? `${CAT_UNIDADES.find(e => e.descripcion === m.unidad).codigo} - ${m.unidad}` : `${m.unidad}`

                                }</Text>
                            </View>
                        </View>
                        <View style={{ width: '7%', height: "auto" }}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{m.cantidad}</Text>
                            </View>
                        </View>
                        <View style={{ width: '36%', height: "auto", hyphens: 'none' }}>
                            <View style={{ weight: "auto", hyphens: 'none' }}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', position: "relative" }}>{`${m.csat} - ${m.descripcion}`}</Text>
                            </View>
                        </View>
                        <View style={{ width: '4%', height: "auto" }}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{`$0.0`}</Text>
                            </View>
                        </View>
                        <View style={styles.sectionFolioTopSmall}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{`${m.fraccion}`}</Text>
                            </View>
                        </View>
                        <View style={{ width: '17%', height: "auto" }}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{(m.pedimento !== undefined && m.pedimento != "") ? m.pedimento : pedimentos}</Text>
                            </View>
                        </View>
                        <View style={styles.sectionFolioTopSmall}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{`${m.TipoMateria ? `${m.TipoMateria}-${m.DescripcionMateria}` : ''}`}</Text>
                            </View>
                        </View>
                        {/*<View style={styles.sectionFolioTopSmall}>
    <View style={{ weight: "auto" }}>
        <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{`${m.c_TipoEmbalaje || ""}`}</Text>
    </View>
</View>*/}
                        <View style={{ width: '7%', height: "auto" }}>
                            <View style={{ weight: "auto" }}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{`${parseFloat(m.pesoKG).toFixed(2)}`}</Text>
                            </View>
                        </View>
                    </View>
                })
            }
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Total</Text>
                    </View>

                </View>
                <View style={{ width: '5%', height: "auto" }}>
                    <View >
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{
                            mercancias.reduce((sum, current) => { return sum + parseFloat(current.cantidad) }, 0)
                        }</Text>
                    </View>

                </View>
                <View style={{ width: '68%', height: "auto" }}>
                    <View >
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>&nbsp;</Text>
                    </View>

                </View>

                <View style={styles.sectionFolioTopSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{versionCCP === "2.0" ? `Peso Neto` : `Peso Bruto`}&nbsp;</Text>
                    </View>

                </View>
                {/*<View style={styles.sectionFolioTopSmall}>
<View style={styles.sectionClientePagaHeader}>
<Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Embalaje</Text>
</View>

</View>*/}
                <View style={{ width: '7%', height: "auto" }}>
                    <View >
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{
                            mercancias.reduce((sum, current) => { return sum + parseFloat(current.pesoKG) }, 0).toFixed(2)
                        }</Text>
                    </View>

                </View>
            </View>
            {versionCCP == "2.0" && <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                <View style={{ width: '85%', height: "auto" }}>
                    <View >
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}></Text>
                    </View>

                </View>
                <View style={styles.sectionFolioTopSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Peso Bruto</Text>
                    </View>

                </View>
                {/*<View style={styles.sectionFolioTopSmall}>
<View style={styles.sectionClientePagaHeader}>
<Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Embalaje</Text>
</View>

</View>*/}
                <View style={{ width: '7%', height: "auto" }}>
                    <View >
                        {pesoBruto == 0 ? <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{
                            mercancias.reduce((sum, current) => { return sum + parseFloat(current.pesoKG) }, 0).toFixed(2)
                        }</Text> :
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{
                                pesoBruto
                            }</Text>
                        }
                    </View>

                </View>
            </View>}


            <View style={{ flexDirection: 'row', marginTop: 10, width: "95vw", backgroundColor: "#d9d9d9" }}>
                <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>O P E R A D O R</Text>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }} wrap={false}>
                <View style={{ width: '9%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>&nbsp;</Text>
                    </View>

                </View>
                <View style={{ width: '9%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Unidad</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTopCompenzaSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>RFC</Text>
                    </View>
                </View>
                <View style={{ width: '20%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Nombre</Text>
                    </View>
                </View>
                <View style={{ width: '13%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Licencia</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTopBigDetail}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Dirección</Text>
                    </View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                <View style={{ width: '9%', height: "auto" }}>
                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Operador</Text>

                </View>
                <View style={{ width: '9%', height: "auto" }}>
                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{unidad}</Text>
                </View>
                <View style={styles.sectionFolioTopCompenzaSmall}>
                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{operadorrfc}</Text>
                </View>
                <View style={{ width: '20%', height: "auto" }}>
                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{operadornombre}</Text>
                </View>
                <View style={{ width: '13%', height: "auto" }}>
                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{operadorlicencia}</Text>
                </View>
                <View style={styles.sectionFolioTopBigDetail}>
                    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{operadorDireccion}</Text>
                </View>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 10, width: "95vw", backgroundColor: "#d9d9d9" }}>
                <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>P R O D U C T O S    /    S E R V I C I O S    /    P A R T I D A S   (Line Items)</Text>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }} wrap={false}>
                <View style={{ width: '9%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Cantidad</Text>
                    </View>
                </View>
                <View style={styles.sectionFolioTopCompenzaSmall}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Unidad</Text>
                    </View>
                </View>
                <View style={{ width: '39%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Descripción</Text>
                    </View>
                </View>
                <View style={{ width: '10%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Precio Unitario</Text>
                    </View>
                </View>
                <View style={{ width: '19%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Impuestos</Text>
                    </View>
                </View>

                <View style={{ width: '10%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Importe {conceptos.length}</Text>
                    </View>
                </View>
            </View>
            {
                conceptos && conceptos.length > 0 && conceptos[0]['cfdi:Concepto'].map((c, index) => {
                    return <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }} wrap={false}>
                        <View style={{ width: '9%', height: "auto" }}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{c['$']['Cantidad'] ? c['$']['Cantidad'] : "-"}</Text>
                        </View>
                        <View style={styles.sectionFolioTopCompenzaSmall}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{c['$'] ? `${c['$']['ClaveUnidad']} - ${c['$']['Unidad']}` : "-"}</Text>
                        </View>
                        <View style={{ width: '39%', height: "auto", hyphens: 'none' }}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto', }} wrap={false}>{c['$'] ? `${c['$']['ClaveProdServ']} - ${c['$']['Descripcion']}` : "-"}</Text>
                        </View>
                        {data.verTarifa && showTarifa ? <View style={{ width: '10%', height: "auto" }}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{c['$']['ValorUnitario'] ? c['$']['ValorUnitario'] : "-"}</Text>
                        </View> : <View style={{ width: '19%', height: "auto" }}></View>}
                        {data.verTarifa && showTarifa ? <View style={{ width: '19%', height: "auto" }}>
                            {c['cfdi:Impuestos'] && c['cfdi:Impuestos'][0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$']['Importe'].split(".")[1].length > 2 ?
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{c['cfdi:Impuestos'] ? `IVA ${parseInt(100 * c['cfdi:Impuestos'][0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$']['TasaOCuota'])}% ($${c['cfdi:Impuestos'][0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$']['Importe']})${c['cfdi:Impuestos'][0]['cfdi:Retenciones'] ? `, Retención ${parseFloat(100 * c['cfdi:Impuestos'][0]['cfdi:Retenciones'][0]['cfdi:Retencion'][0]['$']['TasaOCuota'])}% ($${c['cfdi:Impuestos'][0]['cfdi:Retenciones'][0]['cfdi:Retencion'][0]['$']['Importe']}) ${c['cfdi:Impuestos'][0]['cfdi:Retenciones'][0]['cfdi:Retencion'][1] ? ` Ret.ISR ${parseFloat(100 * c['cfdi:Impuestos'][0]['cfdi:Retenciones'][0]['cfdi:Retencion'][1]['$']['TasaOCuota'])}%` : ''}` : ''}` : " "}</Text>
                                : <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{c['cfdi:Impuestos'] ? `IVA ${parseInt(100 * c['cfdi:Impuestos'][0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$']['TasaOCuota'])}%${c['cfdi:Impuestos'][0]['cfdi:Retenciones'] ? `, Retención ${parseFloat(100 * c['cfdi:Impuestos'][0]['cfdi:Retenciones'][0]['cfdi:Retencion'][0]['$']['TasaOCuota'])}%${c['cfdi:Impuestos'][0]['cfdi:Retenciones'][0]['cfdi:Retencion'][1] ? ` Ret.ISR ${parseFloat(100 * c['cfdi:Impuestos'][0]['cfdi:Retenciones'][0]['cfdi:Retencion'][1]['$']['TasaOCuota'])}%` : ''}` : ''}` : " "}</Text>
                            }
                            {
                                !c['cfdi:Impuestos'] && <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>No Objeto</Text>
                            }

                        </View> : <View style={{ width: '19%', height: "auto" }}></View>}
                        {data.verTarifa && showTarifa ? <View style={{ width: '10%', height: "auto" }}>
                            <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{c['$'] ? `$${c['$']['Importe']}` : "-"}</Text>
                        </View> : <View style={{ width: '19%', height: "auto" }}></View>}
                        {/*
 <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{c['cfdi:Impuestos'] ? `IVA ${parseInt(100 * c['cfdi:Impuestos'][0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$']['TasaOCuota'])}%${c['cfdi:Impuestos'][0]['cfdi:Retenciones'] ? `, Retención ${parseFloat(100 * c['cfdi:Impuestos'][0]['cfdi:Retenciones'][0]['cfdi:Retencion'][0]['$']['TasaOCuota'])}%${c['cfdi:Impuestos'][0]['cfdi:Retenciones'][0]['cfdi:Retencion'][1] ? ` Ret.ISR ${parseFloat(100 * c['cfdi:Impuestos'][0]['cfdi:Retenciones'][0]['cfdi:Retencion'][1]['$']['TasaOCuota'])}%` : ''}` : ''}` : " "}</Text>

<View style={styles.sectionFolioTopCompenzaSmall}>
    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{c['cfdi:Concepto'][0]['$'] ? c['cfdi:Concepto'][0]['$']['Cantidad'] : "-"}</Text>
</View>
<View style={styles.sectionFolioTopCompenzaSmall}>
    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{c['cfdi:Concepto'][0]['$'] ? `${c['cfdi:Concepto'][0]['$']['ClaveUnidad']} - ${c['cfdi:Concepto'][0]['$']['Unidad']}` : "-"}</Text>
</View>
<View style={{ width: '29%', height: "auto" }}>
    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{c['cfdi:Concepto'][0]['$'] ? `${c['cfdi:Concepto'][0]['$']['ClaveProdServ']} - ${c['cfdi:Concepto'][0]['$']['Descripcion']}` : "-"}</Text>
</View>
<View style={styles.sectionFolioTopCompenzaSmall}>
    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{c['cfdi:Concepto'][0]['$'] ? `$${c['cfdi:Concepto'][0]['$']['ValorUnitario']}` : "-"}</Text>
</View>
<View style={{ width: '19%', height: "auto" }}>
    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{c['cfdi:Concepto'][0]['$'] ? `IVA ${parseInt(100 * c['cfdi:Concepto'][0]['cfdi:Impuestos'][0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$']['TasaOCuota'])}%, Retención ${parseInt(100 * c['cfdi:Concepto'][0]['cfdi:Impuestos'][0]['cfdi:Retenciones'][0]['cfdi:Retencion'][0]['$']['TasaOCuota'])}%` : "-"}</Text>
</View>
<View style={styles.sectionFolioTopCompenzaSmall}>
    <Text style={{ fontSize: '7px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>{c['cfdi:Concepto'][0]['$'] ? `$${c['cfdi:Concepto'][0]['$']['Importe']}` : "-"}</Text>
</View>*/}
                    </View>
                })
            }
            <View style={{ flexDirection: 'row', marginTop: 1, width: "95vw" }} wrap={false} >
                <View style={{ flexDirection: 'row', marginTop: 10, width: "75vw" }}>
                    <Text style={{ fontSize: '7px', fontWeight: 'bold', margin: 'auto' }}>Total con letra:</Text>

                    {data.verTarifa && <Text style={{ fontSize: '7px', fontWeight: 'bold', margin: 'auto' }}>({showTarifa ? NumeroALetras(total, moneda === "MXN") : " "})</Text>}
                </View>
                <View style={{ flexDirection: 'row', marginTop: 10, width: "20vw" }}>
                    <View style={{ flexDirection: 'column', marginTop: 10, width: "10vw", backgroundColor: "#d9d9d9" }}>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginRight: '0', textAlign: 'right' }}>{`SubTotal:`}</Text>
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginRight: '0', textAlign: 'right' }}>{`IVA:`}</Text>
                        {impuestos && impuestos[0] && impuestos[0]['cfdi:Retenciones'] && impuestos[0]['cfdi:Retenciones'][0] &&
                            impuestos[0]['cfdi:Retenciones'][0]['cfdi:Retencion'] &&
                            impuestos[0]['cfdi:Retenciones'][0]['cfdi:Retencion'].length > 1 &&
                            impuestos[0]['cfdi:Retenciones'][0]['cfdi:Retencion'].map(impaux => {
                                let nombreRetencion = "IVA";
                                if (impaux['$'] && impaux['$']['Impuesto']) {
                                    if (impaux['$']['Impuesto'] == "001") {
                                        nombreRetencion = "ISR";
                                    }

                                }
                                return <Text style={{ fontSize: '7px', fontWeight: 'bold', marginRight: '0', textAlign: 'right' }}>{`Ret. ${nombreRetencion}:`}</Text>
                            })
                        }
                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginRight: '0', textAlign: 'right' }}>{`Retenciones:`}</Text>

                        <Text style={{ fontSize: '7px', fontWeight: 'bold', marginRight: '0', textAlign: 'right' }}>{`Total:`}</Text>
                    </View>
                    <View style={{ flexDirection: 'column', marginTop: 10, width: "10vw" }}>
                        {data.verTarifa ? <Text style={{ fontSize: '7px', fontWeight: 'bold', marginRight: '0', marginLeft: '5', textAlign: 'right' }}>{` ${formatoMoneda(subtotal)}`}</Text> : <Text style={{ fontSize: '7px', fontWeight: 'bold', marginRight: '0', marginLeft: '5', textAlign: 'left' }}>{` - `}</Text>}
                        {data.verTarifa ? <Text style={{ fontSize: '7px', fontWeight: 'bold', marginRight: '0', marginLeft: '5', textAlign: 'right' }}>{` ${formatoMoneda(impuestos.length === 1 ? impuestos[0]['$']['TotalImpuestosTrasladados'] : '0')}`}</Text> : <Text style={{ fontSize: '7px', fontWeight: 'bold', marginRight: '0', marginLeft: '5', textAlign: 'left' }}>{` - `}</Text>}
                        {impuestos && impuestos[0] && impuestos[0]['cfdi:Retenciones'] && impuestos[0]['cfdi:Retenciones'][0] &&
                            impuestos[0]['cfdi:Retenciones'][0]['cfdi:Retencion'] &&
                            impuestos[0]['cfdi:Retenciones'][0]['cfdi:Retencion'].length > 1 &&
                            impuestos[0]['cfdi:Retenciones'][0]['cfdi:Retencion'].map(impaux => {
                                let montoRetencion = "0.00";
                                if (impaux['$'] && impaux['$']['Importe']) {
                                    montoRetencion = impaux['$']['Importe'];

                                }
                                return data.verTarifa ? <Text style={{ fontSize: '7px', fontWeight: 'bold', marginRight: '0', marginLeft: '5', textAlign: 'right' }}>{` ${formatoMoneda(montoRetencion)}`}</Text> : <Text style={{ fontSize: '7px', fontWeight: 'bold', marginRight: '0', marginLeft: '5', textAlign: 'left' }}>{` - `}</Text>
                            })
                        }
                        {data.verTarifa ? <Text style={{ fontSize: '7px', fontWeight: 'bold', marginRight: '0', marginLeft: '5', textAlign: 'right' }}>{` ${formatoMoneda(impuestos.length === 1 ? impuestos[0]['$']['TotalImpuestosRetenidos'] ? impuestos[0]['$']['TotalImpuestosRetenidos'] : '0.00' : '0.00')}`}</Text> : <Text style={{ fontSize: '7px', fontWeight: 'bold', marginRight: '0', marginLeft: '5', textAlign: 'left' }}>{` - `}</Text>}
                        {data.verTarifa ? <Text style={{ fontSize: '7px', fontWeight: 'bold', marginRight: '0', marginLeft: '5', textAlign: 'right' }}>{` ${formatoMoneda(total)}`}</Text> : <Text style={{ fontSize: '7px', fontWeight: 'bold', marginRight: '0', marginLeft: '5', textAlign: 'left' }}>{` - `}</Text>}
                    </View>
                </View>
            </View>

            <View style={{ flexDirection: 'col', marginTop: 3, width: "95vw" }} wrap={false} >
                <View style={{ flexDirection: 'row', marginTop: 10, width: "95vw" }} wrap={false} >
                    <View style={{ flexDirection: 'row', marginTop: 10, width: "20vw" }}>
                        {datosTimbre && <Image style={{ marginLeft: "0px", width: '80%', height: "auto" }} src={`https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/qrgeneratorccp?content=${dataqr}`}></Image>
                        }
                    </View>
                    <View style={{ flexDirection: 'column', marginTop: 3, width: "75vw" }}>
                        <View style={styles.sectionSellosBottomFirst}>
                            <View style={styles.sectionClientePagaHeader}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', margin: 'auto' }}>Sello Digital del CFDI</Text>
                            </View>
                            <Text style={{ fontSize: '6px', marginLeft: 1 }}>{sellocfdi.match(/.{1,125}/g).join('\n')}</Text>

                        </View>
                        <View style={styles.sectionSellosBottom}>
                            <View style={styles.sectionClientePagaHeader}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', margin: 'auto' }}>Sello Digital del SAT</Text>
                            </View>
                            <Text style={{ fontSize: '6px', marginLeft: 1 }}>{sellosat.match(/.{1,125}/g).join('\n')}</Text>

                        </View>
                        <View style={styles.sectionSellosBottomLast}>
                            <View style={styles.sectionClientePagaHeader}>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', margin: 'auto' }}>CADENA ORIGINAL DEL COMPLEMENTO DE CERTIFICACION DIGITAL DEL SAT</Text>
                            </View>
                            <Text style={{ fontSize: '6px', marginLeft: 1 }}>{cadenaOriginal.match(/.{1,125}/g).join('\n')}</Text>

                        </View>
                    </View>


                </View>
                <View style={{ width: '95vw' }}>
                    <Text style={{ fontSize: '8px', fontWeight: 'nomal', margin: 'auto' }}>Este documento es una representación impresa de un CFDI</Text>
                    <Text style={{ fontSize: '7px', fontWeight: 'bold', margin: 'auto' }}> Todos los derechos reservados, Prohibida su copia, reproducción total o parcial sin autorización.</Text>
                    <Text style={{ fontSize: '8px', fontWeight: 'normal', margin: 'auto' }}> https://home.transportplustms.com </Text>
                </View>
            </View>



        </Page>
        {reqcontrato1 && <Contrato1 />}
    </Document>;
};

const PDFView = props => {
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);

    const params = useParams();
    const locations = useLocation();
    const [id, setId] = useState(params.id || null);
    //console.log(locations);
    const [objData, setObjData] = useState(null);
    let queryparams = queryString.parse(locations.search);
    const [showTarifa, setShowTarifa] = useState(queryparams.t);
    let verTarifa = true;
    if (localStorage.getItem('deny')) {
        let auxdeny = localStorage.getItem('deny').split(":");
        if (auxdeny.find(e => e === "vertarifaspdf")) {
            verTarifa = false;
        }
    }

    const getData = async () => {
        let r = null;
        if (id) {
            console.log("getting viaje");
            await getViajeById(id).then(resp => {
                r = { id, ...resp.data() };

            }).catch(err => {

            });

            if (r && r.general && r.general.clienteId) {
                await getClienteByID(r.general.clienteId).then(resp => {
                    let clienteinfo = resp.data();
                    r = { ...r, clienteinfo };
                }).catch(err => { });
            }

            if (r.datos_viaje && r.datos_viaje.origenId) {
                await getUbicacionesById(r.datos_viaje.origenId).then(resp => {
                    let origeninfo = resp.data();

                    r = { ...r, origeninfo };
                }).catch(err => { });
            }

            if (r.datos_viaje && r.datos_viaje.destinoId) {
                await getUbicacionesById(r.datos_viaje.destinoId).then(resp => {
                    let destinoinfo = resp.data();

                    r = { ...r, destinoinfo };
                }).catch(err => { });
            }

            /*if (r.detalles.unidadId && r.detalles.unidadId !== "") {
            await getUnidadByID(r.detalles.unidadId).then(resp => {
            let unidadinfo = resp.data();
            r = { ...r, unidadinfo };
            }).catch(err => { });
            }*/

            let operadorid = null;
            if (r.detalles && r.detalles.operador1Id && r.detalles.operador1Id.length > 5) {
                operadorid = r.detalles.operador1Id;
            } else if (r.detalles && r.detalles.operador2Id && r.detalles.operador2Id.length > 5) {
                operadorid = r.detalles.operador2Id;
            }
            if (operadorid) {
                await getOperadorById(operadorid).then(resp => {
                    let operadorinfo = resp.data();
                    r = { ...r, operadorinfo };
                }).catch(err => { });
            }

            if (r.paradas) {
                console.log("paradas", r.paradas);
            }

            let dataremolque1 = null;
            let dataremolque2 = null;
            if (r.carta_porte && r.carta_porte.remolque1 !== "") {
                await getRemolquesByNombreAndUid(r.uid, r.carta_porte.remolque1).then(snapshot => {
                    snapshot.forEach(document => {
                        dataremolque1 = document.data();
                        dataremolque1.id = document.id;
                        if (r.datos_viaje.claseViaje === "Marítimo-puertos" && r.carta_porte.contenedor1 !== "") {
                            dataremolque1.nombre = r.carta_porte.contenedor1;
                        }
                    })
                }).catch(err => { });
            }

            if (r.carta_porte && r.carta_porte.remolque2 !== "") {
                await getRemolquesByNombreAndUid(r.uid, r.carta_porte.remolque2).then(snapshot => {
                    snapshot.forEach(document => {
                        dataremolque2 = document.data();
                        dataremolque2.id = document.id;
                        if (r.datos_viaje.claseViaje === "Marítimo-puertos" && r.carta_porte.contenedor2 !== "") {
                            dataremolque2.nombre = r.carta_porte.contenedor2;
                        }
                    })
                }).catch(err => { });
            }


            if (dataremolque1 && dataremolque2) {
                //console.log("hay 2",dataremolque1,dataremolque2);
                r.remolque1 = dataremolque2;
                r.remolque2 = dataremolque1;
            } else if (dataremolque1) {
                r.remolque1 = dataremolque1;
            } else if (dataremolque2) {
                r.remolque1 = dataremolque2;
            }

            //  console.log("hay 2",dataremolque1,dataremolque2);

            await getUserByUid(r.uid).then(snapshot => {
                let usuarioInfo = {};
                snapshot.forEach(document => {
                    usuarioInfo = document.data();
                    usuarioInfo.id = document.id;
                })

                r = { ...r, usuarioInfo };
            }).catch(err => { });
            if (r.usuarioInfo && queryparams.rfc) {
                if (r.usuarioInfo.razones && r.usuarioInfo.razones.length > 0) {
                    let credenciales = null;
                    r.usuarioInfo.razones.forEach(element => {
                        if (element.Rfc === queryparams.rfc) {
                            credenciales = element;
                        }
                    });

                    if (credenciales) {
                        r.usuarioInfo.credenciales = credenciales;
                    }
                }
            }
            let datatimbrado = await asyncparser(r);
            if (datatimbrado) {
                r.datatimbrado = datatimbrado;

            }

            if (r.datos_viaje.noSello && r.datos_viaje.noSello !== "") {
                r.detalles.comentarios = r.detalles.comentarios + " Sellos:" + r.datos_viaje.noSello;
            }

            /* if (certificadoFolio) {
            //      r.certificadoFolio = certificadoFolio;
            } else {
            r.certificadoFolio = "";
            }*/
            r.fe = "";

            if (r.facuturado) {
                let fetemp = r.facuturado.selloCFD || "";
                if (fetemp.length > 6) {
                    let fefix = fetemp.slice(id.length - 5);
                    r.fe = fefix;
                }

                console.log(); //Outputs: Tabs1
                // console.log("setting a false showtarifa 1");
                if (showTarifa == 0 || showTarifa === 0 || showTarifa == "0") {
                    //console.log("setting a false showtarifa");
                    r.showTarifa = false;
                } else {
                    // console.log("setting a false showtarifa 3",r.facuturado.mostrarTarifa);
                    // r.showTarifa = r.facuturado.mostrarTarifa || false;
                    r.showTarifa = true;
                }



            } else {
                // console.log("setting a false showtarifa 2" );
                r.showTarifa = parseBooleans(showTarifa);
            }
            if (id && !r.id) {
                r.id = id;
            }
            r.verTarifa = verTarifa;
            setObjData(r);
        }

    }

    useEffect(() => {

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {
                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);
                setUser(userr);
                // setAdminCurrentUser(userr.uid);
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });
        // console.log("num a letra",NumeroALetras(12022.60,false));

        // getData();
    }, []);

    useEffect(() => {
        if (uidAltaEdit) {
            console.log("getting data", uidAltaEdit);
            getData();
        }
    }, [uidAltaEdit]);

    const asyncparser = async (obj) => {
        if (obj.facuturado) {
            let xmlstring = obj.facuturado.timbreXML;
            //let fixedXml=xmlstring.replace('cfdi:', '');
            let fixedXml = xmlstring.split('cfdi:').join('');
            fixedXml = xmlstring.split('$').join('obj');

            let response = null;
            await xml2js.parseString(fixedXml, async (err, result) => {
                if (err) {
                    console.log(err);
                }
                else {
                    // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                    let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                    console.log("primer xml", result);
                    response = objdataxml;
                    // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                }

            });

            let xmlstring2 = obj.facuturado.cfdixml;
            //let fixedXml=xmlstring.replace('cfdi:', '');
            let fixedXml2 = xmlstring2.split('cfdi:').join('');
            fixedXml2 = xmlstring2.split('$').join('obj');
            await xml2js.parseString(fixedXml2, async (err, result) => {
                if (err) {
                    console.log(err);
                }
                else {
                    // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                    let objdataxml = result["cfdi:Comprobante"]["$"];
                    let UsoCFDI = result["cfdi:Comprobante"]["cfdi:Receptor"][0]["$"]["UsoCFDI"];
                    let RegimenFiscalReceptor = "";
                    if (result["cfdi:Comprobante"]["cfdi:Receptor"][0]["$"]["RegimenFiscalReceptor"]) {
                        RegimenFiscalReceptor = result["cfdi:Comprobante"]["cfdi:Receptor"][0]["$"]["RegimenFiscalReceptor"];
                    }

                    let RegimenFiscalEmisor = "";
                    if (result["cfdi:Comprobante"]["cfdi:Emisor"][0]["$"]["RegimenFiscal"]) {
                        RegimenFiscalEmisor = result["cfdi:Comprobante"]["cfdi:Emisor"][0]["$"]["RegimenFiscal"];
                    }

                    let Conceptos = result["cfdi:Comprobante"]["cfdi:Conceptos"];
                    let Impuestos = result["cfdi:Comprobante"]["cfdi:Impuestos"];

                    let DatCCP = result["cfdi:Comprobante"]["cfdi:Complemento"][0]['cartaporte20:CartaPorte'] || result["cfdi:Comprobante"]["cfdi:Complemento"][0]['cartaporte30:CartaPorte'] || result["cfdi:Comprobante"]["cfdi:Complemento"][0]['cartaporte31:CartaPorte'];
                    console.log("DataCCP", DatCCP);

                    let versionCCP = '2.0';
                    if (DatCCP) {
                        versionCCP = DatCCP[0]['$']['Version'];
                    }
                    let versionstring = versionCCP.split(".").join("");



                    let dataAutotransporte = null;

                    let condicionesPago = result["cfdi:Comprobante"]["CondicionesDePago"];

                    if (DatCCP && DatCCP[0] && DatCCP[0][`cartaporte${versionstring}:Mercancias`] && DatCCP[0][`cartaporte${versionstring}:Mercancias`][0][`cartaporte${versionstring}:Autotransporte`]) {
                        dataAutotransporte = DatCCP[0][`cartaporte${versionstring}:Mercancias`][0][`cartaporte${versionstring}:Autotransporte`][0];
                    }
                    // console.log("dataccp", DatCCP);

                    let idCCP = "";
                    if (DatCCP && DatCCP[0]['$']['IdCCP']) {
                        idCCP = DatCCP[0]['$']['IdCCP'];
                    }

                    let regimenAduanero = "";
                    if (DatCCP && DatCCP[0]['$']['RegimenAduanero']) {
                        regimenAduanero = DatCCP[0]['$']['RegimenAduanero'];
                    }
                    if (versionCCP === "3.1") {
                        //console.log("hereee");
                        if (DatCCP[0] && DatCCP[0]['cartaporte31:RegimenesAduaneros'] &&
                            DatCCP[0]['cartaporte31:RegimenesAduaneros'][0] &&
                            DatCCP[0]['cartaporte31:RegimenesAduaneros'][0]['cartaporte31:RegimenAduaneroCCP']
                        ) {
                            let auxregimenes = [];
                            DatCCP[0]['cartaporte31:RegimenesAduaneros'][0]['cartaporte31:RegimenAduaneroCCP'].forEach(ra => {
                                auxregimenes.push(ra['$'].RegimenAduanero);
                            })
                            console.log(auxregimenes);
                            if (auxregimenes.length > 0) {
                                regimenAduanero = auxregimenes.join(',');
                            }
                            //console.log(DatCCP[0]['cartaporte31:RegimenesAduaneros'][0]['cartaporte31:RegimenAduaneroCCP']);

                        }


                    }
                    let NumTotalMercancias = "";
                    let PesoBrutoTotal = "";
                    if ((versionCCP === "3.0" || versionCCP === "3.1") && DatCCP[0][`cartaporte${versionstring}:Mercancias`][0]) {
                        if (DatCCP[0][`cartaporte${versionstring}:Mercancias`][0]['$']['PesoBrutoTotal']) {
                            PesoBrutoTotal = DatCCP[0][`cartaporte${versionstring}:Mercancias`][0]['$']['PesoBrutoTotal'];
                        }
                        if (DatCCP[0][`cartaporte${versionstring}:Mercancias`][0]['$']['NumTotalMercancias']) {
                            NumTotalMercancias = DatCCP[0][`cartaporte${versionstring}:Mercancias`][0]['$']['NumTotalMercancias'];
                        }
                        // console.log("dataaux",NumTotalMercancias);
                    }


                    //console.log(idCCP);

                    let relacionados = null;

                    if (result["cfdi:Comprobante"]["cfdi:CfdiRelacionados"] && result["cfdi:Comprobante"]["cfdi:CfdiRelacionados"][0] && result["cfdi:Comprobante"]["cfdi:CfdiRelacionados"][0]['$']) {
                        let tipoRelacion = result["cfdi:Comprobante"]["cfdi:CfdiRelacionados"][0]['$']['TipoRelacion']
                        let auxrelacionados = [];
                        result["cfdi:Comprobante"]["cfdi:CfdiRelacionados"][0]['cfdi:CfdiRelacionado'].forEach(rel => {
                            //  console.log('rel',rel);
                            auxrelacionados.push({ tiporelacion: tipoRelacion, uuid: rel['$']['UUID'] });
                        });
                        if (auxrelacionados.length > 0) {
                            relacionados = auxrelacionados;
                        }
                        //console.log("RELACIONADOS",auxrelacionados);
                    }
                    console.log("data conceptos", Conceptos)
                    console.log("segundo xml", result);
                    if (response) {
                        response = { ...response, ...objdataxml, NumTotalMercancias, PesoBrutoTotal, idDeCCP: idCCP, versionCCP, regimenAduanero, UsoCFDI, condicionesPago, relacionados, Conceptos, Impuestos, dataAutotransporte, RegimenFiscalReceptor, RegimenFiscalEmisor };
                    }
                    //response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                }

            });
            console.log("response xml", response);
            return response;
        } else {
            return null;
        }

    }

    useEffect(() => {
        if (objData) {
            console.log("data to pdf", objData);
        }
    }, [objData])

    /**
    *  <PDFDownloadLink document={<MyDoc />} fileName="somename.pdf">
    {({ blob, url, loading, error }) =>
    loading ? 'Loading document...' : 'Download now!'
    }
    </PDFDownloadLink>
    <PDFViewer fileName="myPdf.pdf" style={{ width: '100vw', height: '99vh' }}>
    <CartaPorte objdata={objData} algo="hola mundo" />
    </PDFViewer>
    
    */

    return (
        <div>
            {

                objData &&
                <div>

                    {requiereDescargaDirecta.find(f => f === uidAltaEdit) ? <PDFDownloadLink document={<CartaPorte objdata={objData} algo="hola mundo" />} fileName="ccp.pdf">
                        {({ blob, url, loading, error }) =>
                            loading ? 'Cargando documento...' : 'Descargar!'
                        }
                    </PDFDownloadLink> : <PDFViewer style={{ width: '100vw', height: '99vh' }}>
                        <CartaPorte objdata={objData} algo="hola mundo" contrato1={requiereContrato1.find(f => f === uidAltaEdit) ? true : false} />
                    </PDFViewer>}

                </div>
            }
        </div>);

}

export default PDFView;