import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const requiereSubliente = ['HptPbvVxYYUjUzp7Nt3TtmyAt0x1','31RxHqtBOmbgOWWeMCKnb1DuEHc2'];
const requiereManiobras = ['Is55uxcCDdMdI53LaFtWvyFXGH03'];
const requiereInfoPagoLiquidacion = ['RZHc17ViPqQTIJ7VShGfbBRwXBw1', 'xxxLOZJRkQRLvbN2mWSjX4MFNr1qvj2'];


export default class Download extends React.Component {

    constructor(props) {
        super();
        let { data } = props;
        let { uid } = props;
        this.state = {
            dataSet1: data,
            uid
        }
        //   console.log(this.state);

    }

    componentDidUpdate = (prevProps) => {

    }

    render() {
        if (this.state.uid && requiereManiobras.find(rs => rs === this.state.uid)) {
            return (
                <ExcelFile element={
                    <div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>
                }>
                    <ExcelSheet data={this.state.dataSet1} name="Viajes">
                        <ExcelColumn label="Viaje" value="viaje" />
                        <ExcelColumn label="Carta Porte" value="carta" />
                        <ExcelColumn label="Tipo de viaje" value="tipoviaje" />
                        <ExcelColumn label="Segmento de Negocio" value="segmentoNegocio" />
                        <ExcelColumn label="Estatus" value="estatus" />
                        <ExcelColumn label="Recolección" value="recoleccion" />
                        <ExcelColumn label="Entrega" value="entrega" />
                        <ExcelColumn label="Cliente" value="cliente" />
                        <ExcelColumn label="Operador" value="operador" />
                        <ExcelColumn label="Comisión" value="comision" />
                        <ExcelColumn label="Unidad" value="unidad" />
                        <ExcelColumn label="Origen" value="origen" />
                        <ExcelColumn label="Destino" value="destino" />
                        <ExcelColumn label="Remolque1" value="remolque1" />
                        <ExcelColumn label="Remolque2" value="remolque2" />
                        <ExcelColumn label="Kms." value="km" />
                        <ExcelColumn label="Referencia" value="referencia" />
                        <ExcelColumn label="Total" value="tarifa" />
                        <ExcelColumn label="Adicionales" value="adicionales" />
                        <ExcelColumn label="SubTotal" value="subTarifa" />
                        <ExcelColumn label="Moneda" value="moneda" />
                        <ExcelColumn label="Ingreso x Km." value="ingresokm" />
                        <ExcelColumn label="Comentarios" value="detalles" />
                        <ExcelColumn label="Recibo Maniobra" value="recibo" />
                        <ExcelColumn label="Cantidad" value="cantidad" />
                        <ExcelColumn label="Recibo Devolución" value="devolucion" />
                        <ExcelColumn label="Entrega Digital" value="fechaDigital" />
                        <ExcelColumn label="Entrega Físico" value="fechaFisico" />
                    </ExcelSheet>

                </ExcelFile>
            );
        }
        else if (this.state.uid && requiereSubliente.find(rs => rs === this.state.uid)) {
            return (
                <ExcelFile element={
                    <div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>
                }>
                    <ExcelSheet data={this.state.dataSet1} name="Viajes">
                        <ExcelColumn label="Viaje" value="viaje" />
                        <ExcelColumn label="Carta Porte" value="carta" />
                        <ExcelColumn label="Tipo de viaje" value="tipoviaje" />
                        <ExcelColumn label="Clase de viaje" value="auxiliar1" />
                        <ExcelColumn label="Segmento de Negocio" value="segmentoNegocio" />
                        <ExcelColumn label="Estatus" value="estatus" />
                        <ExcelColumn label="Recolección" value="recoleccion" />
                        <ExcelColumn label="Entrega" value="entrega" />
                        <ExcelColumn label="Cliente" value="cliente" />
                        <ExcelColumn label="Subcliente" value="subcliente" />
                        <ExcelColumn label="Operador" value="operador" />
                        <ExcelColumn label="Comisión" value="comision" />
                        <ExcelColumn label="Unidad" value="unidad" />
                        <ExcelColumn label="Origen" value="origen" />
                        <ExcelColumn label="Destino" value="destino" />
                        <ExcelColumn label="Remolque1" value="remolque1" />
                        <ExcelColumn label="Remolque2" value="remolque2" />
                        <ExcelColumn label="Kms." value="km" />
                        <ExcelColumn label="Referencia" value="referencia" />
                        <ExcelColumn label="Total" value="tarifa" />
                        <ExcelColumn label="Adicionales" value="adicionales" />
                        <ExcelColumn label="SubTotal" value="subTarifa" />
                        <ExcelColumn label="Moneda" value="moneda" />
                        <ExcelColumn label="Ingreso x Km." value="ingresokm" />
                        <ExcelColumn label="Comentarios" value="detalles" />
                    </ExcelSheet>

                </ExcelFile>
            );
        } else if (this.state.uid && requiereInfoPagoLiquidacion.find(rs => rs === this.state.uid)) {
            return (
                <ExcelFile element={
                    <div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>
                }>
                    <ExcelSheet data={this.state.dataSet1} name="Viajes">
                        <ExcelColumn label="Viaje" value="viaje" />
                        <ExcelColumn label="Carta Porte" value="carta" />
                        <ExcelColumn label="Tipo de viaje" value="tipoviaje" />
                        <ExcelColumn label="Segmento de Negocio" value="segmentoNegocio" />
                        <ExcelColumn label="Estatus" value="estatus" />
                        <ExcelColumn label="Recolección" value="recoleccion" />
                        <ExcelColumn label="Entrega" value="entrega" />
                        <ExcelColumn label="Cliente" value="cliente" />
                        <ExcelColumn label="Operador" value="operador" />
                        <ExcelColumn label="Comisión" value={(col) => col.comision ? parseFloat(col.comision.split("$").join("").split(",").join("")) : ""} />
                        <ExcelColumn label="Comisión Pagada" value="auxiliar1" />
                        <ExcelColumn label="Unidad" value="unidad" />
                        <ExcelColumn label="Origen" value="origen" />
                        <ExcelColumn label="Destino" value="destino" />
                        <ExcelColumn label="Remolque1" value="remolque1" />
                        <ExcelColumn label="Remolque2" value="remolque2" />
                        <ExcelColumn label="Kms." value="km" />
                        <ExcelColumn label="Referencia" value="referencia" />
                        <ExcelColumn label="Total" value={(col) => col.tarifa ? parseFloat(col.tarifa.split("$").join("").split(",").join("")) : ""} />
                        <ExcelColumn label="Adicionales" value="adicionales" />
                        <ExcelColumn label="Moneda" value="moneda" />
                        <ExcelColumn label="Ingreso x Km." value="ingresokm" />
                        <ExcelColumn label="Comentarios" value="detalles" />

                    </ExcelSheet>

                </ExcelFile>
            );
        } else {
            return (
                <ExcelFile element={
                    <div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>
                }>
                    <ExcelSheet data={this.state.dataSet1} name="Viajes">
                        <ExcelColumn label="Viaje" value="viaje" />
                        <ExcelColumn label="Carta Porte" value="carta" />
                        <ExcelColumn label="Tipo de viaje" value="tipoviaje" />
                        <ExcelColumn label="Segmento de Negocio" value="segmentoNegocio" />
                        <ExcelColumn label="Estatus" value="estatus" />
                        <ExcelColumn label="Recolección" value="recoleccion" />
                        <ExcelColumn label="Entrega" value="entrega" />
                        <ExcelColumn label="Cliente" value="cliente" />
                        <ExcelColumn label="Operador" value="operador" />
                        <ExcelColumn label="Comisión" value="comision" />
                        <ExcelColumn label="Unidad" value="unidad" />
                        <ExcelColumn label="Origen" value="origen" />
                        <ExcelColumn label="Destino" value="destino" />
                        <ExcelColumn label="Remolque1" value="remolque1" />
                        <ExcelColumn label="Remolque2" value="remolque2" />
                        <ExcelColumn label="Kms." value="km" />
                        <ExcelColumn label="Referencia" value="referencia" />
                        <ExcelColumn label="Total" value="tarifa" />
                        <ExcelColumn label="Adicionales" value="adicionales" />
                        <ExcelColumn label="Moneda" value="moneda" />
                        <ExcelColumn label="Ingreso x Km." value="ingresokm" />
                        <ExcelColumn label="Comentarios" value="detalles" />
                    </ExcelSheet>

                </ExcelFile>
            );
        }

    }
}
