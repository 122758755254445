import { firebase } from '../configs/firebase';
export const addCPAlone = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('cp_alone').add(dataaux);
}
export const updateCPAlone = (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date()};
    var db = firebase.firestore();
    return db.collection('cp_alone').doc(id).update(dataaux);
}