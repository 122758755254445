import React, { useState, useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { getUserByUid } from '../../../controllers/usuarios';
import { getClienteByID } from '../../../controllers/clientes';
import { getViajeById } from '../../../controllers/viajes';

import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';


const requiereCoordinacionFlete=['LIv7EZL3RZhZnI3ZGpRXeehkZWA2','f5ti9SuBPVgdMf9rNa47mzPjmrl1'];
const conceptos = [
    { clave: "78101800", descripcion: "Flete" },
    { clave: "78141500", descripcion: "Coordinación de logística en transporte" },
];

const ModalCartaPorteConfirm = (props) => {
    //constantes

    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);
    const [idViaje, setIdViaje] = useState(null);


    const [chekingCSD, setCheckinCSD] = useState(true);

    const [nviaje, setNviaje] = useState("");
    const [ncarta, setNcarta] = useState("");
    const [cliente, setCliente] = useState("");
    const [clienteId, setClienteId] = useState(null);
    const [clienteData, setClietneData] = useState(null);
    const [isIVA16, setIsIVA16] = useState(true);
    const [facturaArchivos, setFacturaArchivos] = useState(null);
    const [ivatasa, setIvaTasa] = useState("16");
    const [razonesSociales, setRazonesSociales] = useState([]);
    const [rfc, setRfc] = useState("");
    const [showTarifa, setShowTarifa] = useState(false);

    const [concepto, setConcepto] = useState("78101800");

    const [allowTimbrado, setAllowTimbrado] = useState(true);
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);

    useEffect(() => {
        if (user && user.razones) {
            setRazonesSociales(user.razones);
            if (user.razones.length > 0 && user.razones[0].Rfc) {
                setRfc(user.razones[0].Rfc);
            }
        }
    }, [user]);
    const checkCSD = async () => {
        console.log("cheking csd", uid);
        if (uid) {
            getUserByUid(uid).then(resp => {
                setCheckinCSD(false);
                //console.log("datos usuario",resp.docs[0].data());
                if (resp.docs.length > 0 && resp.docs[0].data().csd) {
                    setUser({ ...resp.docs[0].data(), id: resp.docs[0].id });
                    initValues();
                } else {
                    Swal.fire("No CSD", "No se han cargado los CSD para esta cuenta, da de alta primero tus credenciales de facturacion en la seccion de dashboard -> configuraciones ", 'warning');
                    setIsLoading(false);
                    setIsOpen(false);
                }
            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar CSD, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });
        } else {
            Swal.fire("Error", "Error sesion terminada", 'error');
            setIsLoading(false);
            setIsOpen(false);
        }

    }

    useEffect(() => {
        if (clienteId) {
            getClienteByID(clienteId).then(resp => {
                if (resp.data()) {
                    setClietneData(resp.data());
                }
            }).catch(err => { });
        }
    }, [clienteId]);


    useEffect(() => {
        if (recordedit != null) {
            if (!recordedit.detalles.currencyMX) {
                setIvaTasa("0");
            } else {
                setIvaTasa("16");
            }

            setNviaje(recordedit.general.nviaje || "");
            setNcarta(recordedit.general.ncarta || "");
            setCliente(recordedit.general.cliente || "");
            setClienteId(recordedit.general.clienteId || "");

            if (recordedit.cpfacuturado) {
                console.log("si esta timbrado");
                setFacturaArchivos(recordedit.cpfacuturado);
            } else {
                console.log("No esta timbrado");
                setFacturaArchivos(null);
            }
        }
    }, [recordedit])
    const initValues = async () => {
        console.log("init values", idViaje);
        if (idViaje != null) {

            await getViajeById(idViaje).then(document => {
                //console.log("llegaron datos");
                let o = document.data();
                //console.log(o);
                o.id = document.id;
                o.key = document.id;
                setRecordEdit(o);
                setIsLoading(false);
            }).catch(err => { console.log(err); setIsLoading(false); });
        }
    }

    useEffect(() => {

        setCheckinCSD(true);
        setUid(props.uid);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);

        //  initValues();

        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        //setRecordEdit(props.currenteditrecrod);
        if (props.currenteditrecrod) {
            setIdViaje(props.currenteditrecrod.key);
        }

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (idViaje) {

            initValues();
        }

    }, [idViaje]);


    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (localStorage.getItem('deny')) {
                let auxdeny = localStorage.getItem('deny').split(":");
                if (auxdeny.find(e => e === "timbrado")) {
                    setAllowTimbrado(false);
                }


            }
            checkCSD();
            if (recordedit) {
                // setTipo(recordedit.tipo);
                //  checkCSD();
            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    const descargarXML = () => {
        //console.log(facturaArchivos);
        if (facturaArchivos) {
            var xmltext = facturaArchivos.cfdixml;

            let nombrefactura = "";
            if (clienteData) {
                nombrefactura = facturaArchivos.noCertificado || clienteData.rfc;
            }
            var filename = `cp_${nombrefactura}.xml`;
            var pom = document.createElement('a');
            var bb = new Blob([xmltext], { type: 'text/plain' });

            pom.setAttribute('href', window.URL.createObjectURL(bb));
            pom.setAttribute('download', filename);

            pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
            pom.draggable = true;
            pom.classList.add('dragout');

            pom.click();
        }
    }
    const previzualizar = () => {

        if (!facturaArchivos && recordedit && recordedit.key) {
            let dates = new Date();
            if(uid && requiereCoordinacionFlete.find(f=>f===uid)){
                const win = window.open('/cporte/' + recordedit.key + "?rfc=" + rfc + "&d=" + dates.getTime() + "&t=" + showTarifa+"&c="+concepto, "_blank");
                win.focus();
            }else{
                const win = window.open('/cporte/' + recordedit.key + "?rfc=" + rfc + "&d=" + dates.getTime() + "&t=" + showTarifa, "_blank");
                win.focus();
            }

           
            
        } else if (facturaArchivos) {
            let dates = new Date();
            if(uid && requiereCoordinacionFlete.find(f=>f===uid)){
                const win = window.open('/cporte/' + recordedit.key + "?rfc=" + facturaArchivos.rfc + "&d=" + dates.getTime() + "&t=" + showTarifa+"&c="+concepto, "_blank");
                win.focus();
            }
            else{
                const win = window.open('/cporte/' + recordedit.key + "?rfc=" + facturaArchivos.rfc + "&d=" + dates.getTime() + "&t=" + showTarifa, "_blank");
                win.focus();
            }
           
        } else {
            //alert("here");
        }
    }
    const actionTImbrar = async () => {
        setIsLoading(true);
        //(http://localhost:5000/transport-plus-98f66/us-central1/app
        //https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/timbrarcp
        let fechaPeticionPreventCache = new Date().getTime();
        //https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app
        let rutaPost = "https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/timbrarcp";
        if (rfc === "EKU9003173C9") {
            rutaPost = "https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/timbrarcp";
        }
        await axios.post(rutaPost, { id: recordedit.key, rfc, showTarifa, fechaPeticionPreventCache }).then(resp => {
            setIsLoading(false);
            props.notify();
            props.toggle();
            Swal.fire("Carta porte", "Se ha timbrado la carta porte", 'success').then(r => {
                const win = window.open('/cporte/' + recordedit.key + "?rfc=" + rfc, "_blank");
                win.focus();
            });
            //console.log("respuesta", resp);
        }).catch((err,r) => {
            setIsLoading(false);
            console.log("error0",err.response);
            console.log("error", err);
            if (err.toString().includes("Network Error")) {
                Swal.fire("Error", "Error de conexión, no fue posible conectar con el servidor, revisa tu conexión", 'error');
            } else {
                if(err.response && err.response.data && err.response.data.estatus && err.response.data.estatus.informacionTecnica){
                    Swal.fire("Error", `No se pudo timbrar la carta porte:${err.response.data.estatus.informacionTecnica}`, 'error');
                }else{
                    Swal.fire("Error", "No se pudo timbrar la carta porte", 'error');
                }
               
            }
        });
    }
    const timbrar = async () => {
        if (!facturaArchivos && recordedit && recordedit.key) {
            Swal.fire({
                title: '¿Esta seguro de timbrar la carta porte seleccionada?',
                text: "Se timbrara el carta seleccionada al RFC " + rfc,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1c666f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Timbrar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    actionTImbrar();

                    //console.log("hereee");
                }

            });



        }
    }
    const handleSumbit = async (e) => {
        e.preventDefault();

    }



    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {facturaArchivos ? "Ver Carta Porte" : `Carta Porte `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <LoadingOverlay
                        active={chekingCSD}
                        spinner
                        text='Comprobando CSD, espere ...'
                    >
                        {
                            facturaArchivos ? <div>
                                <div className="row">
                                    <div className="col-6"> <div style={{ width: "100%" }} className="btn btn-primary" onClick={() => previzualizar()}>Ver Carta Porte</div> </div>
                                    <div className="col-6"> <div style={{ width: "100%" }} className="btn btn-primary" onClick={() => descargarXML()}>Descargar XML</div> </div>

                                </div>
                            </div> :
                                <div className="col-12">
                                    <div className="col-12">
                                        {
                                            razonesSociales.length > 1 && <div className="form-group col-12">
                                                <label>Razón Social</label><label className="requiredlabel">*</label>
                                                <select className={"form-control "} name='rfc' onChange={(e) => setRfc(e.target.value)} value={rfc} required >
                                                    {
                                                        razonesSociales.map((item, index) => {
                                                            return <option key={index} value={item.Rfc}>{item.Razon}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        }
                                    </div>
                                    {uid && requiereCoordinacionFlete.find(f=>f===uid) && <div className="form-group col-3">
                                        <label>Concepto</label>
                                        <select name='rfc' onChange={(e) => setConcepto(e.target.value)} value={concepto} required >
                                            {
                                                conceptos.map((item, index) => {
                                                    return <option key={index} value={`${item.clave}`} >{`${item.clave.split("x").join("")} - ${item.descripcion}`}</option>
                                                })
                                            }

                                        </select>

                                    </div>}
                                    <div className="form-group col-3">
                                        <label>Mostrar tarifa de flete</label>
                                        <Checkbox
                                            checked={showTarifa}
                                            onChange={() => setShowTarifa(!showTarifa)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-6"> <div style={{ width: "100%" }} className="btn btn-primary" onClick={() => previzualizar()}>Previzualizar Carta Porte</div> </div>
                                        {/*<div className="col-6"> <div style={{ width: "100%" }} className="btn btn-primary disabled">Timbrado de Carta porte deshabilitado</div> </div>*/}
                                        <div className="col-6"> <div style={{ width: "100%" }} className="btn btn-primary" onClick={() => timbrar()}>Timbrar Carta Porte</div> </div>
                                    </div>
                                </div>
                        }

                    </LoadingOverlay>
                </ModalBody>
                <ModalFooter>
                    {!facturaArchivos && <div style={{ marginRight: 'auto' }}></div>}
                    <button type="button" className="btn btn-secondary" onClick={() => setIsOpen(false)} >CANCELAR</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >
}
export default ModalCartaPorteConfirm;