import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



export default class Download extends React.Component {
    constructor(props)
    {
        super();
        let {data}=props;
        this.state = {
            dataSet1: data,
        }
     //   console.log(this.state);

    }

    componentDidUpdate=(prevProps)=>{
        
    }
    /**
     * 
     * numEconomico: element.numEconomico,
                                                            propiedad: element.propiedad,
                                                            tipo: element.tipo,
                                                            placas: element.placas,
                                                            marca: element.marca,
                                                            year: element.year,
                                                            descripcion: element.descripcion,
                                                            status: element.active ? "Activo" : "Inactivo"
     */
    
    render() {
        return (
            <ExcelFile  element={<div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>}>
                <ExcelSheet data={this.state.dataSet1} name="Combustible"> 
                    <ExcelColumn label="Unidad" value="unidad"/>
                    <ExcelColumn label="Factura" value="factura"/>
                    <ExcelColumn label="Fecha" value="fecha"/>
                    <ExcelColumn label="Cantidad" value="cantidad"/>
                    <ExcelColumn label="Costo" value="costo"/>
                    <ExcelColumn label="Total" value="total"/>
                    <ExcelColumn label="Km anterior" value="kmanterior"/>
                    <ExcelColumn label="Km actual" value="kmactual"/>
                </ExcelSheet>
               
            </ExcelFile>
        );
    }
}
