import { useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
const style = {
    height: '100%',
    width: '10rem',
   // marginRight: '1rem',
   // marginBottom: '1rem',
    color: 'white',
    padding: '5px',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: 'normal',
    float: 'left',
};
export const DustbinPreplan = (id,descrip) => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: ItemTypes.BOXPREPLAN,
        drop: () => ({ name: 'Dustbin',id,descrip }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));
    const isActive = canDrop && isOver;
    let backgroundColor = '#8294af';
    if (isActive) {
        backgroundColor = '#6cc555';
    }
    else if (canDrop) {
        backgroundColor = 'darkkhaki';
    }
    return (<div ref={drop} role={'Dustbin'} style={{ ...style, backgroundColor }}>
			{isActive ? 'Suelta para asignar' : 'Arrastra viaje aquí'}
		</div>);
};
