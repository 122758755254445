import React, { useState, useEffect, Fragment } from 'react';
import { firebase } from '../../../configs/firebase';
import { deleteIngreso } from '../../../controllers/ingresos';
import { getUserByUid, getAllUsuarios } from '../../../controllers/usuarios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import { addDays } from "date-fns";
import { HotTable, HotColumn } from '@handsontable/react';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { registerLanguageDictionary } from 'handsontable/i18n';
import esMX from 'handsontable/i18n/languages/es-MX';
import BuildIcon from '@material-ui/icons/Build';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ModalCreateEdit from '../ModalCreateEdit';
import ModalServicios from '../ModalServicios';
import { deleteMantenimiento, getMantenimientos } from '../../../controllers/proveedor';
import { getActivos } from '../../../controllers/activos';
import { ActionRender, EstatusRender } from './ActionsRender';
import { deleteProveedor } from '../../../controllers/proveedor';
import { useHistory } from 'react-router-dom';
import { getViajesPlaneadosFromActivoCliente } from '../../../controllers/viajes';
import { getUidMaster } from '../../../session/session_controller';
//import { ActionRender, DateRender, MoneyRender } from './ActionsRender';
import Download from './ExcelExport';

const Mantenimiento = props => {
    let history = useHistory();
    registerLanguageDictionary(esMX);
    registerLocale('es', es)

    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [firstDay, setFirstDay] = useState(null);
    const [lastDay, setLastDay] = useState(null);
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [querysearch, setQuerysearch] = useState('');
    const [ingresos, setIngresos] = useState([]);
    const [ingresosNoFilter, setIngresosNoFilter] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenServicios, setIsOpenServicios] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currenteditrecrodMantenimiento, setCurrentEditRecordMantenimiento] = useState(null);
    const [currenteditrecrodServicios, setCurrentEditRecordServicios] = useState(null);

    const [alertas, setAlertas] = useState([]);

    const validateAdmin = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (user.type && user.type === "administrador") {
                    isadmin = true;
                }
            });
            if (isadmin) {
                setAdminCurrentUser(uid);
                setIsAdmin(true);
            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                setIsAdmin(false);
                setUserlist([]);
                // this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }

    useEffect(() => {
        /*if (isAdmin) {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                setUserlist(a);
            }).catch(e => {
                console.log(e);
                //this.setState({isLoading:false,})
            });
        }*/
    }, [isAdmin]);


    useEffect(() => {


        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {
                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);
                setUser(userr);
                // setAdminCurrentUser(userr.uid);

                if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                    // this.validateAdmin(user.uid);
                    //console.log("validando admin");
                    validateAdmin(userr.uid);
                }
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });

    }, []);


    useEffect(() => {
        if (querysearch.trim() === '') {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                aux.push(element);
            });
            setIngresos(aux);
        } else {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                let query = querysearch.trim().toUpperCase();
                if (element.nombre.toUpperCase().includes(query) ||
                    element.rfc.toUpperCase().includes(query)
                ) {
                    aux.push(element);
                }

            });
            setIngresos(aux);
        }
    }, [querysearch]);

    useEffect(() => {
        getData(user);
    }, [user]);

    useEffect(() => {
        if (!isOpen) {
            setCurrentEditRecordMantenimiento(null);
        }
    }, [isOpen])

    useEffect(() => {
        if (!isOpen) {
            setCurrentEditRecordServicios(null);
        }
    }, [isOpenServicios])


    const refresh = () => {
        getData(user);
    }



    const handleDateChanged = (date) => {
        let fDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);
        setCurrentDate(date);
    }

    const findRecord = (id) => {
        let obj = ingresos.find(element => element.id === id);
        return obj;
    }

    const getData = async (usertodisplay) => {
        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }
            // setIsLoading(true);
            let a = [];
            let index = 0;
            var moment = require('moment'); // require
            setIsLoading(true);
            await getMantenimientos(usertoconsult.uid).then(snapshot => {
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    o.id = doc.id;
                    o.key = doc.id;
                    o.recurrentedes = o.recurrente == "1" ? "Si" : "No";
                    index++;
                    a.push(o);
                });
                setIngresos(a);
                setIngresosNoFilter(a);
                setIsLoading(false);
                //console.log("setting clientes");

                // setIngresos(a);

                //  setIngresosNoFilter(a);
                //   setIsLoading(false);
            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar los proveedores, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });

            let auxalerts = [];
            let activos = [];
            await getActivos(usertoconsult.uid).then(resp => {
                resp.forEach(doc => {
                    let dact = doc.data();
                    dact.id = doc.id;
                    activos.push(dact);
                });
            });

            a.forEach(mantenim => {
                if (mantenim.condicionRecurrencia === "km" || mantenim.condicionRecurrencia === "ambos") {
                    let findactivmant = activos.find(fa => fa.id === mantenim.unidad);
                    if (findactivmant) {
                        let kmsservicio = parseFloat(mantenim.servicioKms) - parseFloat(findactivmant.kms || 0);
                        if (kmsservicio <= 0) {
                            auxalerts.push({ idservicio: mantenim.id, descipcion: `Mantenimiento requerido: ${mantenim.tarea} ${mantenim.unidadNombre} (Se llegó al kilometraje)`, tipo: 'danger' })

                        }
                        else if (kmsservicio < 1000) {
                            auxalerts.push({ idservicio: mantenim.id, descipcion: `Mantenimiento Próximo: ${mantenim.tarea} ${mantenim.unidadNombre} (${kmsservicio} kms para servicio)`, tipo: 'warning' })
                        }
                        console.log("se encontro activo comprobando kilometraje", kmsservicio);
                    }
                }
               
                if (mantenim.condicionRecurrencia === "tiempo" || mantenim.condicionRecurrencia === "ambos") {
                        
                    let fechaActual=new Date();
                    let proxima = mantenim.proximaFecha.toDate();
                    
                    let fechaacttualmmoment=moment(fechaActual);
                    let proximamoment=moment(proxima);
                    let diasdiff=proximamoment.diff(fechaacttualmmoment,'days');

                    if (diasdiff <= 0) {
                        auxalerts.push({ idservicio: mantenim.id, descipcion: `Mantenimiento requerido: ${mantenim.tarea} ${mantenim.unidadNombre} fecha programada ${proximamoment.format(`DD/MM/YYYY`)}`, tipo: 'danger' })
                    }else if (diasdiff <= 7) {
                        auxalerts.push({ idservicio: mantenim.id, descipcion: `Mantenimiento Próximo: ${mantenim.tarea} ${mantenim.unidadNombre} fecha programada ${proximamoment.format(`DD/MM/YYYY`)}`, tipo: 'warning' })
                    }

                    console.log("comparando fechas",fechaacttualmmoment.diff(proximamoment,'days'));
                }

            });
            setAlertas(auxalerts);
            /**
             * action getdata
             */
            /*await getAllIngresosByUserDateFilter(usertoconsult.uid, firstDay, lastDay).then(snapshot => {
                            snapshot.forEach(doc => {
                                let o = doc.data();
                                o.index = doc.id;
                                o.n = index;
                                o.id = doc.id;
                                o.key = doc.id;
                                o.fechaformat = moment(o.fecha.toDate()).format('DD/MM/YYYY');
                                index++;
                                a.push(o);
                            });
                            setIngresos(a);
                            setIngresosNoFilter(a);
                            setIsLoading(false);
                        }).catch(err => {
                            console.log(err);
                            Swal.fire("Error", "Error de al consultar los ingresos, comprueba tu conexión ", 'error');
                            setIsLoading(false);
                        });*/
        } else {
            //console.log("user null");
        }
    }

    useEffect(() => {
        if (currenteditrecrodMantenimiento != null) {
            if (currenteditrecrodMantenimiento.none) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
            }
            setIsOpen(true);
        }
    }, [currenteditrecrodMantenimiento])


    useEffect(() => {
        if (currenteditrecrodServicios != null) {
            if (currenteditrecrodServicios.none) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
            }
            setIsOpenServicios(true);
        }
    }, [currenteditrecrodServicios])

    const handleCreate = () => {
        setIsEdit(false);
        setCurrentEditRecordMantenimiento({ none: true });
        //setIsOpen(true);
    }
    const handleOpenServicios = async (id) => {
        // let obj = ingresos.find(element => element.id === id);

        let confirmacion = false;
        await Swal.fire({
            title: '¿Deseas generar el siguiente serivicio?',
            text: "Se generara el siguiente servicio para el plan de mantenimiento.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c666f',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Generar Servicio',
            cancelButtonText: 'Cancelar'
        }).then((result) => {

            if (result.value) {
                confirmacion = true;
            }

        });

        if (confirmacion && id && id !== "")
            history.push('/servicio?p=' + id);


        //console.log(obj);
        /*if (obj) {
            setIsEdit(true);
            setCurrentEditRecordServicios(obj);

        }*/
    }
    const handleOpenHistoricoServicios = async (id) => {
        let obj = ingresos.find(element => element.id === id);


        //console.log(obj);
        if (obj) {
            setIsEdit(true);
            setCurrentEditRecordServicios(obj);

        }
    }
    const handleEditFromId = id => {
        let obj = ingresos.find(element => element.id === id);
        if (obj) {
            setIsEdit(true);
            setCurrentEditRecordMantenimiento(obj);

        }
    }
    const handleDeleteFromId = id => {
        let obj = ingresos.find(element => element.id === id);
        if (obj) {
            Swal.fire({
                title: '¿Esta seguro de eliminar la el plan de mantenimiento seleccionado?',
                text: "Una vez eliminada no podrá ser recuperado.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1c666f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    deleteMantenimiento(id).then(() => {
                        refresh();
                        Swal.fire('Éxito', 'Se ha eliminado con éxito.', 'success');
                    }).catch(err => {
                        Swal.fire("Error", "Error al eliminar el cliente revisa tu conexión", 'error');

                    });
                }

            });
        }
    }

    return (
        <Fragment>
            {
                <div className="row">
                    <div className="col-8">
                        <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>&nbsp;Mantenimientos Preventivos</h3>
                        <div className="card">
                            <div className="card-header card-header-flex">

                                <div className="d-flex px-3 row justify-content-between align-items-center">
                                    <div style={{ height: '46px', padding: '5px' }}>
                                        <div className="col-auto pl-0" style={{ display: 'flex' }}>
                                            <button type="button" style={{ marginRight: '10px' }} className="boton primBtn add ms-right" onClick={() => handleCreate()}><span>Agregar Mantenimiento</span></button>
                                            {
                                                /*!isLoading && ingresos.length > 0 && <Download data={
                                                    ingresos.map(element => {
                                                        return {
                                                            nombre: element.nombre,
                                                            rfc: element.rfc,
                                                            localidad: element.localidad,
                                                            estado: element.estado,
                                                           
    
                                                        }
                                                    })
                                                } className="btn btn-primary btn-sm" />*/
                                            }
                                            {/* <TextField
                                            style={{ height: '20px' }}
                                            variant="outlined"
                                            size="small"
                                            id="input-with-icon-textfield"
                                            label="Buscar"
                                            value={querysearch}
                                            onChange={(e) => setQuerysearch(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />*/}

                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                    <div>
                                    </div>

                                </div>
                            </div>
                            <LoadingOverlay
                                active={isLoading}
                                spinner
                                text='Actualizando datos, espere ...'
                            >
                                <div className="card-body">
                                    <HotTable settings={{
                                        colHeaders: true,
                                        rowHeaders: true,
                                        autoRowSize: false,
                                        columnSorting: true,
                                        dropdownMenu: ['filter_by_condition',
                                            'filter_by_value',
                                            'filter_action_bar'],
                                        filters: true,
                                        manualColumnResize: true,
                                        colWidths: [280, 140, 180, 180, 120],
                                        //para ancho total                                    
                                        //  minSpareRows: 1,          // almenos una fila
                                        // autoColumnSize: true,     // 
                                        //  stretchH: 'all',          // 
                                        //disableVisualSelection: true,
                                        licenseKey: "non-commercial-and-evaluation",
                                        language: "es-MX"
                                    }} data={ingresos} colHeaders={true} rowHeaders={false} width="100%" height="400" >

                                        <HotColumn className="htCenter htMiddle" readOnly title="Tarea" data="tarea" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Activo" data="unidadNombre" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Recurrente" data="recurrentedes" />
                                        <HotColumn className="htCenter htMiddle" render readOnly title="Estatus" data="active" >
                                            <EstatusRender hot-renderer></EstatusRender>
                                        </HotColumn>
                                        <HotColumn className="htCenter htMiddle" readOnly title="Acciones" data="id"  >
                                            <ActionRender hot-renderer handleOpenServicios={handleOpenServicios} handleOpenHistoricoServicios={handleOpenHistoricoServicios} handleEditFromId={handleEditFromId} handleDeleteFromId={handleDeleteFromId} />
                                        </HotColumn>

                                    </HotTable>


                                </div>


                            </LoadingOverlay>
                        </div>
                    </div>
                    <div className="col-4">

                        <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>&nbsp;</h3>
                        <div className="card">
                            <div className="card-header card-header-flex">

                                <div className="d-flex px-3 row justify-content-between align-items-center">
                                    <div style={{ height: '46px', padding: '5px' }}>
                                        <div className="col-auto pl-0" style={{ display: 'flex' }}>
                                            <h2>Alertas</h2>
                                        </div>
                                    </div>
                                    <div>


                                    </div>
                                    <div>

                                    </div>

                                </div>
                            </div>
                            {<div className="card-body">
                                <ul className="list-group">
                                    {
                                        alertas.map((aa, index) => {
                                            return <li key={'alert' + index} className={`list-group-item list-group-item-${aa.tipo}`}>  <BuildIcon role="button" size="small" style={{
                                                color: "#2fa2fa",

                                            }} onClick={() => handleOpenServicios(aa.idservicio)} aria-label="editar">

                                            </BuildIcon>{aa.descipcion} </li>
                                        })
                                    }


                                </ul>
                            </div>}
                        </div>
                    </div>
                    <ModalCreateEdit
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrodMantenimiento}
                        currentDate={currentDate}
                        isEdit={isEdit}
                        isOpen={isOpen}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={refresh}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setIsOpen(false)} />
                    <ModalServicios
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrodServicios}
                        currentDate={currentDate}
                        isEdit={isEdit}
                        isOpen={isOpenServicios}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={refresh}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setIsOpenServicios(false)} />
                </div>
            }
        </Fragment>
    );
}

export default Mantenimiento;