import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



export default class Download extends React.Component {
    constructor(props)
    {
        super();
        let {data}=props;
        this.state = {
            dataSet1: data,
        }
     //   console.log(this.state);

    }

    componentDidUpdate=(prevProps)=>{
        
    }
    
    render() {
        return (
            <ExcelFile  element={
                <div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>
              }>
                <ExcelSheet data={this.state.dataSet1} name="Ingresos"> 
                    <ExcelColumn label="Cliente" value="cliente"/>
                    <ExcelColumn label="Referencia" value="referencia"/>
                    <ExcelColumn label="Tipo" value="tipo"/>
                    <ExcelColumn label="Monto" value="monto"/>
                    <ExcelColumn label="IVA" value="iva"/>
                    <ExcelColumn label="Fecha" value="fechaformat"/>
                    <ExcelColumn label="Detalles" value="descripcion"/>
                    <ExcelColumn label="Folio Fiscal" value="foliofiscal"/>
                    
                </ExcelSheet>
               
            </ExcelFile>
        );
    }
}
