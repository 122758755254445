import { firebase } from '../configs/firebase';
export const getGetTabuladorOperadoresById = (id) => {
    var db = firebase.firestore();
    return db.collection("tabuladorops").doc(id).get();
}

export const getTabuladorOperadores = (uid) => {
    var db = firebase.firestore();
    return db.collection("tabuladorops").where("uid", "==", uid).get();
}

export const getTabuladorOperadoresByRuta = (uid, ruta, segmento) => {
    var db = firebase.firestore();
    return db.collection("tabuladorops").where("uid", "==", uid).where("ruta", "==", ruta).where("segmentoNegocio", "==", segmento).get();
}


export const addTabuladorOperadores = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('tabuladorops').add(dataaux);
}

export const updateTabuladorOperadores = (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date() };
    var db = firebase.firestore();
    return db.collection('tabuladorops').doc(id).update(dataaux);
}

export const deleteTabuladorOperadores = (id) => {
    var db = firebase.firestore();
    return db.collection('tabuladorops').doc(id).delete();
}
