import React, { useState, useEffect, Fragment } from 'react';
import { firebase } from '../../../configs/firebase';
import { deleteIngreso } from '../../../controllers/ingresos';
import { getUserByUid, getAllUsuarios } from '../../../controllers/usuarios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import { addDays } from "date-fns";
import { HotTable, HotColumn } from '@handsontable/react';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { registerLanguageDictionary } from 'handsontable/i18n';
import esMX from 'handsontable/i18n/languages/es-MX';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ModalCreateEdit from '../ModalCreateEdit';
import { getAllIngresosByUserDateFilter, getIngresoById, reactivarDeuda } from '../../../controllers/ingresos';
import { ActionRender, DateRender, MoneyRender } from './ActionsRender';
import Download from './ExcelExport';
import { getUidMaster } from '../../../session/session_controller';
import ModalComplementoPago from '../../complementopago/ModalComplementoPago'

const IngresoContent = (props) => {

    registerLanguageDictionary(esMX);
    registerLocale('es', es)

    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [firstDay, setFirstDay] = useState(null);
    const [lastDay, setLastDay] = useState(null);
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [querysearch, setQuerysearch] = useState('');
    const [ingresos, setIngresos] = useState([]);
    const [ingresosNoFilter, setIngresosNoFilter] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenCFDI, setIsOpenCFDI] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currenteditrecrod, setCurrentEditRecord] = useState(null);
    const [currenteditrecrodCFDI, setCurrentEditRecordCFDI] = useState(null);
    const [total, setTotal] = useState(0.0);
    const [totalMask, setTotalMask] = useState('');


    const validateAdmin = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (user.type && user.type === "administrador") {
                    isadmin = true;
                }
            });
            if (isadmin) {
                setAdminCurrentUser(uid);
                setIsAdmin(true);
            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                setIsAdmin(false);
                setUserlist([]);
                // this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }

    useEffect(() => {
        if (isAdmin) {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                setUserlist(a);
            }).catch(e => {
                console.log(e);
                //this.setState({isLoading:false,})
            });
        }
    }, [isAdmin]);

    useEffect(() => {
        setTotalMask(total.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
    }, [total]);

    useEffect(() => {


        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {
                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);

                setUser(userr);
                // setAdminCurrentUser(userr.uid);

                if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                    // this.validateAdmin(user.uid);
                    //console.log("validando admin");
                    validateAdmin(userr.uid);
                }
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });

    }, []);


    useEffect(() => {
        if (querysearch.trim() === '') {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                aux.push(element);
            });
            setIngresos(aux);
        } else {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                let query = querysearch.trim().toUpperCase();
                if (element.cliente.toUpperCase().includes(query) ||
                    element.referencia.toUpperCase().includes(query) ||
                    element.tipo.toUpperCase().includes(query) ||
                    element.monto.includes(query) ||
                    element.iva.includes(query) ||
                    element.descripcion.toUpperCase().includes(query)) {
                    aux.push(element);
                }

            });
            setIngresos(aux);
        }
    }, [querysearch]);

    useEffect(() => {
        getData(user);
    }, [user, currentDate, adminCurrentUser]);

    useEffect(() => {
        if (!isOpen) {
            setCurrentEditRecord(null);
        }
    }, [isOpen])


    useEffect(() => {
        //calculando totales
        let t = 0.0;
        ingresos.forEach(element => {
            if (element.montoFloat) {
                t += element.montoFloat;
            }
        });
        setTotal(t);
    }, [ingresos])

    const refresh = () => {
        getData(user);
    }



    const handleDateChanged = (date) => {
        let fDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);
        setCurrentDate(date);
    }

    const findRecord = (id) => {
        let obj = ingresos.find(element => element.id === id);
        return obj;
    }

    const getData = async (usertodisplay) => {
        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            if (getUidMaster()) {
                usertoconsult.uid = getUidMaster()
            }
            setIsLoading(true);
            let a = [];
            let index = 0;
            var moment = require('moment'); // require
            console.log("getting ingresos", { firstDay, lastDay });
            await getAllIngresosByUserDateFilter(usertoconsult.uid, firstDay, lastDay).then(snapshot => {
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    o.id = doc.id;
                    o.key = doc.id;
                    o.foliofiscal = o.complementos && o.complementos[0] ? o.complementos[0].uuid : "";
                    o.actions = { id: doc.id, complementos: o.complementos || [] };
                    o.fechaformat = moment(o.fecha.toDate()).format('DD/MM/YYYY');
                    index++;
                    a.push(o);
                });
                setIngresos(a);
                setIngresosNoFilter(a);
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar los ingresos, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });
        } else {
            //console.log("user null");
        }
    }

    useEffect(() => {
        if (currenteditrecrod != null) {
            if (currenteditrecrod.none) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
            }
            setIsOpen(true);
        }
    }, [currenteditrecrod])
    useEffect(() => {
        if (currenteditrecrodCFDI != null) {
            if (currenteditrecrodCFDI.none) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
            }
            setIsOpenCFDI(true);
        }
    }, [currenteditrecrodCFDI])

    const handleCreate = () => {
        setIsEdit(false);
        setCurrentEditRecord({ none: true });
        //setIsOpen(true);
    }
    const handleFacturar = id => {
        //   history.push('/cporte/' + id);
        let o = findRecord(id);
        if (o) {
            setCurrentEditRecordCFDI(o);
        } else {
            console.log("no se encontro registro");
        }
    }
    const handleEditFromId = id => {
        let obj = ingresos.find(element => element.id === id);
        if (obj) {
            setIsEdit(true);
            setCurrentEditRecord(obj);

        }

    }
    const handleDownloadComplemento = objComplemento => {
        /*if (objComplemento && objComplemento.pdf) {
            downloadPDF(objComplemento.pdf, objComplemento.noCertificado);
        }*/
        handleFacturar(objComplemento);
    }
    const handleDownloadComplementoXML = objComplemento => {
        if (objComplemento && objComplemento.cfdixml) {
            downloadXML(objComplemento.cfdixml);
        }
    }
    const downloadXML = (dataxml) => {
        var xmltext = dataxml;
        let nombrefactura = "";

        var filename = `factura${nombrefactura}.xml`;
        var pom = document.createElement('a');
        var bb = new Blob([xmltext], { type: 'text/plain' });

        pom.setAttribute('href', window.URL.createObjectURL(bb));
        pom.setAttribute('download', filename);

        pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
        pom.draggable = true;
        pom.classList.add('dragout');

        pom.click();
    }
    const downloadPDF = (pdf, nombre) => {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        let nombrefactura = nombre;

        const fileName = `factura${nombrefactura}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    const procesarCancelacion = async (id) => {
        let listViajes = [];
        let listCfidNormales = [];
        console.log("procesando cancelacion", id);
        await getIngresoById(id).then(document => {
            let ingresoObj = document.data();
            console.log("data ingreso", document.id);
            if (ingresoObj && ingresoObj.facturas) {
                ingresoObj.facturas.forEach(element => {
                    if (element.cfdinormal) {
                        listCfidNormales.push(element.referencia);
                    } else {
                        listViajes.push(element.referencia);
                    }
                });
            }
        }).catch(err => { });

        await reactivarDeuda(listViajes, listCfidNormales).then(resp => {
            console.log("deuda reactivada");
        }).catch(err => { });

        await deleteIngreso(id).then(() => {
            refresh();
            Swal.fire('Éxito', 'El ingreso se ha eliminado con éxito.', 'success');
        }).catch(err => {
            Swal.fire("Error", "Error de al eliminar el ingreso", 'error');

        });
    }
    const handleDeleteFromId = id => {
        let obj = ingresos.find(element => element.id === id);
        if (obj) {
            Swal.fire({
                title: '¿Esta seguro de eliminar el ingreso seleccionado?',
                text: "Una vez eliminada no podrá ser recuperado.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1c666f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    procesarCancelacion(obj.id);
                }

            });
        }
    }


    return (
        <Fragment>
            {
                <div className="row">
                    <div className="col-12">
                        <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>&nbsp;Módulo de Ingresos</h3>
                        <div className="card">
                            <div className="card-header card-header-flex">

                                <div className="d-flex px-3 row justify-content-between align-items-center">
                                    <div className="col-auto pl-0">
                                        <div style={{ display: 'flex' }}>
                                            <button class="boton primBtn add ms-right" onClick={() => handleCreate()}><span>Agregar Ingreso</span></button>
                                            {
                                                !isLoading && <Download data={ingresos} />
                                            }
                                        </div>

                                    </div>
                                    <div>
                                        {/* <span style={{ color: 'black', marginTop: '10px' }}>Total:</span><span className="currencyrecordmin">${totalMask}</span>
                                        <span style={{ color: 'black', marginTop: '10px' }}>&nbsp;&nbsp;&nbsp;Ingresos Pendientes:</span><span className="currencyrecordmin">-</span>
                                        */}
                                    </div>
                                    <div style={{ height: '46px', padding: '5px' }}>


                                        <DatePicker
                                            className="form-control customdatepicker"
                                            locale="es"
                                            selected={currentDate}
                                            onChange={date => handleDateChanged(date)}
                                            maxDate={addDays(new Date(), 1)}
                                            dateFormat="MMMM yyyy"
                                            showMonthYearPicker
                                        />

                                    </div>

                                </div>
                            </div>
                            <LoadingOverlay
                                active={isLoading}
                                spinner
                                text='Actualizando datos, espere ...'
                            >
                                <div className="card-body">
                                    <HotTable settings={{
                                        colHeaders: true,
                                        rowHeaders: true,
                                        autoRowSize: false,
                                        columnSorting: true,
                                        dropdownMenu: ['filter_by_condition',
                                            'filter_by_value',
                                            'filter_action_bar'],
                                        filters: true,
                                        manualColumnResize: true,
                                        colWidths: [280, 144, 175, 120, 450, 85, 120],
                                        //para ancho total                                    
                                        //  minSpareRows: 1,          // almenos una fila
                                        // autoColumnSize: true,     // 
                                        //  stretchH: 'all',          // 
                                        //disableVisualSelection: true,
                                        licenseKey: "non-commercial-and-evaluation",
                                        language: "es-MX"
                                    }} data={ingresos} colHeaders={true} rowHeaders={false} width="100%" height="400" >

                                        <HotColumn className="htCenter htMiddle" readOnly title="Cliente" data="cliente" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Referencia" data="referencia" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Tipo" data="tipo" />
                                        <HotColumn className="htCenter htMiddle mycustomcolumn" render readOnly title="Monto" data="montoFloat" >
                                            <MoneyRender className="htCenter htMiddle" hot-renderer></MoneyRender>
                                        </HotColumn>
                                        
                                        <HotColumn render readOnly title="Detalles" data="descripcion" />
                                        <HotColumn className="htCenter htMiddle" render readOnly title="Fecha    " data="fecha" >
                                            <DateRender hot-renderer></DateRender>
                                        </HotColumn>
                                        <HotColumn className="htCenter htMiddle" readOnly title="Acciones" data="actions"  >
                                            <ActionRender hot-renderer handleEditFromId={handleEditFromId} handleDownloadComplementoXML={handleDownloadComplementoXML} handleDownloadComplemento={handleDownloadComplemento} handleDeleteFromId={handleDeleteFromId} />
                                        </HotColumn>
                                    </HotTable>
                                </div>


                            </LoadingOverlay>
                        </div>
                    </div>
                    <ModalCreateEdit
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrod}
                        currentDate={currentDate}
                        isEdit={isEdit}
                        isOpen={isOpen}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={refresh}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setIsOpen(false)} />

                    <ModalComplementoPago findRecord={findRecord}
                        currenteditrecrod={currenteditrecrodCFDI}
                        currentDate={currentDate}
                        isEdit={isEdit}
                        isOpen={isOpenCFDI}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={(data) => console.log("refresh")}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => {
                            setIsOpenCFDI(false)
                            setCurrentEditRecordCFDI(null);
                        }} />
                </div>
            }
        </Fragment>
    );
}
export default IngresoContent;