import React, { Component, Fragment, useEffect, useState, useRef } from 'react';
import { doLogout } from './../../session/session_controller';

const HeaderNew = props => {

    let {isToggle}=props.isToggle;

    const {setIsToggle} = props;


    useEffect(()=>{
        isToggle=props.isToggle;
    },[props]);

    return <header>
        <div className="logo" >
            <img src="/imgs/transport-logo.png" alt="" />
        </div>

        <div className="mainButtons">

            <div className="burger buttnAnim" onClick={e => setIsToggle(!isToggle)}>
                <div className="bline line1"></div>
                <div className="bline line2"></div>
                <div className="bline line3"></div>
            </div>



            <div className="logOutBtn buttnAnim" role='button' onClick={doLogout}>
                <div className="logOutIcn" >
                    <img src="/imgs/bdoor-icon.svg" className="bdoor" alt="" />
                    <img src="/imgs/wdoor-icon.svg" className="wdoor" alt="" />
                </div>
                <p className="fixedP">Salir</p>
            </div>

        </div>
    </header>
}
export default HeaderNew;