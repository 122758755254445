import { firebase } from '../configs/firebase';
export const getNominasDateFilter = (uid, init, end) => {
    //  console.log("ingresos datefilter "+init+" "+end+" user:"+uid);
    init.setHours(0, 0, 0);
    end.setHours(23, 59, 0);
    var db = firebase.firestore();
    return db.collection('nomina').where("uid", "==", uid).where("fecha", ">=", init).where("fecha", "<=", end).get();
}

export const getNominasByRange = (uid, init, end) => {
    //  console.log("ingresos datefilter "+init+" "+end+" user:"+uid);
    var db = firebase.firestore();
    return db.collection('nomina').where("uid", "==", uid).where("fecha", ">=", init).where("fecha", "<=", end).get();
}
export const addNomina = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('nomina').add(dataaux);
}
export const updateNomina = (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date()};
    var db = firebase.firestore();
    return db.collection('nomina').doc(id).update(dataaux);
}

export const getNominaById = (id) => {
    var db = firebase.firestore();
    return db.collection("nomina").doc(id).get();
}
export const deleteNomina = (id) => {
    var db = firebase.firestore();
    return db.collection('nomina').doc(id).delete();
}