import React, { useState, useEffect } from 'react';
import { firebase } from '../../../configs/firebase';
import { getUserByUid, getAllUsuarios } from '../../../controllers/usuarios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import { HotTable, HotColumn } from '@handsontable/react';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { registerLanguageDictionary } from 'handsontable/i18n';
import esMX from 'handsontable/i18n/languages/es-MX';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { getUidMaster } from '../../../session/session_controller';

import Mantenimiento from './Mantenimiento';
import Proveedores from '../../proveedores/content';
import Inventario from '../../inventario/content';
import Kilometraje from '../../odometros/content';
import Servicios from '../../servicios/content';

const AlertaDenegado = () => {
    return <div>
        <ul class="list-group">
            <li class="list-group-item list-group-item-danger" style={{}}>Acceso denegado</li>
        </ul>
    </div>
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'} variant={'body2'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
    },
    paperrootfull: {
        width: '100%',
        margin: '5px',
        padding: '30px',
        display: 'flex'
    },

    paperroot: {
        width: '33%',
        display: 'flex'
    },
    paper: {
        minHeight: 80,
        height: 'auto',
        width: '100%',
    },
    paperscontainer: {
        // width: '100%'
        //overflow: 'hidden'
        flex: 1
    },
    title: {
        height: 30,
        backgroundColor: '#dcdcdc',
        paddingLeft: 10,
        paddingTop: 5,
    },
}));

const MantenimientoContent = (props) => {
    registerLanguageDictionary(esMX);
    registerLocale('es', es)
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [firstDay, setFirstDay] = useState(null);
    const [lastDay, setLastDay] = useState(null);
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [querysearch, setQuerysearch] = useState('');
    const [ingresos, setIngresos] = useState([]);
    const [ingresosNoFilter, setIngresosNoFilter] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currenteditrecrod, setCurrentEditRecord] = useState(null);
    const [total, setTotal] = useState(0.0);
    const [totalMask, setTotalMask] = useState('');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [denys, setDenys] = useState([]);

    const validateAdmin = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (user.type && user.type === "administrador") {
                    isadmin = true;
                }
            });
            if (isadmin) {
                setAdminCurrentUser(uid);
                setIsAdmin(true);
            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                setIsAdmin(false);
                setUserlist([]);
                // this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }
    useEffect(() => {
        if (isAdmin) {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                setUserlist(a);
            }).catch(e => {
                console.log(e);
                //this.setState({isLoading:false,})
            });
        }
    }, [isAdmin]);

    useEffect(() => {
        setTotalMask(total.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
    }, [total]);

    useEffect(() => {


        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);

        if (localStorage.getItem('deny')) {
            let auxdeny = localStorage.getItem('deny').split(":");
            setDenys(auxdeny);
        }

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {

                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);

                setUser(userr);

                // setAdminCurrentUser(userr.uid);

                if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                    // this.validateAdmin(user.uid);
                    //console.log("validando admin");
                    validateAdmin(userr.uid);
                }
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });

    }, []);
    useEffect(() => {
        //calculando totales
        let t = 0.0;
        ingresos.forEach(element => {
            if (element.montoFloat) {
                t += element.montoFloat;
            }
        });
        setTotal(t);
    }, [ingresos])

    const refresh = () => {
        getData(user);
    }



    const handleDateChanged = (date) => {
        let fDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);
        setCurrentDate(date);
    }

    const findRecord = (id) => {
        let obj = ingresos.find(element => element.id === id);
        return obj;
    }

    const getData = async (usertodisplay) => {
        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }
            // setIsLoading(true);
            let a = [];
            let index = 0;
            var moment = require('moment'); // require
          
            /**
             * action getdata
             */
            /*await getAllIngresosByUserDateFilter(usertoconsult.uid, firstDay, lastDay).then(snapshot => {
                            snapshot.forEach(doc => {
                                let o = doc.data();
                                o.index = doc.id;
                                o.n = index;
                                o.id = doc.id;
                                o.key = doc.id;
                                o.fechaformat = moment(o.fecha.toDate()).format('DD/MM/YYYY');
                                index++;
                                a.push(o);
                            });
                            setIngresos(a);
                            setIngresosNoFilter(a);
                            setIsLoading(false);
                        }).catch(err => {
                            console.log(err);
                            Swal.fire("Error", "Error de al consultar los ingresos, comprueba tu conexión ", 'error');
                            setIsLoading(false);
                        });*/
        } else {
            //console.log("user null");
        }
    }

    return (

        <div className="card-body">
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs
                        value={value}
                        indicatorColor="tabindicator"
                        textColor="tabtextcolor"
                        onChange={handleChange}
                        aria-label="simple tabs example">
                        <Tab label="Preventivo" {...a11yProps(0)} />
                        <Tab label="Correctivo" {...a11yProps(1)} />
                        <Tab label="Proveedores" {...a11yProps(2)} />
                        <Tab label="Inventario Refacciones" {...a11yProps(3)} />
                        <Tab label="Kilometrajes" {...a11yProps(4)} />

                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    {!denys.find(e => e === "planmantenimiento") ? <Mantenimiento /> : <AlertaDenegado />}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {!denys.find(e => e === "servicios") ? <Servicios /> : <AlertaDenegado />}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {!denys.find(e => e === "proveedores") ? <Proveedores /> : <AlertaDenegado />}
                </TabPanel>
                <TabPanel value={value} index={3}>
                    {!denys.find(e => e === "inventario") ? <Inventario /> : <AlertaDenegado />}
                </TabPanel>
                <TabPanel value={value} index={4}>
                    {!denys.find(e => e === "kilometraje") ? <Kilometraje /> : <AlertaDenegado />}
                </TabPanel>


            </div>
        </div>

    );
}
export default MantenimientoContent;