import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { addTabuladorOperadores, updateTabuladorOperadores, getTabuladorOperadoresByRuta } from '../../../controllers/tabuladoroperadores'
import { getUserByUid } from '../../../controllers/usuarios';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const ModalCreateEdit = (props) => {
    //constantes

    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);

    //estados propios del formulario
    const [origen, setOrigen] = useState('');
    const [destino, setDestino] = useState('');
    const [ruta, setRuta] = useState('');
    const [tarifa, setTarifa] = useState('');
    const [tarifaFloat, setTarifaFloat] = useState(0.0);
    const [currencyMx, setCurrencyMx] = useState(true);
    const [especiales, setEspeciales] = useState([]);
    const [usuarioobj, setUsuarioObj] = useState(null);
    const [segmentoNegocio, setSegmentoNegocio] = useState("");
    const [isEspecial, setIsEspecial] = useState(false);

    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);


    const initValues = () => {

        getUserByUid(uid).then(snapshot => {
            let obuser = null;
            snapshot.forEach(doc => {
                let o = doc.data();
                obuser = { ...doc.data(), id: doc.id, segmento_negocio: ["", ...doc.data().segmento_negocio] }
                if (!obuser.segmento_negocio) {
                    obuser.segmento_negocio = [""];
                }
                o.id = doc.id;
            });
            setUsuarioObj(obuser);

        }).catch(err => {
            console.log(err);
        });

        if (isEdit && recordedit != null) {
            setOrigen(recordedit.origen || "");
            setDestino(recordedit.destino || "");
            setRuta(recordedit.ruta || "");
            setSegmentoNegocio(recordedit.segmentoNegocio || "");
            setTarifa(recordedit.tarifa || "");
            setTarifaFloat(recordedit.tarifaFloat || "");
            setCurrencyMx(recordedit.currencyMx);
            setEspeciales(recordedit.especiales || []);
            setIsEspecial(recordedit.isEspecial || false);
        } else {
            setIsEspecial(false);
            setOrigen("");
            setDestino("");
            setRuta("");
            setTarifa("");
            setTarifaFloat("");
            setSegmentoNegocio("");
            setCurrencyMx(true);
            setEspeciales([]);
        }
    }


    useEffect(() => {
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit) {
            initValues();
        }

    }, [recordedit]);


    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    useEffect(() => {
        if (origen && destino && origen.trim() !== "" && destino.trim() !== "") {
            setRuta(origen.trim() + " - " + destino.trim());
        }
    }, [origen, destino])

    useEffect(() => {
        if (ruta && ruta !== "" && segmentoNegocio !== "") {
            getTabuladorOperadoresByRuta(uid, ruta.toUpperCase(), segmentoNegocio).then(resp => {
                if (resp.size > 0) {
                    setIsEspecial(true);
                    //if (resp.docs[0].data()) {

                    // setIsEdit(true);
                    // let objtoset = { ...resp.docs[0].data(), id: resp.docs[0].id }
                    //  setRecordEdit(objtoset);

                    //}
                } else if (isEspecial) {
                    setIsEspecial(false);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }, [ruta, segmentoNegocio])

    const handleSumbit = async (e) => {
        e.preventDefault();
        //setIsOpen(false);
        if (origen === "" || destino === "" || segmentoNegocio === "") {
            Swal.fire("Invalido", "Completa los campos requeridos", 'warning');
            return;
        }
        let obj = {
            origen,
            destino,
            ruta: ruta.toUpperCase(),
            tarifa,
            tarifaFloat,
            currencyMx,
            especiales,
            isEspecial,
            segmentoNegocio,
            uid,
        }

        if (isAdmin && adminCurrentUser) {
            obj.uid = adminCurrentUser;
        }

        //validaciones de formulario
        // console.log(obj);
        if (isEdit && recordedit.id) {
            // alert(recordedit.id);
            obj.useredit = uidAltaEdit || uid;
            setIsLoading(true);
            updateTabuladorOperadores(recordedit.id, obj).then(resp => {
                setIsLoading(false);
                setIsOpen(false);
                props.notify({ ...obj, id: recordedit.id });
                Swal.fire('Éxito', 'El tabulador se ha actualizado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                console.log(err);
                Swal.fire("Error", "Error de al actualizar el tabulador, comprueba tu conexión ", 'error');
            })
        } else if (!isEdit) {
            obj.useralta = uidAltaEdit || uid;
            setIsLoading(true);
            addTabuladorOperadores(obj).then(resp => {
                setIsLoading(false);
                setIsOpen(false);
                props.notify();
                Swal.fire('Éxito', 'El tabulador se ha registrado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                Swal.fire("Error", "Error de al registrar el tabulador, comprueba tu conexión ", 'error');
            })
        } else {
            alert("no options");
        }
    }
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const handleChangeTarifa = (event, maskedvalue, floatvalue) => {
        setTarifa(maskedvalue);
        setTarifaFloat(floatvalue);
    }
    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Añadir Comisión por Ruta al Operador" : `Editar Tabulador `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP">Ruta</p></div>
                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Origen<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <input type="text" name='cliente' value={origen} onChange={e => setOrigen(e.target.value)} required />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Destino<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <input type="text" name='cliente' value={destino} onChange={e => setDestino(e.target.value)} required />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Ruteo</p>
                                    <div class="fieldInput">
                                        <input disabled type="text" name='cliente' value={ruta} onChange={e => setRuta(e.target.value)} required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP">Comisión</p></div>
                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Moneda</p>
                                    <div class="fieldInput">
                                        <select name='segmento' onChange={(e) => setCurrencyMx(e.target.value == "1")} value={currencyMx ? "1" : "2"} required >
                                            <option key={'simxn'} value={"1"} >MXN</option>
                                            <option key={'nodll'} value={"2"}>DLL</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Monto<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <CurrencyInput style={{ backgroundColor: "#dbffcf", width: "90%" }} value={tarifa} onChangeEvent={handleChangeTarifa} prefix="$" required />

                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Segmento de Negocio<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <select name='segmento' onChange={(e) => setSegmentoNegocio(e.target.value)} value={segmentoNegocio} required >
                                            {
                                                usuarioobj && usuarioobj.segmento_negocio.map((item, index) => {

                                                    return segmentoNegocio == item.value ?
                                                        <option key={index} value={item.value} selected>{item.value}</option> :
                                                        <option key={index} value={item.value}>{item.value}</option>

                                                })
                                            }
                                        </select>

                                    </div>
                                </div>
                                <div className="col-3">
                                    <FormGroup>
                                        <FormControlLabel disabled control={<Checkbox checked={isEspecial} />} label="Especial" />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">



                    </div>
                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalCreateEdit;