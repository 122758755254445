import React, { useState, useEffect, Fragment } from 'react';
import { firebase } from '../../../configs/firebase';
import { deleteIngreso } from '../../../controllers/ingresos';
import { getUserByUid, getAllUsuarios } from '../../../controllers/usuarios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import { addDays } from "date-fns";
import { HotTable, HotColumn } from '@handsontable/react';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { registerLanguageDictionary } from 'handsontable/i18n';
import esMX from 'handsontable/i18n/languages/es-MX';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { getTarifarios, deleteTarifario } from '../../../controllers/tarifario';
import { TipoMoneda, ActionRender } from './ActionsRender';
import ModalCreateEdit from '../ModalCreateEdit';
import { getUidMaster } from '../../../session/session_controller';
import Download from './ExcelExport';
//import { ActionRender, DateRender, MoneyRender } from './ActionsRender';
//import Download from './ExcelExport';
const requiereSubliente = ['HptPbvVxYYUjUzp7Nt3TtmyAt0x1','31RxHqtBOmbgOWWeMCKnb1DuEHc2'];
const requiereComisiones= ['HptPbvVxYYUjUzp7Nt3TtmyAt0x1'];

const ActivoContent = (props) => {

    registerLanguageDictionary(esMX);
    registerLocale('es', es)

    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [firstDay, setFirstDay] = useState(null);
    const [lastDay, setLastDay] = useState(null);
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [querysearch, setQuerysearch] = useState('');
    const [ingresos, setIngresos] = useState([]);
    const [ingresosNoFilter, setIngresosNoFilter] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currenteditrecrod, setCurrentEditRecord] = useState(null);
    const [total, setTotal] = useState(0.0);
    const [totalMask, setTotalMask] = useState('');


    const validateAdmin = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (user.type && user.type === "administrador") {
                    isadmin = true;
                }
            });
            if (isadmin) {
                setAdminCurrentUser(uid);
                setIsAdmin(true);
            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                setIsAdmin(false);
                setUserlist([]);
                // this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }

    useEffect(() => {
        if (isAdmin) {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                setUserlist(a);
            }).catch(e => {
                console.log(e);
                //this.setState({isLoading:false,})
            });
        }
    }, [isAdmin]);

    useEffect(() => {
        setTotalMask(total.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
    }, [total]);

    useEffect(() => {


        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {
                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);
                setUser(userr);
                // setAdminCurrentUser(userr.uid);

                if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                    // this.validateAdmin(user.uid);
                    //console.log("validando admin");
                    validateAdmin(userr.uid);
                }
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });

    }, []);


    useEffect(() => {
        if (querysearch.trim() === '') {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                aux.push(element);
            });
            setIngresos(aux);
        } else {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                let query = querysearch.trim().toUpperCase();
                if (element.nombre.toUpperCase().includes(query) ||
                    element.apellidos.toUpperCase().includes(query) ||
                    element.noLicencia.toUpperCase().includes(query) ||
                    element.noIMSS.toUpperCase().includes(query)) {
                    aux.push(element);
                }

            });
            setIngresos(aux);
        }
    }, [querysearch]);

    useEffect(() => {
        getData(user);
    }, [user, currentDate, adminCurrentUser]);

    useEffect(() => {
        if (!isOpen) {
            setCurrentEditRecord(null);
        }
    }, [isOpen])


    useEffect(() => {
        //calculando totales
        let t = 0.0;
        ingresos.forEach(element => {
            if (element.montoFloat) {
                t += element.montoFloat;
            }
        });
        setTotal(t);
    }, [ingresos])

    const refresh = () => {
        getData(user);
    }



    const handleDateChanged = (date) => {
        let fDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);
        setCurrentDate(date);
    }

    const findRecord = (id) => {
        let obj = ingresos.find(element => element.id === id);
        return obj;
    }

    const getData = async (usertodisplay) => {
        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }
            // setIsLoading(true);
            let a = [];
            let index = 0;
            var moment = require('moment'); // require
            setIsLoading(true);

            getTarifarios(usertoconsult.uid).then(snapshot => {
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    o.id = doc.id;
                    o.key = doc.id;
                    if (o.isEspecial) {
                        o.tarifa = o.tarifa + " (E)"
                    }
                    o.moneda = o.currencyMx ? "MX" : "DLL";
                    index++;
                    a.push(o);
                });
                setIngresos(a);
                setIngresosNoFilter(a);
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar los operadores, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });

        } else {
            //console.log("user null");
        }
    }

    useEffect(() => {
        if (currenteditrecrod != null) {
            if (currenteditrecrod.none) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
            }
            setIsOpen(true);
        }
    }, [currenteditrecrod])

    const handleCreate = () => {
        setIsEdit(false);
        setCurrentEditRecord({ none: true });
        //setIsOpen(true);
    }
    const handleEditFromId = id => {
        let obj = ingresos.find(element => element.id === id);
        if (obj) {
            console.log("obj a editar", obj);
            setIsEdit(true);
            setCurrentEditRecord(obj);

        }
    }
    const handleDeleteFromId = id => {
        let obj = ingresos.find(element => element.id === id);
        if (obj) {
            Swal.fire({
                title: '¿Esta seguro de eliminar el tabulador seleccionado?',
                text: "Una vez eliminada no podrá ser recuperado.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1c666f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {

                    deleteTarifario(id).then(() => {
                        refresh();
                        Swal.fire('Éxito', 'Se ha eliminado con éxito.', 'success');
                    }).catch(err => {
                        Swal.fire("Error", "Error de al eliminar el tarifarioa revisa tu conexion", 'error');

                    });
                }

            });
        }
    }


    return (
        <Fragment>
            {
                <div className="row">
                    <div className="col-12">
                        <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>&nbsp;Tarifario</h3>
                        <div className="card">
                            <div className="card-header card-header-flex">

                                <div className="d-flex px-3 row justify-content-between align-items-center">
                                    <div style={{ height: '46px', padding: '5px' }}>
                                        <div className="col-auto pl-0" style={{ display: 'flex' }}>
                                            <button type="button" style={{ marginRight: '10px' }} className="boton primBtn add ms-right" onClick={() => handleCreate()}><span>Agregar Tarifa</span></button>
                                            {
                                                !isLoading
                                            }
                                            {
                                                !isLoading && ingresos.length > 0 && <Download uid={uid} data={
                                                    ingresos
                                                } className="btn btn-primary btn-sm" />
                                            }

                                        </div>
                                    </div>
                                    <div>


                                    </div>
                                    <div>

                                    </div>

                                </div>
                            </div>
                            <LoadingOverlay
                                active={isLoading}
                                spinner
                                text='Actualizando datos, espere ...'
                            >
                                <div className="card-body">
                                    {uid && <HotTable settings={{
                                        colHeaders: true,
                                        rowHeaders: true,
                                        //autoRowSize: false,
                                        columnSorting: true,
                                        dropdownMenu: ['filter_by_condition',
                                            'filter_by_value',
                                            'filter_action_bar'],
                                        filters: true,
                                        manualColumnResize: true,
                                        colWidths: requiereSubliente.find(f => f === uid) ? [280, 280, 110, 280, 280, 280, 110, 110,110 ,80] : [280, 110, 280, 280, 280, 110, 110, 80],
                                        //para ancho total                                    
                                        minSpareRows: 1,          // almenos una fila
                                        // autoColumnSize: true,     // 
                                        //  stretchH: 'all',          // 
                                        //disableVisualSelection: true,
                                        currentRowClassName: 'currentRowHightlight',
                                        licenseKey: "non-commercial-and-evaluation",
                                        language: "es-MX"
                                    }} data={ingresos} colHeaders={true} rowHeaders={false} width="100%" height="400" >
                                        <HotColumn className="htCenter htMiddle" readOnly title="Cliente" data="cliente" />
                                        {
                                            requiereSubliente.find(f=>f===uid) && <HotColumn className="htCenter htMiddle" readOnly title="SubCliente" data="subcliente" />
                                        }
                                        
                                        <HotColumn className="htCenter htMiddle" readOnly title="Segmento" data="segmentoNegocio" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Origen" data="origen" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Destino" data="destino" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Ruteo" data="ruta" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Tarifa" data="tarifa" />
                                        <HotColumn className="htCenter htMiddle" readOnly title="Moneda" data="moneda" />
                                        {
                                            requiereComisiones.find(f=>f===uid) && <HotColumn className="htCenter htMiddle" readOnly title="Comisión" data="comisionOperador" />
                                        }
                                        <HotColumn className="htCenter htMiddle" readOnly title="Acciones" data="id"  >
                                            <ActionRender hot-renderer handleEditFromId={handleEditFromId} handleDeleteFromId={handleDeleteFromId} />
                                        </HotColumn>
                                    </HotTable>}

                                </div>


                            </LoadingOverlay>
                        </div>
                    </div>
                    <ModalCreateEdit
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrod}
                        currentDate={currentDate}
                        isEdit={isEdit}
                        isOpen={isOpen}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={refresh}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setIsOpen(false)} />
                </div>
            }
        </Fragment>
    );
}
export default ActivoContent;