import React, { Component, Fragment } from 'react';
import { doLogout } from './../../session/session_controller';

export default class Header extends Component {
    constructor(props) {
        super();
        
    }
    render() {
        return (
            <Fragment>
                {/* /////// HEADER */}
                <div className="air__layout__header">
                    <div className="air__utils__header">
                        {/* /////// TOP BAR */}
                        <div className="air__topbar justify-content-between">
                            <div className="dropdown cursor-pointer">
                                <div className="dropdown-item cursor-pointer" role='button' onClick={this.props.foldBar}>
                                    <i className="fa fa-list" ></i>
                                    </div>
                            </div>
                            <div className="dropdown cursor-pointer">
                                <div className="dropdown-item cursor-pointer" role='button' onClick={doLogout}>
                                    <i className="fa fa-sign-out" ></i> Salir
                                    </div>
                            </div>
                        </div>
                        {/* /////// END TOP BAR */}
                    </div>
                </div>
                {/* /////// END HEADER */}
            </Fragment>
        )
    }
}
