import React, { Component, Fragment } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Alert } from 'reactstrap';
import { sendEmail } from './../../../session/session_controller';
import Swal from 'sweetalert2';

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            loading: false,
            isModalOpen: false,
        }
    }

    goBack = () => {
        window.location.href = "/"
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true }, () => {
            sendEmail(this.state.email).then(res => {
                this.setState({ loading: false }, () => {
                    Swal.fire('', 'El correo ha sido envíado.').then(() => {
                        window.location.href = "/";
                    });
                });
            }).catch(e => {
                console.log(e);
                this.setState({ loading: false, email: '' });
                Swal.fire('Error!', 'Your email is not correct, please try again.', 'error');
            });
        });
    }

    render() {
        return (
            <Fragment>
                <div className="container loginbackground" >
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-md-7 col-lg-5 cardlogin">
                            <LoadingOverlay
                                active={this.state.isloading}
                                spinner
                                text='Espere un momento...'>
                                <div className="air__auth__container pl-5 pr-5 pt-5 pb-5 bg-white text-center">
                                    <div className="pt-2 pb-2 mt-auto d-flex justify-content-center">
                                        <img src={process.env.PUBLIC_URL + '/logotp.png'} width={'390'} height={'100%'} className="logo-login" alt="Transport Plus" title="Transport Plus" />
                                    </div>

                                    <div>
                                        <Alert color="dark">Ingresa tu correo electronico asociado a tu cuenta y te enviaremos una url para cambiar tu contraseña.</Alert>
                                    </div>
                                    <form onSubmit={this.handleSubmit} className="mb-4" >
                                        <div className="form-group mb-4">
                                            <input className="form-control" type='email' value={this.state.email} name='email' onChange={this.handleChange} placeholder='Correo electronico' required />
                                        </div>
                                        <button type="submit" className="text-center btn bg-accent-color-2 w-100 font-weight-bold font-size-18">Enviar Correo</button>
                                        <div> &nbsp; </div>
                                        <button type="button" className="text-center btn bg-accent-color-1 w-100 font-weight-bold font-size-18" onClick={this.goBack}>Inicio</button>
                                    </form>
                                    <div className="mt-auto pb-5 pt-5 d-flex justify-content-center flex-column align-items-center">
                                        <div className="text-gray-4 text-center mt-2">Transport Plus© 2021 . All rights reserved. <br /> <small>Development: MTI. Antonio Arce Aguilar antoniodev17@gmail.com</small></div>
                                    </div>
                                </div>
                            </LoadingOverlay>

                            
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ForgotPassword;