import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input';
import Swal from 'sweetalert2';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addIngreso, updateIngreso, addDestino, getAllDestinosByUser } from '../../../controllers/ingresos';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { addOperador, updateOperador } from '../../../controllers/operadores'
import { updateUbicacion } from '../../../controllers/ubicaciones';
import { updateUserOperadoresTimeByUid } from '../../../controllers/usuarios';
import axios from 'axios';
import InputMask from 'react-input-mask';


const ModalCreateEdit = (props) => {
    //constantes
    const CATALOGO_ESTADOS_USA = [
        { estado: '', estado_short: '' },
        { estado: 'Alabama', estado_short: 'AL' },
        { estado: 'Alaska', estado_short: 'AK' },
        { estado: 'Arizona', estado_short: 'AZ' },
        { estado: 'Arkansas', estado_short: 'AR' },
        { estado: 'California', estado_short: 'CA' },
        { estado: 'Carolina del Norte', estado_short: 'NC' },
        { estado: 'Carolina del Sur', estado_short: 'SC' },
        { estado: 'Colorado', estado_short: 'CO' },
        { estado: 'Connecticut', estado_short: 'CT' },
        { estado: 'Dakota del Norte', estado_short: 'ND' },
        { estado: 'Dakota del Sur', estado_short: 'SD' },
        { estado: 'Delaware', estado_short: 'DE' },
        { estado: 'Florida', estado_short: 'FL' },
        { estado: 'Georgia', estado_short: 'GA' },
        { estado: 'Hawái', estado_short: 'HI' },
        { estado: 'Idaho', estado_short: 'ID' },
        { estado: 'Illinois', estado_short: 'IL' },
        { estado: 'Indiana', estado_short: 'IN' },
        { estado: 'Iowa', estado_short: 'IA' },
        { estado: 'Kansas', estado_short: 'KS' },
        { estado: 'Kentucky', estado_short: 'KY' },
        { estado: 'Luisiana', estado_short: 'LA' },
        { estado: 'Maine', estado_short: 'ME' },
        { estado: 'Maryland', estado_short: 'MD' },
        { estado: 'Massachusetts', estado_short: 'MA' },
        { estado: 'Míchigan', estado_short: 'MI' },
        { estado: 'Minnesota', estado_short: 'MN' },
        { estado: 'Misisipi', estado_short: 'MS' },
        { estado: 'Misuri', estado_short: 'MO' },
        { estado: 'Montana', estado_short: 'MT' },
        { estado: 'Nebraska', estado_short: 'NE' },
        { estado: 'Nevada', estado_short: 'NV' },
        { estado: 'Nueva Jersey', estado_short: 'NJ' },
        { estado: 'Nueva York', estado_short: 'NY' },
        { estado: 'Nuevo Hampshire', estado_short: 'NH' },
        { estado: 'Nuevo México', estado_short: 'NM' },
        { estado: 'Ohio', estado_short: 'OH' },
        { estado: 'Oklahoma', estado_short: 'OK' },
        { estado: 'Oregón', estado_short: 'OR' },
        { estado: 'Pensilvania', estado_short: 'PA' },
        { estado: 'Rhode Island', estado_short: 'RI' },
        { estado: 'Tennessee', estado_short: 'TN' },
        { estado: 'Texas', estado_short: 'TX' },
        { estado: 'Utah', estado_short: 'UT' },
        { estado: 'Vermont', estado_short: 'VT' },
        { estado: 'Virginia', estado_short: 'VA' },
        { estado: 'Virginia Occidental', estado_short: 'WV' },
        { estado: 'Washington', estado_short: 'WA' },
        { estado: 'Wisconsin', estado_short: 'WI' },
        { estado: 'Wyoming', estado_short: 'WY' },


    ];
    const CATALOGOS_TIPO_PAGO = ['Manual', 'Por Porcentaje', 'Por Kilómetro', 'Otro'];
    const [CATALOGOS_TIPO_LICENCIA,setCatalogoTIposLicencia] =useState(['A', 'B', 'C', 'D', 'E', 'F']);
    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);

    //estados propios del formulario
    const [nombre, setNombre] = useState("");
    const [apellidos, setApellidos] = useState("");
    const [ciudad, setCiudad] = useState("");
    const [active, setActive] = useState(true);
    const [celular, setCelular] = useState("");
    const [tipoDePago, setTipoDePago] = useState("Manual");
    const [noLicencia, setNoLicencia] = useState("");
    const [tipoLicencia, setTipoLicencia] = useState("A");
    const [fechaExpiracion, setFechaExpiracion] = useState("");
    const [noIMSS, setNoIMSS] = useState("");
    const [rfc, setRfc] = useState("");

    const [isMoreData, setIsMoreData] = useState(false);
    const [direccion, setDireccion] = useState("");
    const [telefonoEmergencia, setTelefonoEmergencia] = useState("");
    const [cuenta, setCuenta] = useState("");
    const [contactoEmergencia, setContactoEmgergencia] = useState("");
    const [fechaContratacion, setFechaContratacion] = useState("");
    const [fechaTerminacion, setFechaTerminacion] = useState("");

    const [internacional, setInternacional] = useState(false);
    const [estado, setEstado] = useState();
    const [estadoSat, setEstadoSat] = useState();
    const [estado_short, setEstadoShort] = useState();
    const [localidad, setLocalidad] = useState("");
    const [localidadSat, setLocalidadSat] = useState("");
    const [municipio, setMunicipio] = useState("");
    const [municipioSat, setMunicipioSat] = useState("");
    const [calle, setCalle] = useState("");
    const [numero, setNumero] = useState("");
    const [colonia, setColonia] = useState("");
    const [coloniaSat, setColoniaSat] = useState("");
    const [colonias, setColonias] = useState([]);
    const [cp, setCP] = useState("");

    const [sueldoBase, setSueldoBase] = useState("");
    const [sueldoBaseFloat, setSueldoBaseFloat] = useState(0.00);
    const [fast,setFast]= useState("");

    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);

    const handleChangeTarifa = (event, maskedvalue, floatvalue) => {
        setSueldoBase(maskedvalue);
        setSueldoBaseFloat(floatvalue);
    }
    const initValues = () => {

        if (isEdit && recordedit != null) {
            setNombre(recordedit.nombre || "");
            setApellidos(recordedit.apellidos || "");
            setCiudad(recordedit.ciudad || "");
            setCelular(recordedit.celular || "");
            setTipoLicencia(recordedit.tipoLicencia || "A");
            setTipoDePago(recordedit.tipoDePago || "Manual");
            setActive(recordedit.active);
            setNoLicencia(recordedit.noLicencia || "");
            setFast(recordedit.fast||"");
            setFechaExpiracion(recordedit.fechaExpiracion || "");
            setFechaContratacion(recordedit.fechaContratacion || "");
            setFechaTerminacion(recordedit.fechaTerminacion || "");
            setNoIMSS(recordedit.noIMSS || "");
            setRfc(recordedit.rfc || "");
            setIsMoreData(recordedit.isMoreData);
            setDireccion(recordedit.direccion || "");
            setTelefonoEmergencia(recordedit.telefonoEmergencia || "");
            setCuenta(recordedit.cuenta || "");
            setContactoEmgergencia(recordedit.contactoEmergencia || "");

            setInternacional(recordedit.internacional);
            setEstadoShort(recordedit.estado_short || "");
            setEstado(recordedit.estado || "");
            setLocalidad(recordedit.localidad || "");
            setLocalidadSat(recordedit.localidadSat || "");
            setCalle(recordedit.calle || "");
            setNumero(recordedit.numero || "");
            setCP(recordedit.cp || "");
            setColonia(recordedit.colonia || "");
            setColoniaSat(recordedit.coloniaSat || "");
            setColonias(recordedit.colonias || []);
            setMunicipioSat(recordedit.municipioSat || "");
            setMunicipio(recordedit.municipio || "");
            setSueldoBase(recordedit.sueldoBase || "");
            setSueldoBaseFloat(recordedit.sueldoBaseFloat || 0.00);

        } else {

            setNombre("");
            setApellidos("");
            setCiudad("");
            setCelular("");
            setTipoLicencia("A");
            setTipoDePago("Manual");
            setActive(true);
            setNoLicencia("");
            setFast("");
            setFechaExpiracion("");
            setFechaContratacion("");
            setFechaTerminacion("");
            setNoIMSS("");
            setRfc("");
            setIsMoreData(false);
            setDireccion("");
            setTelefonoEmergencia("");
            setCuenta("");
            setContactoEmgergencia("");
            setSueldoBase("");
            setSueldoBaseFloat(0.00);

            setInternacional(false);
            setEstadoShort("");
            setEstado("");
            setLocalidad("");
            setLocalidadSat("");
            setCalle("");
            setNumero("");
            setCP("");
            setMunicipio("");
            setMunicipioSat("");
            setColonia("");
            setColonias([]);
            setColoniaSat("");
        }
    }


    useEffect(() => {
        setUid(props.uid);

        if(props.uid==='9nhgbXbSy4StUGrqTvjQaa0pXpa2')
        {
            setCatalogoTIposLicencia(['A', 'B', 'C', 'D', 'E', 'F','BE', 'B INT', 'E INT', 'B INT', 'E INT']);
        }

        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit) {
            initValues();
        }

    }, [recordedit]);


    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();
        //setIsOpen(false);
        let obj = {
            nombre,
            apellidos,
            ciudad,
            celular,
            tipoDePago,
            active,
            noLicencia,
            tipoLicencia,
            fast,
            fechaExpiracion,
            noIMSS,
            isMoreData,
            direccion,
            telefonoEmergencia,
            cuenta,
            contactoEmergencia,
            fechaContratacion,
            fechaTerminacion,
            rfc,
            internacional: internacional ? true : false,
            estado,
            estado_short,
            localidad,
            localidadSat,
            municipio,
            municipioSat,
            calle,
            numero,
            colonia,
            colonias,
            coloniaSat,
            cp,
            sueldoBase,
            sueldoBaseFloat,
            uid,
        }

        // console.log(obj);
        //  return;
        if (isAdmin && adminCurrentUser) {
            obj.uid = adminCurrentUser;
        }

        //validaciones de formulario
        // console.log(obj);
        if (isEdit && recordedit.id) {
            // alert(recordedit.id);
            obj.useredit = uidAltaEdit || uid;
            setIsLoading(true);
            updateOperador(recordedit.id, obj).then(resp => {
                updateUserOperadoresTimeByUid(uid);
                setIsLoading(false);
                setIsOpen(false);
                props.notify({ ...obj, id: recordedit.id });
                Swal.fire('Éxito', 'El operador se ha actualizado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                console.log(err);
                Swal.fire("Error", "Error de al actualizar el operador, comprueba tu conexión ", 'error');
            })
        } else if (!isEdit) {
            obj.useralta = uidAltaEdit || uid;
            setIsLoading(true);
            addOperador(obj).then(resp => {
                updateUserOperadoresTimeByUid(uid);
                setIsLoading(false);
                setIsOpen(false);
                props.notify();
                Swal.fire('Éxito', 'El operador se ha registrado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                Swal.fire("Error", "Error de al registrar el operador, comprueba tu conexión ", 'error');
            })
        }
    }
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);
    useEffect(() => {

        if (!internacional && cp.split('_').join('').length >= 5) {
            consultarInfoFromCP();
        }
    }, [cp]);
    const consultarInfoFromCP = async () => {
        setIsLoading(true);
        //https://us-central1-transport-plus-98f66.cloudfunctions.net/app
        await axios.get(`https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/cpostal?cp=${cp}`).then(resp => {
            // console.log("respuesta", resp.data);
            if (resp.data && resp.data.length > 0) {
                setLocalidad(resp.data[0].localidad);
                setMunicipio(resp.data[0].municipio);
                setEstado(resp.data[0].estado);
                setEstadoShort(resp.data[0].claveEstado);
                setMunicipioSat(resp.data[0].claveMunicipio);
                setLocalidadSat(resp.data[0].claveLocalidad);
                let coloniasAux = resp.data.map(element => {
                    return { claveColonia: element.claveColonia, colonia: element.colonia }
                });
                setColonias([{ claveColonia: "", colonia: "" }, ...coloniasAux]);
                console.log("datos ", resp.data);
            }
            setIsLoading(false);


        }).catch(err => {
            setIsLoading(false);
            console.log("error", err.toString());
            if (err.toString().includes("Network Error")) {
                Swal.fire("Error", "Error de conexión, no fue posible conectar con el servidor, revisa tu conexión", 'error');
            } else {
                Swal.fire("Error", "No se pudo consultar", 'error');
            }
        });
    }
    const changeEstadoUSA = value => {
        let obj_estado = CATALOGO_ESTADOS_USA.find(element => element.estado === value);
        if (obj_estado) {
            setEstado(obj_estado.estado);
            setEstadoShort(obj_estado.estado_short);
        }
    }

    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Añadir Operador" : `Editar Operador `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP">Ubicación</p></div>
                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Nombre<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <input type="text" name='cliente' value={nombre} onChange={e => setNombre(e.target.value)} required />
                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">Apellido Completo<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <input type="text" name='cliente' value={apellidos} onChange={e => setApellidos(e.target.value)} required />
                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">RFC<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <input type="text" name='noimss' value={rfc} onChange={e => setRfc(e.target.value)} required />
                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">Celular<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <input type="text" name='cliente' value={celular} onChange={e => setCelular(e.target.value)} required />
                                    </div>
                                </div>


                                <div class="dataRow">
                                    <p className="fixedP">Tipo de Licencia<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <select name='tipolicencia' onChange={(e) => setTipoLicencia(e.target.value)} value={tipoLicencia} required >
                                            {
                                                CATALOGOS_TIPO_LICENCIA.map((item, index) => {
                                                    return <option key={index} value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">No. Licencia<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <input type="text" name='cliente' value={noLicencia} onChange={e => setNoLicencia(e.target.value)} required />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">FAST</p>
                                    <div class="fieldInput">
                                        <input type="text" name='fast' value={fast} onChange={e => setFast(e.target.value)} />
                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">Fecha Expiración<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <input type="date" name='fechaexpira' style={{ width: '90%' }} value={fechaExpiracion} onChange={e => setFechaExpiracion(e.target.value)} />
                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">Tipo de Pago<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <select name='tipopago' onChange={(e) => setTipoDePago(e.target.value)} value={tipoDePago} required >
                                            {
                                                CATALOGOS_TIPO_PAGO.map((item, index) => {
                                                    return <option key={index} value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">No. IMSS</p>
                                    <div class="fieldInput">
                                        <input type="text" name='noimss' value={noIMSS} onChange={e => setNoIMSS(e.target.value)} required />
                                    </div>
                                </div>


                                <div class="dataRow">
                                    <p className="fixedP">Estatus</p>
                                    <div class="fieldInput">
                                        <select name='segmento' onChange={(e) => setActive(e.target.value == "1")} value={active ? "1" : "2"} required >
                                            <option key={'sistatus'} value={"1"} >Activo</option>
                                            <option key={'nostatus'} value={"2"}>Inactivo</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP">Dirección</p></div>
                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">País</p>
                                    <div class="fieldInput">
                                        <select name='segmento' onChange={(e) => setInternacional(e.target.value == "1")} value={internacional ? "1" : "2"} required >
                                            <option key={'simex'} value={"1"} >USA</option>
                                            <option key={'nousa'} value={"2"}>MEX</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">C.P.</p>
                                    <div class="fieldInput">
                                        <InputMask style={{ width: '90%' }} mask="99999" maskChar="_" value={cp} onChange={e => setCP(e.target.value)} />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Estado</p>
                                    <div class="fieldInput">
                                        {
                                            internacional ?
                                                <select name='tipo' onChange={(e) => changeEstadoUSA(e.target.value)} value={estado} required >
                                                    {
                                                        CATALOGO_ESTADOS_USA.map((item, index) => {
                                                            return <option key={index} value={item.estado}>{item.estado}</option>
                                                        })
                                                    }
                                                </select> :
                                                <input type="text" disabled value={estado} onChange={(e) => console.log(e.target.value)} />
                                        }
                                    </div>
                                </div>
                                {!internacional && <div class="dataRow">
                                    <p className="fixedP">Municipio<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <input type="text" disabled value={municipio} onChange={(e) => console.log(e.target.value)} />
                                    </div>
                                </div>
                                }
                                <div class="dataRow">
                                    <p className="fixedP">Localidad<label className="requiredlabel">*</label></p>

                                    {internacional ?
                                        <div class="fieldInput"> <input type="text" minlength="1" name='localidad' value={localidad} onChange={e => setLocalidad(e.target.value)} /> </div>
                                        : <div class="fieldInput">
                                            <input type="text" disabled value={localidad} onChange={(e) => console.log(e.target.value)} />


                                        </div>
                                    }

                                </div>

                                {!internacional && <div class="dataRow">
                                    <p className="fixedP">Colonia<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <select name='tipo' onChange={(e) => setColoniaSat(e.target.value)} value={coloniaSat} required >
                                            {
                                                colonias.map((item, index) => {
                                                    return <option key={index} value={item.claveColonia}>{item.colonia}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>}

                                <div class="dataRow">
                                    {internacional ? <p className="fixedP">Dirección<label className="requiredlabel">*</label></p> : <p className="fixedP">Calle<label className="requiredlabel">*</label></p>}
                                    <div class="fieldInput">
                                        <input required type="text" minlength="1" name='cliente' value={calle} onChange={e => setCalle(e.target.value)} />
                                    </div>
                                </div>

                                {!internacional && <div class="dataRow">
                                    <p className="fixedP">Número<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <input required type="text" name='cliente' value={numero} onChange={e => setNumero(e.target.value)} />
                                    </div>
                                </div>}
                                <div class="dataRow">
                                    <p className="fixedP">Sueldo Base</p>
                                    <div class="fieldInput">
                                        <CurrencyInput style={{ width: "90%" }} value={sueldoBase} onChangeEvent={handleChangeTarifa} prefix="$" required />

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                    <hr></hr>
                    {
                        isMoreData ? <div className="btn" onClick={e => setIsMoreData(false)}> Mostrar menos </div> : <div className="btn" onClick={e => setIsMoreData(true)}> Mostrar más </div>
                    }

                    {
                        isMoreData && <div>

                            <div class="dataTable twoCol">

                                <div class="dataTitle"><p className="fixedP">Datos Extra</p></div>
                                <div class="dataBox">

                                    <div class="dataRow">
                                        <p className="fixedP">Cuenta</p>
                                        <div class="fieldInput">
                                            <input type="text" name='temergencia' value={cuenta} onChange={e => setCuenta(e.target.value)} />
                                        </div>
                                    </div>

                                    <div class="dataRow">
                                        <p className="fixedP">Teléfono de Emergencia</p>
                                        <div class="fieldInput">
                                            <input type="text" name='temergencia' value={telefonoEmergencia} onChange={e => setTelefonoEmergencia(e.target.value)} />
                                        </div>
                                    </div>

                                    <div class="dataRow">
                                        <p className="fixedP">Contacto de Emergencia</p>
                                        <div class="fieldInput">
                                            <input type="text" name='cemergencia' value={contactoEmergencia} onChange={e => setContactoEmgergencia(e.target.value)} />
                                        </div>
                                    </div>

                                    <div class="dataRow">
                                        <p className="fixedP">Fecha de Contratación</p>
                                        <div class="fieldInput">
                                            <input type="date" name='direccion' style={{ width: "90%" }} value={fechaContratacion} onChange={e => setFechaContratacion(e.target.value)} />
                                        </div>
                                    </div>

                                    <div class="dataRow">
                                        <p className="fixedP">Fecha de Terminación</p>
                                        <div class="fieldInput">
                                            <input type="date" name='direccion' style={{ width: "90%" }} value={fechaTerminacion} onChange={e => setFechaTerminacion(e.target.value)} />
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalCreateEdit;