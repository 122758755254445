import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const requiereProveedor = ['KeMdhwEofxWSIrXh2mylJWra9Jr1', 'isDqcvfDhseYK7ixfiBg1OTOweC2'];
const requiereTAvante = ['LV0bu6i3nsc7QSa55gMrm9rUzMr1'];

export default class Download extends React.Component {
    constructor(props)
    {
        super();
        let {data}=props;
        let {uid}=props;
        this.state = {
            dataSet1: data,
            uid
        }
     //   console.log(this.state);

     
    }

    componentDidUpdate=(prevProps)=>{
        
    }
    
    render() {
        if(this.state.uid && requiereTAvante.find(rs=>rs===this.state.uid)){
            return (
                <ExcelFile  element={
                    <div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>
                  }>
                    <ExcelSheet data={this.state.dataSet1} name="Costos"> 
                        <ExcelColumn label="Nombre" value="nombre"/>
                        <ExcelColumn label="Monto" value="monto"/>
                        <ExcelColumn label="Tipo" value="tipo"/>
                        <ExcelColumn label="Detalles" value="descripcion"/>
                        <ExcelColumn label="Unidad" value="unidadnombre"/>
                        <ExcelColumn label="Fecha" value="fechastring"/>
                        <ExcelColumn label="segmentoNegocio" value="segmentoNegocio"/>
                        <ExcelColumn label="Orden Venta" value="ordenVenta"/>
                        <ExcelColumn label="Estado" value="estado"/>
                    </ExcelSheet>
                   
                </ExcelFile>
            );
        }
        else if(this.state.uid && requiereProveedor.find(rs=>rs===this.state.uid)){
            return (
                <ExcelFile  element={
                    <div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>
                  }>
                    <ExcelSheet data={this.state.dataSet1} name="Costos"> 
                        <ExcelColumn label="Nombre" value="nombre"/>
                        <ExcelColumn label="Monto" value="monto"/>
                        <ExcelColumn label="Tipo" value="tipo"/>
                        <ExcelColumn label="Detalles" value="descripcion"/>
                        <ExcelColumn label="Unidad" value="unidadnombre"/>
                        <ExcelColumn label="Fecha" value="fechastring"/>
                        <ExcelColumn label="Proveedor" value="proveedor"/>
                        <ExcelColumn label="Estado" value="estado"/>
                        
                    </ExcelSheet>
                   
                </ExcelFile>
            );
        }else{
            return (
                <ExcelFile  element={
                    <div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>
                  }>
                    <ExcelSheet data={this.state.dataSet1} name="Costos"> 
                        <ExcelColumn label="Nombre" value="nombre"/>
                        <ExcelColumn label="Monto" value="monto"/>
                        <ExcelColumn label="Tipo" value="tipo"/>
                        <ExcelColumn label="Detalles" value="descripcion"/>
                        <ExcelColumn label="Unidad" value="unidadnombre"/>
                        <ExcelColumn label="Fecha" value="fechastring"/>
                        <ExcelColumn label="segmentoNegocio" value="segmentoNegocio"/>
                        <ExcelColumn label="Estado" value="estado"/>
                    </ExcelSheet>
                   
                </ExcelFile>
            );
        }
       
    }
}
