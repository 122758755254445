import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getUserByUid } from '../../../controllers/usuarios';
import { getClienteByID } from '../../../controllers/clientes';
import { getCfdiById,updateCfdiById } from '../../../controllers/innvoices';
import { getViajeById,updateViaje } from '../../../controllers/viajes';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import xml2js from 'xml2js';
import DatePicker from 'react-datepicker';

import axios from 'axios';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';


const ModalCreateEdit = (props) => {
    //constantes

    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);
    const [tipoFactura, setTipoFactura] = useState('');
    const [idViaje, setIdViaje] = useState(null);
    const [fechaRevision, setFechaRevision] = useState(new Date());
    const [chekingCSD, setCheckinCSD] = useState(true);


    const [facturaArchivos, setFacturaArchivos] = useState(null);

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);

    useEffect(() => {

    }, [user]);





    const initValues = async () => {
        setIsLoading(false);
    }

    useEffect(() => {


    }, [recordedit]);


    useEffect(() => {


        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);

        setFechaRevision(new Date());
        //  initValues();

        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
         setRecordEdit(props.currenteditrecrod);

    }, [props]);
    useEffect(()=>{
        if(recordedit!=null)
        {
            if(recordedit.facuturado)
            {
                if(recordedit.general && recordedit.general.ncarta)
                {
                    setTipoFactura("viaje");
                    getViajeById(recordedit.id).then(resp=>{
                        let data = resp.data();
                        if(data.fechaRevision)
                        {
                            setFechaRevision(data.fechaRevision.toDate());
                        }
                    })
                }else if(recordedit.isViaje===false){
                    setTipoFactura("factura");
                   
                    getCfdiById(recordedit.id).then(resp=>{
                        let data = resp.data();
                        if(data.fechaRevision)
                        {
                            setFechaRevision(data.fechaRevision.toDate());
                        }
                    })
                }
                
            }
            
        }
    },[recordedit])






    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {

            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();
        if(tipoFactura==='viaje'){
            setIsLoading(true);
            updateViaje(recordedit.id,{fechaRevision}).then(resp=>{
                setIsLoading(false);
                props.notify();
                props.toggle();
                Swal.fire("Tiempo asignado", "Se ha asignado el tiempo de revisión a la factura", 'success');
            }).catch(e=>console.log(e));
            //alert("actualiza viaje:"+recordedit.id);

        }else if(tipoFactura==='factura')
        {
            setIsLoading(true);
            updateCfdiById(recordedit.id,{fechaRevision}).then(resp=>{
                setIsLoading(false);
                props.notify();
                props.toggle();
                Swal.fire("Tiempo asignado", "Se ha asignado el tiempo de revisión a la factura", 'success');
            }).catch(e=>console.log(e));
        }
        
    }

    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {facturaArchivos ? "Hora de revisión" : `Hora de revisión `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <div>
                        <div className="row" style={{margin:'50px'}}>
                            {
                                recordedit && <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={fechaRevision} onChange={date => setFechaRevision(date)} />
                            }

                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>

                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >
}
export default ModalCreateEdit;