import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
//import { addIngreso, updateIngreso, addDestino, getAllDestinosByUser } from '../../../controllers/ingresos';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { addRefaccion, updateRefaccion, getRefacciones } from '../../../../controllers/proveedor';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { addUbicacion } from '../../../../controllers/ubicaciones';
import axios from 'axios';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';

const ModalCreateEdit = (props) => {
    //constantes}

    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);

    //estados propios del formulario
    const [active, setActive] = useState(true);
    const [nombre, setNombre] = useState("");
    const [numeroRefaccion, setNumeroRefaccion] = useState("");
    const [fechaIngreso, setFechaIngreso] = useState(new Date());
    const [decimales, setDecimales] = useState(false);
    const [refacciones, setRefacciones] = useState([]);
    const [refaccionId, setRefaccionId] = useState('');
    const [refaccionobj, setRefaccionObj] = useState(null);
    const [cantidadUsada, setCantidadUsada] = useState(0);


    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);
    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);

    useEffect(() => {
        let ff = refacciones.find(f => f.id === refaccionId);
        if (ff) {
            setRefaccionObj(ff);
        }

    }, [refaccionId]);
    const initValues = async () => {
        let refaux = [];
        await getRefacciones(uid).then(resp => {
            resp.forEach(doc => {
                let aa = doc.data();
                aa.id = doc.id;
                refaux.push(aa);
            })
        }).catch(err => { console.log(err) })
        setRefacciones(refaux);



    }



    useEffect(() => {
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit) {
            initValues();
        }

    }, [recordedit]);


    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);





    const handleSumbit = async (e) => {
        e.preventDefault();
        //setIsOpen(false);



        let obj = {
            nombre,
            fechaIngreso,
            numeroRefaccion,
            active,
            uid,
        }



        if (isAdmin && adminCurrentUser) {
            obj.uid = adminCurrentUser;
        }

        //validaciones de formulario

        let notificacion = { refaccionId, refaccionobj, cantidadUsada };

        props.notify({ notificacion });



    }


    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Añadir Refacción" : `Editar Refacción `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP">Refacción</p></div>
                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Refacción</p>
                                    <div class="fieldInput">
                                        <select required name='segmento' onChange={(e) => setRefaccionId(e.target.value)} value={refaccionId} >
                                            <option value={''}></option>
                                            {

                                                refacciones.map(r => {
                                                    return <option value={r.id}>{`${r.nombre} ${r.numeroRefaccion}`}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>




                                {refaccionobj && refaccionobj.cantidad && <div class="dataRow">
                                    <p className="fixedP">Cantidad </p>
                                    <div class="fieldInput">
                                        <input value={cantidadUsada} onChange={e => setCantidadUsada(parseFloat(e.target.value))} type='number' style={{ width: "90%" }} min={0} max={refaccionobj.cantidad}></input>
                                    </div>
                                </div>}



                            </div>
                        </div>

                    </div>
                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalCreateEdit;