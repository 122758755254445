import React, { useState, useEffect, Fragment } from 'react';
import { firebase } from '../../../configs/firebase';
import { deleteIngreso } from '../../../controllers/ingresos';
import { getUserByUid, getAllUsuarios } from '../../../controllers/usuarios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { registerLanguageDictionary } from 'handsontable/i18n';
import { getAllViajesTimbradosByUserDateFilter,getAllViajesByUserDateFilter, getAllViajesByUserAndClienteDateFilter } from '../../../controllers/viajes';
import { getInvoicesDateFilter, getCfdiSolos, getCfdiSolosByCliente } from '../../../controllers/innvoices';
import { getActivos } from '../../../controllers/activos';
import { getClientes } from '../../../controllers/clientes';
import { getUidMaster } from '../../../session/session_controller';
import { getTarifarios } from '../../../controllers/tarifario';
import { getUbicaciones } from '../../../controllers/ubicaciones';
import esMX from 'handsontable/i18n/languages/es-MX';
import moment from 'moment'
import Download from './ExcelExport';
import DownloadMercancia from './ExcelExportMercancias';
import xml2js from 'xml2js';
import LinearProgress from '@material-ui/core/LinearProgress';
import { height } from '@mui/system';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { addDays } from "date-fns";
import DatePicker from 'react-datepicker';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

//import { ActionRender, DateRender, MoneyRender } from './ActionsRender';
//import Download from './ExcelExport';

//particularidades LOZJRkQRLvbN2mWSjX4MFNr1qvj2
const requiereSubliente = ['HptPbvVxYYUjUzp7Nt3TtmyAt0x1'];
const requiereReporteMercancias = ['iHXJJOwSzGVnS4qv723hy4B7bzm2'];

var CryptoJS = require("crypto-js");
const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1C82D0',
    },
}))(LinearProgress);

const ActivoContent = (props) => {

    registerLanguageDictionary(esMX);
    registerLocale('es', es)

    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [tipoReporte, setTipoReporte] = useState("General");
    const [mostrarCancelados, setMostrarCancelados] = useState("No");

    const [isOpen, setIsOpen] = useState(false);

    const [firstDay, setFirstDay] = useState(null);
    const [lastDay, setLastDay] = useState(null);
    const [viajes, setViajes] = useState([]);
    const [viajesMercancias, setViajesMercancias] = useState([]);

    const [progreso, setProgreso] = useState(0);

    const [clientes, setClientes] = useState([]);

    const [cliente, setCliente] = useState('-');
    const [clienteId, setClienteId] = useState("");

    const [backupDiarioFecha, setBackupDiarioFecha] = useState(new Date());

    const validateAdmin = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (user.type && user.type === "administrador") {
                    isadmin = true;
                }
            });
            if (isadmin) {
                setAdminCurrentUser(uid);
                setIsAdmin(true);
            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                setIsAdmin(false);
                setUserlist([]);
                // this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }

    useEffect(() => {
        /*if (isAdmin) {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                setUserlist(a);
            }).catch(e => {
                console.log(e);
                //this.setState({isLoading:false,})
            });
        }*/
    }, [isAdmin]);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {

                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);
                setUser(userr);
                // setAdminCurrentUser(userr.uid);

                if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                    // this.validateAdmin(user.uid);
                    //console.log("validando admin");
                    validateAdmin(userr.uid);
                }
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });

    }, []);

    const getData = async () => {
        if (user && user.uid) {

            let idConsulta = getUidMaster() ? getUidMaster() : user.uid;

            await getClientes(idConsulta).then(snapshot => {
                let aux = [];
                snapshot.forEach(document => {
                    let o = { ...document.data() };
                    o.id = document.id;
                    aux.push(o);
                });
                let toset = aux.map((item, index) => {
                    let ob = { ...item, name: item.nombre.trim() + " - " + item.estado_short.trim(), value: item.id, id: "user" + index };
                    return ob;
                });
                setClientes(toset);
            }).catch(err => { });
        }

    }
    useEffect(() => {
        getData();
        //getData(user);
    }, [user]);

    const getRfcTimbradoFromXML = async (xmldata) => {
        // console.log("data xml",xmldata);

        // let nombreCliente = "";
        let rfcEmisor = "";
        await xml2js.parseString(xmldata, async (err, result) => {
            if (err) {
                console.log(err);
            }
            else {
                // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                //  let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                //console.log("emisor xml", result['cfdi:Comprobante']['cfdi:Emisor'][0]['$']);
                //  console.log("nombre", result['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc']);
                rfcEmisor = result['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc'];
                // nombreCliente = result['cfdi:Comprobante']['cfdi:Receptor'][0]['$']['Nombre'];
                // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
            }

        });
        //  console.log("rfc emite", rfcEmisor);
        return rfcEmisor;
    }
    const getClienteInfoFromXML = async (xmldata) => {
        // console.log("data xml",xmldata);

        let nombreCliente = "";
        let rfcEmisor = "";
        await xml2js.parseString(xmldata, async (err, result) => {
            if (err) {
                console.log(err);
            }
            else {
                if(result['cfdi:Comprobante']['cfdi:CfdiRelacionados'])
                {
                    console.log("tiene relacion",result['cfdi:Comprobante']['cfdi:CfdiRelacionados'][0]['cfdi:CfdiRelacionado'][0]['$']['UUID']);
                }

                // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                //  let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                //console.log("emisor xml", result['cfdi:Comprobante']['cfdi:Emisor'][0]['$']);
                let rfcEmisor = result['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc']
                nombreCliente = result['cfdi:Comprobante']['cfdi:Receptor'][0]['$']['Nombre'];
                // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
            }

            

        });
        return nombreCliente;
    }
    const getClienteInfoTotalFromXML = async (xmldata) => {
        // console.log("data xml",xmldata);

        let total = "";
        await xml2js.parseString(xmldata, async (err, result) => {
            if (err) {
                console.log(err);
            }
            else {
                // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                //  let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                // console.log("primer xml", result['cfdi:Comprobante']["$"]["Total"]);
                total = result['cfdi:Comprobante']["$"]["Total"];
                //nombreCliente=result['cfdi:Comprobante']['cfdi:Receptor'][0]['$']['Nombre'];
                // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
            }

        });
        return total;
    }

    useEffect(() => {
        if (isLoading) {

        }
    }, [isLoading]);


    const actionConsultar = async (tipo = "FACTURACION") => {
        let idConsulta = getUidMaster() ? getUidMaster() : user.uid;

        console.log("consultando");
        setIsLoading(true);

        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        console.log("last day mes", lDay);
        console.log("last day", lDay.getDate())

        let diadiez = new Date(fDay.getFullYear(), fDay.getMonth(), fDay.getDate() + 10);
        let diaveinte = new Date(fDay.getFullYear(), fDay.getMonth(), fDay.getDate() + 20);

        let diasfor = [3, 6, 9, 12, 15, 18, 21, 24, 27, 30];
        let inicioaux = 1;
        let listaIDS = [];
        let prog = 0;
        const procesarRespuesta = (snapshot, isCFDI, facturadonecesario = true) => {
       // console.log("procesando respuesta");
            snapshot.forEach(async (doc) => {
                let pendientes = 0.0;
                let o = doc.data();
                let odt = {};
                if (!o.facuturado && facturadonecesario) {
                    return;
                }

                odt.id = doc.id;
                odt.key = doc.id;
                if (isCFDI) {
                    odt.nfactura = `F-${o.folioFactura}`;
                    odt.carta_porte = "";
                    odt.cliente = o.cliente;
                    odt.referencia = o.referencia || "";
                    odt.fecha = o.fecha_facturado || null;
                    odt.fechaformat = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD-MM-YYYY") : "";
                    odt.fechaformat2 = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD/MM/YYYY") : "";
                    odt.total = parseFloat(o.total) || 0.00;
                    odt.factura_monto = odt.total;
                    odt.moneda = o.moneda || "";
                    odt.distBase_km = "";
                    odt.distVacio_km = "";
                    odt.distTotales_km = "";
                    odt.cancelada = o.facturaCancelada || false;
                } else {
                    odt.factura = o.general.nviaje;
                    odt.carta_porte = o.general.ncarta;
                    odt.segmentoNegocio = o.segmentoNegocio;
                    odt.tipoviaje = o.datos_viaje.tipo
                    odt.cancelada = o.facturaCancelada || false;
                    odt.cliente = o.general.cliente;
                    odt.subcliente = o.general.subcliente || "";
                    if(tipo==="VIAJES"){
                        odt.primeraMercancia= o.mercancias[0] ? o.mercancias[0].descripcion : ""+o.mercancias.length;
                        odt.primeraMercanciaCantidad= o.mercancias[0] ? o.mercancias[0].cantidad : ""+o.mercancias.length;
                    }
                   

                    odt.clienteId = o.general.clienteId;
                    odt.referencia = o.general.referencia;
                    odt.total = o.detalles.tarifaFloat;
                    odt.moneda = o.detalles.currencyMX ? "MXN" : "USD";
                    odt.fecha = o.fecha_facturado || null;
                    odt.fechaformat = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD-MM-YYYY") : "";
                    odt.fechaformat2 = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD/MM/YYYY") : "";
                    odt.nfactura = o.folioFactura ? `F-${o.folioFactura}` : "";

                    odt.distBase_km = o.carta_porte.distBase_km;
                    odt.distVacio_km = o.carta_porte.distVacio_km;
                    odt.distTotales_km = o.carta_porte.distTotales_km;

                    if (uid && requiereSubliente.find(rs => rs === uid)) {
                        odt.subliente = o.subliente || "";
                    }
                }


                if (o.facuturado) {
                    odt.cliente = await getClienteInfoFromXML(o.facuturado.cfdixml);
                    odt.rfcTimbrado = await getRfcTimbradoFromXML(o.facuturado.cfdixml);
                    if (!o.total || o.total === "") {
                        //console.log("test");
                        //  o.total= await getClienteInfoTotalFromXML(o.facuturado.cfdixml)
                        let totalf = await getClienteInfoTotalFromXML(o.facuturado.cfdixml);
                        totalf = parseFloat(totalf);
                        odt.factura_monto = totalf;
                    }
                }

                if (o.pagos) {
                    //calculamos pendientes
                    let pagado = 0.0;
                    o.pagos.forEach(element => {
                        pagado += element.montoFloat;
                    });

                    if (o.factura_monto >= pagado) {
                        pendientes = o.factura_monto - pagado;
                    } else {
                        pendientes = 0.0;
                    }

                } else {
                    pendientes = o.factura_monto;
                }
                odt.pendientes = pendientes;
                odt.obj = { ...odt };

                odt.operador = "";
                odt.unidad = "";
                if (o.dataTimbrada) {
                    if (o.dataTimbrada.transportista) {
                        odt.operador = o.dataTimbrada.transportista.Nombre;
                    }

                    odt.unidad = o.dataTimbrada.NumeroEconomico;
                } else if (o.isFormatoNuevo === false) {
                    odt.operador = o.detalles.operador1;
                    if (o.detalles.operador1 === "N/A" && o.detalles.operador2 !== "N/A") {
                        odt.operador = o.detalles.operador2;
                    }
                    odt.unidad = o.detalles.unidad;
                }



                odt.origen = "";
                odt.destino = "";
                if (o.datos_viaje) {
                    odt.origen = o.datos_viaje.origen;
                    odt.destino = o.datos_viaje.destino;
                }

                odt.status = o.status;


                if (odt.cancelada || odt.facturaCancelada) {
                    odt.status = "Cancelado";
                    odt.cancelada = true;
                }

                if (odt.nfactura === "F-1128") {
                    console.log("F-1128", odt);
                }

                odt.facuturado = o.facuturado ? { uuid: o.facuturado.uuid, cfdixml: o.facuturado.cfdixml } : null;
                odt.facturaname = `${o.prefijoFactura}-${o.folioFactura}`;

                listaIDS.push(odt);
            });
        }
        for await (let a of diasfor) {
            prog += 9;
            setProgreso(prog);
            fDay = new Date(fecha.getFullYear(), fecha.getMonth(), inicioaux);
            if (a === 30) {
                diadiez = new Date(fDay.getFullYear(), fDay.getMonth()+1, 0);
                inicioaux = a + 1;
            } else {
                diadiez = new Date(fDay.getFullYear(), fDay.getMonth(), fDay.getDate() + 2);
                inicioaux = a + 1;
            }

            //periodo
            let inicio = new Date(fDay);
            inicio.setHours(0, 0, 0);
            let fin = new Date(diadiez);
            fin.setHours(23, 59, 59);
            // console.log("periodo inicio",inicio);
            // console.log("periodo fin",fin);
            //continue;
            if (tipoReporte === "Cliente") {
                await getAllViajesByUserAndClienteDateFilter(idConsulta, clienteId, inicio, fin).then(snapshot => {
                    procesarRespuesta(snapshot, false);
                }).catch(err => { console.log(err) });
                if (tipo === "FACTURACION") {
                    await getCfdiSolosByCliente(idConsulta, inicio, fin, clienteId).then(snapshot => {
                        console.log("respuesta de cfdisolos", snapshot.docs.length);
                        procesarRespuesta(snapshot, true);
                    }).catch(err => { console.log(err) });
                }

            } else {

               

                if (tipo === "FACTURACION") {
                    await getAllViajesTimbradosByUserDateFilter(idConsulta, inicio, fin).then(snapshot => {
                        procesarRespuesta(snapshot, false);
                    });
                    await getCfdiSolos(idConsulta, inicio, fin).then(snapshot => {
                        console.log("respuesta de cfdisolos", snapshot.docs.length);
                        procesarRespuesta(snapshot, true);
                    });
                }else if(tipo==="VIAJES"){
                    await getAllViajesByUserDateFilter(idConsulta, inicio, fin).then(snapshot => {
                        procesarRespuesta(snapshot, false);
                    });
                    
                }

            }


        }


        //aplicar filtros
        if (tipoReporte === "Cliente") {
            // listaIDS = listaIDS.filter(f => f.clienteId === clienteId);
        }

        if (mostrarCancelados === "No") {
            listaIDS = listaIDS.filter(f => f.cancelada === false || f.cancelada === undefined);
        }
        console.log("aux", listaIDS);


        const auxfacturas = [];
        await listaIDS.forEach(async (element) => {
            let foliouuid = "";
            let subtotal = "";
            let totalcfdi = "";
            let monedacfdi = "";
            let trasladado = "";
            let retenido = "";
            if (element.facuturado) {
                foliouuid = element.facuturado.uuid;

                await xml2js.parseString(element.facuturado.cfdixml, async (err, result) => {
                    if (err) {
                        console.log(err);
                    }
                    else {
                        // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                        //  let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                        // console.log("primer xml", result['cfdi:Comprobante']["$"]["Total"]);
                        subtotal = result['cfdi:Comprobante']["$"]["SubTotal"];
                        totalcfdi = result['cfdi:Comprobante']["$"]["Total"];
                        monedacfdi = result['cfdi:Comprobante']["$"]["Moneda"];
                        // console.log("impuestos retenidos", result['cfdi:Comprobante']['cfdi:Impuestos'])
                        retenido = result['cfdi:Comprobante']['cfdi:Impuestos'] ? result['cfdi:Comprobante']['cfdi:Impuestos'][0]['$']['TotalImpuestosRetenidos'] || "" : "";
                        trasladado = result['cfdi:Comprobante']['cfdi:Impuestos'] ? result['cfdi:Comprobante']['cfdi:Impuestos'][0]['$']['TotalImpuestosTrasladados'] || "" : "";
                        // trasladado = result['cfdi:Comprobante']['cfdi:Impuestos']['$']['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$']['Importe'] || "";

                    }

                });
            }




            let n = { ...element, foliouuid, subtotal, totalcfdi, monedacfdi, retenido, trasladado };

            auxfacturas.push(n);
        });


        setProgreso(100);
        if (tipo === "FACTURACION") {
        setViajes(auxfacturas);
        }else if(tipo==="VIAJES"){
            setViajesMercancias(auxfacturas);
        }


        await setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }


    const actionBackupDiario = async () => {
        setIsLoading(true);
        let idConsulta = getUidMaster() ? getUidMaster() : user.uid;
        let inicio = new Date(backupDiarioFecha);
        inicio.setHours(0, 0, 0);
        let fin = new Date(backupDiarioFecha);
        fin.setHours(23, 59, 59);


        let viajes = [];
        let cfdinormal = [];
        let clientes = [];
        let activos = [];
        let tarifas = [];
        let ubicaciones = [];

        await getAllViajesTimbradosByUserDateFilter(idConsulta, inicio, fin).then(snapshot => {
            snapshot.forEach(document => {
                let o = { id: document.id, ...document.data() };
                viajes.push(o);
            });
        });


        await getCfdiSolos(idConsulta, inicio, fin).then(snapshot => {

            snapshot.forEach(document => {
                let o = { id: document.id, ...document.data() };
                cfdinormal.push(o);
            });
        });

        await getClientes(idConsulta).then(snapshot => {
            snapshot.forEach(document => {
                let o = { id: document.id, ...document.data() };
                clientes.push(o);
            });
        });
        await getActivos(idConsulta).then(snapshot => {
            snapshot.forEach(document => {
                let o = { id: document.id, ...document.data() };
                activos.push(o);
            });
        });

        await getTarifarios(idConsulta).then(snapshot => {
            snapshot.forEach(document => {
                let o = { id: document.id, ...document.data() };
                tarifas.push(o);
            });
        });

        await getUbicaciones(idConsulta).then(snapshot => {
            snapshot.forEach(document => {
                let o = { id: document.id, ...document.data() };
                ubicaciones.push(o);
            });
        });


        let backup = { viajes, cfdinormal, clientes, activos, ubicaciones, tarifas };
        //console.log(backup);
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(backup)
        )}`;

        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "backup.json";

        link.click();

        await setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }

    return (
        <Fragment>
            {
                <div className="row">
                    <div className="col-12">
                        <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>&nbsp;Reportes</h3>
                        <div className="card">
                            <LoadingOverlay
                                active={isLoading}
                                spinner
                                text='Generando reporte, espere ...'
                            >
                                <div className="card-body">

                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div class="dataTable twoCol">

                                            <div class="dataTitle"><p className="fixedP">Reporte de Facturación</p></div>

                                            <div class="dataBox">
                                                <div className='col-12'>
                                                    <div class="dataRow">
                                                        <p className="fixedP">Mes</p>
                                                        <div class="fieldInput">
                                                            <DatePicker
                                                                className="form-control customdatepicker"
                                                                locale="es"
                                                                selected={fecha}
                                                                onChange={date => setFecha(date)}
                                                                maxDate={addDays(new Date(), 1)}
                                                                dateFormat="MMMM yyyy"
                                                                showMonthYearPicker
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="dataRow">
                                                        <p className="fixedP">Tipo de Reporte</p>
                                                        <div class="fieldInput">
                                                            <select name='segmento' onChange={(e) => setTipoReporte(e.target.value)} value={tipoReporte} required >
                                                                <option key={'reportegeneral'} value={"General"} >General</option>
                                                                <option key={'reportecliente'} value={"Cliente"}>Cliente</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {tipoReporte === "Cliente" && <div class="dataRow">
                                                        <p className="fixedP">Cliente<label className="requiredlabel">*</label></p>
                                                        <div class="fieldInput">

                                                            {

                                                                cliente &&
                                                                <Autocomplete
                                                                    id="combo-box-demo0c"
                                                                    options={clientes}
                                                                    getOptionLabel={(option) => option.name}
                                                                    getOptionSelected={(option, value) => option.name === value.name}
                                                                    defaultValue={clientes.find(element => element.name.trim() === cliente.trim())}
                                                                    className={"selectbusqueda"}
                                                                    onChange={(e, val) => {
                                                                        setCliente(val ? val.name : "");
                                                                        setClienteId(val ? val.value : "");
                                                                    }}
                                                                    renderInput={(params) => <TextField className="reducedselect2" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                />}

                                                            {
                                                                !cliente &&
                                                                <Autocomplete
                                                                    id="combo-box-demo0c"
                                                                    options={clientes}
                                                                    getOptionLabel={(option) => option.name}
                                                                    getOptionSelected={(option, value) => option.name === value.name}
                                                                    className={"selectbusqueda"}
                                                                    onChange={(e, val) => {
                                                                        setCliente(val ? val.name : "");
                                                                        setClienteId(val ? val.value : "");
                                                                    }}
                                                                    renderInput={(params) => <TextField className="reducedselect2" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                />
                                                            }




                                                        </div>
                                                    </div>}

                                                    <div class="dataRow">
                                                        <p className="fixedP">Incluir Cancelados</p>
                                                        <div class="fieldInput">
                                                            <select name='segmento' onChange={(e) => setMostrarCancelados(e.target.value)} value={mostrarCancelados} required >
                                                                <option key={'canceladosno'} value={"No"} >No</option>
                                                                <option key={'canceladossi'} value={"Si"}>Si</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div style={{ alignItems: 'end', display: 'flex', marginBottom: '8px', width: '100%' }}>
                                                        {<button type="button" style={{ marginLeft: 'auto' }} className="boton primBtn" onClick={async () => {
                                                            // ActualizacionMasivaTemporal().then(resp=>console.log("okkk")).catch(er=>console.log(er));
                                                            // prosesarPeticion();


                                                            actionConsultar();



                                                        }}>Generar Reporte</button>}
                                                        {
                                                            !isLoading && viajes.length > 0 && <Download uid={uid} style={{ marginLeft: '5px' }} data={
                                                                viajes.map(element => {
                                                                    return {
                                                                        ...element
                                                                    }
                                                                })
                                                            } className="" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            uid && requiereReporteMercancias.find(f => f === uid) && <div class="dataTable twoCol">

                                                <div class="dataTitle"><p className="fixedP">Reporte de Mercancias</p></div>

                                                <div class="dataBox">
                                                    <div className='col-12'>
                                                        <div class="dataRow">
                                                            <p className="fixedP">Mes</p>
                                                            <div class="fieldInput">
                                                                <DatePicker
                                                                    className="form-control customdatepicker"
                                                                    locale="es"
                                                                    selected={fecha}
                                                                    onChange={date => setFecha(date)}
                                                                    maxDate={addDays(new Date(), 1)}
                                                                    dateFormat="MMMM yyyy"
                                                                    showMonthYearPicker
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="dataRow">
                                                            <p className="fixedP">Tipo de Reporte</p>
                                                            <div class="fieldInput">
                                                                <select name='segmento' onChange={(e) => setTipoReporte(e.target.value)} value={tipoReporte} required >
                                                                    <option key={'reportegeneral'} value={"General"} >General</option>
                                                                    <option key={'reportecliente'} value={"Cliente"}>Cliente</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        {tipoReporte === "Cliente" && <div class="dataRow">
                                                            <p className="fixedP">Cliente<label className="requiredlabel">*</label></p>
                                                            <div class="fieldInput">

                                                                {

                                                                    cliente &&
                                                                    <Autocomplete
                                                                        id="combo-box-demo0c"
                                                                        options={clientes}
                                                                        getOptionLabel={(option) => option.name}
                                                                        getOptionSelected={(option, value) => option.name === value.name}
                                                                        defaultValue={clientes.find(element => element.name.trim() === cliente.trim())}
                                                                        className={"selectbusqueda"}
                                                                        onChange={(e, val) => {
                                                                            setCliente(val ? val.name : "");
                                                                            setClienteId(val ? val.value : "");
                                                                        }}
                                                                        renderInput={(params) => <TextField className="reducedselect2" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                    />}

                                                                {
                                                                    !cliente &&
                                                                    <Autocomplete
                                                                        id="combo-box-demo0c"
                                                                        options={clientes}
                                                                        getOptionLabel={(option) => option.name}
                                                                        getOptionSelected={(option, value) => option.name === value.name}
                                                                        className={"selectbusqueda"}
                                                                        onChange={(e, val) => {
                                                                            setCliente(val ? val.name : "");
                                                                            setClienteId(val ? val.value : "");
                                                                        }}
                                                                        renderInput={(params) => <TextField className="reducedselect2" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                                    />
                                                                }




                                                            </div>
                                                        </div>}


                                                        <div style={{ alignItems: 'end', display: 'flex', marginBottom: '8px', width: '100%' }}>
                                                            {<button type="button" style={{ marginLeft: 'auto' }} className="boton primBtn" onClick={async () => {
                                                                // ActualizacionMasivaTemporal().then(resp=>console.log("okkk")).catch(er=>console.log(er));
                                                                // prosesarPeticion();


                                                                actionConsultar("VIAJES");



                                                            }}>Generar Reporte</button>}
                                                            {
                                                                !isLoading && viajesMercancias.length > 0 && <DownloadMercancia uid={uid} style={{ marginLeft: '5px' }} data={
                                                                    viajesMercancias.map(element => {
                                                                        return {
                                                                            ...element
                                                                        }
                                                                    })
                                                                } className="" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        }

                                        {isAdmin && <div class="dataTable twoCol">

                                            <div class="dataTitle"><p className="fixedP">Respaldo Diario</p></div>

                                            <div class="dataBox">
                                                <div className='col-12'>
                                                    <div class="dataRow">
                                                        <p className="fixedP">Fecha</p>
                                                        <div class="fieldInput">
                                                            <DatePicker
                                                                dateFormat="dd/MM/yyyy"
                                                                style={{ width: '90%' }}
                                                                selected={backupDiarioFecha} onChange={date => setBackupDiarioFecha(date)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ alignItems: 'end', display: 'flex', marginBottom: '8px', width: '100%' }}>
                                                        {<button type="button" style={{ marginLeft: 'auto' }} className="boton primBtn" onClick={async () => {
                                                            // ActualizacionMasivaTemporal().then(resp=>console.log("okkk")).catch(er=>console.log(er));
                                                            // prosesarPeticion();


                                                            actionBackupDiario();



                                                        }}>Generar Respaldo</button>}

                                                    </div>
                                                </div>
                                            </div>

                                        </div>}
                                    </div>



                                    <div style={{ height: '400px', width: '100%' }} >

                                    </div>

                                </div>



                            </LoadingOverlay>
                            {
                                progreso > 0 && progreso < 100 && <BorderLinearProgress variant="determinate" value={progreso} />
                            }
                        </div>
                    </div>

                </div>
            }
        </Fragment>
    );
}
export default ActivoContent;