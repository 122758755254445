import React, { useState, useEffect, Fragment } from 'react';
import { firebase } from '../../../configs/firebase';
import { getUserByUid, getAllUsuarios } from '../../../controllers/usuarios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { registerLanguageDictionary } from 'handsontable/i18n';
import esMX from 'handsontable/i18n/languages/es-MX';
import { makeStyles } from '@material-ui/core/styles';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { HotTable, HotColumn } from '@handsontable/react';
import { EstatusRender, EstatusRenderUnidad, ActionRender, DropRender, TimeRender, DateRender, LinkViajeRender } from './ActionsRender';
import { getUnidades, asignarPrePlan } from '../../../controllers/unidades';
import { despacharViaje, getAllViajesByUserDateFilter, despacharUnidad, getAllViajesByUserAndStatus, actualizaHoraFila, actualizaHoraInspeccion, actualizaHoraModuloMX, actualizaHoraModuloUsa, actualizaHoraUpViaje, actualizaHoraCarga, actualizaHoraEntregadocumentos, actualizaHoraStartViaje, actualizaHoraEndViaje, actualizaHoraDespViaje, preplanearViaje, actualizaRecoleccionViaje, actualizaTerminoRecoleccionViaje, actualizaSalidaBaseViaje, actualizaRegresoVacioViaje } from '../../../controllers/viajes';
import { getOperadores, getOperadorById } from '../../../controllers/operadores';
import Checkbox from '@material-ui/core/Checkbox';
import Download from './ExcelExport';
import moment from 'moment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { getUidMaster } from '../../../session/session_controller';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
    },
    paperrootfull: {
        width: '100%',
        margin: '5px',
        padding: '30px',
        display: 'flex'
    },

    paperroot: {
        width: '33%',
        display: 'flex'
    },
    paper: {
        minHeight: 80,
        height: 'auto',
        width: '100%',
    },
    paperscontainer: {
        // width: '100%'
        //overflow: 'hidden'
        flex: 1
    },
    title: {
        height: 30,
        backgroundColor: '#dcdcdc',
        paddingLeft: 10,
        paddingTop: 5,
    },
}));

const requiereSoloUnidadesConOperador = ["KeMdhwEofxWSIrXh2mylJWra9Jr1"];
const PlaneacionContent = (props) => {

    registerLanguageDictionary(esMX);
    registerLocale('es', es)
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [firstDay, setFirstDay] = useState(null);
    const [lastDay, setLastDay] = useState(null);
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [querysearch, setQuerysearch] = useState('');
    const [ingresos, setIngresos] = useState([]);
    const [ingresosNoFilter, setIngresosNoFilter] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currenteditrecrod, setCurrentEditRecord] = useState(null);
    const [total, setTotal] = useState(0.0);
    const [totalMask, setTotalMask] = useState('');

    const [cargarDatosPeridodo, setCargarDatosPeddridodo] = useState("0");
    const [fechaRangoStart, setFechaRangoStart] = useState(new Date());
    const [fechaRangoEnd, setFechaRangoEnd] = useState(new Date());


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [operadores, setOperadores] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [cargas, setCargas] = useState([]);
    const [isDespachados, setIsDespachados] = useState(false);


    const onChangeDaterangeTwo = (fechas) => {
        if (!fechas || !fechas[0] || !fechas[1]) {
            return;
        }

        let start = new Date(fechas[0].getTime());
        let end = new Date(fechas[1].getTime());

        if (start)
            start.setHours(0, 0, 0);
        if (end)
            end.setHours(23, 59, 59);
        setFechaRangoStart(start);
        setFechaRangoEnd(end);

        if (start && end) {
            if (start.getTime() <= end.getTime()) {

                setTimeout(() => {
                    console.log("buscar por rango");
                    BuscarPorRango(start, end);
                }, 500);
            }
        }

    };
    const BuscarPorRango = async (fechainicio, fechafinal) => {

        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }

            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }

            let a = [];
            let va = [];
            let index = 0;
            var moment = require('moment'); // require

            //let fDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            // let lDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

            let inicioconsulta = fechainicio;
            let finconsulta = fechafinal;

            inicioconsulta.setHours(0, 0, 0);
            setIsLoading(true);


            console.log("iniciando consulta de otro mes", usertoconsult.uid);
            let c = [];
            await getAllViajesByUserDateFilter(usertoconsult.uid, inicioconsulta, finconsulta).then(snapshot => {
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.id = doc.id;
                    o.key = doc.id;
                    o.index = doc.id;
                    o.name = o.general.nviaje || "viaje";
                    o.num = o.general.nviaje;
                    o.pp = o.pp ? 'SI' : '';
                    o.obj = { ...o };
                    o.preplan = o.preplan_viaje ? o.preplan_viaje : '';
                    if (!o.fecha_despacho) {
                        o.fecha_despacho = "";
                    }
                    if (o.carta_porte.remolque1 && o.carta_porte.remolque1 !== "") {
                        o.carta_porte.remolque = o.carta_porte.remolque1;
                    } else if (o.carta_porte.remolque2 && o.carta_porte.remolque2 !== "") {
                        o.carta_porte.remolque = o.carta_porte.remolque2;
                    }
                    o.destino = `${o.carta_porte.destinoCiudad},${o.carta_porte.destinoEstadoShort}`;
                    o.origen = `${o.carta_porte.origenCiudad},${o.carta_porte.origenEstadoShort}`;
                    c.push(o);
                });


            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar los viajes, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });
            console.log("finalizando consulta de otro mes")

            setCargas(c);

            setIsLoading(false);
            //buscar updates

        }

    }
    const validateAdmin = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (user.type && user.type === "administrador") {
                    isadmin = true;
                }
            });
            if (isadmin) {
                setAdminCurrentUser(uid);
                setIsAdmin(true);
            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                setIsAdmin(false);
                setUserlist([]);
                // this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }

    useEffect(() => {
        if (isAdmin) {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                setUserlist(a);
            }).catch(e => {
                console.log(e);
                //this.setState({isLoading:false,})
            });
        }
    }, [isAdmin]);

    useEffect(() => {
        setTotalMask(total.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
    }, [total]);

    useEffect(() => {


        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {
                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);

                setUser(userr);
                // setAdminCurrentUser(userr.uid);

                if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                    // this.validateAdmin(user.uid);
                    //console.log("validando admin");
                    validateAdmin(userr.uid);
                }
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });

    }, []);




    useEffect(() => {
        if (querysearch.trim() === '') {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                aux.push(element);
            });
            setIngresos(aux);
        } else {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                let query = querysearch.trim().toUpperCase();
                if (element.numEconomico.toUpperCase().includes(query) ||
                    element.marca.toUpperCase().includes(query) ||
                    element.placas.toUpperCase().includes(query) ||
                    element.tipo.toUpperCase().includes(query) ||
                    element.year.includes(query) ||
                    element.descripcion.toUpperCase().includes(query)) {
                    aux.push(element);
                }

            });
            setIngresos(aux);
        }
    }, [querysearch]);

    useEffect(() => {
        getData(user);

        let inicioconsulta = new Date();
        let finconsulta = new Date();
        inicioconsulta.setHours(0, 0, 0);
        finconsulta.setHours(23, 59, 59);

        BuscarPorRango(inicioconsulta, finconsulta);
    }, [user, currentDate, adminCurrentUser]);

    useEffect(() => {
        if (!isOpen) {
            setCurrentEditRecord(null);
        }
    }, [isOpen])


    useEffect(() => {
        //calculando totales
        let t = 0.0;
        ingresos.forEach(element => {
            if (element.montoFloat) {
                t += element.montoFloat;
            }
        });
        setTotal(t);
    }, [ingresos])

    const refresh = () => {
        getData(user);
        let inicioconsulta = new Date();
        let finconsulta = new Date();
        inicioconsulta.setHours(0, 0, 0);
        finconsulta.setHours(23, 59, 59);

        BuscarPorRango(inicioconsulta, finconsulta);
    }



    const handleDateChanged = (date) => {
        let fDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);
        setCurrentDate(date);
    }

    const findRecord = (id) => {
        let obj = ingresos.find(element => element.id === id);
        return obj;
    }

    useEffect(() => {
        // getCargas(user);
    }, [isDespachados]);

    const getCargas = async (usertodisplay) => {
        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }
            // setIsLoading(true);
            let c = [];
            setIsLoading(true);
            await getAllViajesByUserAndStatus(usertoconsult.uid, "Planeado").then(snapshot => {

                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.id = doc.id;
                    o.key = doc.id;
                    o.index = doc.id;
                    if (o.carta_porte.remolque1 && o.carta_porte.remolque1 !== "") {
                        o.carta_porte.remolque = o.carta_porte.remolque1;
                    } else if (o.carta_porte.remolque2 && o.carta_porte.remolque2 !== "") {
                        o.carta_porte.remolque = o.carta_porte.remolque2;
                    }
                    console.log("datos getAllViajesByUserAndStatus", o);
                    o.name = o.general.nviaje || "viaje";
                    o.num = o.general.nviaje;
                    o.pp = o.pp ? 'SI' : '';
                    o.obj = { ...o };
                    o.preplan = o.preplan_viaje ? o.preplan_viaje : '';
                    if (!o.fecha_despacho) {
                        o.fecha_despacho = "";
                    }

                    o.destino = `${o.carta_porte.destinoCiudad},${o.carta_porte.destinoEstadoShort}`;
                    o.origen = `${o.carta_porte.origenCiudad},${o.carta_porte.origenEstadoShort}`;
                    c.push(o);
                });
            }).catch(e => {
                console.log("error", e);
            });
            setIsLoading(false);
            if (isDespachados) {
                await getAllViajesByUserAndStatus(usertoconsult.uid, "Despachado").then(snapshot => {
                    snapshot.forEach(doc => {
                        let o = doc.data();
                        o.id = doc.id;
                        o.key = doc.id;
                        o.index = doc.id;
                        o.name = o.general.nviaje || "viaje";
                        o.num = o.general.nviaje;
                        if (o.carta_porte.remolque1 && o.carta_porte.remolque1 !== "") {
                            o.carta_porte.remolque = o.carta_porte.remolque1;
                        } else if (o.carta_porte.remolque2 && o.carta_porte.remolque2 !== "") {
                            o.carta_porte.remolque = o.carta_porte.remolque2;
                        }
                        console.log("datos getAllViajesByUserAndStatus", o);
                        o.num = o.general.nviaje;
                        o.pp = o.pp ? 'SI' : '';
                        o.obj = { ...o };
                        o.preplan = o.preplan_viaje ? o.preplan_viaje : '';
                        if (!o.fecha_despacho) {
                            o.fecha_despacho = "";
                        }
                        o.destino = `${o.carta_porte.destinoCiudad},${o.carta_porte.destinoEstadoShort}`;
                        o.origen = `${o.carta_porte.origenCiudad},${o.carta_porte.origenEstadoShort}`;
                        o.obj = { ...o };
                        c.push(o);
                    });
                }).catch(e => {
                    console.log("error", e);
                });
            }
            setCargas(c);
        }
    }

    const getData = async (usertodisplay) => {
        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }
            // setIsLoading(true);
            let a = [];
            let index = 0;
            var moment = require('moment'); // require


            //get operadores
            let operadorestemp = [];
            await getOperadores(usertoconsult.uid).then(snapshot => {

                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.id = doc.id;
                    operadorestemp.push(o);
                });
                console.log("asignando operadores", operadorestemp);
                setOperadores(operadorestemp);
            }).catch(err => { console.log(err) });

            await getUnidades(usertoconsult.uid).then(snapshot => {
                let unids = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.key = doc.id;
                    o.id = doc.id;
                    o.name = o.nombre || "Unidad";
                    o.num = o.placas;
                    o.obj = { ...o };

                    o.status = o.viaje_asignado ? "Despachado" : "Disponible";
                    o.pp = " ";
                    if (o.status === "Despachado") {
                        o.pp = o.preplan_viaje ? "Si" : "No";
                    }
                    o.clase = "";
                    o.viajetipo = "";
                    o.rem1 = "";
                    o.rem2 = ""
                    o.aux = "";
                    if (o.viaje_asignado) {
                        o.clase = o.viaje_asignado.datos_viaje.claseViaje;
                        o.viajetipo = o.viaje_asignado.general.isFull ? "Full" : "Sencillo";
                        o.rem1 = o.viaje_asignado.carta_porte.remolque1;
                        o.rem2 = o.viaje_asignado.carta_porte.remolque2;
                        o.aux = o.viaje_asignado.carta_porte.otro;
                    }
                    o.viaje_preplaneado = o.preplan_viaje ? o.preplan_viaje.general.nviaje : "";
                    o.operadorname = "";
                    if (o.operador) {
                        let opebusqueda = "" + o.operador.trim();
                        let objoperador = operadorestemp.find(element => element.id === opebusqueda);
                        if (objoperador) {

                            let nombre = "";
                            if (objoperador.nombre) {
                                nombre += objoperador.nombre;
                            }
                            if (objoperador.apellidos) {
                                nombre += " " + objoperador.apellidos;
                            }
                            o.operadorname = nombre;
                        } else {
                            console.log("tiene operador pero no se en contro", o.operador);
                        }

                    }

                    unids.push(o);
                    if (!o.operador && uid && requiereSoloUnidadesConOperador.find(f => f === uid)) {
                        unids.pop(o);
                    }

                });
                setUnidades(unids);
            }).catch(e => { });

            //await getCargas(user);

            /**
             * action getdata
             */
            /*await getAllIngresosByUserDateFilter(usertoconsult.uid, firstDay, lastDay).then(snapshot => {
                            snapshot.forEach(doc => {
                                let o = doc.data();
                                o.index = doc.id;
                                o.n = index;
                                o.id = doc.id;
                                o.key = doc.id;
                                o.fechaformat = moment(o.fecha.toDate()).format('DD/MM/YYYY');
                                index++;
                                a.push(o);
                            });
                            setIngresos(a);
                            setIngresosNoFilter(a);
                            setIsLoading(false);
                        }).catch(err => {
                            console.log(err);
                            Swal.fire("Error", "Error de al consultar los ingresos, comprueba tu conexión ", 'error');
                            setIsLoading(false);
                        });*/
        } else {
            //console.log("user null");
        }
    }

    useEffect(() => {
        if (currenteditrecrod != null) {
            if (currenteditrecrod.none) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
            }
            setIsOpen(true);
        }
    }, [currenteditrecrod])

    const handleCreate = () => {
        setIsEdit(false);
        setCurrentEditRecord({ none: true });
        //setIsOpen(true);
    }
    const handleEditFromId = id => {
        let obj = ingresos.find(element => element.id === id);
        if (obj) {
            setIsEdit(true);
            setCurrentEditRecord(obj);

        }
    }
    const handleDespachar = async (idViaje, idUnidad) => {

        let objUnidad = unidades.find(element => element.id === idUnidad.trim());
        let objViaje = cargas.find(element => element.id === idViaje.trim());
        if (objUnidad && objViaje) {
            let operadorName = "";
            if (objUnidad && objUnidad.operador) {
                await getOperadorById(objUnidad.operador).then(doc => {
                    let operador = doc.data();
                    if (operador.nombre)
                        operadorName = operador.nombre;
                    if (operador.apellidos) {
                        operadorName += " " + operador.apellidos;
                    }
                    //console.log("asignando operador", doc.data());
                }).catch(err => { });
            }

            await despacharViaje(idViaje, objUnidad, operadorName).then(resp => { }).catch(err => { console.log(err) });
            await despacharUnidad(idUnidad, objViaje).then(resp => { }).catch(err => { console.log(err) });
            Swal.fire('Éxito', 'Se ha despachado el viaje.', 'success');
            window.open(`/viaje/${idViaje}`, '_blank');


            getData(user);
        } else {
            alert(`Error, no se encontro unidad o viaje`);

        }
    }

    const actualizaLocalmente = (id, hora, nombreHora) => {
        setCargas(cargas.map(c => {

            let objc = { ...c };
            if (objc.id === id) {
                objc[nombreHora] = hora;
                objc.obj[nombreHora] = hora;
            }
            return objc;
        }))
    }

    const handleChangeRecoleccion = (id, hora) => {

        if (hora != null) {
            actualizaLocalmente(id, hora, "hora_recoleccion");
            actualizaRecoleccionViaje(id, hora).then(resp => { }).catch(err => {
                console.log("error hora", err);
            });
        }

    }

    const handleChangeTerminoRecoleccion = (id, hora) => {
        if (hora != null) {
            actualizaLocalmente(id, hora, "hora_termino_recoleccion");
            actualizaTerminoRecoleccionViaje(id, hora).then(resp => { }).catch(err => {
                console.log("error hora", err);
            });
        }
    }

    const handleChangeSalidaBase = (id, hora) => {
        if (hora != null) {
            actualizaLocalmente(id, hora, "hora_salida_base");
            actualizaSalidaBaseViaje(id, hora).then(resp => { }).catch(err => {
                console.log("error hora", err);
            });
        }
    }

    const handleChangeRegresoVasio = (id, hora) => {
        if (hora != null) {
            actualizaLocalmente(id, hora, "hora_regreso_vacio");
            actualizaRegresoVacioViaje(id, hora).then(resp => { }).catch(err => {
                console.log("error hora", err);
            });
        }
    }

    const handleChangeHourUp = (id, hora) => {

        if (hora != null) {
            actualizaLocalmente(id, hora, "hora_up");
            actualizaHoraUpViaje(id, hora).then(resp => { }).catch(err => {
                console.log("error hora", err);
            });
        }

    }
    const handleChangeHourCarga = (id, hora) => {

        if (hora != null) {
            actualizaLocalmente(id, hora, "hora_carga");
            actualizaHoraCarga(id, hora).then(resp => { }).catch(err => {
                console.log("error hora", err);
            });
        }

    }
    const handleChangeHourDocumentos = (id, hora) => {

        if (hora != null) {
            actualizaLocalmente(id, hora, "hora_documentos");
            actualizaHoraEntregadocumentos(id, hora).then(resp => { }).catch(err => {
                console.log("error hora", err);
            });
        }

    }

    const handleChangeHourFila = (id, hora) => {

        if (hora != null) {
            actualizaLocalmente(id, hora, "hora_fila");
            actualizaHoraFila(id, hora).then(resp => { }).catch(err => {
                console.log("error hora", err);
            });
        }

    }
    const handleChangeHourInspeccion = (id, hora) => {

        if (hora != null) {
            actualizaLocalmente(id, hora, "hora_inspeccion");
            actualizaHoraInspeccion(id, hora).then(resp => {
                console.log("datos acutales", cargas.map(m => m.obj))
            }).catch(err => {
                console.log("error hora", err);
            });
        }

    }

    const handleChangeHourModuloMx = (id, hora) => {
        if (hora != null) {
            actualizaLocalmente(id, hora, "hora_modulomx");
            actualizaHoraModuloMX(id, hora).then(resp => { }).catch(err => {
                console.log("error hora", err);
            });
        }

    }
    const handleChangeHourModuloUSA = (id, hora) => {
        if (hora != null) {
            actualizaLocalmente(id, hora, "hora_modulousa");
            actualizaHoraModuloUsa(id, hora).then(resp => { }).catch(err => {
                console.log("error hora", err);
            });
        }

    }

    const handleChangeHourStart = (id, hora) => {
        if (hora != null) {
            actualizaLocalmente(id, hora, "hora_start");
            actualizaHoraStartViaje(id, hora).then(resp => { }).catch(err => {
                console.log("error hora", err);
            });
        }

    }
    const handleChangeHourEnd = (id, hora) => {
        if (hora != null) {
            actualizaLocalmente(id, hora, "hora_end");
            actualizaHoraEndViaje(id, hora).then(resp => { }).catch(err => {
                console.log("error hora", err);
            });
        }

    }

    const handleChangeHourDesp = (id, hora) => {
        if (hora != null) {
            actualizaLocalmente(id, hora, "hora_desp");
            actualizaHoraDespViaje(id, hora).then(resp => { }).catch(err => {
                console.log("error hora", err);
            });
        }
    }

    const handlePrePlanear = async (id_unidad, id_viaje) => {
        //preplan_viaje
        const viajeapreplanear = cargas.find(element => element.id == id_viaje);
        const unidadapreplanear = unidades.find(element => element.id == id_unidad);
        if (viajeapreplanear && unidadapreplanear) {
            await asignarPrePlan(id_unidad, viajeapreplanear).then(resp => { }).catch(err => { console.log(err) });
            await preplanearViaje(id_viaje, unidadapreplanear).then(resp => { }).catch(err => { console.log(err) });
            Swal.fire('Éxito', 'Se ha pre-plaenado el viaje.', 'success');
            getData(user);
        }
    }

    const handleGoToViaje = (nviaje) => {
        let viajeToGO = cargas.find(element => element.general.nviaje == nviaje);
        if (viajeToGO) {
            // window.location.href = `/viaje/${viajeToGO.id}`;
            window.open(`/viaje/${viajeToGO.id}`, '_blank');
        }
    }
    useEffect(() => {
        let inicioconsulta = new Date();
        let finconsulta = new Date();
        inicioconsulta.setHours(0, 0, 0);
        finconsulta.setHours(23, 59, 59);

        BuscarPorRango(inicioconsulta, finconsulta);

    }, [cargarDatosPeridodo]);
    return (

        <div className="card-body">
            <div className={classes.root}>
                <DndProvider backend={HTML5Backend}>

                    <LoadingOverlay
                        active={isLoading}
                        spinner
                        text='Cargando ...'
                    >
                        <div className="col-12">
                            <div className="col-12 row">
                                <div className="col-1">
                                    <h3 style={{ fontWeight: "600" }}>Cargas</h3>
                                </div>
                                <div className='col-10' style={{ display: 'flex' }}>

                                    {/* <div className="">
                                        <span>Mostrar Planeados</span>
                                        <Checkbox disabled checked inputProps={{ 'aria-label': 'disabled checked checkbox' }} />
                                    </div>
                                    <div className="">
                                        <span>Mostrar Despachados</span>
                                        <Checkbox
                                            checked={isDespachados}
                                            onChange={() => setIsDespachados(!isDespachados)}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    </div>*/}
                                    <div>
                                        <FormControl component="fieldset">
                                            <RadioGroup row aria-label="periodo" name="periodo" value={cargarDatosPeridodo} onChange={e => setCargarDatosPeddridodo(e.target.value)}>
                                                <FormControlLabel value="0" control={<Radio />} label="Día actual" />
                                                <FormControlLabel value="1" control={<Radio />} label="Rango" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    {cargarDatosPeridodo === "1" && <div style={{ paddingTop: '5px' }} className="col-6">
                                        <DateRangePicker
                                            style={{ marginTop: '5px' }}
                                            clearIcon={null}
                                            value={[fechaRangoStart, fechaRangoEnd]}
                                            onChange={onChangeDaterangeTwo}
                                        />
                                        { /*<DatePicker
                                                selected={fechaRangoStart}
                                                onChange={onChangeDaterange}
                                                startDate={fechaRangoStart}
                                                endDate={fechaRangoEnd}
                                                selectsRange={true}
                                                withPortal
                                                //inline
                                            />*/}
                                    </div>}
                                    <div className="">
                                        {
                                            !isLoading && cargas.length > 0 && <Download data={
                                                cargas.map(element => {
                                                    return {
                                                        estatus: element.status,
                                                        unidad: element.detalles.unidad,
                                                        cliente: element.general.cliente,
                                                        origen: element.origen,
                                                        destino: element.destino,
                                                        viaje: element.name,
                                                        hrecoleccion: element.hora_recoleccion ? element.hora_recoleccion : "Not Set",
                                                        hterminorecoleccion: element.hora_termino_recoleccion ? element.hora_termino_recoleccion : "Not Set",
                                                        hsalidabase: element.hora_salida_base ? element.hora_salida_base : "Not Set",                                                        
                                                        hcliente: element.hora_up ? element.hora_up : "Not Set",
                                                        hcarga: element.hora_carga ? element.hora_carga : "Not Set",
                                                        hdocumentos: element.hora_documentos ? element.hora_documentos : "Not Set",
                                                        hsalida: element.hora_start ? element.hora_start : "Not Set",
                                                        hfila: element.hora_fila ? element.hora_fila : "Not Set",
                                                        hinspeccion: element.hora_inspeccion ? element.hora_inspeccion : "Not Set",
                                                        hmx: element.hora_modulomx ? element.hora_modulomx : "Not Set",
                                                        husa: element.hora_modulousa ? element.hora_modulousa : "Not Set",
                                                        hfin: element.hora_end ? element.hora_end : "Not Set",
                                                        hregresovacio: element.hora_regreso_vacio ? element.hora_regreso_vacio : "Not Set",
                                                    }
                                                })
                                            } className="btn btn-primary btn-sm" />
                                        }
                                    </div>
                                </div>

                            </div>

                            <HotTable style={{ fontSize: '12px' }} settings={{
                                colHeaders: true,
                                rowHeaders: true,
                                rowHeights: 36,
                                autoRowSize: true,
                                columnSorting: true,

                                dropdownMenu: ['filter_by_condition',
                                    'filter_by_value',
                                    'filter_action_bar'],
                                filters: true,
                                manualColumnResize: true,
                                colWidths: [140, 240, 100, 60, 100, 100, 150, 130, 130, 85, 100, 160, 210, 210, 210, 210, 210, 210, 210, 210, 210, 210, 210, 210, 210],
                                //para ancho total
                                //  minSpareRows: 1,          // almenos una fila
                                // autoColumnSize: true,     //
                                //  stretchH: 'all',          //
                                //disableVisualSelection: true,
                                beforeOnCellMouseDown: function (e) {
                                    var value = e.target.innerText;
                                    if (e.target && e.target.innerText && (e.target.innerText.startsWith('Despac') || e.target.innerText.startsWith('Pre-'))) {
                                        e.stopImmediatePropagation()
                                    }
                                },
                                licenseKey: "non-commercial-and-evaluation",
                                language: "es-MX"
                            }}

                                data={cargas} width="100%" height="400" >
                                <HotColumn className="htCenter htMiddle" readOnly title="No. Viaje" data="name" >
                                    <LinkViajeRender handleGoToViaje={handleGoToViaje} hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Despacho/Pre-plan" data="obj"  >
                                    <DropRender handlePrePlanear={handlePrePlanear} hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Estatus" data="status" >
                                    <EstatusRender hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="PP" data="pp" />
                                <HotColumn className="htCenter htMiddle" readOnly title="Unidad" data="detalles.unidad" />
                                <HotColumn className="htCenter htMiddle" readOnly title="Remolque" data="carta_porte.remolque" />
                                <HotColumn className="htCenter htMiddle" readOnly title="Cliente paga" data="general.cliente" />
                                <HotColumn className="htCenter htMiddle" readOnly title="Origen" data="origen" />
                                <HotColumn className="htCenter htMiddle" readOnly title="Destino" data="destino" />
                                <HotColumn className="htCenter htMiddle" readOnly title="Kilómetros" data="carta_porte.distTotales_km" />
                                <HotColumn className="htCenter htMiddle" readOnly title="Planeado" data="createAt"  >
                                    <DateRender hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Despachado" data="fecha_despacho"  >
                                    <DateRender hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Recolección" data="obj"  >
                                    <TimeRender handleChangeHour={handleChangeRecoleccion} type="recoleccion" hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Termino Recolección" data="obj"  >
                                    <TimeRender handleChangeHour={handleChangeTerminoRecoleccion} type="terminorecoleccion" hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Salida de Base" data="obj"  >
                                    <TimeRender handleChangeHour={handleChangeSalidaBase} type="salidabase" hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Con cliente" data="obj"  >
                                    <TimeRender handleChangeHour={handleChangeHourUp} type="up" hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Carga" data="obj"  >
                                    <TimeRender handleChangeHour={handleChangeHourCarga} type="carga" hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Entrega documentos" data="obj"  >
                                    <TimeRender handleChangeHour={handleChangeHourDocumentos} type="documentos" hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Salida cliente" data="obj"  >
                                    <TimeRender handleChangeHour={handleChangeHourStart} type="start" hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Llegada Fila" data="obj"  >
                                    <TimeRender handleChangeHour={handleChangeHourFila} type="fila" hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Inspección" data="obj"  >
                                    <TimeRender handleChangeHour={handleChangeHourInspeccion} type="inspeccion" hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Módulo MX" data="obj"  >
                                    <TimeRender handleChangeHour={handleChangeHourModuloMx} type="modulomx" hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Módulo USA" data="obj"  >
                                    <TimeRender handleChangeHour={handleChangeHourModuloUSA} type="modulousa" hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Entrega" data="obj"  >
                                    <TimeRender handleChangeHour={handleChangeHourEnd} type="end" hot-renderer />
                                </HotColumn>
                                <HotColumn className="htCenter htMiddle" readOnly title="Regreso Vacio" data="obj"  >
                                    <TimeRender handleChangeHour={handleChangeRegresoVasio} type="regresovacio" hot-renderer />
                                </HotColumn>
                            </HotTable>
                        </div>
                    </LoadingOverlay>
                    <hr />
                    <div className="col-12">
                        <h3 style={{ fontWeight: "600" }}>Unidades</h3>
                        <HotTable style={{ fontSize: '12px' }} settings={{
                            colHeaders: true,
                            rowHeaders: true,
                            rowHeights: 36,
                            autoRowSize: true,
                            columnSorting: true,

                            dropdownMenu: ['filter_by_condition',
                                'filter_by_value',
                                'filter_action_bar'],
                            filters: true,
                            manualColumnResize: true,
                            colWidths: [140, 240, 100, 60, 100, 100, 100, 100, 100, 100, 300, 100],
                            //para ancho total
                            //  minSpareRows: 1,          // almenos una fila
                            // autoColumnSize: true,     //
                            //  stretchH: 'all',          //
                            //disableVisualSelection: true,
                            beforeOnCellMouseDown: function (e) {
                                var value = e.target.innerText;
                                if (e.target && e.target.innerText && (e.target.innerText.startsWith('Despac') || e.target.innerText.startsWith('Pre-'))) {
                                    e.stopImmediatePropagation()
                                }
                            },
                            licenseKey: "non-commercial-and-evaluation",
                            language: "es-MX"
                        }}

                            data={unidades} width="100%" height="400" >
                            <HotColumn className="htCenter htMiddle" readOnly title="Unidad" data="name" />
                            <HotColumn className="htCenter htMiddle" readOnly title="Despacho/Pre-plan" data="obj"  >
                                <ActionRender handleDespachar={handleDespachar} hot-renderer />
                            </HotColumn>
                            <HotColumn className="htCenter htMiddle" readOnly title="Estatus" data="status" >
                                <EstatusRenderUnidad hot-renderer />

                            </HotColumn>
                            <HotColumn className="htCenter htMiddle" readOnly title="PP" data="pp" />
                            <HotColumn className="htCenter htMiddle" readOnly title="PrePlan" data="viaje_preplaneado" />
                            <HotColumn className="htCenter htMiddle" readOnly title="Tipo" data="viajetipo" />
                            <HotColumn className="htCenter htMiddle" readOnly title="Remolque 1" data="rem1" />
                            <HotColumn className="htCenter htMiddle" readOnly title="Remolque 2" data="rem2" />
                            <HotColumn className="htCenter htMiddle" readOnly title="Auxiliar" data="aux" />
                            <HotColumn className="htCenter htMiddle" readOnly title="Clase" data="clase" />
                            <HotColumn className="htCenter htMiddle" readOnly title="Operador" data="operadorname" />
                            <HotColumn className="htCenter htMiddle" readOnly title="Último Vacío" data="preplan" />


                        </HotTable>
                    </div>
                </DndProvider>
            </div>
        </div>

    );
}
export default PlaneacionContent;