import React, { Component, Fragment } from 'react';
import { getTiposCosto, getAllCostosRecurrentesByUserDateFilter, addCosto, cancelarCosto, deleteCostoRecurrente, getAllCostosRecurrentesByUserActives, getAllCostosByUserDateFilter, updateCosto, deleteCosto, addCostoRecurrente, updateCostoRecurrente } from '../../../controllers/costos';
import { getUserByUid, getAllUsuarios } from '../../../controllers/usuarios';
import { getAllActivos } from '../../../controllers/unidades';
import { auxiliarTareas, getAllViajesByUserDateFilter } from '../../../controllers/viajes';
import LoadingOverlay from 'react-loading-overlay';
//import TableCostos from '../tablecostos'
import { firebase } from '../../../configs/firebase';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input';
import { addDays } from "date-fns";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { Checkbox } from '@material-ui/core';
import { HotTable, HotColumn } from '@handsontable/react';
import { ActionRender, DateRender, MoneyRender, NameRender } from './ActionsRender';
import { registerLanguageDictionary } from 'handsontable/i18n';
import esMX from 'handsontable/i18n/languages/es-MX';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Download from './ExcelExport';
import moment from 'moment';
//import Select from 'react-select';
//import { Creatable } from 'react-select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getUidMaster } from '../../../session/session_controller';
import { faL } from '@fortawesome/free-solid-svg-icons';
//import ModalCreateEdit from './ModalCreateEdit/index';
registerLanguageDictionary(esMX);
registerLocale('es', es);

const requiereProveedor = ['KeMdhwEofxWSIrXh2mylJWra9Jr1', 'isDqcvfDhseYK7ixfiBg1OTOweC2'];
//LV0bu6i3nsc7QSa55gMrm9rUzMr1
const requiereTAvante = ['LV0bu6i3nsc7QSa55gMrm9rUzMr1'];

export default class index extends Component {
    constructor(props) {
        super();
        this.state = {
            // subtiposelected: '',
            sumfijos: '',
            sumvariables: '',
            sumsueldos: '',
            querysearch: '',
            costos: [],
            costosfilter: [],
            isModalOpen: false,
            isModalRecurrentOpen: false,
            isEdit: false,
            idEdit: null,
            user: null,
            currentDate: new Date(),
            firstDay: new Date(),
            lastDay: new Date(),
            isAdmin: false,
            adminCurrentUser: null,
            showCheckRecurrent: true,
            checkrecurrent: false,
            //-------------------
            isLoading: false,
            //datatable dinamico
            // totalRecords: 0,
            //  currentpage: 1,
            //  recordsbypage: 10,
            //  dattablefilter: '',
            catalogotipos: [],
            catalogosubtipos: [],
            unidades: [],
            userlist: [],
            //datos de formulario
            descripcion: '',
            fecha: new Date(),
            recurrentperiodos: 0,
            recurrentend: new Date(),
            nombre: '',
            tipo: '',
            montofloat: 0.0,
            monto: '',
            unidad: '',
            segmentoNegocio: '',
            proveedor: '',
            factura: '',
            ordenVenta: '',
            pagado: false,

        }
        this.textInput = React.createRef();
    }
    handleChangeSelectt = (selectedOption) => {
        //console.log("changed",selectedOption);
        //this.setState({ selectedOption });
        // selectedOption can be null when the `x` (close) button is clicked
        if (selectedOption) {
            this.setState({ nombre: selectedOption.value });
        }
    }
    handleChangeSelecttBB = (a, b) => {
        console.log("a", a);
        console.log("b", b);
    }
    onNewOptionClick = (event) => {
        if (event.value) {
            let aux = [];
            this.state.catalogosubtipos.forEach(element => {
                aux.push(element);
            });
            aux.push(event.value);
            this.setState({ nombre: event.value, catalogosubtipos: aux }, () => {
                this.textInput.current.focus();
            });
        }
    }
    getUserList = () => {
        if (this.state.isAdmin) {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    if (!o.segmento_negocio) {
                        o.segmento_negocio = [];
                    }
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                this.setState({ userlist: a });
            }).catch(e => {
                console.log(e);
                //this.setState({isLoading:false,})
            });
        }

    }
    validateAdmin = (uid) => {
        // console.log("validando user",uid);
        getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (!user.segmento_negocio) {
                    user.segmento_negocio = [];
                }
                if (user.type && user.type === "administrador") {
                    isadmin = true;
                }
            });
            if (isadmin) {

                this.setState({ isAdmin: isadmin, adminCurrentUser: uid }, () => {
                    //console.log("admincurrentuser", this.state.adminCurrentUser);
                    this.getUserList();
                });

            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }

    componentDidMount = () => {
        // console.log("comp did mount");
        let firstDay = new Date(this.state.currentDate.getFullYear(), this.state.currentDate.getMonth(), 1);
        let lastDay = new Date(this.state.currentDate.getFullYear(), this.state.currentDate.getMonth() + 1, 0);
        this.setState({ firstDay: firstDay, lastDay: lastDay });

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ user: user, adminCurrentUser: user.uid }, () => {
                    if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                        this.validateAdmin(user.uid);
                    }
                    this.getData(user);
                });

            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });
        // this.getData();
    }
    calcTotals = () => {
        let fijos = 0;
        let variables = 0;
        let sueldos = 0;
        this.state.costos && this.state.costos.forEach(element => {
            if (element.tipo === 'Costo Fijo') {
                fijos += element.montofloat;
            } else if (element.tipo === 'Sueldo de Operador') {
                sueldos += element.montofloat;
            } else {
                variables += element.montofloat;
            }
        });

        let numfijos = '$' + fijos.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        let numvariables = '$' + variables.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        let numsueldos = '$' + sueldos.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

        this.setState({ sumfijos: numfijos, sumsueldos: numsueldos, sumvariables: numvariables }, () => {
            //console.log("fijos", this.state.sumfijos);
            //console.log("variables", this.state.sumvariables);
            //console.log("sueldos", this.state.sumsueldos);
        });
    }

    getData = async (usertodisplay) => {
        let user = usertodisplay;
        if (!user.segmento_negocio) {
            user.segmento_negocio = [];
        }
        if (this.state.adminCurrentUser) {
            user = { ...user, uid: this.state.adminCurrentUser }
        }
        if (getUidMaster()) {
            user.uid = getUidMaster()
        }
        await getUserByUid(user.uid).then(snapshot => {
            let u;
            snapshot.forEach(doc => {
                u = doc.data();
                u.uid = user.uid;
                u.id = doc.id;
                if (!u.segmento_negocio) {
                    u.segmento_negocio = [];
                }

            });
            if (u) {
                user = u;
                this.setState({ user });
            }
        }).catch(err => { console.log(err) });

        await getAllActivos(user.uid).then(snapshot => {
            let aux = [];
            snapshot.forEach(doc => {
                let u = doc.data();
                u.uid = user.uid;
                u.id = doc.id;
                aux.push(u);

            });
            user.unidadesobjs = [...aux];
            this.setState({ user });
        }).catch(err => console.log(err));
        this.setState({ isLoading: true }, async () => {

            let index = 1;
            let a = [];
            let fecha = this.state.currentDate;
            console.log("fecha", fecha);
            let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
            let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
            console.log("fechas", `${fDay}    ${lDay}`);
            let requiereAjusteTiempo = true;
            if (requiereAjusteTiempo) {
                function addHoursToDate(objDate, intHours) {
                    var numberOfMlSeconds = objDate.getTime();
                    var addMlSeconds = (intHours * 60) * 60000;
                    var newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
                    return newDateObj;
                }
                function subtractTimeFromDate(objDate, intHours) {
                    var numberOfMlSeconds = objDate.getTime();
                    var addMlSeconds = (intHours * 60) * 60000;
                    var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
                    return newDateObj;
                }
                fDay = addHoursToDate(fDay, 25);
                lDay = subtractTimeFromDate(lDay, 25);
                //console.log("fechas",`${fDay}    ${lDay}`);

            }
            console.log("busanco recurrentes", `${user.uid}  ${fDay} ${lDay}`)
            await getAllCostosRecurrentesByUserDateFilter(user.uid, fDay, lDay).then(snapshot => {
                console.log("costos Recurrentes encontrados", snapshot.docs.length);
                // let nowdate=new Date();
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.nombre = o.nombre + ' (R)';
                    if (o.tipo == "Costo Fijo") {
                        o.segmentoNegocio = "";
                    }
                    o.n = index;
                    o.id = doc.id;
                    o.key = doc.id;
                    o.estado = o.pagado ? "Pagado" : "No Pagado";
                    o.isrecurrent = true;
                    if (o.fecha_inicio && o.fecha_inicio.toDate().getTime() <= fDay.getTime()) {
                        index++;
                        a.push(o);
                    }
                });

            }).catch();


            await getAllCostosByUserDateFilter(user.uid, this.state.firstDay, this.state.lastDay).then(async snapshot => {


                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    o.id = doc.id;
                    o.key = doc.id;
                    o.estado = o.pagado ? "Pagado" : "No Pagado";
                    o.isrecurrent = false;
                    let auxunidad = false;
                    if (o.tipo == "Costo Variable" || o.tipo == 'Mantenimiento' || o.tipo == 'Combustible' || o.tipo == 'Combustible ') {

                        if (o.unidad && o.unidad !== "") {
                            auxunidad = this.state.user.unidadesobjs.find(act => act.id === o.unidad);
                            if (!auxunidad) {
                                console.log("no se encontro unidad", o.unidad);
                                console.log("unidades", this.state.user.unidadesobjs);
                            }
                        }
                    }

                    if (o.tipo == "Costo Fijo") {
                        o.segmentoNegocio = "";
                    }
                    o.unidadnombre = auxunidad ? auxunidad.nombre : '-';
                    index++;
                    a.push(o);
                });


                //getAllViajesByUserDateFilter
                await getAllViajesByUserDateFilter(user.uid, this.state.firstDay, this.state.lastDay).then(snapshot => {
                    console.log("todos los viajes", snapshot.docs.length);
                    snapshot.forEach(doc => {
                        let oo = doc.data();
                        oo.id = doc.id;
                        if (oo.status !== "Cancelado" && oo.lineas_captura && oo.lineas_captura.length > 0) {
                            oo.lineas_captura.forEach(lc => {
                                if (lc.tipo == "COSTO VARIABLE") {

                                    console.log("costo variable", lc);
                                    let o = doc.data();
                                    let auxunidad = false;
                                    if (o.unidad && o.unidad !== "") {
                                        auxunidad = this.state.user.unidadesobjs.find(act => act.id === o.unidad);
                                    }

                                    o.index = doc.id;
                                    o.n = index;
                                    o.id = doc.id;
                                    o.monto = lc.monto;
                                    o.descripcion = `Capturado en viaje ${o.general.ncarta || ""}`;
                                    o.montofloat = lc.montoFloat;
                                    o.segmentoNegocio = oo.segmentoNegocio;
                                    o.tipo = "Costo Variable";
                                    o.unidadnombre = oo.detalles.unidad || "";
                                    o.nombre = `${lc.descripcion}`;
                                    o.estado = oo.pagado ? "Pagado" : "No Pagado";
                                    o.key = doc.id;
                                    o.fecha = oo.datos_viaje.fechaOrigen;
                                    o.isrecurrent = false;
                                    o.unidadnombre = auxunidad ? auxunidad.nombre : "";
                                    index++;
                                    a.push(o);
                                    console.log("pushed", o);
                                }

                                if (lc.tipo == "COSTO_FIJO") {
                                    console.log("costo variable", lc);
                                    let o = doc.data();
                                    o.index = doc.id;
                                    o.n = index;
                                    o.id = doc.id;
                                    o.monto = lc.monto;
                                    o.descripcion = `Capturado en viaje ${o.general.ncarta || ""}`;
                                    o.montofloat = lc.montoFloat;
                                    o.segmentoNegocio = oo.segmentoNegocio;
                                    o.unidadnombre = oo.detalles.unidad || "";
                                    o.tipo = "Costo Fijo";
                                    o.estado = oo.pagado ? "Pagado" : "No Pagado";
                                    o.esdeviaje = true;
                                    o.nombre = `${lc.descripcion}`;
                                    o.key = doc.id;
                                    o.fecha = oo.datos_viaje.fechaOrigen;
                                    o.isrecurrent = false;
                                    index++;
                                    a.push(o);
                                } else if (lc.tipo == "SUELDOS DE OPERADOR") {
                                    let o = doc.data();
                                    o.index = doc.id;
                                    o.n = index;
                                    o.id = doc.id;
                                    o.monto = lc.monto;
                                    o.descripcion = `Capturado en viaje ${o.general.ncarta || ""}`;
                                    o.montofloat = lc.montoFloat;
                                    o.segmentoNegocio = oo.segmentoNegocio;
                                    o.unidadnombre = oo.detalles.unidad || "";
                                    o.tipo = "Sueldo de Operador";
                                    o.esdeviaje = true;
                                    o.nombre = `${lc.descripcion}`;
                                    o.estado = oo.pagado ? "Pagado" : "No Pagado";
                                    o.key = doc.id;
                                    o.fecha = oo.datos_viaje.fechaOrigen;
                                    o.isrecurrent = false;
                                    index++;
                                    a.push(o);
                                }
                            })
                        }
                    })

                });

                a = a.map(am => {
                    let auxm = { ...am }
                    if (auxm.createAt) {
                        auxm.fechaRegistro = moment(auxm.createAt.toDate()).format("DD/MM/YYYY hh:mm")
                    }
                    return auxm;
                })

                this.setState({ costos: a, costosfilter: a, isLoading: false, }, () => {
                    //  console.log("data",this.state.costos);
                    this.calcTotals();
                });
            }).catch(e => {
                console.log(e);
                this.setState({ isLoading: false, })
            });
        });


    }
    handleEdit = (record, ant = null) => {
        getTiposCosto().then(snapshot => {
            let aux = [];
            snapshot.forEach(doctipo => {
                let doc = doctipo.data();
                aux.push(doc);
            });

            let sub = [];
            aux.forEach(element => {
                if (record.tipo === element.nombre) {
                    let find = false;

                    element.subtipo && element.subtipo.forEach(element2 => {
                        sub.push(element2);
                        if (element2 === record.nombre) {
                            find = true;
                        }
                    });

                    if (!find) {
                        sub.push(record.nombre);
                    }

                }
            });

            this.setState({
                catalogotipos: aux,
                catalogosubtipos: sub,
                monto: record.monto || '',
                montofloat: record.montofloat || 0.00,
                nombre: record.nombre || '',
                showCheckRecurrent: record.tipo === 'Costo Fijo' || false,
                checkrecurrent: record.isrecurrent || false,
                descripcion: record.descripcion || '',
                segmentoNegocio: record.segmentoNegocio || '',
                tipo: record.tipo || 'Costo Fijo',
                idEdit: record.id,
                proveedor: record.proveedor || '',
                factura: record.factura || '',
                ordenVenta: record.ordenVenta || '',
                pagado: record.pagado ? true : false,
                estado: record.estado || "",
                unidad: record.unidad,
                isModalOpen: true,
                isEdit: true,
                pagado: record.pagado ? true : false,
            });
        });


    }
    handleEditRecurrent = (record) => {
        this.setState({
            catalogotipos: [],
            monto: record.monto || '',
            recurrentperiodos: 1,
            montofloat: record.montofloat || 0.00,
            nombre: record.nombre || '',
            descripcion: record.descripcion || '',
            tipo: 'Costo Fijo',
            idEdit: record.id,
            segmentoNegocio: record.segmentoNegocio || '',
            isModalRecurrentOpen: true,
            isEdit: true,
        });


    }

    openModalRecurrente = () => {
        this.setState({
            monto: '',
            montofloat: 0.0,
            nombre: '',
            descripcion: '',
            tipo: 'Costo Fijo',
            fecha: new Date(),
            recurrentperiodos: 1,
            segmentoNegocio: '',
            unidad: '',
            isModalRecurrentOpen: true,
            isEdit: false,
        });
    }

    openModal = () => {

        getTiposCosto().then(snapshot => {
            let aux = [];
            snapshot.forEach(doctipo => {
                let doc = doctipo.data();
                aux.push(doc);
            });

            //todo agregar devolucion
            if (this.state.user && requiereTAvante.find(rp => rp === this.state.user.uid)) {
                aux.push({
                    nombre: 'Devolución',
                    orden: 7
                })
            }

            let auxsubitpos = [];
            if (this.state.catalogotipos[0]) {
                if (this.state.catalogotipos[0].subtipo) {
                    auxsubitpos = this.state.catalogotipos[0].subtipo;
                }
            }




            this.setState({
                catalogotipos: aux,
                monto: '',
                montofloat: 0.0,
                nombre: '',
                descripcion: '',
                recurrentperiodos: 1,
                tipo: 'Costo Fijo',
                catalogosubtipos: auxsubitpos,
                unidad: '',
                showCheckRecurrent: true,
                checkrecurrent: false,
                segmentoNegocio: '',
                isModalOpen: true,
                isEdit: false,
                fecha: new Date(),

            }, () => {
                console.log("is open here", this.state.isModalOpen)
            });
        }).catch(e => {

        });

    }

    closeModal = () => {
        this.setState({ isModalOpen: false });
    }
    closeModalRecurrent = () => {
        this.setState({ isModalRecurrentOpen: false });
    }
    parseData = () => {
        let uid = null;
        if (this.state.user) {
            uid = this.state.user.uid;
        }
        if (this.state.isAdmin && this.state.adminCurrentUser) {
            uid = this.state.adminCurrentUser;
        } else {
            console.log("no es admin");
        }
        //let fechaobj=this.state.fechaobj;
        //fechaobj..Date.AddHours(23).AddMinutes(59).AddSeconds(59);
        let fixedSegmento = this.state.segmentoNegocio;
        if (fixedSegmento === "" && this.state.user.segmento_negocio.length >= 0) {
            fixedSegmento = this.state.user.segmento_negocio[0].value;
        }
        if (this.state.isEdit) {
            return {
                nombre: this.state.nombre,
                descripcion: this.state.descripcion,
                fecha: this.state.fecha,
                monto: this.state.monto,
                montofloat: this.state.montofloat,
                tipo: this.state.tipo,
                unidad: this.state.unidad,
                segmentoNegocio: this.state.segmentoNegocio,
                proveedor: this.state.proveedor,
                factura: this.state.factura,
                pagado: this.state.pagado,
                ordenVenta: this.state.ordenVenta,
                uid: uid,
            }
        } else {
            return {
                nombre: this.state.nombre,
                descripcion: this.state.descripcion,
                fecha: this.state.fecha,
                monto: this.state.monto,
                montofloat: this.state.montofloat,
                segmentoNegocio: fixedSegmento,
                unidad: this.state.unidad,
                tipo: this.state.tipo,
                proveedor: this.state.proveedor,
                factura: this.state.factura,
                ordenVenta: this.state.ordenVenta,
                pagado: this.state.pagado,
                uid: uid,

            }
        }
    }


    handleDelete = (record) => {
        Swal.fire({
            title: '¿Esta seguro de eliminar el costo seleccionado?',
            text: "Una vez eliminada no podrá ser recuperado.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c666f',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                deleteCosto(record.id).then(() => {
                    this.getData(this.state.user);
                    Swal.fire('Éxito', 'El costo se ha eliminado con éxito.', 'success');
                }).catch(err => {
                    Swal.fire("Error", "Error de al eliminar el costo", 'error');
                    this.setState({ isLoading: false });
                });
            }

        });
    }
    handleDeleteRecurrente = (record) => {
        Swal.fire({
            title: '¿Esta seguro de eliminar el costo recurrente seleccionado?',
            text: "Una vez eliminada no podrá ser recuperado.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c666f',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                deleteCostoRecurrente(record.id).then(() => {
                    this.getData(this.state.user);
                    Swal.fire('Éxito', 'El costo se ha eliminado con éxito.', 'success');
                }).catch(err => {
                    Swal.fire("Error", "Error de al eliminar el costo", 'error');
                    this.setState({ isLoading: false });
                });
            }

        });
    }

    handleCancelar = (record) => {
        Swal.fire({
            title: '¿Esta seguro de cancelar el costo seleccionado?',
            text: "Una vez cancelado el monto se modificará a $0.00.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c666f',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Proceder',
            cancelButtonText: 'No Cancelar'
        }).then((result) => {
            if (result.value) {
                cancelarCosto(record.id).then(() => {
                    this.getData(this.state.user);
                    Swal.fire('Éxito', 'El costo se ha cancelado con éxito.', 'success');
                }).catch(err => {
                    Swal.fire("Error", "Error de al cancelar el costo", 'error');
                    this.setState({ isLoading: false });
                });
            }

        });
    }

    doUpdateCosto = (o) => {
        console.log("datos costo",o);
        updateCosto(this.state.idEdit, o).then(() => {
            Swal.fire('Éxito', 'El costo se ha actualizado con éxito.', 'success');
            this.getData(this.state.user);
        }).catch(err => {
            this.closeModal();
            Swal.fire("Error", "Error de conexion al actualizar el costo", 'error');
            this.setState({ isLoading: false });
        });
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        this.closeModal();
        let o = this.parseData();
        const formatoMoneda = (valor) => {
            let fixtostring = "" + valor;
            let fixtofloat = parseFloat(fixtostring);
            let toMoney = '$' + fixtofloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            return toMoney;

        }
        if (o.tipo === "Devolución") {
            o.montofloat = -1 * Math.abs(o.montofloat);
            o.monto = formatoMoneda(o.montofloat);
        }

        this.setState({ isLoading: true });
        if (this.state.isEdit) {

            if (this.state.checkrecurrent) {
                let objant = this.state.costos.find(element => element.id === this.state.idEdit);
                if (!objant.isrecurrent) {
                    //console.log("recurrente a no recurrente");
                    let objcopy = { ...o };

                    let date = this.state.fecha;
                    objcopy.fecha = new Date(date.getTime());;

                    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                    objcopy.fecha_inicio = firstDay;
                    let fechafix = new Date(o.fecha.getTime());
                    let periodosint = parseInt(this.state.recurrentperiodos);
                    // console.log("periodos",periodosint);
                    fechafix.setMonth(fechafix.getMonth() + periodosint);
                    let lastDay = new Date(fechafix.getTime());

                    objcopy.fecha_fin = lastDay;
                    objcopy.id = null;



                    await addCostoRecurrente(objcopy).then(async (resp) => {
                        await deleteCosto(o).then(resp2 => {
                            this.setState({ isLoading: false });
                        }).catch(e => {
                            this.setState({ isLoading: false });
                        });

                    }).catch(e => {
                        this.setState({ isLoading: false });
                    });
                    // this.setState({ isLoading: false });
                } else {
                    this.doUpdateCosto(o);
                }
            }

            this.doUpdateCosto(o);

        } else {
            if (this.state.showCheckRecurrent && this.state.checkrecurrent) {
                this.handleSubmitRecurrent(e);
            } else {
                addCosto(o).then(ref => {
                    // this.setState({ isLoading: false });

                    Swal.fire('Éxito', 'El costo se ha registrado con éxito.', 'success');
                    this.getData(this.state.user);
                }).catch(e => {
                    this.closeModal();
                    Swal.fire("Error", "Error de conexion al reigistrar el costo", 'error');
                    this.setState({ isLoading: false });
                });
            }

        }

    }

    handleSubmitRecurrent = (e) => {
        e.preventDefault();
        console.log("submitting with date", this.state.fecha);
        this.closeModalRecurrent();
        let o = this.parseData();
        o.tipo = "Costo Fijo";//  costo recurrente siempre son 

        let date = this.state.fecha;
        o.fecha = new Date(date.getTime());;

        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        o.fecha_inicio = firstDay;
        // date.setMonth(date.getMonth() + this.state.recurrentperiodos);

        // console.log("fecha",o.fecha);
        let fechafix = new Date(o.fecha.getTime());
        let periodosint = parseInt(this.state.recurrentperiodos);
        // console.log("periodos",periodosint);
        //fechafix.setMonth(fechafix.getMonth() + periodosint);
        //let lastDay = new Date(fechafix.getTime());
        let lastDay = new Date(date.getFullYear(), date.getMonth() + periodosint, 0);
        o.fecha_fin = lastDay;
        console.log("first day", firstDay);
        console.log("last day", lastDay);

        /*
         let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                */


        this.setState({ isLoading: true });
        if (this.state.isEdit) {
            updateCostoRecurrente(this.state.idEdit, o).then(() => {
                Swal.fire('Éxito', 'El costo recurrente se ha actualizado con éxito.', 'success');
                this.closeModalRecurrent();
                this.getData(this.state.user);
            }).catch(err => {
                this.closeModalRecurrent();
                Swal.fire("Error", "Error de conexion al actualizar el costo", 'error');
                this.setState({ isLoading: false });
            });

        } else {
            addCostoRecurrente(o).then(ref => {
                // this.setState({ isLoading: false });
                Swal.fire('Éxito', 'El costo recurrente se ha registrado con éxito.', 'success');
                this.closeModalRecurrent();
                this.getData(this.state.user);
            }).catch(e => {
                this.closeModalRecurrent();
                Swal.fire("Error", "Error de conexion al reigistrar el costo", 'error');
                this.setState({ isLoading: false });
            })
        }

    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    handleSearchChange = (e) => {
        this.setState({
            querysearch: e.target.value,
        }, () => {
            //console.log('query',this.state.querysearch);
            if (this.state.querysearch.trim() === '') {
                let aux = [];
                this.state.costosfilter.forEach(element => {
                    aux.push(element);
                });
                this.setState({ costos: aux })
            } else {
                let aux = [];
                this.state.costosfilter.forEach(element => {
                    let query = this.state.querysearch.trim();
                    query = query.toUpperCase();
                    if (element.nombre.toUpperCase().includes(query) || element.tipo.toUpperCase().includes(query) || element.monto.includes(query) || element.descripcion.toUpperCase().includes(query)) {
                        aux.push(element);
                    }


                });
                this.setState({ costos: aux })
            }


        });
    }
    handleInputChangeSelectTipo = (e) => {
        let showcheck = false;
        let showinput = true;
        if (e.target.value === 'Costo Fijo') {
            showcheck = true;
            showinput = false;
        } else {
            showcheck = false;
            showinput = false;
        }

        let auxsubitpos = [];
        this.state.catalogotipos.forEach(element => {
            if (element.nombre === e.target.value) {
                if (element.subtipo) {
                    //                  console.log("tiene subtipo",element.subtipo);
                    auxsubitpos = element.subtipo;
                } else {
                    //console.log("no tiene subtipo");
                }
            }
        });

        this.setState({
            [e.target.name]: e.target.value,
            catalogosubtipos: auxsubitpos,
            showCheckRecurrent: showcheck,
            checkrecurrent: showinput,
            recurrentperiodos: 1,
        })
    }
    handleUserChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        }, () => {
            this.getData(this.state.user);
        })
    }

    handleChangeCurrency = (event, maskedvalue, floatvalue) => {
        this.setState({ monto: maskedvalue, montofloat: floatvalue });
    }

    handleDateChanged = (date) => {
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        if (!(this.state.fecha.getTime() <= lastDay.getTime() && this.state.fecha.getTime() >= firstDay.getTime())) {
            this.setState({ fecha: firstDay, currentDate: date, firstDay: firstDay, lastDay: lastDay }, () => {
                if (this.state.user) {
                    this.getData(this.state.user);
                }
            });
        } else {
            this.setState({ fecha: new Date(), currentDate: date, firstDay: firstDay, lastDay: lastDay }, () => {
                if (this.state.user) {
                    this.getData(this.state.user);
                }
            });
        }

    }

    handleChangeChecked = event => {
        this.setState({ checkrecurrent: event.target.checked });
    };

    renderCUstomcell = (instance, td, row, col, prop, value, cellProperties) => {

        return <p>hola</p>;
    }


    handleEditFromId = (id, ant = null) => {
        //  alert(id);

        this.state.costos.forEach(element => {
            if (element.id === id) {
                if (element.esdeviaje == true) {
                    console.log("es viaje");

                } else {
                    if (element.isrecurrent) {
                        this.handleEditRecurrent(element);
                    } else {
                        this.handleEdit(element, ant);
                    }
                }



                //break;
            }
        });
    }

    handleDeleteFromId = id => {
        this.state.costos.forEach(element => {
            if (element.id === id) {

                if (element.esdeviaje == true) {

                } else {

                    if (element.isrecurrent) {
                        this.handleDeleteRecurrente(element);
                    } else {
                        this.handleDelete(element);
                    }
                }

                //break;
            }
        });


    }
    handleCancelarFromId = id => {
        this.state.costos.forEach(element => {
            if (element.id === id) {

                if (element.esdeviaje == true) {

                } else {

                    if (element.isrecurrent) {
                        //this.handleDeleteRecurrente(element);
                        Swal.fire("Error", "Costos recurrentes no pueden ser cancelados", 'error');
                    } else {
                        this.handleCancelar(element);
                    }
                }

                //break;
            }
        });


    }
    cargarCostosDeViajes = () => {
        if (!this.state.isLoading) {

            this.setState({ isLoading: true }, async () => {
                let user = this.state.user;
                if (!user.segmento_negocio) {
                    user.segmento_negocio = [];
                }
                if (this.state.adminCurrentUser) {
                    user = { ...user, uid: this.state.adminCurrentUser }
                }
                if (getUidMaster()) {
                    user.uid = getUidMaster()
                }
                await getUserByUid(user.uid).then(snapshot => {
                    let u;
                    snapshot.forEach(doc => {
                        u = doc.data();
                        u.uid = user.uid;
                        u.id = doc.id;
                        if (!u.segmento_negocio) {
                            u.segmento_negocio = [];
                        }

                    });
                    if (u) {
                        user = u;
                        this.setState({ user });
                    }
                }).catch(err => { console.log(err) });
                console.log("here");

                //(user.uid, this.state.firstDay, this.state.lastDay)
                await getAllViajesByUserDateFilter(user.uid, this.state.firstDay, this.state.lastDay).then(snapshot => {
                    snapshot.forEach(doc => {
                        let o = doc.data();
                        o.id = doc.id;
                        if (o.lineas_captura && o.lineas_captura.length > 0) {
                            o.lineas_captura.forEach(lc => {
                                if (lc.tipo == "COSTO VARIABLE") {
                                    console.log("costo variable", lc);
                                }
                            })
                        }
                    })
                    this.setState({ isLoading: false });
                });
            });
        }

    }
    render() {

        return (
            <Fragment>
                {
                    <div className="row">
                        <div className="col-12">
                            <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>&nbsp;Módulo de Costos</h3>
                            <div className="card">
                                <div className="card-header card-header-flex">

                                    <div className="d-flex px-3 row  align-items-center" >
                                        <div className="col-auto pl-0">
                                            <div style={{ display: 'flex' }}>
                                                <button class="boton primBtn add ms-right" onClick={this.openModal}><span>Agregar Costo</span></button>
                                                <button class="boton primBtn add ms-right" onClick={this.openModalRecurrente}><span>Agregar Costo Recurrente</span></button>
                                                {
                                                    //  this.state.user && requiereTAvante.find(rp => rp === this.state.user.uid) && <button class="boton primBtn add ms-right" onClick={this.openModal}><span>Agregar Devolución</span></button>
                                                }
                                                {
                                                    !this.state.isLoading && this.state.user && <Download uid={this.state.user.uid} data={this.state.costos.map(a => {
                                                        let auxfecha = '';
                                                        if (a.fecha) {
                                                            auxfecha = moment(a.fecha.toDate()).format("DD/MM/YYYY")
                                                        }
                                                        return { ...a, fechastring: auxfecha }
                                                    })} />
                                                }
                                            </div>
                                        </div>

                                        <div style={{ height: '46px', padding: '5px' }}>

                                            <DatePicker
                                                className="form-control customdatepicker"
                                                locale="es"
                                                selected={this.state.currentDate}
                                                onChange={date => this.handleDateChanged(date)}
                                                maxDate={addDays(new Date(), 1)}
                                                dateFormat="MMMM yyyy"
                                                showMonthYearPicker
                                            />


                                        </div>

                                    </div>
                                </div>
                                {/*<div className="row">
                                    <div style={{ marginLeft: '35px', marginTop: '5px' }} className="col-4 ExpenseTable2">
                                        <table className="tablenostriped">
                                            <tbody><tr>
                                                <td className="totalExpense vaT pLeft0" style={{ width: '33%' }} rowspan="2">
                                                    <font> Total Fijos </font><span><label for="lblTotalAmount" id="lblTotalAmount" >{this.state.sumfijos}</label></span>
                                                </td>
                                                <td className="pRight0" style={{ width: '33%' }}>
                                                    Total Variables <span><label for="lblFuelAmount" id="lblFuelAmount" >{this.state.sumvariables}</label></span>
                                                </td>


                                                <td className="pRight0" style={{ width: '33%' }}>
                                                    Total Sueldos <span><label for="lblOtherAmount" id="lblOtherAmount" >{this.state.sumsueldos}</label></span>
                                                </td></tr></tbody></table>
                                    </div>
                                            </div>*/}
                                <LoadingOverlay
                                    active={this.state.isLoading}
                                    spinner
                                    text='Actualizando datos, espere ...'
                                >
                                    <div className="card-body">



                                        {this.state.user && !requiereTAvante.find(rp => rp === this.state.user.uid) && <HotTable settings={{
                                            colHeaders: true,
                                            rowHeaders: true,
                                            autoRowSize: false,
                                            columnSorting: true,
                                            dropdownMenu: ['filter_by_condition',
                                                'filter_by_value',
                                                'filter_action_bar'],
                                            filters: true,
                                            manualColumnResize: true,
                                            colWidths: requiereProveedor.find(frqp => frqp === this.state.user.uid) ? [280, 144, 120, 450, 85, 100, 150, 100, 120] : [280, 144, 120, 450, 85, 100, 100, 120],
                                            //para ancho total                                    
                                            //  minSpareRows: 1,          // almenos una fila
                                            // autoColumnSize: true,     // 
                                            //  stretchH: 'all',          // 
                                            //disableVisualSelection: true,
                                            licenseKey: "non-commercial-and-evaluation",
                                            language: "es-MX"
                                        }} data={this.state.costos} colHeaders={true} rowHeaders={false} width="100%" height="400" >
                                            <HotColumn readOnly title="Nombre" data="nombre" >
                                                <NameRender hot-renderer></NameRender>
                                            </HotColumn>
                                            <HotColumn className="htCenter htMiddle" readOnly title="Tipo" data="tipo" />
                                            <HotColumn className="htCenter htMiddle mycustomcolumn" render readOnly title="Monto" data="montofloat" >
                                                <MoneyRender className="htCenter htMiddle" hot-renderer></MoneyRender>
                                            </HotColumn>
                                            <HotColumn render readOnly title="Detalles" data="descripcion" />
                                            <HotColumn className="htCenter htMiddle" render readOnly title="Fecha    " data="fecha" >
                                                <DateRender hot-renderer></DateRender>
                                            </HotColumn>
                                            <HotColumn className="htCenter htMiddle" readOnly title="Unidad" data="unidadnombre" />
                                            {requiereProveedor.find(rp => rp === this.state.user.uid) && <HotColumn className="htCenter htMiddle" readOnly title="Proveedor" data="proveedor" />}
                                            <HotColumn render readOnly title="Estado" data="estado" />
                                            <HotColumn className="htCenter htMiddle" readOnly title="Acciones" data="id"  >
                                                <ActionRender hot-renderer handleEditFromId={this.handleEditFromId} handleDeleteFromId={this.handleDeleteFromId} />
                                            </HotColumn>
                                        </HotTable>}

                                        {this.state.user && requiereTAvante.find(rp => rp === this.state.user.uid) && <HotTable settings={{
                                            colHeaders: true,
                                            rowHeaders: true,
                                            autoRowSize: false,
                                            columnSorting: true,
                                            dropdownMenu: ['filter_by_condition',
                                                'filter_by_value',
                                                'filter_action_bar'],
                                            filters: true,
                                            manualColumnResize: true,
                                            colWidths: [280, 144, 120, 450, 85, 100, 130, 130, 120],
                                            //para ancho total                                    
                                            //  minSpareRows: 1,          // almenos una fila
                                            // autoColumnSize: true,     // 
                                            //  stretchH: 'all',          // 
                                            //disableVisualSelection: true,
                                            licenseKey: "non-commercial-and-evaluation",
                                            language: "es-MX"
                                        }} data={this.state.costos} colHeaders={true} rowHeaders={false} width="100%" height="400" >
                                            <HotColumn readOnly title="Nombre" data="nombre" >
                                                <NameRender hot-renderer></NameRender>
                                            </HotColumn>
                                            <HotColumn className="htCenter htMiddle" readOnly title="Tipo" data="tipo" />
                                            <HotColumn className="htCenter htMiddle mycustomcolumn" render readOnly title="Monto" data="montofloat" >
                                                <MoneyRender className="htCenter htMiddle" hot-renderer></MoneyRender>
                                            </HotColumn>
                                            <HotColumn render readOnly title="Detalles" data="descripcion" />
                                            <HotColumn className="htCenter htMiddle" render readOnly title="Fecha    " data="fecha" >
                                                <DateRender hot-renderer></DateRender>
                                            </HotColumn>
                                            <HotColumn className="htCenter htMiddle" readOnly title="Unidad" data="unidadnombre" />
                                            <HotColumn className="htCenter htMiddle" readOnly title="Orden Venta" data="ordenVenta" />
                                            <HotColumn className="htCenter htMiddle" readOnly title="Fecha Registro" data="fechaRegistro" />

                                            <HotColumn className="htCenter htMiddle" readOnly title="Acciones" data="id"  >
                                                <ActionRender hot-renderer handleEditFromId={this.handleEditFromId} handleDeleteFromId={this.handleDeleteFromId} handleCancelar={this.handleCancelarFromId} />
                                            </HotColumn>
                                        </HotTable>}




                                        {/*  <TableCostos records={this.state.costos} editHandler={this.handleEdit} handleDeleteRecurrente={this.handleDeleteRecurrente} handleEditRecurrent={this.handleEditRecurrent} deleteHandler={this.handleDelete} date={this.state.fecha}></TableCostos>
                */}</div>
                                </LoadingOverlay>
                            </div>
                        </div>

                        <Modal isOpen={this.state.isModalOpen} toggle={this.closeModal} size="lg">
                            <ModalHeader toggle={this.closeModal}>
                                {!this.state.isEdit ? "Añadir un nuevo costo" : `Editar costo `}
                            </ModalHeader>
                            <form onSubmit={this.handleSubmit}>
                                <ModalBody>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div class="dataTable twoCol">

                                            <div class="dataTitle"><p className="fixedP">Costo</p></div>
                                            <div class="dataBox">
                                                <div class="dataRow">
                                                    <p className="fixedP">Tipo de Costo<label className="requiredlabel">*</label></p>
                                                    <div class="fieldInput">
                                                        <select name='tipo' onChange={this.handleInputChangeSelectTipo} value={this.state.tipo} required >
                                                            {
                                                                this.state.catalogotipos.map((item, index) => {
                                                                    return <option key={index} value={item.nombre}>{item.nombre}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">Nombre<label className="requiredlabel">*</label></p>
                                                    <div class="fieldInput">
                                                        <Autocomplete
                                                            id="free-solo-demo"
                                                            freeSolo
                                                            className={"selectbusqueda"}
                                                            options={this.state.catalogosubtipos.map((option) => '' + option)}
                                                            value={this.state.nombre}
                                                            onChange={(e, val) => {
                                                                if (val && val != "") {
                                                                    this.setState({
                                                                        nombre: val,
                                                                    })
                                                                }

                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} name="nombre" onChange={this.handleInputChange} value={this.state.nombre} className="form-control" variant="outlined" required />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">Monto<label className="requiredlabel">*</label></p>
                                                    <div class="fieldInput">
                                                        <CurrencyInput style={{ width: '90%' }} value={this.state.monto} onChangeEvent={this.handleChangeCurrency} prefix="$" />
                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">Segmento de Negocio<label className="requiredlabel">*</label></p>
                                                    <div class="fieldInput">
                                                        {this.state.tipo === "Costo Fijo" ? <input disabled type="text" minlength="2" name='cliente' value={"N/A"} onChange={e => { }} /> : <select name='segmentoNegocio' onChange={this.handleInputChange} value={this.state.segmentoNegocio} required >
                                                            {
                                                                this.state.user && this.state.user.segmento_negocio && this.state.user.segmento_negocio.map((item, index) => {

                                                                    return this.state.segmentoNegocio === item.value ?
                                                                        <option key={index} value={item.value} selected>{item.value}</option> :
                                                                        <option key={index} value={item.value}>{item.value}</option>

                                                                })
                                                            }
                                                        </select>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dataTable twoCol">

                                            <div class="dataTitle"><p className="fixedP">Detalles</p></div>
                                            <div class="dataBox">
                                                <div class="dataRow">
                                                    <p className="fixedP">Fecha</p>
                                                    <div class="fieldInput">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            minDate={this.state.firstDay} maxDate={this.state.lastDay} style={{ width: '90%' }} selected={this.state.fecha} onChange={date => this.setState({ fecha: date })} />

                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">Es recurrente?</p>
                                                    <div class="fieldInput">
                                                        <select name='segmento' onChange={(e) => {
                                                            this.setState({ checkrecurrent: e.target.value == "1" })
                                                        }} value={this.state.checkrecurrent ? "1" : "2"} required >
                                                            <option key={'sirecurrente'} value={"1"} >Si</option>
                                                            <option key={'norecurrente'} value={"2"}>No</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {this.state.checkrecurrent && <div class="dataRow">
                                                    <p className="fixedP">Meses</p>
                                                    <div class="fieldInput">
                                                        <input type="number" min={1} name='recurrentperiodos' style={{ width: '90%' }} value={this.state.recurrentperiodos} onChange={this.handleInputChange} placeholder="Cantidad de periodos" required />
                                                    </div>
                                                </div>}
                                                <div class="dataRow">
                                                    <p className="fixedP">Unidad</p>
                                                    <div class="fieldInput">
                                                        {this.state.tipo === "Costo Fijo" ? <input disabled type="text" minlength="2" name='cliente' value={"N/A"} onChange={e => { }} /> : <select name='unidad' onChange={this.handleInputChange} value={this.state.unidad} required >
                                                            <option key={10000} value={''}>{' '}</option>
                                                            {
                                                                this.state.user && this.state.user.unidadesobjs && this.state.user.unidadesobjs.map((item, index) => {

                                                                    return this.state.unidad === item.value ?
                                                                        <option key={index} value={item.id} selected>{item.nombre}</option> :
                                                                        <option key={index} value={item.id}>{item.nombre}</option>

                                                                })
                                                            }</select>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">Detalles</p>
                                                    <div class="fieldInput">
                                                        <textarea ref={this.textInput} style={{ minHeight: "50px" }} type="text" name='descripcion' value={this.state.descripcion} onChange={this.handleInputChange} placeholder="Detalles del costo" />
                                                    </div>
                                                </div>
                                                {
                                                    this.state.user && this.state.user.uid && requiereProveedor.find(r => r === this.state.user.uid) &&
                                                    <div class="dataRow">
                                                        <p className="fixedP">Proveedor</p>
                                                        <div class="fieldInput">
                                                            <input type="text" ref={this.textInput} name='proveedor' value={this.state.proveedor} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.user && this.state.user.uid && requiereProveedor.find(r => r === this.state.user.uid) &&
                                                    <div class="dataRow">
                                                        <p className="fixedP">No.Factura</p>
                                                        <div class="fieldInput">
                                                            <input type="text" ref={this.textInput} name='factura' value={this.state.factura} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                }
                                                <div class="dataRow">
                                                    <p className="fixedP">Estado</p>
                                                    <div class="fieldInput">
                                                        <select name='pagado' onChange={(e) => {
                                                            this.setState({ pagado: e.target.value == "1" })
                                                        }} value={this.state.pagado ? "1" : "2"} required >
                                                            <option key={'sipagado'} value={"1"} >Pagado</option>
                                                            <option key={'nopagado'} value={"2"}>No Pagado</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.user && this.state.user.uid && requiereTAvante.find(r => r === this.state.user.uid) &&
                                                    <div class="dataRow">
                                                        <p className="fixedP">Orden de venta</p>
                                                        <div class="fieldInput">
                                                            <input type="text" ref={this.textInput} name='ordenVenta' value={this.state.ordenVenta} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    {/*                   <table className="tablenostriped" style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td className="modalform_label">  <label> <span className="red">*</span>Tipo de costo</label> </td>
                                                <td className="modalform_field"> <select className="form-control" name='tipo' onChange={this.handleInputChangeSelectTipo} value={this.state.tipo} required >
                                                    {
                                                        this.state.catalogotipos.map((item, index) => {
                                                            return <option key={index} value={item.nombre}>{item.nombre}</option>
                                                        })
                                                    }
                                                </select>
                                                </td>
                                                <td className="modalform_label"><label>Es recurrente?</label></td>
                                                <td className="modalform_field"><Checkbox style={{ marginBottom: 0, paddingBottom: 0 }} checked={this.state.checkrecurrent}
                                                    onChange={this.handleChangeChecked}></Checkbox></td>

                                                <td className="modalform_label">Meses</td>
                                                <td className="modalform_field"> <input type="number" min={1} name='recurrentperiodos' className="form-control" value={this.state.recurrentperiodos} onChange={this.handleInputChange} placeholder="Cantidad de periodos" required />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="modalform_label"> <label>Fecha</label></td>
                                                <td className="modalform_field"><DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    minDate={this.state.firstDay} maxDate={this.state.lastDay} className="form-control" selected={this.state.fecha} onChange={date => this.setState({ fecha: date })} />
                                                </td>
                                                <td className="modalform_label">  <label>Segmento de negocio</label></td>
                                                <td className="modalform_field"> <select className={"form-control "} name='segmentoNegocio' onChange={this.handleInputChange} value={this.state.segmentoNegocio} required >
                                                    {
                                                        this.state.user && this.state.user.segmento_negocio && this.state.user.segmento_negocio.map((item, index) => {

                                                            return this.state.segmentoNegocio === item.value ?
                                                                <option key={index} value={item.value} selected>{item.value}</option> :
                                                                <option key={index} value={item.value}>{item.value}</option>

                                                        })
                                                    }
                                                </select></td>

                                                <td className="modalform_label"> <label>Unidad</label></td>
                                                <td className="modalform_field"> <select className={"form-control "} name='unidad' onChange={this.handleInputChange} value={this.state.unidad} required >
                                                    {
                                                        this.state.user && this.state.user.unidadesobjs && this.state.user.unidadesobjs.map((item, index) => {

                                                            return this.state.unidad === item.value ?
                                                                <option key={index} value={item.id} selected>{item.nombre}</option> :
                                                                <option key={index} value={item.id}>{item.nombre}</option>

                                                        })
                                                    }</select>

                                                </td>
                                            </tr>
                                            <tr>

                                                <td className="modalform_label"><label>Monto</label></td>
                                                <td className="modalform_field"> <CurrencyInput className="form-control" value={this.state.monto} onChangeEvent={this.handleChangeCurrency} prefix="$" /></td>
                                                <td className="modalform_label"><label>Nombre</label> </td>
                                                <td className="modalform_field"> <Autocomplete
                                                    id="free-solo-demo"
                                                    freeSolo
                                                    options={this.state.catalogosubtipos.map((option) => '' + option)}
                                                    value={this.state.nombre}
                                                    renderInput={(params) => (
                                                        <TextField {...params} name="nombre" onChange={this.handleInputChange} value={this.state.nombre} className="form-control" variant="outlined" required />
                                                    )}
                                                /></td>
                                                <td className="modalform_label">  <label >Detalles</label> </td>
                                                <td className="modalform_field"> <textarea ref={this.textInput} style={{ minHeight: "50px" }} type="text" name='descripcion' className="form-control" value={this.state.descripcion} onChange={this.handleInputChange} placeholder="Detalles del costo" />
                                                </td>



                                            </tr>
                                        </tbody>
                                    </table>
                */}
                                    <div className="form-group col-12">

                                    </div>
                                    {/*    <div className="row">
                                        <div className="form-group col-3">
                                            <label>Tipo de costo</label>
                                            <select className="form-control" name='tipo' onChange={this.handleInputChangeSelectTipo} value={this.state.tipo} required >
                                                {
                                                    this.state.catalogotipos.map((item, index) => {
                                                        return <option key={index} value={item.nombre}>{item.nombre}</option>
                                                    })
                                                }
                                            </select>
                                        </div>

                                        {
                                            this.state.showCheckRecurrent ?
                                                <div className="col-3">
                                                    <label>Es recurrente?</label>
                                                    <Checkbox style={{ marginBottom: 0, paddingBottom: 0 }} checked={this.state.checkrecurrent}
                                                        onChange={this.handleChangeChecked}></Checkbox>

                                                    {
                                                        this.state.checkrecurrent ? <div className="form-group col-12">

                                                            <input type="number" min={1} name='recurrentperiodos' className="form-control" value={this.state.recurrentperiodos} onChange={this.handleInputChange} placeholder="Cantidad de periodos" required />

                                                        </div> : <div></div>
                                                    }
                                                </div> : <div></div>
                                        }


                                        <div className="form-group col-3">
                                            <label>Fecha</label>
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.firstDay} maxDate={this.state.lastDay} className="form-control" selected={this.state.fecha} onChange={date => this.setState({ fecha: date })} />
                                        </div>
                                        <div className="form-group col-3">
                                            <label>Segmento de negocio</label>
                                            <select className={"form-control "} name='segmentoNegocio' onChange={this.handleInputChange} value={this.state.segmentoNegocio} required >
                                                {
                                                    this.state.user && this.state.user.segmento_negocio && this.state.user.segmento_negocio.map((item, index) => {

                                                        return this.state.segmentoNegocio === item.value ?
                                                            <option key={index} value={item.value} selected>{item.value}</option> :
                                                            <option key={index} value={item.value}>{item.value}</option>

                                                    })
                                                }
                                            </select>

                                        </div>
                                        <div className="form-group col-3">
                                            <label>Unidad</label>
                                            <input type="text" name='nombre' className="form-control" disabled />
                                        </div>

                                        <div className="form-group col-3">
                                            <label>Monto</label>
                                            <CurrencyInput className="form-control" value={this.state.monto} onChangeEvent={this.handleChangeCurrency} prefix="$" />
                                        </div>

                                        <div className="form-group col-12">
                                            <label>Nombre</label>
                                            <Autocomplete
                                                id="free-solo-demo"
                                                freeSolo
                                                options={this.state.catalogosubtipos.map((option) => '' + option)}
                                                value={this.state.nombre}
                                                renderInput={(params) => (
                                                    <TextField {...params} name="nombre" onChange={this.handleInputChange} value={this.state.nombre} className="form-control" variant="outlined" required />
                                                )}
                                            />
                                        </div>
                                        <div className="form-group col-12">
                                            <label>Detalles</label>
                                            <textarea ref={this.textInput} style={{ minHeight: "50px" }} type="text" name='descripcion' className="form-control" value={this.state.descripcion} onChange={this.handleInputChange} placeholder="Detalles del costo" />
                                        </div>

                                    </div>
                */}
                                </ModalBody>
                                <ModalFooter>
                                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={this.closeModal} >Salir</button>
                                    <button type="submit" className="boton secBtn"> Aceptar</button>
                                </ModalFooter>
                            </form>
                        </Modal>


                        <Modal isOpen={this.state.isModalRecurrentOpen} toggle={this.closeModalRecurrent} size="lg">
                            <ModalHeader toggle={this.closeModalRecurrent}>
                                {!this.state.isEdit ? "Añadir un nuevo costo recurrente" : `Editar costo recurrente`}
                            </ModalHeader>
                            <form onSubmit={this.handleSubmitRecurrent}>
                                <ModalBody>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div class="dataTable twoCol">

                                            <div class="dataTitle"><p className="fixedP">Costo</p></div>
                                            <div class="dataBox">
                                                <div class="dataRow">
                                                    <p className="fixedP">Nombre<label className="requiredlabel">*</label></p>
                                                    <div class="fieldInput">
                                                        <input type="text" name='nombre' value={this.state.nombre} onChange={this.handleInputChange} placeholder="Nombre del costo" required />
                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">Fecha<label className="requiredlabel">*</label></p>
                                                    <div class="fieldInput">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            minDate={this.state.firstDay} maxDate={this.state.lastDay} style={{ width: '90%' }} selected={this.state.fecha} onChange={date => this.setState({ fecha: date })} />
                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">Periodos (Meses)<label className="requiredlabel">*</label></p>
                                                    <div class="fieldInput">
                                                        <input type="number" min={0} name='recurrentperiodos' style={{ width: '90%' }} value={this.state.recurrentperiodos} onChange={this.handleInputChange} placeholder="Cantidad de periodos" required />

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="dataTable twoCol">

                                            <div class="dataTitle"><p className="fixedP">Detalles</p></div>
                                            <div class="dataBox">
                                                <div class="dataRow">
                                                    <p className="fixedP">Segmento Negocio<label className="requiredlabel">*</label></p>
                                                    <div class="fieldInput">

                                                        <select name='segmentoNegocio' onChange={this.handleInputChange} value={this.state.segmentoNegocio} required >
                                                            {
                                                                this.state.user && this.state.user.segmento_negocio && this.state.user.segmento_negocio.map((item, index) => {

                                                                    return this.state.segmentoNegocio === item.value ?
                                                                        <option key={index} value={item.value} selected>{item.value}</option> :
                                                                        <option key={index} value={item.value}>{item.value}</option>

                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">Monto<label className="requiredlabel">*</label></p>
                                                    <div class="fieldInput">
                                                        <CurrencyInput style={{ width: '90%' }} value={this.state.monto} onChangeEvent={this.handleChangeCurrency} prefix="$" />
                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">Detalles</p>
                                                    <div class="fieldInput">
                                                        <textarea style={{ minHeight: "50px" }} type="text" name='descripcion' value={this.state.descripcion} onChange={this.handleInputChange} placeholder="Detalles del costo" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="row">


                                        <div className="form-group col-4">
                                            <label>Fecha</label>
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.firstDay} maxDate={this.state.lastDay} className="form-control" selected={this.state.fecha} onChange={date => this.setState({ fecha: date })} />
                                        </div>
                                        <div className="form-group col-4">
                                            <label>Peridodos (meses)</label>
                                            <input type="number" min={0} name='recurrentperiodos' className="form-control" value={this.state.recurrentperiodos} onChange={this.handleInputChange} placeholder="Cantidad de periodos" required />

                                        </div>
                                        <div className="form-group col-4">
                                            <label >Segmento de negocio</label>
                                            <select className={"form-control "} name='segmentoNegocio' onChange={this.handleInputChange} value={this.state.segmentoNegocio} required >
                                                {
                                                    this.state.user && this.state.user.segmento_negocio && this.state.user.segmento_negocio.map((item, index) => {

                                                        return this.state.segmentoNegocio === item.value ?
                                                            <option key={index} value={item.value} selected>{item.value}</option> :
                                                            <option key={index} value={item.value}>{item.value}</option>

                                                    })
                                                }
                                            </select>

                                        </div>
                                        <div className="form-group col-4">
                                            <label>Unidad</label>
                                            <input type="text" name='nombre' className="form-control" disabled />
                                        </div>

                                        <div className="form-group col-4">
                                            <label>Monto</label>
                                            <CurrencyInput className="form-control" value={this.state.monto} onChangeEvent={this.handleChangeCurrency} prefix="$" />
                                        </div>
                                        <div className="form-group col-12">
                                            <label>Nombre del costo</label><label className="requiredlabel">*</label>
                                            <input type="text" name='nombre' className="form-control" value={this.state.nombre} onChange={this.handleInputChange} placeholder="Nombre del costo" required />
                                        </div>
                                        <div className="form-group col-12">
                                            <label>Detalles</label>
                                            <textarea style={{ minHeight: "50px" }} type="text" name='descripcion' className="form-control" value={this.state.descripcion} onChange={this.handleInputChange} placeholder="Detalles del costo" />
                                        </div>

                                    </div>*/}
                                </ModalBody>
                                <ModalFooter>
                                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={this.closeModalRecurrent} >Salir</button>
                                    <button type="submit" className="boton secBtn">  Aceptar</button>
                                </ModalFooter>
                            </form>
                        </Modal>


                    </div>


                }
            </Fragment>

        );
    }
}