import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import CurrencyInput from 'react-currency-input';
import Swal from 'sweetalert2';
import { udpateSegmentoNegocio, getUserByUid,udpatePrecioDolar } from '../../../controllers/usuarios';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


const Configuraciones = (props) => {
    const classes = useStyles();
    const [user, setUser] = useState(null);
    const [segmentosNegocio, setSegmentosNegocio] = useState([]);

    const [dolarFloat, setDolarFloat] = useState(0.0);
    const [dolar, setDolar] = useState("$0.0");

    useEffect(() => {
        //setUser(props.user);
        if (props.user) {
            getUserByUid(props.user.uid).then(snapshot => {
                let iduser = 0;
                let ustoset = null;
                snapshot.forEach(doc => {
                    ustoset = { ...doc.data(), id: doc.id }
                });
                setUser(ustoset);
            });
        }

    }, [props]);


    useEffect(() => {
        if (user && user.segmento_negocio) {
            setSegmentosNegocio([...user.segmento_negocio]);
            if (user.dolarprice) {
                setDolarFloat(user.dolarprice);
                let num = '$' + user.dolarprice.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                setDolar(num);
            }
        }
    }, [user]);

    const addSegmento = () => {
        let newsegmento = {
            saved: false,
            value: 'Nuevo segmento'
        }
        setSegmentosNegocio([...segmentosNegocio, newsegmento]);
    }
    const guardarPrecioDolar=()=>{
        if (user && user.id) {
            udpatePrecioDolar(user.id, dolarFloat).then(resp => {
                Swal.fire('Éxito', 'Se ha actualizado el precio del dolar.', 'success')
            }).catch(err => { console.log(err) });
        }
    }
    const guardarSegmentos = () => {

        if (user && user.id) {
            udpateSegmentoNegocio(user.id, segmentosNegocio).then(resp => {
                console.log("ok")
            }).catch(err => { console.log(err) });
        }
    }

    const handleActualiza = (e, index) => {
        let aux = [];
        segmentosNegocio.forEach((element, i) => {
            let o = element;
            if (index === i) {
                o.value = e.target.value;
            }
            aux.push(o);
        });
        setSegmentosNegocio(aux);
    }
    const handleChangeTarifa = (event, maskedvalue, floatvalue) => {
        setDolar(maskedvalue);
        setDolarFloat(floatvalue);
    }
    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Segmentos de negocio</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div onClick={() => addSegmento()} style={{ width: '300px' }}>
                        <div className="btn btn-primary">
                            <AddIcon size="default" style={{
                                color: "#9e9e9e",

                            }} aria-label="editar" />
                            <span>Agregar</span>
                        </div>
                    </div>
                    <div className="col-12">
                        {
                            segmentosNegocio.map((element, index) => {
                                return <TextField key={`elindex${index}`} id={`elindex${index}`} onChange={e => handleActualiza(e, index)} value={element.value} label="Outlined" variant="outlined" />
                            })
                        }
                        <button className="btn btn-primary" onClick={guardarSegmentos}>Guardar segmentos</button>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Precio de Dolar</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="col-3">
                        <div>
                            <label >Dolar</label>
                            <CurrencyInput className={"form-control "} value={dolar} onChangeEvent={handleChangeTarifa} prefix="$" required />
                            <button className="btn btn-primary" onClick={guardarPrecioDolar}>Guardar precio</button>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>

        </div>

    );
}

export default Configuraciones;