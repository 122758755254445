import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input';
import Swal from 'sweetalert2';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addIngreso, updateIngreso, addDestino, getAllDestinosByUser } from '../../../controllers/ingresos';
import { actualizaPagos, actualizaPagosCFDINormal } from '../../../controllers/viajes';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { getClientes } from '../../../controllers/clientes';
import { makeStyles } from '@material-ui/core/styles';
import { pagarViajes } from '../../../controllers/ingresos';
import { getViajesFacturadosByCliente, getViajesFacturadosByClienteByRange, getCFDISNormalesByClienteByRange } from '../../../controllers/viajes';
import { getUserByUid } from '../../../controllers/usuarios';
import Checkbox from '@material-ui/core/Checkbox';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import axios from 'axios';
import xml2js from 'xml2js';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'inline-block',
        float: 'right',
        width: '60%',
        height: 30,
        marginTop: 4,
        marginRight: 2
    }
}));
const ModalCreateEdit = (props) => {
    //constantes
    const CATALOGOS_TIPOS = ['Facturación por ventas', 'Renta de unidad', 'Otros ingresos'];
    const CATALOGO_METODOS_PAGO = ['Transferencia', 'Efectivo', 'Cheque'];
    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);
    const [destinosUser, setDestinosUser] = useState([]);
    //estados propios del formulario
    const [tipo, setTipo] = useState('Facturación por ventas');
    const [monto, setMonto] = useState('');
    const [montoFloat, setMontoFloat] = useState(0.0);
    const [iva, setIva] = useState('');
    const [ivaFloat, setIvaFloat] = useState(0.0);
    const [referencia, setReferencia] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [cliente, setCliente] = useState('-');
    const [clienteId, setClienteId] = useState("");
    const [metodoPago, setMetodoPago] = useState('Depósito');
    const [facturas, setFacturas] = useState([]);
    const [facturaid, setFacturaID] = useState(1);
    const [iva16, setIva16] = useState(true);
    const [destinoIngreso, setDestinoIngreso] = useState('');
    const [clientes, setClientes] = useState([]);
    const [facturasReft, setFacturasReft] = useState([]);
    const [usuarioobj, setUsuarioObj] = useState(null);
    const [segmentoNegocio, setSegmentoNegocio] = useState("");
    const [isComplementoPago, setIsComplementoPago] = useState(false);
    const [razonesSociales, setRazonesSociales] = useState([]);
    const [rfc, setRfc] = useState("");
    const [currencyMX, setCurrencyMX] = useState(true);

    const [dolarprice, setDolarPrice] = useState(20.0);

    const [fechaRangoStart, setFechaRangoStart] = useState(new Date());
    const [fechaRangoEnd, setFechaRangoEnd] = useState(new Date());
    const [totalNota, setTotalNota] = useState(0.0);
    const [totalNotaString, setTotalNotaString] = useState("$0.0");

    const [totalNotaDesc, setTotalNotaDesc] = useState(0.0);
    const [totalNotaDescString, setTotalNotaDescString] = useState("$0.0");
    const [porciento, setPorciento] = useState(10);

    const [ivatasa, setIvaTasa] = useState("16");
    const [retencion, setRetencion] = useState("0");

    const [incluyeImpuestos, setIncluyeImpuesto] = useState("No");

    useEffect(() => {
        setMetodoPago('Depósito');
        setTipo('Facturación por ventas');
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);

    useEffect(() => {
        if (usuarioobj && usuarioobj.razones) {
            let razonesasignar = [];
            usuarioobj.razones.forEach(r => {
                if (!r.inactivo) {
                    razonesasignar.push(r);
                }
            })
            setRazonesSociales(razonesasignar);

            if (usuarioobj.razones.length > 0 && usuarioobj.razones[0].Rfc) {
                setRfc(usuarioobj.razones[0].Rfc);
            }
            if (!isEdit && segmentoNegocio === "" && usuarioobj.segmento_negocio && usuarioobj.segmento_negocio.length > 0) {
                setSegmentoNegocio(usuarioobj.segmento_negocio[0].value);
            }
        }
    }, [usuarioobj])

    const getData = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let obuser = null;
            snapshot.forEach(document => {
                obuser = { ...document.data(), id: document.id }
                if (!obuser.segmento_negocio) {
                    obuser.segmento_negocio = [];
                }

            });
            setUsuarioObj(obuser);
        });
        await getClientes(uid).then(snapshot => {
            let aux = [];
            snapshot.forEach(document => {
                let o = { ...document.data() };
                o.id = document.id;
                aux.push(o);
            });
            let toset = aux.map((item, index) => {
                let ob = { ...item, name: item.nombre.trim() + " - " + item.estado_short.trim(), value: item.id, id: "user" + index };
                return ob;
            });
            setClientes(toset);
        }).catch(err => { });
    }
    useEffect(() => {
        if (uid) {
            getData(uid);
        }
    }, [uid]);

    useEffect(() => {
        if (facturasReft.length > 0) {

        }
    }, [facturasReft]);

    useEffect(() => {
        if (cliente && cliente != "" && clientes.length > 0) {
            let objf = clientes.find(element => element.name === cliente);
            if (objf) {
                if (objf.value != clienteId) {
                    // console.log("cliente info", objf);
                    setClienteId(objf.value);
                    // cargarFacturasReft(objf.value);
                }

            }
        }
    }, [cliente])

    const cargarFacturasReft = async (clienteid) => {
        console.log("cargando facturas de cliente", clienteid);
        getViajesFacturadosByCliente(clienteid).then(snapshot => {
            let aux = [];
            snapshot.forEach(doc => {
                let pendientes = 0.0;


                let o = doc.data();

                o.id = doc.id;
                o.key = doc.id;
                o.factura = o.general.nviaje;
                o.carta_porte = o.general.ncarta;
                o.cliente = o.general.cliente;
                o.total = o.detalles.tarifaFloat;
                o.fecha = o.fecha_facturado || null;
                if (o.pagos) {
                    //calculamos pendientes
                    let pagado = 0.0;
                    o.pagos.forEach(element => {
                        pagado += element.montoFloat;
                    });

                    if (o.factura_monto >= pagado) {
                        pendientes = o.factura_monto - pagado;
                    } else {
                        pendientes = 0.0;
                    }

                } else {
                    pendientes = o.factura_monto;
                }
                o.pendientes = pendientes;
                o.obj = { ...o };

                let nombreCP = "";
                if (o.general && o.general.ncarta) {
                    nombreCP = `  ${o.general.ncarta}`;
                }
                o.facturaname = `${o.prefijoFactura}-${o.folioFactura}${o.carta_porte}`;
                if (o.status !== "Pagado")
                    aux.push(o);

            });
            setFacturasReft(aux);
        }).catch(err => console.log(err));

    }
    const initValues = () => {
        /* if (props.facturasref) {
             setFacturasReft(props.facturasref);
         }*/

        if (isEdit && recordedit != null) {
            setTipo(recordedit.tipo);
            setMonto(recordedit.monto);
            setMontoFloat(recordedit.montoFloat);
            setIva(recordedit.iva);
            setIvaFloat(recordedit.ivaFlaot);
            setDescripcion(recordedit.descripcion);
            setCliente(recordedit.cliente);
            setReferencia(recordedit.referencia);
            setFecha(recordedit.fecha.toDate());
            setMetodoPago(recordedit.metodoPago);
            setFacturas(recordedit.facturas);
            setSegmentoNegocio(recordedit.segmentoNegocio || '');
            setDestinoIngreso(recordedit.destinoIngreso);
            let maxfactid = 0;
            if (recordedit && recordedit.facturas) {
                recordedit.facturas.forEach(element => {
                    if (element.temid && element.temid >= maxfactid) {
                        maxfactid = element.temid;
                    }
                });
            }

            maxfactid++;
            try {
                setFacturaID(maxfactid);
            } catch { }

        } else {
            setTotalNotaDesc(0.0);
            setTotalNota(0.0);
            setPorciento(10.0);
            setIsSearching(false);
            setClienteId("");
            setCliente(null);
            setFacturasReft([]);
            setFacturaID(1);
            setTipo('Facturación por ventas');
            setMonto('');
            setSegmentoNegocio('');
            setReferencia('');
            setMontoFloat(0.0);
            setIva('');
            setDestinoIngreso('');
            setIvaFloat(0.0);
            setDescripcion('');
            setCliente('-');
            setMetodoPago('Depósito');
            setFacturas([]);
        }
    }

    const calculateIVA = () => {
        let percent = iva16 ? 0.08 : 0.16;
        let ivanew = montoFloat * percent;
        let num = '$' + ivanew.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        setIvaFloat(ivanew);
        setIva(num);
    }
    useEffect(() => {
        calculateIVA();
    }, [montoFloat, iva16]);

    useEffect(() => {
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setFacturaID(1);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit && recordedit.facturas) {
            setFacturas(recordedit.facturas);
            initValues();
        }

    }, [recordedit]);


    const loadDestinos = () => {
        if (uid) {

            let destinos = [];
            getAllDestinosByUser(uid).then(snapshot => {
                snapshot.forEach(doc => {
                    let o = doc.data();
                    destinos.push(o);
                });
                setDestinosUser(destinos);
            }).catch(err => { console.log(err) });
        }
    }
    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
            loadDestinos();
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();
        // setIsLoading(true);

        setIsSearching(true);
        //setIsOpen(false);
        let ivarate = 0.16;
        let retencionrate = 0.0;
        /*if (isIVA16) {
            ivarate = 0.16;
        } else {
            ivarate = 0.08;
        }*/
        if (ivatasa == "16") {
            ivarate = 0.16;
        } else if (ivatasa == "8") {
            ivarate = 0.08;
        } else {
            ivarate = 0.0;
        }

        if (retencion === "4") {
            retencionrate = 0.04;
        } else {
            retencionrate = 0.0;
        }
        let cfdisrealcionados = [];
        //console.log("facturasssss",facturas);
        //return;
        await facturas.forEach(async (f) => {

            let facturaR=facturasReft.find(e=>e.uuid==f.uuid);
          

            cfdisrealcionados.push(f.uuid);
            let pago = {
                ...f
            }
            let calcPago = pago.pendienteFloat * porciento / 100;

            if(facturas.length==1){
                let difTotalCalc=totalNotaDesc-calcPago;
                if(Math.abs(difTotalCalc)>1){
                    calcPago=parseFloat(totalNotaDesc);
                }
            }
           

            pago.montoFloat = calcPago;
            pago.monto = '$' + calcPago.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

            pago.difpendienteFloat = pago.pendienteFloat - calcPago;
            pago.difpendiente = '$' + pago.difpendienteFloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

            let a = [pago];
            console.log("pago", a);
           // console.log("factura", f);
            //return;

            if (facturaR && facturaR.cfdinormal) {
                console.log("pago a cfdiNOrmal");
                await actualizaPagosCFDINormal(f.referencia, a).then(resp => { }).catch(err => { console.log(err) });
            } else {
                console.log("pago a CCP");
                await actualizaPagos(f.referencia, a).then(resp => { }).catch(err => { console.log(err) });
            }
        });
        // console.log("relacionados",cfdisrealcionados);
        //  return;
        let totalfix = totalNotaDesc ? parseFloat(parseFloat(totalNotaDesc).toFixed(2)) : 0.0;
        if (totalfix > 0.0) {
            if (incluyeImpuestos === "Si") {
                let aux = totalfix / (1 + (ivarate - retencionrate));
                totalfix = parseFloat(parseFloat(aux).toFixed(2));
            }
        }
        let obj = {
            total: totalfix,
            descripcion,
            cfdisrealcionados,
            idcliente: clienteId,
            rfc,
            ivar: ivarate,
            retencionrate,
            moneda: currencyMX ? "MXN" : "USD",
            dolarprice,
            uid,
        }

        if (isAdmin && adminCurrentUser) {
            obj.uid = adminCurrentUser;
        }

        // console.log(obj);
        // return;
        let rutatipofactura = "notacredito";

        //https://us-central1-transport-plus-98f66.cloudfunctions.net/app
        //http://localhost:5000/transport-plus-98f66/us-central1/app

        //https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/
        //http://localhost:5000/unit-testing-d4fe7/us-central1/app/api/
        let rutaPost = "https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/";
        if (rfc === "EKU9003173C9") {
            rutaPost = "https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/";
        }

        //  rutaPost = "http://localhost:5000/unit-testing-d4fe7/us-central1/app/api/";

        await axios.post(`${rutaPost}${rutatipofactura}`,
            { ...obj }).then(resp => {

                props.notify();
                props.toggle();

                if (resp.data == "Ok") {
                    Swal.fire("Factura generada", `Se ha generado la Nota de credito `, 'success');
                    setIsSearching(false);
                } else {
                    setIsSearching(false);
                    console.log("resp.data", resp.data);

                    if (resp.data && resp.data.estatus && resp.data.estatus.informacionTecnica) {
                        Swal.fire("No se pudo generar", `${resp.data.estatus.informacionTecnica}`, 'warning');
                    } else {
                        if (typeof resp.data === "object") {
                            const myJSON = JSON.stringify(resp.data);
                            Swal.fire("No se pudo generar", `${myJSON}`, 'warning');
                        } else {
                            Swal.fire("No se pudo generar", `${resp.data}`, 'warning');
                        }

                    }

                }

                //console.log("respuesta", resp);


            }).catch(err => {
                setIsSearching(false);
                console.log("error", err.toString());
                if (err.toString().includes("Network Error")) {
                    Swal.fire("Error", "Error de conexión, no fue posible conectar con el servidor, revisa tu conexión", 'error');
                } else {
                    Swal.fire("Error", "No se pudo generar la nota de crédito", 'error');
                }
            });
        console.log("objTopost", obj);


    }
    const handleChangeCurrency = (event, maskedvalue, floatvalue) => {
        setMonto(maskedvalue);
        setMontoFloat(floatvalue);
    }
    const handleChangeCurrencyIva = (event, maskedvalue, floatvalue) => {
        setIva(maskedvalue);
        setIvaFloat(floatvalue);
    }
    const onChangeDaterangeTwo = (fechas) => {
        if (!fechas || !fechas[0] || !fechas[1]) {
            return;
        }

        let start = new Date(fechas[0].getTime());
        let end = new Date(fechas[1].getTime());

        if (start)
            start.setHours(0, 0, 0);
        if (end)
            end.setHours(23, 59, 59);
        setFechaRangoStart(start);
        setFechaRangoEnd(end);

    };
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);


    const addFactura = () => {
        let nextfactid = facturaid;
        setFacturaID(facturaid + 1);//incrementamos

        let pend = '';
        let pendfloat = 0.0;
        let refe = '';
        let uuid = '';
        if (facturasReft && facturasReft.length > 0) {
            pendfloat = facturasReft[0].obj.pendientes;
            uuid = facturasReft[0].uuid;
            refe = facturasReft[0].id;
            let num = '$' + pendfloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            pend = num;
        }

        let factura = {
            temid: nextfactid,
            referencia: refe,
            monto: '',
            uuid,
            montoFloat: 0.0,
            pendiente: pend,
            pendienteFloat: pendfloat,
            difpendienteFloat: pendfloat,
            difpendiente: pend
        }
        setFacturas([...facturas, factura]);
    }
    const removeFactura = (element) => {
        let newArr = [...facturas];
        let foundIndex = newArr.findIndex(x => x.temid === element.temid);
        if (foundIndex >= 0) {
            newArr.splice(foundIndex, 1);
            setFacturas(newArr);
        }
    }
    const changeElmentFactura = (value, element) => {
        //
        let newArr = [...facturas];


        let foundIndex = newArr.findIndex(x => x.temid === element.temid);
        if (foundIndex >= 0) {
            newArr[foundIndex].referencia = value;
            if (facturasReft) {
                let objFactura = facturasReft.find(element => element.id == value);
                if (objFactura) {
                    newArr[foundIndex].pendienteFloat = objFactura.obj.pendientes;
                    newArr[foundIndex].monto = '';
                    newArr[foundIndex].uuid = objFactura.uuid || "";
                    newArr[foundIndex].montoFloat = 0.0;
                    let num = '$' + newArr[foundIndex].pendienteFloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                    newArr[foundIndex].pendiente = num;
                }


            }
            setFacturas(newArr);
        }


    }


    useEffect(() => {
        if (totalNota) {
            let num = '$' + parseFloat(totalNota).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            setTotalNotaString(num);

            if (porciento < 100 && porciento > 0) {
                let descuento = parseFloat(totalNota) * parseFloat(porciento) / 100.00;
                setTotalNotaDesc(descuento);
            }


        }

    }, [totalNota, porciento])
    useEffect(() => {
        if (facturas) {
            let totalaux = 0.0;
            facturas.forEach(e => {
                console.log("data factura", e);
                totalaux = parseFloat(totalaux) + parseFloat(e.pendienteFloat);
            });
            console.log("seting total", totalaux);
            setTotalNota(totalaux);
        }
    }, [facturas]);

    const handleChangeCurrencyFactura = (event, maskedvalue, floatvalue, element) => {

        let newArr = [...facturas];
        let foundIndex = newArr.findIndex(x => x.temid === element.temid);
        if (foundIndex >= 0) {

            newArr[foundIndex].monto = maskedvalue;
            newArr[foundIndex].montoFloat = floatvalue;

            if (newArr[foundIndex].pendienteFloat) {
                let dif = newArr[foundIndex].pendienteFloat - floatvalue;
                if (dif < 0) {
                    dif = 0.0;
                }
                let num = '$' + dif.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                newArr[foundIndex].difpendiente = num;
                newArr[foundIndex].difpendienteFloat = dif;
            }

            setFacturas(newArr);
        }
    }

    const refAfterEnter = useRef();
    const eventEnterOnFindCreate = (e) => {
        e.preventDefault();
        refAfterEnter.current.focus();
    }
    const classes = useStyles();

    const [isSearching, setIsSearching] = useState(false);



    const procesBusqueda = async () => {
        const viajesClienteFacturados = [];
        let fixed = [];
        await getViajesFacturadosByClienteByRange(clienteId, fechaRangoStart, fechaRangoEnd).then(snapshot => {

            //console.log("viajes encontrads del cliente",snapshot.docs.length);
            snapshot.forEach(doc => {
                let pendientes = 0.0;
                let o = { ...doc.data() };
                o.id = doc.id;
                if (o.facuturado) {

                    o.key = doc.id;
                    o.factura = o.general.nviaje;
                    o.carta_porte = o.general.ncarta;
                    o.uuid = o.facuturado.uuid || "";
                    o.cliente = o.general.cliente;
                    o.total = o.detalles.tarifaFloat;
                    o.fecha = o.fecha_facturado || null;
                    if (o.pagos) {
                        //calculamos pendientes
                        let pagado = 0.0;
                        o.pagos.forEach(element => {
                            pagado += element.montoFloat;
                        });

                        if (o.factura_monto >= pagado) {
                            pendientes = o.factura_monto - pagado;
                        } else {
                            pendientes = 0.0;
                        }

                    } else {
                        pendientes = o.factura_monto;
                    }
                    o.pendientes = pendientes;
                    o.obj = { ...o };

                    o.facturaname = `${o.prefijoFactura}-${o.folioFactura} ${o.carta_porte}`;
                    if (o.status !== "Pagado")
                        viajesClienteFacturados.push(o);

                }


            });


            viajesClienteFacturados.forEach(async (obj) => {
                if (obj.facuturado) {
                    let xmlstring = obj.facuturado.cfdixml;
                    //let fixedXml=xmlstring.replace('cfdi:', '');
                    let fixedXml = xmlstring.split('cfdi:').join('');
                    fixedXml = xmlstring.split('$').join('obj');

                    let response = null;
                    await xml2js.parseString(fixedXml, async (err, result) => {
                        if (err) {
                            console.log(err);
                        }
                        else {
                            // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                            //let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                            let moneda = result['cfdi:Comprobante']['$']['Moneda'];
                            if (moneda === "USD") {
                                if (!currencyMX) {
                                    fixed.push(obj);
                                }
                            } else {
                                if (currencyMX) {
                                    fixed.push(obj);
                                }
                            }

                            console.log("primer xml", moneda);
                            //response = objdataxml;
                            // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                        }

                    });
                }
            })



        }).catch(err => console.log(err));

        let aux2 = [];
        console.log("buscando cfdi normales")
        await getCFDISNormalesByClienteByRange(clienteId, fechaRangoStart, fechaRangoEnd).then(snapshot => {

            snapshot.forEach(doc => {
                let pendientes = 0.0;


                let o = doc.data();
                console.log("cfdinormal", o);
                if (o.facuturado) {

                    o.id = doc.id;
                    o.key = doc.id;
                    o.factura = `${o.prefijoFactura} - ${o.folioFactura}`;
                    o.carta_porte = "";
                    o.cliente = "";
                    o.uuid = o.facuturado.uuid || "";
                    o.cfdinormal = true;
                    o.total = o.factura_monto;
                    o.fecha = o.fecha_facturado || null;
                    if (o.pagos) {
                        //calculamos pendientes
                        let pagado = 0.0;
                        o.pagos.forEach(element => {
                            pagado += element.montoFloat;
                        });

                        if (o.factura_monto >= pagado) {
                            pendientes = o.factura_monto - pagado;
                        } else {
                            pendientes = 0.0;
                        }

                    } else {
                        pendientes = o.factura_monto;
                    }
                    o.pendientes = pendientes;
                    o.obj = { ...o };

                    o.facturaname = `${o.prefijoFactura || ""}-${o.folioFactura || ""} ${o.carta_porte || ""}`;
                    if (o.status !== "Pagado")
                        aux2.push(o);
                }

            });

        }).catch(err => console.log(err));

        aux2.forEach(async (obj) => {
            if (obj.facuturado) {
                let xmlstring = obj.facuturado.cfdixml;
                //let fixedXml=xmlstring.replace('cfdi:', '');
                let fixedXml = xmlstring.split('cfdi:').join('');
                fixedXml = xmlstring.split('$').join('obj');

                let response = null;
                await xml2js.parseString(fixedXml, async (err, result) => {
                    if (err) {
                        console.log(err);
                    }
                    else {
                        // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                        //let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                        let moneda = result['cfdi:Comprobante']['$']['Moneda'];
                        if (moneda === "USD") {
                            if (!currencyMX) {
                                fixed.push(obj);
                            }
                        } else {
                            if (currencyMX) {
                                fixed.push(obj);
                            }
                        }

                        // console.log("primer xml", moneda);
                        //response = objdataxml;
                        // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                    }

                });
            }
        })
        setFacturasReft(fixed);
        setIsSearching(false);
        console.log("viajes facturados", fixed.length);
    }
    useEffect(() => {
        if (isSearching) {
            procesBusqueda();
        }
    }, [isSearching])

    const buscarFacturas = async () => {
        if (clienteId) {
            if (!isSearching) {
                setIsSearching(true);
            }
        } else {
            alert("selecciona un cliente");
        }
    }

    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isSearching}
            spinner
            text='Procesando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Crear Nota de Crédito" : `Crear Nota de Crédito `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP">Configuración de Timbrado</p></div>

                            <div class="dataBox">


                                {
                                    razonesSociales.length > 1 &&
                                    <div class="dataRow">
                                        <p className="fixedP">Razón Social</p>
                                        <div class="fieldInput">
                                            <select name='rfc' onChange={(e) => setRfc(e.target.value)} value={rfc} required >
                                                {
                                                    razonesSociales.map((item, index) => {
                                                        return <option key={index} value={item.Rfc}>{item.Razon}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    /**
                                     *  RadioGroup row aria-label="gender" name="gender1" value={retencion} onChange={e => setRetencion(e.target.value)}>
                                        <FormControlLabel value="4" control={<Radio />} label="4%" />
                                        <FormControlLabel value="0" control={<Radio />} label="0" />

                                    </RadioGroup>

                                    </RadioGroup>
                                     */
                                }
                                <div class="dataRow">
                                    <p className="fixedP">IVA</p>
                                    <div class="fieldInput">
                                        <select name='ivaconfig' value={ivatasa} onChange={e => setIvaTasa(e.target.value)} >
                                            <option key={'iva16'} value="16" >16%</option>
                                            <option key={'iva8'} value="8" >8%</option>
                                            <option key={'iva0'} value="0" >Tasa 0</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Retención</p>
                                    <div class="fieldInput">
                                        <select name='retencionconfig' value={retencion} onChange={e => setRetencion(e.target.value)} >
                                            <option key={'ret4'} value="4" >4%</option>
                                            <option key={'ret0'} value="0" >0</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Total Inlcuye Impuestos</p>
                                    <div class="fieldInput">
                                        <select name='segmento' onChange={(e) => setIncluyeImpuesto(e.target.value)} value={incluyeImpuestos} required >
                                            <option key={'siincluye'} value="Si">Si</option>
                                            <option key={'noincluye'} value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Descripción</p>
                                    <div class="fieldInput">
                                        <textarea ref={refAfterEnter} style={{ minHeight: "50px" }} type="text" name='descripcion' value={descripcion} onChange={e => setDescripcion(e.target.value)} placeholder="Detalles del costo" />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="dataTable twoCol">
                            <div class="dataTitle"><p className="fixedP">Búsqueda de Facturas</p></div>

                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Cliente<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">

                                        {

                                            !isEdit && cliente &&
                                            <Autocomplete
                                                id="combo-box-demo0c"
                                                options={clientes}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option, value) => option.name === value.name}
                                                defaultValue={clientes.find(element => element.name.trim() === cliente.trim())}
                                                className={"selectbusqueda"}
                                                onChange={(e, val) => setCliente(val ? val.name : "")}
                                                renderInput={(params) => <TextField className="reducedselect2" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                            />}

                                        {
                                            !isEdit && !cliente &&
                                            <Autocomplete
                                                id="combo-box-demo0c"
                                                options={clientes}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option, value) => option.name === value.name}
                                                className={"selectbusqueda"}
                                                onChange={(e, val) => setCliente(val ? val.name : "")}
                                                renderInput={(params) => <TextField className="reducedselect2" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                            />
                                        }

                                        {isEdit && <input type="text" value={cliente} onChange={e => setCliente(e.target.value)} variant="outlined" disabled />}


                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">Moneda</p>
                                    <div class="fieldInput">
                                        <select name='segmento' onChange={(e) => setCurrencyMX(e.target.value == "1" ? true : false)} value={currencyMX ? "1" : "2"} required >
                                            <option key={'monedamxn'} value='1' selected>MXN</option>
                                            <option key={'monedausd'} value='2'>USD</option>
                                        </select>

                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Fecha</p>
                                    <div class="fieldInput">
                                        <DateRangePicker
                                            className="selectbusqueda2"
                                            clearIcon={null}
                                            value={[fechaRangoStart, fechaRangoEnd]}
                                            onChange={onChangeDaterangeTwo}
                                        />
                                    </div>
                                </div>

                                <button type='button' role='button' className="boton secBtn" style={{ marginBottom: '5px', marginRight: "7px", float: 'right' }} onClick={() => buscarFacturas()}>Buscar Facturas</button>
                                <br />
                            </div>
                        </div>
                    </div>
                    <div class="dataTable oneColmargin">
                        <div class="dataTitle"><p className="fixedP">Facturas Amparadas</p>

                            {
                                facturasReft.length > 0 ?
                                    <button type='button' class="boton plus-button" onClick={() => {
                                        addFactura();
                                    }} ></button> :
                                    <IconButton size="small" style={{ color: '#c7c7c7' }} className="btn-add-factura-amparada" disabled aria-label="agregar">
                                        <AddCircleIcon />
                                    </IconButton>
                            }
                        </div>

                        <div class="dataBox">
                            <div className="col-12">
                                {
                                    facturas && facturas.map((element, index) => {
                                        return <div className="row" key={element.temid}>
                                            <div className="form-group col-4">
                                                {index === 0 && <label>Referencia</label>}

                                                <select className={"form-control "} name='clase' onChange={e => changeElmentFactura(e.target.value, element)} value={element.referencia} required >
                                                    {

                                                        facturasReft.map((item, index) => {
                                                            return <option key={index} value={item.id}>{item.facturaname}</option>
                                                        })

                                                    }
                                                </select>

                                            </div>
                                            <div className="form-group col-4">
                                                {index === 0 && <label>Pendientes</label>}
                                                <input type="text" name='pendientes' className="form-control" value={element.pendiente} onChange={() => console.log("")} disabled />
                                            </div>
                                            {/*<div className="form-group col-3">
                                            {index === 0 && <label>Monto</label>}
                                            <CurrencyInput className="form-control" value={element.monto} max={100000} onChangeEvent={(event, maskedvalue, floatvalue) => handleChangeCurrencyFactura(event, maskedvalue, floatvalue, element)} prefix="$" />
                                            </div>*/}
                                            <div className="form-group col-1">
                                                {index === 0 && <label>Eliminar</label>}
                                                <IconButton size="small" className="btn-remove-factura" onClick={() => removeFactura(element)} aria-label="borrar">
                                                    <CancelIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div class="dataTable twoCol">
                            <div class="dataTitle"><p className="fixedP">Totales</p></div>

                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Subtotal</p>
                                    <div class="fieldInput">
                                        <input type="text" name='descripcion' value={totalNotaString} onChange={e => setTotalNotaString(e.target.value)} disabled />
                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">Descuento %</p>
                                    <div class="fieldInput">
                                        <input type="number" style={{ width: '90%' }} min={0} max={100} name='descripcion' value={porciento} onChange={e => setPorciento(e.target.value)} />
                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">Total</p>
                                    <div class="fieldInput">
                                        <input type="number" style={{ width: '90%' }} step={0.01} name='descripcion' value={totalNotaDesc} onChange={e => setTotalNotaDesc(e.target.value)} />
                                    </div>
                                </div>
                                {!currencyMX && <div class="dataRow">
                                    <p className="fixedP">DLL. tipo de cambio</p>
                                    <div class="fieldInput">
                                        <input type="number" name='cliente' style={{ width: '90%' }} value={dolarprice} onChange={e => setDolarPrice(e.target.value)} />
                                    </div>
                                </div>}


                            </div>
                        </div>
                    </div>


                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Cancelar</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalCreateEdit;