import { firebase } from '../configs/firebase';
export const getProveedorByID = id => {
    var db = firebase.firestore();
    return db.collection("proveedores").doc(id).get();
}
export const getProveedores = (uid) => {
    var db = firebase.firestore();
    return db.collection("proveedores").where("uid", "==", uid).get();
}

export const getTareasPersonalizadas = (uid) => {
    var db = firebase.firestore();
    return db.collection("tareascustom").where("uid", "==", uid).get();
}

export const addTareasPersonalizadas = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('tareascustom').add(dataaux);
}

export const getTalleres = (uid) => {
    var db = firebase.firestore();
    return db.collection("proveedores").where("uid", "==", uid).where("tipo", "==", "Taller").get();
}

export const getRefacciones = (uid) => {
    var db = firebase.firestore();
    return db.collection("refaccion").where("uid", "==", uid).get();
}
export const addRefaccion = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('refaccion').add(dataaux);
}
export const updateRefaccion = (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date() };
    var db = firebase.firestore();
    return db.collection('refaccion').doc(id).update(dataaux);
}


export const addProveedor = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('proveedores').add(dataaux);
}

export const updateProveedor = (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date() };
    var db = firebase.firestore();
    return db.collection('proveedores').doc(id).update(dataaux);
}

export const deleteProveedor = (id) => {
    var db = firebase.firestore();
    return db.collection('proveedores').doc(id).update({ uid: "deleted" });
}
/**
 * lista de tareas disponible
 */
export const getTareaByID = id => {
    var db = firebase.firestore();
    return db.collection("tareas_mantenimineto").doc(id).get();
}
export const getTareas = (uid) => {
    var db = firebase.firestore();
    return db.collection("tareas_mantenimineto").where("uid", "==", uid).get();
}
export const addTarea = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('tareas_mantenimineto').add(dataaux);
}
export const updateTarea = (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date() };
    var db = firebase.firestore();
    return db.collection('tareas_mantenimineto').doc(id).update(dataaux);
}
export const deleteTarea = (id) => {
    var db = firebase.firestore();
    return db.collection('tareas_mantenimineto').doc(id).update({ uid: "deleted" });
}
/**
 * 
 *manteminiento
 */
export const getMantenimientoByID = id => {
    var db = firebase.firestore();
    return db.collection("mantenimiento").doc(id).get();
}
export const getMantenimientos = (uid) => {
    var db = firebase.firestore();
    return db.collection("mantenimiento").where("uid", "==", uid).get();
}
export const addMantenimiento = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('mantenimiento').add(dataaux);
}

export const updateMantenimiento = (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date() };
    var db = firebase.firestore();
    return db.collection('mantenimiento').doc(id).update(dataaux);
}
export const deleteServicio = (id) => {
    var db = firebase.firestore();
    return db.collection('servicios').doc(id).update({ uid: "deleted" });
}
export const deleteMantenimiento = (id) => {
    var db = firebase.firestore();
    return db.collection('mantenimiento').doc(id).update({ uid: "deleted" });
}

export const addServicio = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('servicios').add(dataaux);
}

export const getServiciosByPlan = (planId) => {
    var db = firebase.firestore();
    return db.collection("servicios").where("planId", "==", planId).get();
}

export const getServiciosByUid = (uid) => {
    var db = firebase.firestore();
    return db.collection("servicios").where("uid", "==", uid).get();
}
export const getServicioById = (id) => {
    var db = firebase.firestore();
    return db.collection("servicios").doc(id).get();
}

export const updateServicio = (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date() };
    var db = firebase.firestore();
    return db.collection('servicios').doc(id).update(dataaux);
}

export const nextFolioServicio = (id) => {
    const db = firebase.firestore();
    const increment = firebase.firestore.FieldValue.increment(1);
    // Document reference
    const storyRef = db.collection('user_viaje').doc(id);
    // Update read count
    return storyRef.update({ fservicio: increment });
}
