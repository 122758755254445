import { firebase } from '../configs/firebase';


export const CopiarOperadoresEntreCuentas=async()=>{
    let uidorigen="1W3ZQxsEpGUo2uEsB5hMgJFiFAj2";
    let uiddestino="adCOj3hocBYbS1Z69GOptc0ukOr1";
    let aux=[];
    await getOperadores(uidorigen).then(snapshot=>{
        snapshot.forEach(document=>{
           let o= {...document.data()};
           o.uid=uiddestino;
           aux.push(o);
        });

    }).catch(err=>console.log(err));


    // Get a new write batch
    var db = firebase.firestore();
    const batch = db.batch();

    // Commit the batch

    aux.forEach((doc) => {
        var docRef = db.collection("operadores_user").doc(); //automatically generate unique id
        batch.set(docRef, doc);
    });

    /*await db.collection('viajes').get().then(resp => {
       resp.docs.forEach((doc) => {
            const docRef = firebase.firestore().collection("viajes").doc(doc.id)
            batch.update(docRef, {isViajeCargado:true});
        })
    });*/
    await batch.commit();
}

export const ActualizacionMasivaTemporal = async () => {
    const parquevehicular = [
        {
            "Nombre": "Rumaldo",
            "Apellidos": "Martinez Rangel",
            "Telefono": "1",
            "RFC": "MARR8104209T4",
            "TipoLicencia": "B",
            "NumeroLicencia": "BCN0222869",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJT006539585",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Raul",
            "Apellidos": "Jimenez Franco",
            "Telefono": "1",
            "RFC": "JIFR841206737",
            "TipoLicencia": "B",
            "NumeroLicencia": "BCN0218820",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJT005161975",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Jorge",
            "Apellidos": "Alvarez Montañez",
            "Telefono": "1",
            "RFC": "AAMJ860305RV2",
            "TipoLicencia": "B",
            "NumeroLicencia": "BCN0218215",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJT011415045",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Jose Marcelo",
            "Apellidos": "Zamudio Alvarado",
            "Telefono": "1",
            "RFC": "ZAAM8301168EA",
            "TipoLicencia": "B",
            "NumeroLicencia": "BCN0217447",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJT007147996",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Fernando",
            "Apellidos": "Gonzalez Lopez",
            "Telefono": "1",
            "RFC": "GOLF731112M42",
            "TipoLicencia": "B",
            "NumeroLicencia": "BCN0215012",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJT009932618",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Cecilio Isidro",
            "Apellidos": "Telles Devora",
            "Telefono": "1",
            "RFC": "TEDC6910035S6",
            "TipoLicencia": "B",
            "NumeroLicencia": "BCN207920",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJT003964100",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Ricardo Alonso",
            "Apellidos": "Muñoz Ortiz",
            "Telefono": "1",
            "RFC": "MUOR790817BQ2",
            "TipoLicencia": "B",
            "NumeroLicencia": "BCN0215494",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJT004868210",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Humberto Jacobo",
            "Apellidos": "Unzueta Galvan",
            "Telefono": "1",
            "RFC": "UUGH830501STA",
            "TipoLicencia": "B",
            "NumeroLicencia": "LFD00004527",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJT007492803",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Fidel",
            "Apellidos": "Lopez Perez",
            "Telefono": "1",
            "RFC": "LOPF650311CZ5",
            "TipoLicencia": "B",
            "NumeroLicencia": "BC202106144524",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJT006610713",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Felix",
            "Apellidos": "Padilla Lugo",
            "Telefono": "1",
            "RFC": "PALF940526SD4",
            "TipoLicencia": "B",
            "NumeroLicencia": "BCN0217681",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJT012424295",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Victor Manuel",
            "Apellidos": "Ibarra Corona",
            "Telefono": "1",
            "RFC": "IACV850201KR6",
            "TipoLicencia": "B",
            "NumeroLicencia": "BC202105230802",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "NA",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Rolando Eramos",
            "Apellidos": "Galaviz Lopez",
            "Telefono": "1",
            "RFC": "GALR7410141T9",
            "TipoLicencia": "B",
            "NumeroLicencia": "BGT00019476",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "NA",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Moises Israel",
            "Apellidos": "Rosales Montelongo ",
            "Telefono": "1",
            "RFC": "ROMM961220PB2",
            "TipoLicencia": "B",
            "NumeroLicencia": "BCN0222898",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJT011390771",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Angel ",
            "Apellidos": "Francisco",
            "Telefono": "1",
            "RFC": "OOZA921103T17",
            "TipoLicencia": "B",
            "NumeroLicencia": "BCN0220886",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJT007656757",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Jose",
            "Apellidos": "Calletano",
            "Telefono": "1",
            "RFC": "DOQC781122ME6",
            "TipoLicencia": "B",
            "NumeroLicencia": "BCN209473",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJN000786362",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Silvestre",
            "Apellidos": " ",
            "Telefono": "1",
            "RFC": "EIMS6512314C2",
            "TipoLicencia": "B",
            "NumeroLicencia": "GTO0015506",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "NA",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Javier ",
            "Apellidos": "Alejandro",
            "Telefono": "1",
            "RFC": "TECJ890511QD7",
            "TipoLicencia": "B",
            "NumeroLicencia": "LFD00031863",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJT005589822",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "Andres",
            "Apellidos": "Alfonso",
            "Telefono": "1",
            "RFC": "BARA810330T49",
            "TipoLicencia": "B",
            "NumeroLicencia": "LFD00053622",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "MEX033528924",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "CESAR",
            "Apellidos": " ",
            "Telefono": "1",
            "RFC": "CESC761228JN7",
            "TipoLicencia": "B",
            "NumeroLicencia": "BCN0212793",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJT005476046",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "JULIO CESAR",
            "Apellidos": " ",
            "Telefono": "1",
            "RFC": "AAFJ930412KK0",
            "TipoLicencia": "B",
            "NumeroLicencia": "LFD00008025",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "TJT008325628",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        },
        {
            "Nombre": "VIOLETA",
            "Apellidos": " ",
            "Telefono": "1",
            "RFC": "MECV800323TJ9",
            "TipoLicencia": "B",
            "NumeroLicencia": "LFD01041856",
            "ExpiracionLicencia": "11/8/25",
            "NumeroIMSS": "NA",
            "cp": "22203",
            "estado": "Baja California",
            "estado_short": "BCN",
            "localidad": "Tijuana",
            "localidadSat": "04",
            "municipio": "Tijuana",
            "municipioSat": "004",
            "calle": "Escaramuza Charra",
            "numero": "Lote - 106",
            "colonia": "Granjas Familiares de Matamoros",
            "coloniaSat": "1 432"
        }
    ];
    const fixedunidades=parquevehicular.map(element=>{
        let obj = {
            nombre:element.Nombre||"",
            apellidos:element.Apellidos||"",
            ciudad:"",
            celular:element.Telefono||"",
            tipoDePago:"Manual",
            active:true,
            noLicencia:element.NumeroLicencia||"",
            tipoLicencia:element.TipoLicencia||"",
            noIMSS:element.NumeroIMSS||"",
            isMoreData:false,
            direccion:"",
            telefonoEmergencia:"",
            contactoEmergencia:"",
            rfc:element.RFC||"",
            internacional: false,
            estado:element.estado||"",
            estado_short:element.estado_short||"",
            localidad:element.localidad||"",
            localidadSat:element.localidadSat||"",
            municipio:element.municipio||"",
            municipioSat:element.municipioSat||"",
            calle:element.calle||"",
            numero:element.numero||"",
            colonia:element.colonia||"",
            colonias:[],
            coloniaSat:element.coloniaSat||"",
            cp:element.cp||"",
            uid:"xxx",
        };

        return obj;

    })
  

    console.log("unidades user", fixedunidades);
    //    return;

    // Get a new write batch
    var db = firebase.firestore();
    const batch = db.batch();

    // Commit the batch

    fixedunidades.forEach((doc) => {
        var docRef = db.collection("operadores_user").doc(); //automatically generate unique id
        batch.set(docRef, doc);
    });

    /*await db.collection('viajes').get().then(resp => {
       resp.docs.forEach((doc) => {
            const docRef = firebase.firestore().collection("viajes").doc(doc.id)
            batch.update(docRef, {isViajeCargado:true});
        })
    });*/
    await batch.commit();
}
export const getOperadorById = (id) => {
    var db = firebase.firestore();
    return db.collection("operadores_user").doc(id).get();
}

export const getOperadores = (uid) => {
    var db = firebase.firestore();
    return db.collection("operadores_user").where("uid", "==", uid).get();
}

export const addOperador = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('operadores_user').add(dataaux);
}

export const updateOperador = (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date()};
    var db = firebase.firestore();
    return db.collection('operadores_user').doc(id).update(dataaux);
}

export const deleteOperador = (id) => {
    var db = firebase.firestore();
    return db.collection('operadores_user').doc(id).update({uid:"deleted"});
}
