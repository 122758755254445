import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
//import { addIngreso, updateIngreso, addDestino, getAllDestinosByUser } from '../../../controllers/ingresos';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { addMantenimiento, getTareas, updateMantenimiento, deleteMantenimiento, addTareasPersonalizadas, getTareasPersonalizadas } from '../../../controllers/proveedor';
import { getActivos,updateActivo } from '../../../controllers/activos';
import 'react-phone-input-2/lib/style.css'
import DatePicker from 'react-datepicker';
import ModalAddTareaMantenimiento from '../ModalAddTareaMantenimiento';

const ModalCreateEdit = (props) => {
    //constantes}
    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [isOpenTarea, setIsOpenTarea] = useState(false);
    const [isEditTarea, setIsEditTarea] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);

    //estados propios del formulario
    const [active, setActive] = useState(true);
    const [nombre, setNombre] = useState("");

    const [activos, setActivos] = useState([]);

    const [unidad, setUnidad] = useState("");
    const [unidadNombre, setUnidadNombre] = useState("");
    const [unidadTipo, setUnidadTipo] = useState("");
    const [unidadSegmento, setUnidadSegmento] = useState("");
    const [unidadKm, setUnidadKm] = useState(0);
    const [tarea, setTarea] = useState("");
    const [tareaNombre, setTareaNombre] = useState("");
    const [recurrente, setRecurrente] = useState("1");


    const [tipo, setTipo] = useState("Proveedor");
    const [tipos, setTipos] = useState(["Proveedor", "Taller"]);
    const [tareas, setTareas] = useState([""]);
    const [condicionRecurrencia, setCondicionRecurrencia] = useState("tiempo");
    const [recurrencia, setRecurrencia] = useState("mensual");
    const [kmsrecurrencia, setKmsRecurrencia] = useState(0);
    const [odometroActual, setOdometroActual] = useState(0);
    //const [odometroActual, setOdometroActual] = useState(0);
    const [proximaFecha, setProximaFecha] = useState(new Date());
    const [servicioKms, setServicioKms] = useState(0);


    useEffect(() => {
        if (unidad) {
            let ufind = activos.find(f => f.id === unidad);
            if (ufind) {
                setUnidadNombre(ufind.nombre || "");
                setUnidadTipo(ufind.tipo || "");
                setUnidadSegmento(ufind.segmentoNegocio || "");
                setUnidadKm(ufind.unidadKm || 0);
                if (ufind.kms) {
                    setOdometroActual(ufind.kms);
                }
            }
        }
    }, [unidad])

    useEffect(() => {
        if (!isEdit) {
            setServicioKms(parseInt(kmsrecurrencia) + parseInt(odometroActual));
        }
    }, [kmsrecurrencia, odometroActual])

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);
    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);

    const consultaTareas = async () => {

        getTareas(uid).then(resp => {
            let auxt = [];
            resp.forEach(doc => {
                let t = doc.data();
                auxt.push(t.nombreTarea);
            });
            setTareas(["", ...auxt]);
        });
    }

    const initValues = async () => {
        let auxactivos = [];
        console.log("init values");
        getActivos(uid).then(resp => {
            resp.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                auxactivos.push(o);
            });
            auxactivos.sort((a, b) => (a.nombre > b.nombre) ? 1 : -1)
            setActivos(auxactivos);

            if (isEdit && recordedit && recordedit.unidad) {
                let findact = auxactivos.find(fa => fa.id === recordedit.unidad);
                if (findact) {
                    setOdometroActual(findact.kms || 0);
                }else{
                    console.log("no se encontro activo",auxactivos);
                }
            }
        })

        /*getActivos(uid).then(resp => {
            resp.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                auxactivos.push(o);
            });
            auxactivos.sort((a, b) => (a.nombre > b.nombre) ? 1 : -1)
            setActivos(auxactivos);
        });*/

        consultaTareas();

        if (isEdit && recordedit != null) {
            setNombre(recordedit.nombre || "");
            setActive(recordedit.active);
            setUnidad(recordedit.unidad);
            setUnidadNombre(recordedit.unidadNombre);
            setTarea(recordedit.tarea);
            setCondicionRecurrencia(recordedit.condicionRecurrencia || "tiempo");
            setRecurrencia(recordedit.recurrencia || "mensual");
            setKmsRecurrencia(recordedit.kmsrecurrencia || 0);
            setServicioKms(recordedit.servicioKms || 0);


        } else {

            setNombre("");
            setActive(true);
            setUnidad("");
            setUnidadNombre("");
            setTarea("");
            setCondicionRecurrencia("tiempo");
            setRecurrencia( "mensual");
            setKmsRecurrencia(0);
            setServicioKms(0);

        }

        /* let auxtareas = [];
         await getTareasPersonalizadas(uid).then(resp => {
             resp.forEach(doc => {
                 let docdata = doc.data();
                 if (docdata.tarea) {
                     if (!tareas.find(t => t === docdata.tarea))
                         auxtareas.push(docdata.tarea);
                 }
             })
         });
         if (auxtareas.length > 0) {
             setTareas([...tareas, ...auxtareas])
         }*/
    }



    useEffect(() => {
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
        if (props.isOpen === true)
            initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit && recordedit.none !== true) {
            console.log("recordedit", recordedit);
          //  initValues();
        }

    }, [recordedit]);


    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);


    const handleSumbit = async (e) => {
        e.preventDefault();
        //setIsOpen(false);


        let tareaAux = tarea;
        if (tarea === "otro") {
            tareaAux = tareaNombre;

            await addTareasPersonalizadas({ uid, tarea: tareaAux }).then(resp => { }).catch(err => console.log(err));
        }

        let obj = {
            unidad,
            unidadNombre,
            unidadTipo,
            unidadSegmento,
            unidadKm,
            active,
            tarea: tareaAux,
            recurrente,
            proximaFecha,
            servicioKms,
            kmsrecurrencia,
            condicionRecurrencia,
            recurrencia,
            uid,
        }


        if (isAdmin && adminCurrentUser) {
            obj.uid = adminCurrentUser;
        }

        //validaciones de formulario

        if (isEdit && recordedit.id) {
            // alert(recordedit.id);
            obj.useredit = uidAltaEdit || uid;
            setIsLoading(true);
            updateMantenimiento(recordedit.id, obj).then(resp => {
                setIsLoading(false);
                setIsOpen(false);
                props.notify({ ...obj, id: recordedit.id });
                Swal.fire('Éxito', 'El proveedor se ha actualizado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                console.log(err);
                Swal.fire("Error", "Error de al actualizar el proveedor, comprueba tu conexión ", 'error');
            })
        } else if (!isEdit) {
            obj.useralta = uidAltaEdit || uid;
            setIsLoading(true);
            addMantenimiento(obj).then(resp => {

                setIsLoading(false);
                setIsOpen(false);
                props.notify({ ...obj, id: resp.id });
                Swal.fire('Éxito', 'El proveedor se ha registrado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                Swal.fire("Error", "Error de al registrar el proveedor, comprueba tu conexión ", 'error');
            });

            if(parseFloat(odometroActual)>0){
                let objupdate={kms:parseFloat(odometroActual)};
                updateActivo(unidad,objupdate).then(resp=>{console.log("kms actualizado")});
            }

        }


    }


    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Añadir Mantenimiento de Unidad" : `Editar Mantenimiento de Unidad `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div class="dataTable oneCol">

                            <div class="dataTitle"><p className="fixedP">Detalles de Unidad</p></div>
                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Unidad</p>
                                    <div class="fieldInput">
                                        <select name='tipo' onChange={(e) => setUnidad(e.target.value)} value={unidad} required >
                                            {
                                                activos.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.nombre}</option>
                                                })
                                            }
                                            <option key={"nounidad"} value={""}>{""}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div class="dataTable oneCol">

                            <div class="dataTitle"><p className="fixedP">Detalles de Tarea</p></div>
                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Tarea de Mantenimiento</p>
                                    <div class="fieldInput">
                                        <button type='button' class="boton plus-button" onClick={() => setIsOpenTarea(true)}></button>
                                        <select name='tipo' onChange={(e) => setTarea(e.target.value)} value={tarea} required >
                                            {
                                                tareas.map((item, index) => {
                                                    return <option key={index} value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {tarea === "otro" && <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Nombre de Tarea</p>
                                    <div class="fieldInput">
                                        <input type='text' required value={tareaNombre} onChange={e => setTareaNombre(e.target.value)}></input>
                                    </div>
                                </div>
                            </div>}
                            <div class="dataRow">
                                <p className="fixedP">Es recurrente?</p>
                                <div class="fieldInput">
                                    <select name='tipo' onChange={(e) => setRecurrente(e.target.value)} value={recurrente} required >
                                        <option key={"optsirecurrente"} value={"1"}>{"Si"}</option>
                                        <option key={"optnorecurrente"} value={"2"}>{"No"}</option>
                                    </select>
                                </div>
                            </div>
                            {
                                recurrente == "1" && <div class="dataRow">
                                    <p className="fixedP">Condición de Recurrencia</p>
                                    <div class="fieldInput">
                                        <select name='tipo' onChange={(e) => setCondicionRecurrencia(e.target.value)} value={condicionRecurrencia} required >
                                            <option key={"optcondiciont"} value={"tiempo"}>{"Tiempo"}</option>
                                            <option key={"optcondicionkms"} value={"km"}>{"Kms"}</option>
                                            <option key={"optcondicionambos"} value={"ambos"}>{"Ambos (lo que ocurra primero)"}</option>
                                        </select>
                                    </div>
                                </div>
                            }
                            {
                                (condicionRecurrencia == "tiempo" || condicionRecurrencia == "ambos") && <div class="dataRow">
                                    <p className="fixedP">Recurrencia</p>
                                    <div class="fieldInput">
                                        <select name='tipo' onChange={(e) => setRecurrencia(e.target.value)} value={recurrencia} required >
                                            <option key={"optcondiciont"} value={"semanal"}>{"Semanal"}</option>
                                            <option key={"optcondicionkms"} value={"mensual"}>{"Mensual"}</option>
                                            <option key={"optcondicionambos"} value={"anual"}>{"Anual"}</option>
                                            <option key={"optcondiciontiempofijo"} value={"fecha"}>{"Fecha"}</option>
                                        </select>
                                    </div>
                                </div>
                            }
                            {
                                recurrencia === "fecha" && <div class="dataRow">
                                    <p className="fixedP">Fecha</p>
                                    <div class="fieldInput">
                                        <DatePicker
                                            showTimeSelect
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            selected={proximaFecha} onChange={date => setProximaFecha(date)} />
                                    </div>
                                </div>
                            }
                            {
                                (condicionRecurrencia == "km" || condicionRecurrencia == "ambos") && <div class="dataRow">
                                    <p className="fixedP">Kms</p>
                                    <div class="fieldInput">
                                        <input type='number' style={{ width: '90%' }} required value={kmsrecurrencia} onChange={e => setKmsRecurrencia(parseFloat(e.target.value))}></input>
                                    </div>
                                </div>
                            }
                            {
                                (condicionRecurrencia == "km" || condicionRecurrencia == "ambos") && <div class="dataRow">
                                    <p className="fixedP">Odometro Actual</p>
                                    <div class="fieldInput">
                                        <input type='number' style={{ width: '90%' }} required value={odometroActual} onChange={e => setOdometroActual(e.target.value)}></input>
                                    </div>
                                </div>
                            }
                            {
                                (condicionRecurrencia == "km" || condicionRecurrencia == "ambos") && <div class="dataRow">
                                    <p className="fixedP">Proximo serivicio (kms)</p>
                                    <div class="fieldInput">
                                        <input disabled type='number' style={{ width: '90%' }} required value={servicioKms} onChange={e => console.log("")}></input>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>



                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>


        <ModalAddTareaMantenimiento
            currenteditrecrod={null}
            currentDate={new Date()}
            isEdit={isEditTarea}
            isOpen={isOpenTarea}
            uid={uid ? uid : null}
            uidAltaEdit={uidAltaEdit}
            adminCurrentUser={adminCurrentUser || null}
            isAdmin
            notify={() => { consultaTareas() }}
            setLoading={value => setIsLoading(value)}
            toggle={() => setIsOpenTarea(false)} />

    </Modal >

}
export default ModalCreateEdit;