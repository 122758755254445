import { firebase } from '../configs/firebase';

export const getUbicacionesById = (id) => {
    var db = firebase.firestore();
    return db.collection("ubicaciones_user").doc(id).get();
}

export const getUbicaciones = (uid) => {
    var db = firebase.firestore();
    return db.collection("ubicaciones_user").where("uid", "==", uid).get();
}

export const addUbicacion = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('ubicaciones_user').add(dataaux);
}

export const updateUbicacion = (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date()};
    var db = firebase.firestore();
    return db.collection('ubicaciones_user').doc(id).update(dataaux);
}

export const deleteUbicacion = (id) => {
    var db = firebase.firestore();
    return db.collection('ubicaciones_user').doc(id).update({uid:"deleted"});
}

