import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input';
import Swal from 'sweetalert2';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addIngreso, updateIngreso, addDestino, getAllDestinosByUser } from '../../../controllers/ingresos';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { addOperador, updateOperador } from '../../../controllers/operadores'
import { updateUbicacion } from '../../../controllers/ubicaciones';
import { updateUserOperadoresTimeByUid } from '../../../controllers/usuarios';
import axios from 'axios';
import InputMask from 'react-input-mask';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';


const ModalCreateEdit = (props) => {
    //constantes

    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);

    //estados propios del formulario
    const [nombre, setNombre] = useState("");

    const [prioridad, setPrioridad] = useState("");


    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);


    const initValues = () => {

        if (isEdit && recordedit != null) {
            setNombre(recordedit.nombre || "");


        } else {

            setNombre("");

        }
    }


    useEffect(() => {
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit) {
            initValues();
        }

    }, [recordedit]);


    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();
        //setIsOpen(false);
        let obj = {
            nombre,
            uid,
        }

        // console.log(obj);
        //  return;
        if (isAdmin && adminCurrentUser) {
            obj.uid = adminCurrentUser;
        }

        //validaciones de formulario
        // console.log(obj);

    }
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);


    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Encuesta" : `Encuesta. `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <ul style={{ width: '100%',marginBottom:"10px" }} className="list-group">
                        <li style={{ width: '100%' }} className="list-group-item list-group-item-info" >Para nosotros en TransportPlus es muy importante tu opinión por tal motivo nos gustaria nos apoyaras contestando la siguiente encuesta a fin de poder brindarles un mejor servicio</li>
                    </ul>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                        <div class="dataTable oneCol">

                            <div class="dataTitle"><p className="fixedP">Formulario</p></div>
                            <div class="dataBox">
                                <div class="dataRow">
                                    <ul style={{ width: '35%' }} className="list-group">
                                        <li style={{ width: '100%' }} className="list-group-item list-group-item-success" >Qué caracteriscticas te gustan de TransportPlus</li>

                                    </ul>
                                    <div class="fieldInput">
                                        <textarea type="text" name='cliente' value={nombre} onChange={e => setNombre(e.target.value)} required />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <ul style={{ width: '35%' }} className="list-group">
                                        <li style={{ width: '100%' }} className="list-group-item list-group-item-success" >Qué cambiarias de TransportPlus</li>

                                    </ul>
                                    <div class="fieldInput">
                                        <textarea type="text" name='cliente' value={nombre} onChange={e => setNombre(e.target.value)} required />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <ul style={{ width: '35%' }} className="list-group">
                                        <li style={{ width: '100%' }} className="list-group-item list-group-item-success" >Qué te gustaria ver en TransportPlus</li>

                                    </ul>
                                    <div class="fieldInput">
                                        <textarea type="text" name='cliente' value={nombre} onChange={e => setNombre(e.target.value)} required />
                                    </div>
                                </div>

                                <div class="dataRow">
                                    <ul style={{ width: '35%' }} className="list-group">
                                        <li style={{ width: '100%' }} className="list-group-item list-group-item-success" >Cual de las siguientes caracteriscticas te interesária con mayor prioridad</li>

                                    </ul>
                                    <div class="fieldInput">
                                        <FormControl component="fieldset">
                                            <RadioGroup row aria-label="periodo" name="periodo" value={prioridad} onChange={e => setPrioridad(e.target.value)}>
                                                <FormControlLabel value="GPS" control={<Radio />} label="Seguimiento por GPS" />
                                                <FormControlLabel value="mantenimiento" control={<Radio />} label="Módulo de Mantemimiento" />
                                                <FormControlLabel value="otra" control={<Radio />} label="Otra" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>



                            </div>
                        </div>


                    </div>



                    <hr></hr>

                </ModalBody>
                <ModalFooter>

                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalCreateEdit;