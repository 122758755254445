import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
//import { addIngreso, updateIngreso, addDestino, getAllDestinosByUser } from '../../../controllers/ingresos';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { nextFolioServicio, addServicio, addMantenimiento, updateMantenimiento, deleteMantenimiento, addTareasPersonalizadas, getServiciosByPlan } from '../../../controllers/proveedor';
import { getViajeRepo } from '../../../controllers/viajes';
import { HotTable, HotColumn } from '@handsontable/react';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { registerLanguageDictionary } from 'handsontable/i18n';
import esMX from 'handsontable/i18n/languages/es-MX';
import 'react-phone-input-2/lib/style.css'
import { addUbicacion } from '../../../controllers/ubicaciones';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { DateRender,ActionRender } from './ActionsRender';

const ModalCreateEdit = (props) => {

    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);

    const [servicios, setServicios] = useState([]);
    const [resumen, setResumen] = useState("");

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);
    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);


    const initValues = async () => {
        let auxservicios = [];


        if (isEdit && recordedit != null) {
            // addServicio
            getServiciosByPlan(recordedit.id).then(resp => {
                resp.forEach(doc => {
                    let o = doc.data();
                    o.id = doc.id;
                    auxservicios.push(o);
                })

                setServicios(auxservicios);
            })
        }

    }



    useEffect(() => {
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        if (props.currenteditrecrod)
            setRecordEdit(props.currenteditrecrod);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit) {
            initValues();
        }

    }, [recordedit]);


    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
        let resumenaux = ``;
        if (recordedit && recordedit.unidadNombre) {
            resumenaux += `Unidad:${recordedit.unidadNombre},  \n`;
        }
        if (recordedit && recordedit.tarea) {
            resumenaux += `Tarea:${recordedit.tarea}  \n`;
        }
        setResumen(resumenaux);

        console.log("recorededit", recordedit);
    }, [isOpen]);


    const handleSumbit = async (e) => {
        e.preventDefault();
        //setIsOpen(false);
        let obj = {

            uid,
        }


        if (isAdmin && adminCurrentUser) {
            obj.uid = adminCurrentUser;
        }

        //validaciones de formulario



    }

    const agregarServicio = async () => {

        let idUserViaje = null;
        await getViajeRepo(uid).then(resp => {
            if (resp.size > 0) {
                //console.log("resp",resp.docs[0].data());
                idUserViaje = resp.docs[0].id;
            }
        });
        if (idUserViaje) {
            let okfolio = false;
            await nextFolioServicio(idUserViaje).then(resp => {
                okfolio = true;
            }).catch(err => { console.log("hjerre") });
            if (!okfolio) {
                alert("error al generar el servicio");
                return;
            }
            let folio = null;

            await getViajeRepo(uid).then(resp => {
                if (resp.size > 0) {
                    //console.log("resp",resp.docs[0].data());
                    let dataus = resp.docs[0].data();
                    folio = dataus.fservicio || null;
                }
            });
            if (!folio) {
                alert("error al generar el servicio");
                return;
            }



            let objservicio = {
                planId: recordedit ? recordedit.id : null,
                plan: recordedit,
                fecha: new Date(),
                folio,
                folios: `S-${folio || ""}`,
                uid
            }
            await addServicio(objservicio).then(resp => {
                console.log("agregado", resp.id);
                initValues();
            }).catch(err => console.log(err));
        }

    }

    const handleVerServicio=id=>{
        //console.log(id);
        let dtime = new Date().getTime();
        const win = window.open('/servicio/' + id + "?d=" + dtime, "_blank");
        win.focus();
    }

    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Mantenimiento Preventivo" : `Mantenimiento Preventivo`}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    {resumen && <div>{`${resumen}`}</div>}
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div class="dataTable oneCol">

                            <div class="dataTitle"><p className="fixedP">Histórico de Servicios</p></div>
                            <div class="dataBox">
                              <HotTable settings={{
                                    colHeaders: true,
                                    rowHeaders: true,
                                    autoRowSize: false,
                                    columnSorting: true,
                                    dropdownMenu: ['filter_by_condition',
                                        'filter_by_value',
                                        'filter_action_bar'],
                                    filters: true,
                                    manualColumnResize: true,
                                    colWidths: [180,180],
                                    //para ancho total                                    
                                    //  minSpareRows: 1,          // almenos una fila
                                    // autoColumnSize: true,     // 
                                    //  stretchH: 'all',          // 
                                    //disableVisualSelection: true,
                                    licenseKey: "non-commercial-and-evaluation",
                                    language: "es-MX"
                                }} data={servicios} colHeaders={true} rowHeaders={false} width="100%" height="200" >

                                    <HotColumn className="htCenter htMiddle" readOnly title="Servicio" data="folios" />
                                    <HotColumn className="htCenter htMiddle mycustomcolumn" render readOnly title="Fecha" data="fecha" >
                                        <DateRender className="htCenter htMiddle" hot-renderer></DateRender>
                                    </HotColumn>
                                    <HotColumn className="htCenter htMiddle mycustomcolumn" render readOnly title="-" data="id" >
                                        <ActionRender handleVerServicio={handleVerServicio} className="htCenter htMiddle" hot-renderer></ActionRender>
                                    </HotColumn>


                                </HotTable>
                            </div>
                        </div>

                    </div>




                </ModalBody>
                <ModalFooter>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>

                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalCreateEdit;