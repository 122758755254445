import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getUserByUid } from '../../../controllers/usuarios';
import { getClienteByID, getClientes } from '../../../controllers/clientes';
import { getUbicaciones } from '../../../controllers/ubicaciones';
import { getFacturaArchivos } from '../../../controllers/facturama';
import { getCfdiById } from '../../../controllers/innvoices';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import xml2js from 'xml2js';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyInput from 'react-currency-input';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
    },
    paperrootfull: {
        width: '100%',
        margin: '5px',
        padding: '30px',
        display: 'flex'
    },

    paperroot: {
        width: '33%',
        display: 'flex'
    },
    paper: {
        minHeight: 80,
        height: 'auto',
        width: '100%',
    },
    paperscontainer: {
        // width: '100%'
        //overflow: 'hidden'
        flex: 1
    },
    title: {
        height: 30,
        backgroundColor: '#dcdcdc',
        paddingLeft: 10,
        paddingTop: 5,
    },
    label: {
        fontSize: '12px',
        paddingLeft: 3,
        paddingTop: 7,
        width: '38%',
    },

    input: {
        border: '1px solid var(--lines-clr)',
        borderRadius: '5px',
        width: '90%'
    },
    inputbig: {
        width: '100%',
        height: 30,
        marginTop: 4,
        marginRight: 2
    },
    inputsmall: {
        display: 'inline-block',
        float: 'right',
        width: '50%',
        height: 30,
        marginTop: 4,
        marginRight: 2
    },
    inputwithextra: {
        display: 'inline-block',
        float: 'right',
        width: '30%',
        height: 30,
        marginRight: 2
    },
    inputdate: {
        width: '99%',
        marginTop: 4,
        marginLeft: 0,
        marginRight: 2,
        height: '30px',
    }
}));
const ModalCreateEdit = (props) => {
    const classes = useStyles();
    //constantes
    const conceptos = [
        { id: "1", clave: "01", descripcion: "Comprobante emitido con errores con relación" },
        { id: "2", clave: "02", descripcion: "Comprobante emitido con errores sin relación" },
        { id: "3", clave: "03", descripcion: "No se llevó a cabo la operación" },
        { id: "4", clave: "04", descripcion: "Operación nominativa relacionada en una factura global" },

    ];


    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);
    const [idViaje, setIdViaje] = useState(null);

    const [chekingCSD, setCheckinCSD] = useState(true);

    const [nviaje, setNviaje] = useState("");
    const [ncarta, setNcarta] = useState("");

    const [clienteData, setClietneData] = useState(null);
    const [isIVA16, setIsIVA16] = useState(true);
    const [facturaArchivos, setFacturaArchivos] = useState(null);
    const [isFacuturaCancelada, setIsFacturaCancelada] = useState(false);
    const [ivatasa, setIvaTasa] = useState("16");
    const [retencion, setRetencion] = useState("0");
    const [razonesSociales, setRazonesSociales] = useState([]);
    const [rfc, setRfc] = useState("");

    const [referencia, setReferencia] = useState("");
    const [comentarios, setComentarios] = useState("");

    const [isViaje, setIsVIaje] = useState("0");
    const [usoCfdi, setUsoCfdi] = useState("P01 - Por definir");
    const [claveConcepto, setClaveConcepto] = useState("78101800");

    const [motivo, setMotivo] = useState("02");
    const [UIDRelacion, setUidRelacion] = useState("");

    const [allowCancel, setAllowCancel] = useState(true);

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    useEffect(() => {
        setIsOpen(props.isOpen);



        if (localStorage.getItem('deny')) {
            let auxdeny = localStorage.getItem('deny').split(":");
            if (auxdeny.find(e => e === "cancelarcfdi")) {
                setAllowCancel(false);
            }


        }
        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);

    useEffect(() => {
        if (user && user.razones) {

            let razonesasignar = [];
            user.razones.forEach(r => {
                if (!r.inactivo) {
                    razonesasignar.push(r);
                }
            })
            setRazonesSociales(razonesasignar);

            if (user.razones.length > 0 && user.razones[0].Rfc) {
                setRfc(user.razones[0].Rfc);
            }
        }
    }, [user]);

    const checkCSD = async () => {
        console.log("cheking csd", uid);
        if (uid) {
            getUserByUid(uid).then(resp => {
                let obuser = null;
                resp.forEach(document => {
                    obuser = { ...document.data(), id: document.id }

                });
                setUser(obuser);
                setCheckinCSD(false);
                //console.log("datos usuario",resp.docs[0].data());
                if (resp.docs.length > 0 && resp.docs[0].data().csd) {
                    initValues();
                } else {
                    Swal.fire("No CSD", "No se han cargado los CSD para esta cuenta, da de alta primero tus credenciales de facturacion en la seccion de dashboard -> configuraciones ", 'warning');
                    setIsLoading(false);
                    setIsOpen(false);
                }
            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar CSD, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });
        } else {
            Swal.fire("Error", "Error sesion terminada", 'error');
            setIsLoading(false);
            setIsOpen(false);
        }

    }



    const initValues = async () => {
        setIsLoading(true);

        if (idViaje != null) {
            console.log("getting cfdi by id", idViaje);
            await getCfdiById(idViaje).then(document => {
                console.log("llegaron datos");
                let o = document.data();
                o.id = document.id;
                o.key = document.id;
                setRecordEdit(o);
                setIsLoading(false);
            }).catch(err => { console.log(err); setIsLoading(false); });
        }

        setIsLoading(false);
    }

    useEffect(() => {
        console.log("record edit", recordedit);

    }, [recordedit]);

    useEffect(() => {

        setCheckinCSD(true);
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);

        //  initValues();

        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);


        setUidRelacion("");
        setMotivo("02");

        setRecordEdit(props.currenteditrecrod);
        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            checkCSD();
            console.log("recordedit", recordedit)
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();
        console.log("submiting");

        setIsLoading(true);
        let tipo = "viaje";
        if (recordedit.cfdisolo) {
            tipo = "cfdisolo";
        }
        let datatopost = {
            id: recordedit.id,
            motivo,
            UIDRelacion,
            tipo,
            uid
        }
        let rutaPost = "https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/";
        if (rfc === "EKU9003173C9") {
            rutaPost = "https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/";
            //rutaPost = "https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/";
        }

        // rutaPost="http://localhost:5000/unit-testing-d4fe7/us-central1/app/api/"
        //console.log("datatopost",datatopost);
        // return;
        await axios.post(`${rutaPost}cancelarcfdinuevo`, datatopost).then(resp => {
            console.log("cancelado ok");
            if (resp.data.codigo == "000") {
                Swal.fire("Exito", `Se cancelo la factura`, 'success');
            } else {
                Swal.fire("No se pudo cancelar", `${resp.data}`, 'warning');
            }

            //console.log("respuesta", resp);


        }).catch(err => {
            console.log("error", err.toString());
            if (err.toString().includes("Network Error")) {
                Swal.fire("Error", "Error de conexión, no fue posible conectar con el servidor, revisa tu conexión", 'error');
            } else {
                Swal.fire("Error", "No se pudo cancelar la factura", 'error');
            }
        });
    }

    const asyncparser = async (obj) => {
        if (obj) {
            let xmlstring = obj.cfdixml;
            //let fixedXml=xmlstring.replace('cfdi:', '');
            let fixedXml = xmlstring.split('cfdi:').join('');
            fixedXml = xmlstring.split('$').join('obj');

            let response = null;
            await xml2js.parseString(fixedXml, async (err, result) => {
                if (err) {
                    console.log(err);
                }
                else {
                    // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                    // let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                    console.log("primer xml", result);
                    response = result;
                    // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                }

            });

            // console.log("response xml", response);
            return response;
        } else {
            return null;
        }

    }


    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {facturaArchivos ? "Cancelar CFDI" : `Cancelar CFDI`}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <LoadingOverlay
                        active={chekingCSD}
                        spinner
                        text='Comprobando CSD, espere ...'
                    >


                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div class="dataTable oneColmargin">

                                    <div class="dataTitle"><p className="fixedP">Cancelación de CFDI</p></div>

                                    {allowCancel ? <div class="dataBox">



                                        <div class="dataRow">
                                            <p className="fixedP">Motivo</p>
                                            <div class="fieldInput">
                                                <select name='rfc' onChange={(e) => setMotivo(e.target.value)} value={motivo} required >
                                                    {
                                                        conceptos.map((item, index) => {
                                                            return <option key={index} value={`${item.clave}`} >{`${item.clave} - ${item.descripcion}`}</option>
                                                        })
                                                    }
                                                </select>

                                            </div>
                                        </div>
                                        {
                                            motivo === "01" && <div class="dataRow">
                                                <p className="fixedP">Folio Fiscal Sustitución</p>
                                                <div class="fieldInput">
                                                    <input type='text' value={UIDRelacion} onChange={e => setUidRelacion(e.target.value)} />
                                                </div>
                                            </div>
                                        }

                                    </div> : <div>
                                        <ul class="list-group">

                                            <li class="list-group-item list-group-item-danger" style={{}}>La cuenta no tiene permitido cancelar CFDI</li>


                                        </ul>
                                    </div>}
                                </div>



                            </div>

                        </div>

                    </LoadingOverlay>
                </ModalBody>
                <ModalFooter>
                    {!facturaArchivos && <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>}
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    {allowCancel && <button type="submit" className="boton secBtn"> Aceptar</button>}
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >
}
export default ModalCreateEdit;