import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const requiereSubliente = ['HptPbvVxYYUjUzp7Nt3TtmyAt0x1'];

export default class Download extends React.Component {
    constructor(props) {
        super();
        let { data } = props;
        let { uid } = props;
        this.state = {
            dataSet1: data,
            uid
        }
        //   console.log(this.state);

    }

    componentDidUpdate = (prevProps) => {

    }

    render() {

        if (this.state.uid && requiereSubliente.find(rs => rs === this.state.uid)) {
            return (
                <ExcelFile element={<div style={{ marginLeft: '5px' }}><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>}>
                    <ExcelSheet data={this.state.dataSet1} name="Factuas">
                        <ExcelColumn label="Folio" value="nfactura" />
                        <ExcelColumn label="RFC Emisor" value="rfcTimbrado" />
                        <ExcelColumn label="Cliente" value="cliente" />
                        <ExcelColumn label="Subcliente" value="subcliente" />
                        <ExcelColumn label="Referencia" value="referencia" />
                        <ExcelColumn label="Carta Porte" value="carta_porte" />
                        <ExcelColumn label="Tipo de viaje" value="tipoviaje" />
                        <ExcelColumn label="Segmento de Negocio" value="segmentoNegocio" />
                        <ExcelColumn label="Operador" value="operador" />
                        <ExcelColumn label="Unidad" value="unidad" />
                        <ExcelColumn label="Origen" value="origen" />
                        <ExcelColumn label="Destino" value="destino" />
                        <ExcelColumn label="Mercancia" value="primeraMercancia" />
                        <ExcelColumn label="Cantidad" value="primeraMercanciaCantidad" />
                        <ExcelColumn label="Fecha" value="fechaformat2" />
                        <ExcelColumn label="Estatus" value="status" />
                        <ExcelColumn label="Folio Fiscal" value="foliouuid" />
                        <ExcelColumn label="Dist. Base" value="distBase_km" />
                        <ExcelColumn label="Dist. Vacio" value="distVacio_km" />
                        <ExcelColumn label="Dist. Total" value="distTotales_km" />
                    </ExcelSheet>

                </ExcelFile>
            );
        } else {
            return (
                <ExcelFile element={<div style={{ marginLeft: '5px' }}><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>}>
                    <ExcelSheet data={this.state.dataSet1} name="Factuas">
                        <ExcelColumn label="Folio" value="nfactura" />
                        <ExcelColumn label="RFC Emisor" value="rfcTimbrado" />
                        <ExcelColumn label="Cliente" value="cliente" />
                        <ExcelColumn label="Referencia" value="referencia" />
                        <ExcelColumn label="Carta Porte" value="carta_porte" />
                        <ExcelColumn label="Tipo de viaje" value="tipoviaje" />
                        <ExcelColumn label="Segmento de Negocio" value="segmentoNegocio" />
                        <ExcelColumn label="Operador" value="operador" />
                        <ExcelColumn label="Unidad" value="unidad" />
                        <ExcelColumn label="Origen" value="origen" />
                        <ExcelColumn label="Destino" value="destino" />
                        <ExcelColumn label="Mercancia" value="primeraMercancia" />
                        <ExcelColumn label="Cantidad" value="primeraMercanciaCantidad" />
                        <ExcelColumn label="Fecha" value="fechaformat2" />
                        <ExcelColumn label="Estatus" value="status" />
                        <ExcelColumn label="Folio Fiscal" value="foliouuid" />
                        <ExcelColumn label="Dist. Base" value="distBase_km" />
                        <ExcelColumn label="Dist. Vacio" value="distVacio_km" />
                        <ExcelColumn label="Dist. Total" value="distTotales_km" />
                    </ExcelSheet>

                </ExcelFile>
            );
        }

    }
}
