import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input';
import Swal from 'sweetalert2';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addIngreso, updateIngreso, addDestino, getAllDestinosByUser } from '../../../controllers/ingresos';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { addCPAlone, updateCPAlone } from '../../../controllers/cpalone';
import { getViajeRepo, getClientes, getActivos, getUbicaciones } from '../../../controllers/viajes';
import { getUserByUid } from '../../../controllers/usuarios';
import { updateUbicacion } from '../../../controllers/ubicaciones';


const ModalCreateEdit = (props) => {
    //constantes
    const CATALOGOS_TIPO_PAGO = ['Manual', 'Por Porcentaje', 'Por Kilómetro', 'Otro'];
    const CATALOGOS_TIPO_LICENCIA = ['A', 'B', 'C', 'D', 'E', 'F'];
    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);

    const [user_viaje, setUserViaje] = useState(null);
    const [usuarioobj, setUsuarioObj] = useState(null);
    const [precioDolar, setPrecioDolar] = useState(0.0);
    //estados propios del formulario
    const [ncarta, setNcarta] = useState("");
    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState("");
    const [clienteId, setClienteId] = useState("");
    const [segmentoNegocio, setSegmentoNegocio] = useState("");
    const [unidades, setUnidades] = useState([]);
    const [remolques, setRemolques] = useState([]);
    const [remolque1, setRemolque1] = useState("");
    const [remolque2, setRemolque2] = useState("");
    const [unidad, setUnidad] = useState("");
    const [unidadId, setUnidadId] = useState(null);
    const [localidades, setLocalidades] = useState([]);

    const [origen, setOrigen] = useState("");
    const [destino, setDestino] = useState("");
    const [origenId, setOrigenId] = useState("");
    const [destinoId, setDestinoId] = useState("");
    const [origenCiudad, setOrigenCiudad] = useState("");
    const [origenEstado, setOrigenEstado] = useState("");
    const [origenEstadoShort, setOrigenEstadoShort] = useState("");
    const [destinoCiudad, setDestinoCiudad] = useState("");
    const [destinoEstado, setDestinoEstado] = useState("");
    const [destinoEstadoShort, setDestinoEstadoShort] = useState("");

    const [distBase, setDistBase] = useState(0);
    const [distBase_km, setDistBaseKM] = useState(0);
    const [distBase_mi, setDistBaseMI] = useState(0);

    const [distKM, setdistKM] = useState(true);
    const [peso, setPeso] = useState(0);
    const [isPesoKG, setIsPesoKG] = useState(true);
    const [descripcion,setDescripcion]=useState("");

    useEffect(() => {
        if (distKM) {
            setDistBaseKM(parseFloat(distBase));
          
            const conversor = 0.621371;
            const db = distBase * conversor;
            setDistBaseMI(parseFloat(db.toFixed(2)));

        } else {
            setDistBaseMI(parseFloat(distBase));
            const conversor = 1.60934;
            const db = distBase * conversor;
            setDistBaseKM(parseFloat(db.toFixed(2)));
        }

    }, [distKM,distBase]);
    
    useEffect(() => {
        if (origen) {
            let origenOBJ = localidades.find(element => element.name == origen);
            if (origenOBJ) {
                setOrigenId(origenOBJ.id);
                setOrigenCiudad(origenOBJ.localidad);
                setOrigenEstado(origenOBJ.estado);
                setOrigenEstadoShort(origenOBJ.estado_short);
            }
        }
        if (destino) {
            let destinoOBJ = localidades.find(element => element.name == "" + destino);
            if (destinoOBJ) {
                console.log("se encontro destino", destino);
                setDestinoId(destinoOBJ.id);
                setDestinoCiudad(destinoOBJ.localidad);
                setDestinoEstado(destinoOBJ.estado);
                setDestinoEstadoShort(destinoOBJ.estado_short);
            }
        }

    }, [origen, destino]);
    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);

    useEffect(() => {
        if (uid) {
            getUserByUid(uid).then(snapshot => {
                let obuser = null;
                snapshot.forEach(document => {
                    obuser = { ...document.data(), id: document.id }
                    if (!obuser.segmento_negocio) {
                        obuser.segmento_negocio = [];
                    }

                    if (obuser.dolarprice) {
                        setPrecioDolar(obuser.dolarprice);
                    }
                });
                setUsuarioObj(obuser);
            }).catch(err => { console.log(err) });
        }
    }, [uid])

    const initValues = async () => {

        if (isEdit && recordedit != null) {
            setNcarta(recordedit.ncarta || "");
            setCliente(recordedit.cliente || "");
            setClienteId(recordedit.clienteId || "");
            setSegmentoNegocio(recordedit.segmentoNegocio || "");
            setPrecioDolar(recordedit.precioDolar || 0.0);

        } else {
            setNcarta("");
            setCliente("");
            setClienteId("");
            setSegmentoNegocio("");
            setPrecioDolar(0.0);

        }
        await getClientes(uid).then(snapshot => {
            let aux = [];
            snapshot.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                aux.push(o);
            });
            let toset = aux.map((item, index) => {
                let ob = { ...item, name: item.nombre + " - " + item.estado_short, value: item.id, id: "user" + index };
                return ob;
            });
            setClientes(toset);


        }).catch(err => { });
        getViajeRepoAction();
        getActivos(uid).then(snapshot => {
            let aux = [];
            snapshot.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                aux.push(o);
            });
            let toset = aux.map((item, index) => {
                let ob = { ...item, name: item.nombre, value: item.id, id: item.id };
                return ob;
            });
            let remolquess = [];
            let otros = [];
            let unidadestoset = [];
            toset.forEach(element => {
                // String.tolower
                if (element.tipo.toLowerCase() === "remolque") {
                    remolquess.push(element);
                } else if (element.tipo.toLowerCase() === "unidad") {
                    unidadestoset.push(element);
                } else {
                    otros.push(element)
                }
            });

            setUnidades(unidadestoset);
            if(!isEdit && unidadestoset.length>0){
                setUnidadId(unidadestoset[0].id);
            }
            setRemolques(remolquess);


        }).catch(err => { console.log(err) });
        getUbicaciones(uid).then(snapshot => {
            let aux = [];
            snapshot.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                aux.push(o);
            });
            let toset = aux.map((item, index) => {
                let num = item.numero || "";
                let idubica = item.nombre.trim() + " - " + item.calle.trim() + " " + num.trim();
                let ob = { ...item, name: idubica, value: idubica };
                return ob;
            });
            setLocalidades(toset);

        }).catch(err => { });

    }

    useEffect(() => {
        if (unidadId) {
            let uniobj = unidades.find(element => element.id === unidadId);
            if (uniobj) {
                setUnidad(uniobj.nombre);
            }
        }
    }, [unidadId]);

    useEffect(() => {
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
      //  initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit) {
            initValues();
        }

    }, [recordedit]);


    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();
        console.log("submiting");
        //setIsOpen(false);
        let segmentoactual = segmentoNegocio;

        if (segmentoactual === "" && usuarioobj && usuarioobj.segmento_negocio && usuarioobj.segmento_negocio.length > 0) {
            segmentoactual = usuarioobj.segmento_negocio[0].value;
        }

        let obj = {
            ncarta,
            segmentoNegocio: segmentoactual,
            precioDolar,
            cliente,
            clienteId,
            distBase,
            distBase_km,
            distBase_mi,
            distKM,
            descripcion,
            origen,
            origenCiudad,
            origenEstado,
            origenEstadoShort,
            origenId,
            destino,
            destinoCiudad,
            destinoEstado,
            destinoEstadoShort,
            destinoId,
            peso,
            isPesoKG,
            remolque1,
            remolque2,
            unidad,
            unidadId,
            uid,
        }
    

        if (isAdmin && adminCurrentUser) {
            obj.uid = adminCurrentUser;
        }

        //validaciones de formulario
        // console.log(obj);
        if (isEdit && recordedit.id) {
            // alert(recordedit.id);
            obj.useredit = uidAltaEdit || uid;
            setIsLoading(true);
            updateCPAlone(recordedit.id, obj).then(resp => {
                setIsLoading(false);
                setIsOpen(false);
                props.notify({ ...obj, id: recordedit.id });
                Swal.fire('Éxito', 'La carta porte se ha actualizado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                console.log(err);
                Swal.fire("Error", "Error de al actualizar la carta porte, comprueba tu conexión ", 'error');
            })
        } else if (!isEdit) {
            obj.useralta = uidAltaEdit || uid;
            setIsLoading(true);
            addCPAlone(obj).then(resp => {
                setIsLoading(false);
                setIsOpen(false);
                props.notify();
                Swal.fire('Éxito', 'La carta porte se ha registrado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                Swal.fire("Error", "Error de al registrar carta porte, comprueba tu conexión ", 'error');
            })
        }
    }
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);


    useEffect(() => {
        if (user_viaje) {
            // console.log("user viaje", user_viaje);
            let stringfolio = "";
            let stringfoliocp = "";
            if (user_viaje.cfolio < 10) {
                stringfoliocp = "000"
            } else if (user_viaje.cfolio < 100) {
                stringfoliocp = "00"
            } else if (user_viaje.cfolio < 1000) {
                stringfoliocp = "0"
            } else {
                stringfoliocp = ""
            }
            if (!isEdit)
                setNcarta(user_viaje.prefixc.toUpperCase() + "-" + stringfolio + user_viaje.cfolio);




        }
    }, [user_viaje]);

    const getViajeRepoAction = () => {
        if (uid) {
            if (uid) {
                getViajeRepo(uid).then(resp => {

                    if (resp.size > 0) {
                        //console.log("resp",resp.docs[0].data());
                        let objuserviaje = { id: resp.docs[0].id, ...resp.docs[0].data() }
                        setUserViaje(objuserviaje);
                    }

                }).catch(err => {
                    console.log("viajerepo error", err);
                });
            }
        }
    }

    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Añadir carta porte" : `Editar carta porte`}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>

                    <table className="tablenostriped" style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td className="modalform_label">  <label> <span className="red">*</span>No. CP</label> </td>
                                <td className="modalform_field">
                                    <input disabled type="text" style={{ backgroundColor: '#afe8a1' }} value={ncarta} onChange={e => setNcarta(e.target.value)} className="form-control" />
                                </td>
                                <td className="modalform_label"><label>Cliente</label></td>
                                <td className="modalform_field">
                                    <Autocomplete
                                        id="combo-box-demo0c"
                                        options={clientes}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option, value) => option.name === value.name}

                                        style={{ height: '30px' }}
                                        onChange={e => setCliente(e.target.innerText)}
                                        renderInput={(params) => <TextField className="reducedselect" onChange={e => setCliente(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                    />
                                </td>
                                <td className="modalform_label">Segmento Negocio</td>
                                <td className="modalform_field">
                                    <select className={"form-control"} name='segmento' onChange={(e) => setSegmentoNegocio(e.target.value)} value={segmentoNegocio} required >
                                        {
                                            usuarioobj && usuarioobj.segmento_negocio.map((item, index) => {

                                                return segmentoNegocio == item.value ?
                                                    <option key={index} value={item.value} selected>{item.value}</option> :
                                                    <option key={index} value={item.value}>{item.value}</option>

                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="modalform_label">  <label> <span className="red">*</span>Origen</label> </td>
                                <td className="modalform_field">
                                    <Autocomplete
                                        id="combo-box-demo0c"
                                        options={localidades}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option, value) => option.name === value.name}

                                        style={{ height: '30px' }}
                                        onChange={e => setOrigen(e.target.innerText)}
                                        renderInput={(params) => <TextField className="reducedselect" onChange={e => setOrigen(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                    />
                                </td>
                                <td className="modalform_label">  <label> <span className="red">*</span>Destino</label> </td>
                                <td className="modalform_field">
                                    <Autocomplete
                                        id="combo-box-demo0c"
                                        options={localidades}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option, value) => option.name === value.name}

                                        style={{ height: '30px' }}
                                        onChange={e => setDestino(e.target.innerText)}
                                        renderInput={(params) => <TextField className="reducedselect" onChange={e => setDestino(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                    />
                                </td>
                                <td className="modalform_label"> <label><Typography component="div">
                                    <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item > mi.</Grid>
                                        <Grid item>
                                            <AntSwitch checked={distKM} onChange={() => setdistKM(!distKM)} name="checkedC" />
                                        </Grid>
                                        <Grid item >km.</Grid>
                                    </Grid>
                                </Typography></label>
                                </td>
                                <td className="modalform_field">
                                    <input type="number" name='segmento' className={"form-control "} value={distBase} onChange={e => setDistBase(e.target.value)} />
                                </td>

                            </tr>
                            <tr>
                                <td className="modalform_label">  <label> <span className="red">*</span>Unidad</label> </td>
                                <td className="modalform_field">
                                    <select className={"form-control "} name='unidad' onChange={(e) => setUnidadId(e.target.value)} value={unidadId} required >
                                        {
                                            unidades.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.nombre}</option>
                                            })
                                        }
                                    </select>
                                </td>
                                <td className="modalform_label">  <label> Remolque</label> </td>
                                <td className="modalform_field">
                                    <Autocomplete
                                        id="combo-box-demo0c"
                                        options={remolques}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option, value) => option.name === value.name}

                                        style={{ height: '30px' }}
                                        onChange={e => setRemolque1(e.target.innerText)}
                                        renderInput={(params) => <TextField className="reducedselect" onChange={e => setRemolque1(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                    />
                                </td>
                                <td className="modalform_label">  <label> Remolque 2</label> </td>
                                <td className="modalform_field">
                                    <Autocomplete
                                        id="combo-box-demo0c"
                                        options={remolques}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option, value) => option.name === value.name}

                                        style={{ height: '30px' }}
                                        onChange={e => setRemolque2(e.target.innerText)}
                                        renderInput={(params) => <TextField className="reducedselect" onChange={e => setRemolque2(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                    />
                                </td>
                            </tr>
                            <tr>
                            <td className="modalform_label">  <label> Descripción</label>

                            </td>
                            <td className="modalform_field">
                            <input type="text" name='descripcion' className={"form-control "} value={descripcion} onChange={e => setDescripcion(e.target.value)} />
                             
                            </td>
                            <td className="modalform_label"> <label><Typography component="div">
                                    <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item > lb.</Grid>
                                        <Grid item>
                                            <AntSwitch checked={isPesoKG} onChange={() => setIsPesoKG(!isPesoKG)}  name="checkedC" />
                                        </Grid>
                                        <Grid item >kg.</Grid>
                                    </Grid>
                                </Typography></label>
                                </td>
                                <td className="modalform_field">
                                    <input type="number" name='segmento' className={"form-control "} value={peso} onChange={e => setPeso(e.target.value)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalCreateEdit;