import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { getUserByUid } from '../../../controllers/usuarios';
import { getViajeById } from '../../../controllers/viajes';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



const ModalCreateEdit = (props) => {
    //constantes
    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);
    const [idViaje, setIdViaje] = useState(null);
    const [viajeObj, setViajeObj] = useState(null);
    const [datasetExcel, setDatasetExcel] = useState([]);


    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);

    useEffect(() => {
        if (viajeObj) {
            setDatasetExcel([{
                Viaje: viajeObj.general.nviaje,
                CP: viajeObj.general.ncarta,
                Operador: viajeObj.detalles.operador1,
                Start: viajeObj.hora_start,
                End: viajeObj.hora_end,
                Arrival: viajeObj.hora_up
            }]);
        }
    }, [viajeObj]);

    useEffect(() => {
        if (uid) {
            getUserByUid(uid).then(snapshot => {
                let obuser = null;
                snapshot.forEach(document => {
                    obuser = { ...document.data(), id: document.id }
                    if (!obuser.segmento_negocio) {
                        obuser.segmento_negocio = [];
                    }


                });
                // setUsuarioObj(obuser);
            }).catch(err => { console.log(err) });
        }
    }, [uid])

    const initValues = async () => {

        if (isEdit && recordedit != null) {
        } else {
        }
    }



    useEffect(() => {
        setUid(props.uid);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        //setRecordEdit(props.currenteditrecrod);
        setIdViaje(props.currenteditrecrod);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (idViaje) {
            //console.log("id viaje", idViaje);
            getViajeById(idViaje).then(document => {
                if (document.data()) {
                    setViajeObj(document.data());
                }
            }).catch(err => { console.log(err) });
        }
    }, [idViaje]);


    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();


    }
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const dataSet1 = [
        {
            name: "Johson",
            amount: 30000,
            sex: 'M',
            is_married: true
        },
        {
            name: "Monika",
            amount: 355000,
            sex: 'F',
            is_married: false
        },
        {
            name: "John",
            amount: 250000,
            sex: 'M',
            is_married: false
        },
        {
            name: "Josef",
            amount: 450500,
            sex: 'M',
            is_married: true
        }
    ];

    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Bitácora de tiempos" : `Editar bítacora de tiempos`}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                   
                    <ExcelFile element={<button className="btn btn-primary">Exportar a Excel</button>}>
                        <ExcelSheet data={datasetExcel} name="Horarios">
                            <ExcelColumn label="Viaje" value="Viaje" />
                            <ExcelColumn label="CP" value="CP" />
                            <ExcelColumn label="Operador" value="Operador" />
                            <ExcelColumn label="Pick Up"
                                value={(col) => col.Start ? col.Start : "No Set"} />
                            <ExcelColumn label="Row Arrival"
                                value={(col) => col.Arrival ? col.Arrival : "No Set"} />
                            <ExcelColumn label="Arrival Destination"
                                value={(col) => col.Start ? col.End : "No Set"} />
                        </ExcelSheet>
                    </ExcelFile>

                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalCreateEdit;