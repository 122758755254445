import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



export default class Download extends React.Component {
    constructor(props)
    {
        super();
        let {data}=props;
        this.state = {
            dataSet1: data,
        }
     //   console.log(this.state);

    }

    componentDidUpdate=(prevProps)=>{
        
    }
    /**
     * 
     * numEconomico: element.numEconomico,
                                                            propiedad: element.propiedad,
                                                            tipo: element.tipo,
                                                            placas: element.placas,
                                                            marca: element.marca,
                                                            year: element.year,
                                                            descripcion: element.descripcion,
                                                            status: element.active ? "Activo" : "Inactivo"
     */
    
    render() {
        return (
            <ExcelFile  element={<div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>}>
                <ExcelSheet data={this.state.dataSet1} name="Viajes"> 
                    <ExcelColumn label="No." value="numEconomico"/>
                    <ExcelColumn label="Propiedad" value="propiedad"/>
                    <ExcelColumn label="Tipo" value="tipo"/>
                    <ExcelColumn label="Placas" value="placas"/>
                    <ExcelColumn label="Placas USA" value="placasUsa"/>
                    <ExcelColumn label="Marca" value="marca"/>
                    <ExcelColumn label="Año" value="year"/>
                    <ExcelColumn label="No.Serie" value="noSerie"/>
                    <ExcelColumn label="Aseguradora" value="aseguradora"/>
                    <ExcelColumn label="Póliza" value="poliza"/>
                    <ExcelColumn label="Descripción" value="descripcion"/>
                    <ExcelColumn label="Estatus" value="status"/>
                </ExcelSheet>
               
            </ExcelFile>
        );
    }
}
