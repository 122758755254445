import React, { Component, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';

class TableUsers extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "n",
                text: "#",
                className: "n",
                align: "center",
                sorteable: true,
            },
            {
                key: "email",
                text: "Email",
                className: "email",
                align: "center",
                sorteable: true,
            },
            {
                key: "uid",
                text: "UID",
                className: "uid",
                align: "center",
                sorteable: true,
            },
            {
                key: "type",
                text: "Tipo de usuario",
                className: "type",
                align: "center",
                sorteable: true,
            }, {
                key: "action",
                text: "Acciones",
                className: "action",
                width: 100,
                align: "center",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment key={record.id}>
                            <button className="btn btn-info btn-sm" onClick={() => this.props.editHandler(record)}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button className="btn btn-danger btn-sm ml-1" onClick={() => this.props.deleteHandler(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            page_size: 50,
            length_menu: [50, 100, 200],
            button: { excel: false },
        }
        this.state = {
            records: this.props.records
        }
    }
    static getDerivedStateFromProps = (props, state) => {
        return {
            records: props.records,
        }
    }

    render() {
        return (
            <div className="table-responsive">
                <ReactDatatable
                    className="table stack b-white hover table-bordered"
                    key={'table11'}
                    config={this.config}
                    records={this.state.records}
                    columns={this.columns}
                />
            </div>
        );
    }
}

export default TableUsers;