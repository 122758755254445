import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input';
import Swal from 'sweetalert2';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { reactivarDeuda, addIngreso, updateIngreso, addDestino, getAllDestinosByUser } from '../../../controllers/ingresos';
import { actualizaPagos, actualizaPagosCFDINormal } from '../../../controllers/viajes';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import { getClientes } from '../../../controllers/clientes';
import { makeStyles } from '@material-ui/core/styles';
import { pagarViajes } from '../../../controllers/ingresos';
import { getViajesFacturadosByClienteByRange, getCFDISNormalesByClienteByRange, getAllViajesidsList, buscarPorNumCP, buscarPorNcarta, buscarCFDINormalPorNfolio } from '../../../controllers/viajes';
import { getUserByUid } from '../../../controllers/usuarios';
import axios from 'axios';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import xml2js from 'xml2js';
import LinearProgress from '@material-ui/core/LinearProgress';
import readXlsxFile from 'read-excel-file';
import Button from '@material-ui/core/Button';
import InfiniteScroll from "react-infinite-scroller";
import { width } from '@mui/system';

const cargaPorExcel = ['BrZNecrFhCX9aWWjLJQy2B8EAAp2', 'OvQ0BgHR2fS8ACZgKk6Vj4Q8UMC3', 'T0ugYIhT2ccUJYwVlEdbLZlMDvg1', 'Is55uxcCDdMdI53LaFtWvyFXGH03','dgna2dBqK8NdzwdhepCiMQbYQ5g2'];

const CAT_USOCFDI = [
    {
        "Natural": true,
        "Moral": false,
        "Name": "Sin efectos fiscales.",
        "Value": "S01"
    },
    {
        "Natural": true,
        "Moral": false,
        "Name": "Honorarios médicos, dentales y gastos hospitalarios.",
        "Value": "D01"
    },
    {
        "Natural": true,
        "Moral": false,
        "Name": "Gastos médicos por incapacidad o discapacidad",
        "Value": "D02"
    },
    {
        "Natural": true,
        "Moral": false,
        "Name": "Gastos funerales.",
        "Value": "D03"
    },
    {
        "Natural": true,
        "Moral": false,
        "Name": "Donativos.",
        "Value": "D04"
    },
    {
        "Natural": true,
        "Moral": false,
        "Name": "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
        "Value": "D05"
    },
    {
        "Natural": true,
        "Moral": false,
        "Name": "Aportaciones voluntarias al SAR.",
        "Value": "D06"
    },
    {
        "Natural": true,
        "Moral": false,
        "Name": "Primas por seguros de gastos médicos.",
        "Value": "D07"
    },
    {
        "Natural": true,
        "Moral": false,
        "Name": "Gastos de transportación escolar obligatoria.",
        "Value": "D08"
    },
    {
        "Natural": true,
        "Moral": false,
        "Name": "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
        "Value": "D09"
    },
    {
        "Natural": true,
        "Moral": false,
        "Name": "Pagos por servicios educativos (colegiaturas)",
        "Value": "D10"
    },
    {
        "Natural": true,
        "Moral": false,
        "Name": "Pagos",
        "Value": "CP01"
    },
    {
        "Natural": true,
        "Moral": true,
        "Name": "Adquisición de mercancias",
        "Value": "G01"
    },
    {
        "Natural": true,
        "Moral": true,
        "Name": "Devoluciones, descuentos o bonificaciones",
        "Value": "G02"
    },
    {
        "Natural": true,
        "Moral": true,
        "Name": "Gastos en general",
        "Value": "G03"
    },
    {
        "Natural": true,
        "Moral": true,
        "Name": "Construcciones",
        "Value": "I01"
    },
    {
        "Natural": true,
        "Moral": true,
        "Name": "Mobilario y equipo de oficina por inversiones",
        "Value": "I02"
    },
    {
        "Natural": true,
        "Moral": true,
        "Name": "Equipo de transporte",
        "Value": "I03"
    },
    {
        "Natural": true,
        "Moral": true,
        "Name": "Equipo de computo y accesorios",
        "Value": "I04"
    },
    {
        "Natural": true,
        "Moral": true,
        "Name": "Dados, troqueles, moldes, matrices y herramental",
        "Value": "I05"
    },
    {
        "Natural": true,
        "Moral": true,
        "Name": "Comunicaciones telefónicas",
        "Value": "I06"
    },
    {
        "Natural": true,
        "Moral": true,
        "Name": "Comunicaciones satelitales",
        "Value": "I07"
    },
    {
        "Natural": true,
        "Moral": true,
        "Name": "Otra maquinaria y equipo",
        "Value": "I08"
    },
    {
        "Natural": true,
        "Moral": true,
        "Name": "Por definir",
        "Value": "P01"
    }
]
const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1C82D0',
    },
}))(LinearProgress);
const useStyles = makeStyles((theme) => ({
    input: {
        display: 'inline-block',
        float: 'right',
        width: '60%',
        height: 30,
        marginTop: 4,
        marginRight: 2
    }
}));
const ModalCreateEdit = (props) => {
    //constantes
    const CATALOGOS_TIPOS = ['Facturación por ventas', 'Renta de unidad', 'Otros ingresos'];
    const CATALOGO_METODOS_PAGO = ['Transferencia', 'Efectivo', 'Cheque', 'Compensación'];
    const CAT_METODOSPAGO = [
        {
            "Name": "Pago en parcialidades ó diferido",
            "Value": "PPD"
        },
        {
            "Name": "Pago en una sola exhibición",
            "Value": "PUE"
        }
    ];
    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);
    const [destinosUser, setDestinosUser] = useState([]);
    //estados propios del formulario
    const [tipo, setTipo] = useState('Facturación por ventas');
    const [monto, setMonto] = useState('');
    const [montoFloat, setMontoFloat] = useState(0.0);
    const [iva, setIva] = useState('');
    const [ivaFloat, setIvaFloat] = useState(0.0);
    const [referencia, setReferencia] = useState('');
    const [numCP, setNumCP] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [cliente, setCliente] = useState('-');
    const [clienteId, setClienteId] = useState("");

    const [usoCFDI, setUsoCFDI] = useState("G03 - Gastos en general");

    const [factoraje, setFactoraje] = useState('-');
    const [factorajeId, setFactorajeId] = useState("");

    const [metodoPago, setMetodoPago] = useState('Depósito');
    const [tipoPago, setTipoPago] = useState('PUE - Pago en una sola exhibición');
    const [incluirCuentas, setIncluirCuentas] = useState('No');
    const [rfcCtaOrdenante, setRfcCtaOrdenante] = useState('');
    const [cuentaOrdenante, setCuentaOrdenante] = useState('');
    const [rfcCtaBeneficiario, setRfcCtaBeneficiario] = useState('');
    const [cuentaBeneficiario, setCuentaBeneficiario] = useState('');
    const [facturas, setFacturas] = useState([]);
    const [facturaid, setFacturaID] = useState(1);
    const [iva16, setIva16] = useState(true);
    const [destinoIngreso, setDestinoIngreso] = useState('');
    const [clientes, setClientes] = useState([]);
    const [facturasReft, setFacturasReft] = useState([]);
    const [usuarioobj, setUsuarioObj] = useState(null);
    const [segmentoNegocio, setSegmentoNegocio] = useState("");
    const [isComplementoPago, setIsComplementoPago] = useState(false);
    const [isFactoraje, setIsFactoraje] = useState(false);
    const [razonesSociales, setRazonesSociales] = useState([]);
    const [rfc, setRfc] = useState("");
    const [currencyMX, setCurrencyMX] = useState(true);
    const [dolarprice, setDolarPrice] = useState(20);
    const [ivatasa, setIvaTasa] = useState("16");

    const [fechaRangoStart, setFechaRangoStart] = useState(new Date());
    const [fechaRangoEnd, setFechaRangoEnd] = useState(new Date());

    const [totalPagoFacturas, setTotalPagoFacturas] = useState(0);

    const [progreso, setProgreso] = useState(0);

    useEffect(() => {
        setMetodoPago('Depósito');
        setTipo('Facturación por ventas');
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);

    const handleChangeFile = (event) => {
        let file = event.target.files[0];

        if (file) {
            readXlsxFile(file).then((rows) => {
                //console.log("rows", rows);
                if (rows.length > 1) {
                    let cpss = [];
                    rows.forEach((r, index) => {
                        if (index > 0) {
                            cpss.push(r[0]);
                        }
                    });
                    console.log("cpss", cpss);
                    buscarPorFolios(cpss);
                }
            });
        }
    };

    const buscarPorFolios = async (folios) => {

        let aux = [];
        let aux2 = [];
        let fixed = [];
        let actual = 1;
        setProgreso(actual);
        for await (let f of folios) {




            let sp = f.split("-");

            if (sp.length === 2) {
                if (sp[0] == "F") {
                    console.log("buscando cfdi normal")
                    await buscarCFDINormalPorNfolio(parseFloat(sp[1]), uid).then(snapshot => {
                        if (snapshot.docs.length == 0) {
                            console.log("no se encontro fnormal", sp[1])
                        }
                        snapshot.forEach(doc => {
                            let pendientes = 0.0;


                            let o = doc.data();
                            delete o.facuturado.pdf;
                            console.log("cfdinormal", o);
                            if (o.facuturado && !o.facturaCancelada) {

                                o.id = doc.id;
                                o.key = doc.id;
                                o.factura = `${o.prefijoFactura} - ${o.folioFactura}`;
                                o.carta_porte = "";
                                o.cliente = "";
                                o.cfdinormal = true;
                                o.total = o.factura_monto;
                                o.parcialidad = o.parcialidad || 1;
                                o.fecha = o.fecha_facturado || null;
                                if (o.pagos) {
                                    //calculamos pendientes
                                    let pagado = 0.0;
                                    o.pagos.forEach(element => {
                                        pagado += element.montoFloat;
                                    });
                                    if (pagado > 0) {
                                        console.log("hay pagos en ", o.factura);
                                        // idsfacturasconpagos.push(o.id);
                                    }
                                    if (o.factura_monto >= pagado) {
                                        pendientes = o.factura_monto - pagado;
                                    } else {
                                        pendientes = 0.0;
                                    }


                                } else {
                                    pendientes = o.factura_monto;
                                }
                                o.pendientes = pendientes;
                                o.obj = { ...o };

                                o.facturaname = `${o.prefijoFactura || ""}-${o.folioFactura || ""} ${o.carta_porte || ""}`;
                                if (o.status !== "Pagado")
                                    aux2.push(o);
                            }

                        });

                    }).catch(err => console.log(err));
                } else {
                    await buscarPorNcarta(f, uid).then(snapshot => {
                        console.log("encontrados", snapshot.docs.length);
                        if (snapshot.docs.length == 0) {
                            console.log("no se encontro ncarta", f);
                        }
                        snapshot.forEach(doc => {
                            let pendientes = 0.0;


                            let o = doc.data();
                            if (o.facuturado)
                                delete o.facuturado.pdf;
                            delete o.mercancias;
                            delete o.carta_porte;

                            if (o.facuturado && !o.facturaCancelada) {
                                o.id = doc.id;
                                o.key = doc.id;
                                o.factura = o.general.nviaje;
                                o.carta_porte = o.general.ncarta;
                                o.cliente = o.general.cliente;
                                o.total = o.detalles.tarifaFloat;
                                o.fecha = o.fecha_facturado || null;
                                if (o.pagos) {
                                    //calculamos pendientes

                                    let pagado = 0.0;
                                    o.pagos.forEach(element => {
                                        pagado += element.montoFloat;
                                    });
                                    if (pagado > 0) {
                                        console.log("hay pagos en ", o.carta_porte);
                                        // idscartaporteconpagos.push(o.id);
                                    }

                                    if (o.factura_monto >= pagado) {
                                        pendientes = o.factura_monto - pagado;
                                    } else {
                                        pendientes = 0.0;
                                    }

                                } else {
                                    pendientes = o.factura_monto;
                                }
                                o.pendientes = pendientes;
                                delete o.detalles;
                                o.obj = { ...o };

                                o.facturaname = `${o.prefijoFactura || ""}-${o.folioFactura || ""} ${o.carta_porte || ""}`;
                                // if (o.status !== "Pagado")
                                aux.push(o);
                            }else{
                                console.log("no facturado o factura cancelada",f);
                            }

                        });

                    }).catch(err => console.log(err));
                }

            }
            actual++;
            setProgreso(parseInt(actual * 100 / folios.length));
        }
        console.log("total auxx",aux.length);
        aux.forEach(async (obj) => {
            if (obj.facuturado) {
                let xmlstring = obj.facuturado.cfdixml;
                //let fixedXml=xmlstring.replace('cfdi:', '');
                let fixedXml = xmlstring.split('cfdi:').join('');
                fixedXml = xmlstring.split('$').join('obj');

                let response = null;
                await xml2js.parseString(fixedXml, async (err, result) => {
                    if (err) {
                        console.log(err);
                    }
                    else {
                        // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                        //let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                        let folioFiscal = obj.facuturado.uuid;
                        let serie = result["cfdi:Comprobante"]['$']["Serie"] || "F";
                        let folio = result["cfdi:Comprobante"]['$']["Folio"];
                        let impaux = result["cfdi:Comprobante"]["cfdi:Impuestos"];
                        let impuestos=null;
                        if(impaux && impaux[0]){
                            impuestos = {
                                totales: impaux[0]['$'],
                                retenidos: impaux[0]['cfdi:Retenciones'] ? impaux[0]['cfdi:Retenciones'][0]['cfdi:Retencion'][0]['$'] : { noimpuesto: true },
                                trasladados: impaux[0]['cfdi:Traslados'] ? impaux[0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$'] : { noimpuesto: true },
                            }
                        }
                        //factura_monto
                        let auxiliarcompara1 = result["cfdi:Comprobante"]['$']["Total"];
                        auxiliarcompara1 = parseFloat(auxiliarcompara1);
                        auxiliarcompara1 = `${auxiliarcompara1}`;
                        let montoaux2 = `${obj.factura_monto}`;
                        if (auxiliarcompara1 !== montoaux2) {
                            console.log(`direfencia ${auxiliarcompara1}  ${montoaux2} en folio ${serie}-${folio}`)
                        }
                        delete obj.facuturado;
                        let moneda = result['cfdi:Comprobante']['$']['Moneda'];
                        if (moneda === "USD") {
                            if (!currencyMX) {
                                fixed.push({ ...obj, serie, folio, folioFiscal, impuestos });
                            }
                        } else {
                            if (currencyMX) {
                                fixed.push({ ...obj, serie, folio, folioFiscal, impuestos });
                            }
                        }

                        // console.log("primer xml", moneda);
                        //response = objdataxml;
                        // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                    }

                });
            }
        })
        aux2.forEach(async (obj) => {
            if (obj.facuturado) {
                let xmlstring = obj.facuturado.cfdixml;
                //let fixedXml=xmlstring.replace('cfdi:', '');
                let fixedXml = xmlstring.split('cfdi:').join('');
                fixedXml = xmlstring.split('$').join('obj');

                let response = null;
                await xml2js.parseString(fixedXml, async (err, result) => {
                    if (err) {
                        console.log(err);
                    }
                    else {
                        // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                        //let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                        let folioFiscal = obj.facuturado.uuid;
                        delete obj.facuturado;
                        let serie = result["cfdi:Comprobante"]['$']["Serie"] || 'F';
                        let folio = result["cfdi:Comprobante"]['$']["Folio"];
                        let impaux = result["cfdi:Comprobante"]["cfdi:Impuestos"];
                        let impuestos=null;
                        if(impaux && impaux[0]){
                            impuestos = {
                                totales: impaux[0]['$'],
                                retenidos: impaux[0]['cfdi:Retenciones'] ? impaux[0]['cfdi:Retenciones'][0]['cfdi:Retencion'][0]['$'] : { noimpuesto: true },
                                trasladados: impaux[0]['cfdi:Traslados'] ? impaux[0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$'] : { noimpuesto: true },
                            }
                        }

                        let auxiliarcompara1 = result["cfdi:Comprobante"]['$']["Total"];
                        auxiliarcompara1 = parseFloat(auxiliarcompara1);
                        auxiliarcompara1 = `${auxiliarcompara1}`;
                        let montoaux2 = `${obj.factura_monto}`;
                        if (auxiliarcompara1 !== montoaux2) {
                            console.log(`direfencia ${auxiliarcompara1}  ${montoaux2} en folio ${serie}-${folio}`)
                        }

                        let moneda = result['cfdi:Comprobante']['$']['Moneda'];
                        if (moneda === "USD") {
                            if (!currencyMX) {
                                fixed.push({ ...obj, serie, folio, folioFiscal, impuestos });
                            }
                        } else {
                            if (currencyMX) {
                                fixed.push({ ...obj, serie, folio, folioFiscal, impuestos });
                            }
                        }

                        // console.log("primer xml", moneda);
                        //response = objdataxml;
                        // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                    }

                });
            }
        })
        console.log("asignadno", fixed);


        setFacturasReft(fixed);
    }

    useEffect(() => {
        let auxtotal = 0;
        facturas.forEach(f => {
            auxtotal += f.montoFloat || 0;
        });
        setTotalPagoFacturas(parseFloat(auxtotal.toFixed(2)));
    }, [facturas])
    useEffect(() => {
        if (usuarioobj && usuarioobj.razones) {
            let razonesasignar = [];
            usuarioobj.razones.forEach(r => {
                if (!r.inactivo) {
                    razonesasignar.push(r);
                }
            })
            setRazonesSociales(razonesasignar);

            if (usuarioobj.razones.length > 0 && usuarioobj.razones[0].Rfc) {
                setRfc(usuarioobj.razones[0].Rfc);
            }
            if (!isEdit && segmentoNegocio === "" && usuarioobj.segmento_negocio && usuarioobj.segmento_negocio.length > 0) {
                setSegmentoNegocio(usuarioobj.segmento_negocio[0].value);
            }
        }
    }, [usuarioobj])

    const getData = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let obuser = null;
            snapshot.forEach(document => {
                obuser = { ...document.data(), id: document.id }
                if (!obuser.segmento_negocio) {
                    obuser.segmento_negocio = [];
                }

            });
            setUsuarioObj(obuser);
        });
        await getClientes(uid).then(snapshot => {
            let aux = [];
            snapshot.forEach(document => {
                let o = { ...document.data() };
                o.id = document.id;
                aux.push(o);
            });
            let toset = aux.map((item, index) => {
                let ob = { ...item, name: item.nombre.trim() + " - " + item.estado_short.trim(), value: item.id, id: "user" + index };
                return ob;
            });
            setClientes(toset);
        }).catch(err => { });
    }
    useEffect(() => {
        if (uid) {
            getData(uid);
        }
    }, [uid]);

    useEffect(() => {
        if (facturasReft.length > 0) {

            let nextfactid = facturaid;
            let aux = [];
            facturasReft.forEach(factenmemoria => {
                if (factenmemoria.obj.pendientes >= 0) {


                    nextfactid++;
                    let pend = '$0.00';
                    let pendfloat = 0.0;
                    let refe = '';
                    let isCFDI = false;

                    let num = '$' + factenmemoria.pendientes.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                    let factura = {
                        factura_monto: parseFloat(parseFloat(factenmemoria.obj.factura_monto).toFixed(2)),
                        pendienteFloat: parseFloat(parseFloat(factenmemoria.obj.pendientes).toFixed(2)),
                        monto: num,
                        parcialidad: factenmemoria.parcialidad || 1,
                        cfdinormal: factenmemoria.cfdinormal ? true : false,
                        montoFloat: parseFloat(factenmemoria.pendientes.toFixed(2)),
                        pendiente: num,
                        impuestos: factenmemoria.impuestos || [],
                        difpendiente: '$0.00',
                        difpendienteFloat: 0.0,
                        facturaname: `${factenmemoria.prefijoFactura || ""}-${factenmemoria.folioFactura || ""} ${factenmemoria.carta_porte || ""}`,
                        id: factenmemoria.id,
                        referencia: factenmemoria.id,
                        folioFiscal: factenmemoria.folioFiscal,
                        serie: factenmemoria.serie,
                        folio: factenmemoria.folio,
                        temid: factenmemoria.id

                    }
                    aux.push(factura);
                } else {
                    console.log("pendiente 0 en ", factenmemoria.obj);
                }
            });
            if (uid !== "OvQ0BgHR2fS8ACZgKk6Vj4Q8UMC3") {
                setFacturas(aux);
            }

        }

        console.log("cpss", facturasReft.map(m => { return `${m.serie}-${m.folio}` }));
        console.log("montos", facturasReft.map(m => { return `${m.factura_monto}` }));
        // console.log("cambio facturasreft", facturasReft);
    }, [facturasReft]);

    useEffect(() => {
        if (cliente && cliente != "" && clientes.length > 0) {
            let objf = clientes.find(element => element.name === cliente);
            if (objf) {
                if (objf.value != clienteId) {
                    // console.log("cliente info", objf);
                    setClienteId(objf.value);
                    // cargarFacturasReft(objf.value);
                }

            }
        }
    }, [cliente])

    const cargarFacturasDeArrayIds = async (idsViajes, idsCfdisNormales, facturas) => {
        console.log("cargando facturas arrayids");
        let aux = [];
        await getAllViajesidsList(idsViajes).then(snapshotList => {

            snapshotList.forEach(doc => {

                let pendientes = 0.0;
                let o = doc.data();
                if (o.facuturado) {
                    o.id = doc.id;
                    o.key = doc.id;
                    o.factura = o.general.nviaje;
                    o.carta_porte = o.general.ncarta;
                    o.cliente = o.general.cliente;
                    o.total = o.detalles.tarifaFloat;
                    o.fecha = o.fecha_facturado || null;
                    o.parcialidad = o.parcialidad || 1;
                    if (o.pagos) {
                        //calculamos pendientes
                        let pagado = 0.0;
                        o.pagos.forEach(element => {
                            pagado += element.montoFloat;
                        });

                        if (o.factura_monto >= pagado) {
                            pendientes = o.factura_monto - pagado;
                        } else {
                            pendientes = 0.0;
                        }

                    } else {
                        pendientes = o.factura_monto;
                    }
                    o.pendientes = pendientes;
                    o.obj = { ...o };
                    o.facturaname = `${o.prefijoFactura || ""}-${o.folioFactura || ""} ${o.carta_porte || ""}`;
                    aux.push(o);

                }
            });
        }).catch(err => { console.log(err) });


        setFacturasReft(aux);

        console.log("asignando facturas");
        setFacturas(facturas);

    }
    const getDateBlocks = (start, end, maxDays) => {
        let result = [];
        // Copy start so don't affect original
        let s = new Date(start);

        while (s < end) {
            // Create a new date for the block end that is s + maxDays
            let e = new Date(s.getFullYear(), s.getMonth(), s.getDate() + maxDays);
            e.setHours(23, 59, 59);
            // Push into an array. If block end is beyond end date, use a copy of end date
            let startnew = new Date(s);
            startnew.setHours(0, 0, 0);
            let endnew = new Date(end);
            endnew.setHours(23, 59, 59);
            result.push({ start: startnew, end: e <= end ? e : endnew });
            // Increment s to the start of the next block which is one day after 
            // the current block end
            s.setDate(s.getDate() + maxDays + 1);
        }
        return result;
    }
    const cargarFacturasReft = async () => {
        if (clienteId) {
            let aux = [];
            let aux2 = [];
            let fixed = [];

            console.log("fechas", fechaRangoStart);
            console.log("fechas", fechaRangoEnd);
            let arregloFechas = getDateBlocks(fechaRangoStart, fechaRangoEnd, 2);

            console.log(arregloFechas);
            //console.log("realizando busqueda", { clienteId, fechaRangoStart, fechaRangoEnd });
            let totalPeticiones = arregloFechas.length;
            let actual = 1;
            setProgreso(actual);
            for await (let rangoauxiliar of arregloFechas) {
                await getViajesFacturadosByClienteByRange(clienteId, rangoauxiliar.start, rangoauxiliar.end).then(snapshot => {
                    console.log("encontrados", snapshot.docs.length);
                    snapshot.forEach(doc => {
                        let pendientes = 0.0;


                        let o = doc.data();
                        if (o.facuturado)
                            delete o.facuturado.pdf;
                        delete o.mercancias;
                        delete o.carta_porte;

                        if (o.facuturado && !o.facturaCancelada) {
                            o.id = doc.id;
                            o.key = doc.id;
                            o.factura = o.general.nviaje;
                            o.carta_porte = o.general.ncarta;
                            o.cliente = o.general.cliente;
                            o.total = o.detalles.tarifaFloat;
                            o.fecha = o.fecha_facturado || null;
                            if (o.pagos) {
                                //calculamos pendientes
                                let pagado = 0.0;
                                o.pagos.forEach(element => {
                                    pagado += element.montoFloat;
                                });

                                if (o.factura_monto >= pagado) {
                                    pendientes = o.factura_monto - pagado;
                                } else {
                                    pendientes = 0.0;
                                }

                            } else {
                                pendientes = o.factura_monto;
                            }
                            o.pendientes = pendientes;
                            delete o.detalles;
                            o.obj = { ...o };

                            o.facturaname = `${o.prefijoFactura || ""}-${o.folioFactura || ""} ${o.carta_porte || ""}`;
                            // if (o.status !== "Pagado")
                            aux.push(o);
                        }

                    });

                }).catch(err => console.log(err));
                await getCFDISNormalesByClienteByRange(clienteId, rangoauxiliar.start, rangoauxiliar.end).then(snapshot => {

                    snapshot.forEach(doc => {
                        let pendientes = 0.0;


                        let o = doc.data();
                        delete o.facuturado.pdf;
                        console.log("cfdinormal", o);
                        if (o.facuturado && !o.facturaCancelada) {

                            o.id = doc.id;
                            o.key = doc.id;
                            o.factura = `${o.prefijoFactura} - ${o.folioFactura}`;
                            o.carta_porte = "";
                            o.cliente = "";
                            o.cfdinormal = true;
                            o.total = o.factura_monto;
                            o.parcialidad = o.parcialidad || 1;
                            o.fecha = o.fecha_facturado || null;
                            if (o.pagos) {
                                //calculamos pendientes
                                let pagado = 0.0;
                                o.pagos.forEach(element => {
                                    pagado += element.montoFloat;
                                });

                                if (o.factura_monto >= pagado) {
                                    pendientes = o.factura_monto - pagado;
                                } else {
                                    pendientes = 0.0;
                                }

                            } else {
                                pendientes = o.factura_monto;
                            }
                            o.pendientes = pendientes;
                            o.obj = { ...o };

                            o.facturaname = `${o.prefijoFactura || ""}-${o.folioFactura || ""} ${o.carta_porte || ""}`;
                            if (o.status !== "Pagado")
                                aux2.push(o);
                        }else{
                            console.log("no facturado??");
                        }

                    });

                }).catch(err => console.log(err));

                if(clienteId==="ljEwykFmnm3cAd94E8K5" || clienteId==="oHBIsEw9PtAQK9nk8MFa"){
                    let otroidciente=""
                    if(clienteId==="oHBIsEw9PtAQK9nk8MFa"){
                        otroidciente="ljEwykFmnm3cAd94E8K5";
                    }else{
                        otroidciente="oHBIsEw9PtAQK9nk8MFa";
                    }
                    await getViajesFacturadosByClienteByRange(otroidciente, rangoauxiliar.start, rangoauxiliar.end).then(snapshot => {
                        console.log("encontrados", snapshot.docs.length);
                        snapshot.forEach(doc => {
                            let pendientes = 0.0;
    
    
                            let o = doc.data();
                            if (o.facuturado)
                                delete o.facuturado.pdf;
                            delete o.mercancias;
                            delete o.carta_porte;
    
                            if (o.facuturado && !o.facturaCancelada) {
                                o.id = doc.id;
                                o.key = doc.id;
                                o.factura = o.general.nviaje;
                                o.carta_porte = o.general.ncarta;
                                o.cliente = o.general.cliente;
                                o.total = o.detalles.tarifaFloat;
                                o.fecha = o.fecha_facturado || null;
                                if (o.pagos) {
                                    //calculamos pendientes
                                    let pagado = 0.0;
                                    o.pagos.forEach(element => {
                                        pagado += element.montoFloat;
                                    });
    
                                    if (o.factura_monto >= pagado) {
                                        pendientes = o.factura_monto - pagado;
                                    } else {
                                        pendientes = 0.0;
                                    }
    
                                } else {
                                    pendientes = o.factura_monto;
                                }
                                o.pendientes = pendientes;
                                delete o.detalles;
                                o.obj = { ...o };
    
                                o.facturaname = `${o.prefijoFactura || ""}-${o.folioFactura || ""} ${o.carta_porte || ""}`;
                                // if (o.status !== "Pagado")
                                aux.push(o);
                            }
    
                        });
    
                    }).catch(err => console.log(err));
                    await getCFDISNormalesByClienteByRange(otroidciente, rangoauxiliar.start, rangoauxiliar.end).then(snapshot => {
    
                        snapshot.forEach(doc => {
                            let pendientes = 0.0;
    
    
                            let o = doc.data();
                            delete o.facuturado.pdf;
                            console.log("cfdinormal", o);
                            if (o.facuturado && !o.facturaCancelada) {
    
                                o.id = doc.id;
                                o.key = doc.id;
                                o.factura = `${o.prefijoFactura} - ${o.folioFactura}`;
                                o.carta_porte = "";
                                o.cliente = "";
                                o.cfdinormal = true;
                                o.total = o.factura_monto;
                                o.parcialidad = o.parcialidad || 1;
                                o.fecha = o.fecha_facturado || null;
                                if (o.pagos) {
                                    //calculamos pendientes
                                    let pagado = 0.0;
                                    o.pagos.forEach(element => {
                                        pagado += element.montoFloat;
                                    });
    
                                    if (o.factura_monto >= pagado) {
                                        pendientes = o.factura_monto - pagado;
                                    } else {
                                        pendientes = 0.0;
                                    }
    
                                } else {
                                    pendientes = o.factura_monto;
                                }
                                o.pendientes = pendientes;
                                o.obj = { ...o };
    
                                o.facturaname = `${o.prefijoFactura || ""}-${o.folioFactura || ""} ${o.carta_porte || ""}`;
                                if (o.status !== "Pagado")
                                    aux2.push(o);
                            }
    
                        });
    
                    }).catch(err => console.log(err));
                }

                actual++;
                setProgreso(parseInt(actual * 100 / totalPeticiones));
            }

            aux.forEach(async (obj) => {
                if (obj.facuturado) {
                    let xmlstring = obj.facuturado.cfdixml;
                    //let fixedXml=xmlstring.replace('cfdi:', '');
                    let fixedXml = xmlstring.split('cfdi:').join('');
                    fixedXml = xmlstring.split('$').join('obj');

                    let response = null;
                    await xml2js.parseString(fixedXml, async (err, result) => {
                        if (err) {
                            console.log(err);
                        }
                        else {
                            // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                            //let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                            let folioFiscal = obj.facuturado.uuid;
                            let serie = result["cfdi:Comprobante"]['$']["Serie"]||'F';
                            let folio = result["cfdi:Comprobante"]['$']["Folio"];
                            let impaux = result["cfdi:Comprobante"]["cfdi:Impuestos"];
                            let impuestos=null;
                            if(impaux && impaux[0]){
                                impuestos = {
                                    totales: impaux[0]['$'],
                                    retenidos: impaux[0]['cfdi:Retenciones'] ? impaux[0]['cfdi:Retenciones'][0]['cfdi:Retencion'][0]['$'] : { noimpuesto: true },
                                    trasladados: impaux[0]['cfdi:Traslados'] ? impaux[0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$'] : { noimpuesto: true },
                                }
                            }
                            delete obj.facuturado;
                            let moneda = result['cfdi:Comprobante']['$']['Moneda'];
                            if (moneda === "USD") {
                                if (!currencyMX) {
                                    fixed.push({ ...obj, serie, folio, folioFiscal, impuestos });
                                }
                            } else {
                                if (currencyMX) {
                                    fixed.push({ ...obj, serie, folio, folioFiscal, impuestos });
                                }
                            }

                            // console.log("primer xml", moneda);
                            //response = objdataxml;
                            // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                        }

                    });
                }
            })
            aux2.forEach(async (obj) => {
                if (obj.facuturado) {
                    let xmlstring = obj.facuturado.cfdixml;
                    //let fixedXml=xmlstring.replace('cfdi:', '');
                    let fixedXml = xmlstring.split('cfdi:').join('');
                    fixedXml = xmlstring.split('$').join('obj');

                    let response = null;
                    await xml2js.parseString(fixedXml, async (err, result) => {
                        if (err) {
                            console.log(err);
                        }
                        else {
                            // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                            //let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                            let folioFiscal = obj.facuturado.uuid;
                            delete obj.facuturado;
                            let serie = result["cfdi:Comprobante"]['$']["Serie"]||'F';
                            let folio = result["cfdi:Comprobante"]['$']["Folio"];
                            let impaux = result["cfdi:Comprobante"]["cfdi:Impuestos"];
                            let impuestos=null;
                            if(impaux && impaux[0]){
                                impuestos = {
                                    totales: impaux[0]['$'],
                                    retenidos: impaux[0]['cfdi:Retenciones'] ? impaux[0]['cfdi:Retenciones'][0]['cfdi:Retencion'][0]['$'] : { noimpuesto: true },
                                    trasladados: impaux[0]['cfdi:Traslados'] ? impaux[0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$'] : { noimpuesto: true },
                                }
                            }
                            
                            let moneda = result['cfdi:Comprobante']['$']['Moneda'];
                            if (moneda === "USD") {
                                if (!currencyMX) {
                                    fixed.push({ ...obj, serie, folio, folioFiscal, impuestos });
                                }
                            } else {
                                if (currencyMX) {
                                    fixed.push({ ...obj, serie, folio, folioFiscal, impuestos });
                                }
                            }

                            // console.log("primer xml", moneda);
                            //response = objdataxml;
                            // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                        }

                    });
                }
            })
            setFacturasReft(fixed);
        }
    }
    const onChangeDaterangeTwo = (fechas) => {
        if (!fechas || !fechas[0] || !fechas[1]) {
            return;
        }

        let start = new Date(fechas[0].getTime());
        let end = new Date(fechas[1].getTime());

        if (start)
            start.setHours(0, 0, 0);
        if (end)
            end.setHours(23, 59, 59);
        setFechaRangoStart(start);
        setFechaRangoEnd(end);

    };

    const initValues = () => {
        /* if (props.facturasref) {
             setFacturasReft(props.facturasref);
         }*/

        if (isEdit && recordedit != null) {

            let auxIdsViajes = [];
            let auxIdsCfdiNormales = [];
            recordedit.facturas.forEach(f => {
                if (f.cfdinormal == true) {
                    if (f.referencia != "")
                        auxIdsCfdiNormales.push(f.referencia);
                } else {
                    if (f.referencia != "")
                        auxIdsViajes.push(f.referencia);
                }
            });
            //console.log("ids de viajes",auxIdsViajes);
            //console.log("ids de cfdis",auxIdsCfdiNormales);
            cargarFacturasDeArrayIds(auxIdsViajes, auxIdsCfdiNormales, recordedit.facturas);


            setTipo(recordedit.tipo);
            setMonto(recordedit.monto);
            setMontoFloat(recordedit.montoFloat);
            setIva(recordedit.iva);
            setIvaFloat(recordedit.ivaFlaot);
            setDescripcion(recordedit.descripcion);
            setCliente(recordedit.cliente);
            setReferencia(recordedit.referencia);
            setFecha(recordedit.fecha.toDate());
            setMetodoPago(recordedit.metodoPago);
            // setFacturas(recordedit.facturas);
            setSegmentoNegocio(recordedit.segmentoNegocio || '');
            setDestinoIngreso(recordedit.destinoIngreso);
            let maxfactid = 0;
            if (recordedit && recordedit.facturas) {
                recordedit.facturas.forEach(element => {
                    if (element.temid && element.temid >= maxfactid) {
                        maxfactid = element.temid;
                    }
                });
            }

            maxfactid++;
            try {
                setFacturaID(maxfactid);
            } catch { }

        } else {
            setFacturasReft([]);
            setFacturaID(1);
            setTipo('Facturación por ventas');
            setMonto('');
            setSegmentoNegocio('');
            setReferencia('');
            setMontoFloat(0.0);
            setIva('');
            setDestinoIngreso('');
            setIvaFloat(0.0);
            setDescripcion('');
            setCliente('-');
            setMetodoPago('Depósito');
            setFacturas([]);
            setIsFactoraje(false);
            setIsComplementoPago(false);
        }
    }

    const calculateIVA = () => {
        let percent = iva16 ? 0.08 : 0.16;
        let ivanew = montoFloat * percent;
        let num = '$' + ivanew.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        setIvaFloat(ivanew);
        setIva(num);
    }
    useEffect(() => {
        calculateIVA();
    }, [montoFloat, iva16]);

    useEffect(() => {
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setFacturaID(1);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit && recordedit.facturas) {
            //  setFacturas(recordedit.facturas);
            initValues();
        }

    }, [recordedit]);


    const loadDestinos = () => {
        if (uid) {

            let destinos = [];
            getAllDestinosByUser(uid).then(snapshot => {
                snapshot.forEach(doc => {
                    let o = doc.data();
                    destinos.push(o);
                });
                setDestinosUser(destinos);
            }).catch(err => { console.log(err) });
        }
    }
    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
            loadDestinos();
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();
        //setIsOpen(false);
        if (!isComplementoPago) {

            let confirmacion = false;
            await Swal.fire({
                title: '¿Esta seguro de no generar un complemento de pago para este ingreso?',
                text: "No se generará el complemento de pago.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1c666f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Generar Ingreso',
                cancelButtonText: 'Cancelar'
            }).then((result) => {

                if (result.value) {
                    confirmacion = true;
                }

            });
            if (!confirmacion)
                return;
        }

        let fixSegmento = segmentoNegocio;
        if (fixSegmento === "") {
            if (usuarioobj && usuarioobj.segmento_negocio && usuarioobj.segmento_negocio.length > 0) {
                fixSegmento = usuarioobj.segmento_negocio[0].value;
            }
        }
        let otroDestinatario = null;
        if (isComplementoPago && isFactoraje && factorajeId) {
            otroDestinatario = factorajeId;
        }
        let obj = {
            fecha,
            tipo,
            referencia,
            monto: monto || '',
            montoFloat: montoFloat || 0.0,
            iva: iva || '',
            ivaFloat: ivaFloat || 0.0,
            ivaporcentaje: iva16 ? '8%' : '16%',
            cliente,
            clienteId,
            isComplementoPago,
            rfc,
            tipoPago,
            otroDestinatario,
            currencyMX,
            metodoPago,
            usoCFDI,
            destinoIngreso: destinoIngreso.toUpperCase(),
            descripcion,
            facturas,
            segmentoNegocio: fixSegmento,
            incluirCuentas,
            rfcCtaBeneficiario,
            rfcCtaOrdenante,
            cuentaBeneficiario,
            cuentaOrdenante,
            uid,
        }

        if (!obj.currencyMX) {
            obj.dolarprice = dolarprice;
        }
        // console.log("obj", obj);
        // return;
        if (isAdmin && adminCurrentUser) {
            obj.uid = adminCurrentUser;
        }

        //validaciones de formulario
        if (!obj.monto || montoFloat === 0.0) {
            Swal.fire("Monto Invalido", "Debes especificar el monto del ingreso, (el monto debe ser mayor a $0.0 ", 'error');
            return;
        }
        let facturasPagadas = [];
        let sumfacturas = 0.0;
        const tolerancia = 10.0;
        let alertaMontoIncorrecto = false;
        let incorrectosNames = [];
        console.log("Facturas buscando pagadas", facturas);
        facturas.forEach(element => {
            sumfacturas += element.montoFloat;
            if (element.difpendienteFloat <= 0 && element.pendienteFloat > 0) {
                facturasPagadas.push(element);
            }
            if (element.pendienteFloat < element.montoFloat) {
                alertaMontoIncorrecto = true;
                console.log("element", element);
                if (element.referencia && element.referencia !== "") {
                    let buscarRef = facturasReft.find(f => f.id === element.referencia);
                    if (buscarRef && buscarRef.facturaname) {
                        incorrectosNames.push(buscarRef.facturaname);
                    }
                }
            }
        });
        if (alertaMontoIncorrecto) {

            Swal.fire("Monto Facturas invalido", `Existen facturas cuyo monto sobrepasa al monto insoluto, facturas:[${incorrectosNames.join(', ')}]`, 'error');
            return;
        }
        if ((obj.montoFloat + tolerancia) < sumfacturas) {
            Swal.fire("Monto Facturas invalido", `El total de las facturas amparadas sobrepasa al monto ingresado. [Monto:${obj.montoFloat} Facturas:${sumfacturas.toFixed(2)} Tolerancia permitida:10.0]`, 'error');
            return;
        }


        //verifica si hay nuevo destino de ingreso
        if (destinoIngreso.trim().toUpperCase().length > 0 && !destinosUser.some((element) => element.nombre === destinoIngreso.trim().toUpperCase())) {
            //no existe agregamos
            let objtoadd = {
                uid,
                nombre: destinoIngreso.trim().toUpperCase(),
            };
            await addDestino(objtoadd).then(resp => {
                // console.log("agregado destino");
            }).catch(err => { console.log("error al agregar destino", err) });
        } else {
            //  console.log("no se agrega nuevo destino");
        }



        setIsLoading(true);
        if (facturasPagadas && facturasPagadas.length > 0) {
            //   console.log("a pagar array",facturasPagadas.map(element => { return {id:element.referencia,isCFDI:element.cfdinormal} }));
            console.log("pagando facturas", facturasPagadas);
            await pagarViajes(facturasPagadas.map(element => { return { id: element.referencia, isCFDI: element.cfdinormal } })).then(resp => { }).catch(err => { console.log(err) });
        }

        // console.log("ingreso", obj);
        // return;

        if (isEdit && recordedit) {
            console.log("edit", obj);
            //console.log('id obj',recordedit.id)
            obj.useredit = uidAltaEdit || uid;
            if (recordedit) {

                updateIngreso(recordedit.id, obj).then(resp => {
                    setIsLoading(false);
                    setIsOpen(false);
                    props.notify();
                    Swal.fire('Éxito', 'El ingreso se ha actualizado con éxito.', 'success');
                }).catch(err => {
                    setIsLoading(false);
                    Swal.fire("Error", "Error de al actualizar el ingreso, comprueba tu conexión ", 'error');

                });
            }

        } else {
            obj.useralta = uidAltaEdit || uid;
            let idingresoAgregado = 0;



            let sizeofchunk = 1800;
            if (obj.facturas && obj.facturas.length > sizeofchunk) {

                let aux1 = [];
                let aux2 = [];
                for (let i = 0; i < obj.facturas.length; i += sizeofchunk) {
                    const chunk = obj.facturas.slice(i, i + sizeofchunk);
                    if (i == 0) {
                        aux1 = chunk
                    } else {
                        aux2 = chunk;
                    }


                    // do whatever
                }
                obj.facturas = [...aux1];
                obj.facturasExtra = [...aux2];
            }
            //console.log("adding ingreso", obj);
          //  return;

            await addIngreso(obj).then(async (resp) => {
                idingresoAgregado = resp.id;

                if (isComplementoPago) {

                    let rutaPost = "https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/complementopagoopt";
                    if (rfc === "EKU9003173C9") {
                        rutaPost = "https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/complementopagoopt";
                    }
                    //rutaPost="http://localhost:5000/unit-testing-d4fe7/us-central1/app/api/complementopagoopt";

                    let ivarate = 0.16;
                    if (ivatasa == "16") {
                        ivarate = 0.16;
                    } else if (ivatasa == "8") {
                        ivarate = 0.08;
                    } else {
                        ivarate = 0.0;
                    }

                    await axios.post(rutaPost, { id: resp.id, ivar: ivarate, rfc, uid }).then(resp => {
                        setIsLoading(false);
                        props.notify();
                        props.toggle();

                        if (resp.data == "Ok") {
                            Swal.fire("Exito", `El ingreso se ha registrado con éxito y se ha timbrado el complemento de pago`, 'success');
                        } else {
                            Swal.fire("No se pudo generar", `${resp.data}`, 'warning');
                        }

                        //console.log("respuesta", resp);


                    }).catch(err => {
                        setIsLoading(false);
                        console.log("error", err.toString());
                        if (err.toString().includes("Network Error")) {
                            Swal.fire("Error", "Error de conexión, no fue posible conectar con el servidor, revisa tu conexión", 'error');
                        } else {
                            Swal.fire("Error", "No se pudo generar la factura", 'error');
                        }
                    });
                } else {
                    setIsLoading(false);
                    setIsOpen(false);
                    props.notify();
                    Swal.fire('Éxito', 'El ingreso se ha registrado con éxito.', 'success');
                }
                //`https://us-central1-transport-plus-98f66.cloudfunctions.net/app


            }).catch(err => {
                setIsLoading(false);
                Swal.fire("Error", "Error de al registrar el ingreso, comprueba tu conexión ", 'error');

            });

            if (obj.facturas.length > 0) {
                //actualizar viaje para agrega pago
                await obj.facturas.forEach(async (element) => {
                    if (facturasReft) {
                        let objFactura = facturasReft.find(e => e.id == element.referencia);
                        let a = [];
                        if (objFactura.pagos) {
                            a = [...objFactura.pagos];
                        }
                        a.push({ ingreso: idingresoAgregado, ...element });
                        console.log("actualizando viaje", objFactura);

                        if (objFactura.cfdinormal) {
                            await actualizaPagosCFDINormal(objFactura.id, a).then(resp => { }).catch(err => { console.log(err) });
                        } else {
                            await actualizaPagos(objFactura.id, a).then(resp => { }).catch(err => { console.log(err) });
                        }


                        console.log("agregando pago por ", a);

                    }

                });
            }

        }


    }
    const handleChangeCurrency = (event, maskedvalue, floatvalue) => {
        setMonto(maskedvalue);
        setMontoFloat(floatvalue);
    }
    const handleChangeCurrencyIva = (event, maskedvalue, floatvalue) => {
        setIva(maskedvalue);
        setIvaFloat(floatvalue);
    }
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);


    const addFacturaPorCP = async () => {
        if (numCP !== "") {
            await buscarPorNumCP("CP-" + numCP, uid).then(resp => {
                let current = 1;
                let nextfactid = facturaid;
                let aux = [];
                let aux2 = [];
                resp.forEach(doc => {
                    let o = doc.data();


                    let pendientes = 0.0;

                    if (o.facuturado) {
                        o.id = doc.id;
                        o.key = doc.id;
                        o.factura = o.general.nviaje;
                        o.carta_porte = o.general.ncarta;
                        o.cliente = o.general.cliente;
                        o.total = o.detalles.tarifaFloat;
                        o.fecha = o.fecha_facturado || null;
                        o.parcialidad = o.parcialidad || 1;
                        if (o.pagos) {
                            //calculamos pendientes
                            let pagado = 0.0;
                            o.pagos.forEach(element => {
                                pagado += element.montoFloat;
                            });

                            if (o.factura_monto >= pagado) {
                                pendientes = o.factura_monto - pagado;
                            } else {
                                pendientes = 0.0;
                            }

                        } else {
                            pendientes = o.factura_monto;
                        }
                        o.pendientes = pendientes;
                        o.obj = { ...o };
                        o.facturaname = `${o.prefijoFactura || ""}-${o.folioFactura || ""} ${o.carta_porte || ""}`;
                        aux.push(o);

                    }

                    current++;
                    setFacturaID(nextfactid + current);//incrementamos


                    let pend = '$0.00';
                    let pendfloat = 0.0;
                    let refe = doc.id;
                    let isCFDI = false;


                    let factura = {
                        busquedaPorCP: true,
                        id: refe,
                        temid: refe,
                        referencia: refe,
                        monto: '',
                        factura_monto: o.factura_monto,
                        parcialidad: o.parcialidad || 1,
                        montoFloat: 0.0,
                        pendiente: pend,
                        cfdinormal: isCFDI ? true : false,
                        pendienteFloat: pendfloat,
                        difpendienteFloat: pendfloat,
                        difpendiente: pend
                    }
                    aux2 = ([...aux2, factura]);
                });
                setFacturasReft([...facturasReft, ...aux]);
                setTimeout(function () {
                    console.log("seting facutras", [...facturas, ...aux2])
                    setFacturas([...facturas, ...aux2]);
                }, 1000);

            })
        }

    }
    const addFactura = () => {
        let nextfactid = facturaid;
        setFacturaID(facturaid + 1);//incrementamos

        let pend = '$0.00';
        let pendfloat = 0.0;
        let refe = '';
        let isCFDI = false;

        let factura = {
            temid: nextfactid,
            referencia: refe,
            monto: '',
            factura_monto: 0.00,
            parcialidad: 1,
            montoFloat: 0.0,
            pendiente: pend,
            cfdinormal: isCFDI,
            pendienteFloat: pendfloat,
            difpendienteFloat: pendfloat,
            difpendiente: pend
        }
        setFacturas([...facturas, factura]);
    }
    const removeFactura = (element) => {
        let newArr = [...facturas];
        let foundIndex = newArr.findIndex(x => x.id === element.id);
        if (foundIndex >= 0) {
            newArr.splice(foundIndex, 1);
            setFacturas(newArr);
        }
    }
    const changeElmentFactura = (value, element, i) => {
        //
        console.log("changin", value);
        let newArr = [...facturas];

        let foundIndex = i;
        //let foundIndex = newArr.findIndex(x => x.id === element.id);
        if (foundIndex >= 0) {
            newArr[foundIndex].referencia = value;
            newArr[foundIndex].id = value;
            if (facturasReft) {

                let objFactura = facturasReft.find(e => e.id == value);
                if (objFactura) {
                    newArr[foundIndex].factura_monto = parseFloat(parseFloat(objFactura.obj.factura_monto).toFixed(2));
                    newArr[foundIndex].pendienteFloat = parseFloat(parseFloat(objFactura.obj.pendientes).toFixed(2));

                    newArr[foundIndex].montoFloat = parseFloat(parseFloat(objFactura.obj.pendientes).toFixed(2));
                    newArr[foundIndex].cfdinormal = objFactura.cfdinormal ? true : false;
                    newArr[foundIndex].folioFiscal = objFactura.folioFiscal;
                    newArr[foundIndex].impuestos = objFactura.impuestos || [];
                    newArr[foundIndex].serie = objFactura.serie;
                    newArr[foundIndex].folio = objFactura.folio;
                    let num = '$' + newArr[foundIndex].pendienteFloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                    newArr[foundIndex].pendiente = num;
                    newArr[foundIndex].monto = num;
                    newArr[foundIndex].parcialidad = objFactura.parcialidad || 1;
                }


            }
            setFacturas(newArr);
        } else {

        }


    }


    const handleChangePendienteFactura = (event, maskedvalue, floatvalue, element) => {

        if (element) {

            console.log("se encontro");

            let newArr = [...facturas];
            let foundIndex = newArr.findIndex(x => x.id === element.id);
            if (foundIndex >= 0) {

                newArr[foundIndex].pendiente = maskedvalue;
                newArr[foundIndex].pendienteFloat = floatvalue;

                if (newArr[foundIndex].montoFloat) {
                    let dif = newArr[foundIndex].pendienteFloat - newArr[foundIndex].montoFloat;
                    if (dif < 0) {
                        dif = 0.0;
                    }
                    let num = '$' + dif.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                    newArr[foundIndex].difpendiente = num;
                    newArr[foundIndex].difpendienteFloat = dif;
                }

                setFacturas(newArr);
            }
        }
        else {
            console.log(element);
        }
    }

    const handleChangeParcialidadFactura = (value, element) => {

        let newArr = [...facturas];
        let foundIndex = newArr.findIndex(x => x.id === element.id);
        if (foundIndex >= 0) {
            newArr[foundIndex].parcialidad = parseInt(value);

            setFacturas(newArr);
        }
    }
    const handleChangeCurrencyFactura = (event, maskedvalue, floatvalue, element) => {

        let newArr = [...facturas];
        let foundIndex = newArr.findIndex(x => x.id === element.id);
        if (foundIndex >= 0) {

            newArr[foundIndex].monto = maskedvalue;
            newArr[foundIndex].montoFloat = floatvalue;

            if (newArr[foundIndex].pendienteFloat) {
                let dif = newArr[foundIndex].pendienteFloat - floatvalue;
                if (dif < 0) {
                    dif = 0.0;
                }
                let num = '$' + dif.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                newArr[foundIndex].difpendiente = num;
                newArr[foundIndex].difpendienteFloat = dif;
            }

            setFacturas(newArr);
        }
    }


    const refAfterEnter = useRef();
    const eventEnterOnFindCreate = (e) => {
        e.preventDefault();
        refAfterEnter.current.focus();
    }
    const classes = useStyles();
    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Añadir Ingreso" : `Editar Ingreso `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>

                    <LoadingOverlay
                        active={progreso > 0 && progreso < 100}
                        spinner
                        text='Buscando Facturas, espere ...'
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div class="dataTable twoCol">

                                <div class="dataTitle"><p className="fixedP">Ingreso</p></div>

                                <div class="dataBox">

                                    <div class="dataRow">
                                        <p className="fixedP">Tipo de Ingreso</p>
                                        <div class="fieldInput">
                                            <select name='tipo' onChange={(e) => setTipo(e.target.value)} value={tipo} required >
                                                {
                                                    CATALOGOS_TIPOS.map((item, index) => {
                                                        return <option key={index} value={item}>{item}</option>
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>

                                    <div class="dataRow">
                                        <p className="fixedP">Cliente<label className="requiredlabel">*</label></p>
                                        <div class="fieldInput">

                                            {

                                                !isEdit && cliente &&
                                                <Autocomplete
                                                    id="combo-box-demo0c"
                                                    options={clientes}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionSelected={(option, value) => option.name === value.name}
                                                    defaultValue={clientes.find(element => element.name.trim() === cliente.trim())}
                                                    className={"selectbusqueda"}
                                                    onChange={(e, val) => setCliente(val ? val.name : "")}
                                                    renderInput={(params) => <TextField className="reducedselect2" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                />}

                                            {
                                                !isEdit && !cliente &&
                                                <Autocomplete
                                                    id="combo-box-demo0c"
                                                    options={clientes}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionSelected={(option, value) => option.name === value.name}
                                                    className={"selectbusqueda"}
                                                    onChange={(e, val) => setCliente(val ? val.name : "")}
                                                    renderInput={(params) => <TextField className="reducedselect2" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                />
                                            }

                                            {isEdit && <input type="text" value={cliente} onChange={e => setCliente(e.target.value)} variant="outlined" disabled />}


                                        </div>
                                    </div>
                                    <div class="dataRow">
                                        <p className="fixedP">Fecha</p>
                                        <div class="fieldInput">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={firstDay} maxDate={lastDay} selected={fecha} onChange={date => setFecha(date)} />
                                        </div>
                                    </div>

                                    <div class="dataRow">
                                        <p className="fixedP">Segmento de Negocio</p>
                                        <div class="fieldInput">
                                            <select name='segmento' onChange={(e) => setSegmentoNegocio(e.target.value)} value={segmentoNegocio} required >
                                                {
                                                    usuarioobj && usuarioobj.segmento_negocio.map((item, index) => {

                                                        return segmentoNegocio == item.value ?
                                                            <option key={index} value={item.value} selected>{item.value}</option> :
                                                            <option key={index} value={item.value}>{item.value}</option>

                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>


                                    <div class="dataRow">
                                        <p className="fixedP">Referencia</p>
                                        <div class="fieldInput">
                                            <input type="text" name='referencia' value={referencia} onChange={e => setReferencia(e.target.value)} />
                                        </div>
                                    </div>

                                    <div class="dataRow">
                                        <p className="fixedP">Detalles</p>
                                        <div class="fieldInput">
                                            <textarea ref={refAfterEnter} style={{ minHeight: "50px" }} type="text" name='descripcion' value={descripcion} onChange={e => setDescripcion(e.target.value)} placeholder="Detalles del costo" />
                                        </div>
                                    </div>
                                    {
                                        isComplementoPago && <div class="dataRow">
                                            <p className="fixedP">Factoraje</p>
                                            <div class="fieldInput">
                                                <select name='segmento' onChange={(e) => setIsFactoraje(e.target.value == "1")} value={isFactoraje ? "1" : "2"} required >
                                                    <option key={'sicomplemento'} value={"1"} >Si</option>
                                                    <option key={'nocomplemento'} value={"2"}>No</option>
                                                </select>

                                            </div>
                                        </div>
                                    }

                                    {
                                        isComplementoPago && isFactoraje && <div class="dataRow">
                                            <p className="fixedP">Ente Pagador</p>
                                            <div class="fieldInput">
                                                <Autocomplete
                                                    id="combo-box-demo0c"
                                                    options={clientes}
                                                    className={"selectbusqueda"}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionSelected={(option, value) => option.name === value.name}
                                                    style={{ height: '30px' }}
                                                    onChange={(e, val) => {
                                                        if (val && val.name) {
                                                            setFactorajeId(val.value);
                                                            setFactoraje(val.name);
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField className="form-control" onChange={e => setFactoraje(e.target.value)}  {...params} variant="outlined" />}
                                                />

                                            </div>
                                        </div>
                                    }
                                    {isComplementoPago && incluirCuentas === "Si" && <div class="dataRow">
                                        <p className="fixedP">RFC Cta.Ordenante</p>
                                        <div class="fieldInput">
                                            <input type="text" name='referencia' value={rfcCtaOrdenante} onChange={e => setRfcCtaOrdenante(e.target.value)} />
                                        </div>
                                    </div>}
                                    {isComplementoPago && incluirCuentas === "Si" && <div class="dataRow">
                                        <p className="fixedP">Cta.Ordenante</p>
                                        <div class="fieldInput">
                                            <input type="text" name='referencia' value={cuentaOrdenante} onChange={e => setCuentaOrdenante(e.target.value)} />
                                        </div>
                                    </div>}
                                    {isComplementoPago && incluirCuentas === "Si" && <div class="dataRow">
                                        <p className="fixedP">RFC Cta.Beneficiario</p>
                                        <div class="fieldInput">
                                            <input type="text" name='referencia' value={rfcCtaBeneficiario} onChange={e => setRfcCtaBeneficiario(e.target.value)} />
                                        </div>
                                    </div>}
                                    {isComplementoPago && incluirCuentas === "Si" && <div class="dataRow">
                                        <p className="fixedP">Cta.Beneficiario</p>
                                        <div class="fieldInput">
                                            <input type="text" name='referencia' value={cuentaBeneficiario} onChange={e => setCuentaBeneficiario(e.target.value)} />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div class="dataTable twoCol">
                                <div class="dataTitle"><p className="fixedP">Monto</p></div>
                                <div class="dataBox">
                                    <div class="dataRow">
                                        <p className="fixedP">Monto</p>
                                        <div class="fieldInput">
                                            <CurrencyInput value={monto} onChangeEvent={handleChangeCurrency} prefix="$" required />
                                        </div>
                                    </div>
                                    <div class="dataRow">
                                        <p className="fixedP">Moneda</p>
                                        <div class="fieldInput">
                                            <select name='segmento' onChange={(e) => setCurrencyMX(e.target.value == "1" ? true : false)} value={currencyMX ? "1" : "2"} required >
                                                <option key={'monedamxn'} value='1' selected>MXN</option>
                                                <option key={'monedausd'} value='2'>USD</option>
                                            </select>

                                        </div>
                                    </div>
                                    {
                                        !currencyMX &&
                                        <div class="dataRow">
                                            <p className="fixedP">Tipo de Cambio</p>
                                            <div class="fieldInput">
                                                <input style={{ width: '90%' }} type="number" step={0.01} name='referencia' value={dolarprice} onChange={e => setDolarPrice(e.target.value)} />

                                            </div>
                                        </div>
                                    }
                                    <div class="dataRow">
                                        <p className="fixedP">Forma de Pago</p>
                                        <div class="fieldInput">
                                            <select name='metodopago' onChange={(e) => setMetodoPago(e.target.value)} value={metodoPago} required >
                                                {
                                                    CATALOGO_METODOS_PAGO.map((item, index) => {
                                                        return <option key={index} value={item}>{item}</option>
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    <div class="dataRow">
                                        <p className="fixedP">Destino</p>
                                        <div class="fieldInput">
                                            <Autocomplete
                                                freeSolo
                                                options={destinosUser.map((option) => '' + option.nombre.toUpperCase())}
                                                value={destinoIngreso}
                                                className={"selectbusqueda"}
                                                onKeyPress={(e) => { e.key === 'Enter' && eventEnterOnFindCreate(e) }}
                                                onChange={e => setDestinoIngreso(e.target.innerText)}
                                                renderInput={(params) => (
                                                    <TextField {...params} name="metodopago" onChange={e => setDestinoIngreso(e.target.value)} value={destinoIngreso} variant="outlined" required />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div class="dataRow">
                                        <p className="fixedP">Complemento de Pago</p>
                                        <div class="fieldInput">
                                            <select name='segmento' onChange={(e) => setIsComplementoPago(e.target.value == "1")} value={isComplementoPago ? "1" : "2"} required >
                                                <option key={'sicomplemento'} value={"1"} >Si</option>
                                                <option key={'nocomplemento'} value={"2"}>No</option>
                                            </select>

                                        </div>
                                    </div>

                                    {isComplementoPago && razonesSociales.length > 1 && <div class="dataRow">
                                        <p className="fixedP">Razón Social</p>
                                        <div class="fieldInput">
                                            <select name='rfc' onChange={(e) => setRfc(e.target.value)} value={rfc} required >
                                                {
                                                    razonesSociales.map((item, index) => {
                                                        return <option key={index} value={item.Rfc}>{item.Razon}</option>
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>}
                                    {
                                        isComplementoPago && <div class="dataRow">
                                            <p className="fixedP">Uso CFDI</p>
                                            <div class="fieldInput">
                                                <select name='rfc' onChange={(e) => setUsoCFDI(e.target.value)} value={usoCFDI} required >
                                                    {
                                                        CAT_USOCFDI.map((item, index) => {
                                                            return <option key={index} value={`${item.Value} - ${item.Name}`} >{`${item.Value} - ${item.Name}`}</option>
                                                        })
                                                    }
                                                </select>

                                            </div>
                                        </div>
                                    }

                                    {isComplementoPago && <div class="dataRow">
                                        <p className="fixedP">Método de Pago</p>
                                        <div class="fieldInput">
                                            <select className={"form-control "} name='rfc' onChange={(e) => setTipoPago(e.target.value)} value={tipoPago} required >
                                                {
                                                    CAT_METODOSPAGO.map((item, index) => {
                                                        return <option key={index} value={`${item.Value} - ${item.Name}`} >{`${item.Value} - ${item.Name}`}</option>
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>}

                                    {isComplementoPago && <div class="dataRow">
                                        <p className="fixedP">IVA</p>
                                        <div class="fieldInput">
                                            <select name='rfc' value={ivatasa} onChange={e => setIvaTasa(e.target.value)} required >
                                                <option key={"iva16"} value={`16`} >{`16%`}</option>
                                                <option key={"iva08"} value={`8`} >{`8%`}</option>
                                                <option key={"iva00"} value={`0`} >{`Tasa 0`}</option>
                                            </select>

                                        </div>
                                    </div>}
                                    {isComplementoPago && <div class="dataRow">
                                        <p className="fixedP">Incluir cuentas</p>
                                        <div class="fieldInput">
                                            <select className={"form-control "} name='incluircuetnas' onChange={(e) => setIncluirCuentas(e.target.value)} value={incluirCuentas} required >
                                                <option key={1} value={`No`} >{`No`}</option>
                                                <option key={1} value={`Si`} >{`Si`}</option>
                                            </select>

                                        </div>
                                    </div>}

                                    <div class="dataRow">
                                        <p className="fixedP">Rango de Búsqueda</p>
                                        <div class="fieldInput">
                                            <DateRangePicker
                                                className={"selectbusqueda2"}
                                                clearIcon={null}
                                                value={[fechaRangoStart, fechaRangoEnd]}
                                                onChange={onChangeDaterangeTwo}
                                            />

                                        </div>
                                    </div>
                                    <div class="dataRow">
                                        <p className="fixedP"></p>
                                        <div class="fieldInput">

                                            <button type="button" role='button' style={{ marginRight: "7px", float: 'right' }} className="boton secBtn" onClick={() => cargarFacturasReft()}>Buscar Facturas</button>

                                        </div>
                                    </div>

                                    {
                                        uid &&
                                        <div class="dataRow">
                                            <p className="fixedP"></p>
                                            <div class="fieldInput">

                                                <Button
                                                    component="label"
                                                    className="btn btn-outline-dark btn-sm"
                                                    style={{
                                                        backgroundColor: "#2fa2fa",
                                                        color: "white",
                                                        marginRight: '10px'
                                                    }}
                                                >
                                                    EXCEL
                                                    <input
                                                        type="file"
                                                        accept={".xlsx, .xls"}
                                                        hidden
                                                        onChange={handleChangeFile}
                                                    />
                                                </Button>
                                            </div>
                                        </div>
                                    }

                                    {/*<div class="dataRow">
                                    <p className="fixedP">Buscar por No. CP</p>
                                    <div class="fieldInput">
                                        <input type="number" style={{ width: '80%' }} name='referencia' value={numCP} onChange={e => setNumCP(e.target.value)} />
                                        <button type='button' class="boton plus-button" onClick={() => {
                                            addFacturaPorCP();
                                        }} ></button>totalPagoFacturas > 0 ? `Total Pago Facturas:${totalPagoFacturas}` : ''
                                    </div>
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                        <div class="dataTable oneColmargin">
                            <div class="dataTitle"><p className="fixedP">Facturas Amparadas {`${facturas.length > 0 ? facturas.length : ""}`} - {totalPagoFacturas > 0 ? `Total Pago Facturas:${totalPagoFacturas}` : ''}</p>
                                {
                                    facturasReft.length > 0 ?
                                        <button type='button' class="boton plus-button" onClick={() => {
                                            addFactura();
                                        }} ></button> :
                                        <IconButton size="small" style={{ color: '#c7c7c7' }} className="btn-add-factura-amparada" disabled aria-label="agregar">
                                            <AddCircleIcon />
                                        </IconButton>
                                }

                            </div>

                            <div class="dataBox">
                                <div className="col-12">

                                    {
                                        facturas && facturas.map((element, index) => {
                                            return <div className="row" key={element.temid}>
                                                <div className="form-group col-2">
                                                    {index === 0 && <label>Referencia</label>}


                                                    {!isEdit && <Autocomplete
                                                        id="combo-box-demo0c"
                                                        options={facturasReft}
                                                        getOptionLabel={(option) => option.facturaname}
                                                        getOptionSelected={(option, value) => option.facturaname === value.facturaname}
                                                        defaultValue={facturasReft.find(ee => ee.id === element.id)}
                                                        style={{ height: '30px' }}
                                                        onChange={(e, val) => {
                                                            if (val && val.facturaname) {

                                                                changeElmentFactura(val.id, element, index)
                                                                // setCliente(val.name);
                                                            }
                                                        }}
                                                        renderInput={(params) => <TextField className="form-control" onChange={e => { }}  {...params} variant="outlined" />}
                                                    />}
                                                    {isEdit && <select className={"form-control "} name='clase' onChange={e => changeElmentFactura(e.target.value, element, index)} value={element.referencia} required >
                                                        {

                                                            facturasReft.map((item, index) => {
                                                                return <option key={index} value={item.id}>{item.facturaname}</option>
                                                            })

                                                        }
                                                    </select>}

                                                </div>
                                                <div className="form-group col-1">
                                                    {index === 0 && <label>Parcialidad</label>}
                                                    <input type='number' className="form-control" value={element.parcialidad} min={1} max={10} onChange={e => handleChangeParcialidadFactura(e.target.value, element)} />

                                                </div>
                                                <div className="form-group col-2">
                                                    {index === 0 && <label>Total Factura</label>}
                                                    <CurrencyInput className="form-control" disabled value={element.factura_monto} max={1000000} onChangeEvent={(event, maskedvalue, floatvalue) => { }} prefix="$" />

                                                </div>
                                                <div className="form-group col-2">
                                                    {index === 0 && <label>Pendientes</label>}
                                                    <input type="text" name='pendientes' className="form-control" value={"" + element.difpendiente + " de " + element.pendiente} onChange={() => console.log("")} disabled />
                                                </div>
                                                <div className="form-group col-2">
                                                    {index === 0 && <label>Insoluto</label>}
                                                    <CurrencyInput className="form-control" value={element.pendiente} max={1000000} onChangeEvent={(event, maskedvalue, floatvalue) => handleChangePendienteFactura(event, maskedvalue, floatvalue, element)} prefix="$" />
                                                </div>
                                                <div className="form-group col-2">
                                                    {index === 0 && <label>Monto Pago</label>}
                                                    <CurrencyInput className="form-control" value={element.monto} max={1000000} onChangeEvent={(event, maskedvalue, floatvalue) => handleChangeCurrencyFactura(event, maskedvalue, floatvalue, element)} prefix="$" />
                                                </div>
                                                <div className="form-group col-1">
                                                    {index === 0 && <label>Eliminar</label>}
                                                    <button type='button' class="minus-button" onClick={() => { removeFactura(element) }} />

                                                </div>
                                            </div>
                                        })
                                    }
                                </div>

                            </div>
                        </div>
                    </LoadingOverlay>
                    {
                        progreso > 0 && progreso < 100 && <BorderLinearProgress variant="determinate" value={progreso} />
                    }
                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Cancelar</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalCreateEdit;