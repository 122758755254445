import { firebase } from '../configs/firebase';
export const addResumen = async (data) => {
    var db = firebase.firestore();
    /*const batch = db.batch();
    data.resumenLineas.forEach((doc) => {
        var docRef = db.collection("resumen_user").doc(); //automatically generate unique id
        batch.set(docRef, { uid: data.uid, fecha: data.fecha, ...doc });
    });

    return batch.commit();*/
    let dataaux = { ...data, createAt: firebase.firestore.FieldValue.serverTimestamp(), changed: firebase.firestore.FieldValue.serverTimestamp()};
    return db.collection('resumen_user').add(dataaux);

}

export const addResumenRutas = async (data) => {
    var db = firebase.firestore();
    let dataaux = { ...data, createAt: firebase.firestore.FieldValue.serverTimestamp(), changed: firebase.firestore.FieldValue.serverTimestamp()};
    return db.collection('resumen_rutas').add(dataaux);

}

export const getResumenRutas = async (uid,fechames) => {
    var db = firebase.firestore();
    return db.collection('resumen_rutas').where("uid", "==", uid).where("fecha","==",fechames).orderBy('createAt','desc').limit(1).get();
}
export const getResumenUser = async (uid,fechames) => {
    var db = firebase.firestore();
    return db.collection('resumen_user').where("uid", "==", uid).where("fecha","==",fechames).orderBy('createAt','desc').limit(1).get();
}