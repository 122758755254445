import React, { useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';

const Rentabilidad = props => {
    const { vajeAsOBJ } = props;

    const [ingreso, setIngreso] = useState("$0.00");
    const [ingresoFloat, setIngresoFloat] = useState(0);
    const [totalFloat, setTotalFloat] = useState(0);
    const [total, setTotal] = useState("$0.00");
    const [otrosIngresosFloat, setOtrosIngresosFloat] = useState(0);
    const [otrosIngresos, setOtrosIngresos] = useState("$0.00");
    const [ingresokm, setIngresoKM] = useState("$0.00");
    const [ingresokmFloat, setIngresoKMFloat] = useState(0);
    const [km, setKM] = useState(0);

    const [ingresoDll, setIngresoDll] = useState("$0.00");
    const [ingresoFloatDll, setIngresoFloatDll] = useState(0);
    const [totalFloatDll, setTotalFloatDll] = useState(0);
    const [totalDll, setTotalDll] = useState("$0.00");
    const [otrosIngresosFloatDll, setOtrosIngresosFloatDll] = useState(0);
    const [otrosIngresosDll, setOtrosIngresosDll] = useState("$0.00");
    const [ingresokmDll, setIngresoKMDll] = useState("$0.00");
    const [ingresokmFloatDll, setIngresoKMFloatDll] = useState(0);
    const [millas, setMillas] = useState(0);

    const [dolarepresio, setDolarPrecio] = useState("");

    const convierteDivisa = (valor, factor) => {
        let ingresoPesosFloat = valor * factor;
        ingresoPesosFloat = ingresoPesosFloat.toFixed(2);
        let ipesosS = '$' + parseFloat(ingresoPesosFloat).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        let objresponse = { valuefloat: ingresoPesosFloat, valuestring: ipesosS };
        return objresponse;
    }
    useEffect(() => {
        if (vajeAsOBJ && vajeAsOBJ.detalles) {

            let preciodolar = vajeAsOBJ.dolarprice || 0.0;
            let preciodolarstring = '$' + parseFloat(preciodolar).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            setDolarPrecio(preciodolarstring);
            
            if (vajeAsOBJ.detalles.currencyMX === true) {
                //precio en pesos mexicanos

                setIngreso(vajeAsOBJ.detalles.tarifa);
                setIngresoFloat(vajeAsOBJ.detalles.tarifaFloat);



                setKM(vajeAsOBJ.carta_porte.distTotales_km);
                setMillas(vajeAsOBJ.carta_porte.distTotales_mi);


                let sumotros = 0.0;
                console.log("lineas de captura en rentabilidad", vajeAsOBJ.lineas_captura);
                vajeAsOBJ.lineas_captura.forEach(element => {
                    if (element.tipo === "INGRESO") {
                        sumotros += element.montoFloat;
                        console.log("sumando " + sumotros);
                    }
                });
                let otros = '$' + parseFloat(sumotros).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                setOtrosIngresos(otros);
                setOtrosIngresosFloat(sumotros);


                const conversionApesosotrosingresos = convierteDivisa(sumotros, (1 / preciodolar))
                setOtrosIngresosDll(conversionApesosotrosingresos.valuestring);
                setOtrosIngresosFloatDll(conversionApesosotrosingresos.valuefloat);

                //calculando ingreso en dolares
                const conversionApesos = convierteDivisa(vajeAsOBJ.detalles.tarifaFloat, parseFloat(1 / preciodolar))
                setIngresoDll(conversionApesos.valuestring);
                setIngresoFloatDll(conversionApesos.valuefloat);


                let sum = vajeAsOBJ.detalles.tarifaFloat;
                sum += sumotros;
                setTotalFloat(sum);


                let sumpesos = conversionApesos.valuefloat;

                sumpesos = parseFloat(sumpesos) + parseFloat(conversionApesosotrosingresos.valuefloat);
                // console.log("total",sumpesos);
                setTotalFloatDll(sumpesos);

                let ikm = parseFloat(sum / vajeAsOBJ.carta_porte.distTotales_km).toFixed(2);
                setIngresoKMFloat(ikm);
                let ikms = '$' + parseFloat(ikm).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                setIngresoKM(ikms);


                ikm = parseFloat(parseFloat(sumpesos) / vajeAsOBJ.carta_porte.distTotales_mi).toFixed(2);
                setIngresoKMFloatDll(ikm);
                ikms = '$' + parseFloat(ikm).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                setIngresoKMDll(ikms);

            } else {
                //precio en dolares
                setIngresoDll(vajeAsOBJ.detalles.tarifa);
                setIngresoFloatDll(vajeAsOBJ.detalles.tarifaFloat);

                setKM(vajeAsOBJ.carta_porte.distTotales_km);
                setMillas(vajeAsOBJ.carta_porte.distTotales_mi);


                let sumotros = 0.0;
                console.log("lineas de captura en rentabilidad", vajeAsOBJ.lineas_captura);
                vajeAsOBJ.lineas_captura.forEach(element => {
                    if (element.tipo === "INGRESO") {
                        sumotros += element.montoFloat;

                    }
                });

                let otros = '$' + parseFloat(sumotros).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                setOtrosIngresosDll(otros);
                setOtrosIngresosFloatDll(sumotros);

                const conversionApesosotrosingresos = convierteDivisa(sumotros, preciodolar)
                setOtrosIngresos(conversionApesosotrosingresos.valuestring);
                setOtrosIngresosFloat(conversionApesosotrosingresos.valuefloat);

                //calculando ingreso en pesos
                const conversionApesos = convierteDivisa(vajeAsOBJ.detalles.tarifaFloat, preciodolar)
                setIngreso(conversionApesos.valuestring);
                setIngresoFloat(conversionApesos.valuefloat);

                let sum = vajeAsOBJ.detalles.tarifaFloat;
                sum += sumotros;
                setTotalFloatDll(sum);

                let sumpesos = conversionApesos.valuefloat;

                sumpesos = parseFloat(sumpesos) + parseFloat(conversionApesosotrosingresos.valuefloat);
                // console.log("total",sumpesos);
                setTotalFloat(sumpesos);

                let ikm = parseFloat(parseFloat(sumpesos) / vajeAsOBJ.carta_porte.distTotales_km).toFixed(2);

                setIngresoKMFloat(ikm);

                let ikms = '$' + parseFloat(ikm).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                setIngresoKM(ikms);


                ikm = parseFloat(sum / vajeAsOBJ.carta_porte.distTotales_mi).toFixed(2);
                setIngresoKMFloatDll(ikm);
                ikms = '$' + parseFloat(ikm).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                setIngresoKMDll(ikms);
            }


        }
    }, [vajeAsOBJ])

    useEffect(() => {
        let totals = '$' + parseFloat(totalFloat).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        setTotal(totals);
    }, [totalFloat]);

    useEffect(() => {
        let totals = '$' + parseFloat(totalFloatDll).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        setTotalDll(totals);
    }, [totalFloatDll]);

    /* useEffect(() => {
         //console.log("seting ikm",ingresokmFloat);
         let totals = '$' + parseFloat(ingresokmFloat).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
         setIngresoKM(totals);
     }, [ingresokmFloat]);*/

    return <div>{vajeAsOBJ ?
        <div>
            <div className="row">
                <div className="col-12">
                <Alert severity="info">Calculado con tipo de cambio de dolar a la fecha de creación del viaje. Precio del dolar: {dolarepresio}</Alert>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="form-group col-12">
                        <label>Ingreso</label>
                        <input required type="text" name='ingreso' className="form-control" value={ingreso} onChange={e => console.log(e)} />
                    </div>
                    <div className="form-group col-12">
                        <label>Otros ingresos</label>
                        <input required type="text" name='ingreso' className="form-control" value={otrosIngresos} onChange={e => console.log(e)} />
                    </div>
                    <div className="form-group col-12">
                        <label>Total</label>
                        <input required type="text" name='ingreso' className="form-control" value={total} onChange={e => console.log(e)} />
                    </div>
                    <hr />
                    <div className="form-group col-12">
                        <label>KM totales</label>
                        <input required type="text" name='ingreso' className="form-control" value={km} onChange={e => console.log(e)} />
                    </div>
                    <div className="form-group col-12">
                        <label>Ingreso x KM</label>
                        <input required type="text" name='ingreso' className="form-control" value={ingresokm} onChange={e => console.log(e)} />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group col-12">
                        <label>Ingreso DLL</label>
                        <input required type="text" name='ingreso' className="form-control" value={ingresoDll} onChange={e => console.log(e)} />
                    </div>
                    <div className="form-group col-12">
                        <label>Otros ingresos DLL</label>
                        <input required type="text" name='ingreso' className="form-control" value={otrosIngresosDll} onChange={e => console.log(e)} />
                    </div>
                    <div className="form-group col-12">
                        <label>Total DLL</label>
                        <input required type="text" name='ingreso' className="form-control" value={totalDll} onChange={e => console.log(e)} />
                    </div>
                    <hr />
                    <div className="form-group col-12">
                        <label>Mi. totales</label>
                        <input required type="text" name='ingreso' className="form-control" value={millas} onChange={e => console.log(e)} />
                    </div>
                    <div className="form-group col-12">
                        <label>Ingreso x Mi</label>
                        <input required type="text" name='ingreso' className="form-control" value={ingresokmDll} onChange={e => console.log(e)} />
                    </div>
                </div>
            </div>
        </div>
        : <>
            Guarda los datos para obtener la rentabilidad
        </>} </div>;
};

export default Rentabilidad;