export const DBConfig = {
    name: 'transportplusdb',
    version: 20,
    objectStoresMeta: [
      {
        store: 'operadores',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: [
          { name: 'nombre', keypath: 'nombre', options: { unique: false } }
        ]
      },
      {
        store: 'activos',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: [
          { name: 'nombre', keypath: 'nombre', options: { unique: false },
          name: 'name', keypath: 'name', options: { unique: false },
          name: 'value', keypath: 'value', options: { unique: false },
          name: 'razon', keypath: 'razon', options: { unique: false },
          name: 'tipo', keypath: 'tipo', options: { unique: false },
          name: 'placas', keypath: 'placas', options: { unique: false },
           }
        ]
      },
      {
        store: 'clientes',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: [
          { name: 'name', keypath: 'name', options: { unique: false } },
          { name: 'value', keypath: 'value', options: { unique: false } },
          { name: 'alias', keypath: 'alias', options: { unique: false } }
        ]
      },
      {
        store: 'ubicaciones',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: [
          { name: 'name', keypath: 'name', options: { unique: false } },
          { name: 'value', keypath: 'value', options: { unique: false } },
          { name: 'localidad', keypath: 'localidad', options: { unique: false } },
          { name: 'localidadSat', keypath: 'localidadSat', options: { unique: false } },
          { name: 'estado', keypath: 'estado', options: { unique: false } },
          { name: 'estado_short', keypath: 'estado_short', options: { unique: false } },
          { name: 'municipioSat', keypath: 'municipioSat', options: { unique: false } },
          { name: 'municipio', keypath: 'municipio', options: { unique: false } },
          { name: 'colonia', keypath: 'colonia', options: { unique: false } },
          { name: 'coloniaSat', keypath: 'coloniaSat', options: { unique: false } },
          { name: 'internacional', keypath: 'internacional', options: { unique: false } },


        ]
      },
      {
        store: 'viajes',
        storeConfig: { keyPath: 'key', autoIncrement: false },
        storeSchema: [
          { name: 'id', keypath: 'id', options: { unique: false } },
          { name: 'n', keypath: 'n', options: { unique: false } },
          { name: 'index', keypath: 'index', options: { unique: false } },
          { name: 'fechaformat', keypath: 'fechaformat', options: { unique: false } },
          { name: 'origenformat', keypath: 'origenformat', options: { unique: false } },
          { name: 'destinoformat', keypath: 'destinoformat', options: { unique: false } },
          { name: 'nviaje', keypath: 'nviaje', options: { unique: false } },
          { name: 'status', keypath: 'status', options: { unique: false } },
          { name: 'ncarta', keypath: 'ncarta', options: { unique: false } },
          { name: 'foliofiscal', keypath: 'ncarta', options: { unique: false } },
          { name: 'recoleccion', keypath: 'recoleccion', options: { unique: false } },
          { name: 'entrega', keypath: 'entrega', options: { unique: false } },
          { name: 'fechaconsulta', keypath: 'fechaconsulta', options: { unique: false } },
          { name: 'operador', keypath: 'operador', options: { unique: false } },
          { name: 'unidad', keypath: 'unidad', options: { unique: false } },
          { name: 'cliente', keypath: 'cliente', options: { unique: false } },
          { name: 'subcliente', keypath: 'subcliente', options: { unique: false } },
          { name: 'auxiliar1', keypath: 'auxiliar1', options: { unique: false } },          
          { name: 'auxiliar2', keypath: 'auxiliar2', options: { unique: false } },
          { name: 'isSubViaje', keypath: 'isSubViaje', options: { unique: false } },
          { name: 'updatetime', keypath: 'updatetime', options: { unique: false } },
          { name: 'tipoviaje', keypath: 'tipoviaje', options: { unique: false } },
          { name: 'contenedor1', keypath: 'contenedor1', options: { unique: false } },
          { name: 'remolque1', keypath: 'remolque1', options: { unique: false } },
          { name: 'remolque2', keypath: 'remolque2', options: { unique: false } },
          { name: 'moneda', keypath: 'moneda', options: { unique: false } },
          { name: 'kms', keypath: 'kms', options: { unique: false } },
          { name: 'kmsreales', keypath: 'kmsreales', options: { unique: false } },
          { name: 'referencia', keypath: 'referencia', options: { unique: false } },
          { name: 'total', keypath: 'total', options: { unique: false } },
          { name: 'subTotal', keypath: 'subTotal', options: { unique: false } },
          { name: 'ingresoxkm', keypath: 'ingresoxkm', options: { unique: false } },
          { name: 'comentarios', keypath: 'comentarios', options: { unique: false } },
          { name: 'segmentoNegocio', keypath: 'segmentoNegocio', options: { unique: false } },
          { name: 'comisionoperador', keypath: 'comisionoperador', options: { unique: false } },
          { name: 'isFormatoNuevo', keypath: 'isFormatoNuevo', options: { unique: false } },
          { name: 'isViajeCargado', keypath: 'isViajeCargado', options: { unique: false } },
          { name: 'changed', keypath: 'changed', options: { unique: false } },
        
        ]
      }
    ]
  };

  /**
   *    name
   *  setOrigenId(origenOBJ.id);
                setOrigenCiudad(origenOBJ.localidad);
                setOrigenCiudadSat(origenOBJ.localidadSat || "");
                setOrigenEstado(origenOBJ.estado);
                setOrigenEstadoShort(origenOBJ.estado_short);
                setOrigenMunicipioSat(origenOBJ.municipioSat || "");
                setOrigenMunicipio(origenOBJ.municipio || "");
                setOrigenColonia(origenOBJ.colonia || "");
                setOrigenColoniaSat(origenOBJ.coloniaSat || "");
                internacional
   */