import { firebase } from '../configs/firebase';

export const getAllUsuarios = () => {
    var db = firebase.firestore();
    return db.collection('usuarios').get();
}

export const getUserByEmail = (email) => {
    var db = firebase.firestore();
    return db.collection('usuarios').where("email", "==", email).get();
}

export const getUserByUid = (uid) => {
    var db = firebase.firestore();
    return db.collection('usuarios').where("uid", "==", uid).get();
}

export const getUserDashboardByUid = (uid,fecha) => {
    var db = firebase.firestore();
    return db.collection('usuarios_dashboard').where("uid", "==", uid).where("fecha","==",fecha).orderBy("createAt","desc").limit(1).get();
}

export const addUserDashboard = (data) => {
    var db = firebase.firestore();
    return db.collection('usuarios_dashboard').add({...data,createAt: firebase.firestore.FieldValue.serverTimestamp(), changed: firebase.firestore.FieldValue.serverTimestamp()});
}
export const updateUserDashboard = (id, data) => {
    var db = firebase.firestore();
    let dataaux;
    dataaux = { ...data, updateAt: new Date(), changed: firebase.firestore.FieldValue.serverTimestamp() };
    return db.collection('usuarios_dashboard').doc(id).update(dataaux);
}


export const getUsuarioById = (id) => {
    var db = firebase.firestore();
    return db.collection('usuarios').doc(id).get();
}
export const addUsuario = (data) => {
    var db = firebase.firestore();
    return db.collection('usuarios').add(data);
}
export const firstLogin = (email, uid) => {
    var db = firebase.firestore();
    return db.collection('usuarios').add({ type: "user", email: email, uid: uid });
}

export const updateUsuario = (id, data) => {
    var db = firebase.firestore();
    return db.collection('usuarios').doc(id).update(data);
}
export const deleteUsuario = (id) => {
    var db = firebase.firestore();
    return db.collection('usuarios').doc(id).delete();
}

export const udpateSegmentoNegocio = (id, segmentos) => {
    var db = firebase.firestore();
    return db.collection('usuarios').doc(id).update({ segmento_negocio: [...segmentos] });


}
export const udpatePrecioDolar = (id, precio) => {
    var db = firebase.firestore();
    return db.collection('usuarios').doc(id).update({ dolarprice: precio });
}
export const updateUserOperadoresTime = (id) => {
    var db = firebase.firestore();
    return db.collection('usuarios').doc(id).update({ changedOperadores: firebase.firestore.FieldValue.serverTimestamp() });
}

export const updateUserOperadoresTimeByUid = async(uid) => {
    var db = firebase.firestore();
    await db.collection('usuarios').where("uid", "==", uid).get().then(resp => {
        if (resp.docs.length > 0) {
            db.collection('usuarios').doc(resp.docs[0].id).update({ changedOperadores: firebase.firestore.FieldValue.serverTimestamp() }).then(respupdate=>{}).catch(err=>{});
        }
    }).catch(err => { console.log(err) });    
}

export const updateViajesTime = (id) => {
    var db = firebase.firestore();
    return db.collection('usuarios').doc(id).update({ changedViajes: firebase.firestore.FieldValue.serverTimestamp() });
}
export const updateViajesTimeByUid = async (uid) => {
    var db = firebase.firestore();
    await getUserByUid(uid).then(async(snapshot)=>{
        if(snapshot.docs.length>0){
            await db.collection('usuarios').doc(snapshot.docs[0].id).update({ changedViajes: firebase.firestore.FieldValue.serverTimestamp() }).then(resp=>{

            }).catch(err=>{
                console.log(err);
            });
        }
    }).catch(err=>{
        console.log(err);
    });
   // return db.collection('usuarios').doc(id).update({ changedViajes: firebase.firestore.FieldValue.serverTimestamp() });
}


export const updateUserClientesTime = (id) => {
    var db = firebase.firestore();
    return db.collection('usuarios').doc(id).update({ changedClientes: firebase.firestore.FieldValue.serverTimestamp() });
}
export const updateUserClientesTimeByUid = async (uid) => {
    var db = firebase.firestore();
    await db.collection('usuarios').where("uid", "==", uid).get().then(resp => {
        if (resp.docs.length > 0) {
            db.collection('usuarios').doc(resp.docs[0].id).update({ changedClientes: firebase.firestore.FieldValue.serverTimestamp() }).then(respupdate=>{}).catch(err=>{});
        }
    }).catch(err => { console.log(err) });
    //  return db.collection('usuarios').where("uid","==",uid).update({ changedClientes: firebase.firestore.FieldValue.serverTimestamp()});
}
export const updateUserUbicacionesTime = (id) => {
    var db = firebase.firestore();
    return db.collection('usuarios').doc(id).update({ changedUbicaciones: firebase.firestore.FieldValue.serverTimestamp() });
}

export const updateUserUbicacionesTimeByUid =async (uid) => {
    var db = firebase.firestore();
    await db.collection('usuarios').where("uid", "==", uid).get().then(resp => {
        if (resp.docs.length > 0) {
            db.collection('usuarios').doc(resp.docs[0].id).update({ changedUbicaciones: firebase.firestore.FieldValue.serverTimestamp() }).then(respupdate=>{}).catch(err=>{});
        }
    }).catch(err => { console.log(err) });   
}
