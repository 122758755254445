import React, { Component } from 'react';
import { ProSidebar, Menu, MenuItem, SidebarHeader } from 'react-pro-sidebar';
//import DashIcon from '@material-ui/icons/List';
import MoneyIcon from '@material-ui/icons/AttachMoney';
//import sidebarBg from '../../img/bg3.png';
import sidebarBg from '../../img/bg2.png';
//import sidebarBg from '../../img/bg1.jpg';
import CategoryIcon from '@material-ui/icons/Category';
import SpeedIcon from '@material-ui/icons/Speed';
import BarChartIcon from '@material-ui/icons/BarChart';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PeopleIcon from '@material-ui/icons/People';
import Badge from '@material-ui/core/Badge';
import { ListenerPendientes } from '../../controllers/viajes';
import Swal from 'sweetalert2';
import { firebase } from '../../configs/firebase';
import { getUidMaster } from '../../session/session_controller';
import SideBarNew from './NewSideBar';

const Toast = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export default class ProSideBar extends Component {

  constructor(props) {
    super();
    let accessLevel;
    let aux = false;
    if (localStorage.getItem('marketpointmanager-user') && (localStorage.getItem('marketpointmanager-user') !== '')) {

      switch (localStorage.getItem('marketpointmanager-user')) {
        case 'user':
          accessLevel = 1;
          break;
        case 'gerencia':
          accessLevel = 2;
          break;
        case 'administrador':
          accessLevel = 4;
          break;
        default:
          accessLevel = 0;
          break;
      }
    }
    this.state = {
      accessLevel,
      cargasnuevas: 0
    }
  }




  componentDidMount = () => {
    const listen = async (uid) => {
      console.log("start listenign")  
          ListenerPendientes(uid).onSnapshot(snapshot => {
            if (snapshot && snapshot.docs) {
              if (snapshot.docs.length > 0) {
                Toast.fire({
                  icon: 'info',
                  title: 'Nuevo cargamento registrado en el portal de clientes..'
                })
              }
      
              this.setState({ cargasnuevas: snapshot.docs.length });
            }
          })
    }
    
    firebase.auth().onAuthStateChanged((userr) => {
      if (userr) {
        // setUidAltaEdit(userr.uid);
        let uiduser = getUidMaster() ? getUidMaster() :
          userr.uid;
        listen(uiduser);

      } else {
        // No user is signed in.
        localStorage.removeItem('marketpointmanagerid');
        localStorage.removeItem('marketpointmanager-user');
        window.location.href = '/';
      }
    });

  }

  render() {
    return (
      <ProSidebar
        collapsed={!this.props.isNotFolded}
        //style={{background:"#F00"}}
        //image={sidebarBg}
      >
        <SidebarHeader>
          {/*style={{backgroundColor:"#eaeaea"}}*/}
          <div className="headersidebar" >
            <img src={process.env.PUBLIC_URL + '/tpluslogonew.png'} width={'100%'} height={'auto'} className="lo  go-login" alt="Transport Plus" title="Transport Plus" />

          </div>

        </SidebarHeader>
        <SideBarNew />
        <Menu iconShape="square">
          <MenuItem icon={<BarChartIcon />} onClick={() => window.location.href = '/dashboard'} >Dashboard</MenuItem>
          <MenuItem icon={<AssignmentIcon />} onClick={() => window.location.href = '/planeacion'} >Planeación</MenuItem>
          <MenuItem icon={<Badge badgeContent={this.state.cargasnuevas} color="primary">
            <LocalShippingIcon />
          </Badge>} onClick={() => window.location.href = '/viajes'} >Viajes</MenuItem>
          <MenuItem icon={<DescriptionOutlinedIcon />} onClick={() => window.location.href = '/cartasporte'} >Cartas porte</MenuItem>
          <MenuItem icon={<MonetizationOnIcon />} onClick={() => window.location.href = '/facturas'} >Facturas</MenuItem>
          {this.state.accessLevel >= 0 && <MenuItem className="iconcostos" icon={<MoneyIcon />} onClick={() => window.location.href = '/costos'} >Costos</MenuItem>}
          {this.state.accessLevel >= 0 && <MenuItem className="iconingresos" icon={<LocalAtmIcon />} onClick={() => window.location.href = '/ingresos'} >Ingresos</MenuItem>}
          <MenuItem icon={<CategoryIcon />} onClick={() => window.location.href = '/activos'} >Activos</MenuItem>
          <MenuItem icon={<PeopleIcon />} onClick={() => window.location.href = '/nomina'} >Nómina</MenuItem>
          {this.state.accessLevel > 1 && <MenuItem icon={<SpeedIcon />} onClick={() => window.location.href = '/costoxkm'} >Costo x km.</MenuItem>}
          {this.state.accessLevel > 1 && <MenuItem icon={<AssessmentIcon />} onClick={() => window.location.href = '/rentabilidad'} >Rentabilidad</MenuItem>}
         
    </Menu>}
      </ProSidebar>
    );
  }
}
