import React, { useState, useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
//import { addIngreso, updateIngreso, addDestino, getAllDestinosByUser } from '../../../controllers/ingresos';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { addActivo, updateActivo } from '../../../controllers/activos';
import { getUnidades, updateActivosTimeByUid } from '../../../controllers/unidades';
import { getUserByUid } from '../../../controllers/usuarios';
import { getOperadores } from '../../../controllers/operadores';
import { getArrendadores } from '../../../controllers/clientes';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const requiereEdicionPlacas = ['HptPbvVxYYUjUzp7Nt3TtmyAt0x1'];
const ModalCreateEdit = (props) => {
    //constantes
    const [CATALOGOS_TIPOS, setCatalogotipos] = useState(['Remolque', 'Unidad', 'Dolly', 'Chasis', 'Otros']);
    const CATALOGOS_PROPIEDAD = ['Propio', 'Subcontratado', 'Arrendado', 'Intercambio', 'Otro'];
    const CAT_TIPOSPERMISO = [
        {
            codigo: "TPAF19",
            descripcion: "Autorización expresa para circular en los caminos y puentes de jurisdicción federal con configuraciones de tractocamión doblemente articulado.",
        },
        {
            codigo: "TPAF11",
            descripcion: "Autotransporte Federal de Carga Especializada cuyo ámbito de aplicación comprende la franja fronteriza con Estados Unidos.",
        },
        {
            codigo: "TPAF03",
            descripcion: "Autotransporte Federal de Carga Especializada de materiales y residuos peligrosos.",

        },
        {
            codigo: "TPAF10",
            descripcion: "Autotransporte Federal de Carga General cuyo ámbito de aplicación comprende la franja fronteriza con Estados Unidos.",

        },
        {
            codigo: "TPAF01",
            descripcion: "Autotransporte Federal de carga general.",

        },
        {
            codigo: "TPAF08",
            descripcion: "Autotransporte internacional de carga de largo recorrido.",

        },
        {
            codigo: "TPAF09",
            descripcion: "Autotransporte internacional de carga especializada de materiales y residuos peligrosos de largo recorrido.",

        },
        {
            codigo: "TPTA01",
            descripcion: "Concesión y/o autorización para el servicio regular nacional y/o internacional para empresas mexicanas",

        },
        {
            codigo: "TPAF18",
            descripcion: "Empresas fabricantes o distribuidoras de vehículos nuevos.",

        },
        {
            codigo: "TPAF17",
            descripcion: "Empresas trasladistas de vehículos nuevos.",

        },
        {
            codigo: "TPTA02",
            descripcion: "Permiso para el servicio aéreo regular de empresas extranjeras",

        },
        {
            codigo: "TPTA03",
            descripcion: "Permiso para el servicio nacional e internacional no regular de fletamento",

        },
        {
            codigo: "TPTA04",
            descripcion: "Permiso para el servicio nacional e internacional no regular de taxi aéreo",

        },
        {
            codigo: "TPTM01",
            descripcion: "Permiso temporal para navegación de cabotaje",

        },
        {
            codigo: "TPAF12",
            descripcion: "Servicio auxiliar de arrastre en las vías generales de comunicación.",

        },
        {
            codigo: "TPAF13",
            descripcion: "Servicio auxiliar de servicios de arrastre, arrastre y salvamento, y depósito de vehículos en las vías generales de comunicación.",

        },
        {
            codigo: "TPAF14",
            descripcion: "Servicio de paquetería y mensajería en las vías generales de comunicación.",

        },
        {
            codigo: "TPAF16",
            descripcion: "Servicio federal para empresas arrendadoras servicio público federal.",

        },
        {
            codigo: "TPAF04",
            descripcion: "Transporte de automóviles sin rodar en vehículo tipo góndola.",

        },
        {
            codigo: "TPAF05",
            descripcion: "Transporte de carga de gran peso y/o volumen de hasta 90 toneladas.",

        },
        {
            codigo: "TPAF06",
            descripcion: "Transporte de carga especializada de gran peso y/o volumen de más 90 toneladas.",

        },
        {
            codigo: "TPAF15",
            descripcion: "Transporte especial para el tránsito de grúas industriales con peso máximo de 90 toneladas.",

        },
        {
            codigo: "TPAF02",
            descripcion: "Transporte privado de carga.",

        },
        {
            codigo: "TPAF07",
            descripcion: "Transporte Privado de materiales y residuos peligrosos.",
        }
    ];
    const CAT_TIPOSUNIDAD = [
        {
            codigo: "",
            descripcion: "",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "VL",
            descripcion: "Vehículo ligero de carga (2 llantas en el eje delantero y 2 llantas en el eje trasero)",
            campo1: "2",
            campo2: "4",
            campo3: null,
            campo4: null
        },
        {
            codigo: "C2",
            descripcion: "Camión Unitario (2 llantas en el eje delantero y 4 llantas en el eje trasero)",
            campo1: "2",
            campo2: "6",
            campo3: null,
            campo4: null
        },
        {
            codigo: "C3",
            descripcion: "Camión Unitario (2 llantas en el eje delantero y 6 o 8 llantas en los dos ejes traseros)",
            campo1: "3",
            campo2: "8 o 10",
            campo3: null,
            campo4: null
        },
        {
            codigo: "C3R3",
            descripcion: "Camión-Remolque (10 llantas en el camión y 12 llantas en remolque)",
            campo1: "6",
            campo2: "22",
            campo3: null,
            campo4: null
        },
        {
            codigo: "C3R2",
            descripcion: "Camión-Remolque (10 llantas en el camión y 8 llantas en remolque)",
            campo1: "5",
            campo2: "18",
            campo3: null,
            campo4: null
        },
        {
            codigo: "C2R3",
            descripcion: "Camión-Remolque (6 llantas en el camión y 12 llantas en remolque)",
            campo1: "5",
            campo2: "18",
            campo3: null,
            campo4: null
        },
        {
            codigo: "C2R2",
            descripcion: "Camión-Remolque (6 llantas en el camión y 8 llantas en remolque)",
            campo1: "4",
            campo2: "14",
            campo3: null,
            campo4: null
        },
        {
            codigo: "OTROEGP",
            descripcion: "Especializado de Gran Peso",
            campo1: "",
            campo2: "",
            campo3: null,
            campo4: null
        },
        {
            codigo: "OTROEV",
            descripcion: "Especializado de Voluminoso",
            campo1: "",
            campo2: "",
            campo3: null,
            campo4: null
        },
        {
            codigo: "OTROSG",
            descripcion: "Servicio de Grúas",
            campo1: "",
            campo2: "",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T3S3",
            descripcion: "Tractocamión Articulado (10 llantas en el tractocamión, 12 llantas en el semirremolque)",
            campo1: "6",
            campo2: "22",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T3S1",
            descripcion: "Tractocamión Articulado (10 llantas en el tractocamión, 4 llantas en el semirremolque)",
            campo1: "4",
            campo2: "14",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T3S2",
            descripcion: "Tractocamión Articulado (10 llantas en el tractocamión, 8 llantas en el semirremolque)",
            campo1: "5",
            campo2: "18",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T2S3",
            descripcion: "Tractocamión Articulado (6 llantas en el tractocamión, 12 llantas en el semirremolque)",
            campo1: "5",
            campo2: "18",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T2S1",
            descripcion: "Tractocamión Articulado (6 llantas en el tractocamión, 4 llantas en el semirremolque)",
            campo1: "3",
            campo2: "10",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T2S2",
            descripcion: "Tractocamión Articulado (6 llantas en el tractocamión, 8 llantas en el semirremolque)",
            campo1: "4",
            campo2: "14",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T3S1R3",
            descripcion: "Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 4 llantas en el semirremolque y 12 llantas en el remolque)",
            campo1: "7",
            campo2: "26",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T3S1R2",
            descripcion: "Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 4 llantas en el semirremolque y 8 llantas en el remolque)",
            campo1: "6",
            campo2: "22",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T3S2R3",
            descripcion: "Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 8 llantas en el semirremolque y 12 llantas en el remolque)",
            campo1: "8",
            campo2: "30",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T3S2R4",
            descripcion: "Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 8 llantas en el semirremolque y 16 llantas en el remolque)",
            campo1: "9",
            campo2: "34",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T3S2R2",
            descripcion: "Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 8 llantas en el semirremolque y 8 llantas en el remolque)",
            campo1: "7",
            campo2: "26",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T2S1R3",
            descripcion: "Tractocamión Semirremolque-Remolque (6 llantas en el tractocamión, 4 llantas en el semirremolque y 12 llantas en el remolque)",
            campo1: "6",
            campo2: "22",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T2S1R2",
            descripcion: "Tractocamión Semirremolque-Remolque (6 llantas en el tractocamión, 4 llantas en el semirremolque y 8 llantas en el remolque)",
            campo1: "5",
            campo2: "18",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T2S2R2",
            descripcion: "Tractocamión Semirremolque-Remolque (6 llantas en el tractocamión, 8 llantas en el semirremolque y 8 llantas en el remolque)",
            campo1: "6",
            campo2: "22",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T3S3S2",
            descripcion: "Tractocamión Semirremolque-Semirremolque (10 llantas en el tractocamión, 12 llantas en el semirremolque delantero y 8 llantas en el semirremolque trasero)",
            campo1: "8",
            campo2: "30",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T3S2S2",
            descripcion: "Tractocamión Semirremolque-Semirremolque (10 llantas en el tractocamión, 8 llantas en el semirremolque delantero y 8 llantas en el semirremolque trasero)",
            campo1: "7",
            campo2: "26",
            campo3: null,
            campo4: null
        },
        {
            codigo: "T2S2S2",
            descripcion: "Tractocamión Semirremolque-Semirremolque (6 llantas en el tractocamión, 8 llantas en el semirremolque delantero y 8 llantas en el semirremolque trasero)",
            campo1: "6",
            campo2: "22",
            campo3: null,
            campo4: null
        }
    ];
    const CAT_TIPOREMOLQUE = [
        {
            codigo: "",
            descripcion: "",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR001",
            descripcion: "Caballete",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR002",
            descripcion: "Caja",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR003",
            descripcion: "Caja Abierta",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR004",
            descripcion: "Caja Cerrada",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR005",
            descripcion: "Caja De Recolección Con Cargador Frontal",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR006",
            descripcion: "Caja Refrigerada",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR007",
            descripcion: "Caja Seca",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR008",
            descripcion: "Caja Transferencia",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR009",
            descripcion: "Cama Baja o Cuello Ganso",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR010",
            descripcion: "Chasis Portacontenedor",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR011",
            descripcion: "Convencional De Chasis",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR012",
            descripcion: "Equipo Especial",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR013",
            descripcion: "Estacas",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR014",
            descripcion: "Góndola Madrina",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR016",
            descripcion: "Grúa",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR015",
            descripcion: "Grúa Industrial",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR017",
            descripcion: "Integral",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR018",
            descripcion: "Jaula",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR019",
            descripcion: "Media Redila",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR020",
            descripcion: "Pallet o Celdillas",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR021",
            descripcion: "Plataforma",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR022",
            descripcion: "Plataforma Con Grúa",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR023",
            descripcion: "Plataforma Encortinada",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR024",
            descripcion: "Redilas",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR025",
            descripcion: "Refrigerador",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR026",
            descripcion: "Revolvedora",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR027",
            descripcion: "Semicaja",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR028",
            descripcion: "Tanque",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR029",
            descripcion: "Tolva",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR030",
            descripcion: "Tractor",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR031",
            descripcion: "Volteo",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        },
        {
            codigo: "CTR032",
            descripcion: "Volteo Desmontable",
            campo1: null,
            campo2: null,
            campo3: null,
            campo4: null
        }
    ]

    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);

    const [isCreateActivoIntermodal, setIsCreateActivoIntermodal] = useState(null);

    //estados propios del formulario
    const [numEconomico, setNumEconomico] = useState("");
    const [tipo, setTipo] = useState("Remolque");
    const [marca, setMarca] = useState("");
    const [active, setActive] = useState(true);
    const [estado, setEstado] = useState("Activo");
    const [placas, setPlacas] = useState("");
    const [year, setYear] = useState("");
    const [propiedad, setPropiedad] = useState("Propio");
    const [aseguradora, setAseguradora] = useState("");
    const [pesoBrutoVehicular, setPesoBrutoVehicular] = useState(0);
    const [poliza, setPoliza] = useState("");
    const [aseguradoraMedioAmbiente, setAseguradoraMedioAmbiente] = useState("");
    const [polizaMedioAmbiente, setPolizaMedioAmbiente] = useState("");

    const [tipoSct, setTipoSct] = useState([]);
    const [sct, setSct] = useState("");
    const [tipoUnidad, setTipoUnidad] = useState("");
    const [tipoRemolque, setTipoRemolque] = useState("")

    //datos secundarios
    const [isMoreData, setIsMoreData] = useState(false);
    const [noSerie, setNoSerie] = useState("");
    const [modelo, setModelo] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [yearCompra, setYearCompra] = useState("");

    const [maxUnidades, setMaxUnidades] = useState(0);
    const [unidades, setUnidades] = useState([]);
    const [operadores, setOperadores] = useState([]);
    const [operador, setOperador] = useState("");

    const [usuarioobj, setUsuarioObj] = useState(null);
    const [segmentoNegocio, setSegmentoNegocio] = useState("");

    //datos de arrendador
    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState("");
    const [clienteId, setClienteId] = useState('');
    const [clienteNombre, setClienteNombre] = useState('');

    const [isComodin, setIsComodin] = useState(false);


    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);


    useEffect(() => {
        setIsOpen(props.isOpen);

        console.log(CAT_TIPOSUNIDAD.map(element => {
            return `${element.codigo} - ${element.descripcion}`
        }));
    }, []);


    const initValues = () => {

        getUserByUid(uid).then(snapshot => {
            let maxUnidadestoset = 0;
            let obuser = null;
            snapshot.forEach(doc => {
                let o = doc.data();
                obuser = { ...doc.data(), id: doc.id, segmento_negocio: ["", ...doc.data().segmento_negocio] }
                if (!obuser.segmento_negocio) {
                    obuser.segmento_negocio = [""];
                    if (obuser.comodines) {
                        setIsComodin(true);
                    }
                }

                o.id = doc.id;
                if (o.unidades && maxUnidadestoset < o.unidades) {
                    maxUnidadestoset = o.unidades;
                }
            });
            setUsuarioObj(obuser);
            setMaxUnidades(maxUnidadestoset);
        }).catch(err => {
            console.log(err);
        });

        getArrendadores(uid).then(snapshot => {
            let aux = [];
            snapshot.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                aux.push(o);
            });
            let toset = aux.map((item, index) => {
                let ob = { ...item, name: item.nombre + " - " + item.estado_short, value: item.id, id: "user" + index };
                return ob;
            });
            setClientes(toset);
        }).catch(err => { });

        getOperadores(uid).then(snapshot => {
            let aux = [];
            snapshot.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                aux.push(o);
            });
            setOperadores(aux);
        }).catch(err => { console.log(err) });

        getUnidades(uid).then(snapshot => {
            let aux = [];
            snapshot.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                aux.push(o);
            });
            setUnidades(aux);
        }).catch(err => { });

        if (isEdit && recordedit != null) {
            setIsMoreData(recordedit.isMoreData);
            setNumEconomico(recordedit.numEconomico || "");
            setTipo(recordedit.tipo || "Remolque");
            setMarca(recordedit.marca || "");
            setActive(recordedit.active);
            setEstado(recordedit.estado || "Activo");
            setPlacas(recordedit.placas || "");
            setOperador(recordedit.operador || "");
            setYear(recordedit.year || "");
            setPropiedad(recordedit.propiedad || "Propio");
            setYearCompra(recordedit.yearCompra || "");
            setNoSerie(recordedit.noSerie || "");
            setModelo(recordedit.modelo || "");
            setDescripcion(recordedit.descripcion || "");

            //  setKM(recordedit.km || 0);
            setSegmentoNegocio(recordedit.segmentoNegocio || "");
            setAseguradora(recordedit.aseguradora || "");
            setPoliza(recordedit.poliza || "");
            setAseguradoraMedioAmbiente(recordedit.aseguradoraMedioAmbiente || "");
            setPolizaMedioAmbiente(recordedit.polizaMedioAmbiente || "");
            setPesoBrutoVehicular(recordedit.pesoBrutoVehicular||0);


            setSct(recordedit.sct || "");
            setTipoSct(recordedit.tipoSct || "TPAF02");
            setTipoUnidad(recordedit.tipoUnidad || "");
            setTipoRemolque(recordedit.tipoRemolque || "");
            setClienteId(recordedit.arrendadorId);
            setCliente(recordedit.cliente);

            if (recordedit.comodin || recordedit.propiedad === "Subcontratado") {
                setIsComodin(true);
            } else {
                setIsComodin(false);
            }

        } else {
            setIsMoreData(false);
            setNumEconomico("");
            setTipo("Remolque");
            setMarca("");
            setActive(true);
            setEstado("Activo");
            setPlacas("");
            setYear("");
            setPropiedad("Propio");
            setYearCompra("");
            setNoSerie("");
            setModelo("");
            setDescripcion("");
            setSegmentoNegocio("");
            // setKM(0);
            setAseguradora("");
            setPoliza("");
            setAseguradoraMedioAmbiente("");
            setPolizaMedioAmbiente("");
            setSct("");
            setTipoUnidad("");
            setTipoRemolque("");
            setTipoSct("TPAF02");
            setClienteId("");
            setCliente("");

        }
    }

    useEffect(() => {
        if (isCreateActivoIntermodal) {
            setCatalogotipos(['Remolque', 'Unidad', 'Dolly', 'Chasis', 'Otros', 'Intermodal']);
        } else {
            setCatalogotipos(['Remolque', 'Unidad', 'Dolly', 'Chasis', 'Otros']);
        }
    }, [isCreateActivoIntermodal])
    useEffect(() => {
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);

        setIsCreateActivoIntermodal(props.isCreateActivoIntermodal);

        // console.log("herree",props.isCreateActivoIntermodal);


    }, [props]);

    useEffect(() => {
        if (recordedit) {
            initValues();
        }

    }, [recordedit]);


    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {

        }
    }, [isOpen]);

    useEffect(() => {
        if (tipo && tipo === "Unidad") {

        }
    }, [tipo]);

    const handleSumbit = async (e) => {
        e.preventDefault();
        //setIsOpen(false);

        if (!isEdit && maxUnidades <= unidades.length && tipo === "Unidad") {
            Swal.fire("Limite de unidades", `Limite de unidades, tu cuenta solo tiene permitido crear ${maxUnidades} unidades`, 'error');
            return;
        }

        let obj = {
            numEconomico: numEconomico ? numEconomico.trim() : '',
            nombre: numEconomico ? numEconomico.trim() : '',
            tipo,
            marca,
            active: estado === "Activo" ? true : false,
            estado,
            placas,
            year,
            propiedad,
            isMoreData,
            operador: operador !== "" ? operador : null,
            noSerie,
            modelo,
            km: 0,
            yearCompra,
            descripcion,
            segmentoNegocio,
            aseguradora,
            poliza,
            aseguradoraMedioAmbiente,
            polizaMedioAmbiente,
            tipoUnidad,
            tipoRemolque,
            pesoBrutoVehicular,
            arrendadorId: clienteId || '',
            cliente: cliente || "",
            sct,
            tipoSct,
            uid,
        }

        if (isAdmin && adminCurrentUser) {
            obj.uid = adminCurrentUser;
        }


        if (isEdit && recordedit.id) {
            // alert(recordedit.id);
            obj.useredit = uidAltaEdit || uid;
            setIsLoading(true);
            updateActivo(recordedit.id, obj).then(resp => {
                updateActivosTimeByUid(uid);
                setIsLoading(false);
                setIsOpen(false);
                props.notify({ ...obj, id: recordedit.id });
                Swal.fire('Éxito', 'El activo se ha actualizado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                console.log(err);
                Swal.fire("Error", "Error de al actualizar el activo, comprueba tu conexión ", 'error');
            })
        } else if (!isEdit) {
            obj.useralta = uidAltaEdit || uid;
            setIsLoading(true);
            addActivo(obj).then(resp => {
                updateActivosTimeByUid(uid);
                setIsLoading(false);
                setIsOpen(false);
                props.notify({ ...obj, id: resp.id });
                Swal.fire('Éxito', 'El activo se ha registrado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                Swal.fire("Error", "Error de al registrar el activo, comprueba tu conexión ", 'error');
            })
        }
        //validaciones de formulario


    }

    useEffect(() => {
        if (cliente && cliente != "" && clientes.length > 0) {
            let objf = clientes.find(element => element.name.trim() === cliente.trim());
            if (objf) {
                if (objf.value != clienteId) {
                    // console.log("cliente info", objf);
                    setClienteId(objf.value);
                }
            } else {
                console.log("no se encontro cliente ID")
            }
        }
    }, [cliente]);

    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Añadir Unidad, Remolque, Dolly, etc." : `Editar activo `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP"> Activo</p></div>
                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Tipo</p>
                                    <div class="fieldInput">
                                        {!isEdit && <select name='tipo' onChange={(e) => setTipo(e.target.value)} value={tipo} required >
                                            {
                                                CATALOGOS_TIPOS.map((item, index) => {
                                                    return <option key={index} value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                        }
                                        {isEdit && <select disabled name='tipo' onChange={(e) => setTipo(e.target.value)} value={tipo} required >
                                            {
                                                CATALOGOS_TIPOS.map((item, index) => {
                                                    return <option key={index} value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                        }

                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">Número Económico</p>
                                    <div class="fieldInput">
                                        {
                                            (!isEdit || tipo !== 'Unidad' || isComodin) && <input required type="text" name='cliente' value={numEconomico} onChange={e => setNumEconomico(e.target.value)} />

                                        }
                                        {
                                            isEdit && !isComodin && tipo === 'Unidad' && <input required disabled type="text" name='cliente' value={numEconomico} onChange={e => console.log(e.target.value)} />

                                        }

                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">Marca</p>
                                    <div class="fieldInput">
                                        <input type="text" name='marca' value={marca} onChange={e => setMarca(e.target.value)} />

                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">Año</p>
                                    <div class="fieldInput">
                                        <input type="number" style={{ width: '90%' }} min="1900" max="2500" name='year' value={year} onChange={e => setYear(e.target.value)} />
                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">Placas</p>
                                    <div class="fieldInput">
                                        {
                                            (!isEdit || tipo !== 'Unidad' || isComodin || requiereEdicionPlacas.find(fr => fr === uid)) && <input type="text" name='placas' value={placas} onChange={e => setPlacas(e.target.value)} />

                                        }
                                        {
                                            isEdit && !isComodin && tipo === 'Unidad' && !requiereEdicionPlacas.find(fr => fr === uid) && <input disabled type="text" name='placas' value={placas} onChange={e => console.log(e.target.value)} />

                                        }

                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">Segmento de Negocio</p>
                                    <div class="fieldInput">

                                        <select name='segmento' onChange={(e) => setSegmentoNegocio(e.target.value)} value={segmentoNegocio} required >
                                            {
                                                usuarioobj && usuarioobj.segmento_negocio.map((item, index) => {

                                                    return segmentoNegocio == item.value ?
                                                        <option key={index} value={item.value} selected>{item.value}</option> :
                                                        <option key={index} value={item.value}>{item.value}</option>

                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/*<div class="dataRow">
                                    <p className="fixedP">Estatus</p>
                                    <div class="fieldInput">
                                        <select name='segmento' onChange={(e) => setActive(e.target.value == "1")} value={active ? "1" : "2"} required >
                                            <option key={'sistatus'} value={"1"} >Activo</option>
                                            <option key={'nostatus'} value={"2"}>Inactivo</option>
                                        </select>
                                    </div>
                                        </div>*/}
                                {
                                    tipo && tipo === "Unidad" && <div class="dataRow">
                                        <p className="fixedP">Peso Bruto Vehicular</p>
                                        <div class="fieldInput" >
                                            <input type="number" style={{width:'90%'}} min={0} step={0.01} max={50} name='pesobrutovehicular' value={pesoBrutoVehicular} onChange={e => setPesoBrutoVehicular(e.target.value)} />
                                        </div>
                                    </div>
                                }
                                <div class="dataRow">
                                    <p className="fixedP">Estatus</p>
                                    <div class="fieldInput">
                                        <select name='segmento' onChange={(e) => setEstado(e.target.value)} value={estado} required >
                                            <option key={'sistatus'} value={"Activo"} >Activo</option>
                                            <option key={'mantenimiento'} value={"Mantenimiento"} >Bajo Mantenimiento</option>
                                            <option key={'siniestrado'} value={"Siniestrado"} >Siniestrado</option>
                                            <option key={'vendido'} value={"Vendido"} >Vendido</option>
                                            <option key={'robado'} value={"Robado"} >Robado</option>
                                            <option key={'nostatus'} value={"Inactivo"}>Inactivo</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP"> Activo</p></div>
                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Propiedad</p>
                                    <div class="fieldInput">
                                        {
                                            (!isEdit || tipo !== 'Unidad') && <select name='propiedad' onChange={(e) => setPropiedad(e.target.value)} value={propiedad} required >
                                                {
                                                    CATALOGOS_PROPIEDAD.map((item, index) => {
                                                        return <option key={index} value={item}>{item}</option>
                                                    })
                                                }
                                            </select>

                                        }
                                        {
                                            isEdit && tipo === 'Unidad' && <select disabled name='propiedad' onChange={(e) => setPropiedad(e.target.value)} value={propiedad} required >
                                                {
                                                    CATALOGOS_PROPIEDAD.map((item, index) => {
                                                        return <option key={index} value={item}>{item}</option>
                                                    })
                                                }
                                            </select>

                                        }
                                    </div>
                                </div>

                                {
                                    propiedad === "Arrendado" && <div class="dataRow">
                                        <p className="fixedP">Arrendador</p>
                                        <div class="fieldInput">
                                            <Autocomplete
                                                id="combo-box-demo0c"
                                                options={clientes}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option, value) => option.name === value.name}
                                                defaultValue={clientes.find(element => element.name.trim() === cliente.trim())}
                                                className={"selectbusqueda"}
                                                style={{ padding: 0 }}
                                                onChange={(e, val) => setCliente(val ? val.name : "")}
                                                renderInput={(params) => <TextField className="reducedselect" onChange={e => setCliente(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    tipo && tipo === "Remolque" && <div class="dataRow">
                                        <p className="fixedP">Tipo de Remolque</p>
                                        <div class="fieldInput">
                                            <select name='tiposct' onChange={(e) => setTipoRemolque(e.target.value)} value={tipoRemolque} required >
                                                {
                                                    CAT_TIPOREMOLQUE.map((item, index) => {
                                                        return <option key={index} value={item.codigo}>{item.codigo + " - " + item.descripcion}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                }
                                {
                                    tipo && tipo === "Unidad" && <div class="dataRow">
                                        <p className="fixedP">Tipo de Transporte</p>
                                        <div class="fieldInput">
                                            <select name='tiposct' onChange={(e) => setTipoUnidad(e.target.value)} value={tipoUnidad} required >
                                                {
                                                    CAT_TIPOSUNIDAD.map((item, index) => {
                                                        return <option key={index} value={item.codigo}>{item.codigo + " - " + item.descripcion}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                }
                                {
                                    tipo && tipo === "Unidad" && <div class="dataRow">
                                        <p className="fixedP">Aseguradora</p>
                                        <div class="fieldInput">
                                            <input type="text" name='aseguradora' value={aseguradora} onChange={e => setAseguradora(e.target.value)} />
                                        </div>
                                    </div>
                                }
                                {
                                    tipo && tipo === "Unidad" && <div class="dataRow">
                                        <p className="fixedP">Póliza</p>
                                        <div class="fieldInput">
                                            <input type="text" name='poliza' value={poliza} onChange={e => setPoliza(e.target.value)} />
                                        </div>
                                    </div>
                                }
                                {
                                    tipo && tipo === "Unidad" && <div class="dataRow">
                                        <p className="fixedP">Aseg. Medio Ambiente</p>
                                        <div class="fieldInput">
                                            <input type="text" name='placas' value={aseguradoraMedioAmbiente} onChange={e => setAseguradoraMedioAmbiente(e.target.value)} />
                                        </div>
                                    </div>
                                }
                                {
                                    tipo && tipo === "Unidad" && <div class="dataRow">
                                        <p className="fixedP">Póliza. Medio Ambiente</p>
                                        <div class="fieldInput">
                                            <input type="text" name='placas' value={polizaMedioAmbiente} onChange={e => setPolizaMedioAmbiente(e.target.value)} />
                                        </div>
                                    </div>
                                }
                                {
                                    tipo && tipo === "Unidad" && <div class="dataRow">
                                        <p className="fixedP">Tipo Permiso SCT </p>
                                        <div class="fieldInput">
                                            <select name='tiposct' onChange={(e) => setTipoSct(e.target.value)} value={tipoSct} required >
                                                {
                                                    CAT_TIPOSPERMISO.map((item, index) => {
                                                        return <option key={index} value={item.codigo}>{item.codigo + " - " + item.descripcion}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                }
                                {
                                    tipo && tipo === "Unidad" && <div class="dataRow">
                                        <p className="fixedP">No. Permiso SCT </p>
                                        <div class="fieldInput">
                                            <input type="text" name='placas' value={sct} onChange={e => setSct(e.target.value)} />
                                        </div>
                                    </div>
                                }
                                {
                                    tipo && tipo === "Unidad" && <div class="dataRow">
                                        <p className="fixedP">Operador</p>
                                        <div class="fieldInput">
                                            <select name='propiedad' onChange={(e) => setOperador(e.target.value)} value={operador} required >
                                                {
                                                    operadores.map((item, index) => {
                                                        return <option key={index} value={item.id}>{item.nombre + " " + item.apellidos}</option>
                                                    })
                                                }
                                                <option key={"nooperador"} value={""}>{""}</option>
                                            </select>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>


                    </div>


                    <hr></hr>
                    {
                        isMoreData ? <div className="btn" onClick={e => setIsMoreData(false)}> Mostrar menos </div> : <div className="btn" onClick={e => setIsMoreData(true)}> Mostrar más </div>
                    }

                    {
                        isMoreData && <div>

                            <div class="dataTable twoCol">

                                <div class="dataTitle"><p className="fixedP">Datos Extra</p></div>
                                <div class="dataBox">
                                    <div class="dataRow">
                                        <p className="fixedP">No. de Serie</p>
                                        <div class="fieldInput">
                                            <input type="text" name='noserie' value={noSerie} onChange={e => setNoSerie(e.target.value)} />
                                        </div>
                                    </div>
                                    <div class="dataRow">
                                        <p className="fixedP">Modelo</p>
                                        <div class="fieldInput">
                                            <input type="text" name='modelo' value={modelo} onChange={e => setModelo(e.target.value)} />
                                        </div>
                                    </div>
                                    <div class="dataRow">
                                        <p className="fixedP">Año de compra</p>
                                        <div class="fieldInput">
                                            <input type="text" name='yearcompra' value={yearCompra} onChange={e => setYearCompra(e.target.value)} />
                                        </div>
                                    </div>
                                    <div class="dataRow">
                                        <p className="fixedP">Descripción</p>
                                        <div class="fieldInput">
                                            <input type="text" name='descripcion' value={descripcion} onChange={e => setDescripcion(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalCreateEdit;