import { firebase } from '../configs/firebase';
export const getGetTarifarioById = (id) => {
    var db = firebase.firestore();
    return db.collection("tarifario").doc(id).get();
}

export const getTarifarios = (uid) => {
    var db = firebase.firestore();
    return db.collection("tarifario").where("uid", "==", uid).get();
}

export const getTarifarioByViajeData = (uid, clienteId, segmento, origen, destino) => {
    var db = firebase.firestore();
    return db.collection("tarifario")
        .where("uid", "==", uid)
        .where("clienteId", "==", clienteId)
        .where("segmentoNegocio", "==", segmento)
        .where("origenId", "==", origen)
        .where("destinoId", "==", destino).get();
}


export const getTarifarioByRuta = (uid, ruta, segmento) => {
    var db = firebase.firestore();
    return db.collection("tarifario").where("uid", "==", uid).where("ruta", "==", ruta).where("segmentoNegocio", "==", segmento).get();
}


export const addTarifario = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('tarifario').add(dataaux);
}

export const updateTarifario = (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date() };
    var db = firebase.firestore();
    return db.collection('tarifario').doc(id).update(dataaux);
}

export const deleteTarifario = (id) => {
    var db = firebase.firestore();
    return db.collection('tarifario').doc(id).update({uid:"deleted"});
}
