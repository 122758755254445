import React, { Component, Fragment } from 'react';
import TableUsers from './tableusers';
import { getAllUsuarios, addUsuario, updateUsuario, deleteUsuario } from './../../controllers/usuarios';
import LoadingOverlay from 'react-loading-overlay';
//import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createNewUser } from '../../session/session_controller';
import Swal from 'sweetalert2';

export default class index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            isLoading: false,
            isModalOpen: false,
            isEdit: false,
            //Datos del formulario
            email: '',
            password: '',
            type: 'administrador',
            id: '',
            index: '',
        }
    }
    createKeywords = (value) => {
        let name = value.toUpperCase();
        const arrName = [];

        let curName = '';
        name.split('').forEach((letter) => {
            curName += letter;
            arrName.push(curName);
        });

        let arrpalabras = name.split(' ');

        if (arrpalabras.length > 1) {
            //omitimos primer palabra que ya esta ingresada
            for (let index = 1; index < arrpalabras.length; index++) {
                const element = arrpalabras[index];
                curName = '';
                element.split('').forEach(letter => {
                    let auxCurName = '';
                    auxCurName += letter;
                    arrName.push(auxCurName);
                });
            }
        }
        if (arrpalabras.length > 2) {
            let aux = '';
            for (let index = 1; index < arrpalabras.length; index++) {
                const element = arrpalabras[index];
                aux += element + " ";
            }
            aux = aux.trim();
            curName = '';
            aux.split('').forEach((letter) => {
                curName += letter;
                arrName.push(curName);
            });
        }

        const uniqueSet = new Set(arrName);
        const backtoarray = [...uniqueSet];

        return backtoarray;
    }

    /**
     * fin test
     */

    openModal = () => {
        alert("here");
        this.setState({
            isModalOpen: true,
            isEdit: false,
            email: '',
            password: '',
            type: 'administrador',
            index: '',
        });
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    handleDelete = (record) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar el usuario seleccionado?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Accept',
        }).then((result) => {
            if (result.value) {
                this.setState({ isLoading: true }, () => {
                    this.closeModal();
                    deleteUsuario(record.index).then(res => {
                        this.setState({ isLoading: false }, () => {
                            Swal.fire('¡Éxito!', 'El usuario ha sido eliminado con éxito.', 'success');
                            this.getData();
                        });

                    }).catch(e => {
                        console.log(e);
                    });
                });

            }
        });
    }

    handleEdit = (record) => {
        this.setState({
            isModalOpen: true,
            isEdit: true,
            email: record.email,
            password: '',
            type: record.type,
            id: record.id,
            index: record.index,
        });
    }

    getData = () => {
        this.setState({ isLoading: true }, () => {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                this.setState({ users: a, isLoading: false, });
            }).catch(e => {
                console.log(e);
                this.setState({isLoading:false,})
            });
        });

    }


    componentDidMount = () => {
        this.getData();
    }

    closeModal = () => {
        this.setState({ isModalOpen: false });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.closeModal();
        if (this.state.isEdit) {
            //Solamente se actualiza el tipo de usuario
            let o = {
                email: this.state.email,
                id: this.state.id,
                type: this.state.type,
            }
            updateUsuario(this.state.index, o).then(res => {
                this.closeModal();
                Swal.fire('¡Éxito!', 'El tipo de usuario ha sido actualizado con éxito.', 'success');
                this.getData();
            }).catch(e => {
                console.log(e);
            });


        } else {
            this.setState({ isLoading: true }, () => {
                createNewUser(this.state.email, this.state.password).then(user => {
                    let o = {
                        email: this.state.email,
                        id: user.user.uid,
                        type: this.state.type,
                    }
                    addUsuario(o).then(res => {
                        this.closeModal();
                        this.setState({ isLoading: false });
                        Swal.fire('¡Éxito!', 'El usuario ha sido agregado con éxito.', 'success');
                        this.getData();
                    }).catch(e => {
                        console.log(e);
                    });
                }).catch(e => {
                    console.log(e);
                });
            });


        }
    }

  render() {
    return (
      <Fragment>
          {
                   <div className="row">
                   <div className="col-12">
                       <div className="card">
                           <div className="card-header card-header-flex">
                               <div className="d-flex px-3 row justify-content-between align-items-center">
                                   <div className="col-auto pl-0">
                                       <h4 className="mb-0">Usuarios</h4>
                                   </div>
                                   <div>
                                       <button type="button" className="btn btn-primary btn-sm" onClick={this.openModal}>Agregar un usuario</button>
                                   </div>
                               </div>
                           </div>
                           <LoadingOverlay
                               active={this.state.isLoading}
                               spinner
                               text='Actualizando datos, espere ...'
                           >
                               <div className="card-body">
                                   <TableUsers records={this.state.users} editHandler={this.handleEdit} deleteHandler={this.handleDelete}></TableUsers>
                               </div>
                           </LoadingOverlay>
                       </div>
                   </div>
               </div>
           
          }
      </Fragment>
    );
  }
}