import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const requiereSubliente = ['HptPbvVxYYUjUzp7Nt3TtmyAt0x1','31RxHqtBOmbgOWWeMCKnb1DuEHc2'];

export default class Download extends React.Component {
    constructor(props) {
        super();
        let { data } = props;
        let {uid}=props;
        this.state = {
            dataSet1: data,
            uid
        }
        //   console.log(this.state);

    }

    componentDidUpdate = (prevProps) => {

    }

    //<ExcelColumn label="ID" value="id" />   
    // <ExcelColumn label="ComisionOperador" value="comisionOperador" />     
    render() {
        if(this.state.uid && requiereSubliente.find(rs=>rs===this.state.uid)){
            return (
                <ExcelFile  element={<div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>}>
                    <ExcelSheet data={this.state.dataSet1} name="Tarifas">
                    <ExcelColumn label="ID" value="id" /> 
                        <ExcelColumn label="Cliente" value="cliente" />
                        <ExcelColumn label="Subcliente" value="subcliente"/>
                        <ExcelColumn label="Segmento" value="segmentoNegocio" />
                        <ExcelColumn label="Origen" value="origen" />
                        <ExcelColumn label="Destino" value="destino" />
                        <ExcelColumn label="Ruteo" value="ruta" />
                        <ExcelColumn label="Tarifa" value="tarifa" />
                        <ExcelColumn label="Moneda" value="moneda" />
                        
                    </ExcelSheet>
    
                </ExcelFile>
            );
        }else{
            return (
                <ExcelFile  element={<div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>}>
                    <ExcelSheet data={this.state.dataSet1} name="Tarifas">
                         <ExcelColumn label="ID" value="id" /> 
                        <ExcelColumn label="Cliente" value="cliente" />
                        <ExcelColumn label="Segmento" value="segmentoNegocio" />
                        <ExcelColumn label="Origen" value="origen" />
                        <ExcelColumn label="Destino" value="destino" />
                        <ExcelColumn label="Ruteo" value="ruta" />
                        <ExcelColumn label="Tarifa" value="tarifa" />
                        <ExcelColumn label="Moneda" value="moneda" />
    
                    </ExcelSheet>
    
                </ExcelFile>
            );
        }

       
    }
}
