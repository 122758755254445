import { firebase } from '../configs/firebase';
export const getInvoiceByID=id=>{
    var db = firebase.firestore();
    return db.collection("invoices").doc(id).get();
}
export const addInvoice = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('invoices').add(dataaux);
}

export const getInvoicesDateFilter= (uid,init,end) => {
    init.setHours(0, 0, 0);
    end.setHours(23, 59, 0);
    var db = firebase.firestore();
    return db.collection("invoices").where("uid", "==", uid).where("createAt", ">=", init).where("createAt", "<=", end).get();
}

export const getCfdiSolos= (uid,init,end) => {
    init.setHours(0, 0, 0);
    end.setHours(23, 59, 0);
    var db = firebase.firestore();
    return db.collection("cfdinormal").where("uid", "==", uid).where("fecha_facturado", ">=", init).where("fecha_facturado", "<=", end).get();
}

export const getCfdiSolosByCliente= (uid,init,end,clienteId) => {
    init.setHours(0, 0, 0);
    end.setHours(23, 59, 0);
    var db = firebase.firestore();
    return db.collection("cfdinormal").where("uid", "==", uid).where("clienteId","==",clienteId).where("fecha_facturado", ">=", init).where("fecha_facturado", "<=", end).get();
}

export const getCfdiById= (id) => {
    var db = firebase.firestore();
    return db.collection('cfdinormal').doc(id).get();
}

export const updateCfdiById= (id,data) => {
    var db = firebase.firestore();
    return db.collection('cfdinormal').doc(id).update(data);
    
}

export const getCfdiPreviewById= (id) => {
    var db = firebase.firestore();
    return db.collection('previewcfdi').doc(id).get();
}