import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getAllViajesByOperadorDateFilter, getAllViajesByOperador2DateFilter } from '../../../controllers/viajes';
import { addNomina, updateNomina } from '../../../controllers/nomina';
import { getUserByUid } from '../../../controllers/usuarios';
import { getOperadores } from '../../../controllers/operadores';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles';



const ModalParadas = (props) => {
    //constantes
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            width: '100%',
            paddingLeft: 0,
            paddingRight: 0,
        },
        paperrootfull: {
            width: '100%',
            margin: '5px',
            padding: '30px',
            display: 'flex'
        },

        paperroot: {
            width: '33%',
            display: 'flex'
        },
        paper: {
            minHeight: 80,
            height: 'auto',
            width: '100%',
        },
        paperscontainer: {
            // width: '100%'
            //overflow: 'hidden'
            flex: 1
        },
        title: {
            height: 30,
            backgroundColor: '#dcdcdc',
            paddingLeft: 10,
            paddingTop: 5,
        },
        label: {
            fontSize: '12px',
            paddingLeft: 3,
            paddingTop: 7,
            width: '38%',
        },

        input: {
            display: 'inline-block',
            float: 'right',
            width: '60%',
            height: 30,
            marginTop: 4,
            marginRight: 2
        },
        inputbig: {
            width: '100%',
            height: 30,
            marginTop: 4,
            marginRight: 2
        },
        inputsmall: {
            display: 'inline-block',
            float: 'right',
            width: '50%',
            height: 30,
            marginTop: 4,
            marginRight: 2
        },
        inputwithextra: {
            display: 'inline-block',
            float: 'right',
            width: '30%',
            height: 30,
            marginRight: 2
        },
        inputdate: {
            width: '99%',
            marginTop: 4,
            marginLeft: 0,
            marginRight: 2,
            height: '30px',
        }
    }));
    const classes = useStyles();
    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);

    const [paradas, setParadas] = useState([]);
    const [localidades, setLocalidades] = useState([]);
    //estados propios del formulario




    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);




    useEffect(() => {
        setParadas(props.paradas || []);
        setIsOpen(props.isOpen);
        setLocalidades(props.localidades || []);
    }, [props]);

    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();
        // console.log("submiting");
        let notificar = [];
        paradas.forEach(element => {
            if (element.id && element.id != 0) {
                let aux = { ...element };
                delete aux.colonias;
                delete aux.createAt;

                notificar.push(aux);
            }
        });
        //console.log(notificar);
        props.notify(notificar);
        // setIsOpen(false);


    }


    const changeUbicacion = (value, element) => {
        //console.log("value",value);
        // return;
        if(element && value){
            let findedIndex = paradas.findIndex(e => e === element);
            //console.log("cambiando index", findedIndex);
            //  console.log("cambiando a", value);
            if (findedIndex >= 0) {
                let newArr = [...paradas];
                newArr[findedIndex] = value;
                setParadas(newArr);
            }
        }
       

        /* let findPeligrosos = value;
         if (findPeligrosos && findPeligrosos.clave) {
             //console.log("se encontro", findPeligrosos);
             let newArr = [...localidades];
             let foundIndex = newArr.findIndex(x => x.i === element.i);
             if (foundIndex >= 0) {
                 newArr[foundIndex].c_MaterialPeligroso = findPeligrosos.clave;
                 console.log("cambiando a ", findPeligrosos.clave);
                 setLocalidades(newArr);
             }
         } else {
             console.log("no se encontro");
         }*/
    }
    const AddParada = () => {
        let newArr = [...paradas];
        newArr.push({ id: 0, obj: null });
        setParadas(newArr);
    }
    const changeKms = (event, element) => {
        let newArr = [...paradas];
        let foundIndex = newArr.findIndex(x => x === element);
        if (foundIndex >= 0) {
            newArr[foundIndex].kms = event.target.value;
            setParadas(newArr);
        }

    }
    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Paradas" : `Paradas`}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div class="dataTable oneColmargin">
                            <div class="dataTitle"><p className="fixedP">Paradas</p>
                                <button type='button' class="boton plus-button" onClick={() => {
                                    AddParada();
                                }} ></button> </div>

                            <div class="dataBox">
                            <div className="col-12">
                                {
                                    paradas.map((element, index) => {
                                        return <div className="row">
                                            <div className="form-group col-8">
                                                <label>Parada</label>
                                              
                                                <Autocomplete
                                                    id="combo-box-demo0c"
                                                    options={localidades}
                                                    getOptionLabel={(option) => option.value}
                                                    getOptionSelected={(option, value) => option.id === value}
                                                    className={classes.inputbig}
                                                    value={ element ? localidades.find(e => e.id === element.id):""}
                                                    style={{ height: '30px' }}
                                                    onChange={(e, value) => changeUbicacion(value, element)}
                                                    renderInput={(params) => <TextField className="reducedselect" onChange={e => console.log(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                />
                                            </div>
                                            <div className="form-group col-2">
                                                <label>Kms.</label>
                                                <input type="number" name='referencia' className="form-control" value={element.kms || 0} onChange={e => changeKms(e, element)} required />

                                            </div>
                                        </div>
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                   

                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalParadas;