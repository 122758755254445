import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getUserByUid } from '../../../controllers/usuarios';
import { getClienteByID, getClientes } from '../../../controllers/clientes';
import { getFacturaArchivos } from '../../../controllers/facturama';
import axios from 'axios';
import CurrencyInput from 'react-currency-input';

const ModalCreateEdit = (props) => {
    //constantes

    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);


    const [chekingCSD, setCheckinCSD] = useState(false);

    const [nviaje, setNviaje] = useState("");
    const [ncarta, setNcarta] = useState("");
    const [cliente, setCliente] = useState("");
    const [clienteId, setClienteId] = useState(null);
    const [clienteData, setClietneData] = useState(null);
    const [isIVA16, setIsIVA16] = useState(true);
    const [facturaArchivos, setFacturaArchivos] = useState(null);

    const [tarifa, setTarifa] = useState('$0.01');
    const [tarifaFloat, setTarifaFloat] = useState(0.01);

    const [clientes, setClientes] = useState([]);

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);


    const checkCSD = async () => {
        console.log("cheking csd", uid);
        if (uid) {
            getUserByUid(uid).then(resp => {
                setCheckinCSD(false);
                //console.log("datos usuario",resp.docs[0].data());
                if (resp.docs.length > 0 && resp.docs[0].data().csd) {
                    initValues();
                } else {
                    Swal.fire("No CSD", "No se han cargado los CSD para esta cuenta, da de alta primero tus credenciales de facturacion en la seccion de dashboard -> configuraciones ", 'warning');
                    setIsLoading(false);
                    setIsOpen(false);
                }
            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar CSD, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });
        } else {
            Swal.fire("Error", "Error sesion terminada", 'error');
            setIsLoading(false);
            setIsOpen(false);
        }

    }

    useEffect(() => {
        if (clienteId) {
            let objclient = clientes.find(element => element.id === clienteId);
            setClietneData(objclient);
            /*  getClienteByID(clienteId).then(resp => {
                  if (resp.data()) {
                      setClietneData(resp.data());
                  }
              }).catch(err => { });*/
        }
    }, [clienteId]);

    useEffect(() => {
        if (clientes && clientes.length > 0) {
            setClienteId(clientes[0].id);
            setCliente(clientes[0].nombre);
        }
    }, [clientes]);

    const initValues = async () => {
        console.log("init values");
        await getClientes(uid).then(snapshot => {
            let a = [];
            snapshot.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                o.key = doc.id;
                a.push(o);
            });
            setClientes(a);
        }).catch(err => { console.log(err) });

        setTarifaFloat(0.01);
        setTarifa("$0.01");

        if (isEdit && recordedit != null) {
            setNviaje(recordedit.general.nviaje || "");
            setNcarta(recordedit.general.ncarta || "");
            setCliente(recordedit.general.cliente || "");
            setClienteId(recordedit.general.clienteId || "");
            if (recordedit.facuturado) {
                setFacturaArchivos(recordedit.facuturado);
            }
        } else {

        }
    }


    useEffect(() => {

        // setCheckinCSD(true);
        setUid(props.uid);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);

        // initValues();

        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);
        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit) {

            initValues();
        }

    }, [recordedit]);


    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            initValues();
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);
                initValues();
                checkCSD();
            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();
        if (!facturaArchivos && recordedit && recordedit.key) {

            setIsLoading(true);
            let ivarate = 0.16;
            if (isIVA16) {
                ivarate = 0.16;
            } else {
                ivarate = 0.08;
            }
            await axios.post("https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/facturacloud", { id: recordedit.key, ivar: ivarate }).then(resp => {
                setIsLoading(false);
                props.notify();
                props.toggle();
                Swal.fire("Factura generada", "Se ha generado la factura", 'success');
                //console.log("respuesta", resp);


            }).catch(err => {
                setIsLoading(false);
                console.log("error", err.toString());
                if (err.toString().includes("Network Error")) {
                    Swal.fire("Error", "Error de conexión, no fue posible conectar con el servidor, revisa tu conexión", 'error');
                } else {
                    Swal.fire("Error", "No se pudo generar la factura", 'error');
                }
            });
        }
    }
    const downloadPDF = (pdf) => {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        let nombrefactura = "";
        if (clienteData) {
            nombrefactura = clienteData.rfc || "";
        }
        const fileName = `factura${nombrefactura}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    const downloadXML = (pdf) => {
        const linkSource = `data:application/xml;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        let nombrefactura = "";
        if (clienteData) {
            nombrefactura = clienteData.rfc || "";
        }
        const fileName = `factura${nombrefactura}.xml`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    const descargarFacturaPDF = () => {
        if (facturaArchivos) {
            getFacturaArchivos(facturaArchivos).then(docu => {
                downloadPDF(docu.data().pdf);
            }).catch(err => { });
        }
    }
    const descargarFacturaXML = () => {
        if (facturaArchivos) {
            getFacturaArchivos(facturaArchivos).then(docu => {
                downloadXML(docu.data().xml);
            }).catch(err => { });
        }
    }
    const handleChangeTarifa = (event, maskedvalue, floatvalue) => {
        setTarifa(maskedvalue);
        setTarifaFloat(floatvalue);
    }
    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {facturaArchivos ? "Descargar Factura" : `Registrar Factura `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <LoadingOverlay
                        active={chekingCSD}
                        spinner
                        text='Comprobando CSD, espere ...'
                    >
                        <div>
                            <div className="row">
                                <div className="form-group col-3">
                                    <label>No. Viaje</label><label className="requiredlabel">*</label>
                                    <input type="text" name='nviaje' className="form-control" value={nviaje} onChange={e => setNviaje(e.target.value)} />
                                </div>
                                <div className="form-group col-3">
                                    <label>No. Carta Porte</label><label className="requiredlabel">*</label>
                                    <input type="text" name='ncarta' className="form-control" value={ncarta} onChange={e => setNcarta(e.target.value)} />
                                </div>
                                <div className="form-group col-3">
                                    <label>Cliente</label><label className="requiredlabel">*</label>
                                    <select className={"form-control "} name='clase' onChange={(e) => setClienteId(e.target.value)} value={clienteId} required >
                                        {
                                            clientes.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.nombre}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <label>RFC Cliente</label><label className="requiredlabel">*</label>
                                    <input readOnly type="text" name='cliente' className="form-control" value={clienteData ? clienteData.rfc : ""} onChange={e => console.log("")} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-3">
                                    <label>No. Factura</label><label className="requiredlabel">*</label>
                                    <input type="text" className="form-control" required />
                                </div>
                                <div className="form-group col-3">
                                    <label>Importe</label><label className="requiredlabel">*</label>
                                    <CurrencyInput className={"form-control "} value={tarifa} onChangeEvent={handleChangeTarifa} prefix="$" required />
                                </div>
                                <div className="form-group col-3">
                                    <label>Fecha de facturación</label><label className="requiredlabel">*</label>
                                    <input type="date" className="form-control" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-3">
                                    <label> <Typography component="div">
                                        <Grid component="label" container alignItems="center" spacing={1} style={{ margin: '1px' }}>
                                            <Grid item style={{ fontSize: '12px' }}>IVA 8%</Grid>
                                            <Grid item>
                                                <AntSwitch checked={isIVA16} onChange={() => setIsIVA16(!isIVA16)} name="checkedCisfull" />
                                            </Grid>
                                            <Grid item style={{ fontSize: '12px' }}>16%</Grid>
                                        </Grid>
                                    </Typography></label>
                                    <label for="inputfecha">&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                </div>
                            </div>
                        </div>

                    </LoadingOverlay>
                </ModalBody>
                <ModalFooter>
                    {!facturaArchivos && <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>}
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >
}
export default ModalCreateEdit;