import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { firebase } from '../../../configs/firebase';
import DatePicker from 'react-datepicker';
import { getServicioById, getMantenimientoByID, updateMantenimiento, getProveedores, updateServicio, addServicio, nextFolioServicio } from '../../../controllers/proveedor';
import { getViajeRepo } from '../../../controllers/viajes';
import { getActivos } from '../../../controllers/activos';
import { getUidMaster } from '../../../session/session_controller';
import { HotTable, HotColumn } from '@handsontable/react';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
import ModalUsoInvetnario from './ModalUsoInventario';

const ServicioContent = (props) => {
    let { idparam } = useParams();

    let history = useHistory();
    const [isSaving, setIsSaving] = useState(false);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);

    const [id, setId] = useState(idparam || null);
    const [isEdit, setIsEdit] = useState(false);
    const [planId, setPlanId] = useState(null);
    const [plan, setPlan] = useState(null);
    const [fecha, setFecha] = useState(null);
    const [folios, setFolios] = useState("");
    const [proveedores, setProveedores] = useState([]);
    const [activos, setActivos] = useState([]);
    const [unidadNombre, setUnidadNombre] = useState("");
    const [unidad, setUnidad] = useState();
    const [provedoresServicio, setProvedoresServicio] = useState([{ i: 0, proveedor: null, costos: [], factura: "" }]);
    // const [proveedor, setProveedor] = useState(null);
    const [notas, setNotas] = useState("");
    // const [factura, setFactura] = useState("");
    //const [costos, setCostos] = useState([]);
    const [proximaFecha, setProximaFecha] = useState(null);

    const [isOpenUsoInventario, setIsOpenUsoInventario] = useState(false);
    const [inventarioUtilizado, setInventarioUtilizado] = useState([]);

    const locations = useLocation();
    useEffect(() => {

        //
        //  loadLocalidades();
        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {

                //setUser(userr);
                // setAdminCurrentUser(userr.uid);
                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);



            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });

        setIsSaving(false);




    }, []);

    useEffect(() => {
        if (uid) {
            initCatalogos();
        }
        if (!idparam) {
            //alta de servicio nuevo
            //geting folio  
            console.log("init nuevo");
            if (uid)
                initNuevo();
            else
                console.log("no uid en init nuevo");
        }

    }, [uid]);

    const initCatalogos = async () => {
        if (uid) {
            let auxprov = [];
            await getProveedores(uid).then(resp => {
                resp.forEach(doc => {
                    let o = doc.data();
                    o.id = doc.id;
                    delete o.colonias;
                    auxprov.push(o);
                });
                setProveedores(auxprov);
            });

            if (!plan) {
                let activosaux = [];
                await getActivos(uid).then(resp => {
                    resp.forEach(doc => {
                        let o = doc.data();
                        o.id = doc.id;

                        activosaux.push(o);
                    });
                    setActivos(activosaux);
                });
            }
        }

    }
    const loadDataFromOBJ = async (data) => {
        console.log("loading data from obj");
        /**planId: recordedit ? recordedit.id : null,
                plan: recordedit,
                fecha: new Date(),
                folio,
                folios: `S-${folio || ""}` */

        if (data.fecha) {
            setFecha(data.fecha.toDate());
        } else {
            setFecha(new Date());
        }
        if (data.planId) {
            setPlanId(data.planId);
            getMantenimientoByID(data.planId).then(respuesta => {
                let aaa = null;
                aaa = respuesta.data();
                aaa.id = data.planId;

                if (aaa) {
                    console.log("si tiene plan");
                    console.log(aaa);
                    if (aaa.proximaFecha) {
                        setProximaFecha(aaa.proximaFecha.toDate());
                    }
                    setPlan(aaa);
                }

            })
        }

        // if (data.plan)
        //    setPlan(data.plan)


        setFolios(data.folios || "");
        if (data.proveedor) {
            // setProveedor(data.proveedor);
        }

        if (data.provedoresServicio) {
            setProvedoresServicio(data.provedoresServicio);
        } else {
            setProvedoresServicio([{ i: 0, proveedor: null, costos: [], factura: "" }]);
        }

        if (data.proximaFecha) {
            setProximaFecha(data.proximaFecha.toDate());
        }
        setUnidad(data.unidad || "");
        setUnidadNombre(data.unidadNombre || null);
        //setCostos(data.costos || []);
        //setFactura(data.factura || "");
        // setProveedor(data.proveedor || "");
        setNotas(data.notas || "");

    }

    useEffect(() => {
        if (idparam || id) {

            getServicioById(idparam || id).then(resp => {
                //console.log(resp.data());
                setId(idparam || id);
                loadDataFromOBJ(resp.data());
            }).catch(err => {
                console.log("error", err);
            })
        }
    }, [props]);

    const initNuevo = async () => {
        //  console.log("uid", uid);
        let folio = null;
        setFecha(new Date());
        await getViajeRepo(uid).then(resp => {
            if (resp.size > 0) {
                //console.log("resp",resp.docs[0].data());
                let dataus = resp.docs[0].data();
                folio = dataus.fservicio || null;
            }
        });
        if (!folio) {
            console.log("no folio!!!");
            return;
        } else if (folio !== "") {
            console.log("next folio", folio);
            setFolios(`S-${folio}`);
        }

        let queryparams = queryString.parse(locations.search);
        console.log("queryparams", queryparams);

        if (queryparams.p) {
            let auxplan = null;
            await getMantenimientoByID(queryparams.p).then(resp => {
                let datosPlan = resp.data();
                datosPlan.id = resp.id;

                if (datosPlan.proximaFecha) {
                    setProximaFecha(datosPlan.proximaFecha.toDate());
                }
                setPlan(datosPlan);
                setPlanId(resp.id);
                auxplan = datosPlan;
            });
            if (auxplan) {
                setUnidad(auxplan.unidad);
                setUnidadNombre(auxplan.unidadNombre);
            }
        }




    }



    const addGastoInventario = () => {
        setIsOpenUsoInventario(true);
    }

    useEffect(() => {
        if (unidad && unidad != "") {
            let findu = activos.find(a => a.id === unidad);
            if (findu) {
                setUnidadNombre(findu.nombre);
            }
        }

    }, [unidad]);

    const save = async () => {
        let obj = {
            provedoresServicio,
            fecha,
            notas,

        }
        if (!plan) {
            obj.unidad = unidad;
            obj.unidadNombre = unidadNombre;
        }

        if (plan && planId && proximaFecha) {
            let objMant = { proximaFecha };
            await updateMantenimiento(planId, objMant).then(resp => {

            });
        }


        if (id) {
            await updateServicio(id, obj).then(resp => {
                Swal.fire("Exito", `Se guardó la información del servicio`, 'success');
            });
        } else {
            //es alta
            if (!uid) {
                console.log("no UID");
                return;
            } else {
                console.log("uid", uid);
            }
            let auxfolios = folios.split('-');

            //cheking if plan
            let queryparams = queryString.parse(locations.search);
            console.log("queryparams", queryparams);
            let planAux = null;

            if (queryparams.p) {
                await getMantenimientoByID(queryparams.p).then(resp => {
                    let datosPlan = resp.data();
                    datosPlan.id = resp.id;
                    planAux = datosPlan;
                });
            }



            if (auxfolios.length > 1) {
                obj.folio = parseInt(auxfolios[1]);
                obj.folios = folios;
                obj.planId = queryparams.p || null;
                obj.plan = planAux;
                obj.uid = uid;
            }

            if (planAux) {
                //comprobando si hay que actualizar el plan
                console.log("requiere actualizar plan", planAux)
                if (planAux.condicionRecurrencia === "km" || planAux.condicionRecurrencia === "ambos") {
                    //buscar unidad
                    if (unidad) {
                        let findact = activos.find(af => af.id === unidad);
                        if (findact) {
                            console.log(findact);
                            let nuevoservicio = parseFloat(planAux.kmsrecurrencia) + parseFloat(findact.kms || 0);
                            let actualizarmanteni = { servicioKms: nuevoservicio };
                            await updateMantenimiento(planAux.id, actualizarmanteni).then(resp => { console.log("mantenimiento actualizado") }).catch(err => console.log(err));
                        }

                    }
                }
            }
            //return;

            let altaok = false;
            let okfolio = false;
            let idGenerado = null;
            await addServicio(obj).then(async (docuRef) => {
                altaok = true;
                idGenerado = docuRef.id || null;
            }).catch(err => console.log(err));
            if (altaok) {
                let idUserViaje = null;
                await getViajeRepo(uid).then(resp => {
                    if (resp.size > 0) {
                        //console.log("resp",resp.docs[0].data());
                        idUserViaje = resp.docs[0].id;
                    }
                });
                await nextFolioServicio(idUserViaje).then(resp => {
                    okfolio = true;
                }).catch(err => { console.log("hjerre") });
                if (!okfolio) {
                    alert("error al generar folio de servicio");
                    return;
                } else {
                    Swal.fire("Exito", `Se registró la información del servicio`, 'success');
                    if (idGenerado)
                        history.push("/servicio/" + idGenerado);
                }
            }

        }

    }


    const changeProveedor = (event, element) => {
        let newArr = [...provedoresServicio];
        let foundIndex = newArr.findIndex(x => x.i === element.i);
        if (foundIndex >= 0) {
            newArr[foundIndex].proveedor = event.target.value;
            let findProv = proveedores.find(p => p.id === event.target.value);
            if (findProv && findProv.nombre) {
                newArr[foundIndex].proveedorNombre = findProv.nombre;
            }
            setProvedoresServicio(newArr);
        }

    }

    const changeFactura = (event, element) => {
        let newArr = [...provedoresServicio];
        let foundIndex = newArr.findIndex(x => x.i === element.i);
        if (foundIndex >= 0) {
            newArr[foundIndex].factura = event.target.value;
            setProvedoresServicio(newArr);
        }

    }

    const addCostoNew = (event, element) => {
        let newArr = [...provedoresServicio];
        let foundIndex = newArr.findIndex(x => x.i === element.i);
        if (foundIndex >= 0) {
            let nuevoCosto = {
                monto: "0.00",
                montoFloat: 0.00,
                descripcion: ""
            }
            if (newArr[foundIndex].costos) {
                newArr[foundIndex].costos = [...newArr[foundIndex].costos, nuevoCosto];

            } else {
                newArr[foundIndex].costos = [nuevoCosto];
            }
            setProvedoresServicio(newArr);
        }

    }


    return (
        <div className="card-body">
            <button class="boton secBtn save" onClick={() => save()}><span>Guardar</span></button>
            <div class="dataTable oneCol" style={{ paddingBottom: "10px", marginTop: '10px' }}>
                <div class="dataTitle">
                    <p className="fixedP">Servicio de Mantenimiento</p>

                </div>

                <div class="dataBox singleRow">
                    <div class="dataCol">
                        <p className="fixedP">Servicio</p>
                        <input type="text" value={folios} readOnly onChange={e => {


                        }} />
                    </div>
                    {
                        plan && plan.unidad && <div class="dataCol">
                            <p className="fixedP">Unidad</p>
                            <input type="text" value={plan.unidadNombre} readOnly onChange={e => {


                            }} />
                        </div>
                    }
                    {
                        !plan && <div class="dataCol">
                            <p className="fixedP">Unidad</p>
                            {activos && <select value={unidad} onChange={(e => setUnidad(e.target.value))}>
                                {activos.map(a => {
                                    return <option key={a.id} value={a.id}>{a.nombre}</option>
                                })}
                                <option key={"noap"} value={""}>{""}</option>
                            </select>}
                        </div>
                    }
                    {
                        plan && plan.tarea && <div class="dataCol">
                            <p className="fixedP">Tarea</p>
                            <input type="text" value={plan.tarea} readOnly onChange={e => {


                            }} />
                        </div>
                    }
                    <div class="dataCol">
                        <p className="fixedP">Fecha</p>
                        <DatePicker
                            showTimeSelect
                            dateFormat="dd/MM/yyyy HH:mm"
                            selected={fecha} onChange={date => setFecha(date)} />
                    </div>
                    {
                        proximaFecha && <div class="dataCol">
                            <p className="fixedP">Próximo Servicio</p>
                            <DatePicker
                                showTimeSelect
                                dateFormat="dd/MM/yyyy HH:mm"
                                selected={proximaFecha} onChange={date => setProximaFecha(date)} />
                        </div>
                    }

                </div>
                <div class="dataBox">
                    <div class="dataCol" style={{ margin: '10px' }}>
                        <p className="fixedP">Notas</p>
                        <textarea type="text" value={notas} onChange={e => {
                            setNotas(e.target.value)

                        }} />
                    </div>
                </div>
            </div>
            <div class="dataTable oneCol" style={{ paddingBottom: "10px" }}>
                <div class="dataTitle">
                    <p className="fixedP">Costos{`${provedoresServicio.length}`}</p>

                </div>

                {provedoresServicio.map((pe, index) => {
                    return <div>
                        <div class="dataBox singleRow">
                            <div class="dataCol">
                                <p className="fixedP">Proveedor</p>
                                <select className='' value={pe.proveedor} onChange={e => changeProveedor(e, pe)} >

                                    {proveedores.map(p => {
                                        return <option key={p.id} value={p.id}>{p.nombre}</option>
                                    })}
                                    <option key={"na"} value={""}>{""}</option>
                                </select>
                                {/*proveedor &&
                                <Autocomplete
                                    id="combo-box-demo0c"
                                    options={proveedores}
                                    getOptionLabel={(option) => option.nombre}
                                    getOptionSelected={(option, value) => option.nombre === value.nombre}
                                    defaultValue={proveedores.find(element => element.nombre.trim() === proveedor.trim())}
                                    className={"selectbusqueda"}
                                    style={{ width: '300px' }}
                                    onChange={(e, val) => setProveedor(val ? val.proveedor : "")}
                                    renderInput={(params) => <TextField className="reducedselect2" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                />*/}
                                {/* !proveedor &&
                                <Autocomplete
                                    id="combo-box-demo0c"
                                    options={proveedores}
                                    getOptionLabel={(option) => option.nombre}
                                    getOptionSelected={(option, value) => option.nombre === value.nombre}
                                    className={"selectbusqueda"}
                                    style={{ width: '300px' }}
                                    onChange={(e, val) => setProveedor(val ? val.nombre : "")}
                                    renderInput={(params) => <TextField className="reducedselect2" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                />
            */}

                            </div>
                            <div class="dataCol">
                                <p className="fixedP">Factura</p>
                                <input type="text" value={pe.factura} onChange={e => changeFactura(e, pe)} />
                            </div>
                        </div>
                        <hr></hr>
                        <button class="boton primBtn add" style={{ margin: '0px 10px' }} onClick={e => addCostoNew(e, pe)}><span>Agregar</span></button>
                        <div class="dataBox singleRow">
                            <HotTable settings={{
                                colHeaders: true,
                                rowHeaders: true,
                                autoRowSize: false,
                                columnSorting: true,
                                dropdownMenu: ['filter_by_condition',
                                    'filter_by_value',
                                    'filter_action_bar'],
                                filters: true,
                                manualColumnResize: true,
                                colWidths: [180, 180],
                                afterChange: function (a, b) {
                                    if (b === 'edit') {
                                        //console.log("data",costos);
                                    }

                                },
                                //para ancho total                                    
                                //  minSpareRows: 1,          // almenos una fila
                                // autoColumnSize: true,     // 
                                //  stretchH: 'all',          // 
                                //disableVisualSelection: true,
                                licenseKey: "non-commercial-and-evaluation",
                                language: "es-MX"
                            }} data={pe.costos} colHeaders={true} rowHeaders={false} width="100%" height="200" >
                                <HotColumn className="htCenter htMiddle" title="Descripción" data="descripcion" />
                                <HotColumn className="htCenter htMiddle" type='numeric' title="Monto" data="monto" />

                            </HotTable>
                        </div>
                    </div>
                })}
                <div class="dataBox singleRow">
                    <div class="dataCol">
                        <button class="boton primBtn add" style={{ margin: '0px 10px' }} onClick={() => {
                            let findLastId = 0;
                            provedoresServicio.forEach(ps => {
                                if (ps.i > findLastId) {
                                    findLastId = ps.i;
                                }
                            });
                            findLastId++;
                            let nuevoProv = {
                                proveedor: null,
                                factura: "",
                                costos: []
                            }
                            setProvedoresServicio([...provedoresServicio, nuevoProv]);
                        }}><span>Agregar Proveedor</span></button>
                    </div>

                </div>



            </div>
            <div class="dataTable oneCol" style={{ paddingBottom: "10px" }}>
                <div class="dataTitle">
                    <p className="fixedP">Inventario Utilizado</p>

                </div>

                <div class="dataBox singleRow">
                    <div class="dataCol">
                        <button class="boton primBtn add" style={{ margin: '0px 10px' }} onClick={() => addGastoInventario()}><span>Agregar</span></button>
                    </div>

                </div>
                <div style={{ padding: '10px' }}>
                    <HotTable settings={{
                        colHeaders: true,
                        rowHeaders: true,
                        autoRowSize: false,
                        columnSorting: true,
                        dropdownMenu: ['filter_by_condition',
                            'filter_by_value',
                            'filter_action_bar'],
                        filters: true,
                        manualColumnResize: true,
                        colWidths: [180, 180],
                        //para ancho total                                    
                        //  minSpareRows: 1,          // almenos una fila
                        // autoColumnSize: true,     // 
                        //  stretchH: 'all',          // 
                        //disableVisualSelection: true,
                        licenseKey: "non-commercial-and-evaluation",
                        language: "es-MX"
                    }} data={inventarioUtilizado} colHeaders={true} rowHeaders={false} width="100%" height="200" >
                        <HotColumn className="htCenter htMiddle" title="Refaccion" data="nombre" />
                        <HotColumn className="htCenter htMiddle" type='numeric' title="Cantidad" data="cantidad" />

                    </HotTable>
                </div>


            </div>

            <ModalUsoInvetnario
                findRecord={null}
                currenteditrecrod={null}
                currentDate={new Date()}
                isEdit={false}
                isOpen={isOpenUsoInventario}
                uid={uid ? uid : null}
                uidAltaEdit={uidAltaEdit}
                adminCurrentUser={null}
                isAdmin
                notify={(datan) => {
                    console.log("actualizando", datan);
                    console.log("datan", datan);
                    setInventarioUtilizado([...inventarioUtilizado, {
                        nombre: `${datan.notificacion.refaccionobj.nombre} ${datan.notificacion.refaccionobj.numeroRefaccion}`,
                        refaccion: datan.notificacion.refaccionobj,
                        cantidad: datan.notificacion.cantidadUsada > 0 ? datan.notificacion.cantidadUsada : 1,
                    }])
                    setIsOpenUsoInventario(false);
                }}
                setLoading={value => console.log("valuye")}
                toggle={() => setIsOpenUsoInventario(false)} />

        </div>



    );
};
export default ServicioContent;