import React, { Component, Fragment } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <Fragment>
                {/*<div className="air__layout__footer">
                    <div className="air__footer">
                    <div className="air__footer__inner">
                        <div className="row">
                        <div className="col-12">
                            <p className="text-right" style={{color:'#FFF'}}>
                            &copy; 2021 . All rights reserved.
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>*/}
            </Fragment>
        )
    }
}
