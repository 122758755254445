import { firebase } from '../configs/firebase';
export const getClienteByID=id=>{
    var db = firebase.firestore();
    return db.collection("clientes_user").doc(id).get();
}
export const getClientes = (uid) => {
    var db = firebase.firestore();
    return db.collection("clientes_user").where("uid", "==", uid).get();
}

export const getArrendadores = (uid) => {
    var db = firebase.firestore();
    return db.collection("clientes_user").where("uid", "==", uid).where("tipo","==","Arrendador").get();
}
 
export const addCliente = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('clientes_user').add(dataaux);
}

export const updateCliente = (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date()};
    var db = firebase.firestore();
    return db.collection('clientes_user').doc(id).update(dataaux);
}

export const updateConfiguracionCliente = (id, configuracionCFDI) => {
    var db = firebase.firestore();
    return db.collection('clientes_user').doc(id).update({configuracionCFDI});
}


export const deleteCliente = (id) => {
    var db = firebase.firestore();
    return db.collection('clientes_user').doc(id).update({uid:"deleted"});
    //return db.collection('clientes_user').doc(id).delete();
}

