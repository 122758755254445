import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input';
import Swal from 'sweetalert2';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addIngreso, updateIngreso, addDestino, getAllDestinosByUser } from '../../../controllers/ingresos';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { addCombustible, updateCombustible } from '../../../controllers/combustible'
import { updateUbicacion } from '../../../controllers/ubicaciones';
import { getUnidades, actualizaKM, getUnidadesTermo } from '../../../controllers/unidades';
import { getViajeById, actualizaLc } from '../../../controllers/viajes'
import { addCosto } from '../../../controllers/costos'
import moment from 'moment';

const ModalCreateEdit = (props) => {
    //constantes
    const CATALOGOS_TIPO_PAGO = ['Efectivo', 'Tarjeta de combustible', 'Autoabasto', 'Transferencia', 'Cheque','Vale de combustible', 'Otro'];
    const CATALOGOS_USOS = ['Diésel Unidad', 'Diésel Termo', 'Gasolina Unidad'];
    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);


    const [unidades, setUnidades] = useState([]);
    const [viajes, setViajes] = useState([]);
    //estados propios del formulario

    const [fechaCarga, setFechaCarga] = useState("");
    const [isViajeAssigned, setIsViajeAssigned] = useState(false);
    const [viaje_id, setViajeID] = useState("");
    const [unidad_id, setUnidadID] = useState("");
    const [unidad, setUnidad] = useState(null);
    const [ultimoKM, setUltimoKM] = useState("");
    const [nuevoKM, setNuevoKM] = useState(0);
    const [pago, setPago] = useState("Efectivo");
    const [cantidad, setCantidad] = useState(0);
    const [costo, setCosto] = useState("");
    const [costoFloat, setCostoFloat] = useState(0);
    const [total, setTotal] = useState("");
    const [totalFloat, setTotalFloat] = useState(0);
    const [factura, setFacutra] = useState("");
    const [currencyMX, setCurrencyMX] = useState(true);
    const [uso, setUso] = useState("Diésel Unidad");
    const [linea_captura, setLineaCaptura] = useState("");

    // const [viaje_id,setViajeId]=useState("");

    useEffect(() => {

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);


    useEffect(() => {
        if (unidad_id && unidad_id.length > 0) {
            let unidadobj = unidades.find(element => element.id === unidad_id);
            if (unidadobj) {
                setUnidad(unidadobj);
            }
            if (!isEdit && unidadobj && unidadobj.km !== undefined) {
                setUltimoKM(unidadobj.km);
                setNuevoKM(unidadobj.km)
            }
        }
    }, [unidad_id]);

    const initValues = () => {

        if (uid) {
            let a = [];
            getUnidades(uid).then(snapshot => {
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.id = doc.id;
                    o.key = doc.id;
                    a.push(o);
                });
                setUnidades(a);
                if (a.length > 0) {
                    setUnidadID(a[0].id);
                    if (!isEdit)
                        setUltimoKM(a[0].km);
                    setNuevoKM(a[0].km);
                }
            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar unidades, comprueba tu conexión ", 'error');
                //setIsLoading(false);
            });
        }

        if (isEdit && recordedit != null) {
            console.log("current record", recordedit);
            setFechaCarga(recordedit.fechaCarga || "");
            setUnidadID(recordedit.unidad_id || "");
            setUltimoKM(recordedit.ultimoKM || "");
            setNuevoKM(recordedit.nuevoKM || "");
            setPago(recordedit.pago || "Efectivo");
            setCantidad(recordedit.cantidad || 0);
            setCostoFloat(recordedit.costoFloat || 0);
            setCosto(recordedit.costo || "");
            setTotalFloat(recordedit.totalFloat || 0);
            setTotal(recordedit.total || "");
            setFacutra(recordedit.factura || "");
            setCurrencyMX(recordedit.currencyMX);
            setUnidad(recordedit.unidad || null);
            setUso(recordedit.uso || "Diésel Unidad");
            setViajeID(recordedit.viaje_id || "");
            setLineaCaptura(recordedit.lineas_captura || "");

        } else {
            /**
         * const [fechaCarga, setFechaCarga] = useState("");
    const [isViajeAssigned, setIsViajeAssigned] = useState(false);
    const [viaje_id, setViajeID] = useState("");
    const [unidad_id, setUnidadID] = useState("");
    const [ultimoKM, setUltimoKM] = useState("");
    const [nuevoKM, setNuevoKM] = useState(0);
    const [pago, setPago] = useState("Efectivo");
    const [cantidad, setCantidad] = useState(0);
    const [costo, setCosto] = useState("");
    const [costoFloat, setCostoFloat] = useState(0);
    const [total, setTotal] = useState("");
    const [totalFloat, setTotalFloat] = useState(0);
    const [factura, setFacutra] = useState("");
         */
            if (unidades.length > 0) {
                setUnidadID(unidades[0].id);
                setUnidad(unidades[0]);
            } else {
                setUltimoKM("");
                setNuevoKM("");
                setUnidadID("");
            }
            setFechaCarga("");
            setPago("Efectivo");
            setCantidad(0);
            setCostoFloat(0);
            setCosto("");
            setTotalFloat(0);
            setTotal("");
            setCurrencyMX(true);
            setFacutra("");
            setUso("Diésel Unidad");
            if (props.iddeviaje) {
                setViajeID(props.iddeviaje);
            } else {
                setViajeID("");
            }
            //
            setLineaCaptura("");
        }
    }

    useEffect(() => {
        if (uso === "Diésel Termo") {
            if (uid) {
                let a = [];
                getUnidadesTermo(uid).then(snapshot => {
                    snapshot.forEach(doc => {
                        let o = doc.data();
                        o.index = doc.id;
                        o.id = doc.id;
                        o.key = doc.id;
                        a.push(o);
                    });
                    setUnidades(a);
                    if (a.length > 0) {
                        setUnidadID(a[0].id);
                        setUltimoKM(a[0].km);
                        setNuevoKM(a[0].km);
                    }
                }).catch(err => {
                    console.log(err);
                    Swal.fire("Error", "Error de al consultar unidades, comprueba tu conexión ", 'error');
                    //setIsLoading(false);
                });
            }
        } else {
            if (uid) {
                let a = [];
                getUnidades(uid).then(snapshot => {
                    snapshot.forEach(doc => {
                        let o = doc.data();
                        o.index = doc.id;
                        o.id = doc.id;
                        o.key = doc.id;
                        a.push(o);
                    });
                    setUnidades(a);
                    if (a.length > 0) {
                        setUnidadID(a[0].id);
                        setUltimoKM(a[0].km);
                        setNuevoKM(a[0].km);
                    }
                }).catch(err => {
                    console.log(err);
                    Swal.fire("Error", "Error de al consultar unidades, comprueba tu conexión ", 'error');
                    //setIsLoading(false);
                });
            }
        }
    }, [uso]);

    useEffect(() => {
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);
        props.iddeviaje && setViajeID(props.iddeviaje);
        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit) {
            initValues();
        }

    }, [recordedit]);


    useEffect(() => {
        if (uid) {
            let a = [];
            getUnidades(uid).then(snapshot => {
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.id = doc.id;
                    o.key = doc.id;
                    a.push(o);
                });
                setUnidades(a);
                if (a.length > 0) {
                    setUnidadID(a[0].id);
                    setUltimoKM(a[0].km);
                    setNuevoKM(a[0].km);
                }
            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar unidades, comprueba tu conexión ", 'error');
                //setIsLoading(false);
            });
        }
    }, [uid]);

    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();
        //setIsOpen(false);
        let auxFechaCarga=`${fechaCarga} 10:30`
        const myDate = moment(auxFechaCarga, 'YYYY-MM-DD hh:mm').toDate();
       let anio=fechaCarga.split("-")[0];
       let messs=fechaCarga.split("-")[1];
        let obj = {
            fechaCarga,
            fechaCargaDate:myDate,
            fechaCargaMes:messs,
            fechaCargaYear:anio,
            fechaCargaMesYear:`${messs}-${anio}`,
            isViajeAssigned,
            viaje_id,
            unidad_id,
            unidad,
            ultimoKM,
            nuevoKM,
            pago,
            cantidad,
            uso,
            costoFloat,
            costo,
            total,
            totalFloat,
            factura,
            currencyMX,
            uid,
            linea_captura,
        }

        //console.log("obj",obj);
        //return;
        if (isAdmin && adminCurrentUser) {
            obj.uid = adminCurrentUser;
        }

        //validaciones de formulario
        // console.log(obj);
        if (isEdit && recordedit.id) {


            /* let lcnew = {
                 id: uuidv4(),
                 temid: nextlcid,
                 descripcion: '',
                 tipo: 'COSTO VARIABLE',
                 currencyMX: true,
                 monto: '',
                 fecha:'',
                 montoFloat: 0.0,
                 costolitro:'',
                 costolitroFloat:0.0,
                 litros:'',
             }*/
            if (obj.viajeid && obj.viaje_id != "") {
                //actualizar tambien el registro en viaje
                await getViajeById(obj.viaje_id).then(async (snapshot) => {
                    let lc = [];
                    snapshot.forEach(doc => {
                        let o = doc.data();
                        o.id = doc.id;
                        if (o.lineas_captura) {
                            lc = o.lineas_captura;
                        }
                    });
                    console.log("lineas de captura edicion", lc);
                    let aux = [];
                    
                    lc.forEach(element => {
                        if (element.lineas_captura === obj.lineas_captura) {
                            let lcnew = {
                                id: element.id,
                                descripcion: obj.factura,
                                tipo: 'COSTO VARIABLE',
                                currencyMX: obj.currencyMX,
                                monto: obj.total,
                                fecha: obj.fechaCarga,
                                montoFloat: obj.totalFloat,
                                costolitro: obj.costo,
                                costolitroFloat: obj.costoFloat,
                                litros: obj.cantidad,
                            }
                            aux.push(lcnew);
                        } else {
                            aux.push(element);
                        }
                    });
                    await actualizaLc(obj.viajeid, aux).then(resp => { }).catch(err => { });

                }).catch(err => { });

            }
            // if(recordedit.)
            // alert(recordedit.id);
            obj.useredit = uidAltaEdit || uid;
            setIsLoading(true);
            updateCombustible(recordedit.id, obj).then(resp => {
                setIsLoading(false);
                setIsOpen(false);
                props.notify({ ...obj, id: recordedit.id });
                Swal.fire('Éxito', 'El gasto de combustible se ha actualizado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                console.log(err);
                Swal.fire("Error", "Error de al actualizar el operador, comprueba tu conexión ", 'error');
            })
        } else if (!isEdit) {
            setIsLoading(true);

            let costotoadd = {
                active: true,
                descripcion: 'Carga de combustible',
                fecha: new Date(obj.fechaCarga),
                monto: obj.total,
                montofloat: obj.totalFloat,
                nombre: 'Combustible',
                tipo: 'Combustible',
                uid,
            }
            let idcosto = null;
            await addCosto(costotoadd).then(resp => {
                // console.log("costo agregado",resp);
                idcosto = resp.id;

            }).catch(err => {
                console.log(err);
            });
            obj.useralta = uidAltaEdit || uid;
            addCombustible({ ...obj, costo_id: idcosto }).then(async (resp) => {
                if (nuevoKM > ultimoKM) {
                    await actualizaKM(unidad_id, nuevoKM).then(resp => {
                        let newunidades = [];
                        unidades.forEach(element => {
                            let o = { ...element }
                            if (element.id === unidad_id) {
                                o.km = nuevoKM;
                            }
                            newunidades.push(o);
                        });
                        setUnidades(newunidades);
                    }).catch(err => { });
                }
                setIsLoading(false);
                setIsOpen(false);
                props.notify();
                Swal.fire('Éxito', 'El gasto de combustible se ha registrado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                Swal.fire("Error", "Error de al registrar el gasto de combustible, comprueba tu conexión ", 'error');
            })
        }
    }
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const handleChangeCurrency = (event, maskedvalue, floatvalue) => {
        setCosto(maskedvalue);
        setCostoFloat(floatvalue);
    }
    const handleChangeCurrencyTotal = (event, maskedvalue, floatvalue) => {
        setTotal(maskedvalue);
        setTotalFloat(floatvalue);
    }
    useEffect(() => {
        let value = costoFloat * cantidad;
        let num = '$' + value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        setTotal(num);
        setTotalFloat(value);
    }, [costoFloat])
    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Añadir Gasto de Combustible" : `Editar Gasto de Combustible `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP">Combustible</p></div>
                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Uso<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <select name='pago' onChange={(e) => setUso(e.target.value)} value={uso} required >
                                            {
                                                CATALOGOS_USOS.map((item, index) => {
                                                    return <option key={index} value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </div>

                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Unidad<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <select name='tipo' onChange={(e) => setUnidadID(e.target.value)} value={unidad_id} required >
                                            {
                                                unidades.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.nombre}</option>
                                                })
                                            }
                                        </select>
                                    </div>

                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Último Kilometraje</p>
                                    <div class="fieldInput">
                                        <input type="text" name='fecha' value={ultimoKM} onChange={e => setUltimoKM(e.target.value)} />
                                    </div>

                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Nuevo Kilometraje</p>
                                    <div class="fieldInput">
                                        <input type="number" name='nuevokm' style={{ width: '90%' }} value={nuevoKM} onChange={e => setNuevoKM(e.target.value)} />
                                    </div>

                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Factura</p>
                                    <div class="fieldInput">
                                        <input type="text" name='factura' value={factura} onChange={e => setFacutra(e.target.value)} required />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Fecha</p>
                                    <div class="fieldInput">
                                        <input type="date" name='fecha' style={{ width: '90%' }} value={fechaCarga} onChange={e => setFechaCarga(e.target.value)} required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP">Detalles</p></div>
                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Pago</p>
                                    <div class="fieldInput">
                                        <select name='pago' onChange={(e) => setPago(e.target.value)} value={pago} required >
                                            {
                                                CATALOGOS_TIPO_PAGO.map((item, index) => {
                                                    return <option key={index} value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Cantidad de litros</p>
                                    <div class="fieldInput">
                                        <input type="number" min="0" step=".01" name='cantidad' style={{ width: "90%" }} value={cantidad} onChange={e => setCantidad(e.target.value)} />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Costo</p>
                                    <div class="fieldInput">
                                        <CurrencyInput style={{ width: '90%' }} value={costo} onChangeEvent={handleChangeCurrency} prefix="$" required />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Moneda</p>
                                    <div class="fieldInput">
                                    <select name='segmento' onChange={(e) => setCurrencyMX(e.target.value == "1" ? true : false)} value={currencyMX ? "1" : "2"} required >
                                            <option key={'monedamxn'} value='1' selected>MXN</option>
                                            <option key={'monedausd'} value='2'>USD</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Total</p>
                                    <div class="fieldInput">
                                    <CurrencyInput style={{ width: '90%' }} value={total} onChangeEvent={handleChangeCurrencyTotal} prefix="$" required />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div><div className="row">

                        <div className="form-group col-3">
                            <label>Uso</label>
                            <select className="form-control" name='pago' onChange={(e) => setUso(e.target.value)} value={uso} required >
                                {
                                    CATALOGOS_USOS.map((item, index) => {
                                        return <option key={index} value={item}>{item}</option>
                                    })
                                }
                            </select>
                        </div>


                        <div className="form-group col-3">
                            <label>Unidad</label><label className="requiredlabel">*</label>
                            <select className="form-control" name='tipo' onChange={(e) => setUnidadID(e.target.value)} value={unidad_id} required >
                                {
                                    unidades.map((item, index) => {
                                        return <option key={index} value={item.id}>{item.nombre}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group col-3">
                            <label>Último kilometraje</label>
                            <input type="text" name='fecha' className="form-control" value={ultimoKM} onChange={e => setUltimoKM(e.target.value)} />
                        </div>
                        <div className="form-group col-3">
                            <label>Nuevo kilometraje</label>
                            <input type="number" name='nuevokm' className="form-control" value={nuevoKM} onChange={e => setNuevoKM(e.target.value)} />
                        </div>

                    </div>
                    <div className="row">
                        <div className="form-group col-3">
                            <label>Pago</label>
                            <select className="form-control" name='pago' onChange={(e) => setPago(e.target.value)} value={pago} required >
                                {
                                    CATALOGOS_TIPO_PAGO.map((item, index) => {
                                        return <option key={index} value={item}>{item}</option>
                                    })
                                }
                            </select>

                        </div>
                        <div className="form-group col-3">
                            <label>Cantidad de litros</label>
                            <input type="number" min="0" step=".01" name='cantidad' className="form-control" value={cantidad} onChange={e => setCantidad(e.target.value)} />
                        </div>
                        <div className="form-group col-3">
                            <label>Costo</label>
                            <CurrencyInput className="form-control" value={costo} onChangeEvent={handleChangeCurrency} prefix="$" required />
                        </div>
                        <div className="form-group col-3">
                            <label> <Typography component="div">
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item style={{ fontSize: '14px' }}>Total&nbsp;&nbsp;&nbsp; $dll.</Grid>
                                    <Grid item>
                                        <AntSwitch checked={currencyMX} onChange={() => setCurrencyMX(!currencyMX)} name="checkedC" />
                                    </Grid>
                                    <Grid item style={{ fontSize: '14px' }}>$mx</Grid>
                                </Grid>
                            </Typography></label>
                            <CurrencyInput className="form-control" value={total} onChangeEvent={handleChangeCurrencyTotal} prefix="$" required />
                        </div>

                    </div>
                    <div className="row">
                        <div className="form-group col-3">
                            <label>Factura</label><label className="requiredlabel">*</label>
                            <input type="text" name='factura' className="form-control" value={factura} onChange={e => setFacutra(e.target.value)} required />
                        </div>
                        <div className="form-group col-3">
                            <label>Fecha</label><label className="requiredlabel">*</label>
                            <input type="date" name='fecha' className="form-control" value={fechaCarga} onChange={e => setFechaCarga(e.target.value)} required />
                        </div>

                    </div>
                    </div>*/}
                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Cancelar</button>
                    <button type="submit" className="boton secBtn">Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalCreateEdit;