import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



export default class Download extends React.Component {
    constructor(props) {
        super();
        let { data } = props;
        this.state = {
            dataSet1: data,
        }
        //   console.log(this.state);

    }

    componentDidUpdate = (prevProps) => {

    }
    /**
     * 
     *  hcliente:element.hora_up? element.hora_up:"Not Set",
                                                    hcarga:element.hora_carga? element.hora_carga:"Not Set",
                                                    hdocumentos:element.hora_documentos? element.hora_documentos:"Not Set",
                                                    hsalida:element.hora_start? element.hora_start:"Not Set",
                                                    hfila:element.hora_fila? element.hora_fila:"Not Set",
                                                    hinspeccion:element.hora_inspeccion? element.hora_inspeccion:"Not Set",
                                                    hmx:element.hora_modulomx? element.hora_modulomx:"Not Set",
                                                    husa:element.hora_modulousa? element.hora_modulousa:"Not Set",
                                                    hfin:element.hora_end? element.hora_end:"Not Set",
     */

    render() {
        return (
            <ExcelFile element={
                <div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>
              }>
                <ExcelSheet data={this.state.dataSet1} name="Viajes">
                    <ExcelColumn label="Viaje" value="viaje" />
                    <ExcelColumn label="Estatus" value="estatus" />
                    <ExcelColumn label="Unidad" value="unidad" />
                    <ExcelColumn label="Cliente" value="cliente" />
                    <ExcelColumn label="Origen" value="origen" />
                    <ExcelColumn label="Destino" value="destino" />
                    <ExcelColumn label="Recolección" value="hrecoleccion" />
                    <ExcelColumn label="Termino Recolección" value="hterminorecoleccion" />
                    <ExcelColumn label="Salida de Base" value="hsalidabase" />
                    <ExcelColumn label="Con Cliente" value="hcliente" />
                    <ExcelColumn label="Carga" value="hcarga" />
                    <ExcelColumn label="Entrega Documentos" value="hdocumentos" />
                    <ExcelColumn label="Salida Cliente" value="hsalida" />
                    <ExcelColumn label="Llegada fila" value="hfila" />
                    <ExcelColumn label="Inspección" value="hinspeccion" />
                    <ExcelColumn label="Módulo MX" value="hmx" />
                    <ExcelColumn label="Módulo USA" value="husa" />
                    <ExcelColumn label="Entrega" value="hfin" />
                    <ExcelColumn label="Regreso Vacio" value="hregresovacio" />

                </ExcelSheet>

            </ExcelFile>
        );
    }
}
