import React, { useState, useEffect, Fragment } from 'react';
import { firebase } from '../../../configs/firebase';
import { getUserByUid } from '../../../controllers/usuarios';
import { getAllUsuarios } from '../../../controllers/usuarios';
import { HotTable, HotColumn } from '@handsontable/react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { getUidMaster } from '../../../session/session_controller';
import { getResumenRutas } from '../../../controllers/resumen';
import { KmsRender, MoneyRender } from './ActionsRender';
import DatePicker from 'react-datepicker';
import { addDays } from "date-fns";
import moment from 'moment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography component={'span'} variant={'body2'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
    },
    paperrootfull: {
        width: '100%',
        margin: '5px',
        padding: '30px',
        display: 'flex'
    },

    paperroot: {
        width: '33%',
        display: 'flex'
    },
    paper: {
        minHeight: 80,
        height: 'auto',
        width: '100%',
    },
    paperscontainer: {
        // width: '100%'
        //overflow: 'hidden'
        flex: 1
    },
    title: {
        height: 30,
        backgroundColor: '#dcdcdc',
        paddingLeft: 10,
        paddingTop: 5,
    },
}));
const RentabilidadContent = (props) => {
    const classes = useStyles();
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [firstDay, setFirstDay] = useState(null);
    const [lastDay, setLastDay] = useState(null);
    const [user, setUser] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isGerencia, setIsGerencia] = useState(false);
    const [value, setValue] = useState(0);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [nombreRutas, setNombreRutas] = useState([]);
    const [dataRutas, setDataRutas] = useState([]);

    const [fechaRangoStart, setFechaRangoStart] = useState(new Date());
    const [fechaRangoEnd, setFechaRangoEnd] = useState(new Date());
    const [busquedatipo, setBusquedatipo] = useState("mes");
    const requiereConsultaPorTiempoCustom = ['31RxHqtBOmbgOWWeMCKnb1DuEHc2'];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const validateAdmin = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (user.type && user.type === "administrador") {
                    isadmin = true;
                    setIsGerencia(true);
                }
            });
            if (isadmin) {
                setAdminCurrentUser(uid);
                setIsAdmin(true);
            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                setIsAdmin(false);
                setUserlist([]);
                // this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }

    useEffect(() => {
        if (isAdmin) {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                setUserlist(a);
            }).catch(e => {
                console.log(e);
                //this.setState({isLoading:false,})
            });
        }
    }, [isAdmin]);


    useEffect(() => {


        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {
                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);

                setUser(userr);
                // setAdminCurrentUser(userr.uid);

                if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                    // this.validateAdmin(user.uid);
                    //console.log("validando admin");
                    validateAdmin(userr.uid);
                } else if (localStorage.getItem('marketpointmanager-user') && (localStorage.getItem('marketpointmanager-user') === 'gerencia' || localStorage.getItem('marketpointmanager-user') === 'administrador')) {
                    setIsGerencia(true);
                }
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });

    }, []);

    useEffect(() => {
        console.log("efecto users");
        //getData(user);
    }, [user, currentDate]);

    const refresh = () => {
        getData(user);
    }
    const formatoMoneda = (valor) => {
        let valorSinsigno = Math.abs(valor);
        let fixtostring = "" + valorSinsigno;
        let fixtofloat = parseFloat(fixtostring);
        let toMoney = valor >= 0 ? '$' + fixtofloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-$' + fixtofloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        return toMoney;

    }
    const getData = async (usertodisplay, isMes = null) => {
        let fechaFix = moment(fecha).format("MM-YYYY");
        if (isMes !== null) {
            fechaFix = isMes;
        }

        console.log("getting data", fechaFix);
        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }
            // setIsLoading(true);
            let segmentosaux = [];
            let segmentosMap = new Map();


            await getResumenRutas(usertoconsult.uid, fechaFix).then(resp => {
                resp.forEach(doc => {
                    let datadoc = doc.data();
                    datadoc.datosRutas.forEach(ruta => {
                        let dataruta = { ...ruta };
                        dataruta.mxn = "$0.00";
                        dataruta.usd = "$0.00";
                        dataruta.mxnFloat = 0;
                        dataruta.usdFloat = 0;

                        let preciodolar = datadoc.dolarprice || 20;

                        if (dataruta.tarifaSelected) {
                            if (dataruta.tarifaSelected.currencyMx) {
                                dataruta.mxn = dataruta.tarifaSelected.tarifa;
                                dataruta.mxnFloat = dataruta.tarifaSelected.tarifaFloat;
                            } else {
                                dataruta.mxnFloat = dataruta.tarifaSelected.tarifaFloat * preciodolar;
                                let toMoney = '$' + dataruta.mxnFloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                                dataruta.mxn = toMoney;
                                dataruta.usd = dataruta.tarifaSelected.tarifa;
                                dataruta.usdFloat = dataruta.tarifaSelected.tarifaFloat;
                            }
                        }
                        dataruta.ixkm = formatoMoneda((dataruta.mxnFloat * dataruta.count) / dataruta.distancia);

                        if (dataruta.resumen) {
                            let costofijoxkm = dataruta.resumen.costosFijos / dataruta.resumen.kms;
                            let costovariablexkm = dataruta.resumen.costosVariables / dataruta.resumen.kms;
                            let soxkm = dataruta.resumen.comision / dataruta.resumen.kms;
                            let adicionalsueldos = 0;
                            if (dataruta.resumen.sueldos && dataruta.resumen.sueldos > 0) {
                                adicionalsueldos = dataruta.resumen.sueldos / dataruta.resumen.kms;
                                soxkm += adicionalsueldos;
                            }

                            dataruta.cfxkm = formatoMoneda(costofijoxkm);
                            dataruta.cvxkm = formatoMoneda(costovariablexkm);
                            dataruta.soxkm = formatoMoneda(soxkm);
                            let calculoUPXKM = ((dataruta.mxnFloat * dataruta.count) / dataruta.distancia) - (costofijoxkm) - (costovariablexkm) - (soxkm);
                            dataruta.upxkm = formatoMoneda(calculoUPXKM)

                            dataruta.uacum = formatoMoneda(calculoUPXKM * dataruta.distancia)
                            dataruta.ur = formatoMoneda((calculoUPXKM * dataruta.distancia) / dataruta.count)

                        }
                        dataruta.distfix = parseFloat(dataruta.distancia / dataruta.count).toFixed(2);


                        if (!segmentosMap.get(ruta.segmentoNegocio)) {
                            segmentosMap.set(ruta.segmentoNegocio, [{ ...dataruta }]);
                        } else {
                            segmentosMap.get(ruta.segmentoNegocio).push(dataruta);
                        }
                    })

                });
                //console.log("datos map",segmentosMap);
                let nombres = [];
                let datas = [];
                segmentosMap.forEach((values, key) => {
                    nombres.push(key);
                    datas.push(values);
                });

                setNombreRutas(nombres);
                setDataRutas(datas);
                //setDataRutas(segmentosMap);
            });


        }
    }

    const datadummy = [{
        origen: 'Monterrey',
        destino: 'Nuevo Laredo',
        kms: 250.00,
        tarifausd: "$320.00",
        tarifa: '$5,824.00',
        ingresoxkm: '$23.30',
        costofxkm: '$5.28',
        costovxkm: '$10.26',
        soxkm: '$1.07',
        utilidadxkm: '$6.69',
        utilidadruta: '$1,671.50',
        utilidadacumulada: '$65,188.50',
        viajes: 39
    },
    {
        origen: 'CDMX',
        destino: 'Nuevo Laredo',
        kms: 1182.00,
        tarifausd: "$950.00",
        tarifa: '$17,290.00',
        ingresoxkm: '$14.63',
        costofxkm: '$5.28',
        costovxkm: '$10.26',
        soxkm: '$1.07',
        utilidadxkm: '-$1.98',
        utilidadruta: '-$2,343.02',
        utilidadacumulada: '-$32,802.28',
        viajes: 14
    }];

    const handleDateChanged = (date) => {
        let fDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        lDay.setHours(23, 59, 59);
        setFirstDay(fDay);
        setLastDay(lDay);
        setCurrentDate(date);
        setFecha(date);
    }
    const onChangeDaterangeTwo = (fechas) => {
        if (!fechas || !fechas[0] || !fechas[1]) {
            return;
        }

        let start = new Date(fechas[0].getTime());
        let end = new Date(fechas[1].getTime());

        if (start)
            start.setHours(0, 0, 0);
        if (end)
            end.setHours(23, 59, 59);
        setFechaRangoStart(start);
        setFechaRangoEnd(end);

        if (start && end) {
            if (start.getTime() <= end.getTime()) {

                setTimeout(() => {
                    console.log("buscar por rango");
                    // BuscarPorRango(start, end);

                    if (fecha && uid) {
                        let fFix = `${moment(start).format("dd-MM-YYYY")}:${moment(end).format("dd-MM-YYYY")}`

                        getData(user,fFix);
                    }
                }, 500);
            }
        }

    };
    useEffect(() => {
        getData(user);
    }, [fecha]);
    return (
        <Fragment>
            {
                <div className="row">
                    <div className="col-12">
                        <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>&nbsp;Rentabilidad</h3>
                        <div className="card">
                            <div className="card-header card-header-flex">

                                <div className="d-flex px-3 row justify-content-between align-items-center">
                                    {uid && requiereConsultaPorTiempoCustom.find(fir => fir === uid) &&
                                        <div className="col-1">
                                            <div style={{ display: 'flex', width: '135px' }}>
                                                <p className="fixedP" style={{ width: "40px" }}>Tipo</p>
                                                <div class="fieldInput">
                                                    <select value={busquedatipo} style={{ width: '90%' }} onChange={e => setBusquedatipo(e.target.value)}>
                                                        <option value={`mes`}>Mes</option>
                                                        <option value={`rango`}>Rango</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {busquedatipo === "mes" && <div className="col-2"> <DatePicker
                                        className="form-control customdatepicker"
                                        locale="es"
                                        selected={currentDate}
                                        onChange={date => handleDateChanged(date)}
                                        maxDate={addDays(new Date(), 1)}
                                        dateFormat="MMMM yyyy"
                                        showMonthYearPicker
                                    /></div>}
                                    {
                                        uid && requiereConsultaPorTiempoCustom.find(fir => fir === uid) &&
                                        busquedatipo === "rango" &&
                                        <div className="col-2">  <DateRangePicker
                                            style={{ marginTop: '5px' }}
                                            clearIcon={null}
                                            value={[fechaRangoStart, fechaRangoEnd]}
                                            onChange={onChangeDaterangeTwo}
                                        /></div>
                                    }
                                    <div style={{ height: '46px', padding: '5px' }}>


                                    </div>


                                </div>
                            </div>

                            <div className="card-body">
                                <div className="air__layout__content p-3">
                                    <div className="air__utils__content">
                                        <div className="row card-panels">
                                            <div className={classes.root}>
                                                {nombreRutas.length > 0 && dataRutas.length > 0 && <div>
                                                    <AppBar position="static">
                                                        <Tabs
                                                            value={value}
                                                            indicatorColor="tabindicator"
                                                            textColor="tabtextcolor"
                                                            onChange={handleChange}
                                                            aria-label="simple tabs example">
                                                            {
                                                                nombreRutas.map((n, i) => {
                                                                    return <Tab label={n} {...a11yProps(i)} />
                                                                })
                                                            }



                                                        </Tabs>
                                                    </AppBar>
                                                    {
                                                        dataRutas.map((r, i) => {
                                                            return <TabPanel value={value} index={i}>
                                                                <div style={{ width: '100%', overflowX: 'auto' }}>
                                                                    <HotTable settings={{
                                                                        colHeaders: true,
                                                                        rowHeaders: true,
                                                                        autoRowSize: false,
                                                                        columnSorting: true,
                                                                        dropdownMenu: ['filter_by_condition',
                                                                            'filter_by_value',
                                                                            'filter_action_bar'],
                                                                        filters: true,
                                                                        manualColumnResize: true,
                                                                        //colWidths: [180, 144, 175, 175, 170, 170, 170, 135, 85, 80],
                                                                        //para ancho total                                    
                                                                        //  minSpareRows: 1,          // almenos una fila
                                                                        autoColumnSize: true,     // 
                                                                        //stretchH: 'all',          // 
                                                                        //disableVisualSelection: true,
                                                                        licenseKey: "non-commercial-and-evaluation",
                                                                        language: "es-MX"
                                                                    }} data={r} colHeaders={true} rowHeaders={false} width="100%" height="auto" >

                                                                        <HotColumn className="htCenter htMiddle" readOnly title="Cliente" data="cliente" />
                                                                        <HotColumn className="htCenter htMiddle" readOnly title="Origen" data="origen" />
                                                                        <HotColumn className="htCenter htMiddle" readOnly title="Destino" data="destino" />

                                                                        <HotColumn className="htCenter htMiddle mycustomcolumn" render readOnly title="Kms." data="distfix" >
                                                                            <KmsRender className="htCenter htMiddle" hot-renderer></KmsRender>
                                                                        </HotColumn>
                                                                        <HotColumn className="htCenter htMiddle" readOnly title="Tarifa USD" data="usd" />
                                                                        <HotColumn className="htCenter htMiddle" readOnly title="Tarifa MXN" data="mxn" />
                                                                        <HotColumn className="htCenter htMiddle" readOnly title="I x Km" data="ixkm" />
                                                                        <HotColumn className="htCenter htMiddle" readOnly title="CF x Km" data="cfxkm" />
                                                                        <HotColumn className="htCenter htMiddle" readOnly title="CV x Km" data="cvxkm" />
                                                                        <HotColumn className="htCenter htMiddle" readOnly title="SO x Km" data="soxkm" />
                                                                        <HotColumn className="htCenter htMiddle mycustomcolumn" render readOnly title="UP x KM" data="upxkm" >
                                                                            <MoneyRender className="htCenter htMiddle" hot-renderer></MoneyRender>
                                                                        </HotColumn>
                                                                        <HotColumn className="htCenter htMiddle mycustomcolumn" render readOnly title="Utilidad Ruta" data="ur" >
                                                                            <MoneyRender className="htCenter htMiddle" hot-renderer></MoneyRender>
                                                                        </HotColumn>
                                                                        <HotColumn className="htCenter htMiddle" readOnly title="Viajes" data="count" />
                                                                        <HotColumn className="htCenter htMiddle" readOnly title="Utilidad Acum" data="uacum" />



                                                                    </HotTable>
                                                                </div>
                                                            </TabPanel>
                                                        })
                                                    }


                                                </div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>

                </div>
            }
        </Fragment >
    );
}
export default RentabilidadContent;