import { firebase } from '../configs/firebase';

export const getUnidadByID = (id) => {
    var db = firebase.firestore();
    return db.collection("activos_user").doc(id).get();
}

export const getRemolquesByNombreAndUid= (uid,nombre) => {
    var db = firebase.firestore();
    return db.collection("activos_user").where("uid", "==", uid).where("tipo", "==", "Remolque").where("nombre","==",nombre).limit(1).get();
}

export const setViajeAsignadoUnidad = (id, nuevoviajeasignado) => {
    var db = firebase.firestore();
    return db.collection('activos_user').doc(id).update("viaje_asignado", nuevoviajeasignado);
}

export const getUnidades = (uid) => {
    var db = firebase.firestore();
    return db.collection("activos_user").where("uid", "==", uid).where("tipo", "==", "Unidad").get();
}
export const getUnidadesTermo = (uid) => {
    var db = firebase.firestore();
    return db.collection("activos_user").where("uid", "==", uid).where("tipoRemolque", "==", "CTR025").get();
}

export const getAllActivos = (uid) => {
    var db = firebase.firestore();
    return db.collection("activos_user").where("uid", "==", uid).get();
}

export const actualizaKM = (id, nuevoKM) => {
    var db = firebase.firestore();
    return db.collection('activos_user').doc(id).update("km", nuevoKM);
}
export const asignarPrePlan = (id, viaje) => {
    var db = firebase.firestore();
    return db.collection('activos_user').doc(id).update("preplan_viaje", viaje);
}

export const updateActivosTime = (id) => {
    var db = firebase.firestore();
    return db.collection('usuarios').doc(id).update({ changedActivos: firebase.firestore.FieldValue.serverTimestamp() });
}

export const updateActivosTimeByUid = async(uid) => {
    var db = firebase.firestore();
    await db.collection('usuarios').where("uid", "==", uid).get().then(resp => {
        if (resp.docs.length > 0) {
            db.collection('usuarios').doc(resp.docs[0].id).update({ changedActivos: firebase.firestore.FieldValue.serverTimestamp() }).then(respupdate=>{}).catch(err=>{});
        }
    }).catch(err => { console.log(err) });    
}
