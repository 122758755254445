import React, { Component, Fragment } from 'react'
import { doLogin, checkMaster } from './../../session/session_controller';
import { firebase } from './../../configs/firebase';
import { getUserByEmail, firstLogin } from '../../controllers/usuarios';
import Swal from 'sweetalert2';
import LoadingOverlay from 'react-loading-overlay';
import { Alert } from 'reactstrap';
//import { firebase, googleAuthProvider } from './../../configs/firebase';
export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: null,
            email: '',
            password: '',
            isloading: false,
            isModalOpen: false,
            error_log: false,
        }

    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    socialLogin = async () => {
        localStorage.removeItem('lastviajes');
        localStorage.removeItem('lastviajesexpiration');
        localStorage.removeItem('marketpointmanager-muid');
        localStorage.removeItem("mesCargado");
        var provider = new firebase.auth.GoogleAuthProvider();
        await firebase
            .auth()
            .signInWithPopup(provider)
            .then(result => {
                console.log(result.user);
                const user = result.user;
                let aux = false;
                let userExists = false;
                let type = '';
                getUserByEmail(user.email).then(snapshot => {
                    snapshot.forEach(doc => {
                        let o = doc.data();
                        checkMaster(o.uid);
                        if (user.email === o.email) {
                            userExists = true;
                            switch (o.type) {
                                case 'administrador':
                                    aux = true;
                                    type = o.type;
                                    break;
                                case 'user':
                                    aux = true;
                                    type = o.type;
                                    break;
                                default:
                                    aux = false;
                                    break;
                            }
                        }
                    });

                    if (!userExists) {
                        //primer logeo
                        firstLogin(user.email, user.uid).then(snapshot => {
                            this.setState({ isloading: false });
                            type = "user";
                            aux = true;
                            const localStorage = window.localStorage;
                            localStorage.setItem('marketpointmanagerid', user.email);
                            //localStorage.setItem('navleft_medidores', true);
                            localStorage.setItem('marketpointmanager-user', type);
                            /* if (type === 'verificador') {
                                 window.location.href = '/mediciones';
                             } else {
                                 window.location.href = '/dashboard';
                             }*/
                            window.location.href = '/dashboard';

                        });

                        /*firebase.auth().signOut().then(() => {
                            //Sign-out successful
                            this.setState({ email: '', password: '' });
                            Swal.fire('Error', "User does not exist in records.", 'error');
                            localStorage.removeItem('marketpointmanagerid');
                            localStorage.removeItem('marketpointmanager-user');
                        }).catch(e => {
                            //Error happened
                            console.log(e);
                            this.setState({ isloading: false });
                        });*/
                    } else {
                        if (aux) {

                            const localStorage = window.localStorage;
                            localStorage.setItem('marketpointmanagerid', this.state.email.toLowerCase());
                            //localStorage.setItem('navleft_medidores', true);
                            localStorage.setItem('marketpointmanager-user', type);
                            /* if (type === 'verificador') {
                                 window.location.href = '/mediciones';
                             } else {
                                 window.location.href = '/dashboard';
                             }*/
                            window.location.href = '/dashboard';

                        } else {
                            this.setState({ isloading: false });
                            firebase.auth().signOut().then(() => {
                                //Sign-out successful
                                this.setState({ email: '', password: '' });
                                Swal.fire('Error', "You don't have permission to access web Portal", 'error');
                                localStorage.removeItem('marketpointmanagerid');
                                localStorage.removeItem('marketpointmanager-user');
                            }).catch(e => {
                                //Error happened
                                console.log(e);
                                this.setState({ isloading: false });
                            });
                        }
                    }
                }).catch(e => {
                    console.log(e);
                    this.setState({ isloading: false });
                });
            })
            .catch(error => {
                Swal.fire('Error', error.message, 'error');

                // seterror(error.message)
            });
    }


    handleSubmit = e => {
        e.preventDefault();
        this.setState({ isloading: true }, () => {
            console.log("here1");
            doLogin({ email: this.state.email.toLowerCase(), password: this.state.password }, this.onIncorrectLogin).then(firebaseUser => {
                console.log("here2");
                let aux = false;
                let userExists = false;
                let type = '';
                if (firebaseUser && firebaseUser.user && firebaseUser.user.uid) {
                    checkMaster(firebaseUser.user.uid);
                }

                getUserByEmail(this.state.email.toLowerCase()).then(snapshot => {
                    snapshot.forEach(doc => {
                        let o = doc.data();
                        if (this.state.email.toLowerCase() === o.email) {
                            localStorage.removeItem('lastviajes');
                            localStorage.removeItem('lastviajesexpiration');
                            localStorage.removeItem("fechaclientes");
                            localStorage.removeItem("fechaoperadores");
                            localStorage.removeItem("fechaubicaciones");
                            localStorage.removeItem("fechaviajes");
                            localStorage.removeItem("fechaactivos");

                            let allowsaux = o.allow || "";
                            if (o.master == undefined || o.master == null) {
                                if (!allowsaux.includes("costoxkm")) {
                                    allowsaux += ":costoxkm";
                                }
                                if (!allowsaux.includes("dashboard")) {
                                    allowsaux += ":dashboard";
                                }
                            }

                            localStorage.setItem('allow', allowsaux || "");
                            localStorage.setItem('deny', o.deny || "");
                            userExists = true;
                            switch (o.type) {
                                case 'administrador':
                                    aux = true;
                                    type = o.type;
                                    break;
                                case 'user':
                                    aux = true;
                                    type = o.type;
                                    break;
                                default:
                                    aux = false;
                                    break;
                            }
                        }
                    });

                    if (!userExists) {

                        this.setState({ isloading: false });
                        firebase.auth().signOut().then(() => {
                            //Sign-out successful
                            this.setState({ email: '', password: '' });
                            Swal.fire('Error', "Ese correo no esta registrado o no esta en formato correcto (en minusculas y sin esapacios en blanco)", 'error');
                            localStorage.removeItem('marketpointmanagerid');
                            localStorage.removeItem('marketpointmanager-user');
                            localStorage.removeItem('lastviajes');
                            localStorage.removeItem('lastviajesexpiration');
                        }).catch(e => {
                            //Error happened
                            console.log(e);
                            this.setState({ isloading: false });
                        });

                        //primer logeo
                        /*console.log("primer logeo");
                        firstLogin(this.state.email.toLowerCase(), firebaseUser.user.uid).then(snapshot => {
                            this.setState({ isloading: false });
                            type = "user";
                            aux = true;
                            const localStorage = window.localStorage;
                            localStorage.setItem('marketpointmanagerid', this.state.email.toLowerCase());
                            //localStorage.setItem('navleft_medidores', true);
                            localStorage.setItem('marketpointmanager-user', type);
                            /* if (type === 'verificador') {
                                 window.location.href = '/mediciones';
                             } else {
                                 window.location.href = '/dashboard';
                             }*/
                        /* window.location.href = '/dashboard';

                     }); */

                        /*firebase.auth().signOut().then(() => {
                            //Sign-out successful
                            this.setState({ email: '', password: '' });
                            Swal.fire('Error', "User does not exist in records.", 'error');
                            localStorage.removeItem('marketpointmanagerid');
                            localStorage.removeItem('marketpointmanager-user');
                        }).catch(e => {
                            //Error happened
                            console.log(e);
                            this.setState({ isloading: false });
                        });*/
                    } else {
                        if (aux) {

                            const localStorage = window.localStorage;
                            localStorage.setItem('marketpointmanagerid', this.state.email.toLowerCase());
                            //localStorage.setItem('navleft_medidores', true);
                            localStorage.setItem('marketpointmanager-user', type);
                            /* if (type === 'verificador') {
                                 window.location.href = '/mediciones';
                             } else {
                                 window.location.href = '/dashboard';
                             }*/
                            window.location.href = '/dashboard';

                        } else {
                            this.setState({ isloading: false });
                            firebase.auth().signOut().then(() => {
                                //Sign-out successful
                                this.setState({ email: '', password: '' });
                                Swal.fire('Error', "You don't have permission to access web Portal", 'error');
                                localStorage.removeItem('marketpointmanagerid');
                                localStorage.removeItem('marketpointmanager-user');
                                localStorage.removeItem('lastviajes');
                                localStorage.removeItem('lastviajesexpiration');
                            }).catch(e => {
                                //Error happened
                                console.log(e);
                                this.setState({ isloading: false });
                            });
                        }
                    }
                }).catch(e => {
                    console.log(e);
                    this.setState({ isloading: false });
                });
            }).catch(e => {
                //Handle error
                console.log(e);
                if (e && e.code && e.code === "auth/user-disabled") {
                    Swal.fire('Licencia Expirada', "Tu licencia de TransportPlus expiró, si deseas seguir haciendo uso del sistema favor de renovar la licencia, comunicate al correo soporte@transportplus.mx", 'error');
                }
                this.setState({ status: 'fail', error_log: true, isloading: false });
            });
        });

    }

    resetPassword = () => {
        this.setState({ isModalOpen: true });
    }

    closeModal = () => {
        this.setState({ isModalOpen: false });
    }

    render() {
        return (
            <Fragment>

                {//this.state.loading ? <div></div> :
                    <LoadingOverlay active={this.state.isloading}
                        spinner
                        text='Espere un momento...'>
                        <div className="container loginbackground" >

                            <div className="row d-flex justify-content-center">
                                <div className="col-12 col-md-7 col-lg-5 cardlogin">



                                    <div className="pl-5 pr-5 pt-5 pb-5  text-center">
                                        <div className="pt-2 pb-2 mt-auto d-flex justify-content-center">
                                            <img src={process.env.PUBLIC_URL + '/logonegro3.png'} width={'590'} height={'100%'} className="logo-login" alt="Transport Plus" title="Transport Plus" />
                                        </div>

                                        {this.state.error_log ? <div className="alert alert-danger" role="alert">Correo o contraseña no son correctos.</div> : <div></div>}
                                        <div className="font-size-30 mb-4" style={{ color: 'white', marginTop: '20px', fontFamily: 'Space Mono' }}>Iniciar Sesión</div>
                                        <form onSubmit={this.handleSubmit} className="mb-4" >
                                            <div className="form-group mb-4">
                                                <input className="form-control" type='email' value={this.state.email} name='email' onChange={this.handleChange} placeholder='Correo electronico' />
                                            </div>
                                            <div className="form-group mb-4">
                                                <input className="form-control" type="password" value={this.state.password} onChange={this.handleChange} name="password" placeholder="Contraseña" />
                                            </div>
                                            <button type="submit" className="text-center btn bg-accent-color-2 w-100 font-weight-bold font-size-18">INICIAR</button>
                                        </form>

                                        <div >
                                            <Alert color="primary">
                                                <a href="/forgotpassword" className="alert-link" onClick={this.resetPassword}> ¿Olvidaste tu contraseña?</a>
                                            </Alert>
                                        </div>
                                        <div className="mt-auto justify-content-center flex-column align-items-center">
                                            <div className="text-center mt-2" style={{ color: "white", fontSize: '20px', fontFamily: 'Space Mono' }}>Transport Plus© 2022.  <br />  Todos los Derechos Reservados <br /> <small> EZ-Trips SAPI de C.V.</small></div>
                                        </div>
                                        <div className="mt-auto justify-content-center flex-column align-items-center">
                                            <div className="text-center mt-2" style={{ color: "#3285fd", fontSize: '15px', fontFamily: 'Space Mono' }}><a style={{ color: '#3285fd' }} href='/Aviso%20de%20Privacidad.pdf' target='_blank'> Aviso de Privacidad.</a>  </div>
                                        </div>
                                        <div className="mt-auto  justify-content-center flex-column align-items-center">
                                            <div className="text-center mt-2" style={{ color: "#3285fd", fontSize: '15px', fontFamily: 'Space Mono' }}><a style={{ color: '#3285fd' }} href='/contracts.pdf' target='_blank'> Términos y Condiciones.</a>  </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </LoadingOverlay>
                }

            </Fragment>
        )
    }
}
